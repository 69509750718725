export const RESET_EXCLUDED_DRIVERS = 'RESET_EXCLUDED_DRIVERS';
export const EXCLUDE_DRIVER = 'EXCLUDE_DRIVER';
export const EXCLUDE_DRIVER_SUCCESS = 'EXCLUDE_DRIVER_SUCCESS';
export const EXCLUDE_DRIVER_FAILURE = 'EXCLUDE_DRIVER_FAILURE';

export const UPDATE_EXCLUDED_DRIVER_INFO = 'UPDATE_EXCLUDED_DRIVER_INFO';
export const UPDATE_EXCLUDED_DRIVER_INFO_SUCCESS =
  'UPDATE_EXCLUDED_DRIVER_INFO_SUCCESS';
export const UPDATE_EXCLUDED_DRIVER_INFO_FAILURE =
  'UPDATE_EXCLUDED_DRIVER_INFO_FAILURE';

export const STORE_DRIVERS_PENDING_EXCLUSION =
  'STORE_DRIVERS_PENDING_EXCLUSION';

export const SET_ADDITIONAL_FIELDS = 'SET_ADDITIONAL_FIELDS';

export const CHANGE_REQUIRES_RATES = 'CHANGE_REQUIRES_RATES';

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';

export const UPDATED_RATES_REQUESTED = 'UPDATED_RATES_REQUESTED';

export const CHANGE_REQUIRES_FORMS_METADATA = 'CHANGE_REQUIRES_FORMS_METADATA';

export const GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES =
  'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES';
export const GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_SUCCESS =
  'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_SUCCESS';
export const GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_FAILURE =
  'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_FAILURE';

export const ALL_EXCLUDED_DRIVERS_CONFIRMED = 'ALL_EXCLUDED_DRIVERS_CONFIRMED';

export const resetExcludedDrivers = () => ({
  type: RESET_EXCLUDED_DRIVERS
});

export const excludeDriver = driver => ({
  type: EXCLUDE_DRIVER,
  payload: driver
});

export const excludeDriverSuccess = driver => ({
  type: EXCLUDE_DRIVER_SUCCESS,
  payload: driver
});

export const excludeDriverFailure = error => ({
  type: EXCLUDE_DRIVER_FAILURE,
  error
});

// Takes driverId and driver Object (first name, last name, driverId) as arg
export const storeDriversPendingExclusion = (
  byId,
  driverIdsPendingExclusion,
  totalDriversToExclude,
  nextExcludedDriverId,
  driver
) => ({
  type: STORE_DRIVERS_PENDING_EXCLUSION,
  payload: {
    byId,
    driverIdsPendingExclusion,
    totalDriversToExclude,
    nextExcludedDriverId,
    driver
  }
});

export const updateExcludedDriverInfo = data => ({
  type: UPDATE_EXCLUDED_DRIVER_INFO,
  payload: data
});

export const updateExcludedDriverInfoSuccess = data => ({
  type: UPDATE_EXCLUDED_DRIVER_INFO_SUCCESS,
  payload: data
});

export const updateExcludedDriverInfoFailure = error => ({
  type: UPDATE_EXCLUDED_DRIVER_INFO_FAILURE,
  error
});

export const changeRequiresRates = () => ({
  type: CHANGE_REQUIRES_RATES
});

export const setLoadingStatus = value => ({
  type: SET_LOADING_STATUS,
  payload: value
});

export const updatedRatesRequested = () => ({
  type: UPDATED_RATES_REQUESTED
});

export const changeRequiresFormMetadata = () => ({
  type: CHANGE_REQUIRES_FORMS_METADATA
});

export const getRemovedAndExcludedDriverNames = () => ({
  type: GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES
});

export const getRemovedAndExcludedDriverNamesSuccess = data => ({
  type: GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_SUCCESS,
  payload: data
});

export const getRemovedAndExcludedDriverNamesFailure = error => ({
  type: GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_FAILURE,
  error
});

export const allExcludedDriversConfirmed = () => ({
  type: ALL_EXCLUDED_DRIVERS_CONFIRMED
});
