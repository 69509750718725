import * as Sentry from '@sentry/react';
import { call, put, select, putResolve } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import Noblr from '@noblr-lab/react-app-services';
import { getUrlParameter, configureCookieName } from 'utilities';
import { createAccount, loginUser } from '../actions';
import {
  SECURE_COOKIE_HEADER_CONFIG,
  EXPIRES_COOKIE_HEADER_CONFIG
} from '../../constants';

const cookies = new Cookies();

/**
 * Saves new account password and is called before createAccount
 * Determines whether to use google or noblr authentication
 * Calls endpoint to register user account
 * @param {Object} payload
 * @param {string} payload.password - user's password
 * @param {string} payload.confirmPassword - user's password again
 * @param {string} payload.authType - either noblr or google, defaults to noblr
 */
export function* createNoblrAccountSubroutine({
  payload: { password, confirmPassword, authType = 'noblr' }
}) {
  // extract email and trial status from store
  const {
    drivers: {
      primaryDriver: { email }
    },
    auth: { trialActive: authTrialActive, emailToConfirm },
    app: { trialActive: appTrialActive, form }
  } = yield select();

  Sentry.withScope(scope => {
    scope.setTag('active_trial', authTrialActive);
    scope.setTag('auth_type', authType);
  });

  if (form && form.error) {
    yield put({ type: 'CLEAR_FORM_ERROR' });
  }

  // set form error if passwords do not match
  if (password !== confirmPassword) {
    yield put({
      type: 'SET_FORM_ERROR',
      error: {
        message: "Oops... your passwords don't match!"
      }
    });

    // restart generator
    return;
  }

  // save trial status and user email to pass to createAccount
  const trialStatus = appTrialActive || authTrialActive;
  const userEmail = email || emailToConfirm;

  // set up params and save dispatched action to accountCreate variable
  const accountCreate = createAccount(
    userEmail,
    password,
    trialStatus,
    authType
  );

  // put saga effect & dispatch call to createAccount
  yield put(accountCreate);
}

// secondary generator function called by "createNoblrAccount"
export function* createAccountSubroutine({
  payload: { email, authKey, authType }
}) {
  // pull policyId from redux store
  const {
    policy: { policyId, trialConverted, purchased }
  } = yield select();

  // build payload to send to "createAccount" backend endpoint
  let request = {
    email,
    authenticationType: authType,
    policyId
  };

  // modify request object if user signed in via Google
  if (authType === 'google') {
    request = {
      ...request,
      token: authKey
    };
  } else {
    // otherwise, set password in request object
    request = {
      ...request,
      password: authKey
    };
  }

  // Send request to Noblr App Services endpoint
  try {
    const { jwt, trialActive } = yield call(Noblr.user.createAccount, request);

    if (jwt.length) {
      cookies.set(
        configureCookieName('policySession'),
        jwt,
        SECURE_COOKIE_HEADER_CONFIG
      );
    }

    Sentry.withScope(scope => {
      scope.setTag('active_trial', trialActive);
      scope.setTag('auth_type', authType);
      scope.setTag('trial_converted', trialConverted);
    });

    // if request was successful, put successful saga effect
    yield put({
      type: 'CREATE_ACCOUNT_SUCCESS',
      payload: { trialActive }
    });

    if (trialConverted || (purchased && authType === 'google')) {
      yield put({
        type: 'REDIRECT',
        payload: { url: '/account/download-app' }
      });
    }
  } catch (error) {
    // Set fingerprint and capture exception in Sentry

    yield put({
      type: 'CREATE_ACCOUNT_FAILURE'
    });

    // if error occurs, put error saga effect
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

export function* loginNoblrAccountSubroutine(action) {
  const {
    payload: { email, password, authType = 'noblr' },
    formikActions: { setSubmitting = null }
  } = action;

  yield put(loginUser(email, password, authType, setSubmitting));
}

/**
 * Calls endpoint to authenticate user
 * @param {Object} action - action type and payload
 * @param {Object} action.payload
 * @param {string} action.payload.email - user's email address used to create account
 * @param {string} action.payload.authKey - password or google auth token depending on authType
 * @param {string} action.payload.authType - either 'noblr' or 'google'
 */
export function* userLoginSubroutine(action) {
  const {
    payload: { email, authKey, authType, setSubmitting = null }
  } = action;

  const {
    app: { form }
  } = yield select();

  if (form && form.error) {
    yield put({
      type: 'CLEAR_FORM_ERROR'
    });
  }

  let authRequest = {
    email,
    password: authKey,
    authenticationType: authType
  };

  // pass google as authentication type and authkey as token to login google user
  if (authType === 'google') {
    authRequest = {
      email,
      authenticationType: 'google',
      token: authKey
    };
  }

  try {
    const { jwt, policyId, requiresV2CoverageEndpoints, ...response } =
      yield call(Noblr.user.login, authRequest);

    if (setSubmitting) {
      setSubmitting(false);
    }

    yield put({
      type: 'LOGIN_USER_SUCCESS',
      payload: response
    });

    // trigger loader here to show while we get the other info about the user
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: true }
    });

    const {
      city,
      firstName,
      lastName,
      phoneNumber,
      personId,
      quoteId,
      state,
      street,
      trialActive,
      trialState,
      userOnboardingState,
      zipCode
    } = response;

    Sentry.configureScope(scope => {
      scope.setTag('quote_id', quoteId);
      scope.setTag('auth_type', authType);
      scope.setTag('active_trial', trialActive);
      scope.setTag('trial_state', trialState);
      scope.setUser({
        id: personId
      });
    });

    // Purchased policy if policy id exists and not on active trial
    if (
      policyId &&
      !trialActive &&
      (trialState !== 'TRIAL_CONVERTED' ||
        trialState !== 'TRIAL_CONVERTED_ONBOARDING_COMPLETE')
    ) {
      // Set tags in Sentry
      Sentry.withScope(scope => {
        scope.setTag('policy_id', policyId);
        scope.setTag('trial_converted', false);
        scope.setTag('reset_quote_id_cookie', true);
      });

      // set cookie with jwt from login response
      cookies.set(
        configureCookieName('policySession'),
        jwt,
        SECURE_COOKIE_HEADER_CONFIG
      );
      cookies.set(
        configureCookieName('session'),
        jwt,
        SECURE_COOKIE_HEADER_CONFIG
      );
      // Reset quoteId cookie
      cookies.remove(
        configureCookieName('quoteId'),
        EXPIRES_COOKIE_HEADER_CONFIG
      );
    } else {
      // set cookie with jwt from login response
      cookies.set(
        configureCookieName('session'),
        jwt,
        SECURE_COOKIE_HEADER_CONFIG
      );
      cookies.set(
        configureCookieName('personId'),
        personId,
        SECURE_COOKIE_HEADER_CONFIG
      );
      cookies.set(
        configureCookieName('quoteId'),
        quoteId,
        SECURE_COOKIE_HEADER_CONFIG
      );
    }

    // update redux store with primary driver info after logging in
    yield put({
      type: 'UPDATE_PRIMARY_DRIVER',
      payload: {
        city,
        firstName,
        lastName,
        quote: { quoteId },
        policyId,
        phoneNumber,
        state,
        street,
        trialActive,
        trialState,
        userOnboardingState,
        zipCode,
        email,
        personId
      }
    });

    // update status of quote or policy
    yield put({
      type: 'UPDATE_APP',
      payload: {
        trialActive
      }
    });

    if (
      policyId &&
      !trialActive &&
      (trialState === ' TRIAL_CONVERTED' ||
        trialState === ' TRIAL_CONVERTED_ONBOARDING_COMPLETE')
    ) {
      // Set tag in Sentry
      Sentry.withScope(scope => {
        scope.setTag('policy_id', policyId);
        scope.setTag('active_trial', false);
        scope.setTag('trial_converted', true);
      });

      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/account/download-app'
        }
      });
      // trial user signing in to retrieve quote
    } else if (
      policyId &&
      trialActive &&
      (trialState !== 'TRIAL_CONVERTED' ||
        trialState !== 'TRIAL_CONVERTED_ONBOARDING_COMPLETE')
    ) {
      const quoteQueryParams = { isQuoteRetrieval: true };

      yield put({
        type: 'TOGGLE_LOADER',
        payload: { toShow: false }
      });

      /* use putResolve to ensure quote data is stored
       in redux before yielding next saga effect */

      yield putResolve({
        type: 'FETCH_QUOTE_DATA',
        payload: {
          quoteComplete: true,
          showTrial: false
        }
      });

      // fetch the rate
      // if we allow custom coverages, get rate by package
      yield putResolve({
        type: 'GET_RATE_BY_PACKAGE',
        // we don't need to pass package info when isQuoteRetrieval is true
        payload: {
          // save package always has to be passed to get rate by package endpoint
          queryParams: { ...quoteQueryParams, savePackage: false }
        },
        shouldRedirect: true
      });
    } else {
      // Set tags in Sentry
      Sentry.withScope(scope => {
        scope.setTag('need_help_redirect', true);
        scope.setTag('active_trial', trialActive);
        scope.setTag('policy_id', policyId);
        scope.setTag('trial_converted', trialState === 'TRIAL_CONVERTED');
        scope.setTag('purchased', false);
      });

      yield put({
        type: 'TOGGLE_LOADER',
        payload: { toShow: false }
      });

      // user logged in and doesn't have a trial and hasn't purchased
      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/account/need-help'
        }
      });
    }
  } catch (error) {
    if (setSubmitting) {
      setSubmitting(false);
    }

    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });

    yield put({
      type: 'LOGIN_USER_FAILURE',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Updates user's email if they enter a new email address
 * Creates an account for the user with that email
 * @param {Object} payload
 * @param {string} payload.email - user's email address
 * @param {string} payload.password - user's password
 * @param {string} payload.confirmPassword - user's password again to match
 */
export function* confirmTrialUserEmailSubroutine({ payload }) {
  const { email, password, confirmPassword } = payload;

  const {
    drivers: { primaryDriver }
  } = yield select();

  // check if the entered email is different than the current email for primary driver
  if (primaryDriver.email !== email) {
    // if it is, update primary driver's email
    yield call(updateEmailAddressWatcher, email);
  }

  // create noblr account with email passed in and set trial to true
  yield put({
    type: 'CREATE_NOBLR_ACCOUNT',
    payload: { email, password, confirmPassword, isTrial: true }
  });
}

/**
 * Calls endpoint to update trial user's email address
 * @param {string} email
 */
function* updateEmailAddressWatcher(email) {
  try {
    yield call(Noblr.user.changeTrialEmail, email);

    // Set tag in Sentry
    Sentry.withScope(scope => {
      scope.setTag('trial_email_updated', true);
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Calls endpoint to send user verification code to reset their password
 * @param {Object} payload
 * @param {string} payload.email - email address to send one time pass to
 */
export function* sendOneTimePassSubroutine({ payload }) {
  const { email } = payload;

  try {
    yield call(Noblr.user.sendOneTimePass, email, true);

    yield put({
      type: 'SEND_ONE_TIME_PASS_SUCCESS',
      payload: {
        emailToConfirm: email
      }
    });

    yield put({
      type: 'REDIRECT',
      payload: {
        url: '/account/code-entry'
      }
    });
  } catch (error) {
    // Set fingerprint and capture exception in Sentry
    // 422 status indicates user has signed up with Google
    if (error.status === 422) {
      Sentry.withScope(scope => {
        scope.setTag('non-noblr-account', true);
      });

      yield put({
        type: 'SHOW_OTP_ERROR',
        error: {
          status: error.status,
          data: {
            message: "Please try to 'Sign In with Google' "
          }
        }
      });
    } else {
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    }
  }
}

/**
 * Gets user's email from url parameter or redux store
 * Calls endpoint with entered verification code and email
 * @param {Object} payload
 * @param {string} payload.otp - one time password emailed to user to reset password
 */
export function* verifyOTPSubroutine({ payload }) {
  const { otp } = payload;
  const {
    auth: { emailToConfirm }
  } = yield select();

  // check for email in url parameters
  const emailUrlParam = getUrlParameter(window.location.href, 'email');
  const email = emailUrlParam || emailToConfirm;

  try {
    const { jwt } = yield call(Noblr.user.verifyOneTimePass, {
      email,
      oneTimePass: otp
    });

    yield put({
      type: 'VERIFY_OTP_SUCCESS',
      payload: { email }
    });

    // set jwt in session cookie on success
    cookies.set(
      configureCookieName('policySession'),
      jwt,
      SECURE_COOKIE_HEADER_CONFIG
    );

    yield put({
      type: 'REDIRECT',
      payload: {
        url: '/account/create-password'
      }
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Calls endpoint to update user's password
 * Redirects them to sign in with new password
 * @param {Object} payload
 * @param {string} payload.password - new password to update account with
 */
export function* resetPasswordSubroutine({ payload, formikActions }) {
  const { password } = payload;
  const { setSubmitting } = formikActions;

  try {
    yield call(Noblr.user.resetPassword, password);

    yield put({
      type: 'RESET_PASSWORD_SUCCESS',
      payload: {
        passwordReset: true
      }
    });

    yield put({
      type: 'REDIRECT',
      payload: {
        url: '/account/sign-in'
      }
    });
  } catch (error) {
    // 422 status indicates user has signed up with Google
    if (error.status === 422) {
      Sentry.withScope(scope => {
        scope.setTag('non-noblr-account', true);
      });

      yield put({
        type: 'SET_ERROR',
        error: {
          status: error.status,
          data: {
            message: "Please go back and click 'Sign In with Google'"
          }
        }
      });
    } else if (error.response.status === 401) {
      yield put({
        type: 'SET_ERROR',
        error: {
          status: error.response.status,
          data: {
            message: 'Failed to create password'
          }
        }
      });
    } else if (error.status && error.data) {
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    } else {
      yield put({
        type: 'SET_ERROR',
        error: true
      });
    }
  } finally {
    setSubmitting(false);
  }
}

// Generator Effect for Sales Tool Handoff Purchase Flow - DISABLED
// export function* authenticateSalesToolLink({ payload }) {
//   if (!payload) {
//     yield put({
//       type: 'REDIRECT',
//       payload: {
//         url: '/account/need-help'
//       }
//     });

//     return;
//   }

//   try {
//     const { policyId, firstName, lastName, email, jwt } = yield call(
//       Noblr.user.getAccountInfo,
//       payload
//     );

//     // store jwt as cookie for future requests
//     cookies.set(
//       configureCookieName('policySession'),
//       jwt,
//       SECURE_COOKIE_HEADER_CONFIG
//     );
//     yield all([
//       put({
//         type: 'UPDATE_POLICY',
//         payload: { policyId }
//       }),
//       put({
//         type: 'UPDATE_PRIMARY_DRIVER',
//         payload: { firstName, lastName, email }
//       }),
//       put({
//         type: 'VERIFY_EMAIL_LINK_SUCCESS'
//       })
//     ]);
//   } catch (error) {
//     yield put({
//       type: 'VERIFY_EMAIL_LINK_FAILURE',
//       error: { ...error.data }
//     });

//     yield put({
//       type: 'REDIRECT',
//       payload: {
//         url: '/account/need-help'
//       }
//     });
//   }
// }
