import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Container, Box, Grid } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import Loader from 'components/Loader';
import {
  Prompt,
  StyledContentWrapper,
  StyledDriverExclusionButton,
  StyledDriverExclusionLinkButton,
  ModalHeader
} from 'styles';
import Modal from '../../components/Modal';
import { makeSelectCurrentExcludedDriver } from '../../redux/selectors';

const ButtonGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const ModalPrimaryButton = styled(Button)`
  margin: ${props => (props.margin ? props.margin : '0.125rem auto')};
  padding: 8px;
  height: auto;
`;

function AnyCars(props) {
  const {
    location: { pathname },
    history
  } = props;

  const prevLocation = useRef(pathname);
  const unListen = useRef();

  // TODO: pull driverId from params and set next driver to exclude in redux
  const dispatch = useDispatch();
  const { driverId } = useParams();
  const selectCurrentExcludedDriver = useMemo(
    makeSelectCurrentExcludedDriver,
    []
  );

  // Pull excluded driver from state
  const excludedDriver = useSelector(state =>
    selectCurrentExcludedDriver(state)
  );

  const isLoading = useSelector(state => state?.excludedDrivers?.loading);

  const { firstName, lastName } = excludedDriver;
  const fullName = `${firstName} ${lastName}`;
  const driverExclusion = `/driver-exclusion/${driverId}`;
  const successRouteNoButton = `${driverExclusion}/confirmation`;

  const handleNoClick = useCallback(
    () =>
      dispatch({
        type: 'UPDATE_EXCLUDED_DRIVER_INFO',
        payload: {
          ...excludedDriver,
          driverComplete: true,
          activeBit: false,
          drivesVehicles: false
        },
        successRoute: successRouteNoButton
      }),
    [dispatch, excludedDriver, successRouteNoButton]
  );

  const handleYesClick = () => {
    dispatch({
      type: 'SET_ADDITIONAL_FIELDS',
      payload: {
        drivesVehicles: true
      }
    });
  };

  const backArrowListener = useCallback(() => {
    if (prevLocation !== pathname) {
      history.go(1);
      dispatch({ type: 'OPEN_MODAL', payload: 'driverExclusionModal' });
    }
  }, [history, pathname, dispatch]);

  useEffect(() => {
    if (prevLocation !== pathname) {
      history.push(pathname, '', '');

      unListen.current = history.listen((newLocation, action) => {
        if (action === 'POP') {
          backArrowListener();
        }
      });
    }

    return unListen.current;
  }, [backArrowListener, pathname, history]);

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleNavigation = () => {
    handleClose();
    dispatch({
      type: 'REDIRECT',
      payload: { url: '/driver-exclusion/start' }
    });
  };

  return (
    <Container>
      <StyledContentWrapper>
        {isLoading ? (
          <Loader absolute />
        ) : (
          <>
            <Box m={{ xs: 2, sm: 2, md: 7 }} mx="auto">
              <Prompt>{`Does ${fullName} drive any cars on your policy?`}</Prompt>
            </Box>
            <StyledDriverExclusionLinkButton
              secondary
              id="yes-button"
              onClick={handleYesClick}
              routeTo={`${driverExclusion}/confirm-covered`}
            >
              YES
            </StyledDriverExclusionLinkButton>
            <ContinueButton onContinue={handleNoClick} />
          </>
        )}
      </StyledContentWrapper>
      <Modal
        inline
        modalLabel="DriverExclusionModal"
        modalName="driverExclusionModal"
      >
        <Box>
          <Grid container>
            <ModalHeader>
              {`Clicking 'BACK' will take you to the section start page and reset the list of drivers you've gone through.`}
            </ModalHeader>
            <ButtonGrid container item>
              <Grid item xs sm>
                <ModalPrimaryButton large onClick={handleClose}>
                  CANCEL
                </ModalPrimaryButton>
              </Grid>
              <Grid item xs sm>
                <ModalPrimaryButton primary large onClick={handleNavigation}>
                  BACK
                </ModalPrimaryButton>
              </Grid>
            </ButtonGrid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export const ContinueButton = React.memo(({ onContinue }) => (
  <StyledDriverExclusionButton
    secondary
    large
    id="no-button"
    onClick={onContinue}
  >
    NO
  </StyledDriverExclusionButton>
));

export default AnyCars;
