import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

export default function Airbags() {
  const vehicleId = useSelector(
    state => state.vehicles?.editingVehicle?.vehicleId
  );

  const make = useSelector(state => state.vehicles?.editingVehicle?.make);

  const model = useSelector(state => state.vehicles?.editingVehicle?.model);

  const airbagDiscountMapping = useSelector(
    state => state.vehicles?.editingVehicle?.airbagDiscountMapping
  );

  const fields = [
    {
      type: 'radio',
      name: 'airbagDiscountMapping',
      id: 'air-bag-input',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: true,
      initialValue: airbagDiscountMapping || 'Yes',
      radioGroupAriaLabel: 'Airbags'
    }
  ];

  const prompt = `Does your ${make} ${model} have airbags?`;

  const submitAction = 'UPDATE_VEHICLE';

  const successRoute = '/add-vehicles/vehicles-list';

  return !vehicleId ? (
    <Redirect to={successRoute} replace />
  ) : (
    <StyledContentWrapper>
      <Form
        submitAsync
        prompt={prompt}
        fields={fields}
        submitAction={submitAction}
        successRoute={successRoute}
        renderFieldRow={false}
        maxWidth="500px"
      />
    </StyledContentWrapper>
  );
}
