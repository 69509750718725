import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Button from 'components/Buttons/Button';
import GoogleButton from 'components/Buttons/GoogleButton';
import { Prompt, StyledContentWrapper } from 'styles';

const ImageWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: auto;
  }
`;

class ThankYouPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt:
        'Thank you! Set up your account and download the app to wrap things up.'
    };

    this.navigateToSignUpForm = this.navigateToSignUpForm.bind(this);
  }

  navigateToSignUpForm() {
    const { history, policyId, trialConverted } = this.props;

    return (
      policyId &&
      !trialConverted &&
      history.replace({
        pathname: '/account/setup-account',
        state: { ...history.location.state }
      })
    );
  }

  render() {
    const { prompt } = this.state;
    const { showGoogleSignup } = this.props;

    return (
      <StyledContentWrapper>
        <ImageWrapper>
          <img
            src={`${process.env.REACT_APP_NOBLR_CDN}/animations/love-car-animation.gif`}
            alt=""
            width="400px"
            height="auto"
          />
        </ImageWrapper>
        <Prompt>{prompt}</Prompt>
        <Button
          type="button"
          primary
          extraLarge
          onClick={this.navigateToSignUpForm}
          id="sign-up-button"
        >
          Sign up with Email
        </Button>
        {showGoogleSignup && (
          <GoogleButton signUp>Sign Up With Google</GoogleButton>
        )}
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { showGoogleSignup },
  policy: { policyId, trialConverted }
}) => ({
  showGoogleSignup,
  policyId,
  trialConverted
});

ThankYouPage.propTypes = {
  showGoogleSignup: PropTypes.bool.isRequired,
  policyId: PropTypes.string.isRequired,
  trialConverted: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.objectOf(
        PropTypes.oneOfType[
          (PropTypes.bool, PropTypes.string, PropTypes.object, PropTypes.number)
        ]
      )
    }).isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired
};

export default connect(mapStateToProps)(ThankYouPage);
