import * as Sentry from '@sentry/react';
import Noblr from '@noblr-lab/react-app-services';
import { Cookies } from 'react-cookie';
import valid from 'card-validator';
import {
  call,
  select,
  put,
  all,
  retry,
  putResolve,
  fork
} from 'redux-saga/effects';
import { configureCookieName } from 'utilities';
import { groupCoverages } from 'utilities/coverage';
import {
  PREBIND_FORM_DICT,
  SECURE_COOKIE_HEADER_CONFIG
} from '../../constants';

const cookies = new Cookies();

/**
 * Create a quote
 * Hits endpoint to create quote in Policy One
 * @param {Object} action
 */
export function* createQuoteSubroutine(action) {
  const {
    drivers: {
      primaryDriver: { dlState, state: garageState }
    },
    auth: { state },
    policy: { businessType, producerCode }
  } = yield select();

  const {
    payload: { policyEffectiveDate }
  } = action;

  try {
    // Send request to Noblr App Services endpoint to create quote with effective date
    const response = yield retry(5, 1000, Noblr.policyOne.createQuote, {
      state: dlState || state,
      businessType,
      producerCode,
      policyEffectiveDate
    });

    Sentry.withScope(scope => {
      scope.setTag('business_type', businessType);
      scope.setTag('dl_state', dlState);
      scope.setTag('producer_code', producerCode);
      scope.setTag('quote_created', true);
    });

    // pass result to policy reducer and reset application blob urls
    yield put({
      type: 'UPDATE_POLICY',
      payload: {
        state: garageState,
        policyEffectiveDate,
        prebindApplication: null,
        membershipApplication: null,
        umuimOfferForm: null,
        ...response
      }
    });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Update prebind rate fields
 */
export function* updatePreBindRateFieldsWatcher() {
  // Send request to Noblr App Services endpoint
  try {
    // endpoint doesn't have response body
    yield call(Noblr.policyOne.updatePreBindRateFields);

    yield put({
      type: 'UPDATE_PREBIND_RATE_FIELDS_SUCCESS'
    });

    yield put({
      type: 'CLEAR_FORM_ERROR'
    });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Fetch pre bind application pdf for member to sign
 */
export function* getPrebindApplication() {
  yield put({ type: 'CLEAR_PDF', payload: 'prebindApplication' });

  try {
    // Send request to Noblr App Services endpoint
    const blob = yield call(Noblr.policyOne.getPreBindApplication);

    // pass the url to the pdf to the policy store so we can display it
    yield put({
      type: 'REQUEST_PREBIND_PDF_SUCCESS',
      payload: {
        pdfName: 'prebindApplication',
        pdfURL: window.URL.createObjectURL(blob)
      }
    });
  } catch (error) {
    yield put({ type: 'REQUEST_PREBIND_PDF_FAILURE', error });

    // catch any error and set in redux store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
    yield put({
      type: 'DISPLAY_ASYNC_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Fetch membership application pdf for member to sign
 */
export function* getMembershipApplication() {
  yield put({ type: 'CLEAR_PDF', payload: 'membershipApplication' });

  try {
    // Send request to Noblr App Services endpoint
    const blob = yield call(Noblr.policyOne.getMembershipApplication);

    // pass the url to the pdf to the policy store so we can display it
    yield put({
      type: 'REQUEST_PREBIND_PDF_SUCCESS',
      payload: {
        pdfName: 'membershipApplication',
        pdfURL: window.URL.createObjectURL(blob)
      }
    });
  } catch (error) {
    yield put({ type: 'REQUEST_PREBIND_PDF_FAILURE', error });

    // catch any error and set in redux store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });

    // catch any error and set in redux store
    yield put({
      type: 'DISPLAY_ASYNC_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Fetch umuim offer form pdf for member to sign
 */
export function* getUmuimOfferForm() {
  yield put({ type: 'CLEAR_PDF', payload: 'umuimOfferForm' });
  try {
    // Sends request to Noblr App Services endpoint
    const blob = yield call(Noblr.policyOne.getUmuimOfferForm);

    yield put({
      type: 'REQUEST_PREBIND_PDF_SUCCESS',
      payload: {
        pdfName: 'umuimOfferForm',
        pdfURL: window.URL.createObjectURL(blob)
      }
    });
  } catch (error) {
    yield put({ type: 'REQUEST_PREBIND_PDF_FAILURE', error });

    // catch any error and set in redux store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });

    // catch any error and set in redux store
    yield put({
      type: 'DISPLAY_ASYNC_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Hit Policy One endpoint to sign prebind application
 * @param {Object} action
 */
export function* signUmbiForm(action) {
  const {
    payload: { UMBI_SELECTION, setSubmitting }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(
      Noblr.policyOne.signCustomPreBindForm,
      'UMBI_SELECTION',
      UMBI_SELECTION
    );

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_SUCCESS',
      payload: 'umbiSelection'
    });
  } catch (error) {
    yield put({ type: 'REQUEST_PREBIND_PDF_FAILURE', error });

    // catch any error and set in redux store
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_FAILURE',
      error
    });
  } finally {
    // make sure isSubmitting is false
    setSubmitting(false);
  }
}

export function* signUimbiForm(action) {
  const {
    payload: { UIMBI_SELECTION, setSubmitting }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(
      Noblr.policyOne.signCustomPreBindForm,
      'UIMBI_SELECTION',
      UIMBI_SELECTION
    );

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_SUCCESS',
      payload: 'uimbiSelection'
    });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_FAILURE',
      error
    });
  } finally {
    // make sure isSubmitting is false
    setSubmitting(false);
  }
}

export function* signTortOptionsForm(action) {
  const {
    payload: { TORT_OPTIONS, setSubmitting }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(
      Noblr.policyOne.signCustomPreBindForm,
      'TORT_OPTIONS',
      TORT_OPTIONS
    );

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_SUCCESS',
      payload: 'tortOptions'
    });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_FAILURE',
      error
    });
  } finally {
    // make sure isSubmitting is false
    setSubmitting(false);
  }
}

/**
 * Fetch custom prebind forms
 */

/**
 * Hit Policy One endpoint to sign prebind application
 * @param {Object} action
 */
export function* signInsuranceApplication(action) {
  const {
    payload: { fieldName, signedName, setSubmitting }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(Noblr.policyOne.signPreBindApplication, signedName);

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_SUCCESS',
      payload: fieldName
    });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_FAILURE',
      error
    });
  } finally {
    setSubmitting(false);
  }
}

/**
 * Hit Policy One endpoint to sign membership application
 * @param {Object} action
 */
export function* signMembershipApplication(action) {
  const {
    payload: { fieldName, signedName, setSubmitting }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(Noblr.policyOne.signMembershipApplication, signedName);

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_SUCCESS',
      payload: fieldName
    });
    // pass payload to policy store
    // todo: check if we need to do this
    // yield put({ type: actions.UPDATE_POLICY, payload });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_FAILURE',
      error
    });
  } finally {
    setSubmitting(false);
  }
}

/**
 * Hit Policy One endpoint to sign umim offer form
 * @param {Object} action
 */
export function* signUmuimOfferFormWatcher(action) {
  const {
    payload: { umuimOfferForm: signedName },
    formikActions: { setSubmitting }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(Noblr.policyOne.signUmuimOfferForm, signedName);

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_SUCCESS',
      payload: 'umuimOfferForm'
    });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'SUBMIT_PREBIND_SIGNATURE_FAILURE',
      error
    });
  } finally {
    setSubmitting(false);
  }
}

/**
 * Save selected payment plan option
 */
export function* setPaymentSchedule({
  payload: { option: noblrPaymentPlanName, successRoute }
}) {
  // get business type from store since it's required in request payload
  const {
    policy: { businessType }
  } = yield select();

  const reqBody = { noblrPaymentPlanName, businessType };

  try {
    // Send request to Noblr App Services endpoint
    const response = yield call(Noblr.policyOne.updatePaymentPlan, reqBody);

    // pass successful response to policy store
    yield put({
      type: 'SET_INSTALLMENT_SCHEDULE_SUCCESS',
      payload: {
        ...response,
        noblrPaymentPlanName
      }
    });

    // redirect user to the next screen
    yield put({
      type: 'REDIRECT',
      payload: {
        url: successRoute
      }
    });
  } catch (error) {
    // if there's an error, call failure action to set error
    yield put({
      type: 'SET_INSTALLMENT_SCHEDULE_FAILURE',
      error
    });
  }
}

/**
 * Save billing info for member
 * @param {Object} action
 */
export function* savePaymentInfo(action) {
  const {
    payload: {
      cardCVV,
      cardExpiration,
      cardZipCode,
      email,
      fullName,
      creditCard,
      phoneNumber
    },
    formikActions: { setSubmitting }
  } = action;

  // remove symbols from phoneNumber to use for api calls
  const formattedNumber = phoneNumber.replace(/\D/g, '');

  // split expiration date into month and year
  const expirationMonth = cardExpiration.split('/')[0];
  const expirationYear = cardExpiration.split('/')[1];
  // get billing address off driver store
  const {
    drivers: {
      primaryDriver: { street, state, city }
    }
  } = yield select();

  // build payment info payload
  const paymentInfo = {
    CreditCard: {
      Holder: {
        Zip: cardZipCode,
        Address: `${street} ${city}, ${state}`,
        Name: fullName
      },
      ExpirationYear: `20${expirationYear}`,
      ValidationValue: cardCVV,
      ExpirationMonth: expirationMonth,
      Number: creditCard.replace(/\s+/g, '')
    }
  };

  try {
    // Send request to Noblr App Services endpoint
    const result = yield call(
      Noblr.policyOne.getInstallmentSchedule,
      paymentInfo
    );

    yield put({
      type: 'GET_INSTALLMENT_SCHEDULE_SUCCESS',
      payload: result
    });

    // update primary driver's phone and email address
    // note: user's have an option to change their email address on the payment info screen
    // if they don't, we'll keep their current email address
    yield put({
      type: 'UPDATE_PHONE_AND_EMAIL',
      payload: {
        phoneNumber: formattedNumber,
        email
      }
    });

    // call saga to set credit card type
    yield fork(setCardType, creditCard);
  } catch ({ status, data }) {
    yield put({
      type: 'SET_FORM_ERROR',
      error: {
        status,
        message: data.message
      }
    });

    yield put({
      type: 'GET_INSTALLMENT_SCHEDULE_FAILURE',
      error: { status, message: data.message }
    });
  } finally {
    // manually set submitting to connect button enabled/disabled state
    setSubmitting(false);
  }
}

/**
 * Update email and phone
 * @param {Object} action
 */
export function* updatePhoneAndEmail(action) {
  // pull phone number and email off payload
  const {
    payload: { phoneNumber, email }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(Noblr.policyOne.updatePhoneAndEmail, { phoneNumber, email });
    yield put({
      type: 'UPDATE_PHONE_AND_EMAIL_SUCCESS',
      payload: {
        phoneNumber,
        email
      }
    });

    // update primary driver with this email address
    yield put({
      type: 'UPDATE_PRIMARY_DRIVER',
      payload: { email }
    });
  } catch (error) {
    // catch error and set in redux store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'UPDATE_PHONE_AND_EMAIL_FAILURE',
      payload: {
        phoneNumber,
        email
      }
    });
  }
}

/**
 * Gets card type from validator
 * Sets last four digit of credit card
 * @param {string} cardNumber
 */
export function* setCardType(cardNumber) {
  // use card validator to get card type and format it to uppercase
  const cardType = valid.number(cardNumber).card.type.toUpperCase();
  // take the last four digits of the card number
  const lastFour = cardNumber.substring(cardNumber.length - 4);

  // set the type and last four digits
  yield put({
    type: 'SET_CARD_TYPE',
    payload: {
      cardType,
      lastFour
    }
  });
}

/**
 * Bind quote in Policy One (happens when user purchases)
 */
export function* bindQuote() {
  const {
    app: { trialActive }
  } = yield select();

  try {
    // Response from Noblr App Services BindQuote endpoint
    const { policyId, userAlreadyExists, trialConverted, jwt } = yield call(
      Noblr.policyOne.bindQuote
    );

    Sentry.withScope(scope => {
      scope.setTag('policy_id', policyId);
      scope.setTag('trial_converted', trialConverted);
      scope.setTag('user_exists', userAlreadyExists);
      scope.setTag('active_trial', 'no (manually set in bindQuote Saga)');
      scope.setTag('bind_quote_success', true);
    });

    // update session cookie with new token
    // set cookie with jwt from login response
    cookies.set(
      configureCookieName('policySession'),
      jwt,
      SECURE_COOKIE_HEADER_CONFIG
    );

    // on success, update policy store with new policy id,
    // and whether the user already exists and if they've converted from trial
    yield put({
      type: 'BIND_QUOTE_SUCCESS',
      payload: {
        policyId,
        userExists: userAlreadyExists,
        trialConverted,
        trialActive: false // reset active trial flag
      }
    });

    // update trial value in app reducer
    if (trialActive) {
      yield put({
        type: 'UPDATE_APP',
        payload: { trialActive: false }
      });
      yield put({
        type: 'UPDATE_AUTH_STATUS',
        payload: { isTrial: false, trialActive: false, policyActive: true }
      });
    }

    if (trialConverted) {
      yield put({
        type: 'REDIRECT',
        payload: { url: '/account/download-app' }
      });
    }
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('bind_quote_success', false);
    });

    // if error code is 412, effective date has expired and needs to be updated
    if (error.status === 412) {
      Sentry.withScope(scope => {
        scope.setTag('expired_effective_date', true);
      });

      // redirect to effective date with error boolean true
      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/purchase/effective-date',
          config: {
            showEffectiveDateError: true
          }
        }
      });
    }

    // Bind quote failed because card is invalid or the account has been closed.
    if (error.status === 403) {
      Sentry.withScope(scope => {
        scope.setTag('invalid_credit_card', true);
      });
      yield put({
        type: 'REDIRECT',
        payload: { url: '/purchase/payment-info' }
      });
    }
    // set error on app level
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });

    // set error on form level
    // TODO: determine if we need both of these
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    // TODO: determine if we need both of these
    yield put({
      type: 'BIND_QUOTE_FAILURE'
    });
  }
}

/**
 * Start trial for user
 */
export function* updateToTrial() {
  try {
    // Send request to Noblr App Services endpoint
    // TODO: pull email, trialConverted and userAlreadyExists from response body
    const { policyId, jwt } = yield call(Noblr.quote.updateToTrial);

    // set policy jwt in cookie
    cookies.set(
      configureCookieName('policySession'),
      jwt,
      SECURE_COOKIE_HEADER_CONFIG
    );

    Sentry.withScope(scope => {
      scope.setTag('policy_id', policyId);
      scope.setTag('active_trial', true);
    });

    // set resulting policy id on store
    yield put({
      type: 'UPDATE_TO_TRIAL_SUCCESS',
      payload: {
        policyId
      }
    });
    // TODO: determine why policySession cookie is set on trial?
  } catch (error) {
    // todo: change this to set error
    yield put({
      type: 'DISPLAY_ASYNC_ERROR',
      error: { status: error.status, ...error.data }
    });

    // trigger UPDATE_TO_TRIAL_FAIL to set trial active to false
    yield put({
      type: 'UPDATE_TO_TRIAL_FAIL'
    });
  }
}

/**
 * Set VIN for vehicles added with year make model drop downs
 * @param {Object} payload
 */
function* updateVehicleWithVin(payload) {
  // todo: this was broken out so if one call fails we can add inline error messages
  try {
    // Send request to Noblr App Services endpoint
    // call update vehicle with vehicle id and vin
    yield call(Noblr.vehicle.updateVehicle, payload);
  } catch (error) {
    // set error at form level
    yield put({
      type: 'SET_FORM_ERROR',
      error: { status: error.status, ...error.data }
    });

    // throw error to break out
    throw new Error(error);
  }
}

/**
 * rerate quote with real VINs
 *
 */
export function* verifyVINsSubroutine(action) {
  const {
    payload,
    successRoute,
    formikActions: { setSubmitting }
  } = action;

  const {
    rate: { vehicles, currentPackage, byPackage },
    coverage: { config }
  } = yield select();

  // show loader while we run rerate
  yield put({
    type: 'TOGGLE_LOADER',
    payload: {
      toShow: true
    }
  });

  // get all vehicles missing vin that are active
  const vehiclesToVerify = vehicles.filter(vehicle => !vehicle.vin);

  try {
    // for all of the vehicles
    yield all(
      vehiclesToVerify.map(({ vehicleId, vehicleOwnership }) =>
        all([
          // update form values for vehicle
          put({
            type: 'UPDATE_FORM',
            payload: {
              [vehicleId]: payload[vehicleId]
            }
          }),
          // call saga to update the vehicle with vin
          // vehicle id and ownership are required
          call(updateVehicleWithVin, {
            vin: payload[vehicleId],
            vehicleId,
            vehicleOwnership
          })
        ])
      )
    );

    yield put({ type: 'VERIFY_VINS_SUCCESS' });

    Sentry.withScope(scope => {
      scope.setTag('vin_re_rate', true);
    });

    // after updating vehicles, set vin rerate flag to true
    yield put({
      type: 'SET_RERATE',
      payload: {
        vinRerate: true
      }
    });

    // if allowCustomCoverages is true, use get rate by package
    // once update is all done, call get rate
    if (currentPackage !== 'custom') {
      yield put({
        type: 'GET_RATE_BY_PACKAGE',
        payload: {
          queryParams: { vinRerate: true, savePackage: true },
          currentPackage
        },
        shouldRedirect: true,
        successRoute
      });
    } else {
      const coverages = groupCoverages(
        config.policyCoverages,
        config.vehicleCoverages,
        byPackage.custom.coverage,
        vehicles
      );

      yield put({
        type: 'GET_CUSTOM_COVERAGE_RATE',
        payload: {
          ...coverages,
          queryParams: { vinRerate: true, savePackage: true }
        },
        successRoute
      });
    }
  } catch (error) {
    yield put({
      type: 'VERIFY_VINS_FAILURE',
      error: true
    });
  } finally {
    setSubmitting(false);
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: false
      }
    });
  }
}

/**
 *  Hit quote endpoint to send refusal to write email
 * @param {Object} action
 */

export function* sendRefusalToWriteEmailSubroutine(action) {
  const {
    payload: { email }
  } = action;

  try {
    // Send request to Noblr App Services endpoint
    yield call(Noblr.quote.sendRefusalToWriteEmail, email);

    yield put({
      type: 'SEND_REFUSAL_TO_WRITE_EMAIL_SUCCESS'
    });

    yield put({
      type: 'SHOW_EMAIL_MODAL'
    });
  } catch (error) {
    // catch any error and set in redux store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, message: error.data.message }
    });

    yield put({
      type: 'SEND_REFUSAL_TO_WRITE_EMAIL_FAILURE'
    });
  }
}

/*
 Generator saga that handles all prebind doc requests for states that do not have dynamic forms enabled
*/
export function* prebindPDFWatcher({ payload }) {
  const {
    policy: { state }
  } = yield select();

  const formName = PREBIND_FORM_DICT[payload];

  /*
  As of April 2024, NV is the only state not on dynamic forms that has custom pre-bind forms
  */
  if (state !== 'NV') {
    if (formName === 'APPLICATION') {
      yield call(getPrebindApplication);
    } else if (formName === 'MSA') {
      yield call(getMembershipApplication);
    } else if (formName === 'UMUIM_OFFER_FORM') {
      yield call(getUmuimOfferForm);
    } else {
      // do nothing
    }
  } else {
    // Clear current PDF before requesting new version
    yield put({ type: 'CLEAR_PDF', payload: formName });
    // Custom Coverages

    try {
      const blob = yield call(Noblr.policyOne.getCustomPreBindForms, formName);

      yield put({
        type: 'REQUEST_PREBIND_PDF_SUCCESS',
        payload: {
          pdfName: payload,
          pdfURL: window.URL.createObjectURL(blob)
        }
      });
    } catch (error) {
      yield put({ type: 'REQUEST_PREBIND_PDF_FAILURE', error });
    }
  }
}

/*
   Generator saga that handles all prebind doc requests for states that do not have dynamic forms enabled
*/
export function* watchPrebindSignature({ payload, formikActions }) {
  const { setSubmitting } = formikActions;
  const {
    policy: { state }
  } = yield select();

  // extract field name and signed name from incoming payload
  const [fieldName, signedName] = Object.entries(payload).filter(
    ([key]) => PREBIND_FORM_DICT[key]
  )[0];

  // Format form name for requests
  const signedFormName = PREBIND_FORM_DICT[fieldName];

  /*
  As of April 2024, NV is the only state not on dynamic forms that has custom pre-bind forms
  */
  if (state !== 'NV') {
    if (signedFormName === 'APPLICATION') {
      yield put({
        type: 'SIGN_INSURANCE_APPLICATION',
        payload: { fieldName, signedName, setSubmitting }
      });
    } else if (signedFormName === 'MSA') {
      yield put({
        type: 'SIGN_MEMBERSHIP_APPLICATION',
        payload: { fieldName, signedName, setSubmitting }
      });
    } else if (signedFormName === 'UMUIM_OFFER_FORM') {
      yield putResolve({
        type: 'SIGN_UMUIM_OFFER_FORM',
        payload: { fieldName, signedName, setSubmitting }
      });
    }
  } else {
    try {
      yield call(
        Noblr.policyOne.signCustomPreBindForm,
        PREBIND_FORM_DICT[fieldName],
        signedName
      );

      yield put({
        type: 'SUBMIT_PREBIND_SIGNATURE_SUCCESS',
        payload: { fieldName }
      });
    } catch (error) {
      let message = error.data || error.data.message;

      if (error.status === 401) {
        message = 'Signed name does not match';
      }
      yield put({
        type: 'SET_FORM_ERROR',
        error: {
          status: error.status,
          message
        }
      });

      yield put({
        type: 'SUBMIT_PREBIND_SIGNATURE_FAILURE',
        error
      });
    } finally {
      setSubmitting(false);
    }
  }
}

export function* getDynamicPreBindFormByIdSubroutine({
  payload: { formId, pdfName }
}) {
  yield put({ type: 'CLEAR_PDF', payload: pdfName });

  try {
    const blob = yield call(Noblr.policyOne.getDynamicPreBindFormById, formId);

    yield put({
      type: 'GET_DYNAMIC_PRE_BIND_FORM_BY_ID_SUCCESS',
      payload: {
        pdfName,
        pdfURL: window.URL.createObjectURL(blob)
      }
    });
  } catch (error) {
    yield put({ type: 'GET_DYNAMIC_PRE_BIND_FORM_BY_ID_FAILURE', error });
  }
}

export function* submitFormIdSignatureWatcher({ payload, formikActions }) {
  const { setSubmitting } = formikActions;
  const { policy } = yield select();

  // extract field string formValue containing formId and formName and signed name from incoming payload
  const [formValue, signedName] = Object.entries(payload).filter(
    ([key]) => key
  )[0];

  const submitPayload = formValue.split(':');

  const [pdfName, formId] = submitPayload;

  const policyPdf = Object.entries(policy).filter(
    ([key]) => key === pdfName
  )[0][1];

  try {
    yield call(
      Noblr.policyOne.submitDynamicPreBindFormIdSignature,
      formId,
      signedName
    );

    yield put({
      type: 'SUBMIT_FORM_ID_SIGNATURE_SUCCESS',
      payload: policyPdf
    });

    const { pendingFormIds, nextFormId, customPreBindForms } = yield select(
      state => state.policy
    );

    let updatedCurrentFormId = null;
    let updatedNextFormId = null;
    let updatedPendingFormIds = pendingFormIds.slice();

    updatedCurrentFormId = nextFormId;

    if (pendingFormIds.length > 0) {
      updatedNextFormId = updatedPendingFormIds.shift();
    }

    /* if forms are not complete then sequences normally else reset form list */
    if (updatedCurrentFormId) {
      yield put({
        type: 'SET_PENDING_FORMS',
        payload: {
          pendingFormIds: updatedPendingFormIds,
          currentFormId: updatedCurrentFormId,
          nextFormId: updatedNextFormId,
          customPreBindForms
        }
      });
    } else {
      updatedPendingFormIds = customPreBindForms.allIds.slice();
      updatedCurrentFormId = updatedPendingFormIds.shift();

      if (updatedPendingFormIds.length > 0) {
        updatedNextFormId = updatedPendingFormIds.shift();
      }

      yield put({
        type: 'SET_PENDING_FORMS',
        payload: {
          pendingFormIds: updatedPendingFormIds,
          currentFormId: updatedCurrentFormId,
          nextFormId: updatedNextFormId,
          customPreBindForms
        }
      });
    }
  } catch (error) {
    if (error.status === 401) {
      error.data = 'Signed name does not match';
    }

    const errorMessageAndStatus = {
      data: { message: error.data },
      status: error.status
    };

    yield put({
      type: 'SET_FORM_ERROR',
      error: errorMessageAndStatus
    });

    yield put({ type: 'SUBMIT_FORM_ID_SIGNATURE_FAILURE', error });
  } finally {
    setSubmitting(false);
  }
}

export function* requestDynamicPreBindFormsSubroutine() {
  yield put({
    type: 'TOGGLE_LOADER',
    payload: {
      toShow: true
    }
  });

  try {
    const response = yield call(Noblr.policyOne.getUpdatedDynamicPreBindForms);

    yield put({
      type: 'REQUEST_DYNAMIC_PRE_BIND_FORMS_SUCCESS',
      payload: response
    });
  } catch (error) {
    yield put({
      type: 'REQUEST_DYNAMIC_PRE_BIND_FORMS_FAILURE',
      error
    });
  } finally {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: false
      }
    });
  }
}
