export default {
  name: 'set-up-account',
  pages: [
    {
      name: 'set-password',
      overrideSectionTitle: {
        type: 'static',
        value: 'Set Up Password'
      }
    },
    {
      type: 'DownloadPage',
      name: 'download-app',
      overrideSectionTitle: {
        type: 'static',
        value: 'Download The App'
      }
    }
  ]
};
