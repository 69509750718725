/* eslint-disable no-restricted-globals */

import React from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { FORM_ERRORS } from '../../constants';

function DatePicker({
  field,
  form,
  label,
  placeholder,
  // initialValue, //TODO: Remove?
  // disablePast,  //TODO: Remove?
  minDate,
  maxDate,
  value
  // required,  //TODO: Remove?
  // ...rest  //TODO: Remove?
}) {
  const currentError = form.errors[field.name];

  const upperCaseLabel = label;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        autoOk
        disabled={form.isSubmitting}
        margin="normal"
        inputVariant="outlined"
        name={field.name}
        id={field.id}
        value={field.value || value}
        helperText={null}
        placeholder={placeholder}
        format="MM/dd/yyyy"
        variant="dialog"
        label={upperCaseLabel}
        minDate={minDate}
        keyboardIcon={
          <img
            src={`${process.env.REACT_APP_NOBLR_CDN}/icons/calendar-icon.svg`}
            alt=""
            className="datePicker"
          />
        }
        minDateMessage={FORM_ERRORS.invalidPolicyStartDate}
        maxDate={maxDate}
        maxDateMessage={FORM_ERRORS.invalidPolicyStartDate}
        // TODO: Move setFieldTouched to onClose
        onOpen={() => form.setFieldTouched(field.name, true)}
        onError={error => {
          if (error === currentError) {
            return;
          }

          if (error.length) {
            form.setFieldError(field.name, error);
          }
        }}
        onChange={date => {
          if (!form.touched[field.name]) {
            form.setFieldTouched(field.name, true);
          }

          // TODO: Refactor and use date-fns utilities to parse and validate
          return !isNaN(Date.parse(date))
            ? form.setFieldValue(field.name, format(date, 'MM/dd/yyyy'), true)
            : form.setFieldValue(field.name, date, true);
        }}
        onAccept={date => {
          if (!form.touched[field.name]) {
            form.setFieldTouched(field.name, true);
          }

          // TODO: Refactor and use date-fns utilities to parse and validate
          return !isNaN(Date.parse(date))
            ? form.setFieldValue(field.name, format(date, 'MM/dd/yyyy'), true)
            : form.setFieldValue(field.name, date, true);
        }}
        // override field input type so browser date picker doesn't display
        type="text"
        KeyboardButtonProps={{
          'aria-label': 'Select an effective date'
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
