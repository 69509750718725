export const NO_REDIRECT_PATHS = {
  '/start-quote/name': true,
  '/start-quote/lets-go': true,
  '/start-quote/referral': true,
  '/start-quote/welcome': true
};

export const NO_REDIRECT_SECTIONS = [
  'quote',
  'purchase',
  'account',
  'set-up-account',
  'driver-exclusion'
];

export const DEFAULT_MAX_WIDTH = '670px';

export const GENDER_OPTIONS = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
  { label: 'Non-binary', value: 'NON_BINARY' }
];

export const GENDER_VALUES = GENDER_OPTIONS.map(({ value }) => value);

export const EDUCATION_LEVEL_OPTIONS = [
  {
    value: 'No high school degree',
    label: 'No high school degree'
  },
  {
    value: 'High school degree or GED',
    label: 'High school degree or GED'
  },
  {
    value: 'Vocational/trade school degree',
    label: 'Vocational/trade school degree'
  },
  { value: 'Military training', label: 'Military training' },
  { value: 'Some college', label: 'Some college' },
  {
    value: 'Currently in college',
    label: 'Currently in college'
  },
  { value: 'College degree', label: 'College degree' },
  {
    value: 'Graduate degree',
    label: 'Graduate work or graduate degree'
  }
];

export const EDUCATION_LEVEL_VALUES = EDUCATION_LEVEL_OPTIONS.map(
  ({ value }) => value
);

export const HOME_OWNER_OPTIONS = [
  { value: 'Rent', label: 'Rent' },
  { value: 'Own', label: 'Own' },
  { value: 'Other', label: 'Other' }
];

export const HOME_OWNER_VALUES = HOME_OWNER_OPTIONS.map(({ value }) => value);

export const MARITAL_STATUS_OPTIONS = [
  { value: 'Single', label: 'Single' },
  { value: 'Married', label: 'Married' },
  { value: 'Divorced', label: 'Divorced' },
  { value: 'Separated', label: 'Separated' },
  { value: 'Widowed', label: 'Widowed' }
];

export const MARITAL_STATUS_VALUES = MARITAL_STATUS_OPTIONS.map(
  ({ value }) => value
);

// todo: replace this with options from fe-tools
export const vehicleOwnershipYears = [
  { value: 'LESS_THAN_ONE_YEAR', label: 'Less than 1 year', cutOff: -10 },
  { value: 'ONE_TO_TWO_YEARS', label: '1 to 2 years', cutOff: 0 },
  { value: 'THREE_TO_FOUR_YEARS', label: '3 to 4 years', cutOff: 2 },
  { value: 'FIVE_OR_MORE_YEARS', label: '5 or more years', cutOff: 4 }
];

export const currentAutoInsuranceCarrierOptions = [
  { value: 'Not Insured', label: 'Not Insured' },
  { value: '21st Century', label: '21st Century' },
  { value: 'AAA', label: 'AAA' },
  { value: 'AIG', label: 'AIG' },
  { value: 'Access Insurance', label: 'Access Insurance' },
  { value: 'Alfa', label: 'Alfa' },
  { value: 'Allied', label: 'Allied' },
  { value: 'Allstate', label: 'Allstate' },
  {
    value: 'American Alliance Insurance',
    label: 'American Alliance Insurance'
  },
  {
    value: 'American Automobile Insurance',
    label: 'American Automobile Insurance'
  },
  { value: 'American Family', label: 'American Family' },
  { value: 'Ameriprise', label: 'Ameriprise' },
  { value: 'Amica', label: 'Amica' },
  { value: 'Anchor General', label: 'Anchor General' },
  { value: 'Auto-Owners', label: 'Auto-Owners' },
  { value: 'Bristol West', label: 'Bristol West' },
  { value: 'Commerce West', label: 'Commerce West' },
  {
    value: 'Country Insurance and Financial Services',
    label: 'Country Insurance and Financial Services'
  },
  { value: 'Countrywide', label: 'Countrywide' },
  { value: 'Cure', label: 'Cure' },
  { value: 'Dairyland Insurance', label: 'Dairyland Insurance' },
  { value: 'Direct General', label: 'Direct General' },
  { value: 'Elephant', label: 'Elephant' },
  { value: 'Equity Auto Insurance', label: 'Equity Auto Insurance' },
  { value: 'Erie Insurance', label: 'Erie Insurance' },
  { value: 'Esurance', label: 'Esurance' },
  { value: 'Explorer', label: 'Explorer' },
  {
    value: 'Farm Bureau/Farm Family/Rural',
    label: 'Farm Bureau/Farm Family/Rural'
  },
  { value: 'Farmers', label: 'Farmers' },
  { value: 'First Acceptance', label: 'First Acceptance' },
  { value: 'Foremost', label: 'Foremost' },
  { value: 'Fred Loya', label: 'Fred Loya' },
  { value: 'Freeway Insurance', label: 'Freeway Insurance' },
  { value: 'GEICO', label: 'GEICO' },
  { value: 'GMAC', label: 'GMAC' },
  { value: 'Gainsco', label: 'Gainsco' },
  { value: 'Grange', label: 'Grange' },
  { value: 'Hallmark', label: 'Hallmark' },
  { value: 'Hanover', label: 'Hanover' },
  { value: 'High Point Insurance', label: 'High Point Insurance' },
  { value: 'IFA Auto Insurance', label: 'IFA Auto Insurance' },
  { value: 'Imperial Fire & Casualty', label: 'Imperial Fire & Casualty' },
  { value: 'Infinity Insurance', label: 'Infinity Insurance' },
  { value: 'Insurance Depot', label: 'Insurance Depot' },
  { value: 'InsureOne', label: 'InsureOne' },
  { value: 'Kemper', label: 'Kemper' },
  { value: 'Liberty Mutual', label: 'Liberty Mutual' },
  { value: 'Mendota', label: 'Mendota' },
  { value: 'Mercury', label: 'Mercury' },
  { value: 'MetLife', label: 'MetLife' },
  { value: 'National Insurance', label: 'National Insurance' },
  { value: 'Nationwide', label: 'Nationwide' },
  { value: 'Ocean Harbor', label: 'Ocean Harbor' },
  { value: 'Omni Insurance', label: 'Omni Insurance' },
  { value: 'Other', label: 'Other' },
  { value: 'Peachtree', label: 'Peachtree' },
  {
    value: 'Peak Property and Casualty Insurance',
    label: 'Peak Property and Casualty Insurance'
  },
  { value: 'Permanent General', label: 'Permanent General' },
  { value: 'Plymouth Rock', label: 'Plymouth Rock' },
  { value: 'Progressive', label: 'Progressive' },
  { value: 'Pronto', label: 'Pronto' },
  { value: 'SafeAuto', label: 'SafeAuto' },
  { value: 'Safeco', label: 'Safeco' },
  { value: 'Safeway Insurance', label: 'Safeway Insurance' },
  { value: 'Sante Fe', label: 'Sante Fe' },
  { value: 'Shelter', label: 'Shelter' },
  { value: 'State Farm', label: 'State Farm' },
  { value: 'The General', label: 'The General' },
  { value: 'The Hartford', label: 'The Hartford' },
  { value: 'Titan', label: 'Titan' },
  { value: 'Travelers', label: 'Travelers' },
  { value: 'UAIC', label: 'UAIC' },
  { value: 'US Agencies', label: 'US Agencies' },
  { value: 'USAA', label: 'USAA' },
  { value: 'United Insurance', label: 'United Insurance' },
  { value: 'Unitrin Direct', label: 'Unitrin Direct' },
  { value: 'Victoria', label: 'Victoria' },
  { value: 'Viking Insurance', label: 'Viking Insurance' },
  { value: 'Wawanesa Mutual', label: 'Wawanesa Mutual' },
  { value: 'Windhaven', label: 'Windhaven' }
];

export const SLIDER_CONFIG = {
  personaCode: {
    marks: [{ value: 1 }, { value: 2 }],
    min: 0,
    max: 2.99,
    step: 0.1,
    defaultValue: 1.5
  },
  personaCodeQuote: {
    marks: [{ value: 1 }, { value: 2 }],
    min: 0,
    max: 2.99,
    step: 0.1
  },
  mileage: {
    marks: [
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 20 },
      { value: 25 },
      { value: 30 },
      { value: 40 },
      { value: 50 },
      { value: 60 },
      { value: 70 }
    ],
    min: 1,
    max: 70,
    defaultValue: 20,
    // null uses marks for steps https://material-ui.com/components/slider/#restricted-values
    step: null
  }
};

export const militaryDiscountLink =
  'https://noblr-website-content.s3-us-west-2.amazonaws.com/Louisiana+Application+For+Military+Discount_Blank_PDF.pdf';

export const SALES_FORCE_GATES = {
  'start-quote': {
    lastSection: null,
    nextSection: 'add-drivers',
    gate: ''
  },
  'add-drivers': {
    lastSection: 'start-quote',
    nextSection: 'add-vehicles',
    gate: null
  },
  'add-vehicles': {
    lastSection: 'add-drivers',
    nextSection: 'driving-behavior',
    gate: 'DRIVERS_COMPLETE'
  },
  'driving-behavior': {
    lastSection: 'add-vehicles',
    nextSection: 'quote',
    gate: 'VEHICLES_COMPLETE'
  },
  quote: {
    lastSection: 'driving-behavior',
    nextSection: 'purchase',
    gate: 'COVERAGES_DEDUCTIBLES_COMPLETE'
  },
  purchase: {
    lastSection: 'quote',
    nextSection: 'account',
    gate: null
  }
};

export const pennDotLink =
  'https://www.dmv.pa.gov/Driver-Services/Mature-Drivers/Pages/Mature-Driver-Improvement-Course.aspx';

export const PREBIND_FORM_DICT = {
  prebindApplication: 'APPLICATION',
  membershipApplication: 'MSA',
  umuimOfferForm: 'UMUIM_OFFER_FORM',
  umbiSelection: 'UMBI_SELECTION',
  uimbiSelection: 'UIMBI_SELECTION',
  umSelection: 'UM_SELECTION',
  pipWaiver: 'PIP_WAIVER',
  tortOptions: 'TORT_OPTIONS',
  medPayForm: 'MEDPAY_OFFER_FORM'
};

export const BASE_COOKIE_HEADER_CONFIG = {
  path: '/'
};

export const SECURE_COOKIE_HEADER_CONFIG = {
  ...BASE_COOKIE_HEADER_CONFIG,
  secure: true,
  sameSite: 'lax'
};

export const EXPIRES_COOKIE_HEADER_CONFIG = {
  ...BASE_COOKIE_HEADER_CONFIG,
  expires: 'Thu, 01 Jan 1970 00:00:01 GMT'
};

export const DEFAULT_IDLE_TIMEOUT = 1000 * 60 * 30; // IdleTimer runs for 30 min
export const CONFIRM_IDLE_TIMEOUT = 1000 * 60; // Show inactive Modal for 60 sec

export const PAUSED_IDLE_TIMER_PAGES = {
  '/start-quote/name': true,
  '/start-quote/lets-go': true,
  '/start-quote/referral': true,
  '/start-quote/welcome': true,
  '/quote/retrieve-your-quote': true,
  '/quote/not-found': true,
  '/purchase/thank-you': true,
  '/account/setup-account': true,
  '/account/setup-trial': true,
  '/account/sign-in': true,
  '/account/confirm-email': true,
  '/account/code-entry': true,
  '/account/create-password': true,
  '/account/download-app': true,
  '/account/need-help': true,
  '/dnq': true,
  '/dnq/agency': true,
  '/dnq/detailed': true,
  '/dnq/usaa': true,
  '/call-to-complete': true
};

export const COVERAGES_MAP = {
  bi: {
    title: 'Bodily Injury Limits',
    description: 'In case you’re at fault and someone is hurt'
  },
  pd: {
    title: 'Property Damage Limit',
    description: 'In case you’re at fault and something is damaged'
  },
  umuim: {
    title: 'Uninsured Motorist Bodily Injury Limits',
    description: 'If an uninsured or underinsured motorist is involved'
  },
  umbi: {
    title: 'Uninsured Motorist Bodily Injury Limits',
    description: 'If an uninsured motorist is involved'
  },
  umuimbi: {
    title: 'Uninsured Motorist Bodily Injury Limits',
    description:
      'Bodily injury if an uninsured or underinsured motorist is involved'
  },
  umuimpd: {
    title: 'Uninsured Motorist Property Damage Limits',
    description: 'Property damage if an uninsured motorist is involved'
  },
  uimbi: {
    title: 'Underinsured Motorist Bodily Injury Limits',
    description: 'If an underinsured motorist is involved'
  },
  pip: {
    title: 'Personal Injury Protection Limit',
    description: 'In case you’re injured and need medical attention'
  },

  med: {
    title: 'Medical Payments Limit',
    description: "In case you're injured and need medical attention"
  }
};

export const NO_COVERAGE_STRING = 'No Coverage';

export const ELIGIBILITY_PAGE_INFOBOX_CONTENT = {
  start: 'In order to purchase a Noblr policy, you must qualify for ',
  middle: 'USAA membership',
  end: ' . Your USAA eligibility will be verified within two weeks after purchase. If we are unable to verify your policyholder details, your policy will be canceled.',
  member:
    'If you are currently a USAA member and you have an active USAA auto policy only, please call USAA at '
};

export const USAA_SIGNATURE_START_DISCLAIMER =
  'Your USAA eligibility will be verified within two weeks after purchase.  If we are unable to verify your policyholder details, your policy will be canceled.';

export const DEFAULT_REFUSAL_TO_WRITE_TEXT = `Would you want us to email you a copy of the "Notice of Refusal to Write" which includes the reasons we've already discussed why we're unable to give you a quote?`;

export const REFUSAL_TO_WRITE_REQUEST_TEXT =
  'To request more information that outlines the specific reason why we were unable to accommodate your request for car insurance at this time, also called a "Notice of Refusal to Write", please give us a call at (877) 236-6257';

export const FORM_ERRORS = {
  invalidFullName: 'We need your first and last name.',
  invalidFirstName: 'Check to make sure this is entered correctly.',
  invalidLastName: 'Check to make sure this is entered correctly.',
  invalidEmail: "Hmmm... this email address doesn't look right.",
  invalidBirthday: 'Birth date should look like this: MM/DD/YYYY.',
  underAgeUser:
    'You must be at least 18 to purchase your own policy. If you are younger than 18 you may purchase if a parent or guardian co-signs. Please call (877) 236-6257 for further details.',
  outOfRangeBirthday: 'Check to make sure this is entered correctly.',
  ageLicensed: 'Please make a selection to continue.',
  invalidStreetAddress: 'Please enter a valid street address.',
  invalidStreet2: 'Please enter a valid apartment or unit number.',
  invalidCity: "Hmmm... this city name doesn't look right",
  invalidState: 'Please enter a valid state code',
  invalidZipCode: "Hmmm… this zip code doesn't look right.",
  license: 'Check to make sure this is entered correctly.',
  duplicate_license: "This driver's license is already added to your quote.",
  payment: {
    cardNum: {
      invalid: 'Invalid card number'
    },
    cardCvv: {
      invalid: 'Invalid CVV',
      tooShort: 'CVV entered is too short'
    },
    cardExp: {
      invalid: 'Expiration date should look like this: MM/YY',
      tooShort: 'Expiration date should look like this: MM/YY'
    },
    zipCode: {
      invalid: "Hmmm… this zip code doesn't look right.",
      tooShort: "Hmmm… this zip code doesn't look right."
    }
  },
  invalidPhone: 'Your phone number should look like this: 555 555-5555',
  invalidVin: "Double-check that you've entered the full 17 characters.",
  invalidPolicyStartDate: 'Please select a valid date',
  password: "Hmmm... this password doesn't look right.",
  passwordTooShort: 'Your password should have at least 6 characters',
  // state specific license error messages
  CO: 'CO licenses are 9 numbers or begin with 1 letter.',
  NM: 'NM licenses must be 9 numbers.',
  OH: 'OH licenses are 2 letters and 6 numbers.',
  PA: 'PA licenses must be 8 numbers.',
  TX: 'TX licenses must be 8 numbers, starting with 0-4.',
  UT: 'UT licenses must be 4-10 numbers.'
};
