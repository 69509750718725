import React from 'react';
import styled from 'styled-components/macro';

function Separator() {
  return (
    <StyledSeparator className="separator" aria-hidden="true" tabIndex={-1}>
      <img
        src={`${process.env.REACT_APP_NOBLR_CDN}/icons/dark-squiggles.svg`}
        alt=""
      />
    </StyledSeparator>
  );
}

const StyledSeparator = styled.div`
  margin: 40px auto;
  clear: both;
  display: block;
  width: 135px;
  background-color: white;

  img {
    display: block;
    width: 100%;
    color: black;
  }
`;

export default Separator;
