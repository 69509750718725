import React from 'react';
import styled from 'styled-components/macro';
import { colors, brandonGrotesque, breakPoints } from 'styles/variables';

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin-top: 40px;
  border-top: 1px solid ${colors.usaaLightGrey};
`;

const TextContainer = styled.div`
  padding: 20px 10px 0;
  max-width: 460px;
  margin: 0 auto;
  @media screen and (min-width: ${breakPoints.large}) {
    padding: 20px 20px 0;
  }
`;

const StyledText = styled.p`
  text-align: center;
  font-family: ${brandonGrotesque};
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.29px;
  margin-bottom: 30px;
  span.currency {
    font-weight: bold;
  }
`;

function PaymentDetails({ selectedReward }) {
  return (
    <ContentWrapper>
      <TextContainer>
        <StyledText>
          Earn <span className="currency">{`${selectedReward}`}</span> in
          rewards to apply towards the variable portion of your rate
        </StyledText>
      </TextContainer>
    </ContentWrapper>
  );
}

export default PaymentDetails;
