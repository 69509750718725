import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { maskVIN } from 'utilities/vehicles';
import {
  selectPrimarilyDrivenAssignmentDetails,
  selectPrimarilyDrivenBy,
  makeSelectActiveDrivers,
  makeSelectActiveVehicles,
  makeSelectConfirmedExcludedDrivers,
  makeSelectConfirmedAdditionalDrivers
} from 'redux/selectors';
import { colors } from 'styles';
import { SummaryText, StyledCoverageTitle } from '../styles';

const BoldText = styled.span`
  font-weight: bold;
`;

const Wrapper = styled.div`
  text-align: left;
`;

const DriversSection = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${colors.usaaLightestGrey};
`;

function CarsAndDrivers(props) {
  const {
    vehicles,
    drivers: { primaryDriver },
    carAndDriverDetails,
    additionalDrivers,
    primarilyDrivenByExists,
    requiresVehicleAssignment,
    section,
    excludedDrivers
  } = props;

  const isPurchaseFlow = section === 'purchase';
  const displayExcludedDrivers = isPurchaseFlow && excludedDrivers.length > 0;

  const {
    firstName: primaryDriverFirstName,
    lastName: primaryDriverLastName,
    age: primaryDriverAge,
    dlNumber: primaryDriverDLNumber
  } = primaryDriver;

  const primaryDriverFullName = `${primaryDriverFirstName} ${primaryDriverLastName}`;

  return (
    <Wrapper>
      <StyledCoverageTitle marginTop>Cars</StyledCoverageTitle>
      {requiresVehicleAssignment && primarilyDrivenByExists
        ? carAndDriverDetails.map(details => {
            const {
              driverNames,
              vehicle: { year, make, model, vin, vehicleId, hasPrimaryDriver }
            } = details;

            const maskedVin = maskVIN(vin);

            return (
              <Fragment key={vin}>
                <SummaryText>
                  <BoldText>
                    {year} {make} {model}
                  </BoldText>
                </SummaryText>
                <SummaryText extraMargin={!hasPrimaryDriver}>
                  {maskedVin && `VIN: ${maskedVin}`}
                </SummaryText>
                {driverNames ? (
                  <SummaryText extraMargin>
                    Primarily Driven By:{' '}
                    {/* if we have more than one driver and we aren't on the last one yet
                      add an '&' between the names */}
                    {driverNames.map(
                      ({ firstName, lastName, driverId }, index) => (
                        <span key={`${driverId}-${vehicleId}`}>
                          {`${firstName} ${lastName} ${
                            index !== driverNames.length - 1 &&
                            driverNames.length > 1
                              ? ` & `
                              : ''
                          }`}
                        </span>
                      )
                    )}
                  </SummaryText>
                ) : (
                  hasPrimaryDriver && (
                    <SummaryText extraMargin>
                      {`Primarily Driven By: ${primaryDriverFullName}`}
                    </SummaryText>
                  )
                )}
              </Fragment>
            );
          })
        : vehicles.map(({ year, make, model, vin }) => {
            const maskedVin = maskVIN(vin);

            return (
              <Fragment key={vin}>
                <SummaryText>
                  <BoldText>
                    {year} {make} {model}
                  </BoldText>
                </SummaryText>
                {vin && <SummaryText>VIN:{maskedVin}</SummaryText>}
              </Fragment>
            );
          })}
      <DriversSection>
        <StyledCoverageTitle>Drivers</StyledCoverageTitle>
        <Fragment key={primaryDriverDLNumber}>
          <SummaryText>
            <BoldText>{primaryDriverFullName}</BoldText>
          </SummaryText>
          <SummaryText extraMargin>{`Age: ${primaryDriverAge}`}</SummaryText>
        </Fragment>
        {additionalDrivers.length > 0 &&
          additionalDrivers.map(({ firstName, lastName, age, driverId }) => {
            const fullName = `${firstName} ${lastName}`;

            return (
              <Fragment key={driverId}>
                <SummaryText>
                  <BoldText>{fullName}</BoldText>
                </SummaryText>
                <SummaryText extraMargin>{`Age: ${age}`}</SummaryText>
              </Fragment>
            );
          })}
        {displayExcludedDrivers && (
          <StyledCoverageTitle>Excluded Drivers</StyledCoverageTitle>
        )}
        {displayExcludedDrivers &&
          excludedDrivers.map(({ age, firstName, lastName, driverId }) => {
            const fullName = `${firstName} ${lastName}`;

            return (
              <Fragment key={driverId}>
                <SummaryText>
                  <BoldText>{fullName}</BoldText>
                </SummaryText>
                <SummaryText extraMargin>{`Age: ${age}`}</SummaryText>
              </Fragment>
            );
          })}
      </DriversSection>
    </Wrapper>
  );
}

const mapStateToProps = () => {
  const selectActiveDrivers = makeSelectActiveDrivers();
  const selectActiveVehicles = makeSelectActiveVehicles();
  const selectConfirmedExcludedDrivers = makeSelectConfirmedExcludedDrivers();
  const selectConfirmedAdditionalDrivers =
    makeSelectConfirmedAdditionalDrivers();

  const realMapState = state => {
    const {
      app: { requiresVehicleAssignment, section },
      vehicles,
      drivers,
      excludedDrivers: { completedDrivers }
    } = state;

    return {
      drivers,
      requiresVehicleAssignment,
      section,
      carAndDriverDetails: selectPrimarilyDrivenAssignmentDetails({
        drivers,
        vehicles
      }),
      primarilyDrivenByExists: selectPrimarilyDrivenBy(state),
      excludedDrivers: selectConfirmedExcludedDrivers(state),
      additionalDrivers:
        completedDrivers.length > 0 && section === 'purchase'
          ? selectConfirmedAdditionalDrivers(state)
          : selectActiveDrivers(state),
      vehicles: selectActiveVehicles(state)
    };
  };

  return realMapState;
};

export default connect(mapStateToProps)(CarsAndDrivers);
