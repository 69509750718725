import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';
import {
  makeSelectPrimaryDriver,
  makeSelectEditingDriver,
  makeSelectRequiresDriverExclusion
} from '../../redux/selectors';
import { GENDER_OPTIONS } from '../../constants';

class GenderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt: '',
      submitAction: '',
      fields: [
        {
          type: 'select',
          name: 'genderMapping',
          id: 'gender-select-input',
          selectLabel: 'Gender',
          selectLabelId: 'gender-select-label',
          options: GENDER_OPTIONS,
          required: true
        }
      ],
      successRoute: null
    };
  }

  componentDidMount() {
    const { section, primaryDriver, editingDriver, requiresDriverExclusion } =
      this.props;

    const isStartQuote = section === 'start-quote';

    const driver = isStartQuote ? primaryDriver : editingDriver;

    const confirmGender = driver.genderMapping || driver.gender;

    const genderPrompt = `Please confirm ${
      section === 'start-quote' ? 'your' : "this driver's"
    } gender.`;

    const defaultPrompt = isStartQuote
      ? "What's your gender?"
      : "What's this driver's gender?";

    const currentSuccessRoute = `/${
      isStartQuote ? 'start-quote' : 'add-drivers'
    }/${
      !isStartQuote && requiresDriverExclusion ? 'verify-active-' : ''
    }driver-license`;

    const currentPrompt = confirmGender ? genderPrompt : defaultPrompt;

    const currentSubmitAction = isStartQuote
      ? 'UPDATE_PRIMARY_DRIVER_REQUEST'
      : 'UPDATE_ADDITIONAL_DRIVER_INFO';

    this.setState(prevState => ({
      fields: prevState.fields.map(field =>
        // only field is gender
        ({
          ...field,
          options:
            primaryDriver.state === 'CO'
              ? [GENDER_OPTIONS[0], GENDER_OPTIONS[1]]
              : GENDER_OPTIONS,
          initialValue: driver[field.name]
        })
      ),
      prompt: currentPrompt,
      successRoute: currentSuccessRoute,
      submitAction: currentSubmitAction
    }));
  }

  render() {
    const { editingDriver, section } = this.props;
    const { prompt, submitAction, fields, successRoute } = this.state;

    return section === 'add-drivers' && !editingDriver.firstName ? (
      <Redirect to="/add-drivers/drivers-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = () => {
  const selectPrimaryDriver = makeSelectPrimaryDriver();
  const selectEditingDriver = makeSelectEditingDriver();
  const selectRequiresDriverExclusion = makeSelectRequiresDriverExclusion();

  const realMapState = state => {
    return {
      requiresDriverExclusion: selectRequiresDriverExclusion(state),
      primaryDriver: selectPrimaryDriver(state),
      editingDriver: selectEditingDriver(state)
    };
  };

  return realMapState;
};

export default connect(mapStateToProps)(GenderPage);
