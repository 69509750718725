/* eslint-disable default-param-last */
import {
  UPDATE_POLICY,
  SET_INSTALLMENT_SCHEDULE,
  SET_INSTALLMENT_SCHEDULE_SUCCESS,
  SET_INSTALLMENT_SCHEDULE_FAILURE,
  SET_CARD_TYPE,
  BIND_QUOTE,
  BIND_QUOTE_SUCCESS,
  BIND_QUOTE_FAILURE,
  GET_INSTALLMENT_SCHEDULE_SUCCESS,
  GET_INSTALLMENT_SCHEDULE_FAILURE,
  UPDATE_TO_TRIAL_SUCCESS,
  UPDATE_TO_TRIAL_FAIL,
  UPDATE_PHONE_AND_EMAIL,
  UPDATE_PHONE_AND_EMAIL_SUCCESS,
  UPDATE_PHONE_AND_EMAIL_FAILURE,
  SEND_REFUSAL_TO_WRITE_EMAIL,
  SEND_REFUSAL_TO_WRITE_EMAIL_SUCCESS,
  SEND_REFUSAL_TO_WRITE_EMAIL_FAILURE,
  REQUEST_PREBIND_PDF,
  REQUEST_PREBIND_PDF_SUCCESS,
  REQUEST_PREBIND_PDF_FAILURE,
  SUBMIT_PREBIND_SIGNATURE,
  SUBMIT_PREBIND_SIGNATURE_SUCCESS,
  SUBMIT_PREBIND_SIGNATURE_FAILURE,
  CLEAR_PDF,
  SET_PENDING_FORMS,
  GET_DYNAMIC_PRE_BIND_FORM_BY_ID,
  GET_DYNAMIC_PRE_BIND_FORM_BY_ID_SUCCESS,
  GET_DYNAMIC_PRE_BIND_FORM_BY_ID_FAILURE,
  SUBMIT_FORM_ID_SIGNATURE,
  SUBMIT_FORM_ID_SIGNATURE_SUCCESS,
  SUBMIT_FORM_ID_SIGNATURE_FAILURE,
  REQUEST_DYNAMIC_PRE_BIND_FORMS,
  REQUEST_DYNAMIC_PRE_BIND_FORMS_SUCCESS,
  REQUEST_DYNAMIC_PRE_BIND_FORMS_FAILURE
} from '../actions';

const INITIAL_POLICY_STATE = {
  state: '',
  noblrPaymentPlanName: 'INSTALLMENTS',
  businessType: 'New Business',
  producerCode: 11111,
  firstPossibleEffectiveDate: '',
  lastPossibleEffectiveDate: '',
  payInFullPrice: '',
  installmentPrice: '',
  membersContribution: '',
  payInFullReward: '',
  installmentReward: '',
  paymentPlanNames: ['INSTALLMENTS', 'PAID_IN_FULL'],
  isSaving: false,
  prebindApplication: '',
  membershipApplication: '',
  umuimOfferForm: '',
  uimbiSelection: '',
  umbiSelection: '',
  umSelection: '',
  pipWaiver: '',
  tortOptions: '',
  purchased: false,
  trialActive: false,
  paymentPlanSaved: false,
  trialConverted: null,
  policyId: null,
  refusalToWriteSent: false,
  requiresUmuimOfferForm: false,
  customPreBindForms: {
    allIds: [],
    byId: {}
  },
  requestingPreBindForm: null,
  pendingFormIds: null,
  currentFormId: null,
  nextFormId: null,
  policyEffectiveDate: '',
  paymentScheduleMap: {}
};

const policyReducer = (state = INITIAL_POLICY_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_POLICY:
      return {
        ...state,
        ...payload
      };

    case SET_PENDING_FORMS:
      return {
        ...state,
        pendingFormIds: payload.pendingFormIds,
        currentFormId: payload.currentFormId,
        nextFormId: payload.nextFormId,
        customPreBindForms: payload.customPreBindForms
      };

    case SET_INSTALLMENT_SCHEDULE:
      return {
        ...state,
        isSaving: true
      };

    case BIND_QUOTE:
      return {
        ...state,
        isSaving: true,
        error: null
      };

    case BIND_QUOTE_SUCCESS:
      return {
        ...state,
        ...payload,
        isSaving: false,
        purchased: true
      };

    case BIND_QUOTE_FAILURE:
      return {
        ...state,
        purchased: false,
        isSaving: false
      };

    case UPDATE_TO_TRIAL_SUCCESS:
      return {
        ...state,
        trialActive: true,
        policyId: payload.policyId,
        trialConverted: false
      };

    case UPDATE_TO_TRIAL_FAIL:
      return {
        ...state,
        trialActive: false,
        trialConverted: false
      };

    case SET_INSTALLMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        ...payload,
        isSaving: false,
        paymentPlanSaved: true
      };

    case SET_INSTALLMENT_SCHEDULE_FAILURE:
      return {
        ...state,
        isSaving: false,
        paymentPlanSaved: false,
        error: action.error
      };

    case GET_INSTALLMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        ...payload
      };

    case GET_INSTALLMENT_SCHEDULE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case SET_CARD_TYPE:
      return {
        ...state,
        ...payload
      };

    case UPDATE_PHONE_AND_EMAIL:
      return {
        ...state,
        isSaving: true
      };

    case UPDATE_PHONE_AND_EMAIL_SUCCESS:
      return {
        ...state,
        isSaving: false
      };

    case UPDATE_PHONE_AND_EMAIL_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.error
      };

    case SEND_REFUSAL_TO_WRITE_EMAIL:
      return {
        ...state
      };

    case SEND_REFUSAL_TO_WRITE_EMAIL_SUCCESS:
      return {
        ...state,
        refusalToWriteSent: true
      };

    case SEND_REFUSAL_TO_WRITE_EMAIL_FAILURE:
      return {
        ...state,
        refusalToWriteSent: false
      };

    case REQUEST_PREBIND_PDF:
      return {
        ...state,
        [payload]: {
          pdfURL: null,
          signed: false,
          pdfName: payload
        },
        requestingPreBindForm: true,
        error: null
      };

    case REQUEST_PREBIND_PDF_SUCCESS:
      return {
        ...state,
        [payload.pdfName]: {
          ...state[payload.pdfName],
          ...payload
        },
        requestingPreBindForm: false
      };

    case REQUEST_PREBIND_PDF_FAILURE:
      return {
        ...state,
        requestingPreBindForm: false,
        error: action.error
      };

    case GET_DYNAMIC_PRE_BIND_FORM_BY_ID:
      return {
        ...state,
        [payload.pdfName]: {
          pdfURL: null,
          signed: false,
          pdfName: payload.pdfName,
          formId: payload.formId
        },
        requestingPreBindForm: true,
        error: null
      };

    case GET_DYNAMIC_PRE_BIND_FORM_BY_ID_SUCCESS:
      return {
        ...state,
        [payload.pdfName]: {
          ...state[payload.pdfName],
          ...payload
        },
        requestingPreBindForm: false
      };

    case GET_DYNAMIC_PRE_BIND_FORM_BY_ID_FAILURE:
      return {
        ...state,
        requestingPreBindForm: false,
        error: action.error
      };

    case SUBMIT_PREBIND_SIGNATURE:
      return {
        ...state,
        submittingSignature: true
      };

    case SUBMIT_PREBIND_SIGNATURE_SUCCESS:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          pdfURL: null,
          signed: true
        },
        submittingSignature: false
      };

    case SUBMIT_PREBIND_SIGNATURE_FAILURE:
      return {
        ...state,
        submittingSignature: false,
        error: action.error
      };

    case SUBMIT_FORM_ID_SIGNATURE:
      return {
        ...state,
        submittingSignature: true
      };

    case SUBMIT_FORM_ID_SIGNATURE_SUCCESS:
      return {
        ...state,
        [payload.pdfName]: {
          ...state[payload.pdfName],
          pdfURL: null,
          signed: true
        },
        submittingSignature: false
      };

    case SUBMIT_FORM_ID_SIGNATURE_FAILURE:
      return {
        ...state,
        submittingSignature: false,
        error: action.error
      };

    case CLEAR_PDF:
      return {
        ...state,
        [payload]: {
          ...state[payload],
          pdfURL: null
        }
      };

    case REQUEST_DYNAMIC_PRE_BIND_FORMS:
      return {
        ...state,
        requestingPreBindForm: true,
        customPreBindForms: {},
        error: null
      };

    case REQUEST_DYNAMIC_PRE_BIND_FORMS_SUCCESS:
      return {
        ...state,
        requestingPreBindForm: false,
        customPreBindForms: {
          ...state.customPreBindForms,
          allIds: [...payload.customPreBindForms.allIds],
          byId: { ...payload.customPreBindForms.byId }
        }
      };

    case REQUEST_DYNAMIC_PRE_BIND_FORMS_FAILURE:
      return {
        ...state,
        requestingPreBindForm: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default policyReducer;
