import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Box } from '@material-ui/core';
import Button from './Buttons/Button';

const StyledDialogContent = withStyles(theme => ({
  root: {
    ...theme.root
  }
}))(DialogContent);

const StyledDialogActions = withStyles(theme => ({
  root: {
    flexDirection: 'column',
    padding: '5px',
    marginTop: '30px',
    ...theme.root
  }
}))(DialogActions);

const ModalClose = styled.button`
  margin-right: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: 0;
  background: none;

  img {
    width: 20px;
  }

  &:focus {
    outline: none;
  }
`;

export default function Modal(props) {
  const {
    modalLabel,
    children = null,
    showCloseButton = false,
    modalName,
    open
  } = props;

  const dispatch = useDispatch();

  const isOpen = useSelector(state => state.app.isModalOpen);
  const openModalName = useSelector(state => state.app.openModalName);

  const handleClose = () => {
    const { onClose = null } = props;

    if (onClose) {
      onClose();
    }
    dispatch({ type: 'CLOSE_MODAL' });
  };

  return (
    <Dialog
      onClose={handleClose}
      id={modalLabel}
      aria-labelledby={modalLabel}
      aria-describedby="modalDialog"
      open={(open || isOpen) && modalName === openModalName}
      scroll="paper"
      maxWidth="sm"
    >
      <Box p={{ sm: 1.25, md: 2.5 }}>
        <StyledDialogContent>
          <ModalClose onClick={handleClose}>
            <img
              src={`${process.env.REACT_APP_NOBLR_CDN}/icons/close-icon.svg`}
              alt="Close Modal"
            />
          </ModalClose>
          {children && children}
          <StyledDialogActions disableSpacing>
            {showCloseButton && (
              <Button
                large
                noMargin
                primary
                type="button"
                onClick={handleClose}
              >
                Close
              </Button>
            )}
          </StyledDialogActions>
        </StyledDialogContent>
      </Box>
    </Dialog>
  );
}
