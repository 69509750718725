import * as Sentry from '@sentry/react';
import Noblr from '@noblr-lab/react-app-services';

let appStore = null;

export const injectAsyncUtilitiesStore = _store => {
  appStore = { ..._store };

  return appStore;
};

// TODO: Refactor function into Redux middleware
const startAsyncRequest = request => {
  const {
    app: { form }
  } = appStore.getState();

  if (form && form.error) {
    appStore.dispatch({ type: 'CLEAR_FORM_ERROR' });
  }
  appStore.dispatch({
    type: 'START_ASYNC_REQUEST',
    payload: request
  });
};

// TODO: Refactor function into Redux middleware
const setAsyncStatus = response => {
  if ((response.status && response.status >= 200) || response.status < 400) {
    appStore.dispatch({
      type: 'SET_ASYNC_SUCCESS_STATUS'
    });
  } else {
    appStore.dispatch({
      type: 'SET_ASYNC_FAILED_STATUS'
    });
  }
};

// TODO: refactor into generator function and move into sagas
const watchAsyncRequests = () =>
  Noblr.interceptors.map(interceptor => {
    interceptor.request.use(
      request => {
        startAsyncRequest(request.url);

        return request;
      },
      err => {
        if (err.response.status === 401) {
          Sentry.withScope(scope => {
            scope.setTag('request_error_code', '401');
            scope.setLevel('error');
            Sentry.captureException(err.response);
          });
        }

        return Promise.reject(err);
      }
    );

    interceptor.response.use(
      response => {
        setAsyncStatus(response);

        return response;
      },
      error => {
        setAsyncStatus(error);

        if (error.response.status === 401 || error.response.status === 400) {
          Sentry.withScope(scope => {
            scope.setTag('error_response_status', error.response.status);
            scope.setTag('auth_http_error', true);
            scope.setLevel('error');
            // will be tagged with my-tag="my value"
            Sentry.captureException(error);
          });
        }
        Sentry.withScope(scope => {
          scope.setTag('error_response_status', error.response.status);
          scope.setLevel('error');
          // will be tagged with my-tag="my value"
          Sentry.captureException(error);
        });

        return Promise.reject(error);
      }
    );

    return interceptor;
  });

export default watchAsyncRequests;
