import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { colors, breakPoints, openSans } from 'styles/variables';
import { ModalHeader, TextLink } from 'styles';
import Modal from 'components/Modal';
import Copyright from '../Static/Copyright';

const AppFooter = styled.footer`
  margin: 0 auto 5rem;
  min-width: 100%;
  width: 100%;
  height: auto;
  flex: 2 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .footer__link {
    padding: 5px;
    color: ${colors.coreBlue700};
    border-bottom: ${colors.coreBlue700};

    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (min-width: ${breakPoints.medium}) and (max-width: ${breakPoints.large}) {
    padding: ${props => (props.$extraPadding ? '0 0 30px' : '0 0 90px')};

    flex-flow: column nowrap;
    text-align: center;

    .footer__link {
      text-align: center;
    }
  }

  @media only screen and (min-width: ${breakPoints.extraLarge}) {
    padding: ${props => (props.$extraPadding ? '50px 0' : ' 10px 0 2%')};
    margin: 0px;
  }
`;

const FooterContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  vertical-align: text-bottom;
  align-content: center;

  > * {
    font-family: ${openSans};
    color: ${colors.coreBlue700};
    font-size: 10px;
  }

  a {
    margin: 0;
    display: inline-block;
    text-transform: none;
    letter-spacing: 0;
  }
`;

const FooterLinks = styled.div`
  padding: 0;
  width: 100%;
  height: 30px;
  flex: 1 30px;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
`;

const Divider = styled.span`
  margin: 0 3px;
  font-size: 10px;
`;

const CopyrightText = styled.div`
  text-align: center;
  > p {
    margin-top: 0;
    color: ${colors.coreBlue700};
    display: inline-block;
    font-size: 10px;
  }
`;

const Text = styled.p`
  margin-top: 0;
  padding: 10px;
  display: inline-block;
  text-align: center;
  line-height: 1.43;
  font-family: ${openSans};
  color: ${colors.coreBlue700};
`;

const TrademarkText = styled.p`
  font-size: inherit;
`;

const LicenseWrapper = styled.div`
  font-size: 18px;
  font-family: ${openSans};
  h3 {
    margin-top: 30px;
    font-weight: bold;
    font-family: inherit;
    font-size: inherit;
  }
`;

const MediaDiv = styled.div`
  margin: 0 auto;
  max-width: 95%;
  overflow-wrap: break-word;
  @media only screen and (min-width: ${breakPoints.extraLarge}) {
    max-width: 100%;
  }
`;

const footerMiddleLinks = [
  {
    title: 'Privacy Policy',
    url: 'https://www.usaa.com/insurance/pay-as-you-drive/privacy-policy'
  },
  {
    title: 'Terms and Conditions',
    url: 'https://www.usaa.com/insurance/pay-as-you-drive/terms-and-conditions'
  }
];

function Licenses() {
  return (
    <div>
      <LicenseWrapper>
        <h3>Noblr Reciprocal Exchange</h3>
        <p>NAIC #16461</p>
        <p>TX License #13765978</p>
        <p>NM License #508115039</p>
        <p>MD License #010489 </p>
        <h3>Noblr Insurance Services, LLC</h3>
        <p>License #19153735</p>
        <p>CO License #603264</p>
      </LicenseWrapper>
    </div>
  );
}

function Footer(props) {
  const {
    location: { pathname }
  } = props;

  const isQuotePage = pathname === '/quote/review-your-quote';
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({ type: 'OPEN_MODAL', payload: 'licenses' });
  };

  return (
    <AppFooter
      $extraPadding={isQuotePage}
      className="footer"
      role="contentinfo"
    >
      <FooterContent>
        <FooterLinks>
          {footerMiddleLinks.map(({ title, url }) => (
            <Fragment key={title}>
              <a
                className="footer__link"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Opens a new window to ${title}`}
              >
                {title}
              </a>
              <Divider aria-hidden="true" tabindex={-1}>
                |
              </Divider>
            </Fragment>
          ))}
          <TextLink
            licenses
            tabIndex="0"
            aria-label="Licenses"
            onClick={handleClick}
          >
            Licenses
          </TextLink>
          <Modal
            showCloseButton
            inline
            modalName="licenses"
            modalLabel="LicensesModalFooter"
          >
            <ModalHeader>Licenses</ModalHeader>
            <Licenses />
          </Modal>
        </FooterLinks>
        <CopyrightText>
          <Copyright />
          <Divider aria-hidden="true" tabindex={-1} />
          <TrademarkText>NOBLR™ is a trademark of Noblr, Inc.</TrademarkText>
        </CopyrightText>
        <MediaDiv>
          <Text>
            Pay as you drive policies are issued by NOBLR Reciprocal Exchange,
            San Antonio, TX, a USAA company.
          </Text>
        </MediaDiv>
      </FooterContent>
    </AppFooter>
  );
}

export default Footer;
