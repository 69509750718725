import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VerticalOptionDivider from 'components/VerticalOptionDivider';
import GoogleButton from 'components/Buttons/GoogleButton';
import Form from 'components/Form/Form';
import Loader from 'components/Loader';
import FetchingQuote from 'components/FetchingQuote';
import { updateAuthStatus } from 'redux/actions';
import { StyledContentWrapper } from 'styles';

class SignInPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Welcome back!',

      submitAction: 'LOGIN_NOBLR_ACCOUNT',
      fields: [
        {
          type: 'email',
          inputType: 'email',
          name: 'email',
          id: 'sign-in-input',
          label: 'Email Address',
          validation: 'email',
          initialValue: '',
          required: true
        },
        {
          type: 'password',
          name: 'password',
          id: 'password',
          placeholder: '******',
          required: true,
          label: 'password',
          showRecoverPasswordLink: true,
          autoFocus: false
        }
      ],
      staticContent: {
        form: [
          {
            type: 'PasswordRequirements',
            location: 'form'
          },
          {
            type: 'EmailDisclaimer',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { trialActive, primaryDriverEmail, updateAuthStatus } = this.props;
    const { fields } = this.state;

    const subHeader = trialActive
      ? `We see that you're currently trying out Noblr. Sign in to access your saved quote.`
      : 'Please sign in to access your quote';

    const updatedFields = fields.map(field => {
      const currField = { ...field };

      if (
        currField.name === 'email' &&
        !!primaryDriverEmail &&
        primaryDriverEmail.length > 0
      ) {
        currField.initialValue = primaryDriverEmail;
      }

      return currField;
    });

    updateAuthStatus({
      isTrial: trialActive,
      trialActive,
      email: primaryDriverEmail
    });

    this.setState({
      subHeader,
      fields: updatedFields
    });
  }

  render() {
    const { loading, fetchingRate } = this.props;
    const {
      fields,
      submitAction,
      prompt,
      successRoute,
      subHeader,
      staticContent: { form }
    } = this.state;

    // show fetching rate image if we're fetching rate
    if (fetchingRate) {
      return <FetchingQuote />;
    }

    return loading ? (
      <Loader />
    ) : (
      <StyledContentWrapper>
        <Form
          noValidate=""
          fields={fields}
          buttonText="Sign In"
          buttonWidth="280px"
          submitAction={submitAction}
          submitAsync
          prompt={prompt}
          subHeader={subHeader}
          successRoute={successRoute}
          staticContent={form}
        />
        <VerticalOptionDivider />
        <GoogleButton noMargin inlineDisplay noPadding signIn>
          Sign In With Google
        </GoogleButton>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { trialActive, loading },
  drivers: { primaryDriver },
  rate: { loading: fetchingRate }
}) => ({
  trialActive,
  primaryDriverEmail: primaryDriver.email,
  loading,
  fetchingRate
});

const mapDispatchToProps = dispatch => ({
  updateAuthStatus: data => dispatch(updateAuthStatus(data))
});

SignInPage.propTypes = {
  trialActive: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  primaryDriverEmail: PropTypes.string.isRequired,
  updateAuthStatus: PropTypes.func.isRequired,
  fetchingRate: PropTypes.bool
};

SignInPage.defaultProps = {
  fetchingRate: false
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
