import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';
import { MARITAL_STATUS_OPTIONS } from '../../constants';

class MaritalStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt: '',
      submitAction: '',
      fields: [],
      successRoute: ''
    };
  }

  componentDidMount() {
    const { section, editingDriver, primaryDriver } = this.props;

    const fields = [
      {
        type: 'select',
        name: 'maritalStatus',
        id: 'marital-select',
        selectLabel: 'Marital Status',
        selectLabelId: 'marital-select-label',
        options: MARITAL_STATUS_OPTIONS,
        required: true
      }
    ];

    const prompt =
      section === 'start-quote'
        ? "What's your marital status?"
        : "What's this driver's marital status?";

    const submitAction =
      section === 'start-quote'
        ? 'UPDATE_PRIMARY_DRIVER_REQUEST'
        : 'UPDATE_ADDITIONAL_DRIVER_INFO';

    const successRoute = `/${section}/age-licensed`;

    const driver = section === 'start-quote' ? primaryDriver : editingDriver;

    const updatedFields = fields.map(field => ({
      ...field,
      initialValue: driver.maritalStatus || ''
    }));

    this.setState({
      fields: updatedFields,
      prompt: driver.maritalStatus
        ? `Please confirm ${
            section === 'start-quote' ? 'your' : "this driver's"
          } marital status.`
        : prompt,
      submitAction,
      successRoute
    });
  }

  render() {
    const { section, editingDriver } = this.props;
    const { prompt, submitAction, fields, successRoute } = this.state;

    if (!fields || !prompt || !submitAction || !successRoute) {
      return null;
    }

    return section === 'add-drivers' && !editingDriver ? (
      <Redirect to="/add-drivers/drivers-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({ drivers: { editingDriver, primaryDriver } }) => ({
  editingDriver,
  primaryDriver
});

export default connect(mapStateToProps)(MaritalStatusPage);
