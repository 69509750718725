import React, { Component } from 'react';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';
import { pennDotLink } from '../../constants';

function PennDotLink() {
  return (
    <span>
      {
        'If you have successfully completed a driver improvement course in the last 3 years, you can get a discount on your Noblr policy. Please see the '
      }
      <a href={pennDotLink} target="_blank" rel="noopener noreferrer">
        PA Department of Transportation
      </a>
      <span> to see if your course qualifies.</span>
    </span>
  );
}

// we only show this for PA primary drivers
class DriverImprovementPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'UPDATE_PRIMARY_DRIVER_REQUEST',
      fields: [
        {
          type: 'radio',
          name: 'seniorDriverImprovementDiscount',
          options: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' }
          ],
          required: true,
          radioGroupAriaLabel: 'Senior Driver Improvement Discount'
        }
      ],
      successRoute: '/add-drivers/drivers-list'
    };
  }

  render() {
    const { submitAction, fields, successRoute } = this.state;

    const prompt =
      'Have you completed a driver improvement course in the last 3 years?';

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          renderFieldRow={false}
        />
        <InfoBox
          extraData={{
            header: 'Why Are We Asking This?'
          }}
        >
          <PennDotLink />
        </InfoBox>
      </StyledContentWrapper>
    );
  }
}
export default DriverImprovementPage;
