import React, { PureComponent } from 'react';
import { TextField } from '@material-ui/core';
import CleaveInput from 'components/Form/CleaveInput';

class TextInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shrinkLabel: false
    };
    this.toggleLabel = this.toggleLabel.bind(this);
  }

  toggleLabel() {
    this.setState(prevState => ({
      shrinkLabel: !prevState.shrinkLabel
    }));
  }

  render() {
    const { shrinkLabel } = this.state;
    const {
      field: { value, name, onChange, onBlur, ...rest },
      placeholder,
      type = 'text',
      maxLength,
      label,
      hidden = false,
      formattingOptions = null,
      invalid = false,
      disabled = false,
      form,
      autoComplete,
      required,
      id,
      ariaLabel
    } = this.props;

    return formattingOptions ? (
      <TextField
        id={id}
        name={name}
        value={value}
        type={type}
        autoComplete={autoComplete}
        label={label}
        onFocus={this.toggleLabel}
        onBlur={() => {
          form.setFieldTouched(name, true);

          this.toggleLabel();
        }}
        InputLabelProps={{
          notched: 'true',
          shrink: (value && !!value.length) || shrinkLabel
        }}
        maxLength={maxLength || 524288}
        onChange={onChange}
        className={`${hidden ? 'hidden' : ''} fs-exclude`}
        variant="outlined"
        disabled={disabled || invalid}
        InputProps={{
          inputComponent: CleaveInput,
          inputProps: {
            'aria-label': ariaLabel || label,
            'aria-labelledby': name,
            'aria-required': required,
            options: formattingOptions,
            onInit: this.onCleaveInit,
            onKeyDown: this.handleOnKeyDown,
            form,
            placeholder
          }
        }}
      />
    ) : (
      <TextField
        id={id}
        name={name}
        value={value}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        label={label}
        maxLength={maxLength || 524288}
        onChange={onChange}
        onBlur={onBlur}
        className={`${hidden ? 'hidden' : ''} fs-exclude`}
        InputLabelProps={{
          notched: 'true'
        }}
        variant="outlined"
        inputProps={{
          'aria-label': ariaLabel || label,
          'aria-required': required && !hidden,
          'aria-hidden': hidden
        }}
        disabled={disabled}
        {...rest}
      />
    );
  }
}

export default TextInput;
