import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import styled from 'styled-components/macro';
import { createAccount, loginUser } from 'redux/actions';
import Button from 'components/Buttons/Button';

const { REACT_APP_GOOGLE_SIGNIN_CLIENT_ID } = process.env;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding-left: 10px;
  }
`;

class GoogleButton extends Component {
  constructor(props) {
    super(props);

    this.handleOnFailure = this.handleOnFailure.bind(this);
    this.handleOnSuccess = this.handleOnSuccess.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleOnFailure(response) {
    // eslint-disable-next-line no-console
    console.log('failure response', response);
  }

  // eslint-disable-next-line consistent-return
  handleOnSuccess(response) {
    const { signUp, signIn, trialStatus, loginUser, createAccount } =
      this.props;

    const {
      tokenId,
      profileObj: { email }
    } = response;

    if (signUp) {
      return createAccount(email, tokenId, trialStatus);
    }

    if (signIn) {
      return loginUser(email, tokenId);
    }
  }

  render() {
    const { children, noMargin, inlineDisplay, noPadding } = this.props;

    return (
      <GoogleLogin
        clientId={REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
        onSuccess={this.handleOnSuccess}
        onFailure={this.handleOnFailure}
        render={renderProps => (
          <Button
            extraLarge
            secondary
            noMargin={noMargin}
            padding="15px"
            onClick={renderProps.onClick}
            inlineDisplay={inlineDisplay}
            noPadding={noPadding}
          >
            <ButtonContent>
              <img
                src={`${process.env.REACT_APP_NOBLR_CDN}/icons/google-short-logo.svg`}
                alt="Authenticate with Google"
              />
              <span>{children}</span>
            </ButtonContent>
          </Button>
        )}
      />
    );
  }
}

const mapStateToProps = ({ auth: { isTrial, trialActive } }) => ({
  trialStatus: isTrial || trialActive
});

const mapDispatchToProps = dispatch => ({
  createAccount: (email, authKey, trialStatus) =>
    dispatch(createAccount(email, authKey, trialStatus, 'google')),
  loginUser: (email, tokenId) => dispatch(loginUser(email, tokenId, 'google'))
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleButton);
