import startQuoteData from './startQuote';
import addDriverFlow from './addDrivers';
import addVehiclesFlow from './addVehicles';
import drivingBehaviorContent from './drivingBehavior';
import quoteContent from './getQuote';
import purchaseContent from './purchase';
import accountContent from './account';
// import preBind from './preBind'; // Disabled quote flow from Sales Tool handoff
import dnq from './dnq';
import setUpAccount from './setUpAccount';

const QUOTE_FLOW_SECTIONS = [
  startQuoteData,
  addDriverFlow,
  addVehiclesFlow,
  drivingBehaviorContent,
  quoteContent,
  purchaseContent,
  accountContent,
  dnq,
  setUpAccount
];

export default QUOTE_FLOW_SECTIONS;
