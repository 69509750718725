import LiabilityModalContent from 'constants/liabilityCoverages';
import VehicleCoverageModalContent from 'constants/vehicleCoverages';
import { COVERAGES_MAP } from '../../constants';

/* eslint-disable no-param-reassign */

export const buildVehicleCoverages = coverages =>
  coverages.reduce((accumulator, vehicle) => {
    const { vehicleId } = vehicle;

    const vehicleCoverages = Object.keys(vehicle)
      .filter(prop => prop !== 'vehicleId')
      .reduce((acc, deductibleId) => {
        acc = {
          ...acc,
          [`${vehicleId}-${deductibleId}`]: vehicle[deductibleId].value
        };

        return acc;
      }, {});

    accumulator = {
      ...accumulator,
      ...vehicleCoverages
    };

    return accumulator;
  }, {});

export const getFieldProps = ({
  restrictUM,
  header,
  helpShort,
  id,
  value,
  name,
  modalContent,
  label,
  uiFieldType,
  comboDisplay = null,
  fieldRuleResults = null,
  values,
  ratePlanStateCode
}) => {
  let fieldProps = {
    header,
    helpShort,
    id,
    modalContent,
    name,
    value,
    label,
    uiFieldType
  };

  const state = ratePlanStateCode.slice(0, 2);

  // if comboDisplay, add to props
  if (comboDisplay) {
    fieldProps = {
      ...fieldProps,
      comboDisplay
    };
  }

  // check if field rule results have hide property
  if (fieldRuleResults && 'hide' in fieldRuleResults) {
    fieldProps = {
      ...fieldProps,
      hide: fieldRuleResults.hide
    };
  }

  // if messages are returned for the field, return them too
  if (fieldRuleResults && 'errorMessages' in fieldRuleResults) {
    fieldProps = {
      ...fieldProps,
      coverageMessages: fieldRuleResults.errorMessages
    };
  }

  let valueIds = 'allIds';

  // TODO improve logic in if/else block
  if (ratePlanStateCode === 'VA1000') {
    valueIds = 'allIds';
  } else if (
    (id !== 'umuimbi' && id !== 'umuimpd' && !id.endsWith('-umuimpd')) ||
    (state !== 'MD' && state !== 'GA' && state !== 'VA')
  ) {
    valueIds = 'allIds';
  } else if (state === 'MD') {
    valueIds =
      restrictUM === 'ONLY_ENHANCED' ? 'enhancedOptions' : 'standardOptions';
  } else if (
    state === 'GA' ||
    (state === 'VA' && ratePlanStateCode !== 'VA1000')
  ) {
    valueIds = restrictUM === 'ADDED_ON' ? 'addedOn' : 'reducedBy';
  }

  const formatText = text => {
    if (state === 'VA' && ratePlanStateCode !== 'VA1000') {
      return text.replace('Added On', '');
    }

    return text;
  };

  const allowedOptions = fieldRuleResults?.allowedOptions || [];
  const updatedOptions =
    values[valueIds] &&
    values[valueIds].map(valueId => {
      const label = formatText(values.byId[valueId].valueDisplay);
      const value = values.byId[valueId].code;
      const disabled =
        allowedOptions &&
        allowedOptions.length > 0 &&
        !allowedOptions.includes(valueId);

      return {
        ...values.byId[valueId],
        value,
        label,
        disabled
      };
    });

  return {
    ...fieldProps,
    options: updatedOptions
  };
};

export const groupCoverages = (
  policyCoverageIds,
  vehicleCoverageIds,
  values,
  vehicles
) => {
  // grab all policy coverages from values
  const policyCoverages = policyCoverageIds.reduce((acc, id) => {
    // add coverage id to acc
    // only if we don't have it already, and we have a value to add
    if (!acc[id]) {
      acc[id] = {};
    }

    if (values && values[id]) {
      acc[id] = values[id];
    }

    return acc;
  }, {});

  // get all vehicle ids
  const vehicleIds = vehicles.map(vehicle => vehicle.vehicleId);

  const vehicleCoverages = vehicleIds.reduce((acc, vehicleId) => {
    const coverages = vehicleCoverageIds
      // filter out road since we don't have a value/it's defaulted in BE
      .filter(id => id !== 'road')
      .reduce((acc, id) => {
        // make sure we have values before trying to access them
        if (!acc[id] && values && values[`${vehicleId}-${id}`]) {
          acc[id] = values[`${vehicleId}-${id}`];
        }

        return acc;
      }, {});

    acc[vehicleId] = coverages;

    return acc;
  }, {});

  return {
    policyCoverages,
    vehicleCoverages: { byId: vehicleCoverages, allIds: vehicleIds }
  };
};

export const generateRestrictUMValue = (ratePlanState, coverage) => {
  const requiresEnhancedCoverages =
    ratePlanState === 'MD' || ratePlanState === 'GA' || ratePlanState === 'VA';

  if (requiresEnhancedCoverages) {
    let restrictUM = '';
    const { umuimbi } = coverage;

    if (ratePlanState !== 'MD') {
      const umToggleValue = umuimbi.split('_').slice(-2).join('_');

      restrictUM = umToggleValue;
    } else {
      restrictUM = umuimbi.includes('NON_ENHANCED')
        ? 'EXCLUDE_ENHANCED'
        : 'ONLY_ENHANCED';
    }

    return restrictUM;
  }

  return null;
};

export const combineCollAndUmpdDeductibles = collOptions => {
  const collOptionValues = collOptions.map(option => ({
    label:
      option === 'No Coverage'
        ? 'No Coverage'
        : `Collision | $${option} deductible`,
    value: option
  }));

  return [
    ...collOptionValues,
    {
      label: 'UMPD | $7500 limit | $250 deductible',
      value: '7.5'
    }
  ];
};

export const combineCollAndUmuimpdDeductibles = collOptions => {
  const collOptionValues = collOptions.map(option => ({
    label:
      option === 'No Coverage'
        ? 'No Coverage'
        : `Collision | $${option} deductible`,
    value: option
  }));

  return [
    ...collOptionValues,
    {
      label: 'UMPD | $25K limit | $250 deductible',
      value: '25'
    }
  ];
};

export const stripCollAndUMPDValues = (fieldValues, fieldId) => {
  if (
    fieldValues[`${fieldId}-coll`] !== undefined &&
    fieldValues[`${fieldId}-umpd`] !== undefined
  ) {
    return [fieldValues[`${fieldId}-coll`], fieldValues[`${fieldId}-umpd`]];
  }

  return [];
};

export const buildDeductiblePayload = (vehicle, combinedDeductiblesConfig) => {
  const { combineCollAndUmpd, combineCollAndUmuimpd, combineCompAndGlassComp } =
    combinedDeductiblesConfig;

  const collAndUmpdSelection =
    combineCollAndUmpd && vehicle.collAndUmpdDeductible.label.split(' | ')[0];

  const collAndUmuimpdSelection =
    combineCollAndUmuimpd &&
    vehicle.collAndUmuimpdDeductible.label.split(' | ')[0];

  const obj = {};

  if (collAndUmpdSelection) {
    if (collAndUmpdSelection === 'Collision') {
      obj.collDeductible = vehicle.collAndUmpdDeductible.value;
    } else if (collAndUmpdSelection === 'UMPD') {
      // if umpd: set coll to "No Coverage" and umpd to value
      obj.collDeductible = 'No Coverage';

      obj.umpdDeductible = vehicle.collAndUmpdDeductible.value;
    } else if (collAndUmpdSelection === 'No Coverage') {
      obj.collDeductible = 'No Coverage';

      obj.umpdDeductible = 'No Coverage';
    }
  }

  if (collAndUmuimpdSelection) {
    if (collAndUmuimpdSelection === 'Collision') {
      obj.collDeductible = vehicle.collAndUmuimpdDeductible.value;

      obj.umuimpdLimit = 'No Coverage';
    } else if (collAndUmuimpdSelection === 'UMPD') {
      // if umpd: set coll to "No Coverage" and umpd to value
      obj.collDeductible = 'No Coverage';

      obj.umuimpdLimit = vehicle.collAndUmuimpdDeductible.value;
    } else if (collAndUmuimpdSelection === 'No Coverage') {
      obj.collDeductible = 'No Coverage';

      obj.umuimpdLimit = 'No Coverage';
    }
  }

  // if combined AND selection specifies $0 glass deductible we need to add glass to payload
  if (combineCompAndGlassComp) {
    const compAndGlassSelection = vehicle.compDeductible.value.split('_');
    const [compDeductible] = compAndGlassSelection;

    obj.compDeductible = compDeductible;

    if (compAndGlassSelection[1] && compAndGlassSelection[1] === '0') {
      obj.glassDeductible = '0';
    }
  }

  return {
    vehicleId: vehicle.vehicleId,
    collDeductible: obj.collDeductible || vehicle.collDeductible.value,
    compDeductible: obj.compDeductible || vehicle.compDeductible.value,
    rent: vehicle.rent.value,

    // optional values, pass in null as fallback
    umpdDeductible: obj.umpdDeductible || null,
    umuimpdLimit: obj.umuimpdLimit || null,
    glassCompDeductible: obj.glassDeductible || null
  };
};

/**
 *
 * @param {string} value
 */
export const getCoverageValue = value => {
  if (!value) {
    return 'N/A';
  }

  if (value === 'No Coverage') {
    return value;
  }

  return `$${value}K`;
};

/**
 * maps per person, per occurrence object to string
 */
export const mapCoverageValuesToString = coverages =>
  Object.keys(coverages).reduce((acc, coverageId) => {
    const { perPerson, perOccurrence } = coverages && coverages[coverageId];
    const coverageValueConstant = perOccurrence
      ? `${coverageId}_${perPerson}_${perOccurrence}`.toUpperCase()
      : `${coverageId}_${perPerson}`.toUpperCase();

    acc[coverageId] = coverageValueConstant;

    return acc;
  }, {});

export const getLiabilityModalContent = stateCode =>
  LiabilityModalContent[stateCode];

export const getVehicleCoverageModalContent = stateCode =>
  VehicleCoverageModalContent[stateCode];

/**
 * Store each group of coverage options in an array to easily iterate over all options
 * @param {Array} keys
 * @param {Object} data
 */
export const getLiabilityCoverages = (keys, data) =>
  keys.map(key => {
    if (!data[key]) {
      // break out if we don't have have the coverage info
      // todo: do something else here to handle error? this just keeps page from breaking
      return null;
    }

    return { ...data[key], ...COVERAGES_MAP[key] };
  });

  export const displayValuesPer = (value, per) => {
  // if N/A we don't display anything
  if (value === 'N/A') {
    return '';
    // if no coverage, we just display that
  }

  if (value === 'No Coverage') {
    return value;
  }

  // otherwise we display value per string passed in
  return `${value} per ${per}`;
};

  export const generateMileageHeading = value => {
  let displayValue = 'Errands Only';

  if (value >= 75) {
    displayValue = 'Major Miles';
  } else if (value >= 50) {
    displayValue = 'Serious Commute';
  } else if (value >= 25) {
    displayValue = 'Middle of the Road';
  } else if (value >= 10) {
    displayValue = 'Short Commute';
  } else {
    return displayValue;
  }

  return displayValue;
};

export const generatePersonaContent = value => {
  const persona = {
    title: null,
    body: null
  };

  if (value >= 2) {
    persona.title = 'Booked & Busy';

    persona.body =
      'High on distractions and short on attention. A harder-accelerating and braking driver with their mind elsewhere.';
  } else if (value >= 1) {
    persona.title = 'Middle of the Road';

    persona.body =
      'Drives like a pro. Smooth acceleration and braking + laser focus, night or day. Always picks the most efficient route.';
  } else {
    persona.title = 'Slow & Steady';

    persona.body =
      "Defensive driver. Keeps eyes on the road. Doesn't handle their phone while driving. Drives mostly during daylight hours.";
  }

  return persona;
};

export const generatePersonaQuoteContent = value => {
  const persona = {
    title: null
  };

  if (value >= 2) {
    persona.title = 'Booked & Busy';
  } else if (value >= 1) {
    persona.title = 'Middle of the Road';
  } else {
    persona.title = 'Slow & Steady';
  }

  return persona;
};

export const roundMileageValue = mileage => {
  if (!mileage) {
    return null;
  }

  if (mileage < 30) {
    // Step size on the slider changes to every 10 at 30 so the rounding does as well
    return roundToNearestFive(mileage);
  }

  return roundToNearestTen(mileage);
};

function roundToNearestTen(input) {
  return Math.round(input / 10) * 10;
}

function roundToNearestFive(input) {
  // The minimum of 5 on this return is due to the smallest package available being 5 for mileage
  return Math.max(5, Math.round(input / 5) * 5);
}

export const determinePreBindStartPage = (state, requiresDynamicForms) => {
  let successRoute = '/purchase/signature-start';

  if (state === 'NV' && !requiresDynamicForms) {
    successRoute = '/purchase/signature-start-nv';
  }

  return successRoute;
};

export const determinePurchaseStartPage = ({
  allDriversExcluded,
  requiresDriverExclusion,
  dlVerificationRequired,
  vinVerificationNeeded
}) => {
  let route = `/purchase/effective-date`;

  if (dlVerificationRequired) {
    route = '/purchase/verify-drivers-license';
  } else if (vinVerificationNeeded) {
    route = '/purchase/vin-confirm';
  } else if (requiresDriverExclusion && !allDriversExcluded) {
    route = '/driver-exclusion/start';
  }

  return route;
};
