import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';
import { STATE_OPTIONS } from 'constants/stateCodes';

class AddressEntryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: "What's your address?",
      submitAction: 'SAVE_PRIMARY_PERSON_ADDRESS',
      fields: [
        {
          type: 'text',
          name: 'street',
          id: 'address-line-1-input',
          label: 'Street Address',
          required: true,
          initialValue: '',
          autoComplete: 'address-line1'
        },
        {
          type: 'text',
          name: 'street2',
          id: 'address-line-2-input',
          label: 'Apartment or Unit #',
          placeholder: 'Optional',
          initialValue: '',
          required: false,
          autoComplete: 'address-line2',
          ariaLabel: 'Apartment or Unit #'
        },
        {
          type: 'text',
          name: 'city',
          id: 'address-line-3-input',
          label: 'City',
          initialValue: '',
          required: true,
          autoComplete: 'address-level2'
        },
        {
          type: 'searchableSelect',
          name: 'state',
          label: 'State',
          id: 'address-line-4-input',
          required: true,
          initialValue: '',
          options: STATE_OPTIONS,
          resetOnSelect: true,
          autoComplete: 'address-level1'
        },
        {
          type: 'text',
          name: 'zipCode',
          id: 'address-line-5-input',
          label: 'Zip Code',
          initialValue: '',
          required: true,
          autoComplete: 'postal-code'
        }
      ],
      successRoute: '/start-quote/usaa-membership',
      staticContent: {
        form: [
          {
            type: 'static',
            value:
              'By clicking "Continue," you authorize us to order your credit-based insurance score, driving record, claims, and prior insurance information, any of which may be used to make decisions on coverage eligibility and rates and to provide you with a more accurate quote.',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { fields } = this.state;
    const { manualAddress, hasPartnerData, primaryDriver } = this.props;

    const prompt = hasPartnerData
      ? "What's your address?"
      : 'No problem. Can you repeat that?';

    if (manualAddress || hasPartnerData || primaryDriver?.street) {
      const initializedFields = fields?.map(field => {
        const currentField = { ...field };

        if (primaryDriver[currentField?.name]) {
          currentField.initialValue = primaryDriver[field?.name];
        }

        return currentField;
      });

      this.setState({
        fields: initializedFields,
        prompt
      });
    }
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          staticContent={form}
        />
        <InfoBox
          extraData={{
            header: 'What Address Should I Use?',
            text: 'Enter your garaging address, the place where your car sleeps at night.'
          }}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { manualAddress, hasPartnerData },
  drivers: { primaryDriver }
}) => ({
  manualAddress,
  hasPartnerData,
  primaryDriver
});

AddressEntryPage.propTypes = {
  manualAddress: PropTypes.bool.isRequired,
  hasPartnerData: PropTypes.bool.isRequired,
  primaryDriver: PropTypes.shape({
    street: PropTypes.string
  })
};

AddressEntryPage.defaultProps = {
  primaryDriver: {
    street: ''
  }
};

export default connect(mapStateToProps)(AddressEntryPage);
