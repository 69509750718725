import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import Loader from 'components/Loader';
import { requestUSAAEligibilityQuestions } from 'redux/actions';
import { StyledContentWrapper, StyledLink } from 'styles';
import { ELIGIBILITY_PAGE_INFOBOX_CONTENT } from '../../constants';

class SecondaryEligibilityPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Please tell us about your USAA eligibility',
      submitAction: 'SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS',
      fields: [],
      fieldsBuilt: false,
      successRoute: '/start-quote/email'
    };
  }

  componentDidMount() {
    const { secondaryEligibility, requestUSAAEligibilityQuestions } =
      this.props;

    /*
      request secondary eligibility questions if questions
      are not stored in redux
    */
    if (!secondaryEligibility) {
      return requestUSAAEligibilityQuestions();
    }

    // otherwise, setup form fields
    const updatedFields = secondaryEligibility.map(field => ({
      name: field.eligibilityQuestionId,
      type: 'radio',
      frontendKey: field.frontendKey,
      options: field.options,
      description: field.question,
      value: '',
      initialValue: field.lastSavedValue,
      required: true,
      bordered: 'true',
      radioGroupAriaLabel: field.question
    }));

    return this.setState({
      fields: updatedFields,
      fieldsBuilt: true
    });
  }

  render() {
    const { prompt, fields, submitAction, successRoute, fieldsBuilt } =
      this.state;

    const { secondaryEligibility } = this.props;

    // show loader if questions haven't been requested or fields aren't built
    if ((fields.length === 0 && !secondaryEligibility) || !fieldsBuilt) {
      return <Loader absolute />;
    }

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          renderFieldRow
          submitAsync
          disableValidationErrors
        />
        <InfoBox
          extraData={{
            header: 'Why Are We Asking This?'
          }}
        >
          <p>
            {ELIGIBILITY_PAGE_INFOBOX_CONTENT.start}
            <StyledLink
              id="usaa-eligibility-link"
              href="https://www.usaa.com/join"
              target="_blank"
              rel="noopener noreferrer"
              fontWeight="normal"
              aria-label={`opens a new window to ${ELIGIBILITY_PAGE_INFOBOX_CONTENT.middle}`}
            >
              {ELIGIBILITY_PAGE_INFOBOX_CONTENT.middle}
            </StyledLink>
            {ELIGIBILITY_PAGE_INFOBOX_CONTENT.end}
          </p>
        </InfoBox>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: {
    eligibilityQuestions: { secondaryEligibility }
  }
}) => ({
  secondaryEligibility
});

const mapDispatchToProps = dispatch => ({
  requestUSAAEligibilityQuestions: () =>
    dispatch(requestUSAAEligibilityQuestions())
});

SecondaryEligibilityPage.propTypes = {
  requestUSAAEligibilityQuestions: PropTypes.func,
  secondaryEligibility: PropTypes.arrayOf(
    PropTypes.shape({
      eligibilityQuestionId: PropTypes.string.isRequired,
      frontEndKey: PropTypes.number.isRequired,
      question: PropTypes.string.isRequired,
      lastSavedValue: PropTypes.string.isRequired,
      options: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

SecondaryEligibilityPage.defaultProps = {
  requestUSAAEligibilityQuestions: { requestUSAAEligibilityQuestions }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondaryEligibilityPage);
