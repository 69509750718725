import React, { Fragment, memo } from 'react';
import { ExpandableTextWrapper } from 'styles';
import { formatOrdinalMonth } from 'utilities';

function PaidMonthlySection({
  estInstallmentPaymentSchedule,
  installmentPrice
}) {
  const [, ...monthlyInstallments] = Object.assign(
    [],
    estInstallmentPaymentSchedule
  );

  return (
    <>
      <ExpandableTextWrapper firstItem>
        <p>Due Today: </p>
        <p>{installmentPrice}</p>
      </ExpandableTextWrapper>
      {monthlyInstallments.map((value, index) => {
        const ordinalMonth = formatOrdinalMonth(index + 1);

        return (
          <Fragment key={ordinalMonth}>
            <ExpandableTextWrapper>
              <p>{`${ordinalMonth} Month: `}</p>
              <p>{value}</p>
            </ExpandableTextWrapper>
          </Fragment>
        );
      })}
    </>
  );
}

const PaidMonthlyV2 = memo(PaidMonthlySection);

export default PaidMonthlyV2;
