export const ADD_DRIVER = 'ADD_DRIVER';

export const SAVE_PRIMARY_DRIVER = 'SAVE_PRIMARY_DRIVER';
export const SAVE_PRIMARY_DRIVER_SUCCESS = 'SAVE_PRIMARY_DRIVER_SUCCESS';
export const SAVE_PRIMARY_DRIVER_FAILURE = 'SAVE_PRIMARY_DRIVER_FAILURE';

export const UPDATE_PRIMARY_DRIVER = 'UPDATE_PRIMARY_DRIVER';
export const UPDATE_PRIMARY_DRIVER_REQUEST = 'UPDATE_PRIMARY_DRIVER_REQUEST';

export const EDITING_DRIVER = 'EDITING_DRIVER';
export const UPDATE_EDITING_DRIVER = 'UPDATE_EDITING_DRIVER';
export const CLEAR_EDITING_DRIVER = 'CLEAR_EDITING_DRIVER';

export const GET_ASSOCIATED_DRIVERS = 'GET_ASSOCIATED_DRIVERS';
export const GET_ASSOCIATED_DRIVERS_SUCCESS = 'GET_ASSOCIATED_DRIVERS_SUCCESS';

export const UNDO_REMOVE_DRIVER = 'UNDO_REMOVE_DRIVER';

export const REMOVE_DRIVER = 'REMOVE_DRIVER';
export const REMOVE_DRIVER_SUCCESS = 'REMOVE_DRIVER_SUCCESS';
export const REMOVE_DRIVER_FAILURE = 'REMOVE_DRIVER_FAILURE';

export const SAVE_DRIVER_AFTER_PREFILL = 'SAVE_DRIVER_AFTER_PREFILL';

export const GET_ALL_DRIVERS = 'GET_ALL_DRIVERS';
export const GET_ALL_DRIVERS_SUCCESS = 'GET_ALL_DRIVERS_SUCCESS';

export const SAVE_ADDITIONAL_DRIVER_INFO = 'SAVE_ADDITIONAL_DRIVER_INFO';
export const SAVE_ADDITIONAL_DRIVER_INFO_SUCCESS =
  'SAVE_ADDITIONAL_DRIVER_INFO_SUCCESS';
export const SAVE_ADDITIONAL_DRIVER_INFO_FAILURE =
  'SAVE_ADDITIONAL_DRIVER_INFO_FAILURE';

export const UPDATE_ADDITIONAL_DRIVER_INFO = 'UPDATE_ADDITIONAL_DRIVER_INFO';
export const UPDATE_ADDITIONAL_DRIVERS = 'UPDATE_ADDITIONAL_DRIVERS';

export const START_ADD_NEW_DRIVER = 'START_ADD_NEW_DRIVER';

export const COMPLETE_ADDING_ADDITIONAL_DRIVER =
  'COMPLETE_ADDING_ADDITIONAL_DRIVER';

export const UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE =
  'UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE';

export const SAVE_EDITING_DRIVER_DL_STATUS = 'SAVE_EDITING_DRIVER_DL_STATUS';

export const CONFIRM_EXCLUDED_DRIVER = 'CONFIRM_EXCLUDED_DRIVER';
export const CONFIRM_EXCLUDED_DRIVER_SUCCESS =
  'CONFIRM_EXCLUDED_DRIVER_SUCCESS';
export const CONFIRM_EXCLUDED_DRIVER_FAILURE =
  'CONFIRM_EXCLUDED_DRIVER_FAILURE';

export const SAVE_AUTOCOMPLETE_ADDRESS = 'SAVE_AUTOCOMPLETE_ADDRESS';

export const savePrimaryDriver = data => ({
  type: SAVE_PRIMARY_DRIVER,
  payload: data
});

export const savePrimaryDriverSuccess = () => ({
  type: SAVE_PRIMARY_DRIVER_SUCCESS
});

export const savePrimaryDriverFailure = () => ({
  type: SAVE_PRIMARY_DRIVER_FAILURE
});

export const startAddNewDriver = () => ({
  type: START_ADD_NEW_DRIVER
});

export const editingDriver = driver => ({
  type: EDITING_DRIVER,
  payload: driver
});

export const clearEditingDriver = () => ({
  type: CLEAR_EDITING_DRIVER
});

export const updateEditingDriver = data => ({
  type: UPDATE_EDITING_DRIVER,
  payload: data
});

export const getAssociatedDrivers = drivers => ({
  type: GET_ASSOCIATED_DRIVERS,
  payload: drivers
});

// Only updates reducer
export const updatePrimaryDriver = values => ({
  type: UPDATE_PRIMARY_DRIVER,
  payload: values
});

// Sends save or update request to save primary driver information
export const updatePrimaryDriverRequest = values => ({
  type: UPDATE_PRIMARY_DRIVER_REQUEST,
  payload: values
});

export const saveAdditionalDriverInfo = values => ({
  type: SAVE_ADDITIONAL_DRIVER_INFO,
  payload: values
});

export const saveAdditionalDriverInfoSuccess = savedDriver => ({
  type: SAVE_ADDITIONAL_DRIVER_INFO_SUCCESS,
  payload: savedDriver
});

export const saveAdditionalDriverInfoFailure = error => ({
  type: SAVE_ADDITIONAL_DRIVER_INFO_FAILURE,
  error
});

export const updateAdditionalDriverInfo = data => ({
  type: UPDATE_ADDITIONAL_DRIVER_INFO,
  payload: data
});

export const updateAdditionalDrivers = drivers => ({
  type: UPDATE_ADDITIONAL_DRIVERS,
  payload: drivers
});

export const removeDriver = data => ({
  type: REMOVE_DRIVER,
  payload: data
});

export const removeDriverSuccess = driverId => ({
  type: REMOVE_DRIVER_SUCCESS,
  payload: driverId
});

export const removeDriverFailure = error => ({
  type: REMOVE_DRIVER_FAILURE,
  error
});

export const completeAddingAdditionalDriver = data => ({
  type: COMPLETE_ADDING_ADDITIONAL_DRIVER,
  payload: data
});

export const getAssociatedDriversSuccess = drivers => ({
  type: GET_ASSOCIATED_DRIVERS_SUCCESS,
  payload: drivers
});

export const updatePrimaryDriverAndVerifyQuote = data => ({
  type: UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE,
  payload: data
});

export const saveEditingDriverDLStatus = data => ({
  type: SAVE_EDITING_DRIVER_DL_STATUS,
  payload: data
});

export const confirmExcludedDriver = data => ({
  type: CONFIRM_EXCLUDED_DRIVER,
  payload: data
});

export const confirmExcludedDriverSuccess = data => ({
  type: CONFIRM_EXCLUDED_DRIVER_SUCCESS,
  payload: data
});

export const confirmExcludedDriverFailure = error => ({
  type: CONFIRM_EXCLUDED_DRIVER_FAILURE,
  error
});

export const saveAutocompleteAddress = data => ({
  type: SAVE_AUTOCOMPLETE_ADDRESS,
  payload: data
});
