import * as Sentry from '@sentry/react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import Router from 'containers/Router';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ScrollToTop from '../ScrollToTop';

const MainContainer = styled.main`
  margin: 0 auto;
  width: 100%;
  flex: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

function App(props) {
  const {
    location,
    location: { pathname }
  } = props;

  const isQuotePage = pathname === '/quote/review-your-quote';

  return (
    <>
      <Header location={location} />
      <MainContainer $isQuotePage={isQuotePage}>
        <Router {...props} />
        <ScrollToTop />
      </MainContainer>
      <Footer location={location} />
    </>
  );
}

export default Sentry.withProfiler(withRouter(App));
