import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

class SignUpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: null,
      submitAction: 'CREATE_NOBLR_ACCOUNT',
      successRoute: '/account/download-app',
      fields: [
        {
          type: 'password',
          name: 'password',
          id: 'password-input',
          placeholder: '******',
          required: true,
          label: 'Setup your password'
        },
        {
          type: 'password',
          name: 'confirmPassword',
          id: 'confirm-password-input',
          placeholder: '******',
          required: true,
          label: 'Confirm your password'
        }
      ],
      staticContent: {
        form: [
          {
            type: 'PasswordRequirements',
            location: 'form'
          },
          {
            type: 'EmailDisclaimer',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { trialActive, primaryDriverEmail } = this.props;

    const prompt = trialActive
      ? "We're excited you want to test drive the Noblr app! To start your test drive, setup your account and download the app."
      : `We'll create an account using ${primaryDriverEmail}. Just setup your password below and you'll be ready to use the Noblr app.`;

    const submitAction = trialActive ? 'CONFIRM_EMAIL' : 'CREATE_NOBLR_ACCOUNT';
    const fields = trialActive
      ? [
          {
            type: 'email',
            name: 'email',
            id: 'email',
            placeholder: 'Email Address',
            required: true,
            label: 'Please confirm your email address',
            initialValue: primaryDriverEmail
          },
          {
            type: 'password',
            name: 'password',
            id: 'password',
            placeholder: '******',
            required: true,
            label: 'Setup your password'
          },
          {
            type: 'password',
            name: 'confirmPassword',
            id: 'confirm-password',
            placeholder: '******',
            required: true,
            label: 'Confirm your password'
          }
        ]
      : [
          {
            type: 'password',
            name: 'password',
            id: 'set-password',
            placeholder: '******',
            required: true,
            label: 'Setup your password'
          },
          {
            type: 'password',
            name: 'confirmPassword',
            id: 'confirm-password',
            placeholder: '******',
            required: true,
            label: 'Confirm your password'
          }
        ];

    this.setState({
      prompt,
      submitAction,
      fields
    });
  }

  render() {
    const {
      fields,
      submitAction,
      successRoute,
      prompt,
      staticContent: { form }
    } = this.state;

    // TODO: Determine where to redirect user if they randomly land on the page

    return (
      <StyledContentWrapper>
        <Form
          noValidate=""
          fields={fields}
          buttonText="Create Account"
          buttonWidth="280px"
          submitAction={submitAction}
          submitAsync
          prompt={prompt}
          staticContent={form}
          successRoute={successRoute}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { trialActive },
  drivers: {
    primaryDriver: { email }
  }
}) => ({
  trialActive,
  primaryDriverEmail: email
});

SignUpPage.propTypes = {
  trialActive: PropTypes.bool.isRequired,
  primaryDriverEmail: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(SignUpPage);
