import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import { colors } from 'styles/variables';

const ProgressBarTrack = styled.div`
  height: 5px;

  div {
    height: 100%;
  }
`;

const progressAnimation = keyframes`
    0% { width: 0%;}
    100% { width: 100%; }
  `;

const Progress = styled.div`
  background-color: ${colors.alphaBlue500};

  ${'' /* Media query below respects reduce-motion preference */}
  @media screen and (prefers-reduced-motion: no-preference) {
    animation: ${progressAnimation} 2.5s ease-in-out;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
  }
`;

const SECTIONS = [
  'start-quote',
  'add-drivers',
  'add-vehicles',
  'driving-behavior',
  'quote'
];

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: '10',
      showProgressBar: true
    };

    this.calculatePercentage = this.calculatePercentage.bind(this);
  }

  componentDidMount() {
    const { section } = this.props;
    const percentage = this.calculatePercentage(section);
    const showProgressBar = SECTIONS.includes(section);

    this.setState({
      percentage,
      showProgressBar
    });
  }

  componentDidUpdate(prevProps) {
    const { section } = this.props;

    if (prevProps.section === section) {
      return;
    }

    const updatedPercentage = `${this.calculatePercentage(section)}`;

    const displayProgressBar = SECTIONS.includes(section);

    this.setState({
      percentage: updatedPercentage,
      showProgressBar: displayProgressBar
    });
  }

  // eslint-disable-next-line class-methods-use-this
  calculatePercentage(section) {
    switch (section) {
      case 'start-quote':
        return '10';

      case 'add-drivers':
        return '30';

      case 'add-vehicles':
        return '50';

      case 'driving-behavior':
        return '80';

      case 'quote':
        return '100';

      default:
        return '10';
    }
  }

  render() {
    const { percentage, showProgressBar } = this.state;

    return (
      showProgressBar && (
        <ProgressBarTrack>
          <div
            role="progressbar"
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ height: '5px', width: `${percentage}%` }}
            aria-label="Progress Bar"
          >
            <Progress title="Progress Bar" />
          </div>
        </ProgressBarTrack>
      )
    );
  }
}

const mapStateToProps = ({ app: { section } }) => ({
  section
});

export default connect(mapStateToProps)(ProgressBar);
