import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import UpdatedPrice from 'components/Static/UpdatedPrice';
import EffectiveDateErrorMessage from 'components/Static/EffectiveDateErrorMessage';
import Loader from 'components/Loader';
import { getStartTime } from 'utilities';
import { StyledContentWrapper } from 'styles';

class EffectiveDatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'When would you like your Noblr coverage to start?',
      submitAction: 'CREATE_QUOTE',
      fields: null,
      successRoute: '/purchase/payment-schedule'
    };
  }

  componentDidMount() {
    const {
      stateCode,
      firstPossibleEffectiveDate,
      lastPossibleEffectiveDate,
      policyEffectiveDate
    } = this.props;

    const startTime = getStartTime(stateCode);
    // update initial value in date picker
    // set min and max date range of date picker
    const initializedFields = [
      {
        type: 'date',
        label: 'start date',
        name: 'policyEffectiveDate',
        id: 'policy-effective-date-input',
        required: true,

        disablePast: true,
        initialValue: policyEffectiveDate || firstPossibleEffectiveDate,
        minDate: firstPossibleEffectiveDate,
        maxDate: lastPossibleEffectiveDate
      }
    ];

    // set state using updated fields or previous fie
    this.setState({
      fields: initializedFields,
      staticContent: {
        form: [
          {
            type: 'static',
            location: 'form',
            value: startTime
          }
        ]
      }
    });
  }

  render() {
    const {
      firstPossibleEffectiveDate,
      lastPossibleEffectiveDate,
      history,
      vinRerate,
      updatedRates
    } = this.props;

    const { prompt, submitAction, fields, successRoute, staticContent } =
      this.state;

    const displayUpdatedRates = vinRerate || updatedRates;

    const locationState = history.location.state;

    return !fields ||
      !firstPossibleEffectiveDate ||
      !lastPossibleEffectiveDate ? (
      <Loader />
    ) : (
      <StyledContentWrapper>
        {locationState && locationState.showEffectiveDateError && (
          <EffectiveDateErrorMessage />
        )}
        {displayUpdatedRates && <UpdatedPrice />}
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          staticContent={staticContent && staticContent.form}
          submitAsync
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  drivers: {
    primaryDriver: { state }
  },
  rate: { vinRerate },
  excludedDrivers: { updatedRates },
  policy: {
    firstPossibleEffectiveDate,
    lastPossibleEffectiveDate,
    policyEffectiveDate
  }
}) => ({
  stateCode: state,
  firstPossibleEffectiveDate,
  lastPossibleEffectiveDate,
  policyEffectiveDate,
  vinRerate,
  updatedRates
});

EffectiveDatePage.propTypes = {
  stateCode: PropTypes.string.isRequired,
  firstPossibleEffectiveDate: PropTypes.string.isRequired,
  lastPossibleEffectiveDate: PropTypes.string.isRequired,
  policyEffectiveDate: PropTypes.string.isRequired,
  vinRerate: PropTypes.bool.isRequired,
  updatedRates: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({ showEffectiveDateError: PropTypes.bool })
    }).isRequired
  }).isRequired
};

export default connect(mapStateToProps)(EffectiveDatePage);
