import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Form from 'components/Form/Form';
import { clearFormError } from 'redux/actions';
import { StyledContentWrapper } from 'styles';

const ContentWrapper = styled(StyledContentWrapper)`
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;

  div > form > p {
    margin: 0 auto;
    max-width: 470px;
  }
  div > form > div > div.email {
    margin: 40px auto 0;
  }
`;

class ConfirmEmailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: `We'll get this sorted out. `, // don't remove space in string literal
      subHeader: `the email address you used to set up your account. If that email address is in our database,  we'll email you a code to reset your password.`,
      submitAction: 'SEND_ONE_TIME_PASS',
      successRoute: '',
      fields: [
        {
          type: 'email',
          inputType: 'email',
          name: 'email',
          id: 'confirm-email-input',
          label: 'Email Address',
          autoComplete: 'email',
          validation: 'email',
          required: true
        }
      ]
    };
  }

  componentDidMount() {
    const { prompt, subHeader, fields } = this.state;
    const {
      primaryDriverEmail,
      userNotFound,
      showOTPError,
      form,
      clearFormError
    } = this.props;

    if (form && form.error) {
      clearFormError();
    }

    const updatedSubheader = `${
      primaryDriverEmail ? 'Confirm' : 'Enter'
    } ${subHeader}`;

    // update prompt depending on whether or not we have an email address
    const updatedPrompt = primaryDriverEmail
      ? `${prompt} ${primaryDriverEmail}`
      : `${prompt} What's your email address?`;

    // set dynamic success route
    const successRoute =
      primaryDriverEmail && userNotFound && showOTPError
        ? '/account/sign-in'
        : '/account/code-entry';

    // set initial values for fields
    const updatedFields = fields.map(field => ({
      ...field,
      initialValue: primaryDriverEmail,
      label: primaryDriverEmail ? 'Confirm Email' : field.label
    }));

    this.setState({
      prompt: updatedPrompt,
      subHeader: updatedSubheader,
      successRoute,
      fields: updatedFields
    });
  }

  render() {
    const { fields, submitAction, prompt, subHeader, successRoute } =
      this.state;

    const { showOTPError, userNotFound } = this.props;

    return (
      <ContentWrapper>
        <Form
          noValidate=""
          fields={fields}
          subHeader={subHeader}
          buttonWidth="280px"
          submitAction={submitAction}
          submitAsync
          prompt={prompt}
          successRoute={successRoute}
        />
        {userNotFound && showOTPError && <Link to="/sign-in" />}
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { form },
  auth: { userNotFound, showOTPError, email: authEmail },
  drivers: {
    primaryDriver: { email }
  }
}) => ({
  form,
  userNotFound,
  showOTPError,
  primaryDriverEmail: authEmail || email
});

const mapDispatchToProps = dispatch => ({
  clearFormError: () => dispatch(clearFormError())
});

ConfirmEmailPage.propTypes = {
  form: PropTypes.shape({
    error: PropTypes.string
  }),
  userNotFound: PropTypes.bool,
  showOTPError: PropTypes.bool,
  primaryDriverEmail: PropTypes.string,
  clearFormError: PropTypes.func.isRequired
};

ConfirmEmailPage.defaultProps = {
  form: {
    error: ''
  },
  userNotFound: false,
  showOTPError: false,
  primaryDriverEmail: ''
};
// TODO: pull in auth error from redux and display on page
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailPage);
