import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fieldTools } from '@noblr-lab/frontend-tools';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';
import { vehicleOwnershipYears } from '../../constants';

class VehicleOwnershipLengthPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'How long have you had this car?',
      submitAction: 'UPDATE_VEHICLE',
      fields: [
        {
          type: 'select',
          name: 'vehicleOwnershipMonths',
          id: 'vehicle-ownership-months-input',
          selectLabel: 'Length of time',
          selectLabelId: 'vehicle-ownership-label',
          // todo: set this to empty array or keep default options?
          options: vehicleOwnershipYears,
          required: true
        }
      ],
      successRoute: '/add-vehicles/vehicles-list'
    };
  }

  componentDidMount() {
    const {
      askEmergencyBraking,
      askAntiTheft,
      askVinEtching,
      editingVehicle,
      askAirbagVerification
    } = this.props;

    const { successRoute } = this.state;

    // if we don't have an editing vehicle, return
    if (!editingVehicle) {
      return;
    }

    const { fields } = this.state;

    let updatedSuccessRoute = successRoute;

    // check for anti theft first
    if (askAntiTheft) {
      updatedSuccessRoute = '/add-vehicles/anti-theft';
      // if we don't have that, check to see if we need to ask emergency braking still
    } else if (askEmergencyBraking && editingVehicle.newerThan2011) {
      updatedSuccessRoute = '/add-vehicles/emergency-braking';
    } else if (askVinEtching) {
      updatedSuccessRoute = '/add-vehicles/vin-etching';
    } else if (askAirbagVerification) {
      updatedSuccessRoute = '/add-vehicles/airbags';
    }

    const updatedOptions =
      fieldTools.optionValues.OWNERSHIP_LENGTH(editingVehicle);

    const updatedFields = [
      {
        ...fields[0],
        initialValue: editingVehicle.vehicleOwnershipMonths,
        options: updatedOptions
      }
    ];

    this.setState({ fields: updatedFields, successRoute: updatedSuccessRoute });
  }

  render() {
    const { editingVehicle } = this.props;
    const { prompt, submitAction, fields, successRoute } = this.state;

    return !editingVehicle ? (
      <Redirect to="/add-vehicles/vehicles-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  vehicles: { editingVehicle },
  app: {
    askEmergencyBraking,
    askAntiTheft,
    askVinEtching,
    askAirbagVerification
  }
}) => ({
  askEmergencyBraking,
  askAntiTheft,
  askVinEtching,
  editingVehicle,
  askAirbagVerification
});

VehicleOwnershipLengthPage.propTypes = {
  editingVehicle: PropTypes.shape({
    newerThan2011: PropTypes.bool.isRequired,
    vehicleOwnershipMonths: PropTypes.string
  }),
  askEmergencyBraking: PropTypes.bool.isRequired,
  askAntiTheft: PropTypes.bool.isRequired,
  askVinEtching: PropTypes.bool.isRequired,
  askAirbagVerification: PropTypes.bool.isRequired
};

VehicleOwnershipLengthPage.defaultProps = {
  editingVehicle: {
    vehicleOwnershipMonths: ''
  }
};

export default connect(mapStateToProps)(VehicleOwnershipLengthPage);
