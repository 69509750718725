import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

function GoodStudentDiscount(props) {
  const [prompt, setPrompt] = useState(
    'Are you a college graduate with a B average or better?'
  );

  const [submitAction, setSubmitAction] = useState(
    'UPDATE_PRIMARY_DRIVER_REQUEST'
  );

  const editingDriver = useSelector(state => state.drivers?.editingDriver);

  const {
    location: { pathname }
  } = props;

  const successRoute = '/add-drivers/drivers-list';

  const isAdditionalDriverFlow =
    pathname === '/add-drivers/good-student-discount';

  useEffect(() => {
    if (isAdditionalDriverFlow) {
      setPrompt(
        'Is this driver a college graduate with a B average or better?'
      );
      setSubmitAction('UPDATE_ADDITIONAL_DRIVER_INFO');
    }
  }, [prompt, submitAction, isAdditionalDriverFlow]);

  const fields = [
    {
      type: 'radio',
      name: 'goodStudentDiscount',
      id: 'good-student-discount-select-input',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ],
      required: true,
      radioGroupAriaLabel: 'College Graduate Discount'
    }
  ];

  if (isAdditionalDriverFlow && !editingDriver) {
    return <Redirect to="/add-drivers/drivers-list" replace />;
  }

  return (
    <StyledContentWrapper>
      <Form
        prompt={prompt}
        fields={fields}
        submitAction={submitAction}
        successRoute={successRoute}
        submitAsync
        renderFieldRow={false}
        maxWidth="500px"
      />
    </StyledContentWrapper>
  );
}

export default GoodStudentDiscount;
