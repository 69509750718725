/* eslint-disable default-param-last */
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  SEND_ONE_TIME_PASS_SUCCESS,
  VERIFY_OTP_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SHOW_OTP_ERROR,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  CREATE_NOBLR_ACCOUNT,
  VERIFY_EMAIL_LINK,
  VERIFY_EMAIL_LINK_SUCCESS,
  VERIFY_EMAIL_LINK_FAILURE,
  UPDATE_AUTH_STATUS,
  UPDATE_TO_TRIAL_SUCCESS
} from '../actions';

const INITIAL_AUTH_STATE = {
  email: '',
  emailToConfirm: null,
  loggedIn: false,
  trialActive: false,
  policyActive: false,
  isTrial: false,
  state: '',
  isLinkValid: false,
  showOTPError: false,
  userNotFound: null,
  otpSent: false,
  passwordReset: null,
  error: null,
  accountCreated: null
};

const authReducer = (state = INITIAL_AUTH_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_OTP_ERROR:
      return {
        ...state,
        userNotFound: true,
        showOTPError: true,
        error: action.error.data.message
      };

    case CREATE_ACCOUNT:
      return {
        ...state,
        isTrial: payload.trialStatus,
        trialActive: payload.trialStatus,
        authType: payload.authType,
        accountCreated: 'pending'
      };

    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountCreated: true
      };

    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        accountCreated: false
      };

    case CREATE_NOBLR_ACCOUNT:
      return {
        ...state,
        accountCreated: 'pending',
        authType: 'noblr'
      };

    case LOGIN_USER:
      return {
        ...state,
        email: payload.email,
        authType: payload.authType,
        loggedIn: 'pending',
        error: null
      };

    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        loggedIn: true
      };

    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loggedIn: false,
        error: action.error
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        emailToConfirm: payload.email
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: payload.passwordReset
      };

    case SEND_ONE_TIME_PASS_SUCCESS:
      return {
        ...state,
        otpSent: true,
        emailToConfirm: payload.emailToConfirm
      };

    case VERIFY_EMAIL_LINK:
      return {
        ...state,
        isLinkValid: false
      };

    case VERIFY_EMAIL_LINK_SUCCESS:
      return {
        ...state,
        isLinkValid: true
      };

    case VERIFY_EMAIL_LINK_FAILURE:
      return {
        ...state,
        isLinkValid: false,
        error: action.error
      };

    case UPDATE_AUTH_STATUS:
      return {
        ...state,
        ...payload
      };

    case UPDATE_TO_TRIAL_SUCCESS:
      return {
        ...state,
        isTrial: true,
        trialActive: true
      };

    default:
      return state;
  }
};

export default authReducer;
