import React, {
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Container, Box, Grid } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import Form from 'components/Form/Form';
import Modal from 'components/Modal';
import { makeSelectCurrentExcludedDriver } from 'redux/selectors';
import { StyledContentWrapper, ModalHeader } from 'styles';

const ButtonGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const ModalPrimaryButton = styled(Button)`
  margin: ${props => (props.margin ? props.margin : '0.125rem auto')};
  padding: 8px;
  height: auto;
`;

function ConfirmDriverAMP(props) {
  const {
    location: { pathname },
    history
  } = props;

  const { driverId } = useParams();

  const dispatch = useDispatch();
  const prevLocation = useRef(pathname);
  const unListen = useRef();

  const [prompt, setPromptText] = useState();
  // memoize makeSelectCurrentExcludedDriver
  const selectCurrentExcludedDriver = useMemo(
    makeSelectCurrentExcludedDriver,
    []
  );

  // use selector to pull excluded driver from state
  const excludedDriver = useSelector(state =>
    selectCurrentExcludedDriver(state)
  );

  const backArrowListener = useCallback(() => {
    if (prevLocation !== pathname) {
      history.go(1);
      dispatch({ type: 'OPEN_MODAL', payload: 'driverExclusionModal' });
    }
  }, [history, pathname, dispatch]);

  useEffect(() => {
    if (prevLocation !== pathname) {
      history.push(pathname, '', '');

      unListen.current = history.listen((newLocation, action) => {
        if (action === 'POP') {
          backArrowListener();
        }
      });
    }

    return unListen.current;
  }, [backArrowListener, pathname, history]);

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleNavigation = () => {
    handleClose();
    dispatch({
      type: 'REDIRECT',
      payload: { url: '/driver-exclusion/start' }
    });
  };

  const fields = [
    {
      type: 'radio',
      name: 'activeMilitaryPersonnel',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ],
      required: true,
      radioGroupAriaLabel: 'Active Military Discount'
    }
  ];

  useEffect(() => {
    if (excludedDriver && excludedDriver.firstName) {
      const prompt = `Is ${excludedDriver.firstName} driver serving on active military duty, or are they the spouse or dependent of someone who is on active military duty?`;

      setPromptText(prompt);
    }
  }, [setPromptText, excludedDriver]);

  const successRoute = `/driver-exclusion/${driverId}/confirmation`;
  const submitAction = 'UPDATE_EXCLUDED_DRIVER_INFO';

  return (
    <Container>
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          successRoute={successRoute}
          submitAction={submitAction}
          submitAsync
          makeApiCall
          buttonMarginTop="20px"
        />
      </StyledContentWrapper>
      <Modal
        inline
        modalLabel="Confirm Driver Active Military Personnel"
        modalName="activeMilitaryPersonnelDriverExclusionModal"
      >
        <Box>
          <Grid container>
            <ModalHeader>
              {`Clicking 'BACK' will take you to the section start page and reset the list of drivers you've gone through.`}
            </ModalHeader>
            <ButtonGrid container item>
              <Grid item xs sm>
                <ModalPrimaryButton large onClick={handleClose}>
                  CANCEL
                </ModalPrimaryButton>
              </Grid>
              <Grid item xs sm>
                <ModalPrimaryButton primary large onClick={handleNavigation}>
                  BACK
                </ModalPrimaryButton>
              </Grid>
            </ButtonGrid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default ConfirmDriverAMP;
