import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';
import { HOME_OWNER_OPTIONS } from '../../constants';

class HomeOwnershipPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Do you rent or own your home?',
      submitAction: 'SET_HOMEOWNER',
      fields: [
        {
          type: 'select',
          name: 'homeOwner',
          id: 'home-ownership-select-input',
          selectLabelId: 'home-ownership-label',
          selectLabel: 'Home Ownership',
          options: HOME_OWNER_OPTIONS,
          required: true,
          initialValue: 'Rent'
        }
      ],
      successRoute: '/start-quote/education'
    };
  }

  componentDidMount() {
    const { fields } = this.state;
    const {
      primaryDriver: { homeOwner }
    } = this.props;

    if (homeOwner) {
      const initializedFields = fields.map(field => {
        const currentField = { ...field };

        currentField.initialValue = homeOwner;

        return currentField;
      });

      this.setState({
        fields: initializedFields
      });
    }
  }

  render() {
    const { prompt, submitAction, fields, successRoute } = this.state;

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({ drivers: { primaryDriver } }) => ({
  primaryDriver
});

HomeOwnershipPage.propTypes = {
  primaryDriver: PropTypes.shape({
    homeOwner: PropTypes.string
  })
};

HomeOwnershipPage.defaultProps = {
  primaryDriver: {
    homeOwner: ''
  }
};

export default connect(mapStateToProps)(HomeOwnershipPage);
