import * as Sentry from '@sentry/react';
import {
  put,
  call,
  select,
  actionChannel,
  take,
  putResolve
} from 'redux-saga/effects';
import Noblr from '@noblr-lab/react-app-services';
import {
  updateMultipleDeductiblesSuccess,
  updateMultipleDeductiblesFailure
} from 'redux/actions';
import { buildDeductiblePayload } from 'utilities/coverage';

/**
 *
 * fetch product data from v2 endpoint
 */
export function* productDataV2Subroutine() {
  yield put({
    type: 'TOGGLE_LOADER',
    payload: { toShow: true }
  });

  try {
    const response = yield call(Noblr.product.getProductDataV2);
    const {
      productData: { coveragePackages, coverages, uxCoverageRules }
    } = response;

    // make moderate package default package
    const defaultPackage = coveragePackages.allIds[1];
    const [ratePlanStateCode] =
      coveragePackages.byId[defaultPackage].key.split('|');

    const ratePlanState = ratePlanStateCode.slice(0, 2);

    const rulesByCoverage = uxCoverageRules.reduce((accumulator, uxRule) => {
      const { key } = uxRule;

      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      accumulator[key] = [...accumulator[key], { ...uxRule }];

      return accumulator;
    }, {});

    let coveragesConfig = { ...coverages };

    // if state is MD, split enhanced and standard options for easy access
    if (ratePlanState === 'MD') {
      const splitUmuimbi =
        coverages.byId.umuimbi &&
        coverages.byId.umuimbi.values.allIds.reduce(
          (acc, valueId) => {
            if (valueId.endsWith('_NON_ENHANCED')) {
              acc.standardOptions = [...acc.standardOptions, valueId];
            } else {
              acc.enhancedOptions = [...acc.enhancedOptions, valueId];
            }

            return acc;
          },
          { enhancedOptions: [], standardOptions: [] }
        );

      const splitUmuimpd =
        coverages.byId.umuimpd &&
        coverages.byId.umuimpd.values.allIds.reduce(
          (acc, valueId) => {
            if (valueId.endsWith('_NON_ENHANCED')) {
              acc.standardOptions = [...acc.standardOptions, valueId];
            } else {
              acc.enhancedOptions = [...acc.enhancedOptions, valueId];
            }

            return acc;
          },
          { enhancedOptions: [], standardOptions: [] }
        );

      const updatedUMCoverageOptions = {
        ...coverages.byId,
        umuimbi: {
          ...coverages.byId.umuimbi,
          values: {
            ...coverages.byId.umuimbi.values,
            byId: { ...coverages.byId.umuimbi.values.byId },
            allIds: [...coverages.byId.umuimbi.values.allIds],
            ...splitUmuimbi
          }
        },
        umuimpd: {
          ...coverages.byId.umuimpd,
          values: {
            ...coverages.byId.umuimpd.values,
            byId: { ...coverages.byId.umuimpd.values.byId },
            allIds: [...coverages.byId.umuimpd.values.allIds],
            ...splitUmuimpd
          }
        }
      };

      coveragesConfig = {
        ...coveragesConfig,
        byId: { ...updatedUMCoverageOptions }
      };
    } else if (
      ratePlanState === 'GA' ||
      (ratePlanState === 'VA' && ratePlanStateCode !== 'VA1000')
    ) {
      const splitUmuimbi =
        coverages.byId.umuimbi &&
        coverages.byId.umuimbi.values.allIds.reduce(
          (acc, valueId) => {
            if (valueId.endsWith('_REDUCED_BY')) {
              acc.reducedBy = [...acc.reducedBy, valueId];
            } else {
              acc.addedOn = [...acc.addedOn, valueId];
            }

            return acc;
          },
          { reducedBy: [], addedOn: [] }
        );

      const splitUmuimpd =
        coverages.byId.umuimpd &&
        coverages.byId.umuimpd.values.allIds.reduce(
          (acc, valueId) => {
            if (valueId.endsWith('_NONE')) {
              // Add NONE to both reducedBy and addedOn
              acc.reducedBy = [...acc.reducedBy, valueId];
              acc.addedOn = [...acc.addedOn, valueId];
            } else if (valueId.endsWith('_REDUCED_BY')) {
              acc.reducedBy = [...acc.reducedBy, valueId];
            } else {
              acc.addedOn = [...acc.addedOn, valueId];
            }

            return acc;
          },
          { reducedBy: [], addedOn: [] }
        );

      const updatedUMCoverageOptions = {
        ...coverages.byId,
        umuimbi: {
          ...coverages.byId.umuimbi,
          values: {
            ...coverages.byId.umuimbi.values,
            byId: { ...coverages.byId.umuimbi.values.byId },
            allIds: [...coverages.byId.umuimbi.values.allIds],
            ...splitUmuimbi
          }
        },
        umuimpd: {
          ...coverages.byId.umuimpd,
          values: {
            ...coverages.byId.umuimpd.values,
            byId: { ...coverages.byId.umuimpd.values.byId },
            allIds: [...coverages.byId.umuimpd.values.allIds],
            ...splitUmuimpd
          }
        }
      };

      coveragesConfig = {
        ...coveragesConfig,
        byId: { ...updatedUMCoverageOptions }
      };
    } else if (ratePlanState === 'VA' && ratePlanStateCode === 'VA1000') {
      const updatedUMCoverageOptions = {
        ...coverages.byId,
        umuimbi: {
          ...coverages.byId.umuimbi,
          values: {
            ...coverages.byId.umuimbi.values,
            byId: { ...coverages.byId.umuimbi.values.byId },
            allIds: [...coverages.byId.umuimbi.values.allIds]
          }
        },
        umuimpd: {
          ...coverages.byId.umuimpd,
          values: {
            ...coverages.byId.umuimpd.values,
            byId: { ...coverages.byId.umuimpd.values.byId },
            allIds: [...coverages.byId.umuimpd.values.allIds]
          }
        }
      };

      coveragesConfig = {
        ...coveragesConfig,
        byId: { ...updatedUMCoverageOptions }
      };
    }

    yield putResolve({
      type: 'GET_PRODUCT_DATA_SUCCESS',
      payload: {
        coveragePackages,
        config: coveragesConfig,
        rulesByCoverage,
        defaultPackage,
        ratePlanStateCode
      }
    });

    yield put({
      type: 'STORE_RATE_PLAN_STATE_CODE',
      payload: ratePlanStateCode
    });
  } catch (error) {
    // Set Sentry Tag
    Sentry.withScope(scope => {
      scope.setTag('getProductDataV2Failed', true);
    });

    yield put({
      type: 'GET_PRODUCT_DATA_ERROR',
      error
    });
  } finally {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });
  }
}

/**
 * Fetches mileage from Noblr API
 * Sets 15 as default value for mileage if mileage wasn't saved in quote
 */
export function* getMileage() {
  try {
    let { mileage } = yield call(Noblr.coverage.getMileage);
    let savedMileage = true;

    // no mileage was saved, set default mileage value to 15
    if (!mileage) {
      mileage = 15;

      savedMileage = false;
    }
    yield put({
      type: 'GET_MILEAGE_SUCCESS',
      payload: { mileage, savedMileage }
    });
  } catch (error) {
    yield put({
      type: 'GET_MILEAGE_FAILURE',
      error: { status: error.status, ...error.data }
    });
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Saves mileage to backend and update in redux store
 * @param {Object} payload
 * @param {number} payload.mileage - selected mileage value
 */
export function* saveMileage({ payload }) {
  // Send request to Noblr App Services endpoint
  try {
    const { mileage } = yield call(Noblr.coverage.saveMileage, payload.mileage);

    // Store mileage value in coverage reducer
    yield put({
      type: 'SAVE_MILEAGE_SUCCESS',
      payload: { mileage }
    });
  } catch (error) {
    yield put({
      type: 'DISPLAY_ASYNC_ERROR',
      error: error.data.message
    });
  }
}

/**
 * Calls endpoint to update mileage
 * @param {Object} payload
 * @param {number} payload.mileage - selected mileage value
 */
export function* updateMileage({ payload }) {
  // Send request to Noblr App Services endpoint
  try {
    const { mileage } = yield call(
      Noblr.coverage.updateMileage,
      payload.mileage
    );

    // Store mileage value in coverage reducer
    yield put({
      type: 'UPDATE_MILEAGE_SUCCESS',
      payload: { mileage }
    });
  } catch (error) {
    yield put({
      type: 'DISPLAY_ASYNC_ERROR',
      error: error.data.message
    });
  }
}

/**
 * Get saved persona from backend
 * on successful call, get rate
 */
export function* getPersona() {
  const {
    coverage: { personaCode: storedPersonaCode, personaSaved: isPersonaSaved }
  } = yield select();

  // Request persona code from Noblr App Services endpoint
  try {
    const { personaCode } = yield call(Noblr.coverage.getPersona);
    // if personaCode is null, the personaCode hasn't been saved
    const personaSaved =
      !storedPersonaCode &&
      isPersonaSaved &&
      !Number.isNaN(parseInt(personaCode, 10));

    const initialPersonaCode = Number.isNaN(parseInt(personaCode, 10))
      ? 1.5
      : personaCode;

    // pass persona and whether it's been saved to coverage store
    yield put({
      type: 'GET_PERSONA_SUCCESS',
      payload: { personaCode: initialPersonaCode, personaSaved }
    });
  } catch (error) {
    yield put({
      type: 'GET_PERSONA_FAILURE',
      error: {
        status: error.status,
        ...error.data
      }
    });

    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Saves persona code
 * @param {Object} payload
 * @param {number} payload.personaCode - number that maps to persona
 */
export function* savePersona({ payload }) {
  const {
    coverage: { defaultPackage },
    rate: { gotRate }
  } = yield select();

  // Send request to Noblr App Services endpoint
  try {
    const { personaCode } = yield call(
      Noblr.coverage.savePersona,
      Math.floor(payload.personaCode)
    );

    yield put({
      type: 'SAVE_PERSONA_SUCCESS',
      payload: {
        personaCode,
        personaSaved: true
      }
    });

    if (gotRate) {
      yield putResolve({ type: 'CLEAR_RATE' });
    }

    // fetch rate
    yield put({
      type: 'GET_RATE_BY_PACKAGE',
      payload: {
        currentPackage: defaultPackage,
        queryParams: { savePackage: true }
      },
      shouldRedirect: true
    });
  } catch (error) {
    yield put({
      type: 'SAVE_PERSONA_FAILURE',
      error
    });

    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Updates persona code
 * @param {Object} payload
 * @param {number} payload.personaCode - number that maps to persona
 */
export function* updatePersona({ payload }) {
  const {
    coverage: { defaultPackage },
    rate: { gotRate }
  } = yield select();

  try {
    const { personaCode } = yield call(
      Noblr.coverage.updatePersona,
      Math.floor(payload.personaCode)
    );

    yield put({
      type: 'UPDATE_PERSONA_SUCCESS',
      payload: { personaCode, personaSaved: true }
    });

    if (gotRate) {
      yield putResolve({ type: 'CLEAR_RATE' });
    }

    yield put({
      type: 'GET_RATE_BY_PACKAGE',
      payload: {
        currentPackage: defaultPackage,
        queryParams: { savePackage: true }
      },
      shouldRedirect: true
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });

    yield put({
      type: 'UPDATE_PERSONA_FAILURE',
      error
    });
  }
}

/**
 * Calls endpoint to update mileage and personaCode
 * @param {Object} payload
 * @param {number} payload.mileage  - selected mileage value
 * @param {number} payload.personaCode - number that maps to personaCode
 * @param successRoute - url to redirect to after successful save
 */
export function* updateMileageAndPersonaRoutine({
  payload: { mileage, personaCode },
  successRoute
}) {
  // Send request to Noblr App Services endpoint
  try {
    yield call(Noblr.coverage.updateMileageAndPersona, mileage, personaCode);

    yield put({
      type: 'UPDATE_MILEAGE_AND_PERSONA_SUCCESS',
      payload: { mileage, personaCode }
    });

    yield put({
      type: 'REDIRECT',
      payload: {
        url: successRoute
      }
    });
  } catch (error) {
    yield put({
      type: 'UPDATE_MILEAGE_AND_PERSONA_FAILURE',
      error
    });
  }
}

/**
 * Get saved coverage values from Noblr API
 */
export function* getCoverage() {
  try {
    const response = yield call(Noblr.coverage.getCoverage);

    // assumes coverage has been saved but if no response from response, set to false
    let savedCoverage = true;

    if (!response) {
      savedCoverage = false;
    }

    yield put({
      type: 'SAVE_COVERAGE_SUCCESS',
      payload: { ...response, savedCoverage }
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Calls endpoint to save selected coverage package
 * @param {Object} payload
 * @param {string} payload.packageType - min | mod | max coverage package
 */
export function* saveSelectedCoverageType({ payload }) {
  // get package type off of payload
  const { packageType, successRoute } = payload;

  // Send request to Noblr App Services endpoint
  try {
    const response = yield call(Noblr.coverage.saveCoverage, packageType);

    yield put({
      type: 'SAVE_COVERAGE_SUCCESS',
      payload: response
    });

    yield put({
      type: 'REDIRECT',
      payload: { url: successRoute }
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Calls Quote Service and updates saved coverage package
 * @param {Object} payload
 * @param {string} payload.packageType - min | mod | max coverage package
 */
export function* updateCoverage({ payload }) {
  const { packageType, successRoute } = payload;

  // Send request to Noblr App Services endpoint
  try {
    const response = yield call(Noblr.coverage.updateCoverage, packageType);

    yield put({
      type: 'UPDATE_COVERAGE_SUCCESS',
      payload: response
    });
    yield put({
      type: 'REDIRECT',
      payload: { url: successRoute }
    });
  } catch (error) {
    yield put({
      type: 'DISPLAY_ASYNC_ERROR',
      error
    });
  }
}

/**
 * Calls endpoint to save multiple deductibles
 * Selects coverages from redux store if they're available
 * @param {Array} payload - array of vehicle coverages
 */
export function* saveDeductiblesArray({
  payload,
  successRoute,
  setSubmitting
}) {
  // check for combined coverage options
  const {
    coverage: {
      combineCollAndUmpd,
      combineCollAndUmuimpd,
      combineCompAndGlassComp
    }
  } = yield select();

  const combinedDeductiblesConfig = {
    combineCollAndUmpd,
    combineCollAndUmuimpd,
    combineCompAndGlassComp
  };

  // build payload
  // todo: remove once we implement V2 endpoint
  const payloadToSave = payload.map(vehicle =>
    buildDeductiblePayload(vehicle, combinedDeductiblesConfig)
  );

  const vehicleIds = payload.map(vehicle => vehicle.vehicleId);

  // Send request to Noblr App Services endpoint
  try {
    yield call(Noblr.coverage.saveMultipleDeductibles, payloadToSave);

    // keep track of vehicles with saved coverages
    yield put({
      type: 'SAVE_MULTIPLE_DEDUCTIBLES_SUCCESS',
      payload: {
        savedVehicleIds: vehicleIds
      }
    });

    // redirect to on success route
    yield put({
      type: 'REDIRECT',
      payload: { url: successRoute }
    });
  } catch (error) {
    if (error.status === 409) {
      // deductibles already exist and need to be updated
      yield put({
        type: 'UPDATE_MULTIPLE_DEDUCTIBLES',
        payload
      });
    } else {
      yield put({
        type: 'SAVE_MULTIPLE_DEDUCTIBLES_FAILURE',
        error
      });
    }
  } finally {
    setSubmitting(false);
  }
}

/**
 * Update saved vehicle coverages
 * V1 api
 * TODO: Replace when we switch to V2
 * @param {Object} payload - array of vehicle coverages
 */
export function* updateDeductiblesArray({
  payload,
  successRoute,
  setSubmitting
}) {
  const {
    coverage: {
      combineCollAndUmpd,
      combineCollAndUmuimpd,
      combineCompAndGlassComp
    }
  } = yield select();

  const combinedDeductiblesConfig = {
    combineCollAndUmpd,
    combineCollAndUmuimpd,
    combineCompAndGlassComp
  };

  const vehicleIds = payload.map(vehicle => vehicle.vehicleId);
  const payloadToSave = payload.map(vehicle =>
    buildDeductiblePayload(vehicle, combinedDeductiblesConfig)
  );

  // Send request to Noblr App Services endpoint
  try {
    yield call(Noblr.coverage.updateMultipleDeductibles, payloadToSave);

    yield put(updateMultipleDeductiblesSuccess(vehicleIds));

    // redirect to on success route
    yield put({
      type: 'REDIRECT',
      payload: { url: successRoute }
    });
  } catch (error) {
    const failure = yield call(updateMultipleDeductiblesFailure, error);

    yield put(failure);
  } finally {
    setSubmitting(false);
  }
}

export function* productDataWatcher() {
  const requestChan = yield actionChannel('SET_SECTION');

  while (true) {
    const {
      payload: { section }
    } = yield take(requestChan);

    if (section === 'add-vehicles') {
      yield put({
        type: 'GET_PRODUCT_DATA'
      });
    }
  }
}
