import React from 'react';
import styled from 'styled-components/macro';
import { openSans } from 'styles/variables';

const StyledAnchorTag = styled.a`
  font-family: ${openSans};
  font-size: 14px;
  text-decoration: underline;
`;

function NoblrPhoneLink() {
  return (
    <StyledAnchorTag
      href="tel:+18772366257"
      aria-label="1. 8 7 7. 2 3 6. 6 2 5 7"
    >
      (877) 236-6257
    </StyledAnchorTag>
  );
}

export default NoblrPhoneLink;
