import * as Sentry from '@sentry/react';
import Noblr from '@noblr-lab/react-app-services';
import { put, call, select, putResolve } from 'redux-saga/effects';
import {
  generateRestrictUMValue,
  roundMileageValue,
  determinePurchaseStartPage
} from 'utilities/coverage';

/**
 * Calls Noblr v1 API to get rate
 */
export function* getRateRequest({
  payload,
  successRoute,
  showTrial,
  queryParams = {}
}) {
  const {
    app,
    auth,
    drivers: { primaryDriver }
  } = yield select();

  const params = payload.queryParams || queryParams;

  if (payload.currentPackage) {
    yield put({
      type: 'SET_CURRENT_PACKAGE',
      payload: payload.currentPackage
    });
  }
  try {
    if (params) {
      Sentry.setTag('quote_retrieval', params.isQuoteRetrieval);
    }

    Sentry.setTag('quote_id', primaryDriver.quoteId);

    Sentry.setUser({ id: primaryDriver.personId });

    // set initial endpoint to get rate
    let rateEndpoint = Noblr.quote.getRate;

    // but if we have a trial user, change endpoint to get trial rate
    if ((auth && auth.trialActive) || (app && app.trialActive) || showTrial) {
      rateEndpoint = Noblr.quote.getTrialRate;

      yield put({
        type: 'UPDATE_POLICY',
        payload: { trialActive: auth.trialActive || app.trialActive }
      });
    }

    const response = yield call(rateEndpoint, { ...params });

    const {
      firstPossibleEffectiveDate,
      lastPossibleEffectiveDate,
      requiresUmuimOfferForm,
      personaToMileageToVariablePremiumMap,
      noblrCreditScore: {
        creditFactors: { factor }
      }
    } = response;

    // send rate result to reducer and update state
    yield put({
      type: 'GET_RATE_SUCCESS',
      // make credit factors easier to access
      payload: {
        ...response,
        personaToMileageToVariablePremiumMap,
        creditFactors: factor
      }
    });

    // get quote id
    const { quoteId } = primaryDriver;

    // todo: determine if we still use this
    const dataLayer = window.dataLayer || [];

    // and push it to data layer, for GTM tracking
    dataLayer.push({ quoteId });

    // update the primary driver with the email returned from the endpoint
    // this is so we have it when they create an account in case they retrieved their quote
    yield put({
      type: 'UPDATE_PRIMARY_DRIVER',
      payload: {
        email: response.email
      }
    });

    // store effective date and set boolean for az doc flow from rate call on policy reducer
    yield put({
      type: 'UPDATE_POLICY',
      payload: {
        firstPossibleEffectiveDate,
        lastPossibleEffectiveDate,
        requiresUmuimOfferForm
      }
    });

    // hide loader
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: false
      }
    });
    // redirect to quote page
    yield put({
      type: 'REDIRECT',
      payload: {
        url: successRoute || '/quote/review-your-quote'
      }
    });
  } catch (error) {
    // catch any error and set it in redux
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Used to fetch rate with package: basic, moderate
 */
export function* getRateByPackageSubroutine({
  payload: {
    currentPackage = null,
    queryParams,
    policyCoverages = null,
    vehicleCoverages = null
  },
  shouldRedirect = false,
  successRoute = null
}) {
  let rateEndpoint = Noblr.quote.getRateByPackage;

  const {
    auth,
    app: { trialActive },
    drivers: {
      primaryDriver: { state: primaryDriverState }
    },
    rate: { ratePlanState },
    coverage: { defaultPackage, savedCoverage, type },
    excludedDrivers: {
      requiresUpdatedRates,
      allDriversExcluded,
      excludedDriverCount
    }
  } = yield select();

  // if we have a trial user, change endpoint to get trial rate
  if ((auth && auth.trialActive) || trialActive) {
    rateEndpoint = Noblr.quote.getTrialRateByPackage;

    yield put({
      type: 'UPDATE_POLICY',
      payload: {
        trialActive: auth.trialActive || trialActive
      }
    });
  }

  // clear any coverage rule options
  yield put({
    type: 'CLEAR_COVERAGE_RULE_OPTIONS'
  });

  const packageType = currentPackage || (savedCoverage ? type : defaultPackage);

  if (queryParams.isQuoteRetrieval && !currentPackage) {
    yield put({
      type: 'CLEAR_RATE'
    });
  }

  let requestPayload = {
    packageType
  };

  /*
     if success route and redirect are not passed in action payload and
     all drivers are excluded and rates are requested

  */
  if (
    !shouldRedirect &&
    !successRoute &&
    requiresUpdatedRates &&
    allDriversExcluded &&
    excludedDriverCount > 0 &&
    policyCoverages &&
    vehicleCoverages
  ) {
    /*
      driver exclusion flow requirement -
      pass previously selected package and coverages in rate request
      so updated price is displayed on effective date page
    */
    requestPayload = {
      ...requestPayload,
      policyCoverages,
      vehicleCoverages
    };
  }
  // fetch rate from endpoint
  try {
    const {
      estInstallmentPaymentSchedule,
      estRewardSchedule,
      fixedRatePerMonth,
      totalRatePerMile,
      totalQuoteRate,
      variableRatePerMonth,
      maxQuoteRate,
      mileage,
      personaCode,
      rewards,
      vehicles,
      insuranceHistory,
      coverage,
      coverageV2,
      requiresDynamicForms,
      requiresUmuimOfferForm,
      requiresVehicleAssignment,
      noblrCreditScore: {
        creditFactors: { factor }
      },
      firstPossibleEffectiveDate,
      lastPossibleEffectiveDate,
      pickerEffectiveStartDate,
      priceDifference,
      priceIncrease,
      personaToMileageToVariablePremiumMap,
      dlVerificationRequired,
      vinVerificationNeeded,
      customPreBindForms, // dynamic pre-bind forms
      requiresDriverExclusion,
      drivers, // all active drivers
      removedDrivers, // removed (not excluded) drivers
      excludedDrivers // excluded (not removed or added) drivers
    } = yield call(
      rateEndpoint,
      // on quote retrieval we may not have currentPackage yet
      { ...requestPayload },
      queryParams
    );

    let currentPackageType = null;

    if (!packageType && coverage.packageType) {
      currentPackageType = coverage.packageType;
    } else {
      currentPackageType = packageType;
    }

    const currentState = ratePlanState || primaryDriverState;
    const restrictUM = generateRestrictUMValue(currentState, coverageV2);

    if (restrictUM) {
      yield put({
        type: 'RESTRICT_UM_OPTIONS',
        payload: restrictUM
      });
    }

    if (dlVerificationRequired) {
      yield putResolve({
        type: 'UPDATE_APP',
        payload: {
          requiresDLVerification: dlVerificationRequired,
          requiresDriverExclusion,
          requiresDynamicForms
        }
      });
    }

    /* set initial dynamic prebind form stack */
    if (customPreBindForms.allIds.length > 0) {
      const pendingFormIds = customPreBindForms.allIds.slice();
      const currentFormId = pendingFormIds.shift();
      let nextFormId = null;

      if (pendingFormIds.length > 0) {
        nextFormId = pendingFormIds.shift();
      }

      yield put({
        type: 'SET_PENDING_FORMS',
        payload: {
          pendingFormIds,
          currentFormId,
          nextFormId,
          customPreBindForms
        }
      });
    }

    // check if mileage is in personaToMileageToVariablePremiumMap
    const validMileageOptions = Object.keys(
      personaToMileageToVariablePremiumMap[0]
    );

    // items in validMileageOptions are strings so look for mileage as a string in the array

    const roundingMileage = roundMileageValue(mileage);

    const mileageToStore = validMileageOptions.includes(`${roundingMileage}`)
      ? roundingMileage
      : 15;

    // get vehicle coverages from all vehicles returned in rate call
    const vehicleCoverages = {};

    vehicles.map(
      ({ vehicleId, vehicleCoveragesV2 }) =>
        // check that we have vehicleCoveragesV2, basic and moderate packages don't
        vehicleCoveragesV2 &&
        Object.keys(vehicleCoveragesV2).map(key => {
          if (key === 'umuimpd' && vehicleCoveragesV2[key] === null) {
            vehicleCoverages[`${vehicleId}-${key}`] = 'UMUIMPD_NONE';
          } else if (key === 'umpd' && vehicleCoveragesV2[key] === null) {
            vehicleCoverages[`${vehicleId}-${key}`] = 'UMPD_NONE';
          } else {
            vehicleCoverages[`${vehicleId}-${key}`] = vehicleCoveragesV2[key];
          }

          return key;
        })
    );

    yield putResolve({
      type: 'GET_RATE_BY_PACKAGE_SUCCESS',
      payload: {
        firstPossibleEffectiveDate,
        lastPossibleEffectiveDate,
        vinVerificationNeeded,
        currentPackage: currentPackageType,
        rate: {
          estInstallmentPaymentSchedule,
          estRewardSchedule,
          fixedRatePerMonth,
          totalRatePerMile,
          totalQuoteRate,
          variableRatePerMonth,
          maxQuoteRate,
          mileage: mileageToStore,
          personaCode,
          rewards,
          drivers,
          vehicles,
          insuranceHistory,
          biLimits: coverage.bi,
          creditFactors: factor,
          coverage: { ...coverageV2, ...vehicleCoverages },
          priceDifference,
          priceIncrease,
          personaToMileageToVariablePremiumMap
        }
      }
    });

    if (requiresVehicleAssignment) {
      // if we need to display vehicle assignments, dispatch the action(s) to store them

      // set required flag
      yield put({
        type: 'UPDATE_APP',
        payload: {
          requiresVehicleAssignment: !!requiresVehicleAssignment,
          requiresDynamicForms,
          requiresDriverExclusion
        }
      });

      // reduce drivers to an object with the driver id as the key and vehicle id as value
      const vehicleAssignments = drivers.reduce((acc, driver) => {
        const { driverId, vehicleAssignments } = driver;
        // loop through all assignments, they can have type primary or excess
        const assignmentsByType =
          vehicleAssignments &&
          vehicleAssignments.reduce((acc, assignment) => {
            const { assignmentType } = assignment;
            const key = assignmentType.toLowerCase();

            acc[key] = acc[key] ? [...acc[key], assignment] : [assignment];

            return acc;
          }, {});

        acc[driverId] = {
          driverId,
          assignments: assignmentsByType
        };

        return acc;
      }, {});

      // this is an object with driver id keys to assignments by type
      // so now lets pass that object to an action the will dispatch the actions to update primary and excess assignments
      yield putResolve({
        type: 'SET_VEHICLE_ASSIGNMENTS',
        payload: vehicleAssignments
      });
    }

    if (vinVerificationNeeded) {
      yield putResolve({
        type: 'SET_RERATE',
        payload: { vinVerificationNeeded }
      });
    }

    yield put({
      type: 'UPDATE_APP',
      payload: {
        requiresDLVerification: dlVerificationRequired,
        requiresVehicleAssignment,
        requiresDynamicForms,
        requiresDriverExclusion
      }
    });

    yield put({
      type: 'UPDATE_POLICY',
      payload: {
        customPreBindForms,
        requiresUmuimOfferForm,
        firstPossibleEffectiveDate,
        lastPossibleEffectiveDate,
        pickerEffectiveStartDate,
        currentPackage:
          currentPackage || coverage.packageType || defaultPackage,
        estInstallmentPaymentSchedule,
        estRewardSchedule,
        fixedRatePerMonth,
        totalRatePerMile,
        totalQuoteRate,
        variableRatePerMonth,
        maxQuoteRate,
        mileage: mileageToStore,
        personaCode,
        rewards,
        drivers,
        vehicles,
        insuranceHistory,
        biLimits: coverage.bi,
        creditFactors: factor,
        coverage: { ...coverageV2, ...vehicleCoverages },
        priceDifference,
        priceIncrease,
        personaToMileageToVariablePremiumMap
      }
    });

    const {
      excludedDrivers: { requiresUpdatedRates, updatedRates }
    } = yield select();

    const driversPendingExclusion = [...removedDrivers, ...excludedDrivers];
    const totalDriversPendingExclusion = driversPendingExclusion.length;
    const hasExcludedDrivers = totalDriversPendingExclusion > 0;

    if (
      !requiresUpdatedRates &&
      !updatedRates &&
      requiresDriverExclusion &&
      hasExcludedDrivers
    ) {
      yield put({
        type: 'RESET_EXCLUDED_DRIVERS'
      });

      const byId = driversPendingExclusion.reduce((acc, driver) => {
        if (driver.driverId) {
          acc[driver.driverId] = { ...driver };
        }

        return acc;
      }, {});

      const [driver] = driversPendingExclusion.slice(0, 1);

      const driverIdsPendingExclusion = Object.keys(byId).filter(
        driverId => driverId !== driver.driverId
      );

      const allDriversExcluded = !hasExcludedDrivers;
      let nextExcludedDriverId = null;

      if (totalDriversPendingExclusion > 1) {
        [nextExcludedDriverId] = driverIdsPendingExclusion;
      }
      yield put({
        type: 'STORE_DRIVERS_PENDING_EXCLUSION',
        payload: {
          byId,
          driverIdsPendingExclusion,
          totalDriversToExclude: totalDriversPendingExclusion,
          driver,
          allDriversExcluded,
          nextExcludedDriverId,
          requiresUpdatedRates: vinVerificationNeeded
        }
      });
    } else if (requiresDriverExclusion && !hasExcludedDrivers) {
      /* If quote state requires DE flow but no active additional drivers or all active additional drivers were added to quote set allDriversExcludedTo "true" in excluded drivers reducer to redirect correctly

      */
      yield put({
        type: 'ALL_EXCLUDED_DRIVERS_CONFIRMED'
      });
    }

    if (shouldRedirect) {
      // check if we're on the review quote page before redirect
      // redirect to quote page
      yield put({
        type: 'REDIRECT',
        payload: {
          url: successRoute || '/quote/review-your-quote'
        }
      });
    }
  } catch (error) {
    // catch any error and set it in redux
    yield put({
      type: 'GET_RATE_BY_PACKAGE_FAILURE',
      error
    });

    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  } finally {
    // hide loader
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: false
      }
    });
  }
}

export function* getCustomCoverageRateSubroutine({
  payload: { policyCoverages, vehicleCoverages, queryParams, values },
  successRoute
}) {
  const {
    auth,
    app,
    rate: { vinVerificationNeeded: requiredVINVerification }
    // excludedDrivers: { allDriversExcluded, totalDriversToExclude }
  } = yield select();

  let rateEndpoint = Noblr.quote.getRateByPackage;

  // if we have a trial user, change endpoint to get trial rate
  if ((auth && auth.trialActive) || (app && app.trialActive)) {
    rateEndpoint = Noblr.quote.getTrialRateByPackage;

    yield put({
      type: 'UPDATE_POLICY',
      payload: { trialActive: auth.trialActive || app.trialActive }
    });
  }
  // fetch rate from endpoint
  try {
    const {
      estInstallmentPaymentSchedule,
      estRewardSchedule,
      fixedRatePerMonth,
      totalRatePerMile,
      totalQuoteRate,
      variableRatePerMonth,
      maxQuoteRate,
      mileage,
      personaCode,
      rewards,
      drivers,
      excludedDrivers,
      removedDrivers,
      vehicles,
      insuranceHistory,
      coverage,
      customPreBindForms,
      priceDifference,
      priceIncrease,
      noblrCreditScore: {
        creditFactors: { factor }
      },
      personaToMileageToVariablePremiumMap,
      dlVerificationRequired,
      vinVerificationNeeded,
      firstPossibleEffectiveDate,
      lastPossibleEffectiveDate,
      pickerEffectiveStartDate,
      requiresVehicleAssignment,
      requiresDriverExclusion,
      requiresDynamicForms
    } = yield call(
      rateEndpoint,
      { packageType: 'custom', policyCoverages, vehicleCoverages },
      queryParams
    );

    // check if mileage is in personaToMileageToVariablePremiumMap
    const validMileageOptions = Object.keys(
      personaToMileageToVariablePremiumMap[0]
    );

    // items in validMileageOptions are strings so look for mileage as a string in the array

    const roundingMileage = roundMileageValue(mileage);

    const mileageToStore = validMileageOptions.includes(`${roundingMileage}`)
      ? roundingMileage
      : 15;

    yield put({
      type: 'UPDATE_APP',
      payload: {
        requiresDLVerification: dlVerificationRequired,
        requiresDynamicForms,
        requiresDriverExclusion,
        requiresVehicleAssignment
      }
    });

    /* set initial dynamic prebind form stack */
    if (customPreBindForms.allIds.length > 0) {
      const pendingFormIds = customPreBindForms.allIds.slice();
      const currentFormId = pendingFormIds.shift();
      let nextFormId = null;

      if (pendingFormIds.length > 0) {
        nextFormId = pendingFormIds.shift();
      }

      yield put({
        type: 'SET_PENDING_FORMS',
        payload: {
          pendingFormIds,
          currentFormId,
          nextFormId,
          customPreBindForms
        }
      });
    }

    const coverageValues = values;

    yield putResolve({
      type: 'GET_CUSTOM_COVERAGE_RATE_SUCCESS',
      payload: {
        estInstallmentPaymentSchedule,
        estRewardSchedule,
        fixedRatePerMonth,
        totalRatePerMile,
        totalQuoteRate,
        variableRatePerMonth,
        maxQuoteRate,
        mileage: mileageToStore,
        personaCode,
        rewards,
        drivers,
        vehicles,
        insuranceHistory,
        biLimits: coverage.bi,
        creditFactors: factor,
        coverage: coverageValues,
        priceDifference,
        priceIncrease,
        personaToMileageToVariablePremiumMap,
        firstPossibleEffectiveDate,
        lastPossibleEffectiveDate,
        pickerEffectiveStartDate,
        vinVerificationNeeded
      }
    });

    yield put({
      type: 'UPDATE_POLICY',
      payload: {
        firstPossibleEffectiveDate,
        lastPossibleEffectiveDate,
        pickerEffectiveStartDate,
        estInstallmentPaymentSchedule,
        estRewardSchedule,
        fixedRatePerMonth,
        totalRatePerMile,
        totalQuoteRate,
        variableRatePerMonth,
        maxQuoteRate,
        mileage: mileageToStore,
        personaCode,
        rewards,
        drivers,
        vehicles,
        insuranceHistory,
        biLimits: coverage.bi,
        creditFactors: factor,
        coverage: values,
        priceDifference,
        priceIncrease,
        personaToMileageToVariablePremiumMap
      }
    });

    const driversPendingExclusion = [...removedDrivers, ...excludedDrivers];
    const hasExcludedDrivers = driversPendingExclusion.length > 0;

    if (requiresDriverExclusion && hasExcludedDrivers) {
      yield put({
        type: 'RESET_EXCLUDED_DRIVERS'
      });

      const byId = driversPendingExclusion.reduce((acc, driver) => {
        if (driver.driverId) {
          acc[driver.driverId] = { ...driver };
        }

        return acc;
      }, {});

      const [driver] = driversPendingExclusion.slice(0, 1);

      const driverIdsPendingExclusion = Object.keys(byId).filter(
        driverId => driverId !== driver.driverId
      );

      const totalDriversPendingExclusion = driversPendingExclusion.length;
      const allDriversExcluded = !hasExcludedDrivers;
      let nextExcludedDriverId = null;

      if (totalDriversPendingExclusion > 1) {
        [nextExcludedDriverId] = driverIdsPendingExclusion;
      }
      yield put({
        type: 'STORE_DRIVERS_PENDING_EXCLUSION',
        payload: {
          byId,
          driverIdsPendingExclusion,
          totalDriversToExclude: totalDriversPendingExclusion,
          driver,
          allDriversExcluded,
          nextExcludedDriverId,
          requiresUpdatedRates: vinVerificationNeeded
        }
      });
    } else if (requiresDriverExclusion && !hasExcludedDrivers) {
      /* If quote state requires DE flow but no active additional drivers or all active additional drivers were added to quote set allDriversExcludedTo "true" in excluded drivers reducer to redirect correctly

      */
      yield put({
        type: 'ALL_EXCLUDED_DRIVERS_CONFIRMED'
      });
    }

    if (
      requiresDynamicForms &&
      customPreBindForms &&
      customPreBindForms.allIds.length
    ) {
      yield put({
        type: 'UPDATE_POLICY',
        payload: { requiresDynamicForms, customPreBindForms }
      });
    }
    // make sure we hide loader while we run rerate
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: false
      }
    });

    if (successRoute) {
      yield put({ type: 'REDIRECT', payload: { url: successRoute } });
    } else if (requiredVINVerification && !vinVerificationNeeded) {
      yield put({
        type: 'REDIRECT',
        payload: { url: '/purchase/effective-date' }
      });
    }
  } catch (error) {
    // catch any error and set it in redux
    yield put({
      type: 'GET_CUSTOM_COVERAGE_RATE_FAILURE',
      error
    });

    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

export function* saveSelectedPackageSubroutine({
  payload: { packageType, policyCoverages, vehicleCoverages, drivingBehavior }
}) {
  let rateEndpoint = Noblr.quote.getRateByPackage;
  const {
    auth,
    app,
    excludedDrivers: { allDriversExcluded, requiresUpdatedRates },
    coverage: { currentPackage, defaultPackage }
  } = yield select();

  // if we have a trial user, change endpoint to get trial rate
  if ((auth && auth.trialActive) || (app && app.trialActive)) {
    rateEndpoint = Noblr.quote.getTrialRateByPackage;

    yield put({
      type: 'UPDATE_POLICY',
      payload: { trialActive: auth.trialActive || app.trialActive }
    });
  }

  const packageTypeToSave = packageType || currentPackage || defaultPackage;

  let requestBody = {
    packageType: packageTypeToSave,
    policyCoverages,
    vehicleCoverages
  };

  if (packageType !== currentPackage) {
    yield put({
      type: 'SET_CURRENT_PACKAGE',
      payload: { currentPackage: packageType }
    });
  }

  // if user changed mileage or persona on quote page, pass those values in too
  if (drivingBehavior.updateDrivingBehavior) {
    const { mileage, personaCode } = drivingBehavior;

    requestBody = {
      ...requestBody,
      mileage,
      personaCode
    };
  }

  try {
    const {
      estInstallmentPaymentSchedule,
      estRewardSchedule,
      fixedRatePerMonth,
      totalRatePerMile,
      totalQuoteRate,
      variableRatePerMonth,
      maxQuoteRate,
      mileage: responseMileage,
      personaCode,
      rewards,
      drivers,
      excludedDrivers,
      removedDrivers,
      vehicles,
      insuranceHistory,
      coverage,
      customPreBindForms,
      priceDifference,
      priceIncrease,
      noblrCreditScore: {
        creditFactors: { factor }
      },
      personaToMileageToVariablePremiumMap,
      dlVerificationRequired,
      vinVerificationNeeded,
      firstPossibleEffectiveDate,
      lastPossibleEffectiveDate,
      pickerEffectiveStartDate,
      requiresVehicleAssignment,
      requiresDriverExclusion,
      requiresDynamicForms,
      requiresUmuimOfferForm
    } = yield call(rateEndpoint, requestBody, {
      savePackage: true
    });

    const redirectURL = determinePurchaseStartPage({
      allDriversExcluded,
      requiresDriverExclusion,
      dlVerificationRequired,
      vinVerificationNeeded
    });

    // check if mileage is in personaToMileageToVariablePremiumMap
    const validMileageOptions = Object.keys(
      personaToMileageToVariablePremiumMap[0]
    );

    const roundingMileage = roundMileageValue(responseMileage);

    const mileageToStore = validMileageOptions.includes(`${roundingMileage}`)
      ? roundingMileage
      : 15;

    yield put({
      type: 'SAVE_SELECTED_PACKAGE_SUCCESS',
      payload: {
        estInstallmentPaymentSchedule,
        estRewardSchedule,
        fixedRatePerMonth,
        totalRatePerMile,
        totalQuoteRate,
        variableRatePerMonth,
        maxQuoteRate,
        mileage: mileageToStore,
        personaCode,
        rewards,
        drivers,
        vehicles,
        insuranceHistory,
        biLimits: coverage.bi,
        creditFactors: factor,
        priceDifference,
        priceIncrease,
        personaToMileageToVariablePremiumMap,
        firstPossibleEffectiveDate,
        lastPossibleEffectiveDate,
        pickerEffectiveStartDate,
        vinVerificationNeeded
      }
    });

    if (
      requiresDynamicForms &&
      customPreBindForms &&
      customPreBindForms.allIds.length
    ) {
      yield put({
        type: 'UPDATE_POLICY',
        payload: { requiresDynamicForms, customPreBindForms }
      });
    }

    yield put({
      type: 'UPDATE_APP',
      payload: {
        requiresDLVerification: dlVerificationRequired,
        requiresUmuimOfferForm,
        requiresVehicleAssignment,
        requiresDriverExclusion,
        requiresDynamicForms
      }
    });

    yield put({
      type: 'UPDATE_POLICY',
      payload: {
        firstPossibleEffectiveDate,
        lastPossibleEffectiveDate,
        pickerEffectiveStartDate
      }
    });

    if (requiresDriverExclusion) {
      const driversPendingExclusion = [...removedDrivers, ...excludedDrivers];
      const hasExcludedDrivers = driversPendingExclusion.length > 0;

      if (
        !requiresUpdatedRates &&
        requiresDriverExclusion &&
        hasExcludedDrivers
      ) {
        yield put({
          type: 'RESET_EXCLUDED_DRIVERS'
        });

        const byId = driversPendingExclusion.reduce((acc, driver) => {
          if (driver.driverId) {
            acc[driver.driverId] = { ...driver };
          }

          return acc;
        }, {});

        const [driver] = driversPendingExclusion.slice(0, 1);

        const driverIdsPendingExclusion = Object.keys(byId).filter(
          driverId => driverId !== driver.driverId
        );

        const totalDriversPendingExclusion = driversPendingExclusion.length;
        const allDriversExcluded = !hasExcludedDrivers;
        let nextExcludedDriverId = null;

        if (totalDriversPendingExclusion > 1) {
          [nextExcludedDriverId] = driverIdsPendingExclusion;
        }
        yield put({
          type: 'STORE_DRIVERS_PENDING_EXCLUSION',
          payload: {
            byId,
            driverIdsPendingExclusion,
            totalDriversToExclude: totalDriversPendingExclusion,
            driver,
            allDriversExcluded,
            nextExcludedDriverId
          }
        });
      }
    }
    // if we need to verify vin redirect there
    yield put({
      type: 'REDIRECT',
      payload: {
        url: redirectURL
      }
    });
  } catch (error) {
    // catch any error and set it in redux
    yield put({
      type: 'SAVE_SELECTED_PACKAGE_FAILURE',
      error
    });

    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Order MVR
 */
export function* orderMVRSubroutine() {
  try {
    // Send request to Noblr App Services endpoint
    const response = yield call(Noblr.mvr.orderMvr);

    Sentry.withScope(scope => {
      scope.setTag('mvr_ordered', true);
    });

    // Update redux state with response from MVR
    yield put({
      type: 'ORDER_MVR_SUCCESS',
      payload: response
    });
  } catch (error) {
    // set tag in Sentry
    Sentry.withScope(scope => {
      scope.setTag('mvr_ordered', false);
      scope.setTag('mvr_order_error', true);
    });
    yield put({
      type: 'ORDER_MVR_FAILURE',
      error
    });

    // catch any error and set in store
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}
