import React from 'react';
import { Select } from '@noblr-lab/noblr-ui';

function AsyncSelect(props) {
  const handleChange = name => event => {
    const {
      form,
      form: { setFieldValue },
      onInputChange
    } = props;

    const selectedOption = event.target.value;

    if (onInputChange) {
      // pass field name, selected value, and all form values to onChange handler
      onInputChange(name, selectedOption, form);
    }

    setFieldValue(name, selectedOption, true);
  };

  const { field, label, options = [], disabled = false, labelId } = props;
  const updatedField = { ...field };

  updatedField.onChange = handleChange(field.name);

  // if coverageRuleOptions.length, we need to set the value correctly and enable/disable with rules
  return (
    <Select
      fullWidth
      color="primary"
      selectLabel={label}
      selectLabelId={labelId}
      field={updatedField}
      options={options}
      disabled={disabled}
    />
  );
}
export default AsyncSelect;
