import React, { memo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import ProgressBar from 'components/ProgressBar';
import { colors, brandonGrotesque, breakPoints } from 'styles/variables';

const AppHeader = styled.header`
  margin: 0 auto 2px;
  width: 100%;
  min-width: 100%;
  height: 60px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

  @media screen and (max-width: ${breakPoints.large}) {
    .header__link {
      text-align: center;
    }
  }
`;

const HeaderItems = styled.div`
  width: auto;
  display: flex;
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakPoints.extraLarge}) {
    margin: 0 auto;
    height: auto;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
`;

const PrimaryHeaderItem = styled.div`
  margin: 0 0 0 7%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 501px) {
    margin: 0;
    flex: 1 50%;
    align-items: flex-start;
    width: auto;
  }
`;

const OuterHeaderItem = styled.div`
  margin: 0 10px 0 auto;
  flex: 0;
  max-width: 20px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  algin-items: center;
  justify-content: center;

  @media only screen and (min-width: ${breakPoints.extraLarge}) {
    margin: 0 20px 0 auto;
    flex: 1 50%;
    width: auto;
    justify-self: start;
  }
  &.link {
    height: 100%;
  }
`;

const CallUsLink = styled.a`
  flex: 1;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

const CallUsText = styled.p`
  margin: 0 auto 0 10px;
  min-width: 140px;

  font-family: ${brandonGrotesque};
  font-size: 18px;
  font-weight: 500;
  color: ${colors.alphaBlue500};

  @media screen and (max-width: ${breakPoints.small}) {
    display: none;
  }
`;

const Header = memo(({ location }) => {
  const { pathname } = location;

  const renderLogo = () => {
    // all pages should link to our homepage except for dnq pages (including call to complete) and pages in purchase flow
    const shouldLinkOut =
      (!pathname.startsWith('/dnq') || !pathname.startsWith('/purchase')) &&
      pathname !== '/call-to-complete' &&
      pathname !== '/start-quote/welcome';

    return shouldLinkOut ? (
      <a className="header__link" href="https://www.usaa.com/payasyoudrive">
        <img
          width="250px"
          height="40px"
          src={`${process.env.REACT_APP_NOBLR_CDN}/logos/usaa-pay-as-you-drive-logo.svg`}
          alt="Noblr Homepage"
        />
      </a>
    ) : (
      <img
        width="250px"
        height="40px"
        src={`${process.env.REACT_APP_NOBLR_CDN}/logos/usaa-pay-as-you-drive-logo.svg`}
        alt="Noblr Homepage"
      />
    );
  };

  const headerLogo = renderLogo();

  return (
    <AppHeader role="banner">
      <HeaderItems>
        <PrimaryHeaderItem>{headerLogo}</PrimaryHeaderItem>
        <OuterHeaderItem className="link">
          <CallUsLink
            href="tel:+1-877-236-6257"
            aria-label="1. 8 7 7. 2 3 6. 6 2 5 7"
          >
            <img
              src={`${process.env.REACT_APP_NOBLR_CDN}/icons/phone-icon.svg`}
              width="20px"
              height="20px"
              alt=""
            />
            <CallUsText>(877) BE-NOBLR</CallUsText>
          </CallUsLink>
        </OuterHeaderItem>
      </HeaderItems>
      <ProgressBar />
    </AppHeader>
  );
});

const mapStateToProps = ({ app: { error } }) => ({
  error
});

Header.displayName = 'AppHeader';

export default connect(mapStateToProps)(Header);
