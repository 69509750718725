import {
  createTheme,
  responsiveFontSizes,
  // eslint-disable-next-line camelcase
  unstable_createMuiStrictModeTheme
} from '@material-ui/core/styles';
import { colors, openSans, brandonGrotesque } from 'styles/variables';

const materialUITheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: colors.coreBlue500,
      main: colors.coreBlue700
    },
    secondary: {
      light: colors.alphaBlue100,
      main: colors.alphaBlue500,
      dark: colors.alphaBlue600
    },
    background: {
      default: colors.white
    },
    action: {
      active: colors.coreBlue700,
      focus: colors.coreBlue700,
      disabled: colors.accessibleLightGunMetal
    },
    text: {
      primary: colors.coreBlue700,
      disabled: colors.accessibleLightGunMetal
    }
  },
  typography: {
    fontWeightRegular: 500,
    fontFamily: openSans,
    h1: {
      fontSize: '30px',
      padding: ' 0 10px',
      textAlign: 'center',
      fontFamily: brandonGrotesque,
      fontWeight: 'normal',
      lineHeight: 1.4
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto'
        }
      }
    },
    MuiFormControl: {
      root: {
        minWidth: '25ch',
        display: 'flex',
        borderRadius: 4
      },
      fullWidth: {
        margin: '0 auto',
        height: 'auto',
        minWidth: '100%',
        width: '100%'
      }
    },
    MuiFormControlLabel: {
      root: {
        borderRadius: 4,
        '&$disabled': {
          backgroundColor: colors.usaaLightestGrey,
          cursor: 'not-allowed'
        }
      },
      label: {
        fontWeight: 400,
        textTransform: 'capitalize'
      }
    },
    MuiInputBase: {
      root: {
        textAlign: 'left',
        '&$disabled': {
          color: colors.accessibleLightGunMetal,
          cursor: 'not-allowed'
        }
      },
      input: {
        width: '100%',
        minWidth: '250px',
        maxWidth: '350px',
        '&::placeholder': {
          textTransform: 'uppercase'
        }
      }
    },
    MuiTextField: {
      root: {
        margin: '0 auto',
        width: '100%',
        minWidth: '250px',
        maxWidth: '350px',
        color: colors.coreBlue700,
        '&.Mui-focused': {
          border: `2px solid ${colors.coreBlue700}`
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        margin: '0 auto',
        width: '100%',
        minWidth: '250px',
        maxWidth: '350px'
      },
      notchedOutline: {
        borderColor: colors.coreBlue700
      }
    },
    // style focused labels
    MuiFormLabel: {
      root: {
        textTransform: 'uppercase',
        color: colors.coreBlue700,
        fontWeight: 500,
        fontFamily: openSans,
        '&.MuiFormLabel-filled': {
          color: colors.coreBlue700
        },
        '&.Mui-focused': {
          color: colors.coreBlue700,
          fontWeight: 600,
          textTransform: 'uppercase'
        },
        '&.Mui-shrink': {},
        '&.MuiInputLabel-shrink': {
          fontWeight: 600,
          margin: '0 0 0 -5px',
          padding: '0 3px'
        }
      }
    },
    MuiAutocomplete: {
      endAdornment: {
        marginTop: '4px',
        marginRight: '5px',
        cursor: 'pointer'
      },
      popupIndicator: {
        fill: colors.coreBlue700
      },
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: colors.usaaLightestGrey
        },
        '&[aria-selected="true"]:hover': {
          backgroundColor: colors.usaaLightestGrey
        },
        '&[data-focus="true"]': {
          backgroundColor: colors.usaaLightestGrey
        },
        '&:hover': {
          backgroundColor: colors.usaaLightestGrey
        },
        '&:active': {
          backgroundColor: colors.usaaLightestGrey
        }
      },
      popper: {
        maxWidth: 'inherit'
      }
    },
    MuiSelect: {
      root: {
        border: colors.coreBlue700,
        '&$disabled': {
          cursor: 'not-allowed'
        }
      },
      select: {
        '&$disabled': {
          cursor: 'not-allowed'
        }
      },
      outlined: {
        borderRadius: '4px',
        textAlign: 'left'
      },
      icon: {
        fill: colors.coreBlue700
      }
    },
    MuiInputLabel: {
      shrink: {
        color: colors.coreBlue700,
        backgroundColor: colors.white
      }
    },
    MuiFormGroup: {
      root: {
        width: '100%',
        display: 'flex',
        flexFlow: 'column wrap'
      },
      row: {
        width: '100%',
        flexFlow: 'row wrap',
        justifyContent: 'space-around'
      }
    },
    MuiListItem: {
      root: {
        padding: '8px 12px',
        textAlign: 'center',
        color: colors.coreBlue700,
        fontFamily: openSans,
        fontSize: '18px',
        textTransform: 'capitalize',
        '&$disabled': {
          cursor: 'not-allowed',
          backgroundColor: colors.usaaLightestGrey
        }
      }
    }
  }
});

const muiTheme = responsiveFontSizes(materialUITheme);

/*
DO NOT USE unstable_createMuiStrictModeTheme IN PRODUCTION
unstable_createMuiStrictModeTheme prevents React.StrictMode from throwing unnecessary errors. See https://v4.mui.com/customization/theming/#unstable-createmuistrictmodetheme-options-args-theme */

const theme =
  process.env.NODE_ENV !== 'production'
    ? unstable_createMuiStrictModeTheme(muiTheme)
    : muiTheme;

export default theme;
