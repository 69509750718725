import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Box, Grid } from '@material-ui/core';
import { colors, openSans } from 'styles/variables';
import Modal from 'components/Modal';
import { TextLink, ModalHeader } from 'styles';
import Button from '../Buttons/Button';

const StyledTextLink = styled(TextLink)`
  color: ${colors.alphaBlue500};
  margin: 10px auto;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
`;

const ButtonGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const StyledText = styled.p`
  margin: 10px 0 20px;
  font-size: 18px;
  color: ${colors.coreBlue700};
  font-family: ${openSans};
  align-self: flex-start;
  text-align: left;
  line-height: 1.56;
  letter-spacing: 0.45px;
`;

const ModalPrimaryButton = styled(Button)`
  margin: ${props => (props.margin ? props.margin : '0.125rem auto')};
  padding: 8px;
  height: auto;
  min-width: 190px;
`;

export default function StartOver() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({ type: 'OPEN_MODAL', payload: 'startOver' });
  };

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleReset = () => {
    dispatch({ type: 'CLOSE_MODAL' });
    dispatch({
      type: 'RESET_QUOTE',
      payload: {
        redirect: true,
        urlParams: '?utm_source=direct&utm_medium=start_over'
      }
    });
  };

  return (
    <>
      <StyledTextLink
        tabIndex="0"
        aria-label="Or Start Over"
        onClick={handleClick}
      >
        OR START OVER
      </StyledTextLink>
      <Modal inline modalLabel="StartOverModal" modalName="startOver">
        <Box>
          <Grid container>
            <ModalHeader>Are you sure you want to start over?</ModalHeader>
            <StyledText>
              {`We'll clear your information and you will start over with a blank
        slate.`}
            </StyledText>
            <ButtonGrid container item>
              <Grid item xs sm>
                <ModalPrimaryButton large onClick={handleClose}>
                  No, Finish Quote
                </ModalPrimaryButton>
              </Grid>
              <Grid item xs sm>
                <ModalPrimaryButton large onClick={handleReset}>
                  Yes, Start Over
                </ModalPrimaryButton>
              </Grid>
            </ButtonGrid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
