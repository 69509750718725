import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Form from 'components/Form/Form';
import { makeSelectEditingDriver } from 'redux/selectors';
import { StyledContentWrapper } from 'styles';

function ConfirmDriverExclusion() {
  // memoize makeSelectCurrentExcludedDriver
  const selectEditingDriver = useMemo(makeSelectEditingDriver, []);

  // use selector to pull excluded driver from state
  const editingDriver = useSelector(state => selectEditingDriver(state));

  const fields = [
    {
      type: 'radio',
      name: 'excludeDriver',
      id: 'exclude-driver-confirmation-radios',
      options: [
        { value: 'Yes', label: 'Yes, Exclude Driver' },
        { value: 'No', label: 'No' }
      ],
      required: true,
      radioGroupAriaLabel: 'Confirm Driver is Excluded'
    }
  ];

  const [currentPrompt, setPrompt] = useState('');
  const [currentSubHeader, setSubHeader] = useState('');

  useEffect(() => {
    if (editingDriver) {
      const currentPrompt = 'Do you want to exclude them from your policy?';
      const currentSubHeader =
        'Based on your responses, we cannot include this driver in your policy. If you exclude them, there will not be coverage under this policy when they drive any car.';

      setPrompt(currentPrompt);
      setSubHeader(currentSubHeader);
    }
  }, [editingDriver]);

  const submitAction = 'CONFIRM_EXCLUDED_DRIVER';

  if (!editingDriver && !editingDriver.driverId) {
    return <Redirect to="/add-drives/driver-list" replace />;
  }

  return (
    <Container>
      <StyledContentWrapper>
        <Form
          prompt={currentPrompt}
          fields={fields}
          subHeader={currentSubHeader}
          submitAction={submitAction}
        />
      </StyledContentWrapper>
    </Container>
  );
}

export default ConfirmDriverExclusion;
