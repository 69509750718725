import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Container, Box, Grid } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import { Prompt, StyledContentWrapper, ModalHeader } from 'styles';
import Form from 'components/Form/Form';
import Modal from '../../components/Modal';
import { makeSelectCurrentExcludedDriver } from '../../redux/selectors';

const ButtonGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const ModalPrimaryButton = styled(Button)`
  margin: ${props => (props.margin ? props.margin : '0.125rem auto')};
  padding: 8px;
  height: auto;
`;

function ConfirmDateOfBirth(props) {
  const {
    location: { pathname },
    history
  } = props;

  const dispatch = useDispatch();
  const prevLocation = useRef(pathname);
  const unListen = useRef();
  // memoize makeSelectCurrentExcludedDriver
  const selectCurrentExcludedDriver = useMemo(
    makeSelectCurrentExcludedDriver,
    []
  );

  // use selector to pull excluded driver from state
  const excludedDriver = useSelector(state =>
    selectCurrentExcludedDriver(state)
  );

  const backArrowListener = useCallback(() => {
    if (prevLocation !== pathname) {
      history.go(1);
      dispatch({ type: 'OPEN_MODAL', payload: 'driverExclusionModal' });
    }
  }, [history, pathname, dispatch]);

  useEffect(() => {
    if (prevLocation !== pathname) {
      history.push(pathname, '', '');

      unListen.current = history.listen((newLocation, action) => {
        if (action === 'POP') {
          backArrowListener();
        }
      });
    }

    return unListen.current;
  }, [backArrowListener, pathname, history]);

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleNavigation = () => {
    handleClose();
    dispatch({
      type: 'REDIRECT',
      payload: { url: '/driver-exclusion/start' }
    });
  };

  const { firstName, lastName } = excludedDriver;
  const { driverId } = useParams();
  const fields = [
    {
      type: 'maskedDate',
      name: 'dob',
      id: 'birth-date-input',
      label: 'date of birth',
      placeholder: 'MM/DD/YYYY',
      required: true,
      validation: 'additionalDriverDOB',
      options: {
        date: true,
        blocks: [2, 2],
        datePattern: ['m', 'd', 'Y'],
        delimiter: '/'
      }
    }
  ];

  // TODO : Will be modified once we create a routes for drivers excluded screen
  const successRoute = `/driver-exclusion/${driverId}/confirmation`;
  const submitAction = 'UPDATE_EXCLUDED_DRIVER_INFO';

  return (
    <Container>
      <StyledContentWrapper>
        <Box m={{ xs: 2, sm: 2, md: 7 }} mx="auto">
          <Prompt>{`What is ${firstName} ${lastName}'s date of birth?`}</Prompt>
        </Box>
        <Form
          fields={fields}
          successRoute={successRoute}
          submitAction={submitAction}
          submitAsync
          buttonMarginTop="20px"
        />
      </StyledContentWrapper>
      <Modal
        inline
        modalLabel="DriverExclusionModal"
        modalName="driverExclusionModal"
      >
        <Box>
          <Grid container>
            <ModalHeader>
              {`Clicking 'BACK' will take you to the section start page and reset the list of drivers you've gone through.`}
            </ModalHeader>
            <ButtonGrid container item>
              <Grid item xs sm>
                <ModalPrimaryButton large onClick={handleClose}>
                  CANCEL
                </ModalPrimaryButton>
              </Grid>
              <Grid item xs sm>
                <ModalPrimaryButton primary large onClick={handleNavigation}>
                  BACK
                </ModalPrimaryButton>
              </Grid>
            </ButtonGrid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default ConfirmDateOfBirth;
