import React from 'react';
import styled from 'styled-components/macro';
import { SummaryText } from 'components/Pages/Quote/styles';
import { STATE_NAME_MAP } from 'constants/stateCodes';
import { StyledLink } from 'styles/copy';

const ContactText = styled(SummaryText)`
  font-weight: bold;
`;

function DmvContactInfo({ state }) {
  switch (state) {
    case 'AR':
      return (
        <ContactText sm>
          Arkansas Department of Finance and Administration
          <br />
          Ragland Building
          <br />
          1900 W 7th St, Ste 1070
          <br />
          Little Rock, AR 72201
          <br />
          (501) 683-0984
        </ContactText>
      );

    case 'AZ':
      return (
        <ContactText sm>
          Motor Vehicle Division (MVD) Inquiries
          <br />
          PO Box 2100, MD 555M
          <br />
          Phoenix, AZ 85001
          <br />
          602-255-0072
        </ContactText>
      );

    case 'CO':
      return (
        <ContactText sm>
          Colorado Department of Revenue, Division of Motor Vehicles <br />
          1881 Pierce St., Lakewood, Colorado 80214
          <br />
          1-303-205-5600
        </ContactText>
      );

    case 'GA':
      return (
        <ContactText sm>
          Georgia Department of Driver Services (DDS)
          <br />
          2206 East View Parkway
          <br />
          Post Office Box 80447
          <br />
          Conyers, GA 30013
          <br />
          (678) 413-8400
        </ContactText>
      );

    case 'IL':
      return (
        <ContactText sm>
          Secretary of State
          <br />
          Driver Analysis Section
          <br />
          2701 S. Dirksen Pkwy.
          <br />
          Springfield, IL 62723
          <br />
          217-782-2720
        </ContactText>
      );

    case 'IN':
      return (
        <ContactText sm>
          Indiana Bureau of Motor Vehicles (BMV)
          <br />
          Indiana Government Center North
          <br />
          4th Floor, 100 North Senate Avenue
          <br />
          Indianapolis, IN 46204
          <br />
          (888) 692-6841
        </ContactText>
      );

    case 'LA':
      return (
        <ContactText sm>
          Louisiana Office of Motor Vehicles
          <br />
          Attention: ODR
          <br />
          P.O. Box 64886
          <br />
          Baton Rouge, LA 70896
          <br />
          225-925-6146
        </ContactText>
      );

    case 'MD':
      return (
        <ContactText sm>
          <span id="dmv-name">Maryland Motor Vehicle Administration</span>
          <br />
          Driver Records Unit
          <br />
          Room 145
          <br />
          6601 Ritchie Highway, NE
          <br />
          Glen Burnie, MD 21062
          <br />
          <StyledLink
            bold
            href="https://mva.maryland.gov/Pages/driver-record-questions.aspx"
            target="_blank"
            rel="noopener noreferrer"
            aria-labelledby="dmv-name"
          >
            https://mva.maryland.gov/Pages/driver-record-questions.aspx
          </StyledLink>
          <br />
          1-410-768-7000
        </ContactText>
      );

    case 'MO':
      return (
        <ContactText sm>
          Driver License Record Center
          <br />
          301 West High Street - Room 470
          <br />
          PO Box 2167
          <br />
          Jefferson City, MO 65101
          <br />
          (573) 526-2407
        </ContactText>
      );

    case 'NV':
      return (
        <ContactText sm>
          Nevada DMV Department of Motor Vehicles
          <br />
          1339 American Pacific Dr.
          <br />
          Henderson, NV 89074
          <br />
          (775) 684-4711
        </ContactText>
      );

    case 'NM':
      return (
        <ContactText sm>
          <span id="dmv-name">New Mexico Motor Vehicle Division</span>
          <br />
          Joseph Montoya Building
          <br />
          P.O. Box 1028
          <br />
          1100 South St. Francis Drive
          <br />
          Santa Fe, NM 87504-1028
          <br />
          <StyledLink
            bold
            href="http://mvd.newmexico.gov/"
            target="_blank"
            rel="noopener noreferrer"
            aria-labelledby="dmv-name"
          >
            https://mvd.newmexico.gov
          </StyledLink>
          <br />
          888-683-4636
        </ContactText>
      );

    case 'OH':
      return (
        <ContactText sm>
          Ohio Bureau of Motor Vehicles (BMV)
          <br />
          Physical Address: 1970 West Broad Street, Columbus, Ohio 43223
          <br />
          Mailing Address: PO Box 16520, Columbus, Ohio 43216-6520
          <br />
          General Information: (844) 644-6268
        </ContactText>
      );

    case 'PA':
      return (
        <ContactText sm>
          <span id="dmv-name">Pennsylvania Department of Transportation</span>
          <br />
          1101 South Front Street
          <br />
          Harrisburg, PA 17104
          <br />
          <StyledLink
            bold
            href="https://apps.pa.egov.com/idr"
            target="_blank"
            rel="noopener noreferrer"
            aria-labelledby="dmv-name"
          >
            https://apps.pa.egov.com/idr
          </StyledLink>
          <br />
          717-798-9010
        </ContactText>
      );

    case 'TX':
      return (
        <ContactText sm>
          Texas Department of Motor Vehicles (TxDMV) <br />
          4000 Jackson Avenue, Austin, Texas 78731
          <br />
          1-888-368-4689
        </ContactText>
      );

    case 'VA':
      return (
        <ContactText sm>
          Virginia Department of Motor Vehicles
          <br />
          Attention: Vehicle (Driver) Records Work Center
          <br />
          P.O. Box 27412
          <br />
          Richmond, VA 23269
        </ContactText>
      );

    case 'WI':
      return (
        <ContactText sm>
          Driver Records, WisDOT
          <br />
          PO Box 7995
          <br />
          Madison, WI 53707-7995
          <br />
          (608) 266-8753
        </ContactText>
      );

    case 'UT':
      return (
        <ContactText sm>
          <span id="dmv-name">Utah Driver License Division</span>
          <br />
          Taylorsville State Office Building
          <br />
          4315 South 2700 West, Suite 1600
          <br />
          Taylorsville, Utah 84129
          <br />
          (801) 965-4437
          <br />
          <StyledLink
            bold
            href="http://dld.utah.gov"
            target="_blank"
            rel="noopener noreferrer"
            aria-labelledby="dmv-name"
          >
            dld.utah.gov
          </StyledLink>
        </ContactText>
      );

    default:
      return '';
  }
}

function RightToDisputeByState({ state }) {
  let departmentName = 'Division of Motor Vehicles';
  let abbreviatedDept = 'DMV';
  let showStateOfText = true;
  let showFullStateName = false;

  switch (state) {
    // same departmentName and abbreviatedDept for AZ and NM
    case 'AR':
      departmentName = 'Department of Finance and Administration';
      abbreviatedDept = '';
      break;

    case 'AZ':
      departmentName = 'Motor Vehicle Division';
      abbreviatedDept = 'MVD';
      break;

    case 'IL':
      departmentName = 'Driver Services Department';
      break;

    case 'IN':
      departmentName = 'Bureau of Motor Vehicles';
      abbreviatedDept = 'BMV';
      break;

    case 'LA':
      departmentName = 'Office of Motor Vehicles';
      abbreviatedDept = 'OMV';
      break;

    case 'MD':
      departmentName = 'Motor Vehicle Administration';
      abbreviatedDept = 'MVA';
      break;

    case 'NM':
      departmentName = 'Motor Vehicle Division';
      abbreviatedDept = 'MVD';
      break;

    case 'OH':
      departmentName = 'Bureau of Motor Vehicles';
      abbreviatedDept = 'BMV';
      break;

    case 'PA':
      departmentName = 'Department of Transportation';
      abbreviatedDept = 'PennDOT';
      break;

    case 'VA':
      departmentName = 'Department of Motor Vehicles';
      break;

    case 'UT':
      departmentName = 'Driver License Division';
      abbreviatedDept = 'DLD';
      showStateOfText = false;
      showFullStateName = true;
      break;

    default:
      break;
  }

  return (
    <>
      {state === 'AR' ||
      state === 'LA' ||
      state === 'NM' ||
      state === 'PA' ||
      state === 'MD' ||
      state === 'VA' ||
      state === 'IL' ? (
        <SummaryText sm>
          {`You have the right to dispute inaccurate information in your motor vehicle report with the ${
            STATE_NAME_MAP[state]
          } ${departmentName}. If you find mistakes on your motor vehicle report, you can contact the ${
            STATE_NAME_MAP[state]
          } ${departmentName} to request information on how you may challenge your motor vehicle report. ${
            state !== 'AR' ? 'Contact:' : '' // hide "Contact:" if AR
          }`}
        </SummaryText>
      ) : (
        <p>
          {`You have the right to dispute inaccurate information in your motor vehicle report with the ${
            showStateOfText ? 'State of' : ''
          } ${
            STATE_NAME_MAP[state]
          } ${departmentName} (${abbreviatedDept}). If you find mistakes on your motor vehicle report, you can contact the ${
            showFullStateName ? STATE_NAME_MAP[state] : state
          } ${abbreviatedDept} to request information on how you may challenge your motor vehicle report. Contact:`}
        </p>
      )}
      <br />
      <DmvContactInfo state={state} />
    </>
  );
}

export default RightToDisputeByState;
