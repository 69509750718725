import React from 'react';
import styled, { css } from 'styled-components/macro';
import Separator from 'components/Form/Separator';
import { StyledMessage, ErrorPagePrompt } from 'styles';
import { openSans, breakPoints } from 'styles/variables';
import NoblrPhoneLink from '../NoblrPhoneLink';

const Wrapper = styled.div`
  @media screen and (min-width: ${breakPoints.medium}) {
    margin-top: 100px;
  }
`;

const ErrorContainer = styled.div`
  max-width: 480px;
  margin: 0 auto;
  @media screen and (max-width: ${breakPoints.medium}) {
    max-width: 90%;
  }
  img {
    margin: 30px auto 0;
    max-width: inherit;
  }
`;

const DNQMessage = styled.div`
  a {
    text-decoration: underline;
  }

  font-family: ${openSans};
  font-size: ${props => (props.fontSize ? props.fontSize : '24px')};
  text-align: center;

  p {
    font-family: ${openSans};
    font-size: ${props => (props.fontSize ? props.fontSize : '24px')};
    text-align: center;
  }

  ${props =>
    props.mvrDown &&
    css`
      line-height: 1.56;
      letter-spacing: 0.45px;
    `}
`;

function ErrorPage(props) {
  const { error = null } = props;

  if (!error) {
    const message = `Something went wrong with our system. Please try again or give us a call at`;

    return (
      <div>
        <ErrorMessage fallbackMessage={message} />;
        <NoblrPhoneLink />
      </div>
    );
  }

  const errorHeader = (error && error.errorHeader) || `It's not you, it's us`;
  const useSerif = error && error.simpleDnq === 'false';

  if (error && error.status === 424) {
    const mvrDown = {
      prompt: "We're temporarily down, but not out!",
      messageBody:
        "<p>Thank you for your patience as temporary system maintenance is performed in your state. <br/> <br/> Please try again or call us at <a href='tel:+18772366257'  aria-label='1. 8 7 7. 2 3 6. 6 2 5 7'>(877) 236-6257.</a></p>"
    };

    return (
      <div>
        <ErrorPagePrompt>{mvrDown.prompt}</ErrorPagePrompt>
        <Separator />
        <div className="dnq-container">
          <ErrorContainer>
            <DNQMessage
              serif
              fontSize="18px"
              mvrDown
              dangerouslySetInnerHTML={{ __html: mvrDown.messageBody }}
            />
          </ErrorContainer>
        </div>
      </div>
    );
  }

  return (
    error && (
      <Wrapper>
        <ErrorPagePrompt>
          {error.DNQ ? "Sorry, We Can't Give You A Quote Today" : errorHeader}
        </ErrorPagePrompt>
        {error.DNQ ? (
          <div className="dnq-container">
            <ErrorContainer>
              <img
                src={`${process.env.REACT_APP_NOBLR_CDN}/images/oops-error.webp`}
                alt="Oops an Error Has Occurred."
              />
              <DNQMessage
                serif={useSerif}
                dangerouslySetInnerHTML={{ __html: error.message }}
              />
            </ErrorContainer>
          </div>
        ) : (
          <ErrorContainer>
            {error.status === '404' ? (
              <img
                src={`${process.env.REACT_APP_NOBLR_CDN}/images/404.webp`}
                alt="Page Not Found"
              />
            ) : (
              !error.hasPolicyOrTrial && (
                <img
                  src={`${process.env.REACT_APP_NOBLR_CDN}/images/oops-error.webp`}
                  alt="Oops an Error Has Occurred."
                />
              )
            )}

            <ErrorMessage
              status={error.status}
              message={error.message}
              fallbackMessage="Something went wrong with our system. Please try again or give us a call at"
            />
            <NoblrPhoneLink />
          </ErrorContainer>
        )}
      </Wrapper>
    )
  );
}

export function ErrorMessage({ status, message, fallbackMessage }) {
  switch (status) {
    case '404':
      return <StyledMessage>Page not found</StyledMessage>;

    default:
      return <StyledMessage>{message || fallbackMessage}</StyledMessage>;
  }
}

export default ErrorPage;
