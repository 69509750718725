import React, { memo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { colors } from 'styles/variables';

const AsyncErrorWrapper = styled.div`
  width: 40%;
  height: 30px;
  margin: 0 auto;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
`;

const AsyncError = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 30px;
  color: ${colors.red500};
`;

const AsyncErrorMessage = memo(({ errorMessage }) => (
  <div>
    {errorMessage && (
      <AsyncErrorWrapper>
        <AsyncError>{errorMessage}</AsyncError>
      </AsyncErrorWrapper>
    )}
  </div>
));

const mapStateToProps = ({ app: { errorMessage } }) => ({
  errorMessage
});

AsyncErrorMessage.displayName = 'AsyncErrorMessage';

export default connect(mapStateToProps)(AsyncErrorMessage);
