import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { getMileage } from 'redux/actions';
import { StyledContentWrapper } from 'styles';
import { roundMileageValue } from 'utilities/coverage';

export default function Mileage() {
  const savedMileage = useSelector(state => state?.coverage?.savedMileage);

  const mileage = useSelector(state => state?.coverage?.mileage);

  const dispatch = useDispatch();

  const prompt = 'How many miles do you drive each day on average?';

  const fields = [
    {
      type: 'materialSlider',
      name: 'mileage',
      id: 'mileage-input',
      unit: 'miles/day',
      initialValue: 15,
      required: true
    }
  ];

  const successRoute = '/driving-behavior/select-persona';

  const [initialFields, setInitialFields] = useState(fields);

  const [submitAction, setSubmitAction] = useState('SAVE_MILEAGE');

  useEffect(() => {
    if (!savedMileage) {
      dispatch(getMileage());
    }

    if (savedMileage || mileage) {
      const initializedFields = initialFields.map(field => ({
        ...field,
        initialValue: roundMileageValue(mileage)
      }));

      setSubmitAction('UPDATE_MILEAGE');
      setInitialFields(initializedFields);
    }

    // no deps because we only want this effect to run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContentWrapper>
      <Form
        submitAsync
        prompt={prompt}
        fields={fields}
        submitAction={submitAction}
        successRoute={successRoute}
      />
      <InfoBox
        extraData={{
          header: 'How Should I Estimate My Mileage?',
          text: 'Try to be as accurate as possible when you give us your mileage estimate for your initial quote. Just think about how much you drive in an average day.'
        }}
      />
    </StyledContentWrapper>
  );
}
