export default {
  name: 'account',
  pages: [
    {
      name: 'setup-account',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'static',
        value: 'Setup Your Account'
      }
    },
    {
      name: 'setup-trial',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'static',
        value: 'Test drive the Noblr App'
      }
    },
    {
      name: 'sign-in',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'static',
        value: 'Sign In'
      }
    },
    {
      name: 'confirm-email',
      overrideSectionTitle: {
        type: 'static',
        value: 'Confirm email address'
      }
    },
    {
      name: 'code-entry',
      overrideSectionTitle: {
        type: 'static',
        value: 'enter security code'
      }
    },
    {
      name: 'create-password',
      overrideSectionTitle: {
        type: 'static',
        value: 'Create password'
      }
    },
    {
      type: 'DownloadPage',
      name: 'download-app',
      overrideSectionTitle: {
        type: 'static',
        value: 'Download The App'
      }
    },
    {
      type: 'Static',
      name: 'need-help',
      hideBackArrow: true,
      hidePageHeader: true
    }
  ]
};
