export default {
  name: 'start-quote',
  pages: [
    {
      name: 'lets-go',
      hidePageHeader: true
    },
    {
      name: 'welcome',
      hidePageHeader: true
    },
    {
      name: 'referral',
      hidePageHeader: true
    },
    {
      name: 'name',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'static',
        value: 'Start your quote'
      },
      componentDidMountActions: ['CHECK_PERSON_STATUS']
    },
    {
      name: 'date-of-birth',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'address',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'address-entry',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'usaa-membership',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'usaa-eligibility',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'email',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'driver-license',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'age-licensed',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'gender',
      overrideSectionTitle: { type: 'static', value: 'ABOUT YOU' }
    },
    {
      name: 'marital-status',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'home-ownership',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'education',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'defensive-driving-course',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'driver-improvement-discount',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'active-military-service',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'confirm-primary-driver-info',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    },
    {
      name: 'good-student-discount',
      overrideSectionTitle: {
        type: 'static',
        value: 'ABOUT YOU'
      }
    }
  ]
};
