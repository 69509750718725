/* eslint-disable default-param-last */
import {
  GET_RATE,
  GET_RATE_SUCCESS,
  GET_RATE_FAILURE,
  VERIFY_VINS,
  VERIFY_VINS_SUCCESS,
  VERIFY_VINS_FAILURE,
  SET_RERATE,
  CLEAR_RATE,
  GET_RATE_BY_PACKAGE,
  GET_RATE_BY_PACKAGE_SUCCESS,
  GET_RATE_BY_PACKAGE_FAILURE,
  SET_CURRENT_PACKAGE,
  SET_RATE_BY_PACKAGE,
  GET_CUSTOM_COVERAGE_RATE,
  GET_CUSTOM_COVERAGE_RATE_SUCCESS,
  GET_CUSTOM_COVERAGE_RATE_FAILURE,
  SAVE_SELECTED_PACKAGE,
  SAVE_SELECTED_PACKAGE_SUCCESS,
  SAVE_SELECTED_PACKAGE_FAILURE,
  STORE_RATE_PLAN_STATE_CODE,
  ORDER_MVR,
  ORDER_MVR_SUCCESS,
  ORDER_MVR_FAILURE
} from '../actions';

const INITIAL_RATE_STATE = {
  loading: false,
  gotRate: false,
  vinRerate: false,
  vinVerificationNeeded: false,
  byPackage: { basic: null, moderate: null, custom: null },
  vehicles: [],
  defaultPackage: 'moderate',
  currentPackage: null,
  showTrial: false,
  pickerEffectiveStartDate: '',
  firstPossibleEffectiveDate: '',
  lastPossibleEffectiveDate: '',
  ratePlanStateCode: null,
  ratePlanState: null,
  mvrOrdered: false, // Motor Vehicle Report Order
  mvrOrderData: null // MVR Order Response
};

const rateReducer = (state = INITIAL_RATE_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RATE:
      return {
        ...state,
        showTrial: action.showTrial || state.showTrial,
        gotRate: false,
        loading: true
      };

    case GET_RATE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        gotRate: true
      };

    case VERIFY_VINS:
      return {
        ...state
      };

    case VERIFY_VINS_SUCCESS:
      return {
        ...state
      };

    case VERIFY_VINS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case SET_RERATE:
      return {
        ...state,
        ...payload
      };

    case GET_RATE_FAILURE:
      return {
        ...state,
        loading: false,
        gotRate: false,
        error: action.error
      };

    case CLEAR_RATE:
      return {
        ...state,
        vinRerate: false,
        vinVerificationNeeded: false,
        byPackage: { basic: null, moderate: null, custom: null },
        vehicles: [],
        currentPackage: 'moderate',
        pickerEffectiveStartDate: '',
        firstPossibleEffectiveDate: '',
        lastPossibleEffectiveDate: '',
        gotRate: false,
        savedCoverages: {
          policyCoverages: {},
          vehicleCoverages: {}
        }
      };

    case GET_RATE_BY_PACKAGE:
      return {
        ...state,
        showTrial: action.showTrial,
        gotRate: false,
        loading: true
      };

    case GET_RATE_BY_PACKAGE_SUCCESS:
      return {
        ...state,
        currentPackage: payload.currentPackage,
        firstPossibleEffectiveDate: payload.firstPossibleEffectiveDate,
        lastPossibleEffectiveDate: payload.lastPossibleEffectiveDate,
        vinVerificationNeeded: payload.vinVerificationNeeded,
        byPackage: {
          ...state.byPackage,
          [payload.currentPackage]: {
            ...state.byPackage[payload.currentPackage],
            ...payload.rate
          }
        },
        vehicles: payload.rate.vehicles,
        gotRate: true,
        loading: false
      };

    case GET_RATE_BY_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        gotRate: false,
        loading: false
      };

    case SAVE_SELECTED_PACKAGE:
      return {
        ...state,
        currentPackage: payload.packageType,
        savedCoverages: {
          policyCoverages: payload.policyCoverages,
          vehicleCoverages: payload.vehicleCoverages
        },
        loading: true
      };

    case SAVE_SELECTED_PACKAGE_SUCCESS:
      return {
        ...state,
        pickerEffectiveStartDate: payload.pickerEffectiveStartDate,
        firstPossibleEffectiveDate: payload.firstPossibleEffectiveDate,
        lastPossibleEffectiveDate: payload.lastPossibleEffectiveDate,
        vinVerificationNeeded: payload.vinVerificationNeeded,
        dlVerificationRequired: payload.dlVerificationRequired,
        vehicles: payload.vehicles,
        loading: false
      };

    case SAVE_SELECTED_PACKAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case GET_CUSTOM_COVERAGE_RATE:
      return {
        ...state,
        currentPackage: 'custom',
        byPackage: {
          ...state.byPackage,
          custom: {
            ...state.byPackage.custom
          }
        },
        savedCoverages: {
          policyCoverages: payload.policyCoverages,
          vehicleCoverages: payload.vehicleCoverages
        },
        gotRate: false,
        loading: true
      };

    case GET_CUSTOM_COVERAGE_RATE_SUCCESS:
      return {
        ...state,
        byPackage: {
          ...state.byPackage,
          custom: {
            ...state.byPackage.custom,
            ...payload,
            coverage: {
              ...state.byPackage.custom.coverage,
              ...payload.coverage
            }
          }
        },
        vehicles: payload.vehicles,
        firstPossibleEffectiveDate: payload.firstPossibleEffectiveDate,
        lastPossibleEffectiveDate: payload.lastPossibleEffectiveDate,
        vinVerificationNeeded: payload.vinVerificationNeeded,
        loading: false,
        gotRate: true
      };

    case GET_CUSTOM_COVERAGE_RATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case SET_RATE_BY_PACKAGE:
      return {
        ...state,
        byPackage: {
          ...state.byPackage,
          [payload.currentPackage]: {
            ...state.byPackage[payload.currentPackage],
            ...payload.rate
          }
        },
        currentPackage: payload.currentPackage
      };

    case SET_CURRENT_PACKAGE:
      return {
        ...state,
        currentPackage: payload
      };

    case STORE_RATE_PLAN_STATE_CODE:
      return {
        ...state,
        ratePlanStateCode: payload,
        ratePlanState: payload.slice(0, 2)
      };

    case ORDER_MVR:
      return {
        ...state,
        loading: true,
        mvrOrderData: null,
        mvrOrdered: 'pending',
        error: null
      };

    case ORDER_MVR_SUCCESS:
      return {
        ...state,
        loading: false,
        mvrOrdered: true,
        mvrOrderData: {
          ...payload
        }
      };

    case ORDER_MVR_FAILURE:
      return {
        ...state,
        loading: false,
        mvrOrdered: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default rateReducer;
