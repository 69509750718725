export const GET_RATE = 'GET_RATE';
export const GET_RATE_SUCCESS = 'GET_RATE_SUCCESS';
export const GET_RATE_FAILURE = 'GET_RATE_FAILURE';

export const ORDER_MVR = 'ORDER_MVR';
export const ORDER_MVR_SUCCESS = 'ORDER_MVR_SUCCESS';
export const ORDER_MVR_FAILURE = 'ORDER_MVR_FAILURE';

export const SET_RERATE = 'SET_RERATE';

export const CLEAR_RATE = 'CLEAR_RATE';

export const SHOW_RECALCULATE_BUTTON = 'SHOW_RECALCULATE_BUTTON';
export const HIDE_RECALCULATE_BUTTON = 'HIDE_RECALCULATE_BUTTON';

export const GET_RATE_BY_PACKAGE = 'GET_RATE_BY_PACKAGE';
export const GET_RATE_BY_PACKAGE_SUCCESS = 'GET_RATE_BY_PACKAGE_SUCCESS';
export const GET_RATE_BY_PACKAGE_FAILURE = 'GET_RATE_BY_PACKAGE_FAILURE';

export const VERIFY_VINS = 'VERIFY_VINS';
export const VERIFY_VINS_SUCCESS = 'VERIFY_VINS_SUCCESS';
export const VERIFY_VINS_FAILURE = 'VERIFY_VINS_FAILURE';

export const SET_RATE_BY_PACKAGE = 'SET_RATE_BY_PACKAGE';
export const SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE';

export const SAVE_SELECTED_PACKAGE = 'SAVE_SELECTED_PACKAGE';
export const SAVE_SELECTED_PACKAGE_SUCCESS = 'SAVE_SELECTED_PACKAGE_SUCCESS';
export const SAVE_SELECTED_PACKAGE_FAILURE = 'SAVE_SELECTED_PACKAGE_FAILURE';

export const GET_CUSTOM_COVERAGE_RATE = 'GET_CUSTOM_COVERAGE_RATE';
export const GET_CUSTOM_COVERAGE_RATE_SUCCESS =
  'GET_CUSTOM_COVERAGE_RATE_SUCCESS';
export const GET_CUSTOM_COVERAGE_RATE_FAILURE =
  'GET_CUSTOM_COVERAGE_RATE_FAILURE';

export const STORE_RATE_PLAN_STATE_CODE = 'STORE_RATE_PLAN_STATE_CODE';

export const getRate = (
  queryParams = {},
  showTrial = false,
  successRoute = '/quote/review-your-quote'
) => ({
  type: GET_RATE,
  queryParams,
  showTrial,
  successRoute
});

export const orderMVR = () => ({
  type: ORDER_MVR
});

export const orderMVRSuccess = data => ({
  type: ORDER_MVR_SUCCESS,
  payload: data
});

export const orderMVRFailure = error => ({
  type: ORDER_MVR_FAILURE,
  error
});

export const clearRate = () => ({
  type: CLEAR_RATE
});

export const showRecalculateButton = () => ({
  type: SHOW_RECALCULATE_BUTTON
});

export const hideRecalculateButton = () => ({
  type: HIDE_RECALCULATE_BUTTON
});

export const getRateByPackage = ({
  packageType,
  queryParams,
  showTrial,
  shouldRedirect = true
}) => ({
  type: GET_RATE_BY_PACKAGE,
  payload: { currentPackage: packageType, queryParams },
  showTrial,
  shouldRedirect
});

export const getRateByPackageSuccess = (packageType, rate) => ({
  type: GET_RATE_BY_PACKAGE_SUCCESS,
  payload: {
    currentPackage: packageType,
    rate
  }
});

export const getRateByPackageFailure = error => ({
  type: GET_RATE_BY_PACKAGE_FAILURE,
  error
});

export const setRateByPackage = (packageType, rate) => ({
  type: SET_RATE_BY_PACKAGE,
  payload: {
    currentPackage: packageType,
    rate
  }
});

export const setCurrentPackage = packageType => ({
  type: SET_CURRENT_PACKAGE,
  payload: packageType
});

export const saveSelectedPackage = ({
  packageType,
  policyCoverages = {},
  vehicleCoverages = {},
  successRoute,
  drivingBehavior = {}
}) => ({
  type: SAVE_SELECTED_PACKAGE,
  payload: {
    packageType,
    policyCoverages,
    vehicleCoverages,
    drivingBehavior
  },
  successRoute
});

export const getCustomCoverageRate = ({
  policyCoverages,
  vehicleCoverages,
  queryParams,
  values,
  successRoute
}) => {
  return {
    type: GET_CUSTOM_COVERAGE_RATE,
    payload: { policyCoverages, vehicleCoverages, queryParams, values },
    successRoute
  };
};

export const getCustomCoverageRateSuccess = rate => ({
  type: GET_CUSTOM_COVERAGE_RATE_SUCCESS,
  payload: rate
});

export const getCustomCoverageRateFailure = error => ({
  type: GET_CUSTOM_COVERAGE_RATE_FAILURE,
  error
});

export const setRatePlanStateCode = data => ({
  type: STORE_RATE_PLAN_STATE_CODE,
  payload: data
});

export const verifyVINs = (data, formikActions, successRoute) => ({
  type: VERIFY_VINS,
  payload: data,
  formikActions,
  successRoute
});

export const verifyVINsSuccess = () => ({
  type: VERIFY_VINS_SUCCESS
});

export const verifyVINsFailure = error => ({
  type: VERIFY_VINS_FAILURE,
  error
});
