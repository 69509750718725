import { differenceInYears } from 'date-fns';

// dateOfBirth must be in format MM/DD/YYYY
// todo: handle other formats?
export const calculateAge = dateOfBirth => {
  if (!dateOfBirth) {
    return null;
  }

  const [month, day, year] = dateOfBirth.split('/');
  // subtract 1 from month to account for 0 index of months in Date API
  const age = differenceInYears(new Date(), new Date(year, month - 1, day));

  if (!age) {
    return 101;
  }

  return age;
};

/**
 * If gender is returned as an initial, map it to the appropriate gender string
 * @param {string} genderFlag
 */
export const setGender = genderFlag => {
  const genders = {
    MALE: 'Male',
    Male: 'MALE',
    FEMALE: 'Female',
    Female: 'FEMALE',
    NON_BINARY: 'Non-binary',
    'Non-binary': 'NON_BINARY'
  };

  return genders[genderFlag];
};

// dob must be formatted as MM/dd/yyyy
// TODO: account for and handle unformatted DOB (ex. 19850904)
export const maskDOB = dob => {
  if (!dob) {
    return '';
  }

  return `DOB: **/**/${dob.split('/').slice(-1)}`;
};
