import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Prompt, StyledText } from 'styles';
import { breakPoints } from 'styles/variables';
import NoblrPhoneLink from 'components/NoblrPhoneLink';

const SoftStopWrapper = styled.div`
  margin: 1em auto 0;
  height: 10%;
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  align-content: center;

  h2 {
    @media screen and (max-width: ${breakPoints.small}) {
      max-width: 307px;
    }
  }
`;

const InnerContent = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakPoints.small}) {
    padding: 0 20px;
    max-width: 300px;
    text-align: justify;
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    max-width: 488px;
  }

  a {
    text-decoration: underline;
  }

  img {
    margin-bottom: 2em;
    height: 10em;
    width: auto;
    min-height: 220px;

    @media screen and (min-width: ${breakPoints.large}) {
      height: 20em;
      min-height: 285px;
      margin-bottom: 3em;
    }
  }
`;

function SoftStop({ success, errorCodes }) {
  const errorCodesLength = errorCodes ? errorCodes.length : 0;

  return (
    <SoftStopWrapper>
      <Prompt>Please Call Us to Complete Your Quote</Prompt>
      <InnerContent>
        {!success && !!errorCodes && !(errorCodesLength !== 0) ? (
          <img
            src={`${process.env.REACT_APP_NOBLR_CDN}/images/robot-question-marks-image.webp`}
            alt="Illustration of a robot hovering next to three question marks"
          />
        ) : (
          <img
            src={`${process.env.REACT_APP_NOBLR_CDN}/images/csr-avatar.webp`}
            alt="Illustration of a Customer Support Representative"
          />
        )}
        <StyledText>
          {"We're unable to complete your quote online. Please call us at "}
          <NoblrPhoneLink />
          {' and we can finish your quote over the phone.'}
        </StyledText>
      </InnerContent>
    </SoftStopWrapper>
  );
}

const mapStateToProps = ({ reCaptcha: { success, errorCodes } }) => ({
  success,
  errorCodes
});

SoftStop.propTypes = {
  success: PropTypes.bool,
  errorCodes: PropTypes.arrayOf(PropTypes.string)
};

SoftStop.defaultProps = {
  success: false,
  errorCodes: []
};

export default connect(mapStateToProps)(SoftStop);
