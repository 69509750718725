import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

class VehicleOwnershipPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Do you own, lease or finance this car?',
      submitAction: 'UPDATE_VEHICLE',
      fields: [
        {
          type: 'select',
          name: 'vehicleOwnership',
          id: 'vehicle-ownership-input',
          selectLabel: 'Vehicle Ownership',
          selectLabelId: 'vehicle-ownership-label',
          options: [
            { value: 'Lease', label: 'Lease' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Owned', label: 'Own' }
          ],
          required: true,
          initialValue: null
        }
      ],
      successRoute: '/add-vehicles/vehicles-list'
    };
  }

  componentDidMount() {
    const { askOwnershipLength, editingVehicle } = this.props;
    const { fields, successRoute } = this.state;

    const vehicleOwnershipField = fields[0];

    const updatedSuccessRoute = askOwnershipLength
      ? '/add-vehicles/vehicle-ownership-length'
      : successRoute;

    if (editingVehicle && editingVehicle.vehicleOwnership) {
      vehicleOwnershipField.initialValue = editingVehicle.vehicleOwnership;
    }

    this.setState({
      fields: [vehicleOwnershipField],
      successRoute: updatedSuccessRoute
    });
  }

  render() {
    const { editingVehicle } = this.props;
    const { prompt, submitAction, fields, successRoute } = this.state;

    return !editingVehicle ? (
      <Redirect to="/add-vehicles/vehicles-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  drivers: {
    primaryDriver: { requiresVehicleOwnershipMonths }
  },
  app: { askOwnershipLength },
  vehicles: { editingVehicle }
}) => ({
  askOwnershipLength: requiresVehicleOwnershipMonths || askOwnershipLength,
  editingVehicle
});

VehicleOwnershipPage.propTypes = {
  editingVehicle: PropTypes.shape({
    vehicleOwnership: PropTypes.string
  }).isRequired,
  askOwnershipLength: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(VehicleOwnershipPage);
