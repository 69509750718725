/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components/macro';
import { colors, brandonGrotesque, breakPoints } from 'styles/variables';

const InfoIcon = styled.span`
  width: 12px;
  height: 12px;
  border: 1px solid ${colors.alphaBlue500};
  border-radius: 50%;
  display: inline-block;
  position: relative;

  ${props =>
    props.id &&
    css`
      margin: 5px;
    `}

  &:hover {
    cursor: pointer;
  }
  & > a {
    font-size: 8px;
    position: absolute;
    margin: 0;
    left: 1px;
    right: 0;
    font-weight: bold;
    letter-spacing: 0.44px;
    text-align: center;
    color: ${colors.alphaBlue500};
    line-height: 10px;
  }
`;

const ToolTip = styled(ReactTooltip)`
  border-color: ${colors.coreBlue700};
  max-width: 280px;
  background: ${colors.white};
  text-align: left;
  padding: 15px 20px;
  &.type-light.border.place-bottom:before {
    border-bottom-color: ${colors.coreBlue700} !important;
  }
  &.__react_component_tooltip {
    &.show {
      opacity: 1;
      font-weight: normal;
    }
  }

  ${props =>
    props.reward &&
    css`
      width: 240px;
      @media screen and (max-width: ${breakPoints.small}) {
        width: 210px;
      }
    `}

  ${props =>
    props.payment &&
    css`
      width: 280px;
      @media screen and (max-width: ${breakPoints.small}) {
        width: 190px;
      }
    `}


  @media screen and (min-width: ${breakPoints.small}) {
    ${props =>
      props.mobilePosition &&
      css`
        position: ${props.mobilePosition} !important;
      `}
  }
`;

const Header = styled.span`
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 5px;
  color: ${colors.alphaBlue500};
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.29;
  font-weight: 500;

  ${props =>
    props.payment &&
    css`
      font-size: 8px;
    `}
`;

const Body = styled.div`
  font-size: 14px;
  color: ${colors.coreBlue700};
  font-family: ${brandonGrotesque};
  margin-bottom: 10px;
  p {
    margin-bottom: 10px;
  }
`;

export function ToolTipTrigger(props) {
  const { id } = props;

  return (
    <InfoIcon id={id}>
      <a data-tip data-for={id} tabIndex={0}>
        i
      </a>
    </InfoIcon>
  );
}

class InfoToolTip extends PureComponent {
  render() {
    const {
      header,
      body,
      children,
      place,
      overridePosition,
      mobilePosition,
      reward,
      payment,
      id,
      showTrigger = true
    } = this.props;

    return (
      <>
        {showTrigger && <ToolTipTrigger id={id} />}
        <ToolTip
          id={id}
          place={place || 'bottom'}
          type="light"
          effect="solid"
          border
          overridePosition={overridePosition}
          mobilePosition={mobilePosition}
          reward={reward}
          payment={payment}
        >
          <Header>{header}</Header>
          {body && (
            <>
              {body.map((p, idx) => {
                const key = `tooltip-body-${idx}`;

                return <Body key={key}>{p}</Body>;
              })}
            </>
          )}
          {children}
        </ToolTip>
      </>
    );
  }
}

export default InfoToolTip;
