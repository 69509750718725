import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { object } from 'yup';
import styled from 'styled-components/macro';
import Form from 'components/Form/Form';
import { validationSchemas } from 'utilities/form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';
import { selectVehiclesWithoutVIN } from 'redux/selectors';

const InfoBoxText = styled.p`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

class VinConfirmPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt:
        'To wrap things up we need the Vehicle Identification Number (VIN) for your car.',
      submitAction: 'VERIFY_VINS',
      successRoute: '/purchase/effective-date',
      fields: []
    };
    this.createValidationSchema = this.createValidationSchema.bind(this);
  }

  componentDidMount() {
    const { vehiclesWithoutVIN, requiresDriverExclusion, allDriversExcluded } =
      this.props;

    const { successRoute } = this.state;

    let updatedSuccessRoute = successRoute;

    if (requiresDriverExclusion && !allDriversExcluded) {
      updatedSuccessRoute = '/driver-exclusion/start';
    }

    const vinFields =
      vehiclesWithoutVIN &&
      vehiclesWithoutVIN.map(vehicle => ({
        type: 'text',
        name: `${vehicle.vehicleId}`,
        id: `${vehicle.vehicleId}-vin`,
        label: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
        required: true,
        shrink: true
      }));

    this.setState({
      fields: vinFields,
      successRoute: updatedSuccessRoute
    });
  }

  createValidationSchema() {
    const { fields } = this.state;
    const formValues = new Set();
    const schema = object().shape(
      fields.reduce((accumulator, field, index) => {
        accumulator[field.name] = validationSchemas.vin.test(
          'isUnique',
          'Please make sure each VIN is unique',
          value => {
            if (index === fields.length - 1) {
              formValues.clear();
            }

            if (formValues.has(value)) {
              return false;
            }
            formValues.add(value);

            return true;
          }
        );

        return accumulator;
      }, {})
    );

    return schema;
  }

  render() {
    const { fields, submitAction, successRoute, prompt } = this.state;
    const { vinVerificationNeeded, section } = this.props;

    if (!vinVerificationNeeded && section === 'purchase') {
      return <Redirect to="/quote/review-your-quote" replace />;
    }

    if (!vinVerificationNeeded && section === 'quote') {
      return <Redirect to="/purchase/effective-date" replace />;
    }

    const vinSchema = this.createValidationSchema();

    return (
      <StyledContentWrapper>
        {fields.length > 0 && (
          <Form
            fields={fields}
            prompt={prompt}
            submitAction={submitAction}
            successRoute={successRoute}
            validationSchema={vinSchema}
            submitAsync
          />
        )}
        <InfoBox
          extraData={{
            header: 'How do I find my VIN?'
          }}
        >
          <InfoBoxText>
            {`Find your VIN on your insurance ID cards, vehicle registration, or
            your car's dashboard or driver side door.`}
          </InfoBoxText>
          <InfoBoxText>
            Or simply copy/paste your VIN from your current car insurance
            account online.
          </InfoBoxText>
        </InfoBox>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = () => {
  const realMapState = state => {
    const {
      app: { section, requiresDriverExclusion },
      excludedDrivers: { allDriversExcluded },
      rate: { vinVerificationNeeded },
      rate
    } = state;

    return {
      section,
      vinVerificationNeeded,
      rate,
      vehiclesWithoutVIN: selectVehiclesWithoutVIN(state),
      requiresDriverExclusion,
      allDriversExcluded
    };
  };

  return realMapState;
};

VinConfirmPage.propTypes = {
  section: PropTypes.string.isRequired,
  requiresDriverExclusion: PropTypes.bool.isRequired,
  allDriversExcluded: PropTypes.bool.isRequired,
  vinVerificationNeeded: PropTypes.bool.isRequired,
  vehiclesWithoutVIN: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default connect(mapStateToProps)(VinConfirmPage);
