import React from 'react';
import styled from 'styled-components/macro';
import Accordion from 'components/Accordion';
import CoverageSummary from 'components/CoverageSummary';
import { StyledText, colors, openSans } from 'styles';
import CarsAndDrivers from 'components/Pages/Quote/sections/CarsAndDrivers';

const SectionWrapper = styled.div`
  margin: 0 0 30px;
  text-align: left;
`;

const SectionHeader = styled.h3`
  text-transform: uppercase;
  font-family: ${openSans};
  font-weight: 600;
  font-size: 20px;
  color: ${colors.coreBlue700};
`;

function ReviewPolicySummary() {
  // set up accordions
  const coveragesAccordion = [
    {
      key: 'coveragesAccordion',
      title: <StyledText bold>Coverages</StyledText>,
      content: <CoverageSummary readOnly />
    }
  ];

  return (
    <SectionWrapper>
      <SectionHeader>Policy Summary</SectionHeader>
      <CarsAndDrivers />
      <Accordion
        purchaseSummarySection
        noMargin
        sections={coveragesAccordion}
      />
    </SectionWrapper>
  );
}

export default ReviewPolicySummary;
