/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Redirect, withRouter } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import { Grid, Typography, Container } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import StaticComponent from 'components/StaticComponent';
import Sticky from 'components/Modifiers/Sticky';
import Button from 'components/Buttons/Button';
import AsyncButton from 'components/Buttons/AsyncButton';
import { verifyReCaptchaToken } from 'redux/actions';
import {
  getStateValueWithFallback,
  getSubmitAction,
  replaceSpacesWithDashes
} from 'utilities';
import { getValidationSchema, renderField } from 'utilities/form';
import { SubHeader, Prompt, breakPoints } from 'styles';
import Message from './Message';
import Separator from './Separator';
import { InputWrapper } from './styles';

const GOOGLE_RECAPTCHA_CLIENT_KEY = '6Lf2eooeAAAAAF6SEgtxA9lnGXgV3YuizPsko_0r';
const BYPASS_GOOGLE_RECAPTCHA_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

const isProduction = process.env.REACT_APP_HOST_ENV === 'production';

const StyledContainer = styled(Container)`
  margin: 0 auto;
  height: 100%;
  width: 100%;
`;

const FormWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  margin: 30px 0 0;

  @media only screen and (min-width: ${breakPoints.small}) {
    margin: 0 auto;
  }
`;

const StaticComponentWrapper = styled(Grid)`
  p {
    margin: 0 0 20px;
    text-align: center;
    overflow-wrap: break-word;
  }

  @media only screen and (min-width: ${breakPoints.small}) {
    margin: 10px auto 0;
    max-width: ${props =>
      props.$desktopMaxWidth ? props.$desktopMaxWidth : '350px'};

    p {
      padding: 0;
      text-align: left;
      max-width: 670px;
    }
    > div:not(:first-child) {
      margin: 15px auto 0;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const InlineErrorWrapper = styled(Grid)`
  margin: 0 auto 20px;
  min-height: 25px;
  max-height: 50px;
  height: auto;
  width: auto;
  text-align: center;
`;

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requiredFields: [], // TODO: Remove required fields from state?
      isInitialValid: false
    };

    this.handleReCaptcha = this.handleReCaptcha.bind(this);
    this.buildInitialValues = this.buildInitialValues.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { fields, dispatch, appForm, appError } = this.props;
    const requiredFields = fields
      .filter(field => field.required && (!field.initialValue || !field.value))
      .map(field => field.name);

    const isInitialValid = requiredFields.length === 0;

    if (appForm.error && appForm.error.status && appError && appError.status) {
      dispatch({ type: 'CLEAR_ASYNC_ERRORS' });
      dispatch({ type: 'CLEAR_FORM_ERROR' });
    }
    this.setState({
      requiredFields,
      isInitialValid
    });
  }

  handleReCaptcha(token) {
    const { verifyReCaptchaToken } = this.props;

    verifyReCaptchaToken(token);
  }

  handleSubmit(values, initialValues, actions) {
    const {
      submitAction,
      submitAsync = false,
      successRoute = null,
      history,
      dispatch,
      makeApiCall = false,
      appForm = null
    } = this.props;

    if (appForm && appForm.error && appForm.error.status) {
      dispatch({ type: 'CLEAR_FORM_ERROR' });
    }

    const actionToDispatch =
      submitAction instanceof Object
        ? getSubmitAction(submitAction)
        : submitAction;

    dispatch({
      type: actionToDispatch,
      payload: values,
      formikActions: actions,
      makeApiCall:
        makeApiCall || JSON.stringify(initialValues) !== JSON.stringify(values),
      successRoute
    });

    if (!submitAsync && successRoute) {
      history.push(successRoute);
    }
  }

  buildInitialValues(fields) {
    const initialValues = fields.reduce((acc, field) => {
      if (!acc[field.name]) {
        acc[field.name] = '';
      }

      if (field.initialValue || field.value) {
        acc[field.name] = field.initialValue || field.value;
      }

      return acc;
    }, {});

    return initialValues;
  }

  renderSubmitButton(submitAsync, buttonProps) {
    return submitAsync ? (
      <AsyncButton {...buttonProps} />
    ) : (
      <Button
        {...buttonProps}
        id={
          buttonProps.buttonText
            ? `${replaceSpacesWithDashes(buttonProps.buttonText)}-button`
            : 'continue-button'
        }
      >
        {buttonProps.buttonText || 'Continue'}
      </Button>
    );
  }

  render() {
    const {
      fields = [],
      buttonText,
      buttonWidth,
      submitAsync = false,
      prompt,
      promptFontWeight,
      promptTextAlign,
      promptFontSize,
      mobilePromptFontSize,
      serifPrompt,
      staticContent = null,
      subHeader,
      successRoute = null,
      appForm = null,
      confirmedPII,
      buttonMarginTop = '20px',
      buttonPosition,
      staticTextAlign,
      validationSchema = null,
      // set sticky button to false for forms in a modal
      stickyButton = true,
      isDNQ = false,
      appError = null,
      renderFieldRow = false,
      displayReCaptcha = false,
      landingPage = false,
      reCaptchaVerified,
      reCaptchaExpired,
      disableReCaptchaForTests,
      maxFormWidth = '95%',
      disableValidationErrors = false,
      history
    } = this.props;

    // set validation schema up before return
    const currentValidationSchema =
      validationSchema || getValidationSchema(fields);

    const initialValues = this.buildInitialValues(fields);

    return (
      <StyledContainer>
        {isDNQ ? (
          <Redirect to="/call-to-complete" replace />
        ) : (
          <Formik
            enableReinitialize
            validateOnChange
            validateOnBlur
            validateOnMount
            initialValues={initialValues}
            validationSchema={currentValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);

              this.handleSubmit(values, initialValues, actions);
            }}
          >
            {({ values, errors, status, isSubmitting, isValid, touched }) => {
              const isButtonDisabled =
                isSubmitting ||
                !isValid ||
                (isProduction &&
                  displayReCaptcha &&
                  !disableReCaptchaForTests &&
                  (reCaptchaVerified === 'pending' ||
                    !reCaptchaVerified ||
                    reCaptchaExpired));

              /*
                  "appError" is specific to the Referrals Landing Page.
                  If the request to "register quote" fails and the response returns a 400 status code, either the "source" was not sent in request or the referral code is invalid.
                */
              const hasAppErrors = !!appError && appError.status === 400;

              // define submit button props
              const submitButtonProps = submitAsync
                ? {
                    disabled: isButtonDisabled,
                    successRoute,
                    status,
                    buttonText,
                    buttonWidth,
                    isSubmitting,
                    noMargin: true
                  }
                : {
                    disabled: isButtonDisabled,
                    primary: true,
                    large: true,
                    noMargin: true,
                    buttonText,
                    isSubmitting
                  };

              return (
                <FormikForm className="formik-form">
                  {prompt && (
                    <Prompt
                      promptTextAlign={promptTextAlign}
                      promptFontWeight={promptFontWeight}
                      promptFontSize={promptFontSize}
                      mobilePromptFontSize={mobilePromptFontSize}
                      serif={serifPrompt}
                      tabIndex="0"
                    >
                      {prompt}
                    </Prompt>
                  )}
                  {subHeader && <SubHeader marginBottom>{subHeader}</SubHeader>}
                  <FormWrapper
                    container
                    direction="column"
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={3}
                  >
                    {fields.length > 0 &&
                      fields.map(field => {
                        const currentField = { ...field };

                        // TODO: Determine whether we can remove if statement below after discovering none of the pages contain a field.type set to  'separator'
                        if (currentField.type === 'separator') {
                          return <Separator key={currentField.type} />;
                        }

                        const hideField =
                          !!currentField.hidden &&
                          !!currentField.hidden.fallback
                            ? getStateValueWithFallback(currentField.hidden)
                            : !!currentField.hidden;

                        const renderedField = renderField({
                          ...currentField,
                          type: currentField.type,
                          value: values[currentField.name],
                          hidden: hideField,
                          confirmedPII,
                          prompt,
                          autoComplete: currentField.autoComplete || null,
                          fullWidth: !!maxFormWidth || false,
                          history
                        });

                        return renderFieldRow ? (
                          <Grid
                            container
                            direction="row"
                            wrap="wrap"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            item
                            xs
                            sm
                            spacing={3}
                            key={
                              currentField?.frontEndKey || currentField?.name
                            }
                          >
                            <Grid item sm xs>
                              <Typography
                                id={`${currentField.name}-field-title`}
                                // id connects field description text to the field via the aria-labelledby attribute
                              >
                                {currentField.description}
                              </Typography>
                            </Grid>
                            <Grid item sm xs>
                              {renderedField}
                              {touched[currentField.name] &&
                                errors[currentField.name] &&
                                !disableValidationErrors && (
                                  <Message
                                    type="error"
                                    message={errors[currentField.name]}
                                    id={`${currentField.name}_error`}
                                    aria-live="assertive"
                                  />
                                )}
                            </Grid>
                          </Grid>
                        ) : (
                          <InputWrapper
                            key={
                              currentField.frontEndKey
                                ? currentField.frontEndKey
                                : currentField.name
                            }
                            className={currentField.type}
                            maxHeight={
                              currentField.name === 'personaCode' ||
                              currentField.name === 'mileage'
                            }
                            // hides field on quote retrieval page
                            hidden={currentField.hidden}
                          >
                            {renderedField}
                            {touched[currentField.name] &&
                              errors[currentField.name] && (
                                <Message
                                  type="error"
                                  message={errors[currentField.name]}
                                  id={`${currentField.name}_error`}
                                  aria-live="assertive"
                                />
                              )}
                          </InputWrapper>
                        );
                      })}

                    <InlineErrorWrapper>
                      {!isSubmitting && touched && appForm?.error && (
                        <Message
                          type="error"
                          message={
                            appForm?.error?.message ||
                            appForm?.error?.data?.message
                          }
                        />
                      )}
                    </InlineErrorWrapper>
                    {!landingPage && staticContent && (
                      <StaticComponentWrapper
                        container
                        item
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        xs={12}
                        sm={9}
                      >
                        {staticContent &&
                          staticContent.map((content, index) => (
                            <StaticComponent
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              content={content}
                              staticTextAlign={staticTextAlign}
                            />
                          ))}
                      </StaticComponentWrapper>
                    )}
                    {!isSubmitting && hasAppErrors && (
                      <InlineErrorWrapper>
                        <Message
                          relative
                          type="error"
                          message={appError.message}
                          marginTop="40px"
                        />
                      </InlineErrorWrapper>
                    )}

                    {isProduction &&
                      displayReCaptcha &&
                      !disableReCaptchaForTests && (
                        <ReCAPTCHA
                          style={{
                            alignSelf: 'center'
                          }}
                          sitekey={
                            disableReCaptchaForTests
                              ? BYPASS_GOOGLE_RECAPTCHA_KEY
                              : GOOGLE_RECAPTCHA_CLIENT_KEY
                          }
                          onChange={this.handleReCaptcha}
                        />
                      )}

                    {landingPage && (
                      <StaticComponentWrapper
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        xs
                        sm
                      >
                        {staticContent &&
                          staticContent.map((content, index) => (
                            <StaticComponent
                              isLandingPage
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              content={content}
                              staticTextAlign={staticTextAlign}
                            />
                          ))}
                      </StaticComponentWrapper>
                    )}
                    {stickyButton ? (
                      <Sticky
                        buttonPosition={buttonPosition}
                        marginTop={buttonMarginTop}
                        className="form-button"
                      >
                        {this.renderSubmitButton(
                          submitAsync,
                          submitButtonProps
                        )}
                      </Sticky>
                    ) : (
                      <ButtonWrapper>
                        {this.renderSubmitButton(
                          submitAsync,
                          submitButtonProps
                        )}
                      </ButtonWrapper>
                    )}
                  </FormWrapper>
                </FormikForm>
              );
            }}
          </Formik>
        )}
      </StyledContainer>
    );
  }
}

const mapStateToProps = ({
  app: {
    form: appForm,
    confirmedPII,
    isDNQ,
    error: appError,
    isQuoteRetrieval
  },
  drivers: { primaryDriver },
  vehicles,
  form,
  reCaptcha: {
    reCaptchaVerified,
    expired: reCaptchaExpired,
    disableReCaptchaForTests
  }
}) => ({
  appForm,
  confirmedPII,
  primaryDriver,
  vehicles,
  form,
  isDNQ,
  appError,
  isQuoteRetrieval,
  reCaptchaVerified,
  reCaptchaExpired,
  disableReCaptchaForTests
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  verifyReCaptchaToken: token => dispatch(verifyReCaptchaToken(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
