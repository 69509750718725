import * as Sentry from '@sentry/react';
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import rootReducer from 'redux/reducers';
import rootSaga from 'redux/sagas';

// Initialize Sentry Redux Enhancer
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (
      action.type === 'RESET_PASSWORD' ||
      action.type === 'VERIFY_OTP' ||
      action.type === 'SAVE_PAYMENT_INFO'
    ) {
      // Return null to not log the action to Sentry
      return null;
    }

    if (action.type === 'LOGIN_USER' || action.type === 'CREATE_ACCOUNT') {
      return {
        ...action,
        authKey: null
      };
    }

    if (action.type === 'CONFIRM_EMAIL') {
      return {
        ...action,
        password: null,
        confirmPassword: null
      };
    }

    if (
      action.type === 'SET_PASSWORD' ||
      action.type === 'LOGIN_NOBLR_ACCOUNT' ||
      action.type === 'CREATE_NOBLR_ACCOUNT'
    ) {
      // Return a transformed action to remove sensitive information
      return {
        ...action,
        password: null,
        confirmPassword: null
      };
    }

    if (
      action.type === 'UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE' ||
      action.type === 'UPDATE_PRIMARY_DRIVER' ||
      action.type === 'UPDATE_PRIMARY_DRIVER_REQUEST' ||
      action.type === 'UPDATE_EDITING_DRIVER' ||
      action.type === 'SAVE_ADDITIONAL_DRIVER_INFO' ||
      action.type === 'SAVE_ADDITIONAL_DRIVER_INFO_SUCCESS' ||
      action.type === 'UPDATE_ADDITIONAL_DRIVER_INFO' ||
      action.type === 'UPDATE_ADDITIONAL_DRIVERS'
    ) {
      return {
        ...action,
        ageLicensed: null,
        age: null,
        city: null,
        educationLevelString: null,
        dateOfBirth: null,
        dlNumber: null,
        dlState: null,
        dob: null,
        email: null,
        firstName: null,
        genderMapping: null,
        genderString: null,
        givenName: null,
        homeOwner: null,
        lastName: null,
        maritalStatus: null,
        maritalStatusString: null,
        middleName: null,
        person: {
          ...action.payload.person,
          email: null,
          zipCode: null
        },
        phoneNumber: null,
        street: null,
        street2: null,
        streetAddress: null,
        surName: null,
        yearsLicensed: null,
        zipCode: null
      };
    }

    if (
      action.type === 'UPDATE_VEHICLE' ||
      action.type === 'UPDATE_EDITING_VEHICLE' ||
      action.type === 'UPDATE_VEHICLE_SUCCESS' ||
      action.type === 'SAVE_VIN_SUCCESS' ||
      action.type === 'REMOVE_VEHICLE_REQUEST' ||
      action.type === 'UNDO_REMOVE_VEHICLE'
    ) {
      return {
        ...action,
        ownerFirstName: null,
        ownerLastName: null,
        person: null,
        vehicleAge: null,
        vehicleAgeAtAddDate: null,
        vehicleCoverages: null,
        vehicleOwnership: null,
        vin: null
      };
    }

    if (
      action.type === 'UPDATE_ASSOCIATED_VEHICLES' ||
      action.type === 'GET_ALL_VEHICLES_SUCCESS' ||
      action.type === 'COMPLETE_ADDED_VEHICLE_REGISTRATION' ||
      action.type === 'REMOVE_VEHICLE_SUCCESS' ||
      action.type === 'UNDO_REMOVE_VEHICLE_SUCCESS' ||
      action.type === 'SET_PRIMARY_VEHICLE_ASSIGNMENT' ||
      action.type === 'SET_PRIMARY_VEHICLE_ASSIGNMENT_SUCCESS' ||
      action.type === 'ASSIGN_EXCESS_VEHICLES_SUCCESS'
    ) {
      return {
        ...action,
        items: action.payload?.items?.map(item => ({
          ...item,
          ownerFirstName: null,
          ownerLastName: null,
          person: null,
          vehicleAge: null,
          vehicleAgeAtAddDate: null,
          vehicleCoverages: null,
          vehicleOwnership: null,
          vin: null
        }))
      };
    }

    return action;
  }
});

/*
  Redux Logger is development middleware
  Logs all changes in Redux to Console in Chrome Dev Tools
*/
// const logger = createLogger({});

// Creates a Redux Middleware and connect Sagas to Redux Store
const sagaMiddleware = createSagaMiddleware({});

// Set up Redux middleware
const middleware = [
  sagaMiddleware
  // Disables logger middleware in Prod
  // process.env.NODE_ENV === 'development' &&
  //   process.env.REACT_APP_ENABLE_REDUX_DEBUGGING &&
  //   logger
].filter(Boolean);

/*
  composeEnhancers:
  Enable Redux Dev Tools "Tracers" in Dev
  (https://github.com/zalmoxisus/redux-devtools-extension)
  otherwise, compose enhancers
*/
// 9/1/2020 - Redux Dev Tools Docs recommend adding "typeof window === 'object'"
const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 50
        // Specify extension options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer)
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
