import styled, { css } from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import 'cleave.js/dist/addons/cleave-phone.us';
import {
  colors,
  openSans,
  brandonGrotesque,
  breakPoints
} from 'styles/variables';

export const sharedInputStyles = `
  padding: 10px 0;
  border: 0;
  outline: none;
  border: 1px solid ${colors.alphaBlue700};
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: ${openSans};
  color: ${colors.coreBlue700};
  caret-color: ${colors.alphaBlue500};
  letter-spacing: 0.58px;
  font-weight: 400;

  &:not(:first-child) {
    margin-top: 100px;
  }
  &:focus {
    border-width: 2px;
  }
`;

export const StyledTextInput = styled.input`
  ${sharedInputStyles}
  ${props =>
    props.invalid &&
    css`
      border-color: ${colors.red500};
    `}
  ${props =>
    props.type === 'sign' &&
    css`
      max-width: 100%;
    `}

    ${props =>
    props.hidden &&
    css`
      display: none;
      &.show {
        display: flex;
      }
    `}

      ${props =>
    props.type === 'number' &&
    css`
      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `}
`;

export const InputWrapper = styled(Grid)`
  margin: 0 auto;
  height: 100%;
  width: 350px;
  min-height: 105px;

  @media screen and (max-width: ${breakPoints.extraSmall}) {
    width: 305px;
  }

  ${props =>
    props.maxHeight &&
    css`
      max-height: 200px;
      height: 200px;
    `}
`;

export const InputLabel = styled.label`
  width: 100%;
  position: absolute;
  text-align: left;
  background-color: ${colors.white};
  font-family: ${brandonGrotesque};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: ${props => (props.highlight ? 600 : 500)};
  color: ${colors.alphaBlue500};
  ${props =>
    props.invalid &&
    css`
      color: ${colors.red500};
    `}

  ${props =>
    props.disabled &&
    css`
      color: ${colors.accessibleLightGunMetal};
    `}
`;
