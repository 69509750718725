import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';

function AddressPage() {
  const streetAddress = useSelector(
    state => state.drivers?.primaryDriver?.streetAddress
  );

  const street2 = useSelector(state => state.drivers?.primaryDriver?.street2);

  const fields = [
    {
      type: 'autoCompleteAddress',
      id: 'address-autocomplete-input',
      name: 'streetAddress',
      label: 'Street Address',
      placeholder: 'Street Address',
      required: true,
      value: '',
      initialValue: streetAddress || '',
      validation: 'autoCompleteAddress',
      autoComplete: 'streetAddress',
      ariaLabel: 'Street Address'
    },
    {
      type: 'text',
      name: 'street2',
      id: 'street2-input',
      label: 'Apartment or Unit #',
      value: '',
      initialValue: street2 || '',
      required: false,
      placeholder: 'Optional',
      validation: 'street2',
      autoComplete: 'address-line2',
      ariaLabel: 'Apartment or Unit #'
    }
  ];

  const form = [
    {
      type: 'static',
      value:
        'By clicking "Continue," you authorize us to order your credit-based insurance score, driving record, claims, and prior insurance information, any of which may be used to make decisions on coverage eligibility and rates and to provide you with a more accurate quote.',
      location: 'form'
    }
  ];

  const prompt = "What's your address";

  const submitAction = 'SAVE_PRIMARY_PERSON_ADDRESS';

  const successRoute = '/start-quote/usaa-membership';

  const [initialFields] = useState(fields);

  return (
    <StyledContentWrapper>
      <Form
        prompt={prompt}
        fields={initialFields}
        submitAction={submitAction}
        successRoute={successRoute}
        submitAsync
        staticContent={form}
      />
      <InfoBox
        extraData={{
          header: 'What Address Should I Use?',
          text: 'Enter your garaging address, the place where your car sleeps at night.'
        }}
      />
    </StyledContentWrapper>
  );
}

export default AddressPage;
