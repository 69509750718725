import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography } from '@material-ui/core';
import { colors, openSans } from 'styles';

const StyledLinkButton = styled(Link)`
  width: 245px;
  height: 50px;
  min-height: 50px;
  padding: 20px;
  border-radius: 28px;
  color: ${colors.white};
  border: 2px solid ${colors.alphaBlue500};
  background-color: ${colors.alphaBlue500};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 500;
  font-family: ${openSans};
  text-decoration: none;
  letter-spacing: 0.78px;
`;

const HeaderGrid = styled(Grid)`
  &&& {
    margin: 40px auto 0;
    width: 100%;
  }
`;

const StyledLinkButtonGrid = styled(Grid)`
  margin: 20px auto;
`;

function ReferralLandingPage() {
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        spacing={10}
      >
        <HeaderGrid item>
          <Typography align="center" variant="h1">
            The Noblr Refer-a-Friend Program Ended
          </Typography>
        </HeaderGrid>
        <Grid item>
          <Typography align="center">
            Thank you for you interest in Noblr! The Noblr Refer-a-Friend
            Program ended, as of June 30, 2022. But Noblr could still help you
            save on your auto insurance!
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={`${process.env.REACT_APP_NOBLR_CDN}/images/oops-error.webp`}
            alt="Oops an Error Has Occurred."
            width="350px"
            height="auto"
            style={{ objectFit: 'contain' }}
          />
        </Grid>
        <StyledLinkButtonGrid item xs={12}>
          <StyledLinkButton to="/start-quote/name" replace>
            GET A QUOTE
          </StyledLinkButton>
        </StyledLinkButtonGrid>
      </Grid>
    </Container>
  );
}
export default ReferralLandingPage;
