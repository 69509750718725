import { createSelector } from 'reselect';
import { getFieldProps, generateRestrictUMValue } from 'utilities/coverage';

const selectApp = state => state.app;

const selectDrivers = state => state.drivers;

const selectVehicles = state => state.vehicles;

const selectRate = state => state.rate;

const selectExcludedDrivers = state => state.excludedDrivers;

const selectCoverage = state => state.coverage;

const selectDriversItems = createSelector(
  [selectDrivers],
  drivers => drivers.items || []
);

const selectPrimaryDriver = createSelector(
  [selectDrivers],
  drivers => drivers.primaryDriver
);

export const makeSelectEditingDriver = () =>
  createSelector([selectDrivers], drivers => drivers.editingDriver);

const selectVehiclesItems = createSelector(
  [selectVehicles],
  vehicles => vehicles.items || []
);

const selectCurrentPackageType = createSelector(
  [selectRate],
  rate => rate.currentPackage
);

const selectCurrentPackageDetails = createSelector(
  [selectRate],
  rate => rate.byPackage[rate.currentPackage]
);

const selectRateVehicles = createSelector([selectRate], rate => rate.vehicles);

const currentExcludedDriverSelector = createSelector(
  [selectExcludedDrivers],
  excludedDrivers => excludedDrivers.driver
);

const excludedDriversByIdSelector = createSelector(
  [selectExcludedDrivers],
  excludedDrivers => excludedDrivers.byId
);

const nextExcludedDriverIdSelector = createSelector(
  [selectExcludedDrivers],
  excludedDrivers => excludedDrivers.nextExcludedDriverId
);

const lastExcludedDriverIdSelector = createSelector(
  [selectExcludedDrivers],
  excludedDrivers => excludedDrivers.lastExcludedDriverId
);

const selectCompletedExclusionFlowDrivers = createSelector(
  [selectExcludedDrivers],
  excludedDrivers => excludedDrivers.completedDrivers || []
);

const totalDriversToExcludeSelector = createSelector(
  [selectExcludedDrivers],
  excludedDrivers => excludedDrivers.totalDriversToExclude
);

const selectCoverageRatePlanStateCode = createSelector(
  [selectCoverage],
  coverage => coverage.ratePlanStateCode
);

const selectCoveragePackages = createSelector(
  [selectCoverage],
  coverage => coverage.coveragePackages
);

const selectCoverageConfigById = createSelector(
  [selectCoverage],
  coverage => coverage.config.byId
);

const selectCoverageRuleOptions = createSelector(
  [selectCoverage],
  coverage => coverage.coverageRuleOptions
);

const selectRestrictUMsFlag = createSelector([selectCoverage], coverage => {
  const { coveragePackages, currentPackage, ratePlanStateCode } = coverage;
  const ratePlanState = ratePlanStateCode.slice(0, 2);

  const restrictUM = generateRestrictUMValue(
    ratePlanState,
    coveragePackages.byId[currentPackage].policyCoverages
  );

  return coverage.restrictUM || restrictUM;
});

export const makeSelectActiveDrivers = () => {
  return createSelector([selectDriversItems], drivers =>
    drivers.filter(driver => driver.activeBit && driver.type === 'additional')
  );
};

export const makeSelectRemovedDrivers = () => {
  return createSelector([selectDriversItems], drivers =>
    drivers.filter(driver => !driver.activeBit)
  );
};

/* selects drivers that were manually excluded from add drivers flow */
export const makeSelectExcludedDrivers = () => {
  return createSelector([selectDriversItems], drivers =>
    drivers.filter(driver => driver.activeBit && driver.type === 'excluded')
  );
};

export const makeSelectPrimaryDriver = () => {
  return createSelector([selectDrivers], drivers => drivers.primaryDriver);
};

export const makeSelectRequiresDriverExclusion = () => {
  return createSelector([selectApp], app => app.requiresDriverExclusion);
};

/* selects drivers that were confirmed excluded on the driver exclusion flow */
export const makeSelectConfirmedExcludedDrivers = () => {
  return createSelector([selectCompletedExclusionFlowDrivers], drivers =>
    drivers.filter(driver => driver.type === 'excluded' && driver.activeBit)
  );
};

/* selects additional drivers that were converted from excluded or removed on driver exclusion flow and existing additional drivers */
export const makeSelectConfirmedAdditionalDrivers = () => {
  return createSelector(
    [selectCompletedExclusionFlowDrivers, selectDriversItems],
    (exclusionFlowDrivers, driverFlowDrivers) =>
      [...driverFlowDrivers, ...exclusionFlowDrivers].filter(
        driver => driver.type === 'additional' && driver.activeBit
      )
  );
};

export const selectDriverById = createSelector(
  [selectDriversItems, (state, driverId) => driverId],
  (items, driverId) => items.filter(driver => driver.driverId === driverId)
);

export const selectDriversById = createSelector(
  [makeSelectActiveDrivers(), selectPrimaryDriver],
  (additionalDrivers, primaryDriver) =>
    additionalDrivers.reduce(
      (acc, driver) => {
        acc[driver.driverId] = driver;

        return acc;
      },
      { [primaryDriver.driverId]: primaryDriver }
    )
);

/**
 * Get all active vehicles
 */
export const makeSelectActiveVehicles = () => {
  return createSelector([selectVehiclesItems], cars =>
    cars.filter(car => car.activeBit)
  );
};
/**
 * Creates boolean if primarilyDrivenBy exists
 */
export const selectPrimarilyDrivenBy = createSelector(
  [selectVehiclesItems],
  vehicles => !!vehicles.find(vehicle => vehicle.primarilyDrivenBy)
);

export const selectUnassignedVehicles = createSelector(
  [makeSelectActiveVehicles()],
  vehicles => vehicles.filter(vehicle => !vehicle.hasPrimaryDriver)
);
/**
 * Get vehicles missing VIN
 */
export const selectVehiclesWithoutVIN = createSelector(
  [selectRateVehicles],
  vehicles => vehicles.filter(vehicle => !vehicle.vin)
);

/**
 * Get driverId from activeVehicles
 */
export const selectDriverIdFromVehicles = createSelector(
  [makeSelectActiveVehicles()],
  vehicles => !!vehicles.find(vehicle => vehicle.primarilyDrivenBy)
);

/**
 * @returns {object} - object with driver info for cars and drivers section on quote page
 */
export const selectPrimarilyDrivenAssignmentDetails = createSelector(
  [makeSelectActiveVehicles(), selectDriversById],
  (activeVehicles, driversById) =>
    activeVehicles.map(
      ({
        modelYear,
        make,
        model,
        vin,
        vehicleId,
        hasPrimaryDriver,
        primarilyDrivenBy
      }) => {
        let returnObj = {
          vehicle: {
            vin,
            make,
            model,
            year: modelYear,
            vehicleId,
            hasPrimaryDriver
          }
        };

        // if we have vehicle assignments, add to our return object
        if (primarilyDrivenBy) {
          // driverIds is an array of driver ids
          const { driverIds } = primarilyDrivenBy;

          // for each id, we need to get the first and last name of the drivers
          const driverNames = driverIds.map(driverId => {
            const { firstName, lastName } = driversById[driverId];

            return { firstName, lastName, driverId };
          });

          returnObj = {
            ...returnObj,
            driverNames
          };
        }

        return returnObj;
      }
    )
);

export const selectPrimaryVehicleFields = createSelector(
  [makeSelectActiveVehicles(), selectDriversById],
  (vehicles, driversById) => {
    const options = vehicles.map(({ vehicleId, make, model, modelYear }) => ({
      value: vehicleId,
      label: `${modelYear} ${make} ${model}`
    }));

    return Object.keys(driversById).map(driverId => {
      const { firstName, cars, dob } = driversById[driverId];

      return {
        firstName,
        cars,
        dob,
        driverId,
        options
      };
    });
  }
);

export const selectExcessVehicleFields = createSelector(
  [selectUnassignedVehicles, selectDriversById],
  (unassignedVehicles, driversById) => {
    const options = Object.keys(driversById).map(driverId => {
      const { firstName, lastName, dob } = driversById[driverId];

      return {
        value: driverId,
        label: `${firstName} ${lastName} (${dob})`
      };
    });

    // now we need to map over all the unassigned vehicles and return the field props for them
    return unassignedVehicles.map(
      ({ vehicleId, make, model, modelYear, primarilyDrivenBy }) => ({
        vehicleId,
        make,
        model,
        modelYear,
        primarilyDrivenBy,
        options
      })
    );
  }
);

/**
 * @returns {array} - array of objects with vehicle info used in coverages section
 */
export const selectAllVehiclesInfo = createSelector(
  [makeSelectActiveVehicles()],
  cars =>
    cars.map(({ vehicleId, make, model, modelYear }) => ({
      id: vehicleId,
      frontEndKey: vehicleId,
      make,
      model,
      modelYear
    }))
);

/**
 * @returns Array of objects to display coverage package sections
 */
export const selectCoveragePackagesToBuild = createSelector(
  [
    selectCoveragePackages,
    selectCoverageConfigById,
    makeSelectActiveVehicles(),
    selectCoverageRuleOptions,
    selectRestrictUMsFlag,
    selectCoverageRatePlanStateCode // inside coverage reducer - not rate
  ],
  (
    coveragePackages,
    configById,
    vehicles,
    coverageRuleOptions,
    restrictUM,
    ratePlanStateCode
  ) => {
    const ratePlanState = ratePlanStateCode.slice(0, 2);
    const { byId, allIds } = coveragePackages;

    const roadsideConfig = configById.road;
    const roadSideContent = {
      header: roadsideConfig.display,
      helpShort: roadsideConfig.helpShort,
      modalContent: roadsideConfig.helpList
    };

    return (
      allIds &&
      allIds.map(packageId => {
        const {
          coveragePackageName,
          key,
          policyCoverages,
          vehicleCoverages,
          policyCoveragesSections,
          vehicleCoveragesSections
        } = byId[packageId];

        const policyLevelSections =
          policyCoveragesSections &&
          policyCoveragesSections.map(section => {
            const isUMToggleSection = section.name === 'UM_ENHANCED';
            const isCustomCoverage = packageId === 'custom';

            // check that package id is custom so we don't add empty row on coverage packages
            if (isUMToggleSection && isCustomCoverage) {
              let values = {};

              if (ratePlanState === 'MD') {
                values = {
                  allIds: ['EXCLUDE_ENHANCED', 'ONLY_ENHANCED'],
                  byId: {
                    ONLY_ENHANCED: {
                      code: 'ONLY_ENHANCED',
                      valueDisplay: 'Enhanced Coverages'
                    },
                    EXCLUDE_ENHANCED: {
                      code: 'EXCLUDE_ENHANCED',
                      valueDisplay: 'Standard Coverages'
                    }
                  }
                };
              } else if (ratePlanState === 'GA') {
                values = {
                  allIds: ['ADDED_ON', 'REDUCED_BY'],
                  byId: {
                    ADDED_ON: {
                      code: 'ADDED_ON',
                      valueDisplay: 'Added On Coverages'
                    },
                    REDUCED_BY: {
                      code: 'REDUCED_BY',
                      valueDisplay: 'Reduced By Coverages'
                    }
                  }
                };
              } else if (
                ratePlanState === 'VA' &&
                ratePlanStateCode !== 'VA1000'
              ) {
                values = {
                  allIds: ['REDUCED_BY', 'ADDED_ON'],
                  byId: {
                    ADDED_ON: {
                      code: 'ADDED_ON',
                      valueDisplay: 'No'
                    },
                    REDUCED_BY: {
                      code: 'REDUCED_BY',
                      valueDisplay: 'Yes'
                    }
                  }
                };
              }

              const enhancedCoverageRadio = {
                id: 'UMToggle',
                values,
                ariaLabel: 'Toggle options for UM coverages',
                fieldLabel: '',
                value: restrictUM,
                uiFieldType: 'COMBORADIO',
                display: null,
                helpShort: null,
                help: null
              };

              if (ratePlanState === 'VA' && ratePlanStateCode !== 'VA1000') {
                enhancedCoverageRadio.display = `Reduce My Underinsured Motorist Coverage \n (This will lower your premium)`;
                enhancedCoverageRadio.helpShort =
                  'The maximum amount you can receive from your Uninsured Motorist coverage will be reduced by the liability limits paid by an underinsured driver.';
              }

              // add um toggle to config and array of field ids
              // make sure um toggle is before allIds so the radio will display before the fields
              const fieldIds = ['UMToggle', ...section.allIds];
              const fieldConfig = {
                ...configById,
                UMToggle: enhancedCoverageRadio
              };

              const customPackageFields =
                fieldIds &&
                fieldIds.map(id => {
                  const {
                    display,
                    helpShort,
                    values,
                    helpList,
                    fieldLabel,
                    uiFieldType = 'SELECT',
                    comboDisplay
                  } = fieldConfig[id];

                  const fieldRuleResults =
                    (coverageRuleOptions && coverageRuleOptions[id]) || null;

                  const currentValue =
                    id === 'UMToggle'
                      ? fieldConfig[id].value
                      : policyCoverages[id];

                  const value = values.byId[currentValue].code;

                  return getFieldProps({
                    restrictUM,
                    header: display,
                    helpShort,
                    value,
                    id,
                    modalContent: helpList,
                    name: id,
                    label: fieldLabel,
                    uiFieldType,
                    fieldRuleResults,
                    comboDisplay,
                    values,
                    errorMessages:
                      (fieldRuleResults && fieldRuleResults.errorMessages) ||
                      [],
                    ratePlanStateCode
                  });
                });

              return {
                packageId,
                name: section.name,
                header: section.header,
                items: customPackageFields
              };
            }

            // basic and moderate tab fields
            const fields =
              section.allIds &&
              section.allIds.map(id => {
                const {
                  display,
                  helpShort,
                  values,
                  helpList,
                  fieldLabel,
                  uiFieldType = 'SELECT',
                  comboDisplay
                } = configById[id];

                const fieldRuleResults =
                  coverageRuleOptions && coverageRuleOptions[id];

                const value = policyCoverages[id];
                const currentDisplayValue = values.byId[value].valueDisplay;

                return getFieldProps({
                  restrictUM,
                  header: display,
                  helpShort,
                  id,
                  value: currentDisplayValue,
                  modalContent: helpList,
                  name: id,
                  label: fieldLabel,
                  uiFieldType,
                  fieldRuleResults,
                  comboDisplay,
                  values,
                  errorMessages:
                    (fieldRuleResults && fieldRuleResults.errorMessages) || [],
                  ratePlanStateCode
                });
              });

            return {
              packageId,
              name: section.name,
              header: section.header,
              items: fields
            };
          });

        let vehicleInfoArray = [];

        // iterate over each vehicle to get value
        const vehicleLevelSections = vehicles.map(
          ({ modelYear, make, model, vehicleId, vehicleOwnership }) => {
            vehicleInfoArray = [
              ...vehicleInfoArray,
              {
                frontEndKey: vehicleId,
                id: vehicleId,
                make,
                model,
                modelYear
              }
            ];

            const statusOfOwnership =
              vehicleOwnership === 'Owned' ? 'owned' : 'financed';

            const sections =
              vehicleCoveragesSections &&
              vehicleCoveragesSections.map(({ header, name, allIds }) => {
                const items =
                  allIds &&
                  allIds
                    // we don't offer road in webquote so we have to filter it out here
                    .filter(coverageId => coverageId !== 'road')
                    .map(coverageId => {
                      const {
                        display,
                        helpList,
                        helpShort,
                        id,
                        values,
                        fieldLabel
                      } = configById[coverageId];

                      const vehicleCoverageName = `${vehicleId}-${id}`;
                      const fieldRuleResults =
                        coverageRuleOptions &&
                        coverageRuleOptions[vehicleCoverageName];

                      const value = vehicleCoverages[statusOfOwnership][id];

                      const displayValue = values.byId[value].valueDisplay;

                      return getFieldProps({
                        restrictUM,
                        header: display,
                        helpShort,
                        value: displayValue,
                        id: vehicleCoverageName,
                        name: vehicleCoverageName,
                        modalContent: helpList,
                        label: fieldLabel,
                        values,
                        fieldRuleResults,
                        ratePlanStateCode
                      });
                    });

                return {
                  header,
                  name,
                  items,
                  vehicleInfoArray
                };
              });

            return {
              title: `${modelYear} ${make} ${model}`,
              sections,
              packageId
            };
          }
        );

        return {
          label: coveragePackageName,
          id: key,
          policyLevelSections,
          vehicleLevelSections,
          roadSideContent,
          key
        };
      })
    );
  }
);

export const selectPackageToBuild = createSelector(
  [
    selectCoveragePackages,
    selectCoverageConfigById,
    makeSelectActiveVehicles(),
    selectCurrentPackageType,
    selectCurrentPackageDetails
  ],
  (
    coveragePackages,
    configById,
    vehicles,
    currentPackageType,
    currentPackageDetails
  ) => {
    const packageDetails = {};
    const {
      policyCoverages,
      policyCoveragesSections,
      vehicleCoveragesSections,
      vehicleCoverages
    } = coveragePackages.byId[currentPackageType];

    const policyLevelSections = policyCoveragesSections.map(
      ({ name, allIds }) => {
        const items = allIds.map(id => {
          const {
            display,
            values: { byId }
          } = configById[id];

          const valueId = currentPackageDetails.coverage
            ? currentPackageDetails.coverage[id]
            : policyCoverages[id];

          const value = byId[valueId].valueDisplay;

          return {
            label: display,
            value
          };
        });

        return {
          name,
          items
        };
      }
    );

    packageDetails.policyLevelSections = policyLevelSections;

    const vehicleLevelSections = vehicles.map(
      ({ modelYear, make, model, vehicleOwnership, vehicleId }) => {
        const coverageValues =
          vehicleOwnership &&
          (vehicleOwnership === 'Lease' || vehicleOwnership === 'Finance')
            ? vehicleCoverages.financed
            : vehicleCoverages.owned;

        const sections = vehicleCoveragesSections.map(({ allIds, name }) => {
          const items = allIds
            // make sure roadside is filtered out since we add it
            .filter(coverageId => coverageId !== 'road')
            .map(id => {
              const {
                display,
                values: { byId }
              } = configById[id];

              const valueId = currentPackageDetails.coverage
                ? currentPackageDetails.coverage[`${vehicleId}-${id}`]
                : coverageValues[id];

              const value = byId[valueId].valueDisplay;

              return {
                label: display,
                value
              };
            });

          return {
            name,
            items: [
              ...items,
              { label: 'Roadside Assistance', value: 'Included' }
            ]
          };
        });

        return {
          name: `${modelYear} ${make} ${model}`,
          vehicleId,
          sections
        };
      }
    );

    packageDetails.vehicleLevelSections = vehicleLevelSections;

    return packageDetails;
  }
);

export const makeSelectCurrentExcludedDriver = () =>
  createSelector([currentExcludedDriverSelector], driver => driver);

export const makeSelectNextExcludedDriver = () =>
  createSelector(
    [excludedDriversByIdSelector, nextExcludedDriverIdSelector],
    (byId, nextExcludedDriverId) => byId[nextExcludedDriverId]
  );

export const makeSelectLastExcludedDriver = () =>
  createSelector(
    [excludedDriversByIdSelector, lastExcludedDriverIdSelector],
    (byId, lastEditedDriverId) => byId[lastEditedDriverId]
  );
export const makeDriversPendingExclusionSelector = () =>
  createSelector([totalDriversToExcludeSelector], count => count > 0);

export const makeSelectPolicyInfoByRate = () => {
  return createSelector([selectRate], rate => {
    const { currentPackage, byPackage } = rate;

    return currentPackage ? byPackage[currentPackage] : rate;
  });
};
