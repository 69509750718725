import React, { Component } from 'react';
import SignPage from 'components/Pages/SignPage';
import { StyledContentWrapper } from 'styles';

class MedPayForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'SUBMIT_PREBIND_SIGNATURE',
      fields: [
        {
          type: 'sign',
          name: 'medPayForm',
          id: 'med-pay-form-input',
          label: 'First and Last Name',
          required: true,
          validation: 'fullName',
          autoComplete: 'name',
          fullWidth: true
        }
      ]
    };
  }

  render() {
    const { submitAction, fields } = this.state;
    const {
      data: { staticContent },
      prompt,
      successRoute
    } = this.props;

    return (
      <StyledContentWrapper>
        <SignPage
          prompt={prompt}
          pdfFileName="medPayForm"
          formStaticContent={staticContent}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          documentName={prompt}
        />
      </StyledContentWrapper>
    );
  }
}

export default MedPayForm;
