import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';

export default function VinEtchingPage() {
  const vehicleId = useSelector(
    state => state.vehicles?.editingVehicle?.vehicleId
  );

  const make = useSelector(state => state.vehicles?.editingVehicle?.make);

  const model = useSelector(state => state.vehicles?.editingVehicle?.model);

  const fields = [
    {
      type: 'radio',
      name: 'vinEtchingDiscountMapping',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: true,
      radioGroupAriaLabel: 'VIN Etching Discount Test'
    }
  ];

  const submitAction = 'UPDATE_VEHICLE';

  const successRoute = '/add-vehicles/vehicles-list';

  return !vehicleId ? (
    <Redirect to="/add-vehicles/vehicles-list" replace />
  ) : (
    <StyledContentWrapper>
      <Form
        prompt={`Is a Vehicle Identification Number (VIN) etched on all of the windows of your ${make} ${model}?`}
        fields={fields}
        submitAction={submitAction}
        successRoute={successRoute}
        submitAsync
      />
      <InfoBox
        extraData={{
          header: 'What Is VIN Etching?',
          text: 'The VIN is permanently engraved on the windshield, door windows, and rear window of your car. To qualify for the discount, VIN etching must be on all windows of your car.'
        }}
      />
    </StyledContentWrapper>
  );
}
