import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import Loader from 'components/Loader';
import { generateNumericSelectOptions } from 'utilities';
import { StyledContentWrapper } from 'styles';

class AgeLicensedPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'How old were you when you got your first-ever license?',
      submitAction: 'UPDATE_PRIMARY_DRIVER_REQUEST',
      fields: [],
      successRoute: `/start-quote/gender`
    };
  }

  componentDidMount() {
    const { editingDriver, primaryDriver, section } = this.props;
    const { prompt, successRoute, submitAction } = this.state;

    const additionalDriverFlow = section !== 'start-quote';

    const currentFields = [
      {
        type: 'select',
        name: 'ageLicensed',
        id: 'age-licensed-input',
        required: true,
        selectLabel: 'first licensed at',
        selectLabelId: 'age-licensed-label'
      }
    ];

    const currentSuccessRoute = additionalDriverFlow
      ? '/add-drivers/gender'
      : successRoute;

    const currentSubmitAction = additionalDriverFlow
      ? 'UPDATE_ADDITIONAL_DRIVER_INFO'
      : submitAction;

    const driver = additionalDriverFlow ? editingDriver : primaryDriver;

    const currentPrompt = additionalDriverFlow
      ? 'How old was this driver when they first got their license?'
      : prompt;

    const updatedFields = currentFields.map(field => ({
      ...field,
      initialValue: driver.ageLicensed || 16,
      options: generateNumericSelectOptions(15, driver.age, 'Licensed at', true)
    }));

    this.setState({
      prompt: currentPrompt,
      successRoute: currentSuccessRoute,
      fields: updatedFields,
      submitAction: currentSubmitAction,
      mounted: true
    });
  }

  render() {
    const { section, editingDriver } = this.props;
    const { prompt, submitAction, fields, successRoute, mounted } = this.state;

    if (!mounted) {
      return <Loader />;
    }

    return section === 'add-drivers' && !editingDriver.firstName ? (
      <Redirect to="/add-drivers/drivers-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          makeApiCall
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({ drivers: { primaryDriver, editingDriver } }) => ({
  primaryDriver,
  editingDriver
});

AgeLicensedPage.propTypes = {
  primaryDriver: PropTypes.shape({
    dob: PropTypes.string,
    ageLicensed: PropTypes.number.isRequired
  }),
  editingDriver: PropTypes.shape({
    firstName: PropTypes.string,
    dob: PropTypes.string,
    ageLicensed: PropTypes.number.isRequired
  }),
  section: PropTypes.string
};

AgeLicensedPage.defaultProps = {
  primaryDriver: {
    dob: ''
  },
  editingDriver: {
    firstName: '',
    dob: ''
  },
  section: ''
};

export default connect(mapStateToProps)(AgeLicensedPage);
