import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { generateNumericSelectOptions } from 'utilities';
import { StyledContentWrapper } from 'styles';
import {
  GENDER_OPTIONS,
  HOME_OWNER_OPTIONS,
  EDUCATION_LEVEL_OPTIONS,
  MARITAL_STATUS_OPTIONS
} from '../../constants';
import { STATE_OPTIONS } from '../../constants/stateCodes';

function ConfirmPrimaryDriverInfoPage() {
  const [successRoute, setSuccessRoute] = useState('/add-drivers/drivers-list');

  const primaryDriver = useSelector(state => state.drivers?.primaryDriver);

  const usaaPersonInfoVerified = useSelector(
    state => state.app.usaaPersonInfoVerified
  );

  const askSafeDriving = useSelector(state => state.app?.askSafeDriving);

  const askActiveMilitaryPersonnel = useSelector(
    state => state.app?.askActiveMilitaryPersonnel
  );

  const askSeniorDriverImprovement = useSelector(
    state => state.app?.askSeniorDriverImprovement
  );

  const askGoodStudentDiscount = useSelector(
    state => state.app?.askGoodStudentDiscount
  );

  useEffect(() => {
    if (askSafeDriving) {
      setSuccessRoute('/start-quote/defensive-driving-course');
    }

    if (askActiveMilitaryPersonnel) {
      setSuccessRoute('/start-quote/active-military-service');
    }

    if (askSeniorDriverImprovement) {
      setSuccessRoute('/start-quote/driver-improvement-discount');
    }

    if (askGoodStudentDiscount) {
      setSuccessRoute('/start-quote/good-student-discount');
    }
  }, [
    askSafeDriving,
    askActiveMilitaryPersonnel,
    askSeniorDriverImprovement,
    askGoodStudentDiscount
  ]);

  const prompt =
    'We found your driving records! Please verify or modify as needed.';

  const submitAction = 'SET_HOMEOWNER';

  const form = [
    {
      type: 'static',
      value:
        "By clicking “Continue”, you are confirming that the information above is accurate and this driver's license is in good standing.",
      location: 'form'
    }
  ];

  // TODO: possibly pull these fields out to their own constants folder PRIMARY_DRIVER_INFO_FIELDS or something
  const fields = [
    {
      type: 'select',
      name: 'homeOwner',
      id: 'home-ownership-select-input',
      selectLabel: 'Home Ownership',
      selectLabelId: 'home-ownership-select-label',
      options: HOME_OWNER_OPTIONS,
      required: true
    },
    {
      type: 'select',
      name: 'education',
      id: 'education-select-input',
      selectLabel: 'Education Achieved',
      selectLabelId: 'education-select-label',
      options: EDUCATION_LEVEL_OPTIONS,
      required: true
    },
    {
      type: 'select',
      name: 'maritalStatus',
      id: 'marital-status-select-input',
      selectLabel: 'Marital Status',
      selectLabelId: 'marital-status-label',
      options: MARITAL_STATUS_OPTIONS,
      required: true
    },
    {
      type: 'select',
      name: 'ageLicensed',
      id: 'age-licensed-select-input',
      selectLabel: 'first licensed at',
      selectLabelId: 'age-licensed-label',
      required: true,
      options: []
    },
    {
      type: 'select',
      name: 'genderMapping',
      id: 'gender-select-input',
      selectLabel: 'Gender',
      selectLabelId: 'gender-select-label',
      options: GENDER_OPTIONS,
      required: true
    },
    {
      type: 'maskedText',
      name: 'dlNumber',
      id: 'drivers-license-input',
      label: 'drivers license',
      placeholder: 'License Number',
      mask: true,
      required: true
    },
    {
      type: 'searchableSelect',
      name: 'dlState',
      id: 'license-state-input',
      label: 'License State',
      initialValue: '',
      required: true,
      options: STATE_OPTIONS
    }
  ];

  const initializedFields = fields.map(field => {
    const displaySavedInitialValue =
      usaaPersonInfoVerified || primaryDriver.driverComplete;

    const primaryDriverValue = primaryDriver[field.name];

    if (field.name === 'homeOwner') {
      // 'homeOwner' is set to true or false when the component renders
      const initialHomeOwnerValue = primaryDriverValue ? 'Own' : 'Other';

      return {
        ...field,
        initialValue: displaySavedInitialValue ? initialHomeOwnerValue : ''
      };
    }

    if (field.name === 'education') {
      return {
        ...field,
        initialValue: displaySavedInitialValue
          ? primaryDriverValue
          : 'College degree'
      };
    }

    if (field.name === 'ageLicensed') {
      const options = generateNumericSelectOptions(
        15,
        primaryDriver.age,
        'Licensed at',
        true
      );

      return {
        ...field,
        options,
        initialValue: displaySavedInitialValue ? primaryDriverValue : 16
      };
    }

    if (field.name === 'genderMapping') {
      if (primaryDriver.state === 'CO') {
        return {
          ...field,
          options: [GENDER_OPTIONS[0], GENDER_OPTIONS[1]],
          initialValue: displaySavedInitialValue ? primaryDriverValue : ''
        };
      }

      return {
        ...field,
        initialValue: displaySavedInitialValue ? primaryDriverValue : ''
      };
    }

    const initialValue =
      field.name === 'dlNumber' || displaySavedInitialValue
        ? primaryDriverValue
        : '';

    return {
      ...field,
      initialValue
    };
  });

  return (
    <StyledContentWrapper>
      <Form
        prompt={prompt}
        fields={initializedFields}
        submitAction={submitAction}
        successRoute={successRoute}
        staticContent={form}
        submitAsync
      />
      <InfoBox
        extraData={{
          header: 'Why Are We Asking This?'
        }}
      >
        <p>
          {`We use your driver's license to verify your information, speeding up
            your quote process.`}
        </p>
        <br />
        <p>
          You are confirming that your license is current and not expired,
          suspended, revoked, or otherwise inactive.
        </p>
      </InfoBox>
    </StyledContentWrapper>
  );
}

export default ConfirmPrimaryDriverInfoPage;
