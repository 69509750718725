import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Prompt } from 'styles';
import { openSans } from 'styles/variables';

const Styled = styled.div`
  max-width: 670px;
  margin: 40px auto 0;

  p {
    margin-bottom: 80px;
  }
  .pixels-container {
    display: none;
  }

  a {
    max-width: 250px;
    display: block;
    margin: 30px auto;
    img {
      max-width: 100%;
    }
  }
`;

const DisclaimerText = styled.p`
  width: 95%;
  max-width: 350px;
  font-family: ${openSans};
  font-size: 14px;
  line-height: 1.57;
  margin: 10px auto 0;
  text-align: left;
`;

function DownloadPage({ showDisclaimer }) {
  return (
    <Styled>
      <Prompt>
        You&apos;re good to go.
        <br />
        Download the app to start saving!
      </Prompt>
      <a className="download-ios-app" href="https://go.onelink.me/app/wqas">
        <img
          alt="Download the Noblr app for iOS from the Apple App Store"
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/apple-app-store-button.webp`}
        />
      </a>
      <a className="download-android-app" href="https://go.onelink.me/app/wqps">
        <img
          alt="Download the Noblr app for Android from the Google Play Store"
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/google-playstore-button.webp`}
        />
      </a>
      {showDisclaimer && (
        <DisclaimerText>
          {`*Per your agreement, failure to download the Noblr mobile app and
          enable permissions for you and any additional drivers on your policy
          will result in Noblr making an assumption of your variable monthly
          rate charge ("Offline Rate"). The Offline Rate is charged per
          applicable driver, per day.`}
        </DisclaimerText>
      )}
    </Styled>
  );
}

const mapStateToProps = ({ policy: { trialConverted, purchased } }) => ({
  showDisclaimer: purchased || trialConverted
});

DownloadPage.propTypes = {
  showDisclaimer: PropTypes.bool
};

DownloadPage.defaultProps = {
  showDisclaimer: false
};

export default connect(mapStateToProps)(DownloadPage);
