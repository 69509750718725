import React from 'react';
import styled from 'styled-components/macro';
import { colors, breakPoints } from 'styles/variables';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: sub;
  margin-right: 10px;

  @media only screen and (min-width: ${breakPoints.small}) {
    margin-left: 20px;
    padding: 0.3em;
    height 40px;
  }
  @media only screen and (max-width: ${breakPoints.medium}) {
    margin-left: -50px;
    padding: 0em 0.45em 0.2em 0.45em;
  }
`;

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
  'aria-required': props => props.required
})`
  /* Hide checkbox visually but remain accessible to screen readers. */
  /* Source: https://polished.js.org/docs/#hidevisually */
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: ${props =>
    props.checked
      ? `${colors.coreBlue700}`
      : `${props.backgroundColor || colors.white}`};
  border-radius: 1px;
  transition: all 150ms;
  @media screen and (max-width: ${breakPoints.medium}) {
    margin-top: 10px;
  }

  ${HiddenCheckbox}:hover + & {
    box-shadow: 0 0 0 2px ${colors.coreBlue700};
  }

  ${HiddenCheckbox}+ & {
    box-shadow: 0 0 0 2px ${colors.coreBlue700};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

function CheckBox({
  checked,
  onChange,
  checkboxField,
  backgroundColor,
  tabIndex
}) {
  return (
    <CheckboxContainer tabIndex={tabIndex}>
      <HiddenCheckbox
        checked={checked}
        {...checkboxField}
        onChange={onChange}
      />
      <StyledCheckbox checked={checked} backgroundColor={backgroundColor}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
}

export default CheckBox;
