import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { generateNumericSelectOptions } from 'utilities';
import { StyledContentWrapper } from 'styles';
import { GENDER_OPTIONS, MARITAL_STATUS_OPTIONS } from '../../constants';
import { STATE_OPTIONS } from '../../constants/stateCodes';

class ConfirmDriverPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'UPDATE_ADDITIONAL_DRIVER_INFO',
      fields: [
        {
          type: 'text',
          name: 'lastName',
          id: 'additional-driver-last-name-input',
          label: 'Last Name',
          required: true,
          initialValue: '',
          autoFocus: true
        },
        {
          type: 'maskedDate',
          id: 'driver-birth-date-input',
          name: 'dob',
          label: 'date of birth',
          required: true,
          placeholder: 'MM/DD/YYYY',
          options: {
            date: true,
            blocks: [2, 2],
            datePattern: ['m', 'd', 'Y'],
            delimiter: '/'
          }
        },
        {
          type: 'select',
          name: 'maritalStatus',
          id: 'driver-marital-status-select-input',
          selectLabel: 'Marital Status',
          selectLabelId: 'marital-status-label',
          initialValue: '',
          options: MARITAL_STATUS_OPTIONS,
          required: true
        },
        {
          type: 'select',
          id: 'driver-age-licensed-select-input',
          name: 'ageLicensed',
          selectLabel: 'first licensed at',
          selectLabelId: 'age-licensed-label',
          options: [{ value: '', label: '' }],
          initialValue: '',
          required: true
        },
        {
          type: 'select',
          name: 'genderMapping',
          id: 'driver-gender-select-input',
          selectLabel: 'Gender',
          selectLabelId: 'gender-select-label',
          options: GENDER_OPTIONS,
          required: true,
          initialValue: ''
        },
        {
          type: 'maskedText',
          name: 'dlNumber',
          id: 'driver-drivers-license-input',
          label: 'drivers license',
          placeholder: 'License Number',
          required: true
        },
        {
          type: 'searchableSelect',
          name: 'dlState',
          id: 'driver-license-state-input',
          label: 'License State',
          initialValue: '',
          required: true,
          options: STATE_OPTIONS,
          autoComplete: 'off'
        }
      ],
      successRoute: null,
      staticContent: {
        form: [
          {
            type: 'static',
            value:
              'By clicking "Continue", you are confirming that the information above is accurate and this driver\'s license is in good standing.',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const {
      editingDriver,
      primaryDriverState,
      firstName,
      askSafeDriving,
      askGoodStudentDiscount,
      edited,
      askActiveMilitaryPersonnel,
      usaaPersonInfoVerified
    } = this.props;

    let successRoute = '';

    if (askActiveMilitaryPersonnel) {
      successRoute = '/add-drivers/active-military-service';
    } else if (askSafeDriving) {
      successRoute = '/add-drivers/defensive-driving-course';
    } else if (askGoodStudentDiscount) {
      successRoute = '/add-drivers/good-student-discount';
    } else {
      successRoute = '/add-drivers/drivers-list';
    }

    const prompt = `Please verify the basics about ${firstName} or modify as needed.`;

    const displayConfirmedInfo = edited || usaaPersonInfoVerified;

    this.setState(prevState => ({
      fields: prevState.fields.map(fieldConfig => {
        const field = { ...fieldConfig };

        if (field.name === 'dlNumber') {
          return {
            ...field,
            initialValue: editingDriver[field.name]
          };
        }

        if (field.name === 'ageLicensed') {
          // Age should be available for
          const ageLicensedMaxRange = editingDriver.age;
          const initialValue =
            (displayConfirmedInfo && editingDriver.ageLicensed) || 16;

          // generate options from driver's age
          const options = generateNumericSelectOptions(
            15,
            ageLicensedMaxRange,
            'Licensed at',
            true
          );

          return {
            ...field,
            options,
            initialValue
          };
        }

        if (field.name === 'genderMapping' && primaryDriverState === 'CO') {
          field.options = [GENDER_OPTIONS[0], GENDER_OPTIONS[1]];
        }

        // set initial values from editing driver
        if (displayConfirmedInfo && editingDriver[field.name]) {
          return {
            ...field,
            initialValue: editingDriver[field.name]
          };
        }

        return field;
      }),
      successRoute,
      prompt
    }));
  }

  render() {
    const { driverId } = this.props;

    const {
      submitAction,
      fields,
      prompt,
      successRoute,
      staticContent: { form }
    } = this.state;

    if (!driverId) {
      return <Redirect to="/add-drivers/driver-list" replace />;
    }

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          staticContent={form}
          submitAsync
          makeApiCall
        />
        <InfoBox
          extraData={{
            header: 'Why Are We Asking This?'
          }}
        >
          <p>
            We use their driver&apos;s license to verify their information,
            speeding up your quote process.
          </p>
          <br />
          <p>
            You are confirming that their license is current and not expired,
            suspended, revoked, or otherwise inactive.
          </p>
        </InfoBox>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  drivers: {
    primaryDriver: { state },
    editingDriver,
    editingDriver: {
      firstName,
      askSafeDriving,
      askGoodStudentDiscount,
      driverId,
      edited
    }
  },
  app: { usaaPersonInfoVerified, askActiveMilitaryPersonnel }
}) => ({
  primaryDriverState: state,
  editingDriver,
  firstName,
  driverId,
  edited,
  askActiveMilitaryPersonnel,
  askSafeDriving,
  askGoodStudentDiscount,
  usaaPersonInfoVerified
});

ConfirmDriverPage.propTypes = {
  editingDriver: PropTypes.shape({
    ageLicensed: PropTypes.number,
    age: PropTypes.number
  }).isRequired,
  firstName: PropTypes.string.isRequired,
  edited: PropTypes.bool.isRequired,
  askSafeDriving: PropTypes.bool.isRequired,
  askGoodStudentDiscount: PropTypes.bool.isRequired,
  driverId: PropTypes.string.isRequired,
  primaryDriverState: PropTypes.string.isRequired,
  askActiveMilitaryPersonnel: PropTypes.bool.isRequired,
  usaaPersonInfoVerified: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(ConfirmDriverPage);
