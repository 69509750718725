import { getStateValue } from '..';

export const groupActiveVehicles = vehicles =>
  vehicles.reduce(
    (accumulator, vehicle) => {
      const key = vehicle.activeBit ? 'active' : 'inactive';

      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key] = [...accumulator[key], vehicle];

      return accumulator;
    },
    { active: [], inactive: [] }
  );

// Masks VIN with asterisks, exposing only the last four characters
export const maskVIN = vin => {
  if (!vin) {
    return '';
  }

  return new Array(vin.length - 4).fill('*').join('').concat(vin.slice(-4));
};

export const sliceEndingOfVIN = vin => {
  if (!vin) {
    return '';
  }

  return vin.slice(-4);
};

export const isUniqueLicense = licenseNumber => {
  const primaryDriver = getStateValue({}, 'drivers.primaryDriver');
  const additionalDrivers = getStateValue({}, 'drivers.items').filter(
    driver => driver && driver.activeBit
  );

  const editingDriver = getStateValue({}, 'drivers.editingDriver');

  const section = window.location.pathname.split('/')[1];

  if (
    !editingDriver?.firstName ||
    !primaryDriver?.dlNumber ||
    !additionalDrivers
  ) {
    return true;
  }

  if (section === 'start-quote') {
    return (
      additionalDrivers
        .map(driver => (driver.driverComplete ? driver.dlNumber : null))
        .filter(dLNumber => !!dLNumber && dLNumber === primaryDriver.dlNumber)
        .length === 0
    );
  }

  if (additionalDrivers && additionalDrivers.length < 1) {
    return true;
  }

  const duplicates = additionalDrivers.filter(
    driver =>
      (driver.driverComplete &&
        driver.dlNumber === licenseNumber &&
        driver.dlNumber !== editingDriver.dlNumber) ||
      (primaryDriver && primaryDriver.dlNumber === licenseNumber)
  );

  return duplicates && duplicates.length < 1;
};
