import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Container, Box, Grid } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import Form from 'components/Form/Form';
import { generateNumericSelectOptions } from 'utilities';
import { StyledContentWrapper, ModalHeader } from 'styles';
import Modal from '../../components/Modal';
import {
  makeSelectCurrentExcludedDriver,
  selectDriverById
} from '../../redux/selectors';
import { GENDER_OPTIONS } from '../../constants';
import { STATE_OPTIONS } from '../../constants/stateCodes';

const ButtonGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const ModalPrimaryButton = styled(Button)`
  margin: ${props => (props.margin ? props.margin : '0.125rem auto')};
  padding: 8px;
  height: auto;
`;

function ConfirmAdditionalDriver(props) {
  const {
    location: { pathname },
    history
  } = props;

  const dispatch = useDispatch();
  const prevLocation = useRef(pathname);
  const unListen = useRef();

  // memoize makeSelectCurrentExcludedDriver
  const selectCurrentExcludedDriver = useMemo(
    makeSelectCurrentExcludedDriver,
    []
  );

  const askActiveMilitaryPersonnel = useSelector(
    state => state.app.askActiveMilitaryPersonnel
  );

  // use selector to pull excluded driver from state
  const excludedDriver = useSelector(state =>
    selectCurrentExcludedDriver(state)
  );

  const { driverId } = useParams();

  const [additionalDriver] = useSelector(state =>
    selectDriverById(state, driverId)
  );

  const askSafeDriving = additionalDriver?.askSafeDriving;

  const backArrowListener = useCallback(() => {
    if (prevLocation !== pathname) {
      history.go(1);
      dispatch({ type: 'OPEN_MODAL', payload: 'driverExclusionModal' });
    }
  }, [history, pathname, dispatch]);

  useEffect(() => {
    if (prevLocation !== pathname) {
      history.push(pathname, '', '');

      unListen.current = history.listen((newLocation, action) => {
        if (action === 'POP') {
          backArrowListener();
        }
      });
    }

    return unListen.current;
  }, [backArrowListener, pathname, history]);

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleNavigation = () => {
    handleClose();
    dispatch({
      type: 'REDIRECT',
      payload: { url: '/driver-exclusion/start' }
    });
  };

  const fields = [
    {
      type: 'maskedDate',
      id: 'confirm-additional-driver-dob-input',
      name: 'dob',
      label: 'date of birth',
      placeholder: 'MM/DD/YYYY',
      initialValue: '',
      required: true,
      options: {
        date: true,
        blocks: [2, 2],
        datePattern: ['m', 'd', 'Y'],
        delimiter: '/'
      }
    },
    {
      type: 'select',
      name: 'maritalStatus',
      id: 'confirm-additional-driver-marital-status-input',
      selectLabel: 'Marital Status',
      selectLabelId: 'marital-status-label',
      initialValue: '',
      options: [
        { value: 'Single', label: 'Single' },
        { value: 'Married', label: 'Married' },
        { value: 'Divorced', label: 'Divorced' },
        { value: 'Separated', label: 'Separated' },
        { value: 'Widowed', label: 'Widowed' }
      ],
      required: true
    },
    {
      type: 'select',
      name: 'ageLicensed',
      id: 'confirm-additional-driver-age-licensed-input',
      selectLabel: 'first licensed at',
      selectLabelId: 'age-licensed-label',
      options: [{ value: '', label: '' }],
      initialValue: '',
      required: true
    },
    {
      type: 'select',
      name: 'genderMapping',
      id: 'confirm-additional-driver-gender-input',
      selectLabel: 'Gender',
      selectLabelId: 'gender-select-label',
      options: GENDER_OPTIONS,
      required: true,
      initialValue: ''
    },
    {
      type: 'maskedText',
      name: 'dlNumber',
      id: 'confirm-additional-driver-dlNumber-input',
      label: 'drivers license',
      placeholder: 'License Number',
      required: true
    },
    {
      type: 'searchableSelect',
      name: 'dlState',
      id: 'confirm-additional-driver-dlState-input',
      label: 'License State',
      initialValue: '',
      required: true,
      options: STATE_OPTIONS,
      autoComplete: 'off'
    }
  ];

  const [formFields, updateFormFields] = useState([...fields]);
  const [fieldsInitialized, setInitialized] = useState(false);
  const [promptText, setPromptText] = useState('');
  const [successRoute, setSuccessRoute] = useState('');

  useEffect(() => {
    if (excludedDriver) {
      const prompt = `Please verify the basics about ${excludedDriver?.firstName} ${excludedDriver?.lastName} or modify as needed`;

      setPromptText(prompt);
    }

    // TODO : Assuming drivers object comes from 'byId' property of excludedDriver reducer. Confirm the data flow once the drivers object added in the reducer.
    if (!fieldsInitialized) {
      const initializedFields = formFields.map(field => {
        const currentField = { ...field };

        if (currentField.name === 'ageLicensed') {
          // generate options from driver's age
          const options = generateNumericSelectOptions(
            15,
            excludedDriver.age,
            'Licensed at',
            true
          );

          return {
            ...currentField,
            options,
            initialValue: excludedDriver[currentField.name] || 16
          };
        }

        return {
          ...currentField,
          initialValue:
            additionalDriver[currentField.name] ||
            excludedDriver[currentField.name]
        };
      });

      setInitialized(true);
      updateFormFields(initializedFields);
    }
  }, [excludedDriver, formFields, fieldsInitialized, additionalDriver]);

  useEffect(() => {
    let successRedirect = `/driver-exclusion/${driverId}`;

    if (askActiveMilitaryPersonnel) {
      successRedirect += '/active-military-personnel';
    } else if (askSafeDriving) {
      successRedirect += '/confirm-defensive-driving-course';
    } else {
      successRedirect += '/confirmation';
    }
    setSuccessRoute(successRedirect);
  }, [driverId, askActiveMilitaryPersonnel, askSafeDriving, setSuccessRoute]);

  const submitAction = 'UPDATE_EXCLUDED_DRIVER_INFO';

  return (
    <Container>
      <StyledContentWrapper>
        <Form
          prompt={promptText}
          fields={formFields}
          successRoute={successRoute}
          submitAction={submitAction}
          submitAsync
          makeApiCall
          buttonMarginTop="20px"
        />
      </StyledContentWrapper>
      <Modal
        inline
        modalLabel="DriverExclusionModal"
        modalName="driverExclusionModal"
      >
        <Box>
          <Grid container>
            <ModalHeader>
              {`Clicking 'BACK' will take you to the section start page and reset the list of drivers you've gone through.`}
            </ModalHeader>
            <ButtonGrid container item>
              <Grid item xs sm>
                <ModalPrimaryButton large onClick={handleClose}>
                  CANCEL
                </ModalPrimaryButton>
              </Grid>
              <Grid item xs sm>
                <ModalPrimaryButton primary large onClick={handleNavigation}>
                  BACK
                </ModalPrimaryButton>
              </Grid>
            </ButtonGrid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default ConfirmAdditionalDriver;
