import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import ErrorPage from 'components/Pages/ErrorPage';
import {
  makeSelectActiveDrivers,
  makeSelectActiveVehicles
} from 'redux/selectors';
import { StyledContentWrapper } from 'styles';

class RideSharePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'SET_RIDESHARE_TNC_STATUS',
      fields: [
        {
          type: 'radio',
          name: 'isRideshare',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ],
          required: true,
          radioGroupAriaLabel: 'Vehicle Rideshare Confirmation'
        }
      ],
      successRoute: '/driving-behavior/start'
    };
  }

  render() {
    const { submitAction, fields, successRoute } = this.state;
    const {
      history,
      numberOfCars,
      numberOfAdditionalDrivers,
      requiresVehicleAssignment,
      usaaPersonInfoVerified,
      isUSAAMember,
      appError,
      askMilitaryServiceVehicles,
      primaryDriverState
    } = this.props;

    const isUSAAQuoteIntegrationFlow = usaaPersonInfoVerified && isUSAAMember;

    const isArizona = primaryDriverState === 'AZ';

    const userPrompt = isArizona
      ? 'Are any of these cars used for rideshare, car share, or delivery services?'
      : 'Are any of these cars used for rideshare like Lyft/Uber or delivery services like UberEats?';

    // if requiresVehicleAssignment (state is VA) and one car and 2+ drivers OR 2 cars and 3+ drivers OR equal number of cars and drivers or 1 driver and 2 cars
    const needToAssignCars =
      requiresVehicleAssignment &&
      numberOfCars > 1 &&
      numberOfAdditionalDrivers >= 0;

    // if need to assign cars, redirect there else use original route
    let updatedSuccessRoute = null;

    if (needToAssignCars) {
      updatedSuccessRoute = '/add-vehicles/car-assignment';
    } else if (askMilitaryServiceVehicles) {
      updatedSuccessRoute = '/add-vehicles/military-service';
    } else if (isUSAAQuoteIntegrationFlow) {
      // If member is in USAA Quote Integration Flow, bypass start page
      updatedSuccessRoute = '/driving-behavior/mileage';
    } else {
      updatedSuccessRoute = successRoute;
    }

    if (appError) {
      return <ErrorPage error={appError} history={history} />;
    }

    return (
      <StyledContentWrapper>
        <Form
          prompt={userPrompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={updatedSuccessRoute}
          submitAsync
        />
        {isArizona && (
          <InfoBox
            extraData={{
              header: 'Examples',
              text: 'This includes popular rideshare programs like Uber and Lyft, car sharing programs like Turo and GetAround, and delivery services such as UberEats and DoorDash.'
            }}
          />
        )}
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = () => {
  const selectActiveDrivers = makeSelectActiveDrivers();
  const selectActiveVehicles = makeSelectActiveVehicles();

  const realMapState = state => {
    const {
      drivers: {
        primaryDriver: { state: primaryDriverState }
      },
      app: {
        error: appError,
        requiresVehicleAssignment,
        usaaPersonInfoVerified,
        isUSAAMember,
        askMilitaryServiceVehicles
      }
    } = state;

    return {
      appError,
      primaryDriverState,
      requiresVehicleAssignment,
      numberOfCars: selectActiveVehicles(state).length,
      numberOfAdditionalDrivers: selectActiveDrivers(state).length,
      usaaPersonInfoVerified,
      isUSAAMember,
      askMilitaryServiceVehicles
    };
  };

  return realMapState;
};

RideSharePage.propTypes = {
  history: PropTypes.shape({}).isRequired,
  primaryDriverState: PropTypes.string.isRequired,
  appError: PropTypes.string,
  askMilitaryServiceVehicles: PropTypes.bool.isRequired,
  requiresVehicleAssignment: PropTypes.bool.isRequired,
  numberOfCars: PropTypes.number.isRequired,
  numberOfAdditionalDrivers: PropTypes.number.isRequired,
  usaaPersonInfoVerified: PropTypes.bool.isRequired,
  isUSAAMember: PropTypes.bool.isRequired
};

RideSharePage.defaultProps = {
  appError: ''
};

export default connect(mapStateToProps)(RideSharePage);
