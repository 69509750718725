import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { colors, brandonGrotesque } from 'styles/variables';

const StyledLinkButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 245px;
  height: 50px;
  max-height: 50px;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 18px;
  font-family: ${brandonGrotesque};
  border-radius: 27.5px;
  cursor: pointer;

  ${props =>
    props.$primary &&
    css`
      transition: background-color 0.2s ease;
      border: 2px solid ${colors.alphaBlue500};
      background-color: ${colors.alphaBlue500};
      color: ${colors.white};

      &:active {
        box-shadow: inset 0 0 40px ${colors.alphaBlue100};
      }

      @media only screen and (min-width: 501px) {
        margin: ${props.$noMargin ? '0 auto' : '50px auto 50px'};
      }
    `}

  ${props =>
    props.$secondary &&
    css`
      border: 2px solid ${colors.alphaBlue500};
      background-color: ${colors.white};
      color: ${colors.alphaBlue500};
    `}
`;

const StyledWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 501px) {
    margin: 0 auto;
  }
`;

function LinkButton({
  routeTo,
  onClick = null,
  children = null,
  primary = true,
  secondary = false,
  noMargin = false,
  className,
  id
}) {
  return (
    <StyledWrapper>
      <StyledLinkButton
        id={id}
        $noMargin={noMargin}
        $primary={primary}
        $secondary={secondary}
        className={className}
        to={routeTo}
        onClick={onClick}
      >
        {children}
      </StyledLinkButton>
    </StyledWrapper>
  );
}

export default LinkButton;
