import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import InfoToolTip from 'components/Static/InfoToolTip';
import { makeSelectPolicyInfoByRate } from 'redux/selectors';
import { brandonGrotesque, colors } from 'styles/variables';
import { separateThousandthsWithComma } from 'utilities';

const Container = styled.div`
  max-width: 300px;
  border-bottom: 1px solid ${colors.usaaLightGrey};
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
`;

const PriceHeader = styled.h2`
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.93px;
  font-size: 14px;
`;

const NewPrice = styled.span`
  font-size: 44px;
  font-family: ${brandonGrotesque};
`;

const PriceChange = styled.p`
  font-size: 18px;
`;

function UpdatedPrice() {
  const driversUpdatedRates = useSelector(
    state => state.excludedDrivers.updatedRates
  );

  const selectRates = useMemo(makeSelectPolicyInfoByRate, []);
  const ratesToDisplay = useSelector(state => selectRates(state));

  const { priceIncrease, priceDifference, totalQuoteRate } = ratesToDisplay;

  const vinRerate = useSelector(state => state.rate.vinRerate);

  const formattedTotalQuoteRate = separateThousandthsWithComma(totalQuoteRate);

  const [rateChangeReason, setRateChangeReason] = useState('');

  const formattedPriceDifference = priceDifference
    ? separateThousandthsWithComma(priceDifference)
    : null;

  useEffect(() => {
    const generateReRateMessage = (vinRerate, driversUpdatedRates) => {
      let result = '';

      if (vinRerate) {
        result = 'VIN(s)';

        if (vinRerate && driversUpdatedRates) {
          result += ' and driver(s)';
        }
      } else if (driversUpdatedRates) {
        result = 'driver(s)';
      }

      return result;
    };

    setRateChangeReason(generateReRateMessage(vinRerate, driversUpdatedRates));
  }, [driversUpdatedRates, vinRerate]);

  return (
    <Container>
      <PriceHeader>ESTIMATED MONTHLY TOTAL</PriceHeader>
      <NewPrice>
        {`$${formattedTotalQuoteRate}`}
        <InfoToolTip
          key="rerate-tooltip"
          header="Why did my rate change?"
          body={[
            'Additional information about your car and driver(s) helped us inform your price.'
          ]}
          overridePosition={({ top }) =>
            window.innerWidth < 500 ? { left: 0, top } : { left: 0, top: 80 }
          }
          mobilePosition="absolute"
        />
      </NewPrice>
      <PriceChange>
        {`Based on your ${rateChangeReason} your rate `}
        {priceDifference && priceDifference.length > 0
          ? ` ${
              priceIncrease ? 'in' : 'de'
            }creased by $${formattedPriceDifference}
        /month.`
          : 'did not change.'}
      </PriceChange>
    </Container>
  );
}
export default UpdatedPrice;
