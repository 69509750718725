export default {
  name: 'add-drivers',
  pages: [
    {
      name: 'drivers-list'
    },
    {
      name: 'name'
    },
    {
      name: 'confirm-driver',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: ['drivers.editingDriver.firstName'],
        dynamicString: 'CONFIRM $1',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'date-of-birth',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'verify-active-driver-license',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'driver-license',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'age-licensed',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'gender',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'marital-status',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'defensive-driving-course',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'active-military-service',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      name: 'good-student-discount',
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'ADD $1 $2',
        fallback: 'ADD DRIVER'
      }
    },
    {
      type: 'Static',
      name: 'safe-driving',
      overrideSectionTitle: {
        type: 'static',
        value: 'Safe Driving'
      },
      successRoute: { type: 'static', value: '/add-vehicles/start' }
    },
    {
      name: 'prior-insurance',
      overrideSectionTitle: {
        type: 'static',
        value: 'Prior Insurance'
      }
    },
    {
      name: 'confirm-excluded',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'dynamic',
        flags: [
          'drivers.editingDriver.firstName',
          'drivers.editingDriver.lastName'
        ],
        dynamicString: 'EXCLUDE $1 $2',
        fallback: 'ADD DRIVER'
      }
    }
  ]
};
