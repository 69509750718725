import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
import { colors } from 'styles/variables';

const StyledGrid = styled(Grid)`
  margin: ${props =>
    props.marginTop ? `${props.marginTop} auto 15px` : '3px 0 auto '};
  padding: 2px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};

  svg {
    height: 14px;
    vertical-align: middle;
    fill: ${props =>
      props.$type === 'error' ? colors.red500 : colors.usaaLightNavyBlue};
  }
`;

const StyledMessage = styled.p`
  margin-left: 5px;
  text-align: ${props => (props.type === 'error' ? 'left' : 'center')};
  color: ${props =>
    props.type === 'error' ? colors.red500 : colors.usaaLightNavyBlue};
  cursor: default;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.57px;
`;

function Message({ id, marginTop = null, type = 'error', message, maxWidth }) {
  return (
    <StyledGrid
      id={id}
      $marginTop={marginTop}
      type={type}
      aria-live="assertive"
      container
      alignItems="center"
      justifyContent={type === 'error' ? 'flex-start' : 'center'}
      wrap="nowrap"
      $maxWidth={maxWidth}
    >
      {type === 'error' ? (
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/red-error-icon.svg`} // red exclamation
          alt=""
          height="15px"
          width="15px"
          style={{ objectFit: 'contain' }}
        />
      ) : (
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/warning-icon.svg`}
          alt=""
        />
      )}
      <StyledMessage type={type}>{message}</StyledMessage>
    </StyledGrid>
  );
}

export default Message;
