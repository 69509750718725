export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

export const createAccount = (
  email,
  authKey,
  trialStatus,
  authType = 'noblr'
) => ({
  type: CREATE_ACCOUNT,
  payload: {
    email,
    authKey,
    trialStatus,
    authType
  }
});

export const CREATE_NOBLR_ACCOUNT = 'CREATE_NOBLR_ACCOUNT';
export const createNoblrAccount = ({ password, authType, trialStatus }) => ({
  type: CREATE_NOBLR_ACCOUNT,
  payload: {
    password,
    trialStatus,
    authType
  }
});

export const LOGIN_USER = 'LOGIN_USER';

export const loginUser = (email, authKey, authType, setSubmitting) => ({
  type: LOGIN_USER,
  payload: {
    email,
    authKey,
    authType,
    setSubmitting
  }
});
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const loginUserSuccess = data => ({
  type: LOGIN_USER_SUCCESS,
  payload: data
});

export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const loginUserFailure = error => ({
  type: LOGIN_USER_FAILURE,
  error
});

export const LOGIN_NOBLR_ACCOUNT = 'LOGIN_NOBLR_ACCOUNT';

export const loginNoblrAccount = ({
  email,
  password,
  formikActions: { setSubmitting }
}) => ({
  type: LOGIN_NOBLR_ACCOUNT,
  payload: {
    email,
    password,
    setSubmitting
  }
});

export const LOGIN_NOBLR_ACCOUNT_SUCCESS = 'LOGIN_NOBLR_ACCOUNT_SUCCESS';
export const loginNoblrAccountSuccess = () => ({
  type: LOGIN_NOBLR_ACCOUNT_SUCCESS
});

export const LOGIN_NOBLR_ACCOUNT_FAILURE = 'LOGIN_NOBLR_ACCOUNT_FAILURE';
export const loginNoblrAccountFailure = error => ({
  type: LOGIN_NOBLR_ACCOUNT_FAILURE,
  error
});

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const confirmEmail = ({ email, password, confirmPassword }) => ({
  type: CONFIRM_EMAIL,
  payload: {
    email,
    password,
    confirmPassword
  }
});

export const SEND_ONE_TIME_PASS = 'SEND_ONE_TIME_PASS';
export const SEND_ONE_TIME_PASS_SUCCESS = 'SEND_ONE_TIME_PASS_SUCCESS';
export const sendOneTimePass = email => ({
  type: SEND_ONE_TIME_PASS,
  payload: { email }
});

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const verifyOTP = otp => ({
  type: VERIFY_OTP,
  payload: { otp }
});

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const resetPassword = password => ({
  type: RESET_PASSWORD,
  payload: { password }
});

export const SHOW_OTP_ERROR = 'SHOW_OTP_ERROR';

export const VERIFY_EMAIL_LINK = 'VERIFY_EMAIL_LINK';
export const VERIFY_EMAIL_LINK_SUCCESS = 'VERIFY_EMAIL_LINK_SUCCESS';
export const VERIFY_EMAIL_LINK_FAILURE = 'VERIFY_EMAIL_LINK_FAILURE';

export const verifyEmailLink = encString => ({
  type: VERIFY_EMAIL_LINK,
  payload: encString
});

export const verifyEmailLinkSuccess = () => ({
  type: VERIFY_EMAIL_LINK_SUCCESS
});

export const verifyEmailLinkFailure = error => ({
  type: VERIFY_EMAIL_LINK_FAILURE,
  error
});

export const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';

export const updateAuthStatus = data => ({
  type: UPDATE_AUTH_STATUS,
  payload: data
});
