import React from 'react';
import { SummaryText } from 'components/Pages/Quote/styles';

function TransUnion() {
  return (
    <div>
      <SummaryText sm>
        We used information from your credit report to calculate your rate.
        TransUnion did not make any decision related to your rate and is unable
        to provide you with the specific reasons as to how or why your rate was
        impacted. You have a right to dispute any inaccurate information in your
        credit report. If you find mistakes on your credit report, contact
        TransUnion. It is a good idea to check your credit report to make sure
        the information it contains is accurate.
      </SummaryText>
      <br />
      <SummaryText sm>
        Under Federal law, you have the right to obtain a copy of your credit
        report without charge for 60 days after you receive this notice. To
        obtain your free report, contact:
      </SummaryText>
      <br />
      <SummaryText sm>
        TransUnion LLC, Consumer Dispute Center
        <br />
        P.O. Box 2000, Chester, PA 19016
        <br />
        1-877-322-8228
        <br />
        <a
          href="https://www.transunion.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.transunion.com
        </a>
        <br />
      </SummaryText>
    </div>
  );
}

export default TransUnion;
