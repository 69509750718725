import React from 'react';
import { SummaryText } from 'components/Pages/Quote/styles';

function Verisk() {
  return (
    <>
      <SummaryText sm>
        We use an independent data provider, Verisk, to verify your driving
        record and claims history. Verisk did not make any decision related to
        your rate and is unable to provide you with the specific reasons as to
        how or why your rate was impacted. You have the right to obtain a free
        copy of your report from Verisk if requested within 60 days. You also
        have the right to dispute, directly with Verisk, the accuracy of
        information they provided us. Contact:
      </SummaryText>
      <br />
      <SummaryText sm>
        <b>
          {`Verisk Consumer Inquiry Center `}
          <br />
          1000 Bishops Gate Blvd, ste 300
          <br />
          P.O. Box 5404
          <br />
          {`Mt. Laurel, NJ 08054-5404, or `}
          <br />
          1-800-709-8842
        </b>
      </SummaryText>
    </>
  );
}

export default Verisk;
