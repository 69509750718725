import React from 'react';
import styled from 'styled-components/macro';
import { breakPoints } from 'styles/variables';

const DisclaimerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start
  text-align: left;
  justify-content: flex-end;

  @media screen and (min-width: ${breakPoints.medium}) {
    margin-top: 10px;
  }
`;

function PaymentDisclaimer({ text }) {
  return (
    <DisclaimerWrapper>
      <p>
        {text ||
          'We will not sell your phone number or email address. We will only contact you about your insurance. Read our '}
        <a
          className="link"
          href="https://www.usaa.com/insurance/pay-as-you-drive/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        {' and '}
        <a
          className="link"
          href="https://www.usaa.com/insurance/pay-as-you-drive/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </p>
    </DisclaimerWrapper>
  );
}

export default PaymentDisclaimer;
