/* eslint-disable default-param-last */
import {
  UPDATE_VEHICLE,
  UPDATE_ASSOCIATED_VEHICLES,
  REMOVE_VEHICLE_SUCCESS,
  GET_ALL_VEHICLES,
  GET_ALL_VEHICLES_SUCCESS,
  COMPLETE_ADDED_VEHICLE_REGISTRATION,
  UPDATE_VEHICLE_SUCCESS,
  CLEAR_EDITING_VEHICLE,
  REMOVE_VEHICLE_REQUEST,
  UNDO_REMOVE_VEHICLE,
  UNDO_REMOVE_VEHICLE_SUCCESS,
  UNDO_REMOVE_VEHICLE_FAILURE,
  UPDATE_EDITING_VEHICLE,
  GET_YEAR_MAKE_MODEL_SUCCESS,
  UPDATE_YMM,
  SAVE_VIN,
  SAVE_VIN_SUCCESS,
  SAVE_VIN_FAILURE,
  ASSIGN_EXCESS_VEHICLES,
  ASSIGN_EXCESS_VEHICLES_SUCCESS,
  ASSIGN_EXCESS_VEHICLES_FAILURE,
  SET_PRIMARY_VEHICLE_ASSIGNMENT,
  SET_PRIMARY_VEHICLE_ASSIGNMENT_SUCCESS,
  SET_PRIMARY_VEHICLE_ASSIGNMENT_FAILURE,
  SET_PRIMARY_VEHICLE_ASSIGNMENT_LOCALLY
} from '../actions';

const INITIAL_VEHICLE_STATE = {
  editingVehicle: {
    vehicleId: null,
    vin: null,
    vinStem: null,
    prefill: false,
    activeBit: false,
    vehicleComplete: false,
    vehicleOwnership: null,
    make: '',
    model: '',
    modelYear: '',
    edited: false,
    newerThan2011: false
  },
  items: [],
  editedVehicles: [],
  removedVehicles: 0,
  savingVehicle: false,
  error: null
};

const vehiclesReducer = (state = INITIAL_VEHICLE_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_VEHICLE:
      return {
        ...state,
        editingVehicle: {
          ...state.editingVehicle,
          ...payload
        },
        error: null,
        savingVehicle: true
      };

    case SAVE_VIN:
      return {
        ...state,
        editingVehicle: {
          ...state.editingVehicle,
          edited: true,
          vehicleComplete: false
        },
        savingVehicle: true,
        error: {}
      };

    case SAVE_VIN_SUCCESS:
      return {
        ...state,
        error: null,
        editingVehicle: {
          ...state.editingVehicle,
          ...payload
        },
        lastEditedVehicle: payload,
        savingVehicle: false
      };

    case SAVE_VIN_FAILURE:
      return {
        ...state,
        editingVehicle: {
          ...state.editingVehicle,
          vin: null,
          edited: false,
          vehicleId: null
        },
        savingVehicle: false
      };

    case UPDATE_ASSOCIATED_VEHICLES:
      return {
        ...state,
        items: [...payload]
      };

    case GET_ALL_VEHICLES:
      return {
        ...state,
        error: null,
        editedVehicles: [],
        savingVehicle: false,
        editingVehicle: { ...INITIAL_VEHICLE_STATE.editingVehicle }
      };

    case GET_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        items: [...payload.vehicles]
      };

    case UPDATE_VEHICLE_SUCCESS:
      return {
        ...state,
        lastEditedVehicle: { ...payload },
        editingVehicle: { ...INITIAL_VEHICLE_STATE.editingVehicle }
      };

    case UPDATE_EDITING_VEHICLE:
      return {
        ...state,
        editingVehicle: {
          ...state.editingVehicle,
          ...payload,
          newerThan2011: payload.modelYear && payload.modelYear > 2011
        },
        savingVehicle: true,
        editedVehicles: state.editedVehicles.includes(payload.vehicleId)
          ? state.editedVehicles
          : [...state.editedVehicles, payload.vehicleId],
        lastEditedVehicle: { ...payload }
      };

    case CLEAR_EDITING_VEHICLE:
      return {
        ...state,
        editingVehicle: { ...INITIAL_VEHICLE_STATE.editingVehicle },
        savingVehicle: false,
        error: null
      };

    case REMOVE_VEHICLE_REQUEST:
      return {
        ...state,
        editingVehicle: payload,
        lastEditedVehicle: payload,
        savingVehicle: true
      };

    case COMPLETE_ADDED_VEHICLE_REGISTRATION:
      return {
        ...state,
        items: payload.items,
        savingVehicle: false,
        lastEditedVehicle: {
          ...state.editingVehicle,
          lastEdit: 'ADDED_VEHICLE'
        }
      };

    case REMOVE_VEHICLE_SUCCESS:
      return {
        ...state,
        editingVehicle: { ...INITIAL_VEHICLE_STATE.editingVehicle },
        items: state.items.map(item =>
          item.vehicleId !== payload.vehicleId
            ? item
            : {
                ...item,
                removed: true,
                vehicleComplete: false,
                activeBit: false
              }
        ),
        lastEditedVehicle: {
          ...state.lastEditedVehicle,
          lastEdit: 'REMOVE_VEHICLE'
        },
        removedVehicles: state.removedVehicles + 1,
        savingVehicle: false
      };

    case UNDO_REMOVE_VEHICLE:
      return {
        ...state,
        editingVehicle: { ...payload },
        lastEditedVehicle: {
          ...payload
        },
        savingVehicle: true,
        error: null
      };

    case UNDO_REMOVE_VEHICLE_SUCCESS:
      return {
        ...state,
        items: state.items.map(item =>
          item.vehicleId !== payload
            ? item
            : {
                ...item,
                removed: false,
                activeBit: true,
                vehicleComplete: true
              }
        ),
        lastEditedVehicle: {
          ...state.lastEditedVehicle,
          lastEdit: 'UNDO_REMOVE_VEHICLE'
        },
        removedVehicles: state.removedVehicles - 1,
        savingVehicle: false
      };

    case UNDO_REMOVE_VEHICLE_FAILURE:
      return {
        ...state,
        editingVehicle: { ...INITIAL_VEHICLE_STATE.editingVehicle },
        items: state.items.map(item => {
          if (payload.vehicle.vehicleId === item.vehicleId) {
            return {
              ...payload.vehicle,
              activeBit: false,
              removed: true,
              vehicleComplete: true
            };
          }

          return item;
        }),
        lastEditedVehicle: {
          ...state.lastEditedVehicle,
          lastEdit: 'UNDO_REMOVE_VEHICLE'
        },
        savingVehicle: false,
        error: payload.error
      };

    case GET_YEAR_MAKE_MODEL_SUCCESS:
      return { ...state, YMM: { ...payload } };

    case UPDATE_YMM:
      return {
        ...state,
        YMM: {
          ...state.YMM,
          ...payload
        }
      };

    case SET_PRIMARY_VEHICLE_ASSIGNMENT_LOCALLY:
      return {
        ...state,
        savingVehicle: true
      };

    case SET_PRIMARY_VEHICLE_ASSIGNMENT:
      return {
        ...state,
        savingVehicle: true,
        // clear any previous assignments
        items: state.items.map(car => ({
          ...car,
          primarilyDrivenBy: null,
          hasPrimaryDriver: false
        }))
      };

    case SET_PRIMARY_VEHICLE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        savingVehicle: false,
        items: state.items.map(item => {
          const matchedItem = payload.find(
            ({ vehicleId }) => vehicleId === item.vehicleId
          );

          return matchedItem
            ? {
                ...item,
                hasPrimaryDriver: true,
                primarilyDrivenBy: {
                  driverIds: matchedItem.driverIds
                }
              }
            : item;
        })
      };

    case SET_PRIMARY_VEHICLE_ASSIGNMENT_FAILURE:
      return {
        ...state,
        savingVehicle: false,
        error: action.error
      };

    case ASSIGN_EXCESS_VEHICLES:
      return {
        ...state,
        savingVehicle: true,
        error: null
      };

    case ASSIGN_EXCESS_VEHICLES_SUCCESS:
      return {
        ...state,
        savingVehicle: false,
        items: state.items.map(item => {
          const matchedItem = payload.find(
            ({ vehicleId }) => vehicleId === item.vehicleId
          );

          return matchedItem
            ? {
                ...item,
                hasPrimaryDriver: true,
                primarilyDrivenBy: {
                  driverIds: [matchedItem.driverId]
                }
              }
            : item;
        })
      };

    case ASSIGN_EXCESS_VEHICLES_FAILURE:
      return {
        ...state,
        savingVehicle: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default vehiclesReducer;
