import React from 'react';
import { StyledQuoteContainer, Prompt } from 'styles';

function FetchingQuote() {
  return (
    <StyledQuoteContainer fetching>
      <Prompt>Fetching Your Quote...</Prompt>
      <img
        src={`${process.env.REACT_APP_NOBLR_CDN}/animations/airplane-blue-final.gif`}
        alt=""
      />
      <p className="loading-text">Thanks! Hang on while we get your rate.</p>
    </StyledQuoteContainer>
  );
}

export default FetchingQuote;
