import React from 'react';
import Form from 'components/Form/Form';
import MilitaryDiscount from 'components/Static/MilitaryDiscount';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';

export default function ConfirmAMPVehicles() {
  const fields = [
    {
      type: 'radio',
      name: 'allVehiclesOwnedByAmp',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: true,
      radioGroupAriaLabel: 'Vehicle Active Military Discount'
    }
  ];

  const prompt =
    'Are all of these cars owned or leased by the qualifying active military personnel, their spouse, or their dependent?';

  const submitAction = 'SET_VEHICLES_AMP_STATUS';

  const successRoute = '/purchase/effective-date';

  return (
    <StyledContentWrapper>
      <Form
        prompt={prompt}
        fields={fields}
        submitAction={submitAction}
        successRoute={successRoute}
        submitAsync
        renderFieldRow={false}
        maxWidth="500px"
      />
      <InfoBox
        withMarginBottom
        extraData={{
          header: 'Why Are We Asking This?'
        }}
      >
        <MilitaryDiscount />
      </InfoBox>
    </StyledContentWrapper>
  );
}
