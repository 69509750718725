import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  colors,
  breakPoints,
  brandonGrotesque,
  openSans
} from 'styles/variables';

export const StyledQuoteOverviewText = styled.p`
  margin: 10px auto 10px;
  white-space: pre-wrap;
  text-align: center;
  font-family: ${openSans};
  font-size: 14px;
  color: ${colors.coreBlue700};

  @media only screen and (max-width: ${breakPoints.medium}) {
    margin: 20px 20px 10px;
  }

  @media only screen and (max-width: ${breakPoints.small}) {
    padding-right: 18px;
    padding-left: 18px;
  }

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 20px;
    `}

  ${props =>
    props.marginTop &&
    css`
      margin-top: 20px;
    `}

  ${props =>
    props.marginHorizontal &&
    css`
      margin-right: 30px;
      margin-left: 30px;
    `}
`;

export const StyledRateSection = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: ${colors.white};

  p {
    font-family: ${brandonGrotesque};

    &.overview-header {
      font-size: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${colors.coreBlue700};

      @media screen and (max-width: ${breakPoints.small}) {
        margin: 0 20px;
        font-size: 12px;
      }

      &.emphasis {
        font-size: 18px;
        font-weight: normal;
      }
    }

    &.overview-header-fixed {
      font-size: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${colors.coreBlue700};

      @media screen and (max-width: ${breakPoints.small}) {
        margin: 10px 20px;
        font-size: 12px;
      }

      &.emphasis {
        margin-top: 50px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    &.rate-amount {
      margin-bottom: 10px;
      font-size: 30px;
      letter-spacing: 1px;

      @media screen and (max-width: ${breakPoints.small}) {
        font-size: 24px;
      }

      &.emphasis {
        margin-bottom: 30px;
        color: ${colors.coreBlue700};
        font-size: 80px;
        letter-spacing: 1px;

        @media screen and (max-width: ${breakPoints.small}) {
          font-size: 50px;
        }
      }
    }
  }

  span {
    font-size: 16px;
    margin-left: 5px;

    @media screen and (max-width: ${breakPoints.small}) {
      font-size: 12px;
    }
  }
`;

export const AboutRateLink = styled(HashLink)`
  font-family: ${brandonGrotesque};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  text-decoration: underline;
  color: ${colors.coreBlue700};
`;

export const SectionHeader = styled.p`
  flex: 1;
  padding: 20px 0;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: ${brandonGrotesque};
`;

export const StyledContent = styled.div`
  margin: 0 30px 30px 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.usaaLightestGrey};
  }
`;

export const SummaryText = styled.p`
  margin-bottom: 7px;
  font-family: ${openSans};
  font-size: 16px;
  letter-spacing: 0.57;

  @media screen and (max-width: ${breakPoints.small}) {
    font-size: 14px;
  }

  ${props =>
    props.extraMargin &&
    css`
      margin-bottom: 30px;
    `}

  ${props =>
    props.marginTop &&
    css`
      margin-top: 30px;
    `}

  ${props =>
    props.paddingBottom &&
    css`
      padding-bottom: 30px;
    `}

  ${props =>
    props.sm &&
    css`
      font-size: 14px;
    `}

  ${props =>
    props.med &&
    css`
      font-size: 18px;
    `}

  ${props =>
    props.center &&
    css`
      text-align: center;
    `}

    ${props =>
    props.rightAlign &&
    css`
      text-align: right;
    `}

  ${props =>
    props.marginRight &&
    css`
      margin-right: 135px;
      @media screen and (max-width: ${breakPoints.small}) {
        margin-right: 50px;
      }
    `}
`;

export const StyledCoverageTitle = styled.p`
  margin-bottom: 12px;
  font-family: ${openSans};
  font-size: 18px;
  font-weight: 600;

  @media screen and (max-width: ${breakPoints.small}) {
    font-size: 16px;
  }
  ${props =>
    props.marginTop &&
    css`
      margin-top: 30px;
    `}

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 30px;
    `}
    ${props =>
    props.center &&
    css`
      flex: 1;
      display: flex;
      justify-content: center;
    `}
    ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};
`;

export const LinkWrapper = styled.div`
  display: flex;
`;

export const EditLink = styled(Link)`
  padding-top: 5px;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.5;
  text-decoration: underline;
  text-transform: uppercase;
  color: ${colors.alphaBlue500};
  font-family: ${brandonGrotesque};

  ${props =>
    props.$right &&
    css`
      justify-content: flex-end;
    `}
  ${props =>
    props.$marginbottom &&
    css`
      display: inline-block;
      margin-bottom: 30px;
    `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}
`;
