import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import MilitaryDiscount from 'components/Static/MilitaryDiscount';
import { StyledContentWrapper } from 'styles';

class MilitaryServiceDriverPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt:
        'Are you serving on active military duty, or are you the spouse or dependent of someone who is on active military duty?',
      submitAction: 'UPDATE_PRIMARY_DRIVER_REQUEST',
      fields: [
        {
          type: 'radio',
          name: 'activeMilitaryPersonnel',
          options: [
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' }
          ],
          required: true,
          radioGroupAriaLabel: 'Active Military Discount'
        }
      ],
      successRoute: '/add-drivers/drivers-list'
    };
  }

  componentDidMount() {
    const { section, editingDriver } = this.props;

    if (!editingDriver) {
      return;
    }

    if (section === 'add-drivers') {
      const prompt = `Is ${editingDriver.firstName} driver serving on active military duty, or are they the spouse or dependent of someone who is on active military duty?`;
      const submitAction = 'UPDATE_ADDITIONAL_DRIVER_INFO';

      this.setState({ prompt, submitAction });
    }
  }

  render() {
    const { section, editingDriver } = this.props;

    const { prompt, submitAction, fields, successRoute } = this.state;

    return section === 'add-drivers' && !editingDriver.firstName ? (
      <Redirect to="/add-drivers/drivers-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          renderFieldRow={false}
          maxWidth="500px"
        />
        <InfoBox
          withMarginBottom
          extraData={{
            header: 'Why Are We Asking This?'
          }}
        >
          <MilitaryDiscount />
        </InfoBox>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({ drivers: { editingDriver } }) => ({
  editingDriver
});

MilitaryServiceDriverPage.propTypes = {
  editingDriver: PropTypes.shape({
    firstName: PropTypes.string.isRequired
  }),
  section: PropTypes.string.isRequired
};

MilitaryServiceDriverPage.defaultProps = {
  editingDriver: {
    firstName: ''
  }
};

export default connect(mapStateToProps)(MilitaryServiceDriverPage);
