/* eslint-disable default-param-last */
import { UPDATE_FORM } from '../actions';

const INITIAL_FORM_STATE = {};

const formReducer = (state = INITIAL_FORM_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_FORM:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};

export default formReducer;
