import ConfirmDrivingCourses from 'pages/driverExclusion/ConfirmDrivingCourses';
import Start from '../pages/driverExclusion/Start';
import HouseholdMember from '../pages/driverExclusion/HouseholdMember';
import AnyCars from '../pages/driverExclusion/AnyCars';
import ActiveDriverLicense from '../pages/driverExclusion/ActiveDriverLicense';
import Confirmation from '../pages/driverExclusion/Confirmation';
import ConfirmDateOfBirth from '../pages/driverExclusion/ConfirmDateOfBirth';
import ConfirmCovered from '../pages/driverExclusion/ConfirmCovered';
import ConfirmAdditionalDriver from '../pages/driverExclusion/ConfirmAdditionalDriver';
import ConfirmDriverAMP from '../pages/driverExclusion/ConfirmDriverAMP';
import ConfirmAMPVehicles from '../pages/driverExclusion/ConfirmAMPVehicles';

const startQuote = '/start-quote/';
const startQuoteRoutes = [
  `${startQuote}lets-go`, // Media Alpha Marketing Landing Page
  `${startQuote}referral`,
  `${startQuote}name`,
  `${startQuote}date-of-birth`,
  `${startQuote}address`,
  `${startQuote}address-entry`,
  `${startQuote}usaa-membership`,
  `${startQuote}usaa-eligibility`,
  `${startQuote}email`,
  `${startQuote}driver-license`,
  `${startQuote}age-licensed`,
  `${startQuote}gender`,
  `${startQuote}marital-status`,
  `${startQuote}home-ownership`,
  `${startQuote}education`,
  `${startQuote}defensive-driving-course`,
  `${startQuote}driver-improvement-discount`,
  `${startQuote}active-military-service`,
  `${startQuote}confirm-primary-driver-info`,
  `${startQuote}good-student-discount`,
  `${startQuote}welcome` // USAA Quote Flow Integration Landing Page
];

const addDrivers = '/add-drivers/';
const addDriverRoutes = [
  `${addDrivers}start`,
  `${addDrivers}drivers-list`,
  `${addDrivers}name`,
  `${addDrivers}confirm-driver`,
  `${addDrivers}date-of-birth`,
  `${addDrivers}verify-active-driver-license`,
  `${addDrivers}confirm-excluded`,
  `${addDrivers}driver-license`,
  `${addDrivers}age-licensed`,
  `${addDrivers}gender`,
  `${addDrivers}marital-status`,
  `${addDrivers}defensive-driving-course`,
  `${addDrivers}active-military-service`,
  `${addDrivers}safe-driving`,
  `${addDrivers}prior-insurance`,
  `${addDrivers}good-student-discount`
];

const addVehicles = '/add-vehicles/';
const addVehicleRoutes = [
  `${addVehicles}start`,
  `${addVehicles}vehicles-list`,
  `${addVehicles}vehicle-ownership`,
  `${addVehicles}vehicle-ownership-length`,
  `${addVehicles}emergency-braking`,
  `${addVehicles}vin-etching`,
  `${addVehicles}commute`,
  `${addVehicles}anti-theft`,
  `${addVehicles}add-new-vehicle`,
  `${addVehicles}vin`,
  `${addVehicles}ride-share`,
  `${addVehicles}military-service`,
  `${addVehicles}car-assignment`,
  `${addVehicles}car-assignment-followup`,
  `${addVehicles}airbags`
];

const drivingBehavior = '/driving-behavior/';
const drivingBehaviorRoutes = [
  `${drivingBehavior}start`,
  `${drivingBehavior}mileage`,
  `${drivingBehavior}select-persona`
];

const quote = '/quote/';
const getQuoteRoutes = [
  `${quote}review-your-quote`,
  `${quote}retrieve-your-quote`,
  `${quote}not-found`
];

const purchase = '/purchase/';
const purchaseRoutes = [
  `${purchase}verify-drivers-license`,
  `${purchase}dl-verification-issue`,
  `${purchase}vin-confirm`,
  `${purchase}effective-date`,
  `${purchase}payment-schedule`,
  `${purchase}signature-start`,
  `${purchase}signature-start-nv`,
  `${purchase}sign-insurance-application`,
  `${purchase}sign-umbi-selection`,
  `${purchase}sign-uimbi-selection`,
  `${purchase}sign-um-selection`,
  `${purchase}sign-nv-medpay-form`,
  `${purchase}sign-nv-um-form`,
  `${purchase}sign-pip-notice`,
  `${purchase}notice-to-insured`,
  `${purchase}sign-membership-application`,
  `${purchase}sign-form`,
  `${purchase}umbrella-policy-acknowledgement`,
  `${purchase}umbrella-policy`,
  `${purchase}payment-info`,
  `${purchase}review-and-pay`,
  `${purchase}thank-you`
];

const account = '/account/';
const accountRoutes = [
  `${account}setup-account`,
  `${account}setup-trial`,
  `${account}sign-in`,
  `${account}confirm-email`,
  `${account}code-entry`,
  `${account}create-password`,
  `${account}download-app`,
  `${account}need-help`
];

// Driver Exclusion Dynamic Route Configurations
const driverExclusion = '/driver-exclusion/';
const driverExclusionRoutes = [
  { path: `${driverExclusion}start`, component: Start },
  {
    path: `${driverExclusion}:driverId/household-member`,
    component: HouseholdMember
  },
  { path: `${driverExclusion}:driverId/any-cars`, component: AnyCars },
  {
    path: `${driverExclusion}:driverId/active-driver-license`,
    component: ActiveDriverLicense
  },
  {
    path: `${driverExclusion}:driverId/confirm-covered`,
    component: ConfirmCovered
  },
  { path: `${driverExclusion}:driverId/confirmation`, component: Confirmation },
  {
    path: `${driverExclusion}:driverId/confirm-dob`,
    component: ConfirmDateOfBirth
  },
  {
    path: `${driverExclusion}:driverId/confirm-additional-driver`,
    component: ConfirmAdditionalDriver
  },
  {
    path: `${driverExclusion}:driverId/active-military-personnel`,
    component: ConfirmDriverAMP
  },
  {
    path: `${driverExclusion}active-military-service-vehicles`,
    component: ConfirmAMPVehicles
  },
  {
    path: `${driverExclusion}:driverId/confirm-defensive-driving-course`,
    component: ConfirmDrivingCourses
  },
  {
    path: `${driverExclusion}confirm-safe-driving`,
    component: ConfirmDrivingCourses
  }
];

const dnq = '/dnq/';
const dnqRoutes = [`${dnq}agency`, `${dnq}detailed`, `${dnq}usaa`];

const ROUTES = [
  ...startQuoteRoutes,
  ...addDriverRoutes,
  ...addVehicleRoutes,
  ...drivingBehaviorRoutes,
  ...getQuoteRoutes,
  ...purchaseRoutes,
  ...accountRoutes,
  ...driverExclusionRoutes,
  ...dnqRoutes
];

export default ROUTES;
