import React from 'react';
import { Grid, Container, Box } from '@material-ui/core';
import styled from 'styled-components/macro';
import InfoToolTip from 'components/Static/InfoToolTip';
import { brandonGrotesque, colors, breakPoints } from 'styles/variables';

const StyledContainer = styled(Container)`
  padding: 20px;
`;

const StyledGrid = styled(Grid)`
  text-align: left;
  img {
    height: 90px;
    width: 66px;
    @media only screen and (min-width: ${breakPoints.small}) {
      height: 120px;
      width: 100px;
    }
  }
`;

const CustomGrid = styled(Grid)`
  position: relative;

  & span {
    position: absolute;
    bottom: 14%;
    left: 36%;
  }
  & span#usaa-info-box {
    bottom: 35%;
    left: 29%;
  }
  @media all and (min-width: 501px) and (max-width: 600px) {
    & span {
      left: 38%;
    }
    & span#usaa-info-box {
      left: 31%;
    }
  }
  @media all and (min-width: 400px) and (max-width: 500px) {
    & span {
      left: 40%;
      bottom: 9%;
    }
    & span#usaa-info-box {
      left: 33%;
      bottom: 33%;
    }
  }
  @media all and (max-width: 400px) {
    & span {
      bottom: 7%;
      left: 45%;
    }
    & span#usaa-info-box {
      bottom: 32%;
      left: 37%;
    }
  }
`;

const StyledText = styled.p`
  width: 200px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 20px;
  color: ${colors.coreBlue500};
  font-family: ${brandonGrotesque};
  text-align: left;
  font-size: 16px;

  @media only screen and (min-width: ${breakPoints.small}) {
    height: auto;
    width: 250px;
    font-size: 20px;
  }
`;

function ContentBox(props) {
  const { img, description, infoBoxId, infoBoxBody } = props;

  return (
    <StyledContainer maxWidth="xs">
      <StyledGrid
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
      >
        <Grid container item xs={12}>
          <Grid item xs sm={4}>
            <Box sx={{ pl: 2 }}>
              <Grid>
                <img src={img} alt="" />
              </Grid>
            </Box>
          </Grid>
          <CustomGrid item xs={7} sm={8}>
            <Box sx={{ pl: 2 }}>
              <StyledText>{description}</StyledText>
            </Box>
            <InfoToolTip id={infoBoxId} body={infoBoxBody} />
          </CustomGrid>
        </Grid>
      </StyledGrid>
    </StyledContainer>
  );
}

export default ContentBox;
