import React, { Component } from 'react';
import SignPage from 'components/Pages/SignPage';
import { StyledContentWrapper } from 'styles';

class UmbiSelectionFormPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'SUBMIT_PREBIND_SIGNATURE',
      successRoute: '/purchase/sign-uimbi-selection',
      fields: [
        {
          type: 'sign',
          name: 'umbiSelection',
          id: 'umbi-selection-input',
          label: 'First and Last Name',
          required: true,
          validation: 'fullName',
          autoComplete: 'name',
          fullWidth: true
        }
      ],
      staticContent: {
        form: [
          {
            type: 'static',
            location: 'form',
            value:
              'By continuing, I acknowledge that I have read and agree to the Uninsured Motorist Bodily Injury Coverage Selection Form and agree that entering my name above serves as and replaces the need for my physical signature and signifies my authorization going forward.'
          }
        ]
      }
    };
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    return (
      <StyledContentWrapper>
        <SignPage
          updateRateFields
          pdfFileName="umbiSelection"
          prompt={prompt}
          formStaticContent={form}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          documentName="Uninsured Motorist Bodily Injury Coverage Selection Form"
        />
      </StyledContentWrapper>
    );
  }
}

export default UmbiSelectionFormPage;
