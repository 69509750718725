import styled, { css } from 'styled-components/macro';
import { breakPoints, colors, openSans } from './variables';

export const SubHeader = styled.p`
  font-size: 18px;
  font-family: ${openSans};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  white-space: pre-line;
  line-height: 1.78;
  letter-spacing: 0.5px;
  color: ${colors.coreBlue700};

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 10px;
    `}

  ${props =>
    props.usaaStyles &&
    css`
      margin-top: 50px;
      font-weight: 600;
    `}
`;

export const Prompt = styled.h1`
  font-size: ${props =>
    props.mobilePromptFontSize ? props.mobilePromptFontSize : '30px'};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  text-align: center;
  white-space: pre-line;
  color: ${colors.coreBlue700};
  margin: 40px auto 40px;
  padding: 0 10px;

  @media only screen and (min-width: ${breakPoints.medium}) {
    padding: 0;
    margin: 0 auto 40px;
    font-size: ${props =>
      props.promptFontSize ? props.promptFontSize : '44px'};
    line-height: 1.3;
    letter-spacing: 0.9px;
    text-align: ${props =>
      props.promptTextAlign ? props.promptTextAlign : 'center'};
  }
`;

export const ErrorPagePrompt = styled.h1`
  margin: 40px auto;
  padding: 0 10px;
  font-size: ${props =>
    props.mobilePromptFontSize ? props.mobilePromptFontSize : '30px'};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  text-align: center;
  white-space: pre-line;
  color: ${colors.coreBlue700};

  @media screen and (min-width: ${breakPoints.large}) {
    margin: 50px auto;
    padding: 0;
    max-width: 700px;
    font-size: ${props =>
      props.promptFontSize ? props.promptFontSize : '44px'};
    line-height: 1.3;
    letter-spacing: 0.9px;
    text-align: ${props =>
      props.promptTextAlign ? props.promptTextAlign : 'center'};
  }
`;

export const CardHeader = styled.h2`
  margin: 0 auto 10px;
  max-width: 270px;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: ${colors.coreBlue700};

  @media screen and (max-width: ${breakPoints.medium}) {
    padding: 5px;
  }
  ${props =>
    props.persona &&
    css`
      padding: 20px;
      margin: 0;
      max-width: none;
      font-size: 30px;
      font-weight: 400;
      background-color: ${colors.usaaLightestGrey};
      border-radius: 10px 10px 0 0;
    `}
`;

export const PurchaseSectionHeader = styled.h3`
  margin: 0;
  text-align: left;
  font-size: 18px;
  color: ${colors.coreBlue700};
  font-weight: 400;
  text-transform: none;
  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const ModalHeader = styled.h2`
  margin: 20px 0;
  font-size: 20px;
  color: ${colors.coreBlue700};
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: left;
`;

export const QuoteSubheader = styled.h1`
  margin: 20px auto;
  font-size: 30px;

  @media only screen and (min-width: ${breakPoints.medium}) {
    margin: 30px auto 50px;
    line-height: 1;
    line-height: 1.33;
  }
`;
