import React from 'react';
import styled, { css } from 'styled-components/macro';
import StaticContent from 'components/Static';
import { openSans, colors } from 'styles/variables';

function StaticComponent(props) {
  const { staticTextAlign, content = null, isLandingPage = false } = props;
  const StaticComponent = StaticContent[content.type];

  return (
    content && (
      <StyledContainer isLandingPage={isLandingPage} type={content.type}>
        {StaticComponent ? (
          <StyledComponentContainer key={content}>
            <StaticComponent extraData={content.extraData} />
          </StyledComponentContainer>
        ) : (
          <StaticText textAlign={staticTextAlign} className="static-text">
            {content.value}
          </StaticText>
        )}
      </StyledContainer>
    )
  );
}

const StyledContainer = styled.div`
  p {
    color: ${colors.coreBlue700};
    font-size: 14px;
    font-family: ${openSans};
  }
  a {
    text-decoration: underline;
    color: ${colors.coreBlue700};
  }

  ${props =>
    props.type === 'StandaloneLink' &&
    css`
      display: inline-block;
      margin: 20px auto;
    `}
  ${props =>
    props.isLandingPage &&
    css`
      max-width: 350px;
      margin: 30px auto 0;
      text-align: left;
    `}
`;

const StaticText = styled.p`
  font-family: ${openSans};
  font-size: 14px;
  text-align: ${props => (props.textAlign ? props.textAlign : 'inherit')};
`;

const StyledComponentContainer = styled.div`
  &:nth-child(even) {
    margin-top: 30px;
  }
`;

export default StaticComponent;
