import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import { selectPackageToBuild } from 'redux/selectors';
import { openSans } from 'styles';

const PackageName = styled.h5`
  margin-top: 20px;
  font-size: 16px;
  font-family: ${openSans};
  text-transform: uppercase;
`;

const CoverageText = styled.p`
  margin: 5px 0;

  ${props =>
    props.$bold &&
    css`
      font-weight: 600;
    `};

  ${props =>
    props.$alignRight &&
    css`
      text-align: right;
    `};
`;

function CoverageSummary({ rate, selectedCoveragePackage }) {
  const packageName = `Noblr ${rate.currentPackage} Package`;

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        spacing={2}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs sm>
          <PackageName>{packageName}</PackageName>
        </Grid>

        {selectedCoveragePackage.policyLevelSections.map(({ name, items }) =>
          items.map(({ label, value }) => (
            <Grid
              container
              item
              key={`${name}-${label}`}
              wrap="nowrap"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              xs
              sm
            >
              <Grid item>
                <CoverageText>{label}</CoverageText>
              </Grid>
              <Grid item xs={9} sm={9}>
                <CoverageText $bold $alignRight>
                  {label === 'Combined First Party Benefits' &&
                  value === 'Combined First Party Benefits'
                    ? '$177.5K'
                    : value}
                </CoverageText>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
      <Grid container spacing={1}>
        {selectedCoveragePackage.vehicleLevelSections.map(vehicle => (
          <Fragment key={vehicle.vehicleId}>
            <Grid item xs={12}>
              <PackageName>{vehicle.name}</PackageName>
            </Grid>
            {vehicle.sections.map(({ name, items }) =>
              items.map(({ label, value }) => (
                <Fragment key={`${name}-${label}`}>
                  <Grid item xs={7}>
                    <CoverageText>{label}</CoverageText>
                  </Grid>
                  <Grid item xs>
                    <CoverageText $bold>{value}</CoverageText>
                  </Grid>
                </Fragment>
              ))
            )}
          </Fragment>
        ))}
      </Grid>
    </>
  );
}

const mapStateToProps = state => {
  const { rate, vehicles, coverage } = state;

  return {
    selectedCoveragePackage: selectPackageToBuild(state),
    coverage,
    vehicles,
    rate
  };
};

export default connect(mapStateToProps)(CoverageSummary);
