export default {
  name: 'driving-behavior',
  pages: [
    {
      type: 'Static',
      name: 'start',
      svg: 'interstitial-driving.webp',
      hidePageHeader: false,
      svgAltText: '',
      prompt: {
        type: 'static',
        value: `Let's talk about your driving.`
      },
      body: {
        type: 'static',
        value: [
          `We'll use what you've told us so far to calculate your base rate - the fixed portion of your premium that stays the same each month during your policy term.`,
          `The variable portion of your rate is based on how and how much you drive each month. Simply answer a couple of questions about your driving habits to help us calculate your total rate estimate.`
        ]
      },
      successRoute: { type: 'static', value: '/driving-behavior/mileage' },
      componentDidMountActions: ['ORDER_MVR']
    },
    {
      name: 'mileage',
      overrideSectionTitle: {
        type: 'static',
        value: 'Estimate Your Mileage'
      }
    },
    {
      name: 'select-persona'
    }
  ]
};
