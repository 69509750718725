import Noblr from '@noblr-lab/react-app-services';
import { call, put, delay } from 'redux-saga/effects';

// recaptchaSubroutine receives a token in the payload that is sent from Google reCaptcha API after user successfully passes the reCaptcha challenge. After token is generated, the FE has 2 minutes to verify that the token is valid by passing the token to the backend where a separate request to Google reCaptcha API validates the token and verifies the token isn't expired.
export function* recaptchaSubroutine({ payload }) {
  // if payload is empty, user is idle
  if (!payload || !payload.length) {
    // dispatch failure action
    yield put({
      type: 'VERIFY_RECAPTCHA_TOKEN_FAILURE',
      payload: {
        success: false,
        reCaptchaVerified: false
      }
    });

    // return before next failure action is dispatched

    return;
  }
  try {
    // Send token in request body to backend and wait for response
    const {
      success,
      errorCodes = [],
      hostname = '',
      ...rest
    } = yield call(Noblr.reCaptcha.verifyReCaptchaToken, payload);

    // Change reCaptchaVerified from 'pending'
    const reCaptchaVerified =
      !!success && hostname === window.location.hostname;

    if (success && !errorCodes.length) {
      yield put({
        type: 'VERIFY_RECAPTCHA_TOKEN_SUCCESS',
        payload: { success, reCaptchaVerified, ...rest }
      });
    } else {
      // Token was not valid so we need to check the error codes and determine whether the token was invalid because it was a bot or duplicate, extended request
      if (
        errorCodes.length &&
        (errorCodes.includes('bad-request') ||
          errorCodes.includes('timeout-or-duplicate'))
      ) {
        // If the error was caused by bot, request timeout or duplicate token, redirect user to soft stop page
        yield put({
          type: 'REDIRECT',
          payload: {
            url: '/call-to-complete'
          }
        });
      }
      // Dispatch failure action to reCaptcha reducer
      yield put({
        type: 'VERIFY_RECAPTCHA_TOKEN_FAILURE',
        payload: {
          success,
          reCaptchaVerified,
          errorCodes,
          ...rest
        }
      });
    }
  } catch (error) {
    yield put({
      type: 'VERIFY_RECAPTCHA_TOKEN_FAILURE',
      payload: {
        ...error
      }
    });

    yield put({
      type: 'SET_ERROR',
      error
    });
  } finally {
    // manually expire ReCaptcha after 2 minutes
    yield delay(120000);
    yield put({
      type: 'RESET_RECAPTCHA_STATUS',
      payload: {
        expired: true,
        reCaptchaVerified: false,
        challenge_ts: null,
        success: null,
        host: null,
        token: null
      }
    });
  }
}

export function* determineQuoteAutomationStatus({ payload }) {
  try {
    const { testQuote } = yield call(
      Noblr.reCaptcha.getQuoteAutomationStatus,
      payload
    );

    yield put({
      type: 'GET_QUOTE_AUTOMATION_STATUS_SUCCESS',
      payload: testQuote
    });
  } catch (error) {
    yield put({
      type: 'GET_QUOTE_AUTOMATION_STATUS_FAILURE',
      payload: error
    });
  }
}
