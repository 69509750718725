import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import Pages from 'pages';
import { retryImport } from 'utilities';

const StaticPage = lazy(() =>
  retryImport(() => import('components/Pages/StaticPage'))
);

// use React.lazy to enable code-splitting
const ErrorPage = lazy(() =>
  retryImport(() => import('components/Pages/ErrorPage'))
);

const QuotePage = lazy(() =>
  retryImport(() => import('components/Pages/QuotePage'))
);

function BodyContent(props) {
  const {
    data,
    history,
    location,
    match,
    postContentData,
    prompt,
    error,
    successRoute,
    body
  } = props;

  const { type } = data;

  switch (type) {
    case 'Static':
      return (
        <StaticPage
          data={data}
          body={body}
          prompt={prompt}
          history={history}
          postContentData={postContentData}
          successRoute={successRoute}
        />
      );

    case 'Quote':
      return (
        <QuotePage
          data={data}
          history={history}
          location={location}
          match={match}
        />
      );

    case 'Error':
      return <ErrorPage {...props} error={error} />;

    default:
      return <ErrorPage error={{ status: '404' }} />;
  }
}

// TODO: remove isMounted from state, it does nothing useful
class PageBody extends Component {
  constructor(props) {
    super(props);

    const { location } = props;
    const section = location.pathname.split('/')[1];

    this.state = {
      isMounted: false,
      section
    };
  }

  componentDidMount() {
    this.setState({
      isMounted: true
    });
  }

  render() {
    const {
      data,
      location: { pathname }
    } = this.props;

    const { section, isMounted } = this.state;

    const isQuotePage = pathname === '/quote/review-your-quote';
    const maxWidth = isQuotePage ? false : 'md';
    const disableGutters = isQuotePage;

    if (Pages[data.name]) {
      const Component = Pages[data.name];

      return <Component {...this.props} section={section} />;
    }

    return (
      isMounted && (
        <Container maxWidth={maxWidth} disableGutters={disableGutters}>
          <BodyContent {...this.props} />
        </Container>
      )
    );
  }
}

const mapStateToProps = ({ app: { error } }) => ({
  error
});

export default connect(mapStateToProps)(PageBody);
