export const VERIFY_RECAPTCHA_TOKEN = 'VERIFY_RECAPTCHA_TOKEN';
export const VERIFY_RECAPTCHA_TOKEN_SUCCESS = 'VERIFY_RECAPTCHA_TOKEN_SUCCESS';
export const VERIFY_RECAPTCHA_TOKEN_FAILURE = 'VERIFY_RECAPTCHA_TOKEN_FAILURE';

export const GET_QUOTE_AUTOMATION_STATUS = 'GET_QUOTE_AUTOMATION_STATUS';
export const GET_QUOTE_AUTOMATION_STATUS_SUCCESS =
  'GET_QUOTE_AUTOMATION_STATUS_SUCCESS';
export const GET_QUOTE_AUTOMATION_STATUS_FAILURE =
  'GET_QUOTE_AUTOMATION_STATUS_FAILURE';

export const RESET_RECAPTCHA_STATUS = 'RESET_RECAPTCHA_STATUS';

export const verifyReCaptchaToken = token => ({
  type: VERIFY_RECAPTCHA_TOKEN,
  payload: token
});

export const verifyReCaptchaTokenSuccess = data => ({
  type: VERIFY_RECAPTCHA_TOKEN_SUCCESS,
  payload: data
});

export const verifyReCaptchaTokenFailure = error => ({
  type: VERIFY_RECAPTCHA_TOKEN_FAILURE,
  payload: error
});

export const getQuoteAutomationStatus = quoteId => ({
  type: GET_QUOTE_AUTOMATION_STATUS,
  payload: quoteId
});

export const getQuoteAutomationStatusSuccess = data => ({
  type: GET_QUOTE_AUTOMATION_STATUS_SUCCESS,
  payload: data
});

export const getQuoteAutomationStatusFailure = error => ({
  type: GET_QUOTE_AUTOMATION_STATUS_FAILURE,
  payload: error
});

export const resetReCaptcha = data => ({
  type: RESET_RECAPTCHA_STATUS,
  payload: data
});
