import * as FullStory from '@fullstory/browser';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Form from 'components/Form/Form';
import FetchingQuote from 'components/FetchingQuote';
import {
  trackScreenView,
  updateApp,
  resetExcludedDrivers
} from 'redux/actions';
import { getUrlParameter, stripDomainFromEmail } from 'utilities';
import { Prompt, StyledContentWrapper } from 'styles';

const StyledText = styled.p`
  span {
    text-transform: capitalize;
  }
`;

class QuoteRetrievalPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nameToDisplay: null,
      prompt: 'Enter a few pieces of info to continue where you left off.',
      fields: [
        {
          type: 'text',
          name: 'firstName',
          id: 'quote-first-name-input',
          label: 'First Name',
          initialValue: '',
          value: '',
          hidden: false,
          required: true
        },
        {
          type: 'text',
          name: 'enc',
          hidden: true,
          required: false
        },
        {
          type: 'text',
          name: 'lastName',
          id: 'quote-last-name-input',
          label: 'Last Name',
          initialValue: '',
          value: '',
          hidden: false,
          required: true
        },
        {
          type: 'email',
          name: 'email', // Or 'primaryEmail' if enc string is detected
          id: 'quote-email-input',
          label: 'Email address',
          initialValue: '',
          value: '',
          hidden: false,
          required: true
        },
        {
          type: 'cleave',
          // using dateOfBirth here since dob is always required by schema but this can be optional
          name: 'dateOfBirth', // or `primaryDOB` if enc string detected
          label: 'Date of birth',
          id: 'quote-date-of-birth-input',
          placeholder: 'MM/DD/YYYY',
          initialValue: '',
          value: '',
          hidden: false,
          required: true,
          formattingOptions: {
            date: true,
            datePattern: ['m', 'd', 'Y'],
            formatDate: true
          }
        },
        {
          type: 'text',
          name: 'zipCode',
          id: 'quote-zip-code-input',
          label: 'Zip code',
          initialValue: '',
          value: '',
          hidden: false,
          required: true
        }
      ],
      submitAction: 'RETRIEVE_QUOTE',
      staticContent: {
        form: [
          {
            type: 'LiabilityAgreement',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { fields } = this.state;
    const {
      isQuoteRetrieval,
      location: { search },
      primaryDriver,
      trackScreenView,
      updateApp
    } = this.props;

    if (primaryDriver && primaryDriver.personId && primaryDriver.email) {
      const primaryDriverEmailUsername = stripDomainFromEmail(
        primaryDriver.email
      );

      const primaryDriverId = primaryDriver.personId;

      const garagingState = primaryDriver.state || '';

      FullStory.identify(primaryDriverId, {
        displayName: primaryDriverEmailUsername,
        garagingState_str: garagingState
      });
    }

    if (!isQuoteRetrieval) {
      updateApp({ isQuoteRetrieval: true });
    }

    const nameInUrl = getUrlParameter(search, 'firstName');
    const encString = getUrlParameter(search, 'enc');

    const encStringDetected = encString.length > 0;

    if (encStringDetected) {
      trackScreenView('QUOTE_RETRIEVE_LANDING_PAGE', { encString });
    } else {
      trackScreenView('QUOTE_RETRIEVE_LANDING_PAGE');
    }

    const emailFieldName = encStringDetected ? 'primaryEmail' : 'email';
    const dobFieldName = encStringDetected ? 'primaryDOB' : 'dateOfBirth';
    let updatedFields = [...fields];
    let nameToDisplay = '';

    if (primaryDriver && primaryDriver.firstName && primaryDriver.personId) {
      nameToDisplay = nameInUrl || (primaryDriver && primaryDriver.firstName);

      const fieldInitialValues = {
        nameToDisplay,
        firstName: nameToDisplay,
        lastName: primaryDriver && primaryDriver.lastName,
        enc: encStringDetected ? encString : null,
        [emailFieldName]: primaryDriver && primaryDriver.email,
        [dobFieldName]: encStringDetected ? primaryDriver.dob : '',
        zipCode: primaryDriver && primaryDriver.zipCode
      };

      updatedFields = fields.map(field => {
        const currentField = {
          ...field,
          required: field.required || !encStringDetected
        };

        if (field.type === 'email') {
          return {
            ...currentField,
            name: emailFieldName,
            hidden: !!encStringDetected,
            initialValue: fieldInitialValues[emailFieldName],
            value: fieldInitialValues[emailFieldName]
          };
        }

        if (field.type === 'cleave') {
          return {
            ...currentField,
            name: dobFieldName,
            hidden: !!encStringDetected,
            required: !!encStringDetected,
            initialValue: fieldInitialValues[dobFieldName]
          };
        }

        if (field.name === 'firstName') {
          return {
            ...currentField,
            hidden: !!encStringDetected,
            initialValue: fieldInitialValues[field.name],
            value: fieldInitialValues[field.name]
          };
        }

        if (field.name === 'lastName') {
          return {
            ...currentField,
            initialValue: fieldInitialValues[field.name]
          };
        }

        return {
          ...currentField,
          initialValue: fieldInitialValues[field.name]
        };
      });
    }
    this.setState({
      fields: updatedFields,
      nameToDisplay,
      mounted: true
    });
  }

  render() {
    const {
      fields,
      submitAction,
      staticContent: { form },
      nameToDisplay,
      prompt,
      mounted
    } = this.state;

    const { retrievingQuote } = this.props;

    if (!mounted) {
      return null;
    }

    return retrievingQuote ? (
      <FetchingQuote />
    ) : (
      <StyledContentWrapper>
        <Prompt>
          {nameToDisplay ? (
            <StyledText>
              {'Welcome back '}
              <span>{nameToDisplay}</span>! Let&apos;s get you to your quote.
            </StyledText>
          ) : (
            prompt
          )}
        </Prompt>
        <Form
          submitAsync
          makeApiCall
          fields={fields}
          buttonPosition="static"
          staticContent={form}
          submitAction={submitAction}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { isQuoteRetrieval, loading: appLoading, asyncRequest },
  drivers: { primaryDriver },
  rate: { loading },
  excludedDrivers: { allDriversExcluded }
}) => ({
  isQuoteRetrieval,
  primaryDriver,
  retrievingQuote: loading || appLoading || asyncRequest === 'pending',
  allDriversExcluded
});

const mapDispatchToProps = dispatch => ({
  trackScreenView: (screenIdentifier, config) =>
    dispatch(trackScreenView(screenIdentifier, config)),
  updateApp: values => dispatch(updateApp(values)),
  resetExcludedDrivers: () => dispatch(resetExcludedDrivers())
});

QuoteRetrievalPage.propTypes = {
  isQuoteRetrieval: PropTypes.bool.isRequired,
  retrievingQuote: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  primaryDriver: PropTypes.shape({
    personId: PropTypes.string,
    email: PropTypes.string,
    state: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.string,
    zipCode: PropTypes.string
  }).isRequired,
  trackScreenView: PropTypes.func,
  updateApp: PropTypes.func
};

QuoteRetrievalPage.defaultProps = {
  trackScreenView: { trackScreenView },
  updateApp: { updateApp }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteRetrievalPage);
