import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Button from 'components/Buttons/Button';
import { Prompt, StyledContentWrapper } from 'styles';

const PageContentWrapper = styled(StyledContentWrapper)`
  margin: 50px auto 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  h2,
  p {
    cursor: default;
  }
  > button {
    margin-top: 20%;
  }
`;

class VerificationIssuePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'We had a problem verifying your drivers license information.'
    };
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleRedirect() {
    const { history } = this.props;

    history.replace('/start-quote/driver-license', {});
  }

  render() {
    const { prompt } = this.state;

    return (
      <PageContentWrapper>
        <Prompt>{prompt}</Prompt>
        <Button type="button" primary extraLarge onClick={this.handleRedirect}>
          Try Again
        </Button>
      </PageContentWrapper>
    );
  }
}

VerificationIssuePage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired
};

export default VerificationIssuePage;
