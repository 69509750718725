import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/variables';

function VerticalOptionDivider() {
  return (
    <Wrapper>
      <span>OR</span>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1.3px;
  text-align: center;
  color: ${colors.coreBlue700};
  margin: 15px 0;

  &::before,
  &::after {
    content: '';
    width: 1px;
    height: 20px;
    display: block;
    margin: 0 auto;
    background-color: ${colors.usaaLightGrey};
  }

  span {
    margin: 10px 0;
  }
`;

export default VerticalOptionDivider;
