import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';

class AntiTheftDevicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitAction: 'UPDATE_VEHICLE',
      fields: [
        {
          type: 'radio',
          name: 'antitheftDiscountMapping',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ],
          required: true,
          radioGroupAriaLabel: 'Vehicle Anti-Theft Discount'
        }
      ]
    };
  }

  componentDidMount() {
    const { fields } = this.state;
    const {
      make,
      model,
      antitheftDiscountMapping,
      modelYear,
      askEmergencyBraking,
      askCommuteSurcharge
    } = this.props;

    const modelYearAfter2011 = modelYear > 2011;

    // else we have an editing vehicle
    const prompt = `Does your ${make} ${model} have a passive car alarm?`;
    let successRoute = '';
    // update initial value
    const updatedFields = fields.map(field => {
      if (antitheftDiscountMapping) {
        // if we have a value for antitheftDiscountMapping, use it
        return {
          ...field,
          initialValue: antitheftDiscountMapping
        };
      }

      if (modelYearAfter2011) {
        return {
          ...field,
          initialValue: 'Yes'
        };
      }

      return field;
    });

    if (askEmergencyBraking && modelYearAfter2011) {
      successRoute = '/add-vehicles/emergency-braking';
    } else if (askCommuteSurcharge) {
      successRoute = '/add-vehicles/commute';
    } else {
      successRoute = '/add-vehicles/vehicles-list';
    }

    this.setState({
      successRoute,
      prompt,
      fields: updatedFields
    });
  }

  render() {
    const { submitAction, fields, successRoute, prompt } = this.state;
    const { vehicleId } = this.props;

    if (!vehicleId) {
      return <Redirect to="/add-vehicles/vehicles-list" replace />;
    }

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          renderFieldRow={false}
          maxWidth="500px"
        />
        <InfoBox
          extraData={{
            header: 'What Is A Passive Car Alarm?',
            text: "A passive car alarm turns on automatically when you turn off the car and lock the doors. You don't have to do anything to arm it."
          }}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  vehicles: {
    editingVehicle: {
      vehicleId,
      make,
      model,
      antitheftDiscountMapping,
      modelYear
    }
  },
  app: { askEmergencyBraking, askCommuteSurcharge }
}) => ({
  vehicleId,
  make,
  model,
  modelYear,
  antitheftDiscountMapping,
  askEmergencyBraking,
  askCommuteSurcharge
});

AntiTheftDevicePage.propTypes = {
  askEmergencyBraking: PropTypes.bool.isRequired,
  askCommuteSurcharge: PropTypes.bool.isRequired,
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  modelYear: PropTypes.string.isRequired,
  antitheftDiscountMapping: PropTypes.string,
  vehicleId: PropTypes.string.isRequired
};

AntiTheftDevicePage.defaultProps = {
  antitheftDiscountMapping: ''
};

export default connect(mapStateToProps)(AntiTheftDevicePage);
