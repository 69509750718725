/* eslint-disable default-param-last */
import {
  RESET_EXCLUDED_DRIVERS,
  EXCLUDE_DRIVER,
  EXCLUDE_DRIVER_SUCCESS,
  EXCLUDE_DRIVER_FAILURE,
  STORE_DRIVERS_PENDING_EXCLUSION,
  SET_ADDITIONAL_FIELDS,
  UPDATE_EXCLUDED_DRIVER_INFO,
  UPDATE_EXCLUDED_DRIVER_INFO_SUCCESS,
  UPDATE_EXCLUDED_DRIVER_INFO_FAILURE,
  CHANGE_REQUIRES_RATES,
  SET_LOADING_STATUS,
  UPDATED_RATES_REQUESTED,
  CHANGE_REQUIRES_FORMS_METADATA,
  GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES,
  GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_SUCCESS,
  GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_FAILURE,
  ALL_EXCLUDED_DRIVERS_CONFIRMED
} from 'redux/actions';

const INITIAL_STATE = {
  loading: false,
  requiresUpdatedRates: false,
  requiresFormMetadata: false,
  updatedRates: false,
  lastExcludedDriverId: null, // Tracks last excluded driverId for backwards nav
  lastExcludedDriverActiveBit: null,
  completedDrivers: [],
  driver: {
    // Driver currently being excluded in flow
    activeBit: true,
    driverId: null,
    firstName: '',
    lastName: '',
    obfuscatedLastName: '',
    dob: null,
    type: 'additional',
    dlNumber: null,
    dlState: null,
    education: null,
    genderMapping: null,
    maritalStatus: null,
    ageLicensed: null,
    driverComplete: null,
    memberOfHousehold: null,
    drivesVehicles: null,
    activeMilitaryPersonnel: null,
    requiresActiveMilitaryPersonnel: false,
    requiresSeniorSafeDriverDiscount: false
  },
  nextExcludedDriverId: null, // Next driverId for displaying in confirmation
  driverIdsPendingExclusion: [], // Stores all driverIds excluded in add-drivers
  byId: {}, // Stores key/value pair of driverId (key) and driverObj (value)
  excludedDriverIds: [], // Stores driverIds after successful BE request
  excludedDriverCount: 0, // Tracks number of drivers excluded during flow
  totalDriversToExclude: 0, // Length of excludedDriverIds at start of driver exclusion flow - decrements down 1 after each driver is excluded
  allDriversExcluded: false, // Boolean indicating if all drivers are excluded
  excludedDriverError: false, // Boolean if error occurs during driver exclusion
  excludedDriverErrorMessage: null, // String from BE response if error occurs
  driverNamesObfuscated: true // Boolean indicating if driver names are obfuscated
};

const excludedDriversReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case STORE_DRIVERS_PENDING_EXCLUSION: {
      return {
        ...state,
        ...payload,
        driver: {
          ...state.driver,
          ...payload.driver
        },
        byId: {
          ...state.byId,
          ...payload.byId
        },
        driverIdsPendingExclusion: [...payload.driverIdsPendingExclusion],
        driverNamesObfuscated: true
      };
    }

    case EXCLUDE_DRIVER: {
      return {
        ...state,
        excludedDriverError: false,
        excludedDriverErrorMessage: null
      };
    }

    case EXCLUDE_DRIVER_SUCCESS: {
      const nextDriverId = state.nextExcludedDriverId;

      const nextDriver = nextDriverId
        ? { ...state.byId[nextDriverId] }
        : INITIAL_STATE.driver;

      const driverIdsPendingExclusion = nextDriverId
        ? state.driverIdsPendingExclusion.filter(
            driverId => driverId !== nextDriverId
          )
        : state.driverIdsPendingExclusion;

      const excludedDriverIds = state.excludedDriverIds
        .slice()
        .concat(payload.driverId);

      const excludedDriverCount = excludedDriverIds.length;

      const driverCount = Object.keys(state.byId).length;

      const totalDriversToExclude = driverIdsPendingExclusion.length;
      const allDriversExcluded = driverCount === excludedDriverCount;
      const [nextExcludedDriverId = null] = driverIdsPendingExclusion;

      return {
        ...state,
        driver: { ...nextDriver },
        completedDrivers: [
          ...state.completedDrivers,
          { ...state.byId[payload.driverId], ...payload }
        ],
        byId: {
          ...state.byId,
          [payload.driverId]: {
            ...state.byId[payload.driverId],
            ...payload
          }
        },
        lastExcludedDriverId: payload.driverId,
        lastExcludedDriverActiveBit: payload.activeBit,
        nextExcludedDriverId,
        driverIdsPendingExclusion,
        excludedDriverIds,
        totalDriversToExclude,
        excludedDriverCount,
        allDriversExcluded
      };
    }

    case EXCLUDE_DRIVER_FAILURE:
      return {
        ...state,
        excludedDriverError: true,
        excludedDriverErrorMessage: action.error
      };

    case UPDATE_EXCLUDED_DRIVER_INFO:
      return {
        ...state,
        driver: {
          ...state.driver,
          ...payload
        }
      };

    case UPDATE_EXCLUDED_DRIVER_INFO_SUCCESS:
      return {
        ...state,
        driver: {
          ...state.driver,
          ...payload
        }
      };

    case UPDATE_EXCLUDED_DRIVER_INFO_FAILURE:
      return {
        ...state,
        excludedDriverError: true,
        excludedDriverErrorMessage: action.error
      };

    case SET_ADDITIONAL_FIELDS:
      return {
        ...state,
        driver: {
          ...state.driver,
          ...payload
        }
      };

    case CHANGE_REQUIRES_RATES:
      return {
        ...state,
        updatedRates: false,
        requiresUpdatedRates: true,
        requiresFormMetadata: false
      };

    case RESET_EXCLUDED_DRIVERS:
      return {
        ...INITIAL_STATE
      };

    case SET_LOADING_STATUS:
      return {
        ...state,
        loading: payload
      };

    case UPDATED_RATES_REQUESTED:
      return {
        ...state,
        updatedRates: true
      };

    case CHANGE_REQUIRES_FORMS_METADATA:
      return {
        ...state,
        requiresFormMetadata: true,
        requiresUpdatedRates: false,
        updatedRates: false
      };

    case GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES:
      return {
        ...state,
        driverNamesObfuscated: true,
        excludedDriverError: null,
        excludedDriverErrorMessage: null
      };

    case GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_SUCCESS:
      return {
        ...state,
        driver: {
          ...state.driver,
          ...payload.driver
        },
        byId: { ...state.byId, ...payload.byId },
        driverNamesObfuscated: false
      };

    case GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_FAILURE:
      return {
        ...state,
        excludedDriverError: action.error,
        excludedDriverErrorMessage: action.errorMessage
      };

    case ALL_EXCLUDED_DRIVERS_CONFIRMED:
      return {
        ...state,
        allDriversExcluded: true,
        totalDriversToExclude: 0
      };

    default:
      return state;
  }
};

export default excludedDriversReducer;
