import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { brandonGrotesque, colors } from 'styles/variables';

function StandaloneLink({ extraData: { link = null, text = null } }) {
  return (
    link && <StyledLink to={link}>{text || 'Or enter manually'}</StyledLink>
  );
}

const StyledLink = styled(Link)`
  margin: 0 auto;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  font-family: ${brandonGrotesque};
  font-size: ${props => (props.fontSize ? props.fontSize : '18px')};
  letter-spacing: 1px;
  text-align: center;
  display: block;
  color: ${colors.alphaBlue500};
  border-bottom: ${colors.alphaBlue500};
`;

export default StandaloneLink;
