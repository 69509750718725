import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import Modal from 'components/Modal';
import { Typography, Box } from '@material-ui/core';
import { ModalHeader, TextLink, StyledText, StyledList } from 'styles';
import { openSans } from 'styles/variables';
import { militaryDiscountLink } from '../../constants';

const ModalContentWrapper = styled.div`
  p {
    font-size: 18px;
    margin-bottom: 40px;
    font-family: ${openSans};
  }
`;

const NoWrapSpan = styled.span`
  white-space: nowrap;
`;

const MilitaryTextLink = styled(TextLink)`
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #0b2237;
`;

function MilitaryDiscount() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch({ type: 'OPEN_MODAL', payload: 'militaryDiscount' });
  };

  return (
    <>
      <Box>
        <Typography>
          {`Eligible Military Personnel and their families can save even more with
        Noblr by simply submitting an `}
          <a
            style={{ textDecoration: 'underline' }}
            href={militaryDiscountLink}
            target={militaryDiscountLink}
          >
            {'Application for Active Military Service Discount '}
          </a>
          <NoWrapSpan>within 10 days of buying a policy.</NoWrapSpan>
          {' Find out more about our'}
          <span>
            <MilitaryTextLink military onClick={handleClick}>
              Active Military Service Discount
            </MilitaryTextLink>
          </span>
        </Typography>
      </Box>
      <Modal
        modalLabel="MilitaryDiscountModal"
        maxWidth="sm"
        modalName="militaryDiscount"
        showCloseButton
      >
        <ModalContentWrapper>
          <ModalHeader>ELIGIBILITY REQUIREMENTS</ModalHeader>
          <StyledText>
            You may be eligible for an Active Military Service discount on your
            Noblr policy if:
          </StyledText>
          <StyledList>
            <li>
              {`You are serving full-time on active military duty in the US Navy,
              Marine Corp, Air Force, Reserve or National Guard, or Coast Guard
              ("Military Personnel"), or you are the spouse or dependent of
              Military Personnel.`}
            </li>
            <li>
              You are active Military Personnel based in Louisiana, or you are
              the spouse or dependent of active military personnel and you
              reside in Louisiana.
            </li>
            <li>
              As a qualifying dependent, you are under 18, or you are between
              the ages of 18 and 24 and a full-time student and currently
              unmarried.
            </li>
            <li>
              All cars listed on the policy are owned or leased by the
              qualifying active Military Personnel, their spouse, or their
              dependent.
            </li>
            <li>
              {'You complete the '}
              <a
                style={{ textDecoration: 'underline' }}
                href={militaryDiscountLink}
                target={militaryDiscountLink}
              >
                Application for Active Military Service discount
              </a>
              {' provided '}
              <a
                style={{ textDecoration: 'underline' }}
                href={militaryDiscountLink}
                target={militaryDiscountLink}
              >
                here
              </a>
              {' and send it to '}
              <a
                style={{ textDecoration: 'underline' }}
                href="mailto:service@noblr.com"
                target="mailto:service@noblr.com"
              >
                {' service@noblr.com'}
              </a>
              {' ten days of purchasing your policy.'}
            </li>
          </StyledList>
        </ModalContentWrapper>
      </Modal>
    </>
  );
}

export default MilitaryDiscount;
