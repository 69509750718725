/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { openSans, breakPoints, colors } from 'styles/variables';

const downChevronURL = `${process.env.REACT_APP_NOBLR_CDN}/icons/down-chevron-icon.svg`;

const upChevronURL = `${process.env.REACT_APP_NOBLR_CDN}/icons/up-chevron-icon.svg`;

const CardWrapper = styled.div`
  margin: auto 25px;
  padding: 20px;
  width: auto;
  min-width: 360px;
  height: ${props => (props.isExpanded ? '450px' : '100%')};
  border-radius: 20px;
  border: ${props =>
    props.isSelected
      ? `solid 2px ${colors.coreBlue700}`
      : `solid 2px ${colors.usaaLightGrey}`};
  background-color: ${props =>
    props.isSelected ? colors.usaaLightestGrey : colors.white};

  .divider {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${colors.usaaLightGrey};
  }

  @media screen and (max-width: ${breakPoints.small}) {
    width: 310px;
    min-width: 310px;
  }

  @media screen and (max-width: 520px) {
    width: 320px;
    min-width: 320px;
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    width: 340px;
    min-width: 340px;
  }

  /* 1024px is in between the "large" ('991.98px') and "extraLarge" ('1119.98px') break points as defined in "src/styles/variables" */
  @media screen and (min-width: ${breakPoints.large}) {
    width: 360px;
    min-width: 360px;
  }
`;

const SavingsHeader = styled.h3`
  position: relative;
  top: -35px;
  width: 140px;
  padding: 3px 8px;
  margin: 0 auto -30px;
  flex: none;
  color: ${colors.white};
  background-color: ${colors.alphaBlue500};
  font-weight: 600;
  font-size: 14px;
  font-family: ${openSans};
  border-radius: 8px;
  text-transform: uppercase;
`;

const AmountText = styled.p`
  text-align: center;
  letter-spacing: 0.44px;
  line-height: 1.67;
  font-size: 14px;
`;

export const SectionWrapper = styled.section`
  margin: 30px 30px 0 30px;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.usaaLightGrey};
  cursor: pointer;

  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 25px;
    `}
`;

const CardRow = styled.div`
  margin: 3px auto;
  padding: 5px 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;

  > p.installment-detail {
    font-family: ${openSans};
    font-size: 14px;
    max-width: 250px;
    line-height: 1.67;
    letter-spacing: 0.78px;
    text-align: left;
  }

  img {
    cursor: pointer;
    vertical-align: text-top;
    margin: auto 0;
  }
`;

const ExpandableSection = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  text-align: left;
  color: ${colors.coreBlue700};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  height: 40px;
  margin: 0 auto;
  span {
    font-weight: 700;
    line-height: 1.39;
    font-size: 18px;
    letter-spacing: 0.64px;
    color: ${colors.coreBlue700};
  }
`;

function ExpandableRadioCard({
  name,
  label,
  header,
  amount,
  children,
  isSelected,
  handleSelection,
  biggestSavings = false
}) {
  const [isExpanded, setExpanded] = useState(false);
  const toggleSection = () => setExpanded(!isExpanded);

  // TODO: Fix accessibility issues
  return (
    <CardWrapper isExpanded={isExpanded} isSelected={isSelected}>
      {biggestSavings && <SavingsHeader>Biggest Savings</SavingsHeader>}
      <CardRow bottomBorder>
        <StyledFormControlLabel
          value={name}
          label={label}
          labelPlacement="end"
          control={
            <Radio
              name={name}
              checked={isSelected}
              color="primary"
              inputProps={{ 'aria-label': name }}
              onChange={handleSelection}
            />
          }
        />
        <AmountText>
          {amount} <br /> Due Today
        </AmountText>
      </CardRow>
      <CardRow>
        <div className="divider" />
      </CardRow>
      <CardRow onClick={toggleSection}>
        <p className="installment-detail">{header}</p>
        <img
          height="12px"
          width="12px"
          src={isExpanded ? upChevronURL : downChevronURL}
          alt={isExpanded ? 'Close' : 'Open'}
          style={{ objectFit: 'contain' }}
        />
      </CardRow>
      {isExpanded && <ExpandableSection>{children}</ExpandableSection>}
    </CardWrapper>
  );
}

export default ExpandableRadioCard;
