import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { validationTools } from '@noblr-lab/frontend-tools';
import Form from 'components/Form/Form';
import { StyledContentWrapper, AssignmentLabel } from 'styles';
import { selectExcessVehicleFields } from 'redux/selectors';

class CarAssignmentFollowup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: null,
      submitAction: 'ASSIGN_EXCESS_VEHICLES',
      successRoute: '/driving-behavior/start',
      prompt:
        'Next, please tell us which driver to assign to the following cars.'
    };

    this.buildExcessCarAssignmentFields =
      this.buildExcessCarAssignmentFields.bind(this);
  }

  componentDidMount() {
    const { isUSAAMember, usaaPersonInfoVerified } = this.props;

    const isUSAAQuoteIntegrationFlow = isUSAAMember && usaaPersonInfoVerified;

    let updatedSuccessRoute = null;
    const fields = this.buildExcessCarAssignmentFields();

    if (isUSAAQuoteIntegrationFlow) {
      updatedSuccessRoute = `/driving-behavior/mileage`;
    }
    this.setState(prevState => ({
      fields,
      successRoute: updatedSuccessRoute || prevState.successRoute
    }));
  }

  buildExcessCarAssignmentFields() {
    const { fieldData } = this.props;

    return fieldData.map(
      ({ vehicleId, make, model, modelYear, primarilyDrivenBy, options }) => ({
        name: vehicleId,
        id: `assignment-select-${vehicleId}`,
        options,
        type: 'select',
        selectLabel: 'driver',
        selectLabelId: `driver-assignment-select-${vehicleId}`,
        description: (
          <AssignmentLabel>{`${modelYear} ${make} ${model}`}</AssignmentLabel>
        ),
        required: true,
        initialValue: primarilyDrivenBy ? primarilyDrivenBy.driverId : ''
      })
    );
  }

  render() {
    const { submitAction, successRoute, prompt, fields } = this.state;

    if (!fields) {
      return null;
    }

    const validationSchema = validationTools.excessCarAssignmentSchema(fields);

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          validationSchema={validationSchema}
          submitAsync
          renderFieldRow
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = () => {
  const realMapState = state => {
    const {
      app: { isUSAAMember, usaaPersonInfoVerified }
    } = state;

    return {
      fieldData: selectExcessVehicleFields(state),
      isUSAAMember,
      usaaPersonInfoVerified
    };
  };

  return realMapState;
};

CarAssignmentFollowup.propTypes = {
  isUSAAMember: PropTypes.bool.isRequired,
  usaaPersonInfoVerified: PropTypes.bool.isRequired,
  fieldData: PropTypes.shape({
    vehicleId: PropTypes.bool.isRequired,
    make: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    modelYear: PropTypes.string.isRequired,
    options: PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired,
    map: PropTypes.func.isRequired
  }).isRequired
};

export default connect(mapStateToProps)(CarAssignmentFollowup);
