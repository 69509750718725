import React from 'react';
import styled from 'styled-components/macro';

function LiabilityAgreement({ extraData }) {
  const continueText =
    extraData &&
    extraData.liabilityAgreement &&
    extraData.liabilityAgreement.continueText
      ? extraData.liabilityAgreement.continueText
      : 'Continue';

  return (
    <StyledLiabilityAgreement>
      {`By clicking “${continueText},” you agree to our `}
      <a
        className="link"
        href="https://www.usaa.com/insurance/pay-as-you-drive/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Link to Noblr Terms and Conditions"
      >
        Terms and Conditions
      </a>
      {' and '}
      <a
        className="link"
        href="https://www.usaa.com/insurance/pay-as-you-drive/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Link to Noblr Privacy Policy"
      >
        Privacy Policy.
      </a>
    </StyledLiabilityAgreement>
  );
}

const StyledLiabilityAgreement = styled.p`
  margin: 0 0 50px 0;

  a {
    display: inline;
  }
`;

export default LiabilityAgreement;
