import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { object } from 'yup';
import styled, { css } from 'styled-components/macro';
import { Formik, Form, Field } from 'formik';
import Noblr from '@noblr-lab/react-app-services';
import {
  colors,
  brandonGrotesque,
  openSans,
  breakPoints
} from 'styles/variables';
import { singleCheckboxSchema } from 'utilities/form';
import Button from '../Buttons/Button';
import CheckBox from './CheckBox';

const AuthorizeWrapper = styled.div`
  padding: 20px 30px 50px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${colors.usaaLightestGrey};
  overflow-wrap: break-word;

  button {
    align-self: center;
  }

  @media only screen and (max-width: ${breakPoints.medium}) {
    padding: 30px 30px 50px;
  }
`;

const StyledLabel = styled.label`
  color: ${colors.coreBlue700};
  font-size: 24px;
  font-family: ${brandonGrotesque};
  text-transform: uppercase;
  cursor: pointer;

  @media only screen and (max-width: ${breakPoints.medium}) {
    margin-left: 50px;
    text-align: left;
    font-size: 18px;
  }
`;

const CheckboxContainer = styled.div`
  margin: 10px auto 0;
  text-align: left;

  @media screen and (max-width: ${breakPoints.medium}) {
    width: 460px;
    max-width: 90%;
  }
`;

const AuthorizeTextWrapper = styled.div`
  align-self: center;
  align-content: center;
  max-width: 710px;
  margin: 30px auto 0;
  @media screen and (min-width: ${breakPoints.large}) {
    max-height: 200px;
    ${props =>
      props.marginBottom &&
      css`
        margin-bottom: 3rem;
      `}
  }
  @media screen and (max-width: ${breakPoints.medium}) {
    width: 460px;
    max-width: 90%;
    ${props =>
      props.marginBottom &&
      css`
        margin-bottom: 0.5rem;
      `}
  }
`;

const AuthorizeText = styled.p`
  font-size: 14px;
  font-family: ${openSans};
  text-align: left;
  line-height: 1.56;
  color: ${colors.coreBlue700};
  @media screen and (min-width: ${breakPoints.large}) {
    font-size: 18px;
  }
`;

const UsaaText = styled.p`
  font-size: 8px;
  font-family: ${openSans};
  text-align: left;
  color: ${colors.darkGunMetal};
  @media screen and (min-width: ${breakPoints.medium}) {
    font-size: 12px;
  }
`;

function StyledCheckbox({ field }) {
  return (
    <CheckboxContainer>
      <StyledLabel htmlFor={field.id}>
        <CheckBox
          onChange={field.onChange}
          checked={field.value}
          checkboxField={{
            type: 'checkbox',
            id: 'acknowledgeCheckbox',
            required: true,
            ...field
          }}
          backgroundColor="transparent"
          tabIndex={0}
        />
        I have read and agree to the terms below
      </StyledLabel>
    </CheckboxContainer>
  );
}

class ReviewAndPayForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { successRoute, history } = this.props;

    Noblr.interceptors.map(interceptor => {
      interceptor.request.use(
        request => {
          return request;
        },
        err => Promise.reject(err)
      );

      interceptor.response.use(
        response => {
          if (
            response.status === 200 &&
            response.config.url.includes('quote/bind')
          ) {
            history.replace(successRoute);
          }

          return response;
        },
        err => {
          history.replace('/purchase/payment-info');

          return Promise.reject(err);
        }
      );

      return interceptor;
    });
  }

  handleSubmit(values, setSubmitting) {
    const { dispatch, submitAction } = this.props;

    return dispatch({
      type: submitAction,
      payload: values,
      setSubmitting
    });
  }

  render() {
    const { amountDue, requiresUSAAEligibility } = this.props;

    const schema = object().shape({
      acknowledgeCheckbox: singleCheckboxSchema
    });

    // TODO : Fix accessibility issues
    return (
      <div className="form-wrapper">
        <Formik
          validateOnMount
          validationSchema={schema}
          initialValues={{ acknowledgeCheckbox: false }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            return this.handleSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting, isValid, values }) => {
            return (
              <Form>
                <AuthorizeWrapper>
                  <Field
                    name="acknowledgeCheckbox"
                    id="acknowledgeCheckbox"
                    value={values.acknowledgeCheckbox}
                    component={StyledCheckbox}
                  />

                  {requiresUSAAEligibility && (
                    <AuthorizeTextWrapper>
                      <AuthorizeText>
                        {`By purchasing this policy you agree that the information
                  provided is true and correct. If the information provided is
                  found to be untrue or we are unable to verify your
                  policyholder details, you agree that your policy will be
                  canceled.`}
                      </AuthorizeText>
                    </AuthorizeTextWrapper>
                  )}
                  <AuthorizeTextWrapper marginBottom>
                    <AuthorizeText>
                      {`I authorize Noblr to charge my credit or debit card for
                  the amount of $${amountDue} today. I agree to pay the total
                  amount according to the card issuer agreement, and I
                  certify that I am an authorized user for this account. I
                  authorize Noblr to charge this credit or debit card on
                  a monthly basis for the monthly premium, driving        charges, and other charges that I owe under the policy
                  and for any renewal terms. I acknowledge that the amount
                  charged may vary each month. If I choose to cancel the
                  recurring charges, and therefore the policy, I may do so
                  by calling 1-877-BE-NOBLR.`}
                    </AuthorizeText>
                  </AuthorizeTextWrapper>
                  <Button
                    primary
                    large
                    className="form__submit cta--1"
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    Buy Policy
                  </Button>
                </AuthorizeWrapper>
                {requiresUSAAEligibility && (
                  <AuthorizeTextWrapper marginTop>
                    <UsaaText>
                      {`Availability restrictions apply to purchase of certain products. Use of term "member" or "membership" does not convey any eligibility rights for auto and property insurance products, or legal or ownership rights in USAA. To be eligible for auto and property insurance, separated military personnel must have been separated with a discharge type of "Honorable." Once you meet eligibility requirements and purchase auto or property insurance underwritten by USAA, eligibility is generally ongoing. Eligible parent must purchase auto or property insurance for eligibility to pass to children. Eligibility may change based on factors such as marital status, rank, or military status. Eligibility guidelines are subject to change without notice. Purchase of a non property and casualty insurance product, or an insurance policy offered through USAA Insurance Agency, does not establish eligibility for or membership in USAA property and casualty insurance companies.`}
                    </UsaaText>
                  </AuthorizeTextWrapper>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { requiresUSAAEligibility } }) => ({
  requiresUSAAEligibility
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReviewAndPayForm)
);
