import React, { Component, lazy, Suspense } from 'react';
import { withCookies } from 'react-cookie';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import AsyncErrorMessage from 'components/AsyncErrorMessage';
import { setSection, clearFormError, redirect } from 'redux/actions';
import Page from 'components/Pages/Page';
import { retryImport } from 'utilities';
import { GlobalTheme, BodyWrapper } from 'styles';
import ROUTES from 'constants/routes';

const SoftStop = lazy(() => retryImport(() => import('pages/dnq/softStop')));

const ErrorPage = lazy(() =>
  retryImport(() => import('components/Pages/ErrorPage'))
);

// Setup Sentry Route for Performance Tracing
const SentryRoute = Sentry.withSentryRouting(Route);

class Router extends Component {
  componentDidMount() {
    const {
      location: { pathname },
      currentAppSection,
      setSection
    } = this.props;

    const [, currentSection] = pathname.split('/');

    if (window.embedded_svc) {
      if (currentSection === 'quote' || currentSection === 'purchase') {
        window.embedded_svc.settings.displayHelpButton = true;
      } else if (window.embedded_svc.settings.displayHelpButton) {
        window.embedded_svc.settings.displayHelpButton = false;
      }
    }

    if (currentAppSection !== currentSection) {
      setSection(currentSection);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
      currentAppSection,
      setSection
    } = this.props;

    const [, currentSection] = pathname.split('/');

    if (window.embedded_svc) {
      if (currentSection === 'quote' || currentSection === 'purchase') {
        window.embedded_svc.settings.displayHelpButton = true;
      } else {
        window.embedded_svc.settings.displayHelpButton = false;
      }
    }

    if (
      prevProps.location.pathname !== pathname &&
      currentAppSection !== currentSection
    ) {
      setSection(currentSection);
    }
  }

  render() {
    const { cookies, error, location } = this.props;

    return (
      <BodyWrapper path={location.pathname}>
        <GlobalTheme />
        <Suspense fallback={null}>
          <Switch>
            {ROUTES.map(route =>
              route.path && route.component ? (
                <SentryRoute
                  path={route.path}
                  component={route.component}
                  key={route.path}
                  exact
                  sensitive
                  strict
                />
              ) : (
                <SentryRoute
                  exact
                  sensitive
                  strict
                  path={route}
                  key={route}
                  render={routeProps => (
                    <Page {...routeProps} cookies={cookies} path={route} />
                  )}
                />
              )
            )}
            <SentryRoute
              exact
              path="/dnq"
              render={() => <ErrorPage error={error} />}
            />
            <SentryRoute
              exact
              path="/call-to-complete"
              render={() => <SoftStop />}
            />
            <SentryRoute path="/" exact>
              <Redirect from="/" to="/start-quote/name" replace />
            </SentryRoute>
            <SentryRoute
              path="*"
              render={() => <ErrorPage error={{ status: '404' }} />}
            />
          </Switch>
        </Suspense>
        <AsyncErrorMessage />
      </BodyWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { error, section: currentAppSection, prevSection: prevAppSection }
}) => ({
  error,
  currentAppSection,
  prevAppSection
});

const mapDispatchToProps = dispatch => ({
  setSection: section => dispatch(setSection(section)),
  clearFormError: () => dispatch(clearFormError()),
  redirect: url => dispatch(redirect(url))
});

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(Router)
);
