/* eslint-disable camelcase */
import Noblr from '@noblr-lab/react-app-services';
import { put, select, call, all, putResolve } from 'redux-saga/effects';
import { isObjectEmpty } from 'utilities';

/**
 * Build array of all vehicles
 * @param {Object} action
 */
export function* updatedAssociatedVehicles(action) {
  const associatedVehicleList = action.payload.map(
    vehicle =>
      // for each vehicle, use constructor to build object
      new AssociatedVehicle(
        vehicle.vin,
        vehicle.make,
        vehicle.model,
        vehicle.year,
        vehicle.vehicleId,
        vehicle.prefill,
        vehicle.vehicleOwnership,
        vehicle.activeBit,
        vehicle.vinStem,
        vehicle.vehicleComplete
      )
  );

  // update list of vehicles with constructed vehicles
  yield put({
    type: 'UPDATE_ASSOCIATED_VEHICLES',
    payload: associatedVehicleList
  });
}

/**
 * Vehicle Constructor
 * @param {string} vin
 * @param {string} make
 * @param {string} model
 * @param {string} year
 * @param {string} vehicleId
 * @param {Boolean} prefill
 * @param {string} vehicleOwnership
 * @param {Boolean} activeBit
 * @param {string} vinStem
 * @param {Boolean} vehicleComplete
 */
function AssociatedVehicle(
  vin,
  make,
  model,
  year,
  vehicleId,
  prefill,
  vehicleOwnership,
  activeBit,
  vinStem,
  vehicleComplete
) {
  this.vin = vin;
  this.vinStem = vinStem;
  this.make = make;
  this.model = model;
  this.year = year;
  this.modelYear = year;
  this.vehicleId = vehicleId;
  this.vehicleOwnership = vehicleOwnership;
  this.vehicleComplete = vehicleComplete;
  this.prefill = prefill;
  this.activeBit = activeBit;
}

/**
 * Map rideshare question value to boolean and hit endpoint
 * @param {Object} action
 */
export function* submitRideshareStatusSubroutine({
  payload: { isRideshare },
  successRoute
}) {
  const {
    app: { isUSAAMember, usaaPersonInfoVerified }
  } = yield select();

  if (isUSAAMember && usaaPersonInfoVerified) {
    yield putResolve({ type: 'ORDER_MVR' });
  }

  try {
    // Send request to Noblr App Services endpoint
    // if rideshare is true, we DNQ
    const { vehicleRideShare } = yield call(
      Noblr.vehicle.updateRideshare,
      isRideshare !== 'No'
    );
    // Update reducer with rideshare answer

    yield put({
      type: 'SET_RIDESHARE_TNC_STATUS_SUCCESS',
      payload: {
        isRideshare: vehicleRideShare
      }
    });
    // if makeApiCall is true and successRoute is passed in (USAA Quote Integration)
    yield put({ type: 'REDIRECT', payload: { url: successRoute } });
  } catch (error) {
    yield put({
      type: 'SET_RIDESHARE_TNC_STATUS_FAILURE'
    });
    // catch and set any error that occurs
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

// TODO Since this generator is in the vehicles saga, we'd have to either move this to the app reducer
// so we're able to update the app reducer before it's passed to the generator which seems like
// it might make sense here because the vehicles reducer isn't re-hydrating values when this action type is dispatched.
export function* checkAmpOwnsAllVehicles({
  payload: { allVehiclesOwnedByAmp }
}) {
  const {
    app: { section },
    excludedDrivers: { requiresUpdatedRates, updatedRates, allDriversExcluded },
    rate: { currentPackage, savedCoverages, vinVerificationNeeded }
  } = yield select();

  const requestUpdatedRates =
    section === 'driver-exclusion' &&
    requiresUpdatedRates &&
    allDriversExcluded &&
    !updatedRates;

  try {
    yield call(
      Noblr.vehicle.updateVehiclesOwnedByAmp,
      allVehiclesOwnedByAmp === 'Yes'
    );

    // update the app with the result
    yield put({
      type: 'UPDATE_APP',
      payload: {
        allVehiclesOwnedByAmp
      }
    });

    if (requestUpdatedRates && !vinVerificationNeeded) {
      yield putResolve({
        type: 'GET_RATE_BY_PACKAGE',
        payload: {
          currentPackage,
          queryParams: { savePackage: true },
          ...savedCoverages
        },
        shouldRedirect: false
      });
      yield putResolve({
        type: 'UPDATED_RATES_REQUESTED'
      });
    }
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Check if vehicle should be dnq'd after adding all vehicles
 */
export function* getVehicleDNQ({ payload: { onContinueRoute } }) {
  try {
    // Send request to Noblr App Services endpoint
    yield call(Noblr.vehicle.vehicleDNQ);

    // if we don't dnq, redirect the user to the next screen
    yield put({
      type: 'REDIRECT',
      payload: { url: onContinueRoute }
    });
  } catch (error) {
    // set error, this will display dnq screen if any of the vehicles on the policy should dnq
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Save VIN
 * @param {Object} action
 */
export function* saveVINSubroutine(action) {
  const {
    payload: { vin },
    formikActions: { setSubmitting, resetForm }
  } = action;

  // todo: remove this?
  // clear async errors
  yield put({
    type: 'CLEAR_ASYNC_ERRORS'
  });
  yield put({
    type: 'CLEAR_FORM_ERRORS'
  });

  try {
    const {
      vehicles: { items }
    } = yield select();

    // Send request to Noblr App Services endpoint to save the vehicle
    // make sure to trim any whitespace from the vin entered
    const response = yield call(Noblr.vehicle.saveVehicle, { vin: vin.trim() });

    yield all([
      // todo: determine if/why we need both save vin success and update editing vehicle with same payloads
      // set edited flag and pass result from api to vehicle reducer
      put({
        type: 'SAVE_VIN_SUCCESS',
        payload: { ...response, edited: true }
      }),
      put({
        type: 'UPDATE_EDITING_VEHICLE',
        payload: {
          ...response,
          edited: true
        }
      }),
      // update array of vehicles to include new vehicle at the end
      put({
        type: 'UPDATE_ASSOCIATED_VEHICLES',
        payload: [...items, response]
      })
    ]);
  } catch (error) {
    yield put({
      type: 'SAVE_VIN_FAILURE'
    });

    // if the vin validation fails on the backend, set form error
    if (error.status === 400) {
      resetForm();
      yield put({
        type: 'SET_FORM_ERROR',
        error: { status: error.status, ...error.data }
      });
    } else {
      // set error on app level
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    }
  } finally {
    // make sure isSubmitting is false
    setSubmitting(false);
  }
}

/**
 * Update vehicle info
 */
export function* updateVehicleSubroutine({ payload }) {
  const {
    vehicles: {
      editingVehicle: {
        vehicleId: editingVehicleId,
        modelYear,
        prefill,
        vin,
        vinStem
      },
      items
    }
  } = yield select();

  const vehicleId = payload.vehicleId || editingVehicleId;

  const payloadToSave = {
    // these values are required to update a vehicle
    vehicleId,
    vin,
    vinStem
  };

  // check that we have ownership length and model year
  if (payload.vehicleOwnershipMonths && modelYear) {
    // default advancedTechnologyDiscount if car is older than 2011
    const advancedTechnologyDiscount = modelYear > 2011 ? null : 'NONE';

    // add to payload to save
    if (advancedTechnologyDiscount) {
      payloadToSave.advancedTechnologyDiscount = advancedTechnologyDiscount;
    }
  }

  try {
    // Send request to Noblr App Services endpoint to update the vehicle
    const updateVehicleResult = yield call(Noblr.vehicle.updateVehicle, {
      ...payloadToSave,
      ...payload
    });

    const updatedVehicles = items.map(item =>
      item.vehicleId === updateVehicleResult.vehicleId
        ? {
            ...item,
            ...updateVehicleResult,
            edited: true
          }
        : item
    );

    // todo: dispatch update vehicle success action to update vehicle instead of stuff above
    // update items for non prefill vehicles
    if (!prefill) {
      // update the vehicle in array of vehicles and set edited to true
      yield put({
        type: 'UPDATE_ASSOCIATED_VEHICLES',
        payload: updatedVehicles
      });
    }

    // if the vehicle is complete
    if (updateVehicleResult.vehicleComplete) {
      // trigger action to mark vehicle as complete
      yield put({
        type: 'COMPLETE_ADDED_VEHICLE_REGISTRATION',
        payload: {
          items: updatedVehicles
        }
      });
    }
  } catch (error) {
    // ! do not remove this console statement
    // eslint-disable-next-line no-console
    console.log(error);

    // catch and set any error
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Remove a vehicle
 * Sets vehicle's active bit to false
 * @param {Object} action
 */
export function* removeVehicle(action) {
  const {
    payload: { vehicleId }
  } = action;

  try {
    // Send request to Noblr App Services endpoint to remove the vehicle
    yield call(Noblr.vehicle.deleteVehicle, vehicleId);

    // remove vehicle in store
    yield put({
      type: 'REMOVE_VEHICLE_SUCCESS',
      payload: { vehicleId }
    });
  } catch (error) {
    // check for error
    if (error.status && error.status === 400) {
      // REMOVE FAILED
      // show error?

      // clear editing vehicle
      yield put({
        type: 'CLEAR_EDITING_VEHICLE'
      });
    } else {
      // set error at app level
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    }
  }
}

/**
 * Fetch all vehicles on quote
 */
export function* getAllVehicles() {
  try {
    // Send request to Noblr App Services endpoint to get all vehicles
    const vehicles = yield call(Noblr.vehicle.getAllVehicles);

    // pass result to vehicle reducer
    yield put({
      type: 'GET_ALL_VEHICLES_SUCCESS',
      payload: { vehicles }
    });
  } catch (error) {
    // catch and set any error
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Build vehicle coverages after we've gotten all vehicles from backend
 * When vehicle coverages get moved into vehicle object we can remove this
 * @param {Object} action
 */
export function* getAllVehiclesSuccess(action) {
  const { vehicles } = action.payload;

  // todo: do we use this?
  const coverageSelections = {};

  // build vehicle coverages
  vehicles.map(vehicle => {
    if (!vehicle.vehicleCoverages) {
      return coverageSelections;
    }

    // if we are using this, we need to update this to iterate over coverage keys
    // this only works for CO i think
    coverageSelections[`${vehicle.vehicleId}-collDeductible`] =
      vehicle.vehicleCoverages.collDeductible;

    coverageSelections[`${vehicle.vehicleId}-compDeductible`] =
      vehicle.vehicleCoverages.compDeductible;

    coverageSelections[`${vehicle.vehicleId}-rent`] =
      vehicle.vehicleCoverages.rent;

    return coverageSelections;
  });

  // if we have any coverage selections
  if (!isObjectEmpty(coverageSelections)) {
    // set them on the policy reducer
    yield put({
      type: 'GET_VEHICLE_DEDUCTIBLES_SUCCESS',
      payload: coverageSelections
    });
  }
}

/**
 * Change vehicle's active bit from false back to true
 * @param {Object} action
 */
export function* undoRemoveVehicle(action) {
  const {
    vehicles: { items }
  } = yield select();

  const { payload } = action;

  try {
    // Send request to Noblr App Services endpoint
    const updateVehicleResult = yield call(
      Noblr.vehicle.updateVehicle,
      payload
    );

    // update vehicle in array of vehicles or add the vehicle to the array
    // todo: move this to complete add vehicle saga based on completed key
    const foundIndex = items
      ? items.findIndex(item => item.vin === payload.vin)
      : -1;

    if (foundIndex > -1) {
      items[foundIndex] = updateVehicleResult;
    } else {
      items.push(updateVehicleResult);
    }

    // separate the current vehicle from the others
    const currentVehicle = items.find(
      item => item.vehicleId === payload.vehicleId
    );

    const otherVehicleItems = items.filter(
      item => item.vehicleId !== payload.vehicleId
    );

    // update the array of vehicles
    yield put({
      type: 'UNDO_REMOVE_VEHICLE_SUCCESS',
      payload: currentVehicle.vehicleId
    });

    // update the array of vehicles
    yield put({
      type: 'UPDATE_ASSOCIATED_VEHICLES',
      payload: [
        ...otherVehicleItems,
        {
          ...currentVehicle,
          ...updateVehicleResult,
          edited: true
        }
      ]
    });
  } catch (error) {
    if (error && error.status === 400) {
      // failed to undo removal because VIN duplicate
      yield put({
        type: 'UNDO_REMOVE_VEHICLE_FAILURE',
        payload: {
          vehicle: { ...payload, activeBit: false, vehicleComplete: true },
          error: {
            message: error.data.message,
            errorVehicleId: payload.vehicleId
          }
        }
      });
    } else {
      // catch and set any error
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    }
  }
}

/**
 * Get list of makes and models for year selected
 */
export function* getYearMakeModelRequest({ payload: { year } }) {
  try {
    // Send request to Noblr App Services endpoint to get all make, model, styles by year
    const result = yield call(Noblr.vehicle.getYearMakeModel, year);

    const YMM_make = [];
    const YMM_modelDTO = {};

    // iterate over resulting manufacturers to build array of makes
    result.forEach(manufacturer => {
      YMM_make.push({ value: manufacturer.make, label: manufacturer.make });

      // set make as a key associated to the list of models and styles available
      YMM_modelDTO[manufacturer.make] = manufacturer.modelStyleList;
    });

    // pass make array and model object to reducer to build dropdowns
    yield put({
      type: 'GET_YEAR_MAKE_MODEL_SUCCESS',
      payload: { YMM_make, YMM_modelDTO }
    });
  } catch (error) {
    // catch and set any error
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Update list of models and styles when user selects a make
 */
export function* vehicleMakeModelWatcher({ payload: { make } }) {
  const {
    vehicles: {
      YMM: { YMM_modelDTO }
    }
  } = yield select();

  // reduce model that exists for the selected make
  const { YMM_model, YMM_styleDTO } = YMM_modelDTO[make].reduce(
    (acc, { model, styleList }) => {
      // push the model as a value label pair for options
      acc.YMM_model = [...acc.YMM_model, { value: model, label: model }];

      // update the available styles to choose from based on available models
      acc.YMM_styleDTO[model] = styleList;

      return acc;
    },
    { YMM_model: [], YMM_styleDTO: {} }
  );

  // update options for model and style
  yield put({
    type: 'UPDATE_YMM',
    payload: {
      YMM_model,
      YMM_styleDTO
    }
  });
}

/**
 * Restrict available styles based on selected model
 */
export function* vehicleModelStyleWatcher({ payload: { model } }) {
  const {
    vehicles: {
      YMM: { YMM_styleDTO }
    }
  } = yield select();

  // reduce over all styles for the selected model
  const { YMM_style, vinStems } = YMM_styleDTO[model].reduce(
    (acc, { uiStyle, vinStem }) => {
      // update available options for styles
      acc.YMM_style = [...acc.YMM_style, { value: uiStyle, label: uiStyle }];

      // set vin stem for each style
      acc.vinStems[uiStyle] = vinStem;

      return acc;
    },
    { YMM_style: [], vinStems: {} }
  );

  // update options for styles and vin stems
  yield put({
    type: 'UPDATE_YMM',
    payload: {
      YMM_style,
      vinStems
    }
  });
}

/**
 * Save Vin Stem when user adds vehicle with YMMS
 */
export function* saveVINStem({ payload, successRoute }) {
  const {
    vehicles: {
      YMM: { vinStems },
      items,
      editingVehicle
    }
  } = yield select();

  // look up the selected vin stem
  const vinStem = vinStems[payload.style];

  // clear any errors
  // todo: why do we do this?
  yield put({
    type: 'CLEAR_ASYNC_ERRORS'
  });

  // if we are editing a vehicle and the selected vin stem matches the editing vehicle's vin stem
  if (editingVehicle && vinStem === editingVehicle.vinStem) {
    // redirect the user to the next screen
    return yield put({
      type: 'REDIRECT',
      payload: { url: successRoute }
    });
  }
  // if the selected vin stem isn't the same as the editing vehicle's vin stem
  // or we aren't editing a vehicle currently
  try {
    // Send request to Noblr App Services endpoint to get all make, model, styles by year
    const response = yield call(Noblr.vehicle.saveVehicle, {
      vinStem
    });

    // on success, set vin stem on reducer
    yield put({
      type: 'SAVE_VIN_SUCCESS',
      payload: { vinStem }
    });

    // update the editing vehicle with vin stem and style
    yield put({
      type: 'UPDATE_EDITING_VEHICLE',
      payload: {
        ...response,
        // do not change uiStyle or style, these are used for lookups
        uiStyle: response.style,
        // we need to keep the original style for reference in case the returned style is more specific
        style: payload.style,
        vinStem,
        edited: true
      }
    });

    // pass updated vehicle to array of vehicles
    yield put({
      type: 'UPDATE_ASSOCIATED_VEHICLES',
      payload: [...items, response]
    });
  } catch (error) {
    // vin stem is invalid
    if (error.status === 400) {
      yield put({
        type: 'SET_FORM_ERROR',
        error: { status: error.status, ...error.data }
      });
    }

    // set error on app level
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }

  return null;
}

// just calls assignPrimaryCarToDriver with flag to not hit endpoint
export function* setPrimaryVehicleAssignmentLocallySubroutine({
  payload,
  successRoute
}) {
  yield put({
    type: 'SET_PRIMARY_VEHICLE_ASSIGNMENT',
    payload,
    makeApiCall: false,
    successRoute
  });
}

/**
 *
 */
export function* assignPrimaryCarToDriver({
  payload,
  makeApiCall,
  successRoute
}) {
  const {
    drivers: {
      primaryDriver: { driverId: primaryDriverId }
    }
  } = yield select();

  // payload is key value pair of driver id to vehicle id
  const driverIds = Object.keys(payload);

  const assignmentObj = {};

  //  map over the driver ids and build array to send to backend
  const drivers = { primary: {}, additional: {} };
  const vehicleAssignments = driverIds.map(driverId => {
    const vehicleId = payload[driverId];
    const vehicleAssignmentsArray = [{ vehicleId, assignmentType: 'PRIMARY' }];

    assignmentObj[vehicleId] = assignmentObj[vehicleId]
      ? [...assignmentObj[vehicleId], driverId]
      : [driverId];

    // also build drivers object
    if (driverId === primaryDriverId) {
      drivers.primary = { ...drivers.primary, cars: vehicleAssignmentsArray };
    } else {
      drivers.additional = {
        ...drivers.additional,
        [driverId]: { cars: vehicleAssignmentsArray }
      };
    }

    return {
      driverId,
      vehicleAssignments: vehicleAssignmentsArray
    };
  });

  const assignmentArray = Object.keys(assignmentObj).map(vehicleId => ({
    vehicleId,
    driverIds: assignmentObj[vehicleId]
  }));

  try {
    // check if we should make api call from some config passed in action
    if (makeApiCall) {
      yield call(Noblr.vehicle.setVehicleAssignments, vehicleAssignments);
    }
    // we still want to do everything below when we aren't making the api call
    // for each driver, we need to store the vehicle id
    // dispatch action to update primary driver
    yield putResolve({
      type: 'UPDATE_PRIMARY_DRIVER',
      payload: { cars: drivers.primary.cars }
    });

    // dispatch action to update each additional driver
    yield putResolve({
      type: 'UPDATE_ADDITIONAL_DRIVERS',
      payload: drivers.additional
    });
    // pass assignments to success action type to store primarily driven by driver id
    yield put({
      type: 'SET_PRIMARY_VEHICLE_ASSIGNMENT_SUCCESS',
      payload: assignmentArray
    });

    if (!makeApiCall) {
      yield put({
        type: 'REDIRECT',
        payload: { url: successRoute }
      });
    }
  } catch (error) {
    yield put({ type: 'SET_PRIMARY_VEHICLE_ASSIGNMENT_FAILURE', error });

    // set error on app level
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

// payload is vehicle ids mapped to driver ids
export function* assignExcessVehiclesSubroutine({
  payload,
  makeApiCall = true,
  formikActions: { setSubmitting }
}) {
  const vehicleIds = Object.keys(payload);
  const items = Object.entries(payload);
  // get all primary vehicle assignments from redux store
  const {
    drivers: { primaryDriver, items: additionalDrivers }
  } = yield select();

  // build primary assignments by driver id
  const primaryAssignments = [primaryDriver, ...additionalDrivers].reduce(
    (acc, driver) => {
      if (driver.cars) {
        const { driverId } = driver;

        // since this is by driver id i think it will always be 1:1
        acc[driverId] = {
          driverId,
          vehicleAssignments: driver.cars
        };
      }

      return acc;
    },
    {}
  );

  // build assignments by driver id to group multiple vehicles for a single driver
  const assignmentsByDriverId = items.reduce((acc, [vehicleId, driverId]) => {
    const vehicleAssignment = { vehicleId, assignmentType: 'EXCESS' };

    if (!acc[driverId]) {
      acc[driverId] = {
        driverId,
        vehicleAssignments: [vehicleAssignment]
      };
    } else {
      acc[driverId] = {
        ...acc[driverId],
        vehicleAssignments: [
          ...acc[driverId].vehicleAssignments,
          vehicleAssignment
        ]
      };
    }

    return acc;
  }, primaryAssignments); // use primaryAssignments as default so we can combine by driver id

  // convert assignments to an array of objects
  const vehicleAssignments = Object.keys(assignmentsByDriverId).map(
    driverId => assignmentsByDriverId[driverId]
  );

  try {
    if (makeApiCall) {
      yield call(Noblr.vehicle.setVehicleAssignments, vehicleAssignments);
    }

    const excessVehicleAssignments = vehicleIds.map(vehicleId => ({
      vehicleId,
      driverId: payload[vehicleId]
    }));

    yield put({
      type: 'ASSIGN_EXCESS_VEHICLES_SUCCESS',
      payload: excessVehicleAssignments
    });
  } catch (error) {
    yield put({
      type: 'ASSIGN_EXCESS_VEHICLES_FAILURE',
      error
    });

    // set error on app level
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  } finally {
    if (setSubmitting) {
      setSubmitting();
    }
  }
}

/**
 * This saga is used to store assignments by type when quote is retrieved
 * @param {*} param0
 */
export function* assignVehiclesSubroutine({ payload }) {
  // payload is object of driver ids to vehicle assignments by type
  // build payload for primary assignments
  const driverIds = Object.keys(payload);

  const vehicleAssignments = driverIds.reduce(
    (acc, driverId) => {
      const { assignments } = payload[driverId];

      // we can have assignments.primary and assignments.excess for each driver
      if (assignments.primary) {
        // since this is by driver, there can only be one primary car per driver
        const primaryVehicle = assignments.primary[0].vehicleId;

        acc.primary[driverId] = primaryVehicle;
      }

      // dont' use an else here, they can have both
      if (assignments.excess) {
        // excess assignments are saved with vehicle id as the key and driver id as value
        // we need to store them in the excess object with vehicle to driver id

        // TODO: Replace forEach with map
        assignments.excess.forEach(({ vehicleId }) => {
          acc.excess[vehicleId] = driverId;
        });
      }

      return acc;
    },
    { primary: {}, excess: {} }
  );

  // we will always have vehicleAssignments.primary so dispatch action to store primary assignments
  yield put({
    type: 'SET_PRIMARY_VEHICLE_ASSIGNMENT',
    payload: vehicleAssignments.primary,
    makeApiCall: false
  });

  // then if we have excess assignments also store those
  if (vehicleAssignments.excess) {
    // make sure we don't make api call here too
    yield put({
      type: 'ASSIGN_EXCESS_VEHICLES',
      // excess vehicle payload is vehicle ids to driver ids
      payload: vehicleAssignments.excess,
      makeApiCall: false,
      formikActions: { setSubmitting: null }
    });
  }
}
