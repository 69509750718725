import React from 'react';
import styled from 'styled-components/macro';
import Accordion from 'components/Accordion';
import { separateThousandthsWithComma } from 'utilities';
import {
  SectionHeader,
  StyledText,
  StyledMessage,
  colors,
  openSans,
  brandonGrotesque
} from 'styles';

const TableRow = styled.tr`
  margin: 10px auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  text-align: left;
  padding-bottom: 10px;
  th,
  td {
    font-size: 14px;
    font-family: ${openSans};
    text-align: left;
    color: ${colors.coreBlue700};
    letter-spacing: 0.99px;
    margin: 10px 0;
  }

  th {
    flex: 1 0 60%;
    line-height: 1.71;
    height: 24px;
    font-weight: normal;
    .bolded {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  td {
    font-weight: 600;
    flex: 1 40%;
    text-align: left;
    height: 20px;
    line-height: 1.43;
  }
`;

const TableBody = styled.tbody`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
`;

const TableRowHeader = styled.th`
  text-align: left;
  font-family: ${brandonGrotesque};
  color: ${colors.coreBlue700};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.93px;
  line-height: 1.7;

  &.bolded {
    font-weight: 500;
  }
`;

const ContentTable = styled.table`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
`;

const CustomHeader = styled(TableRowHeader)`
  text-transform: uppercase;
  .bolded {
    font-weight: 500;
  }
`;

const SectionWrapper = styled.div`
  margin: 50px 0;
  text-align: left;
  font-family: ${openSans};
  padding-bottom: 45px;
  border-bottom: 1px solid ${colors.usaaLightGrey};
`;

const SubHeader = styled.h4`
  margin-bottom: 10px;
  color: ${colors.coreBlue700};
  font-weight: 600;
  font-size: 18px;
  font-family: ${openSans};
`;

const Content = styled.div`
  margin: 20px auto;
`;

function PaymentToday({ fixedPrice, membersContribution, totalPrice }) {
  const formattedFixedPrice = separateThousandthsWithComma(fixedPrice);
  const formattedMembersContribution =
    separateThousandthsWithComma(membersContribution);

  const formattedTotalPrice = separateThousandthsWithComma(totalPrice);

  return (
    <ContentTable>
      <TableBody>
        <TableRow>
          <CustomHeader>Fixed rate</CustomHeader>
          <td>{`$${formattedFixedPrice}`}</td>
        </TableRow>
        <TableRow>
          <CustomHeader>Membership Contribution</CustomHeader>
          <td>{`$${formattedMembersContribution}`}</td>
        </TableRow>
        <TableRow>
          <CustomHeader className="bolded">Total</CustomHeader>
          <td>{`$${formattedTotalPrice}`}</td>
        </TableRow>
      </TableBody>
    </ContentTable>
  );
}

// todo: rename this since we have a component with the same name
function PaymentSchedule({ payments }) {
  const formattedPaymentScheduleAmount = payments.map(payment => ({
    dueDate: payment.dueDate,
    amount: separateThousandthsWithComma(payment.amount),
    installmentNumber: payment.installmentNumber
  }));

  return (
    <ContentTable>
      <TableBody>
        {formattedPaymentScheduleAmount.map((payment, index) => (
          <TableRow key={payment.dueDate}>
            <TableRowHeader>{payment.dueDate}</TableRowHeader>
            <td>{`$${payment.amount} ${
              index !== 0 ? ' + Variable Rate' : ''
            }`}</td>
          </TableRow>
        ))}
      </TableBody>
    </ContentTable>
  );
}

function PaymentSummary({
  cardType,
  lastFour,
  totalPrice,
  fixedPrice,
  membersContribution,
  payments,
  paymentPlan
}) {
  // set up accordions
  const paymentScheduleAccordion = [
    {
      key: 'paymentScheduleAccordion',
      title: (
        <StyledText>
          {paymentPlan === 'Installments'
            ? 'Everything Monthly'
            : 'Fixed Rate Upfront'}
        </StyledText>
      ),
      content: (
        <>
          <StyledMessage>
            {`Here's what your payment schedule looks like for today and the next 5 payments. Your membership contribution will always be separate from your fixed rate. Your variable rate will vary based on how and how much you drive, and based on the amount you save in Noblr Rewards.`}
          </StyledMessage>
          {payments && <PaymentSchedule payments={payments} />}
        </>
      )
    }
  ];

  const formattedTotalPrice = separateThousandthsWithComma(totalPrice);

  const totalPaymentAccordion = [
    {
      key: 'totalPaymentAccordion',

      title: <StyledText>{`$${formattedTotalPrice}`}</StyledText>,
      content: (
        <PaymentToday
          fixedPrice={fixedPrice}
          membersContribution={membersContribution}
          totalPrice={totalPrice}
        />
      )
    }
  ];

  return (
    <SectionWrapper>
      <SectionHeader>Payment Summary</SectionHeader>
      <Content>
        <SubHeader>Total Payment Today</SubHeader>
        <Accordion sections={totalPaymentAccordion} noMargin />
      </Content>
      <Content>
        <SubHeader>Payment Method</SubHeader>
        <StyledText>{`${cardType} ending in ${lastFour}`}</StyledText>
      </Content>
      <Content>
        <SubHeader>Payment Option and Schedule</SubHeader>
        <Accordion sections={paymentScheduleAccordion} noMargin />
      </Content>
    </SectionWrapper>
  );
}

export default PaymentSummary;
