import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditListCreator from 'components/ListEditor/EditListCreator';
import EditListItem from 'components/ListEditor/EditListItem';
import Sticky from 'components/Modifiers/Sticky';
import Button from 'components/Buttons/Button';
import { clearEditingVehicle } from 'redux/actions';
import { groupCompletedItems } from 'utilities';
import { groupActiveVehicles } from 'utilities/vehicles';
import {
  ConfirmationContainer,
  Prompt,
  AddItemButton,
  BorderedTextButtonWrapper,
  StyledContentWrapper
} from 'styles';

class VehiclesListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onContinueRoute: '/add-vehicles/ride-share',
      addNewVehicleRoute: '/add-vehicles/add-new-vehicle',
      confirmVehicleRoute: '/add-vehicles/vehicle-ownership'
    };
  }

  componentDidMount() {
    const { vehicles } = this.props;

    if (vehicles.length === 0) {
      this.addNewVehicle();
    }
  }

  addNewVehicle = () => {
    const { history, clearEditingVehicle, editingVehicle } = this.props;
    const { addNewVehicleRoute } = this.state;

    if (editingVehicle) {
      clearEditingVehicle();
    }

    history.push({
      pathname: addNewVehicleRoute
    });
  };

  handleContinue = () => {
    const { history } = this.props;
    const { onContinueRoute } = this.state;

    history.push({
      pathname: onContinueRoute
    });
  };

  renderVehicleList = () => {
    const { confirmVehicleRoute } = this.state;
    const { history, editedVehicles, asyncRequest, savingVehicle, vehicles } =
      this.props;

    const EditListLineItem = EditListCreator(EditListItem, 'vehicles');

    const vehiclesByActiveBit = groupActiveVehicles(vehicles);
    // TODO: create memoized selector for vehicle counts
    const atMax = vehiclesByActiveBit.active.length === 6;
    const noVehicles = !vehiclesByActiveBit.active.length;

    const groupedActiveVehicles = groupCompletedItems(
      vehiclesByActiveBit.active,
      'vehicle'
    );

    const sortedVehicles = [
      ...groupedActiveVehicles.complete,
      ...groupedActiveVehicles.incomplete,
      ...vehiclesByActiveBit.inactive
    ];

    return (
      <>
        {sortedVehicles.map(vehicle => (
          <EditListLineItem
            key={vehicle.vehicleId}
            item={vehicle}
            editedVehicles={editedVehicles}
            confirmItemRoute={confirmVehicleRoute}
            history={history}
          />
        ))}
        {!atMax && (
          <BorderedTextButtonWrapper>
            <AddItemButton type="button" onClick={this.addNewVehicle}>
              <img
                src={`${process.env.REACT_APP_NOBLR_CDN}/icons/circular-outline-plus-sign-icon.svg`}
                height="20px"
                width="20px"
                alt=""
              />
              Add New Car
            </AddItemButton>
          </BorderedTextButtonWrapper>
        )}
        <Sticky marginTop="60px">
          <Button
            primary
            large
            onClick={this.handleContinue}
            disabled={
              savingVehicle ||
              (!asyncRequest &&
                (groupedActiveVehicles.incomplete.length || noVehicles))
            }
            id="continue-button"
          >
            Continue
          </Button>
        </Sticky>
      </>
    );
  };

  render() {
    const renderedList = this.renderVehicleList();

    return (
      <StyledContentWrapper>
        <ConfirmationContainer>
          <Prompt>Add the cars you want covered.</Prompt>
          {renderedList}
        </ConfirmationContainer>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { asyncRequest },
  vehicles: { items, editedVehicles, editingVehicle, savingVehicle, error }
}) => ({
  asyncRequest,
  vehicles: items,
  editedVehicles,
  editingVehicle,
  savingVehicle,
  error
});

const mapDispatchToProps = dispatch => ({
  clearEditingVehicle: () => dispatch(clearEditingVehicle())
});

VehiclesListPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  asyncRequest: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  clearEditingVehicle: PropTypes.func.isRequired,
  savingVehicle: PropTypes.bool.isRequired,
  editingVehicle: PropTypes.shape({}).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editedVehicles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesListPage);
