import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';
import { EDUCATION_LEVEL_OPTIONS } from '../../constants';

class EducationPage extends Component {
  constructor(props) {
    super(props);

    const { primaryDriver } = this.props;

    this.state = {
      prompt: `What's the highest level of education you've completed?`,
      submitAction: 'UPDATE_PRIMARY_DRIVER_REQUEST',
      fields: [
        {
          type: 'select',
          name: 'education',
          id: 'education-select',
          selectLabel: 'Education Achieved',
          selectLabelId: 'education-select-label',
          options: EDUCATION_LEVEL_OPTIONS,
          required: true,
          initialValue: primaryDriver.education || 'College degree'
        }
      ],
      successRoute: '/start-quote/marital-status'
    };
  }

  render() {
    const { prompt, submitAction, fields, successRoute } = this.state;

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          makeApiCall
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({ drivers: { primaryDriver } }) => ({
  primaryDriver
});

EducationPage.propTypes = {
  primaryDriver: PropTypes.shape({
    education: PropTypes.string.isRequired
  })
};

EducationPage.defaultProps = {
  primaryDriver: {
    education: ''
  }
};

export default connect(mapStateToProps)(EducationPage);
