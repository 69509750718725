import styled, { css } from 'styled-components/macro';
import { Grid } from '@material-ui/core';
import { breakPoints, colors, brandonGrotesque, openSans } from './variables';
import { Prompt } from './headers';

const NO_MARGIN_PAGES = [
  '/purchase/review-and-pay',
  '/quote/review-your-quote',
  '/start-quote/lets-go',
  '/start-quote/welcome',
  '/dnq/agency'
];

export const BodyWrapper = styled.div`
  margin: ${props => (NO_MARGIN_PAGES.includes(props.path) ? '0' : '0 auto')};
  height: 100%;
  width: 100%;
  min-height: calc(100vh - 260px);
  display: flex;
  flex-flow: column nowrap;
  align-self: center;

  @media only screen and (min-width: ${breakPoints.small}) {
    min-width: 100%;
  }
  @media only screen and (min-width: ${breakPoints.medium}) {
    margin: ${props => (NO_MARGIN_PAGES.includes(props.path) ? '0' : '0 auto')};
    min-width: 100%;
  }
  & > div {
    text-align: center;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }

  .fade-exit {
    opacity: 0;
  }
`;

export const TwoColumn = styled.div`
  @media screen and (min-width: ${breakPoints.medium}) {
    > *,
    a {
      display: inline-block;
    }
  }
`;

export const EditListItemWrapper = styled.div`
  margin: 15px auto;
  padding: 20px 15px;
  width: 100%;
  height: auto;
  min-height: ${props => (props.$isPrimaryDriver ? '165px' : '235px')};
  max-height: ${props => (props.$isPrimaryDriver ? '165px' : '235px')};
  min-width: 350px;
  max-width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  border: 2px solid ${colors.usaaLightestGrey};
  border-radius: 4px;
  box-sizing: border-box;
  transition: background-color 0.2s ease;
  ${props =>
    props.$added &&
    css`
      border-color: ${colors.coreBlue700};
    `}

  ${props =>
    (props.$removed || props.$excluded) &&
    css`
      border: 2px solid ${colors.usaaLightestGrey};
      background-color: ${colors.usaaLightestGrey};
    `}
`;

export const AddItemButton = styled.button`
  padding: 0; /* style reset */
  height: auto;
  width: auto;
  font-family: ${brandonGrotesque};
  font-size: 18px;
  color: ${colors.alphaBlue500};
  letter-spacing: 1px;
  font-weight: normal;
  text-transform: uppercase;
  text-decoration: none;
  border: 0; /* style reset */
  background: none; /* style reset */
  box-shadow: none; /* style reset */
  cursor: pointer;
`;

export const BorderedTextButtonWrapper = styled(EditListItemWrapper)`
  height: 65px;
  min-height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.usaaLightestGrey};
  border-radius: 4px;

  img {
    margin-right: 15px;
    vertical-align: sub;
  }
`;

export const ConfirmationContainer = styled.div`
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;

  @media only screen (min-width: ${breakPoints.small}) and (max-width: ${breakPoints.medium}) {
    margin: 0 20px;
  }

  @media only screen and (min-width: ${breakPoints.medium}) {
    button:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const ListContainer = styled.div`
  padding: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;

  @media only screen and (min-width: ${breakPoints.medium}) {
    padding: 0;
    button:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const Desktop = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  @media only screen and (max-width: ${breakPoints.small}) {
    display: none;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media only screen and (max-width: ${breakPoints.small}) {
    height: auto;
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
`;

export const StyledContentWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-content: center;
`;

export const CoverageRow = styled(Grid)`
  margin: 30px auto;
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid ${colors.usaaLightestGrey};

  ${props =>
    props.$editable &&
    css`
      padding: 20px 10px;
    `}

  @media only screen and (max-width: ${breakPoints.small}) {
    padding: 20px 5px;

    ${props =>
      props.$editable &&
      css`
        flex-flow: column nowrap;
      `}
  }

  ${props =>
    props.$hideborder &&
    css`
      border: none;
    `}

  ${props =>
    props.$filled &&
    css`
      background-color: ${colors.usaaLightestGrey};
    `}

   ${props =>
    props.$column &&
    css`
      flex-flow: column nowrap;
      flex-direction: column-reverse;
    `}

     ${props =>
    props.$hide &&
    css`
      padding: 0;
      border-bottom: none;

      > * {
        display: none;
      }
    `}
`;

/* Quote containers */
export const StyledQuoteSection = styled.div`
  padding: 50px 30px 40px 32px; /* Taken directly from Zeplin Mock */
  margin: 0 auto;
  width: 100%;
  max-width: 470px;
  border-radius: 4px;
  &:first-of-type {
    border: 2px solid ${colors.usaaLightestGrey};
  }

  max-width: 700px;

  @media only screen and (min-width: ${breakPoints.medium}) {
    max-width: 710px;
  }

  button.try-button {
    padding: 8px 68px;
    width: 270px;
    margin-top: 0;
    font-size: 20px;
    box-sizing: border-box;
  }

  p.trial-text {
    margin: 0 auto 15px;
    max-width: 345px;
    line-height: 1.5;
  }

  ${props =>
    props.greyBackground &&
    css`
      padding: 0 10px;
      background-color: ${colors.usaaLightestGrey};
      border: 2px solid ${colors.usaaLightestGrey};
      border-top: none;
    `}

  ${props =>
    props.addSpacing &&
    css`
      @media screen and (min-width: ${breakPoints.medium}) {
        margin-top: 85px;
      }
    `}

    ${props =>
    props.marginBottom &&
    css`
      margin-bottom: 30px;
    `}
`;

export const StyledQuoteContainer = styled.div`
  margin-top: 2px;

  .main-quote-container {
    width: 100%;

    @media only screen and (min-width: ${breakPoints.medium}) {
      background-color: ${colors.usaaLightestGrey};

      &.trial-offset {
        ${StyledQuoteSection} {
          top: -10px;
        }
      }
    }
  }

  img {
    max-width: 95%;
  }
  p.subheader {
    margin: 0 auto 20px;
    padding: 0 20px;
    max-width: 600px;
    font-family: ${brandonGrotesque};
    font-size: 24px;
  }

  p {
    &.loading-text {
      margin-top: 5px;
      font-size: 18px;
      line-height: 1.78;
      font-family: ${openSans};
      @media screen and (min-width: ${breakPoints.medium}) {
        margin-top: 40px;
      }
    }
  }

  .pixels-container {
    display: none;
  }

  .title-text {
    text-transform: uppercase;
    color: ${colors.coreBlue700};
  }

  ${props =>
    props.fetching &&
    css`
      padding-top: 50px;
      ${Prompt} {
        margin-bottom: 0;
      }
      @media screen and (min-width: ${breakPoints.medium}) {
        ${Prompt} {
          margin-bottom: 65px;
        }
      }
    `}

  ${props =>
    props.greyBackground &&
    css`
      background-color: ${colors.usaaLightestGrey};
    `}
`;

export const ButtonWrapper = styled.div`
  margin-top: ${props => (props.marginTop ? props.marginTop : '30px')};
  display: flex;
  justify-content: center;
`;

export const CenteredLoaderContainer = styled.div`
  height: 100%;
  min-height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const ExpandableTextWrapper = styled.div`
  margin: ${props => (props.firstItem ? '20px 10px 10px' : '0 10px 10px')};
  display: flex;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-family: ${brandonGrotesque};
    font-weight: normal;
    line-height: normal;
    :last-child {
      text-align: right;
    }
  }

  :last-child {
    margin-bottom: 25px;
  }
`;

export const PageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255);
`;
