export const UPDATE_POLICY = 'UPDATE_POLICY';

export const CREATE_QUOTE = 'CREATE_QUOTE';

export const UPDATE_PREBIND_RATE_FIELDS = 'UPDATE_PREBIND_RATE_FIELDS';
export const UPDATE_PREBIND_RATE_FIELDS_FAILURE =
  'UPDATE_PREBIND_RATE_FIELDS_FAILURE';
export const UPDATE_PREBIND_RATE_FIELDS_SUCCESS =
  'UPDATE_PREBIND_RATE_FIELDS_SUCCESS';

export const GET_PREBIND_APPLICATION = 'GET_PREBIND_APPLICATION';
export const GET_MEMBERSHIP_APPLICATION = 'GET_MEMBERSHIP_APPLICATION';
export const SIGN_MEMBERSHIP_APPLICATION = 'SIGN_MEMBERSHIP_APPLICATION';
export const SIGN_INSURANCE_APPLICATION = 'SIGN_INSURANCE_APPLICATION';

export const SET_PENDING_FORMS = 'SET_PENDING_FORMS';

export const GET_UMUIM_FORM = 'GET_UMUIM_FORM';
export const SIGN_UMUIM_FORM = 'SIGN_UMUIM_FORM';

export const SIGN_UMBI_FORM = 'SIGN_UMBI_FORM';
export const SIGN_UIMBI_FORM = 'SIGN_UIMBI_FORM';

export const SIGN_TORT_OPTIONS_FORM = 'SIGN_TORT_OPTIONS_FORM';

export const SET_INSTALLMENT_SCHEDULE = 'SET_INSTALLMENT_SCHEDULE';
export const SET_INSTALLMENT_SCHEDULE_SUCCESS =
  'SET_INSTALLMENT_SCHEDULE_SUCCESS';
export const SET_INSTALLMENT_SCHEDULE_FAILURE =
  'SET_INSTALLMENT_SCHEDULE_FAILURE';

export const SAVE_PAYMENT_INFO = 'SAVE_PAYMENT_INFO';
export const SET_CARD_TYPE = 'SET_CARD_TYPE';

export const GET_INSTALLMENT_SCHEDULE_SUCCESS =
  'GET_INSTALLMENT_SCHEDULE_SUCCESS';
export const GET_INSTALLMENT_SCHEDULE_FAILURE =
  'GET_INSTALLMENT_SCHEDULE_FAILURE';

export const UPDATE_PHONE_AND_EMAIL = 'UPDATE_PHONE_AND_EMAIL';
export const UPDATE_PHONE_AND_EMAIL_SUCCESS = 'UPDATE_PHONE_AND_EMAIL_SUCCESS';
export const UPDATE_PHONE_AND_EMAIL_FAILURE = 'UPDATE_PHONE_AND_EMAIL_FAILURE';

export const BIND_QUOTE = 'BIND_QUOTE';
export const BIND_QUOTE_SUCCESS = 'BIND_QUOTE_SUCCESS';
export const BIND_QUOTE_FAILURE = 'BIND_QUOTE_FAILURE';

export const UPDATE_TO_TRIAL = 'UPDATE_TO_TRIAL';
export const UPDATE_TO_TRIAL_SUCCESS = 'UPDATE_TO_TRIAL_SUCCESS';
export const UPDATE_TO_TRIAL_FAIL = 'UPDATE_TO_TRIAL_FAIL';

export const SEND_REFUSAL_TO_WRITE_EMAIL = 'SEND_REFUSAL_TO_WRITE_EMAIL';
export const SEND_REFUSAL_TO_WRITE_EMAIL_SUCCESS =
  'SEND_REFUSAL_TO_WRITE_EMAIL_SUCCESS';
export const SEND_REFUSAL_TO_WRITE_EMAIL_FAILURE =
  'SEND_REFUSAL_TO_WRITE_EMAIL_FAILURE';

export const REQUEST_PREBIND_PDF = 'REQUEST_PREBIND_PDF';
export const REQUEST_PREBIND_PDF_SUCCESS = 'REQUEST_PREBIND_PDF_SUCCESS';
export const REQUEST_PREBIND_PDF_FAILURE = 'REQUEST_PREBIND_PDF_FAILURE';

export const SUBMIT_PREBIND_SIGNATURE = 'SUBMIT_PREBIND_SIGNATURE';
export const SUBMIT_PREBIND_SIGNATURE_SUCCESS =
  'SUBMIT_PREBIND_SIGNATURE_SUCCESS';
export const SUBMIT_PREBIND_SIGNATURE_FAILURE =
  'SUBMIT_PREBIND_SIGNATURE_FAILURE';

export const CLEAR_PDF = 'CLEAR_PDF';

export const GET_DYNAMIC_PRE_BIND_FORM_BY_ID =
  'GET_DYNAMIC_PRE_BIND_FORM_BY_ID';
export const GET_DYNAMIC_PRE_BIND_FORM_BY_ID_SUCCESS =
  'GET_DYNAMIC_PRE_BIND_FORM_BY_ID_SUCCESS';
export const GET_DYNAMIC_PRE_BIND_FORM_BY_ID_FAILURE =
  'GET_DYNAMIC_PRE_BIND_FORM_BY_ID_FAILURE';

export const SUBMIT_FORM_ID_SIGNATURE = 'SUBMIT_FORM_ID_SIGNATURE';
export const SUBMIT_FORM_ID_SIGNATURE_SUCCESS =
  'SUBMIT_FORM_ID_SIGNATURE_SUCCESS';
export const SUBMIT_FORM_ID_SIGNATURE_FAILURE =
  'SUBMIT_FORM_ID_SIGNATURE_FAILURE';

export const REQUEST_DYNAMIC_PRE_BIND_FORMS = 'REQUEST_DYNAMIC_PRE_BIND_FORMS';
export const REQUEST_DYNAMIC_PRE_BIND_FORMS_SUCCESS =
  'REQUEST_DYNAMIC_PRE_BIND_FORMS_SUCCESS';
export const REQUEST_DYNAMIC_PRE_BIND_FORMS_FAILURE =
  'REQUEST_DYNAMIC_PRE_BIND_FORMS_FAILURE';

export const updatePolicy = values => ({
  type: UPDATE_POLICY,
  payload: values
});

export const createQuote = values => ({
  type: CREATE_QUOTE,
  payload: values
});

export const updatePrebindRateFields = () => ({
  type: UPDATE_PREBIND_RATE_FIELDS
});

export const updatePrebindRateFieldsSuccess = () => ({
  type: UPDATE_PREBIND_RATE_FIELDS_SUCCESS
});

export const updatePrebindRateFieldsFailure = error => ({
  type: UPDATE_PREBIND_RATE_FIELDS_FAILURE,
  error
});

export const getPrebindApplication = () => ({
  type: GET_PREBIND_APPLICATION
});

export const getPrebindMembershipApplication = () => ({
  type: GET_PREBIND_APPLICATION
});

export const getUMUIMForm = () => ({
  type: GET_UMUIM_FORM
});

export const signInsuranceApplication = ({
  fieldName,
  signedName,
  setSubmitting
}) => ({
  type: SIGN_INSURANCE_APPLICATION,
  payload: { fieldName, signedName, setSubmitting }
});

export const signMembershipApplication = ({
  fieldName,
  signedName,
  setSubmitting
}) => ({
  type: SIGN_MEMBERSHIP_APPLICATION,
  payload: { fieldName, signedName },
  setSubmitting
});

export const signUMUIMForm = data => ({
  type: SIGN_UMUIM_FORM,
  payload: data
});

export const signUMBIForm = data => ({
  type: SIGN_UMBI_FORM,
  payload: data
});

export const signUIMBIForm = data => ({
  type: SIGN_UIMBI_FORM,
  payload: data
});

export const signTortOptionsForm = data => ({
  type: SIGN_TORT_OPTIONS_FORM,
  payload: data
});

export const setInstallmentSchedule = data => ({
  type: SET_INSTALLMENT_SCHEDULE,
  payload: data
});

export const setInstallmentScheduleSuccess = data => ({
  type: SET_INSTALLMENT_SCHEDULE_SUCCESS,
  payload: data
});

export const setInstallmentScheduleFailure = error => ({
  type: SET_INSTALLMENT_SCHEDULE_FAILURE,
  error
});

export const savePaymentInfo = (data, formikActions) => ({
  type: SAVE_PAYMENT_INFO,
  payload: data,
  formikActions
});

export const updatePhoneAndEmailSuccess = () => ({
  type: UPDATE_PHONE_AND_EMAIL_SUCCESS
});

export const updatePhoneAndEmailFailure = error => ({
  type: UPDATE_PHONE_AND_EMAIL_FAILURE,
  error
});

export const bindQuote = () => ({
  type: BIND_QUOTE
});

export const updateToTrial = () => ({
  type: UPDATE_TO_TRIAL
});

export const updateToTrialSuccess = policyId => ({
  type: UPDATE_TO_TRIAL_SUCCESS,
  payload: policyId
});

export const sendRefusalToWriteEmail = email => ({
  type: SEND_REFUSAL_TO_WRITE_EMAIL,
  payload: email
});

export const sendRefusalToWriteEmailSuccess = () => ({
  type: SEND_REFUSAL_TO_WRITE_EMAIL_SUCCESS
});

export const sendRefusalToWriteEmailFailure = error => ({
  type: SEND_REFUSAL_TO_WRITE_EMAIL_FAILURE,
  error
});

export const requestPreBindPDF = pdfName => ({
  type: REQUEST_PREBIND_PDF,
  payload: pdfName
});

export const requestPreBindPDFSuccess = (pdfName, blob) => ({
  type: REQUEST_PREBIND_PDF_SUCCESS,
  payload: { pdfName, blob }
});

export const requestPreBindPDFFailure = error => ({
  type: REQUEST_PREBIND_PDF_FAILURE,
  error
});

export const submitPrebindSignature = pdfName => ({
  type: SUBMIT_PREBIND_SIGNATURE,
  payload: { pdfName }
});

export const submitPrebindSignatureSuccess = ({
  pdfName,
  formikActions,
  makeApiCall,
  successRoute
}) => ({
  type: SUBMIT_PREBIND_SIGNATURE_SUCCESS,
  payload: pdfName,
  formikActions,
  makeApiCall,
  successRoute
});

export const submitPrebindSignatureFailure = error => ({
  type: SUBMIT_PREBIND_SIGNATURE_FAILURE,
  payload: { error }
});

export const clearPDF = pdfName => ({
  type: CLEAR_PDF,
  payload: pdfName
});

export const getDynamicPreBindFormById = (formId, pdfName) => ({
  type: GET_DYNAMIC_PRE_BIND_FORM_BY_ID,
  payload: { formId, pdfName }
});

export const getDynamicPreBindFormByIdSuccess = (pdfName, blob) => ({
  type: GET_DYNAMIC_PRE_BIND_FORM_BY_ID_SUCCESS,
  payload: { pdfName, blob }
});

export const getDynamicPreBindFormByIdFailure = error => ({
  type: GET_DYNAMIC_PRE_BIND_FORM_BY_ID_FAILURE,
  error
});

export const setPendingForms = (
  pendingFormIds,
  currentFormId,
  nextFormId,
  customPreBindForms
) => ({
  type: SET_PENDING_FORMS,
  payload: {
    pendingFormIds,
    currentFormId,
    nextFormId,
    customPreBindForms
  }
});

export const submitFormIdSignature = (formId, signedName) => ({
  type: SUBMIT_FORM_ID_SIGNATURE,
  payload: { formId, signedName }
});

export const submitFormIdSignatureSuccess = pdfName => ({
  type: SUBMIT_FORM_ID_SIGNATURE_SUCCESS,
  payload: { pdfName }
});

export const submitFormIdSignatureFailure = error => ({
  type: SUBMIT_FORM_ID_SIGNATURE_FAILURE,
  error
});

export const requestDynamicPreBindForms = () => ({
  type: REQUEST_DYNAMIC_PRE_BIND_FORMS
});

export const requestDynamicPreBindFormsSuccess = data => ({
  type: REQUEST_DYNAMIC_PRE_BIND_FORMS_SUCCESS,
  payload: data
});

export const requestDynamicPreBindFormsFailure = error => ({
  type: REQUEST_DYNAMIC_PRE_BIND_FORMS_FAILURE,
  error
});
