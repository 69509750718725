import { createGlobalStyle } from 'styled-components/macro';
import { colors, breakPoints, brandonGrotesque } from './variables';

/*
  Typography sizes scale according to "perfect-fourth"
  For more details: https://www.modularscale.com/?1&em&1.333
*/
const GlobalStyle = createGlobalStyle`

  p {
    line-height: 1.5;
    color: ${colors.coreBlue700};
  }

  a {
      border-bottom: 1px solid ${colors.coreBlue700};
  }

  h1, h2, h3, h4, h5 {
     font-family: ${brandonGrotesque};
     font-weight: 400;
     line-height: 1.5;
     color: ${colors.coreBlue700};
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 24px;
  }

  a {
    text-decoration: none;
    border-bottom: ${colors.coreBlue700};
    color: ${colors.coreBlue700};
  }
  button {
    font-family: ${brandonGrotesque};
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
  }

  @media screen and (max-width: ${breakPoints.medium}) {
    .react-pdf__Document {
      overflow-y: hidden !important;
    }

    canvas.react-pdf__Page__canvas {
      width: 100% !important;
      height: auto !important;
    }

    .react-pdf__Page__textContent {
      width: 100% !important;
      overflow-x: hidden !important;
    }

    .zEWidget-launcher--active {
      top: -5px !important;
      left: -10px !important;
      bottom: auto !important;
    }
  }

  .hidden {
    display: none;
  }

  .content-wrapper {
    margin: 0 auto;
    display: block;
    a.outer {
      display: none;
    }

    @media only screen and (max-width: ${breakPoints.extraSmall})  {
      height: auto;
      margin: auto 10px;
      flex-flow: column nowrap;

      div > img {

        align-self: center;
      }

      div > a {
        display: none;
      }

      a.outer {
        display: inline-block;
      }
    }

    @media only screen and (min-width: ${breakPoints.extraLarge}) {
      height: 100%;
      width: 95%;
      display: flex;

      div > img {
        align-self: center;
      }

      a.outer {
        display: none;
      }
    }
  }

`;

export default GlobalStyle;
