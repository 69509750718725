import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import PageHeader from 'components/PageHeader';
import LinkButton from 'components/Buttons/LinkButton';
import Sticky from 'components/Modifiers/Sticky';
import Loader from 'components/Loader';
import {
  makeSelectCurrentExcludedDriver,
  makeSelectRemovedDrivers,
  makeSelectExcludedDrivers
} from 'redux/selectors';
import { Prompt } from 'styles';

const StartPageGrid = styled(Grid)`
  margin: 100px 0 0;
`;

function Start(props) {
  const {
    location: { pathname },
    history
  } = props;

  const prevLocation = useRef(pathname);
  const unListen = useRef();
  const selectCurrentExcludedDriver = useMemo(
    makeSelectCurrentExcludedDriver,
    []
  );

  const dispatch = useDispatch();

  // Pull excluded driver from state
  const excludedDriver = useSelector(state =>
    selectCurrentExcludedDriver(state)
  );

  const excludedDriverIds = useSelector(
    state => state.excludedDrivers.excludedDriverIds
  );

  const driverNamesObfuscated = useSelector(
    state => state.excludedDrivers.driverNamesObfuscated
  );

  const loading = useSelector(state => state.app.loading);

  // memoize makeSelectRemovedDrivers
  const removedDriverSelector = useMemo(makeSelectRemovedDrivers, []);

  const removedDrivers = useSelector(state => removedDriverSelector(state));

  // memoize makeSelectExcludedDrivers
  const excludedDriversSelector = useMemo(makeSelectExcludedDrivers, []);

  const excludedDrivers = useSelector(state => excludedDriversSelector(state));

  useEffect(() => {
    if (driverNamesObfuscated) {
      dispatch({ type: 'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES' });
    }
  }, [driverNamesObfuscated, dispatch]);

  useEffect(() => {
    /* If user navigates to the start page with partially completed excluded driver flow then resets the excluded state */
    if (excludedDriverIds.length > 0) {
      dispatch({ type: 'RESET_EXCLUDED_DRIVERS' });

      const driversPendingExclusion = [...removedDrivers, ...excludedDrivers];
      const hasExcludedDrivers = driversPendingExclusion.length > 0;

      if (hasExcludedDrivers) {
        const byId = driversPendingExclusion.reduce((acc, driver) => {
          if (driver.driverId) {
            acc[driver.driverId] = { ...driver };
          }

          return acc;
        }, {});

        const [driver] = driversPendingExclusion.slice(0, 1);

        const driverIdsPendingExclusion = Object.keys(byId).filter(
          driverId => driverId !== driver.driverId
        );

        const totalDriversPendingExclusion = driversPendingExclusion.length;
        const allDriversExcluded = !hasExcludedDrivers;
        let nextExcludedDriverId = null;

        if (totalDriversPendingExclusion > 1) {
          [nextExcludedDriverId] = driverIdsPendingExclusion;
        }
        dispatch({
          type: 'STORE_DRIVERS_PENDING_EXCLUSION',
          payload: {
            byId,
            driverIdsPendingExclusion,
            totalDriversToExclude: totalDriversPendingExclusion,
            driver,
            allDriversExcluded,
            nextExcludedDriverId
          }
        });
      }
    }
  }, [dispatch, excludedDriverIds.length, excludedDrivers, removedDrivers]);

  const backArrowListener = useCallback(() => {
    if (prevLocation !== pathname) {
      history.go(1);
    }
  }, [history, pathname]);

  useEffect(() => {
    if (prevLocation !== pathname) {
      history.push(pathname, '', '');

      unListen.current = history.listen((newLocation, action) => {
        if (action === 'POP') {
          backArrowListener();
        }
      });
    }

    return unListen.current;
  }, [backArrowListener, pathname, history]);

  if (!excludedDriver) {
    return <Redirect to="/quote/retrieve-your-quote" replace />;
  }

  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <StartPageGrid
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs sm>
          <PageHeader>Driver Exclusion</PageHeader>
          <Prompt>
            {`Let's review people you have removed from your quote.`}
          </Prompt>
        </Grid>
        <Grid
          item
          xs
          sm={8}
          container
          sx={{
            direction: { sm: 'row', xs: 'column' }
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Sticky primaryLinkButton>
            <LinkButton
              primary
              className="primary cta--1"
              id="continue-button"
              routeTo={`/driver-exclusion/${excludedDriver.driverId}/household-member`}
            >
              CONTINUE
            </LinkButton>
          </Sticky>
        </Grid>
      </StartPageGrid>
    </Container>
  );
}

export default Start;
