import React, { Component } from 'react';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';
import InfoBox from 'components/Static/InfoBox';

class VINPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Tell us about the car you want to cover.',
      submitAction: 'SAVE_VIN',
      fields: [
        {
          type: 'text',
          name: 'vin',
          id: 'vin-input',
          label: 'VIN',
          required: true,
          ariaLabel: 'Enter your Vehicle Identification Number or VIN'
        }
      ],
      staticContent: {
        form: [
          {
            type: 'StandaloneLink',
            location: 'form',
            extraData: {
              text: 'Or enter Year, Make, Model',
              link: '/add-vehicles/add-new-vehicle'
            }
          }
        ]
      },
      successRoute: '/add-vehicles/vehicle-ownership'
    };
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          staticContent={form}
        />
        <InfoBox
          extraData={{
            header: 'Where Do I Find My Vehicle Identification Number (VIN)?',
            text: 'You can find your VIN on insurance policy documents (like your ID cards), your car registration, and your car dashboard or driver side door.'
          }}
        />
      </StyledContentWrapper>
    );
  }
}

export default VINPage;
