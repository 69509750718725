/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import Cleave from 'cleave.js/react';

// Forward MUI inputRef to Cleave
function DateCleaveComponent(props) {
  const { inputRef, ...rest } = props;

  // 'Component' is 'Cleave'
  return <Cleave htmlRef={inputRef} {...rest} />;
}

class MaskedDateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maskInput: false,
      shrinkLabel: false
    };

    this.textInputFocus = this.textInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.toggleLabel = this.toggleLabel.bind(this);
    this.handleMaskInputChange = this.handleMaskInputChange.bind(this);
  }

  componentDidMount() {
    const {
      confirmedPII: { dob: dobConfirmedPII },
      field: { name, value },
      form: { initialValues, isValid }
    } = this.props;

    const { shrinkLabel } = this.state;

    if (!shrinkLabel) {
      this.toggleLabel();
    }

    if (
      (isValid && initialValues.length === 0) ||
      (initialValues[name] === value && dobConfirmedPII)
    ) {
      this.setState({
        maskInput: true
      });
    }
  }

  handleMaskInputChange(e) {
    const {
      field: { name },
      form: { setFieldValue }
    } = this.props;

    const { maskInput } = this.state;

    e.preventDefault();
    setFieldValue(name, e.target.value, true);

    if (maskInput) {
      this.setState({ maskInput: false });
    }
  }

  handleInputBlur() {
    const {
      field: { name, value },
      form: { initialValues, setFieldValue, setFieldTouched }
    } = this.props;

    if ((!value || value.length < 10) && initialValues[name]) {
      setFieldValue(name, initialValues[name], false);
      setFieldTouched(name, false, false);
    } else {
      setFieldTouched(name, true, false);
    }

    this.setState({ maskInput: true });
  }

  textInputFocus() {
    const {
      field: { name },
      form: { setFieldValue, setFieldTouched }
    } = this.props;

    setFieldValue(name, '', true);
    setFieldTouched(name, true, false);

    this.setState({
      maskInput: false
    });
  }

  toggleLabel() {
    this.setState(prevState => ({
      shrinkLabel: !prevState.shrinkLabel
    }));
  }

  render() {
    const { shrinkLabel, maskInput } = this.state;
    const {
      forwardedRef,
      field,
      value,
      label,
      id,
      placeholder,
      hidden = false,
      required = true,
      autoComplete,
      options,
      className,
      history: {
        location: { pathname }
      },
      form: { initialValues, isSubmitting, touched }
    } = this.props;

    const section = pathname.split('/')[1];

    // Set attributes on TextField input base component
    const inputProps = {
      'aria-label': label,
      'aria-labelledby': field.name,
      'aria-required': required,
      options,
      required
    };

    const obfuscateDate =
      maskInput &&
      value.length === 10 &&
      initialValues[field.name].length === 10;

    // if value is set but field isn't touched, initial value is set
    if (
      !touched[field.name] &&
      value.length === 10 &&
      initialValues[field.name].length === 10
    ) {
      return (
        <TextField
          label={label}
          className={`fs-exclude ${className}`}
          variant="outlined"
          {...field}
          onFocus={this.textInputFocus}
          type={
            isSubmitting || (field?.name === 'dob' && section === 'start-quote')
              ? 'password'
              : 'text'
          }
        />
      );
    }

    return (
      <TextField
        {...field}
        type={obfuscateDate || isSubmitting ? 'password' : 'text'}
        id={id}
        inputRef={forwardedRef}
        autoComplete={autoComplete}
        value={value}
        label={label}
        className={`fs-exclude ${className}`}
        InputLabelProps={{
          shrink: shrinkLabel,
          htmlFor: id
        }}
        onFocus={this.handleInputFocus}
        onBlur={this.handleInputBlur}
        onChange={this.handleMaskInputChange}
        placeholder={placeholder}
        variant="outlined"
        inputProps={{
          ...inputProps,
          hidden
        }}
        InputProps={{
          // TODO: FIX DUPLICATE PROPS
          inputComponent: DateCleaveComponent
        }}
      />
    );
  }
}

export default React.forwardRef((props, ref) => (
  <MaskedDateInput {...props} forwardedRef={ref} />
));
