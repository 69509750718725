import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/variables';

function Copyright() {
  const currentYear = new Date().getFullYear();

  return (
    <StyledCopyright className="copyright">{`© ${currentYear} Noblr, Inc.`}</StyledCopyright>
  );
}

export default Copyright;

const StyledCopyright = styled.p`
  margin-top: 15px;
  font-size: 10px;
  color: ${colors.coreBlue700};
`;
