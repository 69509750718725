export default {
  name: 'dnq',
  pages: [
    {
      name: 'agency',
      type: 'DoNotQuote',
      maxWidth: 'auto',
      hidePageHeader: true
    },
    {
      name: 'usaa',
      type: 'DoNotQuote',
      maxWidth: 'auto',
      hidePageHeader: true
    },
    {
      name: 'detailed',
      type: 'DoNotQuote',
      maxWidth: 'auto',
      hidePageHeader: true
    },
    {
      name: 'call-to-complete'
    }
  ]
};
