export const GET_PRODUCT_DATA = 'GET_PRODUCT_DATA';
export const GET_PRODUCT_DATA_SUCCESS = 'GET_PRODUCT_DATA_SUCCESS';
export const GET_PRODUCT_DATA_FAILURE = 'GET_PRODUCT_DATA_FAILURE';

export const GET_MILEAGE = 'GET_MILEAGE';
export const GET_MILEAGE_SUCCESS = 'GET_MILEAGE_SUCCESS';
export const GET_MILEAGE_FAILURE = 'GET_MILEAGE_FAILURE';

export const SAVE_MILEAGE = 'SAVE_MILEAGE';
export const SAVE_MILEAGE_SUCCESS = 'SAVE_MILEAGE_SUCCESS';
export const UPDATE_MILEAGE = 'UPDATE_MILEAGE';
export const UPDATE_MILEAGE_SUCCESS = 'UPDATE_MILEAGE_SUCCESS';

export const GET_PERSONA = 'GET_PERSONA';
export const GET_PERSONA_SUCCESS = 'GET_PERSONA_SUCCESS';
export const GET_PERSONA_FAILURE = 'GET_PERSONA_FAILURE';

export const SAVE_PERSONA = 'SAVE_PERSONA';
export const SAVE_PERSONA_SUCCESS = 'SAVE_PERSONA_SUCCESS';
export const SAVE_PERSONA_FAILURE = 'SAVE_PERSONA_FAILURE';
export const UPDATE_PERSONA = 'UPDATE_PERSONA';
export const UPDATE_PERSONA_SUCCESS = 'UPDATE_PERSONA_SUCCESS';
export const UPDATE_PERSONA_FAILURE = 'UPDATE_PERSONA_FAILURE';

export const UPDATE_MILEAGE_AND_PERSONA = 'UPDATE_MILEAGE_AND_PERSONA';
export const UPDATE_MILEAGE_AND_PERSONA_SUCCESS =
  'UPDATE_MILEAGE_AND_PERSONA_SUCCESS';
export const UPDATE_MILEAGE_AND_PERSONA_FAILURE =
  'UPDATE_MILEAGE_AND_PERSONA_FAILURE';

export const GET_COVERAGE = 'GET_COVERAGE';
export const SAVE_COVERAGE = 'SAVE_COVERAGE';
export const SAVE_COVERAGE_SUCCESS = 'SAVE_COVERAGE_SUCCESS';
export const UPDATE_COVERAGE = 'UPDATE_COVERAGE';
export const UPDATE_COVERAGE_SUCCESS = 'UPDATE_COVERAGE_SUCCESS';

export const GET_VEHICLE_DEDUCTIBLES_SUCCESS =
  'GET_VEHICLE_DEDUCTIBLES_SUCCESS';

export const SAVE_MULTIPLE_DEDUCTIBLES = 'SAVE_MULTIPLE_DEDUCTIBLES';
export const SAVE_MULTIPLE_DEDUCTIBLES_SUCCESS =
  'SAVE_MULTIPLE_DEDUCTIBLES_SUCCESS';
export const SAVE_MULTIPLE_DEDUCTIBLES_FAILURE =
  'SAVE_MULTIPLE_DEDUCTIBLES_FAILURE';
export const UPDATE_MULTIPLE_DEDUCTIBLES = 'UPDATE_MULTIPLE_DEDUCTIBLES';
export const UPDATE_MULTIPLE_DEDUCTIBLES_SUCCESS =
  'UPDATE_MULTIPLE_DEDUCTIBLES_SUCCESS';
export const UPDATE_MULTIPLE_DEDUCTIBLES_FAILURE =
  'UPDATE_MULTIPLE_DEDUCTIBLES_FAILURE';

export const GET_PREFILL_COVERAGES = 'GET_PREFILL_COVERAGES';
export const SET_FILTERED_COVERAGES = 'SET_FILTERED_COVERAGES';

export const RESTRICT_TO_UMPD = 'RESTRICT_TO_UMPD';
export const CLEAR_UMPD_RESTRICTION = 'CLEAR_UMPD_RESTRICTION';

export const UPDATE_COVERAGE_RULE_OPTIONS = 'UPDATE_COVERAGE_RULE_OPTIONS';
export const CLEAR_COVERAGE_RULE_OPTIONS = 'CLEAR_COVERAGE_RULE_OPTIONS';

export const UPDATE_COVERAGES = 'UPDATE_COVERAGES';
export const UPDATE_COVERAGES_SUCCESS = 'UPDATE_COVERAGES_SUCCESS';
export const UPDATE_COVERAGES_FAILURE = 'UPDATE_COVERAGES_FAILURE';

export const RESTRICT_UM_OPTIONS = 'RESTRICT_UM_OPTIONS';

export const getProductData = () => ({
  type: GET_PRODUCT_DATA
});

export const getProductDataSuccess = payload => ({
  type: GET_PRODUCT_DATA_SUCCESS,
  payload
});

export const getProductDataFailure = error => ({
  type: GET_PRODUCT_DATA_FAILURE,
  error
});

export const clearCoverageRuleOptions = () => ({
  type: CLEAR_COVERAGE_RULE_OPTIONS
});

export const updateCoverageRuleOptions = options => ({
  type: UPDATE_COVERAGE_RULE_OPTIONS,
  payload: options
});

export const updateCoverages = (values, formConfig, markAsEdited) => ({
  type: UPDATE_COVERAGES,
  payload: {
    coverages: values,
    formConfig,
    edited: markAsEdited
  }
});

export const updateCoveragesSuccess = (coverages, policyCoverageId) => ({
  type: UPDATE_COVERAGES_SUCCESS,
  payload: coverages,
  policyCoverageId
});

export const getMileage = () => ({
  type: GET_MILEAGE
});

export const getMileageSuccess = mileage => ({
  type: GET_MILEAGE_SUCCESS,
  payload: mileage
});

export const getMileageFailure = error => ({
  type: GET_MILEAGE_FAILURE,
  error
});

export const saveMileageRequest = mileage => ({
  type: SAVE_MILEAGE,
  payload: mileage
});

export const saveMileage = mileage => ({
  type: SAVE_MILEAGE,
  payload: mileage
});

export const updateMileageRequest = mileage => ({
  type: UPDATE_MILEAGE,
  payload: mileage
});

export const updateMileage = mileage => ({
  type: UPDATE_MILEAGE,
  payload: mileage
});

export const getPersona = () => ({
  type: GET_PERSONA
});

export const getPersonaSuccess = (personaCode, personaSaved) => ({
  type: GET_PERSONA_SUCCESS,
  payload: { personaCode, personaSaved }
});

export const getPersonaFailure = error => ({
  type: GET_PERSONA_FAILURE,
  error
});

export const savePersona = personaCode => ({
  type: SAVE_PERSONA,
  payload: personaCode
});

export const savePersonaSuccess = personaCode => ({
  type: SAVE_PERSONA_SUCCESS,
  payload: personaCode
});

export const savePersonaFailure = error => ({
  type: SAVE_PERSONA_FAILURE,
  error
});

export const updatePersona = personaCode => ({
  type: UPDATE_PERSONA,
  payload: personaCode
});

export const updatePersonaSuccess = personaCode => ({
  type: UPDATE_PERSONA_SUCCESS,
  payload: personaCode
});

export const updatePersonaFailure = error => ({
  type: UPDATE_PERSONA_FAILURE,
  error
});

export const updateMileageAndPersona = (
  mileage,
  personaCode,
  successRoute
) => ({
  type: UPDATE_MILEAGE_AND_PERSONA,
  payload: { mileage, personaCode },
  successRoute
});

export const updateMileageAndPersonaSuccess = (mileage, personaCode) => ({
  type: UPDATE_MILEAGE_AND_PERSONA_SUCCESS,
  payload: { mileage, personaCode }
});

export const updateMileageAndPersonaFailure = error => ({
  type: UPDATE_MILEAGE_AND_PERSONA_FAILURE,
  error
});

export const getCoverage = () => ({
  type: GET_COVERAGE
});

export const saveCoverageRequest = (packageType, successRoute) => ({
  type: SAVE_COVERAGE,
  payload: { packageType, successRoute }
});

export const saveCoverageSuccess = () => ({
  type: SAVE_COVERAGE_SUCCESS
});

export const updateCoverageRequest = (packageType, successRoute) => ({
  type: UPDATE_COVERAGE,
  payload: { packageType, successRoute }
});

export const updateCoverage = (packageType, successRoute) => ({
  type: UPDATE_COVERAGE,
  payload: { packageType, successRoute }
});

export const getPrefillCoverages = coverages => ({
  type: GET_PREFILL_COVERAGES,
  payload: coverages
});

export const saveMultipleDeductibles = ({
  coverageSelections,
  successRoute,
  setSubmitting
}) => ({
  type: SAVE_MULTIPLE_DEDUCTIBLES,
  payload: coverageSelections,
  successRoute,
  setSubmitting
});

export const saveMultipleDeductiblesSuccess = savedVehicleIds => ({
  type: SAVE_MULTIPLE_DEDUCTIBLES_SUCCESS,
  payload: { savedVehicleIds }
});

export const updateMultipleDeductiblesSuccess = updatedVehicleIds => ({
  type: UPDATE_MULTIPLE_DEDUCTIBLES_SUCCESS,
  payload: { savedVehicleIds: updatedVehicleIds }
});

export const saveMultipleDeductiblesFailure = error => ({
  type: SAVE_MULTIPLE_DEDUCTIBLES_FAILURE,
  error
});

export const updateMultipleDeductibles = ({
  coverageSelections,
  successRoute,
  setSubmitting
}) => ({
  type: UPDATE_MULTIPLE_DEDUCTIBLES,
  payload: coverageSelections,
  successRoute,
  setSubmitting
});

export const updateMultipleDeductiblesFailure = error => ({
  type: UPDATE_MULTIPLE_DEDUCTIBLES_FAILURE,
  error
});

export const getVehicleDeductibles = deductibles => ({
  type: GET_VEHICLE_DEDUCTIBLES_SUCCESS,
  payload: deductibles
});

export const setFilteredCoverages = filteredCoverages => ({
  type: SET_FILTERED_COVERAGES,
  payload: {
    filteredCoverages
  }
});

export const restrictToUmpd = fieldName => ({
  type: RESTRICT_TO_UMPD,
  payload: { fieldName }
});

export const clearUmpdRestriction = fieldName => ({
  type: CLEAR_UMPD_RESTRICTION,
  payload: { fieldName }
});
/**
 *
 * @param {String} requireUMs - constant describing how UM coverages should be restricted
 * allowed options: EXCLUDE_NULL, ONLY_NULL, EXCLUDE_ENHANCED, ONLY_ENHANCED, ADDED_ON, REDUCED_BY
 */
export const restrictUMOptions = requireUMs => ({
  type: RESTRICT_UM_OPTIONS,
  payload: requireUMs
});
