import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Grid, Container } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import Sticky from 'components/Modifiers/Sticky';
import { brandonGrotesque, colors, breakPoints } from 'styles/variables';
import { StyledContentWrapper, ModalHeader } from 'styles';
import Modal from '../../components/Modal';
import {
  makeSelectCurrentExcludedDriver,
  makeSelectLastExcludedDriver
} from '../../redux/selectors';

const ButtonGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const ModalPrimaryButton = styled(Button)`
  margin: ${props => (props.margin ? props.margin : '0.125rem auto')};
  padding: 8px;
  height: auto;
`;

const ContinueButton = React.memo(({ onContinue }) => (
  <Button
    primary
    large
    className="primary cta--1"
    id="continue-button"
    onClick={onContinue}
  >
    CONTINUE
  </Button>
));

const StyledContainer = styled(Container)`
  padding: 15px;
`;

const StyledGrid = styled(Grid)`
  text-align: left;
  img {
    height: 90px;
    width: 90px;
    @media only screen and (min-width: ${breakPoints.small}) {
      height: 120px;
      width: 100px;
    }
  }
`;

const StyledText = styled.p`
  margin: 0 5px;
  padding: 0;
  width: auto;
  font-size: 30px;
  color: ${colors.coreBlue500};
  font-family: ${brandonGrotesque};
  text-align: left;

  @media only screen and (min-width: ${breakPoints.medium}) {
    width: 250px;
    height: auto;
  }
`;

function Confirmation(props) {
  const {
    location: { pathname },
    history
  } = props;

  const dispatch = useDispatch();

  const selectLastExcludedDriver = useMemo(makeSelectLastExcludedDriver, []);

  const selectCurrentExcludedDriver = useMemo(
    makeSelectCurrentExcludedDriver,
    []
  ); // Pull excluded driver from state

  const lastExcludedDriver = useSelector(state =>
    selectLastExcludedDriver(state)
  );

  const nextExcludedDriver = useSelector(state =>
    selectCurrentExcludedDriver(state)
  );

  const allDriversExcluded = useSelector(
    state => state.excludedDrivers.allDriversExcluded
  );

  const lastExcludedDriverActiveBit = useSelector(
    state => state.excludedDrivers.lastExcludedDriverActiveBit
  );

  const changeRequiresRates = useSelector(
    state => state.excludedDrivers.requiresUpdatedRates
  );

  const askDefensiveDrivingInDriverExclusion = useSelector(
    state => state.app.askDefensiveDrivingInDriverExclusion
  );

  const changeRequiresFormMetadata = useSelector(
    state => state.excludedDrivers.requiresFormMetadata
  );

  const savedCoverages = useSelector(state => state.rate.savedCoverages);
  const savedCoveragePackage = useSelector(state => state.rate.currentPackage);

  const askMilitaryServiceVehicles = useSelector(
    state => state.app.askMilitaryServiceVehicles
  );

  const driverItems = useSelector(state => state.drivers.items);

  const primaryDriverItem = useSelector(state => state.drivers.primaryDriver);

  const allVehiclesOwnedByAmp = useSelector(
    state => state.app.allVehiclesOwnedByAmp
  );

  const [confirmedDriver, setLastExcludedDriver] = useState(
    lastExcludedDriver || { firstName: '', lastName: '' }
  );

  const [successRoute, setSuccessRoute] = useState('');

  const prevLocation = useRef(pathname);

  const unListen = useRef();

  const { firstName, lastName } = confirmedDriver;
  const fullName = `${firstName} ${lastName}`;

  const backArrowListener = useCallback(() => {
    if (prevLocation !== pathname) {
      history.go(1);
      dispatch({ type: 'OPEN_MODAL', payload: 'driverExclusionModal' });
    }
  }, [history, pathname, dispatch]);

  useEffect(() => {
    let updatedSuccessRoute = '';
    const driverExclusionSection = '/driver-exclusion/';

    if (
      !allDriversExcluded &&
      nextExcludedDriver &&
      nextExcludedDriver.driverId
    ) {
      updatedSuccessRoute = `${driverExclusionSection}${nextExcludedDriver.driverId}/household-member`;
    } else if (
      allDriversExcluded &&
      askMilitaryServiceVehicles &&
      allVehiclesOwnedByAmp !== 'Yes' &&
      !changeRequiresFormMetadata && // Drivers were not excluded
      changeRequiresRates // Drivers were added
    ) {
      updatedSuccessRoute = `${driverExclusionSection}active-military-service-vehicles`;
    } else if (allDriversExcluded && askDefensiveDrivingInDriverExclusion) {
      updatedSuccessRoute = `${driverExclusionSection}confirm-safe-driving`;
    } else {
      updatedSuccessRoute = '/purchase/effective-date';
    }
    setSuccessRoute(updatedSuccessRoute);
  }, [
    allDriversExcluded,
    askMilitaryServiceVehicles,
    setSuccessRoute,
    successRoute,
    nextExcludedDriver,
    allVehiclesOwnedByAmp,
    askDefensiveDrivingInDriverExclusion,
    driverItems,
    primaryDriverItem,
    changeRequiresFormMetadata,
    changeRequiresRates
  ]);

  useEffect(() => {
    if (prevLocation !== pathname) {
      history.push(pathname, '', '');

      unListen.current = history.listen((newLocation, action) => {
        if (action === 'POP') {
          backArrowListener();
        }
      });
    }

    return unListen.current;
  }, [backArrowListener, pathname, history]);

  useEffect(() => {
    if (lastExcludedDriver) {
      setLastExcludedDriver(lastExcludedDriver);
    }
  }, [lastExcludedDriver, setLastExcludedDriver]);

  let nextExcludedDriverFullName = '';
  let nextExcludedDriverPrompt = '';

  if (!allDriversExcluded && nextExcludedDriver) {
    nextExcludedDriverFullName = `${nextExcludedDriver?.firstName} ${nextExcludedDriver?.lastName}`;

    nextExcludedDriverPrompt = ` Now we will review ${nextExcludedDriverFullName}.`;
  }

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleNavigation = () => {
    handleClose();
    dispatch({
      type: 'REDIRECT',
      payload: { url: '/driver-exclusion/start' }
    });
  };

  const handleClick = useCallback(() => {
    if (allDriversExcluded && successRoute === '/purchase/effective-date') {
      if (changeRequiresRates) {
        // REQUEST RATES AND DYNAMIC PRE-BIND FORMS
        dispatch({
          type: 'GET_RATE_BY_PACKAGE',
          payload: {
            currentPackage: savedCoveragePackage,
            queryParams: { savePackage: true },
            ...savedCoverages
          }
        });
        dispatch({
          type: 'UPDATED_RATES_REQUESTED'
        });
      } else if (changeRequiresFormMetadata) {
        // REQUEST UPDATED DYNAMIC PRE-BIND FORMS
        dispatch({
          type: 'REQUEST_DYNAMIC_PRE_BIND_FORMS'
        });
      }
    }
    dispatch({
      type: 'REDIRECT',
      payload: { url: successRoute }
    });
  }, [
    dispatch,
    successRoute,
    allDriversExcluded,
    changeRequiresRates,
    changeRequiresFormMetadata,
    savedCoveragePackage,
    savedCoverages
  ]);

  function generateConfirmation(
    fullName,
    confirmedDriver,
    lastExcludedDriverActiveBit
  ) {
    let confirmationStatus = '';
    const isExcluded = confirmedDriver.type === 'excluded';

    if (lastExcludedDriverActiveBit && confirmedDriver.type === 'additional') {
      confirmationStatus = 'added to';
    } else if (lastExcludedDriverActiveBit && isExcluded) {
      confirmationStatus = 'excluded from';
    } else {
      confirmationStatus = 'removed from';
    }

    return `${fullName} ${
      isExcluded ? 'will be' : 'has been'
    } ${confirmationStatus} your quote${
      isExcluded ? ' and will not be covered.' : '.'
    }`;
  }

  const header = generateConfirmation(
    fullName,
    confirmedDriver,
    lastExcludedDriverActiveBit
  );

  return (
    <Container>
      <StyledContentWrapper>
        <StyledContainer maxWidth="xs">
          <StyledGrid
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid container alignItems="flex-end" item xs>
              <Grid item>
                <img
                  src={`${process.env.REACT_APP_NOBLR_CDN}/icons/usaa-blue-circular-outline-checkmark.svg`}
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid container item xs={8} sm>
              <Grid item xs={12} zeroMinWidth>
                <StyledText>{header}</StyledText>
              </Grid>
            </Grid>
            {nextExcludedDriverPrompt && (
              <Grid item xs={8}>
                <StyledText>{nextExcludedDriverPrompt}</StyledText>
              </Grid>
            )}
          </StyledGrid>
        </StyledContainer>
        <Modal
          inline
          modalLabel="DriverExclusionModal"
          modalName="driverExclusionModal"
        >
          <Box>
            <Grid container>
              <ModalHeader>
                {`Clicking 'BACK' will take you to the section start page and reset the list of drivers you've gone through.`}
              </ModalHeader>
              <ButtonGrid container item>
                <Grid item xs sm>
                  <ModalPrimaryButton large onClick={handleClose}>
                    CANCEL
                  </ModalPrimaryButton>
                </Grid>
                <Grid item xs sm>
                  <ModalPrimaryButton primary large onClick={handleNavigation}>
                    BACK
                  </ModalPrimaryButton>
                </Grid>
              </ButtonGrid>
            </Grid>
          </Box>
        </Modal>
      </StyledContentWrapper>
      <Sticky primaryLinkButton>
        <ContinueButton onContinue={handleClick}>CONTINUE</ContinueButton>
      </Sticky>
    </Container>
  );
}

Confirmation.propTypes = {
  history: PropTypes.shape({
    go: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    listen: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Confirmation;
