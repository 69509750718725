import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormControl, RadioGroup } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import Loader from 'components/Loader';
import ExpandableRadioCard from 'components/ExpandableRadioCard';
import Sticky from 'components/Modifiers/Sticky';
import { Prompt, SubHeader, StyledLink } from 'styles';
import { openSans, breakPoints, colors } from 'styles/variables';
import PaidUpfrontV2 from './paidUpfrontV2';
import PaidMonthlyV2 from './paidMonthlyV2';
import { determinePreBindStartPage } from '../../utilities/coverage';

const PageWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: ${props =>
    props.desktopMaxWidth ? props.desktopMaxWidth : '90%'};
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-content: center;

  @media only screen and (min-width: ${breakPoints.widescreen}) {
    padding: 10px 20px;
  }

  @media only screen and (max-width: ${breakPoints.extraSmall}) {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    fieldset > p {
      max-width: 80%;
    }
  }

  @media only screen and (min-width: ${breakPoints.small}) and (max-width: ${breakPoints.medium}) {
    margin: 5px auto 0;
    width: ${props => (props.mobileMaxWidth ? props.mobileMaxWidth : 'auto')};
    max-width: ${props =>
      props.mobileMaxWidth ? props.mobileMaxWidth : '80vw'};
    display: flex;
    justify-content: space-between;
    fieldset > p {
      max-width: 80%;
    }
  }
`;

const StyledContainer = styled.div`
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding: 10px 10px 30px;

  @media only screen and (min-width: ${breakPoints.large}) {
    padding: 0;
  }
`;

const CardsContainer = styled.div`
  margin: 40px auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;

  @media screen and (max-width: ${breakPoints.large}) {
    margin: 50px auto;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div`
  margin: 0 auto 10px;

  @media screen and (max-width: ${breakPoints.large}) {
    margin: 0 auto;
    :first-child {
      margin: 0 auto 30px;
    }
  }
`;

const DisclaimerText = styled.p`
  width: auto;
  max-width: 670px;
  margin: 10px auto 0;
  font-size: 14px;
  font-family: ${openSans};
  text-align: left;
  line-height: 1.57;
  color: ${colors.coreBlue700};
  word-break: break-word;

  @media screen and (max-width: ${breakPoints.large}) {
    max-width: 350px;
    text-align: center;
  }
`;

const PaymentPrompt = styled(Prompt)`
  width: auto;
  margin: 20px auto;
  padding: 0 10px;
  font-size: ${props =>
    props.mobilePromptFontSize ? props.mobilePromptFontSize : '30px'};
  text-align: center;
  font-weight: 300;
  white-space: pre-line;
  color: ${colors.coreBlue700};

  @media screen and (min-width: ${breakPoints.large}) {
    padding: 0;
    max-width: 700px;
    font-size: ${props =>
      props.promptFontSize ? props.promptFontSize : '50px'};
    line-height: 1.3;
    letter-spacing: 0.9px;
    text-align: ${props =>
      props.promptTextAlign ? props.promptTextAlign : 'center'};
  }
`;

const CustomSubHeader = styled(SubHeader)`
  max-width: 816px;
  width: auto;
  max-height: 116px;
  margin: 10px auto;
  text-align: center;
  line-height: 1.67;
  letter-spacing: 0.45px;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-line;
  color: ${colors.coreBlue700};

  @media screen and (max-width: ${breakPoints.large}) {
    max-width: 345px;
    white-space: break-spaces;
    text-align: center;
  }
`;

class PaymentScheduleV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Choose your payment schedule',
      submitAction: 'SET_INSTALLMENT_SCHEDULE',
      selectedValue: null,
      successRoute: null
      // isExpanded: false, //TODO: Determine why isExpanded is not used
    };

    this.handleSelection = this.handleSelection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { state, requiresDynamicForms, noblrPaymentPlanName } = this.props;

    const successRoute = determinePreBindStartPage(state, requiresDynamicForms);

    const selectedValue = noblrPaymentPlanName || 'INSTALLMENT';

    this.setState({
      selectedValue,
      successRoute
    });
  }

  handleSubmit() {
    const { submitAction, successRoute, selectedValue } = this.state;

    const { dispatch } = this.props;

    dispatch({
      type: submitAction,
      payload: {
        option: selectedValue,
        successRoute
      }
    });
  }

  handleSelection(event) {
    this.setState({
      selectedValue: event.target.value
    });
  }

  render() {
    const { prompt, selectedValue, successRoute } = this.state;
    const { asyncRequest, isSaving, paymentScheduleMap } = this.props;
    const isPaymentScheduleMapEmpty =
      Object.keys(paymentScheduleMap).length === 0;

    if (
      isPaymentScheduleMapEmpty ||
      !successRoute ||
      asyncRequest === 'pending' ||
      isSaving
    ) {
      return (
        <StyledContainer>
          <Loader absolute />
        </StyledContainer>
      );
    }

    /*
    "paymentScheduleMap" is sent in the "/create" response body and saved in the policy reducer. Do NOT replace with "estInstallmentPaymentSchedule" from rate reducer here because it will cause a $0.01 discrepancy in Verisk - Sean
    */
    const { installment, paidInFull } = paymentScheduleMap;

    const cardHeaders = {
      monthly: 'Pay Both Fixed and Variable Rates Monthly',
      inFull: 'Pay Total Fixed Rate Upfront and \n Variable Rate Monthly'
    };

    return (
      <StyledContainer>
        <PageWrapper mobileMaxWidth="50vw">
          <form onSubmit={this.handleSubmit}>
            <FormControl component="fieldset">
              <PaymentPrompt>{prompt}</PaymentPrompt>
              <CustomSubHeader>
                Your rate has fixed and variable portions. You will always pay
                your variable rate monthly.
              </CustomSubHeader>
              <CustomSubHeader>
                Choose how you pay your total fixed rate: you can pay it in 6
                monthly installments over your policy term, or pay it upfront
                today and earn rewards.
              </CustomSubHeader>
              <RadioGroup name="paymentSchedule" aria-label="payment schedule">
                <CardsContainer>
                  <Container>
                    <ExpandableRadioCard
                      name="INSTALLMENTS"
                      label="Monthly"
                      amount={installment.dueToday}
                      header={cardHeaders.monthly}
                      handleSelection={this.handleSelection}
                      isSelected={selectedValue === 'INSTALLMENTS'}
                    >
                      <PaidMonthlyV2
                        estInstallmentPaymentSchedule={
                          installment.paymentSchedule
                        }
                        installmentPrice={installment.dueToday}
                      />
                    </ExpandableRadioCard>
                  </Container>
                  <Container>
                    <ExpandableRadioCard
                      biggestSavings
                      label="Upfront"
                      name="PAID_IN_FULL"
                      amount={paidInFull.dueToday}
                      header={cardHeaders.inFull}
                      handleSelection={this.handleSelection}
                      isSelected={selectedValue === 'PAID_IN_FULL'}
                    >
                      <PaidUpfrontV2
                        payInFullPrice={paidInFull.dueToday}
                        rewards={paidInFull.rewards}
                        estInstallmentPaymentSchedule={
                          paidInFull.paymentSchedule
                        }
                      />
                    </ExpandableRadioCard>
                  </Container>
                </CardsContainer>
              </RadioGroup>
              <DisclaimerText>
                {`Your membership contribution, separate from premium, is already included in your fixed
              rate for any payment plan you choose. Read more about `}
                <StyledLink
                  href="https://www.noblr.com/membership-info"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="opens a new window to Noblr membership page"
                >
                  {'Noblr membership here >'}
                </StyledLink>
              </DisclaimerText>
              <Sticky
                buttonPosition="fixed"
                marginTop="50px"
                className="form-button"
              >
                <Button
                  className="cta--1 primary"
                  id="continue-button"
                  large
                  primary
                  type="button"
                  onClick={this.handleSubmit}
                >
                  Continue
                </Button>
              </Sticky>
            </FormControl>
          </form>
        </PageWrapper>
      </StyledContainer>
    );
  }
}

const mapStateToProps = ({
  app: { asyncRequest, requiresDynamicForms },
  drivers: {
    primaryDriver: { state }
  },
  policy: { isSaving, paymentScheduleMap, noblrPaymentPlanName }
}) => ({
  asyncRequest,
  isSaving,
  state,
  paymentScheduleMap,
  noblrPaymentPlanName,
  requiresDynamicForms
});

PaymentScheduleV2.propTypes = {
  asyncRequest: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  isSaving: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  paymentScheduleMap: PropTypes.shape({
    installment: PropTypes.shape({
      rewards: PropTypes.string,
      dueToday: PropTypes.string,
      paymentSchedule: PropTypes.objectOf(PropTypes.string)
    }),
    paidInFull: PropTypes.shape({
      rewards: PropTypes.string,
      dueToday: PropTypes.string,
      paymentSchedule: PropTypes.objectOf(PropTypes.string)
    })
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  noblrPaymentPlanName: PropTypes.string.isRequired,
  requiresDynamicForms: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(PaymentScheduleV2);
