/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components/macro';
import { colors, openSans } from 'styles/variables';

const RulesContainer = styled.ul`
  text-align: left;
  width: 100%;
  padding: 0 0 0 20px;
  list-style-type: inherit;
  margin-bottom: 15px;
  li {
    color: ${colors.coreBlue700};
    font-family: ${openSans};
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`;

function PasswordRequirements() {
  const rules = [
    'Your password should have at least 6 characters',
    'Includes upper and lower case letters',
    'Includes at least one number'
  ];

  return (
    <RulesContainer>
      {rules.map((rule, index) => (
        <li key={index}>{rule}</li>
      ))}
    </RulesContainer>
  );
}

export default PasswordRequirements;
