export const GET_ASSOCIATED_VEHICLES = 'GET_ASSOCIATED_VEHICLES';
export const UPDATE_ASSOCIATED_VEHICLES = 'UPDATE_ASSOCIATED_VEHICLES';

export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';

export const UNDO_REMOVE_VEHICLE = 'UNDO_REMOVE_VEHICLE';
export const UNDO_REMOVE_VEHICLE_SUCCESS = 'UNDO_REMOVE_VEHICLE_SUCCESS';
export const UNDO_REMOVE_VEHICLE_FAILURE = 'UNDO_REMOVE_VEHICLE_FAILURE';

export const SAVE_VIN = 'SAVE_VIN';
export const SAVE_VIN_SUCCESS = 'SAVE_VIN_SUCCESS';
export const SAVE_VIN_FAILURE = 'SAVE_VIN_FAILURE';

export const COMPLETE_ADDED_VEHICLE_REGISTRATION =
  'COMPLETE_ADDED_VEHICLE_REGISTRATION';

export const REMOVE_VEHICLE_REQUEST = 'REMOVE_VEHICLE_REQUEST';
export const REMOVE_VEHICLE_SUCCESS = 'REMOVE_VEHICLE_SUCCESS';

// App reducer consumes these action types
export const SET_RIDESHARE_TNC_STATUS = 'SET_RIDESHARE_TNC_STATUS';
export const SET_RIDESHARE_TNC_STATUS_SUCCESS =
  'SET_RIDESHARE_TNC_STATUS_SUCCESS';
export const SET_RIDESHARE_TNC_STATUS_FAILURE =
  'SET_RIDESHARE_TNC_STATUS_FAILURE';

export const SET_VEHICLES_AMP_STATUS = 'SET_VEHICLES_AMP_STATUS';

export const CLEAR_EDITING_VEHICLE = 'CLEAR_EDITING_VEHICLE';
export const UPDATE_EDITING_VEHICLE = 'UPDATE_EDITING_VEHICLE';

export const GET_ALL_VEHICLES = 'GET_ALL_VEHICLES';
export const GET_ALL_VEHICLES_SUCCESS = 'GET_ALL_VEHICLES_SUCCESS';

export const GET_VEHICLE_DNQ = 'GET_VEHICLE_DNQ';

export const GET_YEAR_MAKE_MODEL = 'GET_YEAR_MAKE_MODEL';
export const UPDATE_YMM = 'UPDATE_YMM';
export const GET_YEAR_MAKE_MODEL_SUCCESS = 'GET_YEAR_MAKE_MODEL_SUCCESS';
export const GET_SELECTED_MAKE_MODEL = 'GET_SELECTED_MAKE_MODEL';
export const GET_SELECTED_MODEL_STYLE = 'GET_SELECTED_MODEL_STYLE';

export const GET_VIN_STEM = 'GET_VIN_STEM';
export const SAVE_VIN_STEM = 'SAVE_VIN_STEM';

export const SET_VEHICLE_ASSIGNMENTS = 'SET_VEHICLE_ASSIGNMENTS';

export const SET_PRIMARY_VEHICLE_ASSIGNMENT = 'SET_PRIMARY_VEHICLE_ASSIGNMENT';
export const SET_PRIMARY_VEHICLE_ASSIGNMENT_SUCCESS =
  'SET_PRIMARY_VEHICLE_ASSIGNMENT_SUCCESS';
export const SET_PRIMARY_VEHICLE_ASSIGNMENT_FAILURE =
  'SET_PRIMARY_VEHICLE_ASSIGNMENT_FAILURE';

export const SET_PRIMARY_VEHICLE_ASSIGNMENT_LOCALLY =
  'SET_PRIMARY_VEHICLE_ASSIGNMENT_LOCALLY';

export const ASSIGN_EXCESS_VEHICLES = 'ASSIGN_EXCESS_VEHICLES';
export const ASSIGN_EXCESS_VEHICLES_SUCCESS = 'ASSIGN_EXCESS_VEHICLES_SUCCESS';
export const ASSIGN_EXCESS_VEHICLES_FAILURE = 'ASSIGN_EXCESS_VEHICLES_FAILURE';

export const getAssociatedVehicles = vehicles => ({
  type: GET_ASSOCIATED_VEHICLES,
  payload: vehicles
});

export const updatedAssociatedVehicles = vehicles => ({
  type: UPDATE_ASSOCIATED_VEHICLES,
  payload: vehicles
});

export const updateVehicle = updatedInfo => ({
  type: UPDATE_VEHICLE,
  payload: { updatedInfo }
});

export const removeVehicleSuccess = vehicleId => ({
  type: REMOVE_VEHICLE_SUCCESS,
  payload: { vehicleId }
});

export const saveVin = vin => ({
  type: SAVE_VIN,
  payload: { vin }
});

export const removeVehicleRequest = values => ({
  type: REMOVE_VEHICLE_REQUEST,
  payload: values
});

export const undoRemoveVehicle = vehicle => ({
  type: UNDO_REMOVE_VEHICLE,
  payload: vehicle
});

export const undoRemoveVehicleSuccess = vehicleId => ({
  type: UNDO_REMOVE_VEHICLE_SUCCESS,
  payload: vehicleId
});

export const undoRemoveVehicleFailure = data => ({
  type: UNDO_REMOVE_VEHICLE_SUCCESS,
  payload: data
});

// DO NOT SET "isRideshare" IN REDUCER UNTIL SUCCESS ACTION
export const setRideshareTNCStatus = isRideshare => ({
  type: SET_RIDESHARE_TNC_STATUS,
  payload: { isRideshare }
});

export const setRideshareTNCStatusSuccess = isRideshare => ({
  type: SET_RIDESHARE_TNC_STATUS_SUCCESS,
  payload: { isRideshare }
});

export const setRideshareTNCStatusSuccessFailure = error => ({
  type: SET_RIDESHARE_TNC_STATUS_FAILURE,
  error
});

export const setVehicleAmpStatus = allVehiclesOwnedByAmp => ({
  type: SET_VEHICLES_AMP_STATUS,
  payload: { allVehiclesOwnedByAmp }
});

export const clearEditingVehicle = () => ({
  type: CLEAR_EDITING_VEHICLE
});

export const getAllVehicles = () => ({
  type: GET_ALL_VEHICLES
});

export const getVehicleDnq = data => ({
  type: GET_VEHICLE_DNQ,
  payload: data
});

export const updateEditingVehicle = values => ({
  type: UPDATE_EDITING_VEHICLE,
  payload: values
});

export const getYearMakeModel = year => ({
  type: GET_YEAR_MAKE_MODEL,
  payload: { year }
});

export const getSelectedMakeModel = make => ({
  type: GET_SELECTED_MAKE_MODEL,
  payload: { make }
});

export const getSelectedModelStyle = model => ({
  type: GET_SELECTED_MODEL_STYLE,
  payload: { model }
});

export const getVinStem = style => ({
  type: GET_VIN_STEM,
  payload: { style }
});

export const saveVinStem = values => ({
  type: SAVE_VIN_STEM,
  payload: { values }
});

export const setVehicleAssignments = values => ({
  type: SET_VEHICLE_ASSIGNMENTS,
  payload: values
});

// values key is driver id, value is vehicle id
// config object lets us specify not to hit endpoint when we have excess cars
export const setPrimaryVehicleAssignment = (values, makeApiCall = true) => ({
  type: SET_PRIMARY_VEHICLE_ASSIGNMENT,
  payload: values,
  makeApiCall
});

export const setPrimaryVehicleAssignmentSuccess = vehicleAssignmentsArray => ({
  type: SET_PRIMARY_VEHICLE_ASSIGNMENT_SUCCESS,
  payload: vehicleAssignmentsArray
});

export const setPrimaryVehicleAssignmentFailure = error => ({
  type: SET_PRIMARY_VEHICLE_ASSIGNMENT_FAILURE,
  error
});

// values key is driver id, value is vehicle id
// we dispatch this action when we have excess cars to assign too,
// it allows us to save primary assignments to use when we call assignExcessVehicles
export const setPrimaryVehicleAssignmentLocally = values => ({
  type: SET_PRIMARY_VEHICLE_ASSIGNMENT_LOCALLY,
  payload: values
});

export const assignExcessVehicles = vehicleId => ({
  type: ASSIGN_EXCESS_VEHICLES,
  payload: vehicleId
});

export const assignExcessVehiclesSuccess = assignments => ({
  type: ASSIGN_EXCESS_VEHICLES_SUCCESS,
  payload: assignments
});

export const assignExcessVehiclesFailure = error => ({
  type: ASSIGN_EXCESS_VEHICLES_FAILURE,
  error
});
