export const DISPLAY_ASYNC_ERROR = 'DISPLAY_ASYNC_ERROR';
export const CLEAR_ASYNC_ERRORS = 'CLEAR_ASYNC_ERRORS';
export const SET_ERROR = 'SET_ERROR';

export const CLEAR_APP_ERRORS = 'CLEAR_APP_ERRORS';

export const SET_ERROR_LEVEL = 'SET_ERROR_LEVEL';
export const UPDATE_APP = 'UPDATE_APP';
export const CONFIRMED_PII = 'CONFIRMED_PII';

export const SET_SECTION = 'SET_SECTION';

export const EDITING_ITEM = 'EDITING_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UNDO_REMOVE = 'UNDO_REMOVE';

export const REQUEST_PREFILL = 'REQUEST_PREFILL';
export const REQUEST_PREFILL_SUCCESS = 'REQUEST_PREFILL_SUCCESS';
export const REQUEST_PREFILL_FAILURE = 'REQUEST_PREFILL_FAILURE';

export const START_ASYNC_REQUEST = 'START_ASYNC_REQUEST';
export const SET_ASYNC_SUCCESS_STATUS = 'SET_ASYNC_SUCCESS_STATUS';
export const SET_ASYNC_FAILED_STATUS = 'SET_ASYNC_FAILED_STATUS';
export const CLEAR_ASYNC_STATUS = 'CLEAR_ASYNC_STATUS';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';

export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const CLEAR_FORM_ERROR = 'CLEAR_FORM_ERROR';

export const TOGGLE_LOADER = 'TOGGLE_LOADER';

export const RETRIEVE_QUOTE = 'RETRIEVE_QUOTE';
export const RETRIEVE_QUOTE_SUCCESS = 'RETRIEVE_QUOTE_SUCCESS';
export const RETRIEVE_QUOTE_FAILURE = 'RETRIEVE_QUOTE_FAILURE';

export const RETRIEVE_USAA_PERSON_INFO = 'RETRIEVE_USAA_PERSON_INFO';
export const RETRIEVE_USAA_PERSON_INFO_SUCCESS =
  'RETRIEVE_USAA_PERSON_INFO_SUCCESS';
export const RETRIEVE_USAA_PERSON_INFO_FAILURE =
  'RETRIEVE_USAA_PERSON_INFO_FAILURE';

export const VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE =
  'VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE';
export const VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_SUCCESS =
  'VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_SUCCESS';
export const VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_FAILURE =
  'VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_FAILURE';

export const GET_CHECKPOINT = 'GET_CHECKPOINT';
export const GET_CHECKPOINT_SUCCESS = 'GET_CHECKPOINT_SUCCESS';

export const REDIRECT = 'REDIRECT';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';
export const USAA_UMBRELLA_POLICY = 'USAA_UMBRELLA_POLICY';

export const SET_MANUAL_ADDRESS_FLAG = 'SET_MANUAL_ADDRESS_FLAG';

export const GET_LEAD_DATA = 'GET_LEAD_DATA';
export const GET_LEAD_DATA_SUCCESS = 'GET_LEAD_DATA_SUCCESS';
export const GET_LEAD_DATA_FAILURE = 'GET_LEAD_DATA_FAILURE';

export const RESET_QUOTE = 'RESET_QUOTE';
export const RESTART_QUOTE_FLOW = 'RESTART_QUOTE_FLOW';

export const TRACK_SCREEN_VIEW = 'TRACK_SCREEN_VIEW';
export const TRACK_SCREEN_VIEW_FAILURE = 'TRACK_SCREEN_VIEW_FAILURE';

export const ENABLE_PRE_BIND_MODE = 'ENABLE_PRE_BIND_MODE';

export const SAVE_CRM_GATE = 'SAVE_CRM_GATE';
export const SAVE_CRM_GATE_SUCCESS = 'SAVE_CRM_GATE_SUCCESS';
export const SAVE_CRM_GATE_FAILURE = 'SAVE_CRM_GATE_FAILURE';

export const SHOW_EMAIL_MODAL = 'SHOW_EMAIL_MODAL';
export const HIDE_EMAIL_MODAL = 'HIDE_EMAIL_MODAL';

export const FETCH_QUOTE_DATA = 'FETCH_QUOTE_DATA';
export const FETCH_QUOTE_DATA_SUCCESS = 'FETCH_QUOTE_DATA_SUCCESS';
export const FETCH_QUOTE_DATA_FAILURE = 'FETCH_QUOTE_DATA_FAILURE';

export const REQUEST_USAA_ELIGIBILITY_QUESTIONS =
  'REQUEST_USAA_ELIGIBILITY_QUESTIONS';
export const REQUEST_USAA_ELIGIBILITY_QUESTIONS_SUCCESS =
  'REQUEST_USAA_ELIGIBILITY_QUESTIONS_SUCCESS';
export const REQUEST_USAA_ELIGIBILITY_QUESTIONS_FAILURE =
  'REQUEST_USAA_ELIGIBILITY_QUESTIONS_FAILURE';

export const CLEAR_USAA_ELIGIBILITY_QUESTIONS =
  'CLEAR_USAA_ELIGIBILITY_QUESTIONS';

export const SUBMIT_PRIMARY_ELIGIBILITY_ANSWER =
  'SUBMIT_PRIMARY_ELIGIBILITY_ANSWER';
export const SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS =
  'SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS';
export const SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_FAILURE =
  'SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_FAILURE';

export const SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS =
  'SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS';
export const SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_SUCCESS =
  'SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_SUCCESS';
export const SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_FAILURE =
  'SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_FAILURE';

export const VERIFY_DRIVERS_LICENSES = 'VERIFY_DRIVERS_LICENSES';
export const VERIFY_DRIVERS_LICENSES_SUCCESS =
  'VERIFY_DRIVERS_LICENSES_SUCCESS';
export const VERIFY_DRIVERS_LICENSES_FAILURE =
  'VERIFY_DRIVERS_LICENSES_FAILURE'; // PM fails to verify DL information
// Only used when HTTP error occurs
export const VERIFY_DRIVERS_LICENSES_ERROR = 'VERIFY_DRIVERS_LICENSES_ERROR';

// Good driver/defensive driving action types for GA
export const UPDATE_DEFENSIVE_DRIVING_ANSWER =
  'UPDATE_DEFENSIVE_DRIVING_ANSWER';
export const UPDATE_DEFENSIVE_DRIVING_ANSWER_SUCCESS =
  'UPDATE_DEFENSIVE_DRIVING_ANSWER_SUCCESS';
export const UPDATE_DEFENSIVE_DRIVING_ANSWER_FAILURE =
  'UPDATE_DEFENSIVE_DRIVING_ANSWER_FAILURE';

export const UPDATE_PRIOR_INSURANCE_LAPSE_REASON =
  'UPDATE_PRIOR_INSURANCE_LAPSE_REASON';
export const UPDATE_PRIOR_INSURANCE_LAPSE_REASON_SUCCESS =
  'UPDATE_PRIOR_INSURANCE_LAPSE_REASON_SUCCESS';
export const UPDATE_PRIOR_INSURANCE_LAPSE_REASON_FAILURE =
  'UPDATE_PRIOR_INSURANCE_LAPSE_REASON_FAILURE';

export const REQUEST_PRIOR_INSURANCE_REASONS =
  'REQUEST_PRIOR_INSURANCE_REASONS';
export const REQUEST_PRIOR_INSURANCE_REASONS_SUCCESS =
  'REQUEST_PRIOR_INSURANCE_REASONS_SUCCESS';
export const REQUEST_PRIOR_INSURANCE_REASONS_FAILURE =
  'REQUEST_PRIOR_INSURANCE_REASONS_FAILURE';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const ASK_DEFENSIVE_DRIVING_IN_DRIVER_EXCLUSION =
  'ASK_DEFENSIVE_DRIVING_IN_DRIVER_EXCLUSION';

export const openModal = modalName => ({
  type: 'OPEN_MODAL',
  payload: modalName
});

export const closeModal = () => ({
  type: 'CLOSE_MODAL'
});

export const ASK_MILITARY_SERVICE_VEHICLES = 'ASK_MILITARY_SERVICE_VEHICLES';

export const trackScreenView = (screenIdentifier, config) => ({
  type: TRACK_SCREEN_VIEW,
  payload: {
    screenIdentifier,
    ...config
  }
});

// endpoint doesn't return an error so we don't accept a param
export const trackScreenViewFailure = () => ({
  type: TRACK_SCREEN_VIEW_FAILURE
});

export const setError = error => ({
  type: SET_ERROR,
  error
});

export const updateApp = values => ({
  type: UPDATE_APP,
  payload: values
});

export const confirmedPII = data => ({
  type: CONFIRMED_PII,
  payload: data
});

export const updateAddedItem = (items, type) => ({
  type: `UPDATE_ADDED_${type.toUpperCase()}`,
  payload: items
});

export const setSection = section => ({
  type: SET_SECTION,
  payload: { section }
});

export const editingItem = item => ({
  type: EDITING_ITEM,
  payload: item
});

export const removeItem = (item, listType) => ({
  type: REMOVE_ITEM,
  payload: { item, listType }
});

export const undoRemove = (item, listType) => ({
  type: UNDO_REMOVE,
  payload: { item, listType }
});

export const requestPrefill = () => ({
  type: REQUEST_PREFILL
});

export const requestPrefillSuccessful = data => ({
  type: REQUEST_PREFILL_SUCCESS,
  payload: data
});

export const requestPrefillFailure = error => ({
  type: REQUEST_PREFILL_FAILURE,
  error
});

export const restartQuoteFlow = () => ({
  type: RESTART_QUOTE_FLOW
});

export const startAsyncRequest = request => ({
  type: START_ASYNC_REQUEST,
  payload: request
});

export const toggleModal = name => ({
  type: TOGGLE_MODAL,
  payload: name
});

export const showModal = name => ({
  type: SHOW_MODAL,
  payload: name
});

export const setFormError = error => ({
  type: SET_FORM_ERROR,
  error
});

export const clearFormError = () => ({
  type: CLEAR_FORM_ERROR
});

export const toggleLoader = toShow => ({
  type: TOGGLE_LOADER,
  payload: { toShow }
});

export const retrieveQuote = values => ({
  type: RETRIEVE_QUOTE,
  payload: {
    ...values
  }
});

export const retrieveQuoteSuccess = () => ({
  type: RETRIEVE_QUOTE_SUCCESS
});

export const retrieveQuoteFailure = error => ({
  type: RETRIEVE_QUOTE_FAILURE,
  error
});

export const retrieveUSAAPersonInfo = data => ({
  type: RETRIEVE_USAA_PERSON_INFO,
  payload: data
});

export const retrieveUSAAPersonInfoSuccess = data => ({
  type: RETRIEVE_USAA_PERSON_INFO_SUCCESS,
  payload: data
});

export const retrieveUSAAPersonInfoFailure = error => ({
  type: RETRIEVE_USAA_PERSON_INFO_FAILURE,
  error
});

export const verifyUSAAPersonAndRetrieveQuote = data => ({
  type: VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE,
  payload: {
    ...data
  }
});

export const verifyUSAAPersonAndRetrieveQuoteSuccess = () => ({
  type: VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_SUCCESS
});

export const verifyUSAAPersonAndRetrieveQuoteFailure = error => ({
  type: VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE_FAILURE,
  error
});

export const getCheckpoint = (quoteComplete, showTrial) => ({
  type: GET_CHECKPOINT,
  payload: {
    quoteComplete,
    showTrial
  }
});

export const getCheckpointSuccess = () => ({
  type: GET_CHECKPOINT_SUCCESS
});

export const redirect = (url, config = null) => ({
  type: REDIRECT,
  payload: { url, config }
});

export const clearRedirect = () => ({
  type: CLEAR_REDIRECT
});

export const setManualAddressFlag = value => ({
  type: SET_MANUAL_ADDRESS_FLAG,
  payload: value
});

export const getLeadData = ({ leadId, leadType }) => ({
  type: GET_LEAD_DATA,
  payload: { leadId, leadType }
});

export const getLeadDataSuccess = data => ({
  type: GET_LEAD_DATA_SUCCESS,
  payload: data
});

export const getLeadDataFailure = error => ({
  type: GET_LEAD_DATA_FAILURE,
  error
});

export const resetQuote = values => ({
  type: RESET_QUOTE,
  payload: { values }
});

export const enablePreBindMode = () => ({
  type: ENABLE_PRE_BIND_MODE
});

// Quote Engine request for SalesForce integration (NYSE ticker - CRM)
// Valid arguments:
// DRIVERS_COMPLETE - dispatched at "/add-vehicles/start"
// VEHICLES_COMPLETE - dispatched at "add-coverages/start"
// COVERAGES_DEDUCTIBLES_COMPLETE - dispatched at "/driving-behavior/start"
export const saveCRMGate = gate => ({
  type: SAVE_CRM_GATE,
  payload: gate
});

export const saveCRMGateSuccess = () => ({
  type: SAVE_CRM_GATE_SUCCESS
});

export const saveCRMGateFailure = error => ({
  type: SAVE_CRM_GATE_FAILURE,
  error
});

export const hideEmailModal = () => ({
  type: HIDE_EMAIL_MODAL
});

export const fetchQuoteData = (quoteComplete, showTrial) => ({
  type: FETCH_QUOTE_DATA,
  payload: { quoteComplete, showTrial }
});

export const fetchQuoteDataSuccess = () => ({
  type: FETCH_QUOTE_DATA_SUCCESS
});

export const fetchQuoteDataFailure = error => ({
  type: FETCH_QUOTE_DATA_FAILURE,
  error
});

export const requestUSAAEligibilityQuestions = () => ({
  type: REQUEST_USAA_ELIGIBILITY_QUESTIONS
});

export const requestUSAAEligibilityQuestionsSuccess = data => ({
  type: REQUEST_USAA_ELIGIBILITY_QUESTIONS_SUCCESS,
  payload: data
});

export const requestUSAAEligibilityQuestionsFailure = error => ({
  type: REQUEST_USAA_ELIGIBILITY_QUESTIONS_FAILURE,
  error
});

export const submitPrimaryEligibilityAnswer = data => ({
  type: SUBMIT_PRIMARY_ELIGIBILITY_ANSWER,
  payload: data
});

export const submitPrimaryEligibilityAnswerSuccess = () => ({
  type: SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_SUCCESS
});

export const submitPrimaryEligibilityAnswerFailure = error => ({
  type: SUBMIT_PRIMARY_ELIGIBILITY_ANSWER_FAILURE,
  error
});

export const submitSecondaryEligibilityAnswers = data => ({
  type: SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS,
  payload: data
});

export const submitSecondaryEligibilityAnswersSuccess = () => ({
  type: SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_SUCCESS
});

export const submitSecondaryEligibilityAnswersFailure = error => ({
  type: SUBMIT_SECONDARY_ELIGIBILITY_ANSWERS_FAILURE,
  error
});

export const clearUSAAEligibilityQuestions = () => ({
  type: CLEAR_USAA_ELIGIBILITY_QUESTIONS
});

export const verifyDriversLicenses = data => ({
  type: VERIFY_DRIVERS_LICENSES,
  payload: data
});

export const verifyDriversLicensesSuccess = overallVerificationSuccess => ({
  type: VERIFY_DRIVERS_LICENSES_SUCCESS,
  payload: overallVerificationSuccess
});

export const verifyDriversLicensesFailure = overallVerificationSuccess => ({
  type: VERIFY_DRIVERS_LICENSES_FAILURE,
  payload: overallVerificationSuccess
});

export const verifyDriversLicensesError = error => ({
  type: VERIFY_DRIVERS_LICENSES_ERROR,
  error
});

export const updateDefensiveDrivingAnswer = value => ({
  type: UPDATE_DEFENSIVE_DRIVING_ANSWER,
  payload: value
});

export const updateDefensiveDrivingAnswerSuccess = () => ({
  type: UPDATE_DEFENSIVE_DRIVING_ANSWER_SUCCESS
});

export const updateDefensiveCourseAnswerFailure = error => ({
  type: UPDATE_DEFENSIVE_DRIVING_ANSWER_FAILURE,
  error
});

export const updatePriorInsuranceLapseReason = reason => ({
  type: UPDATE_PRIOR_INSURANCE_LAPSE_REASON,
  payload: reason
});

export const updatePriorInsuranceLapseReasonSuccess = () => ({
  type: UPDATE_PRIOR_INSURANCE_LAPSE_REASON_SUCCESS
});

export const savePriorInsuranceLapseReasonFailure = error => ({
  type: UPDATE_PRIOR_INSURANCE_LAPSE_REASON_FAILURE,
  payload: error
});

export const requestPriorInsuranceReasons = () => ({
  type: REQUEST_PRIOR_INSURANCE_REASONS
});

export const requestPriorInsuranceReasonsSuccess = data => ({
  type: REQUEST_PRIOR_INSURANCE_REASONS_SUCCESS,
  payload: data
});

export const requestPriorInsuranceReasonsFailure = error => ({
  type: REQUEST_PRIOR_INSURANCE_REASONS_FAILURE,
  error
});

export const clearAsyncErrors = () => ({
  type: CLEAR_ASYNC_ERRORS
});

export const askActiveMilitaryServiceVehicles = () => ({
  type: ASK_MILITARY_SERVICE_VEHICLES
});

export const askDefensiveDrivingInDriverExclusion = () => ({
  type: ASK_DEFENSIVE_DRIVING_IN_DRIVER_EXCLUSION
});
