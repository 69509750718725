import 'react-app-polyfill/stable';
import 'raf/polyfill';
import 'airbnb-js-shims/target/es2015';
import 'airbnb-browser-shims/browser-only';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import { CookiesProvider, Cookies } from 'react-cookie';
import { ThemeProvider } from '@material-ui/core/styles';
import { store, persistor } from 'redux/store';
import { injectAsyncUtilitiesStore } from 'utilities/asyncListener';
import App from 'containers/App';
import AppController from 'containers/AppController';
import ErrorPage from 'components/Pages/ErrorPage';
import theme from 'styles/theme';
import { configureCookieName, injectUtilitiesStore } from './utilities';
import './index.css';
import { EXPIRES_COOKIE_HEADER_CONFIG } from './constants';
// import './setupTests.js';

const isProduction = process.env.NODE_ENV === 'production';

const rootElement = document.getElementById('root');

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME
});

// gives store access to non component utility files;
injectUtilitiesStore(store);
injectAsyncUtilitiesStore(store);

const cookies = new Cookies();
const sessionCookie = cookies.get(configureCookieName('session'));
const personIdCookie = cookies.get(configureCookieName('personId'));
const quoteIdCookie = cookies.get(configureCookieName('quoteId'));

if (process.env.NODE_ENV === 'development') {
  cookies.set('x-noblr-bot-bypass', true, { domain: 'noblr.com', path: '/' });
}

FullStory.init({
  orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
  debug: false,
  devMode: !isProduction
});
// Initializes Sentry and use config values in .sentryclirc
Sentry.init({
  dsn: 'https://0f8cf5f0845e40e5a4e5508282a90303@o227302.ingest.sentry.io/2276503', // DSN is required inside Sentry.init()
  release: `web-quote@${process.env.REACT_APP_VERSION}`,
  environment: process.env.REACT_APP_HOST_ENV,
  validate: true,
  maxBreadcrumbs: 200,
  normalizeDepth: 10,
  // Prevent recapctcha timeouts from being reported to Sentry
  beforeSend(event, hint) {
    if (hint.originalException === `Timeout`) {
      return null;
    }

    return event;
  },
  initialScope: scope => {
    if (personIdCookie) {
      scope.setUser({ id: personIdCookie });
    }

    if (quoteIdCookie && sessionCookie) {
      scope.setTag('quote_id', quoteIdCookie);
    }
  },
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  autoSessionTracking: true, // Release health
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    "Failed to construct 'URL': Invalid URL",
    'gtag is not defined',
    "Unexpected token '<'"
  ],
  denyUrls: [
    /bam\.nr-data\.net/i, //  New Relic
    /bat\.bing\.com\/bat\.js/i, // Bing
    /clarity\.ms/i,
    /s\.yimg\.com\/wi\/ytc\.js/i, // yimg
    /service\.force\.com/i, // Sales Force
    /static\.lightning\.force\.com/i, // Sales Force
    /noblr\.my\.salesforce\.com/i, // Noblr Sales Force files
    /fs\.com/i, // FullStory
    /google\.com/i, // Google
    /google-analytics\.com/i, // GA
    /googleadservices\.com/i,
    /facebook\.com/i, // Facebook
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Firefox extensions
    /^resource:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // GTM and GA
    /\/(gtm|ga|analytics)\.js/i
  ],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport)
});
// MANUALLY SET MARKETING SITE AS REFERRER
// ONLY USED FOR DEBUGGING PERSISTED STATE
// window.document.__defineGetter__('referrer', function() {
//   return 'https://www.noblr.com';
// });

const onBeforeLift = () => {
  /*
  if no session cookie is stored in browser
  or user was referred to web quote from marketing site
  */
  if (
    (personIdCookie && !sessionCookie) ||
    (document.referrer && document.referrer.includes('https://www.noblr.com'))
  ) {
    if (quoteIdCookie) {
      cookies.remove(
        configureCookieName('quoteId'),
        EXPIRES_COOKIE_HEADER_CONFIG
      );
    }

    if (sessionCookie) {
      cookies.remove(
        configureCookieName('session'),
        EXPIRES_COOKIE_HEADER_CONFIG
      );
    }
    cookies.remove('quote-initiated', EXPIRES_COOKIE_HEADER_CONFIG);
    cookies.remove('quote-completed', EXPIRES_COOKIE_HEADER_CONFIG);
    // Reset state in Redux
    store.dispatch({ type: 'RESET_QUOTE' });

    return;
  }

  // pull primary driver off state
  const {
    app: { error, loading },
    drivers: { primaryDriver }
  } = store.getState();

  if (error) {
    store.dispatch({ type: 'CLEAR_ASYNC_ERRORS' });
  }

  if (loading) {
    store.dispatch({ type: 'TOGGLE_LOADER', payload: { toShow: false } });
  }

  // Google Tag Manager
  const dataLayer = window.dataLayer || [];

  // if quoteId, personId  and session cookies exist
  if (personIdCookie && quoteIdCookie && sessionCookie) {
    // if primary driver does not have a quoteId or the primary driver's quoteId does not match the quoteId cookie
    if (!primaryDriver.quoteId || primaryDriver.quoteId !== quoteIdCookie) {
      // update personId and quoteId values in primary driver object
      store.dispatch({
        type: 'UPDATE_PRIMARY_DRIVER',
        payload: {
          quoteId: quoteIdCookie,
          personId: personIdCookie
        }
      });
    }

    // push quoteId to GTM dataLayer array
    dataLayer.push({ quoteId: quoteIdCookie });
  }
};

if (!isProduction) {
  import('@axe-core/react').then(axe => {
    axe.default(React, ReactDOM, 1000);
    ReactDOM.render(
      <React.StrictMode>
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <Provider store={store}>
            <PersistGate
              persistor={persistor}
              loading={null}
              onBeforeLift={onBeforeLift}
            >
              <CookiesProvider>
                <Router
                  history={history}
                  basename={process.env.REACT_APP_BASENAME}
                >
                  <ThemeProvider theme={theme}>
                    <AppController history={history} />
                    <App location={history.location} />
                  </ThemeProvider>
                </Router>
              </CookiesProvider>
            </PersistGate>
          </Provider>
        </Sentry.ErrorBoundary>
      </React.StrictMode>,
      rootElement
    );
  });
} else {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={null}
          onBeforeLift={onBeforeLift}
        >
          <CookiesProvider>
            <Router history={history} basename={process.env.REACT_APP_BASENAME}>
              <ThemeProvider theme={theme}>
                <AppController history={history} />
                <App location={history.location} />
              </ThemeProvider>
            </Router>
          </CookiesProvider>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// unregister();
