import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';
import Form from 'components/Form/Form';
import { makeSelectEditingDriver } from 'redux/selectors';
import { StyledContentWrapper } from 'styles';

function VerifyActiveDriverLicense() {
  // memoize makeSelectCurrentExcludedDriver
  const selectEditingDriver = useMemo(makeSelectEditingDriver, []);

  // use selector to pull excluded driver from state
  const editingDriver = useSelector(state => selectEditingDriver(state));

  const fields = [
    {
      type: 'radio',
      name: 'activeDriverLicense',
      options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      required: true,
      radioGroupAriaLabel: 'Is the driver license active' // TODO :pass driver name
    }
  ];

  const [promptText, setPrompt] = useState('');

  useEffect(() => {
    const prompt = `Does ${editingDriver.firstName} ${editingDriver.lastName} have an active drivers license?`;

    setPrompt(prompt);
  }, [editingDriver]);

  const submitAction = 'SAVE_EDITING_DRIVER_DL_STATUS';

  if (
    !editingDriver &&
    (!editingDriver.driverId ||
      !editingDriver.firstName ||
      !editingDriver.lastName)
  ) {
    return <Redirect to="/add-drivers/driver-list" replace />;
  }

  return (
    <Container>
      <StyledContentWrapper>
        <Form prompt={promptText} fields={fields} submitAction={submitAction} />
      </StyledContentWrapper>
    </Container>
  );
}

export default VerifyActiveDriverLicense;
