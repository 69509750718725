import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ReviewAndPayForm from 'components/Form/ReviewAndPayForm';
import PaymentSummary from 'components/Pages/Purchase/PaymentSummary';
import ReviewPolicySummary from 'containers/ReviewPolicySummary';
import Loader from 'components/Loader';
import { breakPoints, colors } from 'styles/variables';
import { Prompt } from 'styles';
import { DEFAULT_MAX_WIDTH } from '../../constants';

const Wrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  background-color: ${colors.white};
  max-width: ${DEFAULT_MAX_WIDTH};

  @media only screen and (max-width: ${breakPoints.medium}) {
    max-width: 95vw;
  }
`;

const SummaryWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: ${DEFAULT_MAX_WIDTH};
  display: flex;
  flex-flow: column nowrap;

  @media only screen and (max-width: ${breakPoints.medium}) {
    padding: 0 30px;
  }
`;

class ReviewPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Confirm your payment details and policy info.',
      successRoute: '/purchase/thank-you',
      submitAction: 'BIND_QUOTE'
    };
  }

  componentDidMount() {
    const { loggedIn, trialActive, trialConverted } = this.props;

    if ((loggedIn || trialActive) && !trialConverted) {
      this.setState({
        successRoute: '/account/download-app'
      });
    }
  }

  render() {
    const { data, policy } = this.props;

    const { submitAction, prompt, successRoute } = this.state;
    const {
      paymentPlan,
      membersContribution,
      totalPrice,
      payments,
      fixedPrice,
      cardType,
      cclastFourDigits,
      isSaving,
      purchased
    } = policy;

    return isSaving && !purchased ? (
      <Loader />
    ) : (
      <Wrapper>
        <SummaryWrapper>
          <Prompt>{prompt}</Prompt>
          <PaymentSummary
            paymentPlan={paymentPlan}
            payments={payments}
            totalPrice={totalPrice}
            fixedPrice={fixedPrice}
            membersContribution={membersContribution}
            cardType={cardType}
            lastFour={cclastFourDigits}
          />
          <ReviewPolicySummary />
        </SummaryWrapper>
        {totalPrice && (
          <ReviewAndPayForm
            submitAction={submitAction}
            submitAsync
            successRoute={successRoute}
            data={data}
            amountDue={totalPrice}
          />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = ({
  policy,
  auth: { loggedIn, trialActive, trialConverted }
}) => ({
  policy,
  loggedIn,
  trialActive,
  trialConverted
});

ReviewPage.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  trialActive: PropTypes.bool,
  trialConverted: PropTypes.bool,
  policy: PropTypes.shape({
    paymentPlan: PropTypes.string.isRequired,
    membersContribution: PropTypes.string.isRequired,
    totalPrice: PropTypes.string.isRequired,
    payments: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.string,
        dueDate: PropTypes.string,
        installmentNumber: PropTypes.number
      })
    ),
    fixedPrice: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
    cclastFourDigits: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    purchased: PropTypes.bool.isRequired
  }),
  data: PropTypes.shape({
    name: PropTypes.string,
    overrideSectionTitle: PropTypes.objectOf(PropTypes.string)
  }).isRequired
};

ReviewPage.defaultProps = {
  trialActive: false,
  trialConverted: false,
  policy: {
    payments: []
  }
};
export default connect(mapStateToProps)(ReviewPage);
