/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */

import React, { Component } from 'react';
import Cleave from 'cleave.js/react';

class CleaveInput extends Component {
  constructor(props) {
    super(props);

    const { name } = this.props;

    this.fieldName = name;

    this.state = {
      [`${this.fieldName}Cleave`]: null,
      [`${this.fieldName}RawValue`]: '',
      customOptionInitialized: false
    };
    this.onCleaveInit = this.onCleaveInit.bind(this);
    this.initCustomOption = this.initCustomOption.bind(this);
    this.onCleaveChange = this.onCleaveChange.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  componentDidMount() {
    const { customOptionInitialized } = this.state;
    const { options } = this.props;

    if (options && !customOptionInitialized) {
      this.initCustomOption();
    }
  }

  handleOnKeyDown(event) {
    const {
      options,
      name,
      form: { setFieldValue }
    } = this.props;

    if (this._cleave && this._cleave.getRawValue().includes('**')) {
      const regex = /([a-zA-Z])|(\d)/;

      if (regex.test(event.key) && event.key.length < 2) {
        this.setState({
          [`${this.fieldName}RawValue`]: event.key
        });

        setFieldValue(name, event.key);
      } else if (
        event.key.length < 2 ||
        event.keyCode === 8 ||
        event.keyCode === 32
      ) {
        let { key } = event;

        if (event.keyCode === 8 || event.keyCode === 32) {
          key = '';
        }

        this.setState({
          [`${this.fieldName}RawValue`]: key
        });

        this._cleave.setRawValue(key);

        setFieldValue(name, key);
      }

      event.preventDefault();

      return;
    }

    if (
      !options ||
      !options.formatDate ||
      !this._cleave.properties.formatDate
    ) {
      return;
    }

    const currentRawValue = this.state[`${this.fieldName}RawValue`];
    const cleave = this.state[`${this.fieldName}Cleave`];

    if (event.key === '/' || event.keyCode === 191) {
      if (currentRawValue.length === 1) {
        // if current value length is 1 and user typed back slash, prepend raw value with 0
        this.setState({ [`${this.fieldName}RawValue`]: `0${currentRawValue}` });

        cleave.setRawValue(`0${currentRawValue}`);
      }
    }
  }

  onCleaveChange(event) {
    const {
      name,
      form: { setFieldValue }
    } = this.props;

    const { rawValue, value } = event.target;

    this.setState({ [`${this.fieldName}RawValue`]: rawValue });

    setFieldValue(name, value);
  }

  onCleaveInit(cleave) {
    const {
      name,
      form: { initialValues, setFieldValue },
      confirmedPII
    } = this.props;

    const value = initialValues[name];

    this.setState({ [`${name}Cleave`]: cleave });

    // eslint-disable-next-line no-param-reassign
    cleave.lastInputValue = '';

    if (confirmedPII && confirmedPII[name]) {
      this.setState({
        maskedValue: initialValues[name]
      });

      if (value) {
        cleave.setRawValue(
          `****${value.slice(value.length - 4, value.length)}`
        );

        setFieldValue(name, initialValues[name]);
      }
    }
  }

  initCustomOption() {
    const { options } = this.props;

    if (!this._cleave) {
      return;
    }

    if (options.custom) {
      this._cleave.properties = {
        ...this._cleave.properties,
        date: true,
        formatDate: true,
        delimiter: '/',
        datePattern: ['m', 'd', 'Y']
      };

      this._cleave.initDateFormatter();
    }

    if (options && options.blocks && options.blocks.includes(99999)) {
      this._cleave.properties = {
        ...this._cleave.properties,
        delimiter: ''
      };
    }

    this.setState({
      customOptionInitialized: true
    });
  }

  render() {
    const {
      type,
      maxLength,
      label,
      hidden,
      options,
      onFocus,
      onBlur,
      value,
      className,
      autoComplete,
      name,
      ariaLabel,
      ariaRequired,
      id,
      placeholder
    } = this.props;

    return (
      <Cleave
        id={id}
        ref={c => {
          this._cleave = c;
        }}
        name={name}
        type={type || 'text'}
        label={label}
        autoComplete={autoComplete || 'off'}
        hidden={hidden}
        maxLength={maxLength || 524288}
        value={value}
        options={options}
        onInit={this.onCleaveInit}
        onChange={this.onCleaveChange}
        onKeyDown={this.handleOnKeyDown}
        className={className}
        onFocus={onFocus}
        onBlur={onBlur}
        aria-label={ariaLabel}
        aria-required={ariaRequired}
        placeholder={placeholder}
      />
    );
  }
}

export default CleaveInput;
