import React from 'react';
import styled from 'styled-components/macro';

const StyledLiabilityAgreement = styled.p`
  margin: 0;
  cursor: default;
  a {
    display: inline;
  }
`;

function ReferralDisclaimer() {
  return (
    <StyledLiabilityAgreement>
      {
        '* No purchase necessary. Qualification requirements apply. Not available in all states. Your rewards will be paid in '
      }
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.amazon.com"
      >
        Amazon.com
      </a>
      {
        ' Gift Cards, with an aggregate maximum limit (varies by state). Restrictions apply, see amazon.com/gc-legal. Subject to Refer-a-Friend Terms of Use at  '
      }
      <a
        className="link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.noblr.com/refer-a-friend-terms-of-use/"
      >
        noblr.com/refer-a-friend-terms-of-use/
      </a>
    </StyledLiabilityAgreement>
  );
}

export default ReferralDisclaimer;
