import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { validationTools } from '@noblr-lab/frontend-tools';
import Form from 'components/Form/Form';
import {
  makeSelectActiveVehicles,
  makeSelectActiveDrivers,
  selectPrimaryVehicleFields
} from 'redux/selectors';
import {
  StyledContentWrapper,
  AssignmentLabel,
  BlockText,
  DriverName
} from 'styles';

class PrimaryCarAssignment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: null,
      submitAction: 'SET_PRIMARY_VEHICLE_ASSIGNMENT',
      successRoute: '/driving-behavior/start',
      prompt:
        'Please tell us which car is primarily driven by the following drivers.'
    };

    this.buildPrimaryCarAssignmentFields =
      this.buildPrimaryCarAssignmentFields.bind(this);
  }

  componentDidMount() {
    const {
      additionalDrivers,
      vehicles,
      isUSAAMember,
      usaaPersonInfoVerified
    } = this.props;

    const isUSAAQuoteIntegrationFlow = isUSAAMember && usaaPersonInfoVerified;

    const fields = this.buildPrimaryCarAssignmentFields();
    const numCars = vehicles.length;
    const numDrivers = additionalDrivers.length + 1; // add one for the primary driver
    // if we have excess vehicles (more vehicles than drivers *and* more than one driver)
    // we will store the data locally and redirect to excess assignment page
    // if we don't have excess vehicles we can use the existing successRoute and action
    const hasExcessCars = numCars > numDrivers && numDrivers > 1;
    let updatedSuccessRoute = null;
    let updatedSubmitAction = null;

    if (hasExcessCars) {
      updatedSuccessRoute = '/add-vehicles/car-assignment-followup';
      // also update action if condition is met
      updatedSubmitAction = 'SET_PRIMARY_VEHICLE_ASSIGNMENT_LOCALLY';
    } else if (isUSAAQuoteIntegrationFlow) {
      updatedSuccessRoute = '/driving-behavior/mileage';
    }

    this.setState(prevState => ({
      fields,
      successRoute: updatedSuccessRoute || prevState.successRoute,
      submitAction: updatedSubmitAction || prevState.submitAction
    }));
  }

  buildPrimaryCarAssignmentFields() {
    const { fieldData } = this.props;

    return fieldData.map(({ driverId, firstName, cars, dob, options }) => ({
      name: driverId,
      id: `assignment-select-${driverId}`,
      required: true,
      selectLabel: ' vehicle',
      selectLabelId: `vehicle-${driverId}-label`,
      options,
      type: 'select',
      description: (
        <AssignmentLabel primary>
          {`Car primarily driven by `}
          <DriverName>{firstName}</DriverName>
          <BlockText>{`(${dob})`}</BlockText>
        </AssignmentLabel>
      ),
      initialValue: cars && cars.length > 0 ? cars[0].vehicleId : null
    }));
  }

  render() {
    const { submitAction, successRoute, prompt, fields } = this.state;

    if (!fields) {
      return null;
    }

    const validationSchema = validationTools.primaryCarAssignmentSchema(
      fields,
      {
        unique: 'Each driver requires a unique assigned car.',
        eachUsedOnce: 'Each car requires a unique assigned driver.'
      }
    );

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          validationSchema={validationSchema}
          submitAsync
          renderFieldRow
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = () => {
  const selectActiveDrivers = makeSelectActiveDrivers();
  const selectActiveVehicles = makeSelectActiveVehicles();

  const realMapState = state => {
    const {
      app: { isUSAAMember, usaaPersonInfoVerified }
    } = state;

    return {
      additionalDrivers: selectActiveDrivers(state),
      vehicles: selectActiveVehicles(state),
      fieldData: selectPrimaryVehicleFields(state),
      isUSAAMember,
      usaaPersonInfoVerified
    };
  };

  return realMapState;
};

PrimaryCarAssignment.propTypes = {
  additionalDrivers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fieldData: PropTypes.arrayOf(
    PropTypes.exact({
      driverId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      dob: PropTypes.string.isRequired,
      cars: PropTypes.arrayOf(PropTypes.shape({})),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      ).isRequired,
      required: PropTypes.bool,
      initialValue: PropTypes.string
    })
  ).isRequired,
  isUSAAMember: PropTypes.bool.isRequired,
  usaaPersonInfoVerified: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(PrimaryCarAssignment);
