import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Form from 'components/Form/Form';
import {
  redirect,
  updateEditingVehicle,
  getYearMakeModel,
  getSelectedMakeModel,
  getSelectedModelStyle,
  getVinStem
} from 'redux/actions';
import { generateNumericSelectOptions } from 'utilities';
import { StyledContentWrapper } from 'styles';

class NewVehiclePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Tell us about the car you want to cover.',
      submitAction: 'SAVE_VIN_STEM',
      fields: [],
      staticContent: {
        form: [
          {
            type: 'StandaloneLink',
            location: 'form',
            extraData: {
              text: 'Or enter with VIN',
              link: '/add-vehicles/vin'
            }
          }
        ]
      },
      successRoute: '/add-vehicles/vehicle-ownership'
    };
  }

  componentDidMount() {
    const yearCutoff = 2025;

    const yearOptions = generateNumericSelectOptions(1991, yearCutoff).sort(
      (a, b) => b.value - a.value
    );

    const { editingVehicle, yMMOptions } = this.props;

    this.setState({
      fields: [
        {
          type: 'asyncSelect',
          name: 'modelYear',
          id: 'model-year-select-input',
          label: 'Year',
          labelId: 'model-year-label',
          isSearchable: true,
          required: true,
          disabled: false,
          initialValue: editingVehicle && editingVehicle.modelYear,
          options: [
            ...yearOptions,
            {
              type: 'manual',
              value: 'vin',
              label: "I don't see my year here"
            }
          ],
          onInputChange: this.handleYMMSDropdowns
        },
        {
          type: 'asyncSelect',
          name: 'make',
          id: 'make-select-input',
          label: 'Make',
          labelId: 'make-label',
          required: true,
          initialValue: editingVehicle && editingVehicle.make,
          options:
            yMMOptions && yMMOptions.YMM_make.length
              ? [
                  ...yMMOptions.YMM_make,
                  {
                    type: 'manual',
                    value: 'vin',
                    label: "I don't see my make here"
                  }
                ]
              : [
                  {
                    type: 'manual',
                    value: 'vin',
                    label: "I don't see my make here"
                  }
                ],
          onInputChange: this.handleYMMSDropdowns,
          disabled: editingVehicle && editingVehicle.modelYear.length === 0
        },
        {
          type: 'asyncSelect',
          name: 'model',
          id: 'model-select-input',
          label: 'Model',
          labelId: 'model-label',
          required: true,
          initialValue: editingVehicle && editingVehicle.model,
          options:
            yMMOptions && yMMOptions.YMM_model
              ? [
                  ...yMMOptions.YMM_model,
                  {
                    type: 'manual',
                    value: 'vin',
                    label: "I don't see my model here"
                  }
                ]
              : [
                  {
                    type: 'manual',
                    value: 'vin',
                    label: "I don't see my model here"
                  }
                ],
          onInputChange: this.handleYMMSDropdowns,
          disabled: editingVehicle && editingVehicle.make.length === 0
        },
        {
          type: 'asyncSelect',
          name: 'style',
          id: 'style-select-input',
          label: 'Style',
          labelId: 'style-label',
          isSearchable: true,
          required: true,
          initialValue: editingVehicle && editingVehicle.style,
          options:
            yMMOptions && yMMOptions.YMM_style
              ? [
                  ...yMMOptions.YMM_style,
                  {
                    type: 'manual',
                    value: 'vin',
                    label: "I don't see my style here"
                  }
                ]
              : [
                  {
                    type: 'manual',
                    value: 'vin',
                    label: "I don't see my style here"
                  }
                ],
          onInputChange: this.handleYMMSDropdowns,
          disabled: editingVehicle && editingVehicle.model.length === 0
        }
      ]
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    const { yMMOptions } = this.props;
    const { fields } = this.state;
    let updatedFields = fields;

    // update make options
    // todo: modularize this
    if (yMMOptions && yMMOptions.YMM_make) {
      updatedFields = updatedFields.map(field =>
        field.name === 'make'
          ? {
              ...field,
              disabled: false,
              options: [
                ...yMMOptions.YMM_make,
                {
                  type: 'manual',
                  value: 'vin',
                  label: "I don't see my make here"
                }
              ]
            }
          : field
      );
    }

    // update model options
    if (yMMOptions && yMMOptions.YMM_model) {
      updatedFields = updatedFields.map(field =>
        field.name === 'model'
          ? {
              ...field,
              disabled: false,
              options: [
                ...yMMOptions.YMM_model,
                {
                  type: 'manual',
                  value: 'vin',
                  label: "I don't see my model here"
                }
              ]
            }
          : field
      );
    }

    if (yMMOptions && yMMOptions.YMM_style) {
      updatedFields = updatedFields.map(field =>
        field.name === 'style'
          ? {
              ...field,
              disabled: false,
              options: [
                ...yMMOptions.YMM_style,
                {
                  type: 'manual',
                  value: 'vin',
                  label: "I don't see my style here"
                }
              ]
            }
          : field
      );
    }

    this.setState({
      fields: updatedFields
    });
  }

  // TODO: Fix consistent-return ES Lint rule that is disabled
  // eslint-disable-next-line consistent-return
  handleYMMSDropdowns = (fieldName, selectedOption, form) => {
    const {
      updateEditingVehicle,
      redirect,
      getYearMakeModel,
      getSelectedMakeModel,
      getSelectedModelStyle,
      getVinStem
    } = this.props;

    const { values } = form;

    // user selected "i don't see my x here"
    if (selectedOption && selectedOption === 'vin') {
      // need to build payload here
      const ymmsValues = Object.entries(values).reduce(
        (acc, [key, { value }]) => {
          if (value) {
            acc[key] = value;
          }

          return acc;
        },
        {}
      );

      // first save values we have
      updateEditingVehicle(ymmsValues);

      return redirect('/add-vehicles/vin');
    }

    // generate options for the other dropdowns
    if (fieldName === 'modelYear') {
      // generate make/model/style options
      getYearMakeModel(selectedOption);

      // set year and clear any other selections
      form.setFieldValue('make', '');

      form.setFieldValue('model', '');

      form.setFieldValue('style', '');

      updateEditingVehicle({
        modelYear: selectedOption
      });
    } else if (fieldName === 'make') {
      getSelectedMakeModel(selectedOption);

      updateEditingVehicle({
        make: selectedOption
      });

      form.setFieldValue('model', '');

      form.setFieldValue('style', '');
    } else if (fieldName === 'model') {
      getSelectedModelStyle(selectedOption);

      updateEditingVehicle({
        model: selectedOption
      });

      form.setFieldValue('style', '');
    } else if (fieldName === 'style') {
      getVinStem(selectedOption);

      updateEditingVehicle({
        style: selectedOption
      });
    }
  };

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    if (fields.length === 0) {
      return null;
    }

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          staticContent={form}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({ vehicles: { editingVehicle, YMM } }) => ({
  editingVehicle,
  yMMOptions: YMM
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect,
      updateEditingVehicle,
      getYearMakeModel,
      getSelectedMakeModel,
      getSelectedModelStyle,
      getVinStem
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NewVehiclePage);
