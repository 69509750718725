import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Container } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import { Prompt, TextLink } from 'styles';
import { colors, breakPoints, openSans } from 'styles/variables';

const StyledContainer = styled(Container)`
  max-width: none;
  margin: 0 auto;

  > div {
    margin: 0 auto;
  }

  p {
    font-size: ${props => (props.smFont ? '16px' : '18px')};
    line-height: 1.56;
    letter-spacing: 0.45px;
    font-weight: normal;
    margin: 0 auto;

    @media screen and (min-width: ${breakPoints.medium}) {
      width: 650px;
    }
  }
`;

const StyledHeader = styled(Prompt)`
  margin: 40px auto;
  max-width: 80%;

  @media screen and (min-width: ${breakPoints.large}) {
    max-width: none;
  }
`;

const StyledTextLink = styled(TextLink)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${colors.alphaBlue500};
  cursor: pointer;
`;

const StyledMessage = styled.p`
  margin: 0 auto;
  max-width: 675px;
  text-align: center;
  font-size: 14px;
  font-family: ${openSans};
  line-height: 1.43;
  letter-spacing: 0.35px;
  color: ${colors.coreBlue700};

  ${props =>
    props.paddingBottom &&
    css`
      padding-bottom: 30px;
    `}
  }
`;

const Wrapper = styled.div`
  align-items: center;
  padding-bottom: ${props => (props.paddingBottom ? '30px' : 'none')};
`;

function AgencyDoNotQuote() {
  const header = useSelector(state => state.app?.doNotQuote?.header);

  const message = useSelector(state => state.app?.doNotQuote?.message);

  const dnqHeader = header || "We can't give you a quote today.";

  const messageDetails =
    'USAA has other options to help you get your auto coverages, discounts and savings to fit your needs.';

  return (
    <>
      <StyledHeader>{dnqHeader}</StyledHeader>
      <StyledContainer>
        {message && (
          <StyledMessage
            paddingBottom
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        )}
        <Wrapper>
          <StyledMessage>{messageDetails}</StyledMessage>
        </Wrapper>
        <Wrapper paddingBottom>
          <Button
            primary
            large
            as="a"
            className="get-quote-button"
            type="button"
            href="https://www.usaa.com/insurance/pilot-state?bundlingIntent=auto"
            target="_blank"
            rel="noopener noreferrer"
            id="get-usaa-quote-btn"
          >
            GET A QUOTE
          </Button>
        </Wrapper>
        <Wrapper>
          <StyledTextLink
            as="a"
            className="learn-more-link"
            href="https://www.usaa.com/inet/wc/auto-insurance"
            target="_blank"
            rel="noopener noreferrer"
          >
            OR LEARN MORE
          </StyledTextLink>
        </Wrapper>
      </StyledContainer>
    </>
  );
}

export default AgencyDoNotQuote;
