import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper, StyledLink } from 'styles';

class DefensiveDrivingCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt:
        'Have you participated in a safe driver program in the last 3 years?',
      submitAction: 'UPDATE_PRIMARY_DRIVER_REQUEST',
      fields: [
        {
          type: 'radio',
          name: 'safeDriver',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ],
          required: true,
          radioGroupAriaLabel: 'Safe Driver Discount'
          // todo: add initial value for radios?
        }
      ],
      successRoute: '/add-drivers/drivers-list'
    };
  }

  componentDidMount() {
    const { prompt, successRoute, submitAction, fields } = this.state;
    const {
      policyState,
      location,
      editingDriver,
      defensiveCourseTaken,
      usaaPersonInfoRetrieved
    } = this.props;

    const isDefensiveDriving =
      location.pathname === '/add-drivers/defensive-driving-course';

    const isSafeDriving = location.pathname === '/add-drivers/safe-driving';
    let updatedPrompt = prompt;
    let updatedSuccessRoute = successRoute;
    let updatedSubmitAction = submitAction;
    let updatedFields = [...fields];

    if (policyState === 'VA' || policyState === 'AR') {
      if (isDefensiveDriving) {
        updatedPrompt =
          'Has this driver voluntarily completed a driver improvement course in the last 3 years?';
        updatedSubmitAction = 'UPDATE_ADDITIONAL_DRIVER_INFO';
      } else {
        updatedPrompt =
          'Have you voluntarily completed a driver improvement course in the last 3 years?';
      }
    } else if (isDefensiveDriving) {
      updatedPrompt = `Has ${editingDriver.firstName} participated in a safe driver program in the last 3 years?`;
      updatedSubmitAction = 'UPDATE_ADDITIONAL_DRIVER_INFO';
    } else if (isSafeDriving) {
      updatedPrompt =
        'Has every driver on your quote completed a safe driving course in the last 3 years?';
      updatedSuccessRoute = usaaPersonInfoRetrieved
        ? '/add-vehicles/vehicles-list'
        : '/add-vehicles/start';
      updatedSubmitAction = 'UPDATE_DEFENSIVE_DRIVING_ANSWER';
      updatedFields = [
        {
          type: 'radio',
          name: 'defensiveCourseTaken',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ],
          required: true,
          initialValue: defensiveCourseTaken,
          radioGroupAriaLabel: 'Safe Driving Discount'
        }
      ];
    }
    this.setState({
      prompt: updatedPrompt,
      successRoute: updatedSuccessRoute,
      submitAction: updatedSubmitAction,
      fields: updatedFields
    });
  }

  render() {
    const {
      editingDriver,
      location: { pathname },
      policyState,
      section
    } = this.props;

    const { prompt, submitAction, fields, successRoute } = this.state;

    if (
      pathname === '/add-drivers/defensive-driving-course' &&
      !editingDriver.firstName
    ) {
      return <Redirect to="/add-drivers/drivers-list" replace />;
    }

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          renderFieldRow={false}
          maxWidth="500px"
        />

        <InfoBox
          extraData={{
            header: 'Why Are We Asking This?'
          }}
        >
          {policyState === 'VA' ? (
            <span>
              {`If ${section === 'add-drivers' ? 'this driver has' : 'you have'}
            voluntarily completed a driver improvement course in the last 3 years, your Noblr policy may be eligible for a discount. Please see the `}
              <StyledLink
                href="https://www.dmv.virginia.gov/safety/#programs/mature/index.asp"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="opens a new window to VA Department of Motor Vehicles"
              >
                VA Department of Motor Vehicles
              </StyledLink>
              {` to see if ${
                section === 'add-drivers' ? 'their' : 'your'
              } course qualifies.`}
            </span>
          ) : (
            <span>
              {`${
                section !== 'add-drivers' ? `If you have` : `If this driver has`
              } voluntarily completed a driver improvement course in
              the last 3 years, your policy may be eligible for a discount. We
              may require you to provide a copy of your Certificate of
              Completion.`}
            </span>
          )}
        </InfoBox>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { usaaPersonInfoRetrieved, defensiveCourseTaken },
  drivers: {
    editingDriver,
    primaryDriver: { state }
  }
}) => ({
  editingDriver,
  policyState: state,
  defensiveCourseTaken,
  usaaPersonInfoRetrieved
});

DefensiveDrivingCourse.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  policyState: PropTypes.string,
  editingDriver: PropTypes.shape({
    firstName: PropTypes.string
  }),
  section: PropTypes.string,
  defensiveCourseTaken: PropTypes.bool,
  usaaPersonInfoRetrieved: PropTypes.bool
};

DefensiveDrivingCourse.defaultProps = {
  location: {
    pathname: ''
  },
  policyState: '',
  editingDriver: {
    firstName: ''
  },
  section: '',
  defensiveCourseTaken: null,
  usaaPersonInfoRetrieved: null
};

export default connect(mapStateToProps)(DefensiveDrivingCourse);
