import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignPage from 'components/Pages/SignPage';
import { StyledContentWrapper } from 'styles';

class MembershipApplicationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'SUBMIT_PREBIND_SIGNATURE',
      successRoute: '/purchase/umbrella-policy',
      fields: [
        {
          type: 'sign',
          name: 'membershipApplication',
          id: 'membership-application-input',
          label: 'First and Last Name',
          required: true,
          validation: 'fullName',
          autoComplete: 'name',
          fullWidth: true
        }
      ],
      staticContent: {
        form: [
          {
            type: 'static',
            location: 'form',
            value:
              'By continuing, I acknowledge that I have read and agree to the Membership Subscription Agreement and Power of Attorney and agree to become a member of Noblr. I also agree that entering my name above serves as and replaces the need for my physical signature and signifies my authorization going forward.'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { requiresUmuimOfferForm, requiresMedPayOfferForm, state } =
      this.props;

    let updatedSuccessRoute = null;
    const lowercaseState = state.toLowerCase();

    if (lowercaseState !== 'va') {
      if (requiresMedPayOfferForm && requiresUmuimOfferForm) {
        updatedSuccessRoute = `/purchase/sign-${lowercaseState}-medpay-form`;
      } else if (requiresUmuimOfferForm) {
        updatedSuccessRoute = `/purchase/sign-${lowercaseState}-offer-form`;
      }
    }

    if (updatedSuccessRoute) {
      this.setState({
        successRoute: updatedSuccessRoute
      });
    }
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    return (
      <StyledContentWrapper>
        <SignPage
          pdfFileName="membershipApplication"
          prompt={prompt}
          formStaticContent={form}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          documentName="Membership Subscription Agreement and Power of Attorney"
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  drivers: {
    primaryDriver: { state, requiresUmuimOfferForm, requiresMedPayOfferForm }
  }
}) => ({
  state,
  requiresUmuimOfferForm,
  requiresMedPayOfferForm
});

MembershipApplicationPage.propTypes = {
  state: PropTypes.string.isRequired,
  requiresUmuimOfferForm: PropTypes.bool.isRequired,
  requiresMedPayOfferForm: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(MembershipApplicationPage);
