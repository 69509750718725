import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import StartOver from 'components/Static/StartOver';
import { clearFormError } from 'redux/actions';
import { isDev } from 'utilities';
import { StyledContentWrapper } from 'styles';

const devMode = isDev();

class NamePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: null,
      newDriver: true,
      submitAction: null,
      fields: [
        {
          type: 'text',
          name: 'firstName',
          id: 'driver-first-name-input',
          label: 'First Name',
          required: true,
          hidden: false,
          autoComplete: 'given-name'
        },
        {
          type: 'text',
          name: 'lastName',
          id: 'driver-last-name-input',
          label: 'Last Name',
          required: true,
          hidden: false,
          autoComplete: 'family-name'
        }
      ],
      successRoute: '/start-quote/date-of-birth'
    };
  }

  componentDidMount() {
    const { fields } = this.state;
    const { section, primaryDriver, editingDriver, app, clearFormError } =
      this.props;

    const isStartingQuote = section === 'start-quote';

    if (app?.form?.error) {
      clearFormError();
    }

    // Assume driver is creating a new quote
    let newDriver = true;
    // Set prompt for new primary driver
    let prompt = `First, let's get the basics. \n What's your name?`;
    // Set submit action for new primary driver, check quoteId to determine whether Google ReCaptcha is disabled on dob page for Selenium tests
    let submitAction = 'UPDATE_PRIMARY_DRIVER_AND_VERIFY_QUOTE';
    // Set success route using the current section
    const successRoute = `/${section}/date-of-birth`;

    // Initialized fields will contain primaryDriver/editingDriver values
    // (if available in Redux)
    let initializedFields = null;

    // If the current section is start-quote
    if (isStartingQuote) {
      // If a driverId is stored in Redux
      if (primaryDriver.driverId) {
        // Update prompt, submitAction and initialize Fields with saved info
        prompt = 'Welcome back! \n Continue to your quote.';

        newDriver = false;

        // Set initial field values on state using primary driver info
        initializedFields = fields.map(field =>
          // Set both the initialValue and value of field
          ({
            ...field,
            initialValue: primaryDriver[field.name]
          })
        );
      } else {
        //  Quote was not created
        // But we still need to check for firstName, lastName
        // and initialize field values
        initializedFields =
          primaryDriver.firstName && primaryDriver.lastName
            ? // initialize field values with primary driver info
              fields.map(field => ({
                ...field,
                initialValue: primaryDriver[field.name]
              }))
            : // Primary driver info is not there
              // Use original state
              [...fields];
      }
    } else {
      prompt = "What's this driver's name?";

      submitAction = 'SAVE_ADDITIONAL_DRIVER_INFO';

      // Additional driver is being edited
      if (editingDriver?.driverId) {
        newDriver = false;
        submitAction = 'UPDATE_ADDITIONAL_DRIVER_INFO';

        initializedFields = fields.map(field =>
          // initialize field with editing driver info or empty string
          ({
            ...field,
            initialValue: editingDriver[field.name]
          })
        );
      } else {
        // Additional Driver is new

        initializedFields = fields.map(field =>
          // Initialize field with person
          // because editing driver Object hasn't been created
          ({
            ...field,
            initialValue: ''
          })
        );
      }
    }
    // Set state with appropriate values
    this.setState({
      isStartingQuote,
      prompt,
      submitAction,
      successRoute,
      fields: initializedFields,
      newDriver
    });
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      newDriver,
      isStartingQuote
    } = this.state;

    const { gotRate, totalQuoteRate, editingDriver } = this.props;

    const hasQuote = gotRate || totalQuoteRate;

    const displayStartOver = isStartingQuote && (hasQuote || devMode);
    const addingDrivers = !isStartingQuote;

    return addingDrivers && !editingDriver && !newDriver ? (
      <Redirect to="/add-drivers/drivers-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          makeApiCall={addingDrivers}
        />
        {displayStartOver && <StartOver />}
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app,
  drivers: { primaryDriver, person, editingDriver },
  rate: { gotRate, totalQuoteRate }
}) => ({
  app,
  gotRate,
  totalQuoteRate,
  primaryDriver,
  editingDriver,
  person
});

const mapDispatchToProps = dispatch => ({
  clearFormError: () => dispatch(clearFormError())
});

NamePage.propTypes = {
  primaryDriver: PropTypes.shape({
    driverId: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }).isRequired,
  editingDriver: PropTypes.shape({
    driverId: PropTypes.string
  }),
  app: PropTypes.shape({
    form: PropTypes.shape({
      error: PropTypes.shape({})
    })
  }),
  clearFormError: PropTypes.func.isRequired,
  section: PropTypes.string,
  gotRate: PropTypes.bool,
  totalQuoteRate: PropTypes.string
};

NamePage.defaultProps = {
  editingDriver: {
    driverId: ''
  },
  app: {
    form: {
      error: {}
    }
  },
  section: '',
  gotRate: false,
  totalQuoteRate: ''
};

export default connect(mapStateToProps, mapDispatchToProps)(NamePage);
