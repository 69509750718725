import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import Form from 'components/Form/Form';
import FetchingQuote from 'components/FetchingQuote';
import { getPersona } from 'redux/actions';
import { StyledContentWrapper } from 'styles';

export default function Persona() {
  const gotRate = useSelector(state => state?.rate?.gotRate);

  const personaSaved = useSelector(state => state?.coverage?.personaSaved);

  const personaCode = useSelector(state => state?.coverage?.personaCode);

  const loading = useSelector(state => state?.rate?.loading);

  const dispatch = useDispatch();

  const prompt = "Let's talk about how you drive.";

  const fields = [
    {
      type: 'materialSlider',
      name: 'personaCode',
      id: 'persona-input',
      initialValue: 1.5,
      hidden: false
    }
  ];

  const [initialFields, setInitialFields] = useState(fields);

  const [submitAction, setSubmitAction] = useState('SAVE_PERSONA');

  useEffect(() => {
    if (!gotRate || !personaSaved) {
      dispatch(getPersona());
    }

    if (gotRate || (personaSaved && personaCode)) {
      const initializedFields = initialFields.map(field => ({
        ...field,
        initialValue: personaCode
      }));

      setSubmitAction('UPDATE_PERSONA');
      setInitialFields(initializedFields);
    }

    // no deps because we only want this effect to run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      {loading ? (
        <FetchingQuote />
      ) : (
        <StyledContentWrapper>
          <Form
            submitAsync
            prompt={prompt}
            fields={initialFields}
            submitAction={submitAction}
            // successRoute handled in rate call
            buttonMarginTop="50px"
          />
        </StyledContentWrapper>
      )}
    </Grid>
  );
}
