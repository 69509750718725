import { USAA_SIGNATURE_START_DISCLAIMER } from '../../constants';

export default {
  name: 'purchase',
  pages: [
    {
      name: 'verify-drivers-license',
      overrideSectionTitle: {
        type: 'static',
        value: 'VERIFY DRIVERS LICENSE'
      }
    },
    {
      name: 'dl-verification-issue',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'static',
        value: 'Verification Issue'
      }
    },
    {
      name: 'vin-confirm'
    },
    {
      name: 'effective-date',
      overrideSectionTitle: {
        type: 'static',
        value: 'Start Date'
      }
    },
    {
      name: 'payment-schedule',
      overrideSectionTitle: {
        type: 'static',
        value: 'Payment Options'
      },
      maxWidth: 'auto'
    },
    {
      name: 'signature-start',
      overrideSectionTitle: {
        type: 'static',
        value: 'Signature Required'
      },
      prompt: {
        type: 'static',
        value: 'We need you to sign a few documents before coverage can begin.'
      },
      minHeight: '420px'
    },
    {
      type: 'Static',
      name: 'signature-start-nv',
      overrideSectionTitle: {
        type: 'static',
        value: 'Signature Required'
      },
      prompt: {
        type: 'static',
        value: 'We need you to sign a few documents before coverage can begin.'
      },
      subHeader:
        'Read then sign the UMBI and UIMBI Coverage Selection Forms, Notice to Named Insured, Insurance Application and the Member Subscription Agreement and Power of Attorney documents. Coverage can only begin once we receive your signatures and payment.',
      subHeader2: USAA_SIGNATURE_START_DISCLAIMER,
      componentDidMountActions: ['UPDATE_PREBIND_RATE_FIELDS'],
      minHeight: '420px',
      successRoute: {
        type: 'static',
        value: '/purchase/sign-insurance-application'
      }
    },
    {
      name: 'sign-umbi-selection',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'sign-uimbi-selection',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'sign-um-selection',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'sign-pip-notice',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'sign-form',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'notice-to-insured',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'sign-insurance-application',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'sign-membership-application',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      maxWidth: '100%'
    },
    {
      name: 'sign-nv-medpay-form',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      prompt: {
        type: 'static',
        value: `Nevada Medical Payments Coverage Selection/Rejection Form`
      },
      staticContent: [
        {
          type: 'static',
          location: 'form',
          value:
            'By continuing, I acknowledge that I have read and agree to the Nevada Medical Payments Coverage Selection/Rejection form and agree that entering my name above serves as and replaces the need for my physical signature and signifies my authorization.'
        }
      ],
      successRoute: {
        type: 'static',
        value: '/purchase/sign-nv-um-form'
      }
    },
    {
      name: 'sign-nv-um-form',
      overrideSectionTitle: {
        type: 'static',
        value: 'SIGNATURE REQUIRED'
      },
      prompt: {
        type: 'static',
        value: `Nevada Uninsured/Underinsured Motorist Coverage Selection/Rejection Form`
      },
      staticContent: [
        {
          type: 'static',
          location: 'form',
          value:
            'By continuing, I acknowledge that I have read and agree to the Nevada Uninsured/Underinsured Motorist Coverage Selection/Rejection form and agree that entering my name above serves as and replaces the need for my physical signature and signifies my authorization.'
        }
      ],
      successRoute: {
        type: 'static',
        value: '/purchase/umbrella-policy'
      }
    },
    {
      name: 'umbrella-policy',
      overrideSectionTitle: {
        type: 'static',
        value: 'USAA Umbrella Policy'
      }
    },
    {
      name: 'umbrella-policy-acknowledgement',
      overrideSectionTitle: {
        type: 'static',
        value: 'USAA Umbrella Policy Acknowledgement '
      }
    },
    {
      name: 'payment-info',
      overrideSectionTitle: {
        type: 'static',
        value: 'Payment Details'
      }
    },
    {
      name: 'review-and-pay',
      overrideSectionTitle: {
        type: 'static',
        value: 'Review'
      }
    },
    {
      name: 'thank-you',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'static',
        value: 'Thank you'
      }
    }
  ]
};
