import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LandingPage from 'components/Pages/LandingPage';
import { getLeadData } from 'redux/actions';
import { getUrlParameter } from 'utilities';

class PartnerLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [
        {
          type: 'text',
          name: 'firstName',
          label: 'First Name',
          required: true
        },
        {
          type: 'text',
          name: 'lastName',
          label: 'Last Name',
          required: true
        },
        {
          type: 'text',
          name: 'dob',
          label: 'date of birth',
          placeholder: 'MM/DD/YYYY',
          required: true,
          formattingOptions: {
            custom: true,
            maskedDate: true
          }
        }
      ],
      staticContent: {
        form: [
          {
            type: 'LiabilityAgreement',
            location: 'form',
            extraData: {
              liabilityAgreement: {
                continueText: 'Get Started'
              }
            }
          }
        ]
      }
    };
  }

  componentDidMount() {
    const {
      hasPartnerData,
      location: { search },
      getLeadData,
      primaryDriver
    } = this.props;

    const { fields } = this.state;

    // we either have an id from everquote or leadcloud, try to get both
    const leadCloudId = getUrlParameter(search, 'leadCloudId');
    const everQuoteId = getUrlParameter(search, 'everQuoteId');

    if (hasPartnerData && primaryDriver.firstName && primaryDriver.lastName) {
      const updatedFields = fields.map(field =>
        primaryDriver[field.name]
          ? { ...field, initialValue: primaryDriver[field.name] }
          : field
      );

      this.setState({ fields: updatedFields });
    } else {
      // if we have a lead cloud id, fire off get lead cloud data action
      if (leadCloudId.length) {
        getLeadData({ leadId: leadCloudId, leadType: 'leadCloudId' });
      }

      if (everQuoteId.length) {
        getLeadData({
          leadId: everQuoteId,
          leadType: 'everQuoteLeadId'
        });
      }
    }
  }

  static getDerivedStateFromProps({ primaryDriver }, { fields }) {
    let derivedFields = fields;

    if (primaryDriver.firstName && primaryDriver.lastName) {
      derivedFields = derivedFields.map(field =>
        primaryDriver[field.name]
          ? { ...field, initialValue: primaryDriver[field.name] }
          : field
      );
    }

    return {
      fields: derivedFields
    };
  }

  render() {
    const {
      staticContent: { form },
      fields
    } = this.state;

    const {
      location: { search }
    } = this.props;

    /* TODO: Setup fetchingLeadData boolean in app reducer
        and show loader if true
    */
    return !search.length ? (
      <Redirect to="/start-quote/name" replace />
    ) : (
      <LandingPage
        prompt="App-powered car insurance. Drive better, save more."
        subHeader="Let's confirm a few things, and we'll get you a quote:"
        successRoute="/start-quote/address-entry"
        form={{
          staticContent: form,
          fields,
          submitAsync: true,
          submitAction: 'REGISTER_QUOTE',
          buttonText: 'Get Started'
        }}
      />
    );
  }
}

const mapStateToProps = ({
  app: { hasPartnerData },
  drivers: { primaryDriver }
}) => ({ hasPartnerData, primaryDriver });

const mapDispatchToProps = dispatch => ({
  getLeadData: ({ leadId, leadType }) =>
    dispatch(getLeadData({ leadId, leadType }))
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerLandingPage);
