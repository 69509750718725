import React from 'react';
import { SummaryText } from 'components/Pages/Quote/styles';

function ConsumerFinance() {
  return (
    <div>
      <SummaryText sm extraMargin>
        {`For more information about consumer reports and your rights under Federal law, visit the Consumer Financial Protection Bureau's website at `}
        <a
          href="https://www.consumerfinance.gov/learnmore"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.consumerfinance.gov/learnmore
        </a>
        .
      </SummaryText>
    </div>
  );
}

export default ConsumerFinance;
