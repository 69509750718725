import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
import Form from 'components/Form/Form';
import StaticComponent from 'components/StaticComponent';
import Loader from 'components/Loader';
import ErrorPage, { ErrorMessage } from 'components/Pages/ErrorPage';
import {
  SubHeader,
  Prompt,
  Mobile,
  Desktop,
  StyledContentWrapper
} from 'styles';
import { breakPoints, colors } from 'styles/variables';

const StageWrapper = styled.div`
  background-color: ${colors.usaaLightestGrey};

  @media only screen and (min-width: ${breakPoints.extraLarge}) {
    padding-top: 100px;
    height: auto;
    width: 100vw;

    ${Desktop} {
      position: absolute;
      display: block;
      z-index: 2;
      top: 50%;
      left: 5%;
      transform: translateY(-50%);
    }

    ${Mobile} {
      display: none;
    }

    ${SubHeader} {
      max-width: 485px;
      margin: 0 auto 50px;
    }
    img {
      z-index: 2;
      height: auto;
      max-width: 20vw;
    }
  }

  ${Prompt},
  ${SubHeader} {
    padding: 0;
    z-index: 2;
  }

  @media screen and (max-width: ${breakPoints.extraLarge}) {
    ${Mobile} {
      position: relative;
      display: block;
    }

    ${Desktop} {
      display: none;
    }

    ${Prompt} {
      margin: 0 auto;
      line-height: 1.33;
      letter-spacing: 0.54px;
      position: relative;
      display: block;
      padding: 0 30px;
      @media only screen and (min-width: ${breakPoints.medium}) {
        width: 90%;
        padding: 0;
      }
    }

    ${SubHeader} {
      letter-spacing: normal;
      text-align: left;
      padding: 25px 25px 0 0;
    }

    img {
      max-width: 100%;
      max-height: 200px;
    }
  }

  .form__submit {
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const PromptWrapper = styled.div`
  background-color: ${colors.usaaLightestGrey};
  padding: 30px;
`;

const FormContainer = styled.div`
  background-color: ${colors.white};

  p {
    padding: 0 10px;
    position: relative;
    z-index: 2;
  }

  button {
    position: relative;
    z-index: 2;
  }

  @media only screen and (max-width: ${breakPoints.extraLarge}) {
    ${Prompt},
    ${SubHeader} {
      display: none;
    }
  }

  @media only screen and (min-width: ${breakPoints.extraLarge}) {
    margin: 0 auto;
    padding: 30px 0;
    max-width: 970px;
    border-radius: 6px;
    border: 2px solid ${colors.usaaLightestGrey};
    transform: translateY(-50px);

    p {
      padding: 0;
    }

    ${Prompt},
    ${SubHeader} {
      margin-bottom: 30px;
    }
  }
`;

const MobileGrid = styled(Grid)`
  padding-top: 20px;
`;

class LandingPage extends Component {
  componentDidMount() {
    const { dispatch, asyncRequest, componentDidMountActions } = this.props;

    if (componentDidMountActions) {
      componentDidMountActions.map(action =>
        dispatch({
          type: action
        })
      );
    }

    if (asyncRequest) {
      dispatch({
        type: 'CLEAR_ASYNC_STATUS'
      });
    }
  }

  render() {
    const {
      history,
      successRoute,
      appError,
      loading,
      prompt,
      subHeader,
      form,
      postContent
    } = this.props;

    const {
      fields,
      validation,
      staticContent,
      submitAction,
      submitAsync,
      buttonText
    } = form;

    if (appError && appError.critical && !appError.DNQ) {
      return <ErrorPage error={appError} history={history} />;
    }

    return loading ? (
      <Loader absolute />
    ) : (
      <StageWrapper>
        <>
          <Mobile>
            {prompt && (
              <PromptWrapper>
                <Prompt>{prompt}</Prompt>
              </PromptWrapper>
            )}
            <MobileGrid
              spacing={2}
              container
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={4} md={6}>
                <img
                  src={`${process.env.REACT_APP_NOBLR_CDN}/images/blue-phone-dispensing-coins.webp`}
                  alt=""
                />
              </Grid>
              <Grid item xs={8} md>
                {subHeader && <SubHeader>{subHeader}</SubHeader>}
              </Grid>
            </MobileGrid>
          </Mobile>
          <Desktop>
            <img
              src={`${process.env.REACT_APP_NOBLR_CDN}/images/blue-phone-dispensing-coins.webp`}
              alt=""
              height="450px"
              width="450px"
              style={{ objectFit: 'contain' }}
            />
          </Desktop>
          {form && (
            <FormContainer>
              <StyledContentWrapper>
                <Form
                  landingPage
                  displayReCaptcha
                  fields={fields}
                  submitAction={submitAction}
                  successRoute={successRoute}
                  history={history}
                  validation={validation}
                  submitAsync={submitAsync}
                  buttonText={buttonText || 'Continue'}
                  buttonMarginTop="0"
                  buttonPosition="static"
                  prompt={prompt}
                  subHeader={subHeader}
                  staticContent={staticContent}
                />
              </StyledContentWrapper>
              {appError && appError.status && appError.message && (
                <ErrorMessage>{appError.message}</ErrorMessage>
              )}
              {/* if there is any post-form
                  // content we will display it here */}
              {postContent &&
                postContent.map(content => (
                  <StaticComponent
                    key={content}
                    staticTextAlign="left"
                    content={content}
                    isLandingPage
                  />
                ))}
            </FormContainer>
          )}
        </>
      </StageWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { error: appError, loading, asyncRequest }
}) => ({
  appError,
  loading,
  asyncRequest
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
