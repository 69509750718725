import React, { Fragment, memo } from 'react';
import styled from 'styled-components/macro';
import { ExpandableTextWrapper } from 'styles';
import { formatOrdinalMonth } from 'utilities';
import { openSans } from 'styles/variables';

const RewardsText = styled.p`
  margin: 20px auto;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.5px;
  text-align: left;

  &&& {
    font-family: ${openSans};
  }
`;

/* todo create utility function that renders 5 ordinal numbers
 with Variable Rate text in jsx */
function PaidUpfrontSection({
  payInFullPrice,
  estInstallmentPaymentSchedule,
  rewards
}) {
  const [, ...monthlyInstallments] = Object.assign(
    [],
    estInstallmentPaymentSchedule
  );

  return (
    <>
      <ExpandableTextWrapper firstItem>
        <p>Due Today: </p>
        <p>{payInFullPrice}</p>
      </ExpandableTextWrapper>
      {monthlyInstallments.map((value, index) => {
        const ordinalMonth = formatOrdinalMonth(index + 1);

        return (
          <Fragment key={ordinalMonth}>
            <ExpandableTextWrapper>
              <p>{`${ordinalMonth} Month: `}</p>
              <p>{value}</p>
            </ExpandableTextWrapper>
          </Fragment>
        );
      })}

      <RewardsText>
        Earn <b>{rewards}</b> in rewards for paying upfront to apply towards the
        variable portion of your rate.
      </RewardsText>
    </>
  );
}

const PaidUpfrontV2 = memo(PaidUpfrontSection);

export default PaidUpfrontV2;
