export const CHECK_PERSON_STATUS = 'CHECK_PERSON_STATUS';
export const UPDATE_PERSON = 'UPDATE_PERSON';

export const SAVE_DL_INFO = 'SAVE_DL_INFO';

export const COMPLETE_PRIMARY_PERSON_REGISTRATION =
  'COMPLETE_PRIMARY_PERSON_REGISTRATION';
export const COMPLETE_ADDED_DRIVER_REGISTRATION =
  'COMPLETE_ADDED_DRIVER_REGISTRATION';
export const COMPLETE_ADDED_PREFILL_DRIVER_REGISTRATION =
  'COMPLETE_ADDED_PREFILL_DRIVER_REGISTRATION';
export const SAVE_ADDED_PREFILL_DRIVER_REGISTRATION =
  'SAVE_ADDED_PREFILL_DRIVER_REGISTRATION';

export const SAVE_PRIMARY_PERSON_ADDRESS = 'SAVE_PRIMARY_PERSON_ADDRESS';

export const REGISTER_QUOTE = 'REGISTER_QUOTE';
export const REGISTER_QUOTE_SUCCESS = 'REGISTER_QUOTE_SUCCESS';
export const REGISTER_QUOTE_FAILURE = 'REGISTER_QUOTE_FAILURE';

export const CHECK_FOR_GMAIL_ADDRESS = 'CHECK_FOR_GMAIL_ADDRESS';

export const SET_HOMEOWNER = 'SET_HOMEOWNER';

export const setHomeOwner = value => ({
  type: SET_HOMEOWNER,
  payload: { value }
});

export const checkPersonStatus = () => ({
  type: CHECK_PERSON_STATUS
});

export const updatePerson = (values, completed = false) => ({
  type: UPDATE_PERSON,
  payload: { values, completed }
});
export const saveDlInfo = dlInfo => ({
  type: SAVE_DL_INFO,
  payload: dlInfo
});

export const savePrimaryPersonAddress = data => ({
  type: SAVE_PRIMARY_PERSON_ADDRESS,
  payload: data
});

// registers person, quote, checks zip code if applicable
export const registerQuote = (data, successRoute) => ({
  type: REGISTER_QUOTE,
  payload: data,
  successRoute
});

export const registerQuoteSuccess = data => ({
  type: REGISTER_QUOTE_SUCCESS,
  payload: data
});

export const registerQuoteFailure = error => ({
  type: REGISTER_QUOTE_FAILURE,
  error
});

export const checkForGmailAddress = email => ({
  type: CHECK_FOR_GMAIL_ADDRESS,
  payload: { email }
});

export const confirmAdditionalDriver = values => ({
  payload: values
});
