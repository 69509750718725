import { Cookies } from 'react-cookie';

const cookies = new Cookies();

let appStore = null;

export const injectUtilitiesStore = _store => {
  appStore = { ..._store };
};

export const isDev = () =>
  window.location.hostname === 'dev-webquote.noblr.com' ||
  window.location.hostname === 'alpha-webquote.noblr.com';

export const determineEnvironment = () => {
  if (!window && !window.location) {
    return process.env.REACT_APP_HOST_ENV;
  }

  const { hostname } = window.location;

  if (hostname === 'dev-webquote.noblr.com') {
    return 'development';
  }

  if (hostname === 'alpha-webquote.noblr.com') {
    return 'alpha';
  }

  return 'production';
};

export const getBrowserSource = () => {
  let source = 'Web';

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    source = 'Mobile';
  }

  return source;
};

// Uses react-cookie to locate cookie with name passed in as arg.
export const getCookie = name => !!cookies.get(name) && cookies.get(name);

export const configureCookieName = name => `${isDev() ? 'dev-' : ''}${name}`;

export const getUrlParameter = (url, param, scrubPlusSign = false) => {
  const currentParam = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');

  const regex = new RegExp(`[\\?&]${currentParam}=([^&]*)`);

  const results = regex.exec(url);

  let parsedParam = null;

  if (results === null) {
    parsedParam = '';
  } else if (scrubPlusSign) {
    parsedParam = decodeURIComponent(results[1].replace(/\+/g, ' '));
  } else {
    parsedParam = decodeURIComponent(results[1]);
  }

  return parsedParam;
};

/** --------------------------------------------
 * Form Helpers
 *---------------------------------------------/

/**
 * sets initial value for field from data json
 * @param {Array} fields
 */
export const setInitialValues = fields => {
  const initialValues = {};

  fields.map(field => {
    initialValues[field.name] =
      `${field.initialValue}` || `${field.value}` || '';

    return field;
  });

  return initialValues;
};

/**
 * sets Selection option value directly on key
 * @param {Object} formValues
 */
export const getSelectValues = formValues => {
  const mappedValues = {};
  const formEntries = Object.entries(formValues);

  formEntries.map(entry => {
    const [key, value] = entry;

    if (value) {
      mappedValues[key] = value;
    } else {
      mappedValues[key] = '';
    }

    return entry;
  });

  return mappedValues;
};

export const getStateValue = (config, ...property) => {
  const stateValues = [];

  for (let i = 0; i < property.length; i++) {
    if (!property[i]) {
      return null;
    }

    const key = property[i].split('.');
    const state = appStore.getState();
    let value = state[key[0]];

    for (let j = 1; j < key.length; j++) {
      if (!value) {
        return null;
      }

      value = value[key[j]];
    }

    stateValues.push(value);
  }

  if (config.concat) {
    return stateValues.length === 1 ? stateValues[0] : stateValues.join(' ');
  }

  return stateValues.length === 1 ? stateValues[0] : stateValues;
};

export const getDynamicValue = ({ flags, truthy, falsy }) => {
  let foundFlag = null;

  flags?.map(flag => {
    foundFlag = getStateValue({}, flag);

    if (foundFlag) {
      return truthy;
    }

    return false;
  });

  return foundFlag ? truthy : falsy;
};

export const getStateValueWithFallback = ({ value, fallback }) =>
  getStateValue({}, value) || getStateValue({}, fallback);

const fillInDynamicString = ({ flags, dynamicString = null, fallback }) => {
  const newString = flags.reduce((acc, flag, index) => {
    const dynamicValue = getStateValue({}, flag);

    return dynamicValue && flag
      ? acc.replace(`$${index + 1}`, dynamicValue)
      : false;
  }, dynamicString);

  return newString || fallback;
};

export const buildDynamicString = config => {
  const { flags, dynamicString, fallback, value } = config;

  if (dynamicString) {
    return fillInDynamicString({ flags, dynamicString, fallback });
  }

  if (fallback) {
    return getStateValueWithFallback({ value, fallback });
  }

  return getDynamicValue({ ...config, flags });
};

export const buildPrompt = prompt =>
  prompt?.type === 'static' ? prompt.value : buildDynamicString(prompt);

export const isObjectEmpty = obj => Object.keys(obj).length === 0;

export const createPayload = payloadObj =>
  Object.entries(payloadObj).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});

export const generateNumericSelectOptions = (
  minNum,
  maxNum,
  extraText = false,
  prefix = false
) => {
  const possibleValues = [];

  for (let i = minNum; i <= maxNum; i++) {
    let label = '';

    if (extraText) {
      label = prefix ? `${extraText} ${i}` : `${i} ${extraText}`;
    } else {
      label = i;
    }

    possibleValues.push({
      value: i,
      label
    });
  }

  return possibleValues;
};

/**
 * builds action type from config based on whether it is static or dynamic
 * @param {Object} actionConfig
 */
export const getSubmitAction = actionConfig =>
  actionConfig.type === 'dynamic'
    ? getDynamicValue(actionConfig)
    : actionConfig.value;

export const getStartTime = state => {
  switch (state) {
    case 'CO':
      return 'Your coverage starts at 12:01AM MT on your selected date.';

    default:
      return 'Your coverage starts at 12:01AM local time at your address, on your selected date.';
  }
};

export const formatOrdinalMonth = val => {
  if (val === 1) {
    return '1st';
  }

  if (val === 2) {
    return '2nd';
  }

  if (val === 3) {
    return '3rd';
  }

  return `${val}th`;
};

export const retryImport = (fn, retriesRemaining = 5, retryInterval = 1000) =>
  new Promise((resolve, reject) =>
    // eslint-disable-next-line no-promise-executor-return
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesRemaining === 1) {
            reject(error);

            return;
          }

          retryImport(fn, retriesRemaining - 1, retryInterval).then(
            resolve,
            reject
          );
        }, retryInterval);
      })
  );

export const isKeyCodeNumeric = e => {
  if (
    (e.keyCode >= 48 && e.keyCode <= 57) ||
    (e.keyCode >= 37 && e.keyCode <= 40) ||
    (e.keyCode >= 96 && e.keyCode <= 105) ||
    e.keyCode === 8 ||
    e.keyCode === 13
  ) {
    return true;
  }

  return e.preventDefault();
};

export const separateThousandthsWithComma = amount => {
  if (!amount) {
    return null;
  }

  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseSearchQueryParams = queryString => {
  const parsedString = queryString.substring(1).split('&');

  const pairs = parsedString.reduce((acc, value) => {
    const [param, string] = value.split('=');

    if (!acc[param]) {
      acc[param] = string;
    }

    return acc;
  }, {});

  return pairs;
};

export const capitalizeString = string =>
  string.length > 0
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : '';

export const replaceSpacesWithDashes = string => {
  if (string === null || string === undefined) {
    return ''; // set string to '' if string is null or undefined
  }

  return string.toLowerCase().replace(/\s+/g, '-');
};

export const stripDomainFromEmail = (emailString = '') => {
  let result = '';
  // Regexp pattern checks for @ symbol and domain
  const domainTest = /@[a-z]*(?:\.[\d\w]*)+$/;
  const domainRegExp = new RegExp(domainTest);

  // Verify that emailString is not just an empty string
  if (emailString && emailString.length > 0 && domainRegExp.test(emailString)) {
    // split email address at the "@" symbol and store 0 index as result
    [result] = emailString.split('@');
  }

  return result;
};

export const groupActiveItems = items =>
  items.reduce(
    (accumulator, item) => {
      let key = 'inactive';

      if (item.activeBit && item.type === 'additional') {
        key = 'active';
      }

      if (item.activeBit && item.type === 'excluded') {
        key = 'excluded';
      }

      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      accumulator[key] = [...accumulator[key], item];

      return accumulator;
    },
    {
      active: [],
      excluded: [],
      inactive: []
    }
  );

export const groupCompletedItems = (items, itemType) =>
  items.reduce(
    (accumulator, item) => {
      const itemIsComplete =
        itemType === 'driver' ? item.driverComplete : item.vehicleComplete;

      const key = itemIsComplete ? 'complete' : 'incomplete';

      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      accumulator[key] = [...accumulator[key], item];

      return accumulator;
    },
    {
      complete: [],
      incomplete: []
    }
  );
