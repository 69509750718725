import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import Loader from 'components/Loader';
import { requestUSAAEligibilityQuestions } from 'redux/actions';
import { StyledContentWrapper, StyledLink } from 'styles';
import { ELIGIBILITY_PAGE_INFOBOX_CONTENT } from '../../constants';

export default function PrimaryEligibilityPage() {
  const primaryEligibility = useSelector(
    state => state.app?.eligibilityQuestions?.primaryEligibility
  );

  const dispatch = useDispatch();

  const [fields, setInitialFields] = useState([]);

  const [prompt, setPrompt] = useState('');

  useEffect(() => {
    // primaryEligibility is needed from the BE for setting question and fields
    if (!primaryEligibility) {
      dispatch(requestUSAAEligibilityQuestions());
    } else {
      const {
        eligibilityQuestionId,
        frontEndKey,
        options,
        lastSavedValue,
        question
      } = primaryEligibility[0];

      const initialFields = [
        {
          type: 'radio',
          value: '',
          name: eligibilityQuestionId,
          frontEndKey,
          options,
          required: true,
          initialValue: lastSavedValue,
          radioGroupAriaLabel: 'Confirm USAA Eligibility'
        }
      ];

      const prompt = question;

      setInitialFields(initialFields);
      setPrompt(prompt);
    }
  }, [primaryEligibility, dispatch]);

  const submitAction = 'SUBMIT_PRIMARY_ELIGIBILITY_ANSWER';

  // display loader until we have primaryEligibilty from BE
  return !primaryEligibility ? (
    <Loader absolute />
  ) : (
    <StyledContentWrapper>
      <Form
        submitAsync
        prompt={prompt}
        fields={fields}
        submitAction={submitAction}
      />
      <InfoBox
        extraData={{
          header: 'Why Are We Asking This?'
        }}
      >
        <p>
          {ELIGIBILITY_PAGE_INFOBOX_CONTENT.start}
          <StyledLink
            id="usaa-eligibility-link"
            href="https://www.usaa.com/join"
            target="_blank"
            rel="noopener noreferrer"
            fontWeight="normal"
            aria-label={`opens a new window to ${ELIGIBILITY_PAGE_INFOBOX_CONTENT.middle}`}
          >
            {ELIGIBILITY_PAGE_INFOBOX_CONTENT.middle}
          </StyledLink>
          {ELIGIBILITY_PAGE_INFOBOX_CONTENT.end}
        </p>
        <br />
        <p>
          {ELIGIBILITY_PAGE_INFOBOX_CONTENT.member}
          <StyledLink
            fontweight="normal"
            id="usaa-phone-number"
            href="tel:+1-800-531-8722"
            aria-label="1. 8 0 0. 5 3 1. 8 7 2 2"
          >
            1-800-531-8722
          </StyledLink>
        </p>
      </InfoBox>
    </StyledContentWrapper>
  );
}
