import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Typography } from '@material-ui/core';
import Loader from 'components/Loader';
import Button from 'components/Buttons/Button';
import {
  editingItem,
  removeItem,
  undoRemove,
  clearEditingVehicle
} from 'redux/actions';
import { EditListItemWrapper } from 'styles';
import { breakPoints } from 'styles/variables';

const LoaderContainer = styled.div`
  min-height: 170px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  ${props =>
    props.removed &&
    css`
      background-color: transparent;
      margin: 0 auto;
      display: flex; /* TODO: Remove button styles that duplicate base styles */
      align-items: center;
      justify-content: center;
      img {
        margin-right: 5px;
      }
    `}
`;

const ButtonWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  > button {
    margin: 5px auto;
  }

  @media only screen and (min-width: ${breakPoints.small}) {
    flex-flow: row nowrap;
    justify-content: space-around;
  }
`;

const EditListCreator = (WrappedComponent, listType) => {
  class EditList extends Component {
    constructor(props) {
      super(props);
      this.removeBtnRef = createRef();
      this.undoBtnRef = createRef();
      this.editBtnRef = createRef();
      this.onAdd = this.onAdd.bind(this);
      this.onRemove = this.onRemove.bind(this);
      this.onEdit = this.onEdit.bind(this);
      this.onUndo = this.onUndo.bind(this);
    }

    componentDidMount() {
      const { lastEditedDriver, lastEditedVehicle, item } = this.props;

      const { removeBtnRef, undoBtnRef, editBtnRef } = this;

      // apply focus to action buttons
      const shouldApplyFocus =
        lastEditedVehicle?.vehicleId === item.vehicleId ||
        lastEditedDriver?.driverId === item.driverId;

      if (shouldApplyFocus) {
        const doFocusRemoveButton =
          lastEditedDriver?.lastEdit === 'UNDO_REMOVE_DRIVER' ||
          lastEditedVehicle?.lastEdit === 'UNDO_REMOVE_VEHICLE';

        const doFocusUndoButton =
          lastEditedDriver?.lastEdit === 'REMOVE_DRIVER' ||
          lastEditedVehicle?.lastEdit === 'REMOVE_VEHICLE';

        if (doFocusRemoveButton) {
          removeBtnRef?.current?.focus();
        }

        if (doFocusUndoButton) {
          undoBtnRef?.current?.focus();
        }

        if (lastEditedVehicle?.lastEdit === 'ADDED_VEHICLE') {
          editBtnRef?.current?.focus();
        }
      }
    }

    onAdd() {
      const {
        editingVehicle,
        editingItem,
        history,
        confirmItemRoute,
        item,
        clearEditingVehicle
      } = this.props;

      // if vehicle, clear editing vehicle first
      if (listType === 'vehicles' && editingVehicle) {
        clearEditingVehicle();
      }

      editingItem({ ...item, added: true });

      return history.push({
        pathname: confirmItemRoute
      });
    }

    onEdit() {
      const { editingItem, history, confirmItemRoute, item } = this.props;

      editingItem({ ...item, edited: true });

      return history.push({
        pathname: confirmItemRoute
      });
    }

    onRemove() {
      const { removeItem, item } = this.props;

      return removeItem(item, listType);
    }

    onUndo() {
      const { undoRemove, item } = this.props;

      return undoRemove(item, listType);
    }

    render() {
      const {
        item,
        lastEditedDriver,
        savingDriver,
        lastEditedVehicle,
        savingVehicle,
        vehicleError
      } = this.props;

      const isVehiclesList = listType === 'vehicles';

      const showLoader = isVehiclesList
        ? savingVehicle && lastEditedVehicle.vehicleId === item.vehicleId
        : savingDriver && lastEditedDriver.driverId === item.driverId;

      const added = isVehiclesList
        ? item.vehicleComplete
        : (item.activeBit &&
            item.type === 'additional' &&
            item.driverComplete) ||
          item.type === 'primary';

      const incomplete = isVehiclesList
        ? !item.vehicleComplete
        : item.type === 'additional' && !item.driverComplete;

      const removed = isVehiclesList
        ? !item.activeBit
        : !item.activeBit && item.driverComplete;

      const excluded = item.type === 'excluded';

      const formattedItemLabel = isVehiclesList
        ? `${item.modelYear} ${item.make} ${item.model}`
        : `${item.firstName} ${item.lastName}`;

      const buttonId = isVehiclesList ? item.vehicleId : item.driverId;

      /*
        if "EditListCreator" is building the vehicles list,
        and the element that is being mapped has a vehicleId,
        and an error occurred in the vehicle reducer, display
        the error if the error has a vehicleId that matches the current elements vehicleId that is being mapped.
        */
      const displayVehicleError =
        isVehiclesList &&
        item.vehicleId &&
        vehicleError &&
        vehicleError.message &&
        vehicleError.errorVehicleId &&
        vehicleError.errorVehicleId === item.vehicleId;

      return (
        <EditListItemWrapper
          $added={added}
          $removed={removed}
          $excluded={excluded}
          $incomplete={incomplete}
          $isPrimaryDriver={!isVehiclesList && item.type === 'primary'}
        >
          {showLoader ? (
            <LoaderContainer>
              <Loader absolute={false} />
            </LoaderContainer>
          ) : (
            <WrappedComponent
              listType={listType}
              removed={removed}
              item={item}
            />
          )}
          {(item.type !== 'primary' || isVehiclesList) &&
            !showLoader &&
            (removed && !excluded ? (
              <ButtonWrapper>
                <StyledButton
                  removed
                  noMargin
                  small
                  cardBtn
                  onClick={this.onUndo}
                  type="button"
                  id={`undo-btn-${buttonId}`}
                  ariaLabel={`Undo Removal of ${formattedItemLabel}`}
                  ref={this.undoBtnRef}
                >
                  <img
                    src={`${process.env.REACT_APP_NOBLR_CDN}/icons/undo-arrow-icon.svg`}
                    height="15px"
                    width="15px"
                    style={{ objectFit: 'contain' }}
                    alt=""
                    aria-hidden="true"
                  />
                  Undo
                </StyledButton>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                {(added || incomplete) && !excluded && (
                  <StyledButton
                    noMargin
                    small
                    cardBtn
                    onClick={this.onRemove}
                    type="button"
                    id={`remove-btn-${buttonId}`}
                    aria-label={`Remove ${formattedItemLabel}`}
                    ref={this.removeBtnRef}
                  >
                    <img
                      src={`${process.env.REACT_APP_NOBLR_CDN}/icons/close-icon.svg`}
                      height="12px"
                      width="12px"
                      style={{ objectFit: 'contain' }}
                      alt=""
                      aria-hidden="true"
                    />
                    Remove
                  </StyledButton>
                )}

                {(added || excluded) && (
                  <StyledButton
                    noMargin
                    small
                    id={isVehiclesList ? item.vehicleId : item.personId}
                    cardBtn
                    onClick={this.onEdit}
                    type="button"
                    ariaLabel={`Edit ${formattedItemLabel}`}
                    ref={this.editBtnRef}
                  >
                    <img
                      src={`${process.env.REACT_APP_NOBLR_CDN}/icons/pencil-icon.svg`}
                      height="12px"
                      width="12px"
                      style={{ objectFit: 'contain' }}
                      alt=""
                      aria-hidden="true"
                    />
                    Edit
                  </StyledButton>
                )}
                {!added && !excluded && incomplete && (
                  <StyledButton
                    noMargin
                    primary
                    small
                    cardBtn
                    id={`add-btn-${buttonId}`}
                    onClick={this.onAdd}
                    type="button"
                    ariaLabel={`Add ${formattedItemLabel}`}
                  >
                    <img
                      src={`${process.env.REACT_APP_NOBLR_CDN}/icons/white-add-icon.svg`}
                      height="12px"
                      width="12px"
                      style={{ objectFit: 'contain' }}
                      alt=""
                      aria-hidden="true"
                    />
                    Add
                  </StyledButton>
                )}
              </ButtonWrapper>
            ))}
          {displayVehicleError && ( // Specific error for duplicate VINs
            <Typography variant="body2" display="inline" color="error">
              {vehicleError.message}
            </Typography>
          )}
        </EditListItemWrapper>
      );
    }
  }

  const mapStateToProps = ({
    drivers: { editedDrivers, savingDriver, lastEditedDriver },
    vehicles: {
      editingVehicle,
      editedVehicles,
      savingVehicle,
      lastEditedVehicle,
      error: vehicleError
    }
  }) => ({
    editedDrivers,
    savingDriver,
    lastEditedDriver,
    editedVehicles,
    savingVehicle,
    lastEditedVehicle,
    editingVehicle,
    vehicleError
  });

  const mapDispatchToProps = dispatch => ({
    editingItem: item => dispatch(editingItem(item)),
    removeItem: (removedItem, listType) =>
      dispatch(removeItem(removedItem, listType)),
    undoRemove: (item, listType) => dispatch(undoRemove(item, listType)),
    clearEditingVehicle: () => dispatch(clearEditingVehicle()),
    dispatch
  });

  return connect(mapStateToProps, mapDispatchToProps)(EditList);
};

export default EditListCreator;
