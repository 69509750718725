import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { breakPoints, colors } from 'styles/variables';

const StyledArrow = styled.div`
  @media screen and (max-width: ${breakPoints.medium}) {
    display: none;
  }
`;

// First media screen shows the header at width break
// Second media screen removes the margin at height break
const HeaderWrapper = styled.div`
  margin: max(30px, 1%) auto;
  display: flex;
  width: auto;
  align-items: baseline;
  justify-content: center;
  background-color: ${colors.white};
  border: none;
  cursor: default;
  text-align: center;

  @media screen and (max-width: ${breakPoints.large}) {
    display: none;
  }

  @media screen and (max-height: ${breakPoints.medium}) {
    margin: max(0px, 1%) auto;
  }

  ${props =>
    props.hideBackArrow &&
    css`
      ${StyledArrow} {
        display: none;
      }
    `}
`;

const ArrowWrapper = styled.div`
  flex: 0 1 auto;
  padding: 0 10px;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  margin: 0;
  padding: 0;
  flex: 0 1 auto;
`;

const StyledHeader = styled.p`
  margin: 0 auto;
  padding: 0 20px;
  display: inline-block;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 300;
  color: ${colors.coreBlue700};
  text-transform: uppercase;
  text-align: center;

  @media screen and (min-width: ${breakPoints.large}) {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.4;
    letter-spacing: 2px;
  }
`;

export function BackArrow({ visible, hideBackArrow = false }) {
  return (
    visible &&
    !hideBackArrow && (
      <StyledArrow
        aria-hidden="true"
        tabIndex={-1}
        className="back-arrow visible"
      >
        <img
          loading="eager"
          height="11px"
          width="auto"
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/back-chevron-gunmetal-icon.svg`}
          alt=""
        />
      </StyledArrow>
    )
  );
}

function PageNavHeader({
  children = null,
  history,
  history: {
    location: { pathname }
  },
  hideBackArrow = false,
  dlVerificationStatus,
  requiresVINVerification
}) {
  const hasHistory = history.length > 1;
  const handleBackNavigation = () => {
    if (pathname === '/add-drivers/drivers-list') {
      history.replace('/start-quote/confirm-primary-driver-info');

      return;
    }

    if (pathname === '/add-vehicles/vehicles-list') {
      history.replace('/add-drivers/drivers-list');

      return;
    }

    if (
      pathname === '/purchase/effective-date' ||
      pathname === '/driver-exclusion/start'
    ) {
      if (dlVerificationStatus === 'success' && !requiresVINVerification) {
        history.replace('/quote/review-your-quote');

        return;
      }
    }

    history.goBack();
  };

  return (
    <HeaderWrapper hideBackArrow={hideBackArrow} enabled={hasHistory}>
      <ArrowWrapper
        name="back-button"
        tabIndex="0"
        role="button"
        id="back-button"
        onClick={handleBackNavigation}
        onKeyPress={handleBackNavigation}
        aria-label="Go back to previous page"
        hidden={hideBackArrow}
      >
        <BackArrow visible={hasHistory} hideBackArrow={hideBackArrow} />
      </ArrowWrapper>
      <TextWrapper>
        {children && <StyledHeader>{children}</StyledHeader>}
      </TextWrapper>
    </HeaderWrapper>
  );
}

const mapStateToProps = ({
  rate: { vinRerate },
  app: { dlVerificationStatus }
}) => ({
  requiresVINVerification: vinRerate,
  dlVerificationStatus
});

const PageHeader = withRouter(connect(mapStateToProps)(PageNavHeader));

export default PageHeader;
