import React from 'react';
import styled from 'styled-components/macro';

function EmailDisclaimer() {
  return (
    <StyledEmailDisclaimer>
      {`We will not sell your email address. We will only contact you about
      insurance. Read our `}
      <a
        className="link"
        href="https://www.usaa.com/insurance/pay-as-you-drive/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>
      {` and `}
      <a
        className="link"
        href="https://www.usaa.com/insurance/pay-as-you-drive/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </StyledEmailDisclaimer>
  );
}

const StyledEmailDisclaimer = styled.p`
  margin: 0;
`;

export default EmailDisclaimer;
