import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { colors, openSans } from 'styles/variables';
import { maskVIN, sliceEndingOfVIN } from 'utilities/vehicles';

const AddedSubjectText = styled.h2`
  font-size: 21px;
  font-family: ${openSans};
  color: ${colors.coreBlue700};
  text-transform: capitalize;
`;

const ItemWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const AddedDetailText = styled.h3`
  margin: 10px auto;
  text-align: center;
  font-family: ${openSans};
  font-size: 18px;
  letter-spacing: 1px;
  ${props => props.uppercase && 'text-transform: uppercase'};
  > .sr-only {
    display: none;
    margin: 0;
    height: 0;
  }
`;

const AddedConfirmationText = styled.p`
  margin: 0 auto 10px;
  width: 100%;
  height: auto;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  > span {
    margin-left: 0.3125rem;
  }

  img {
    vertical-align: middle;
  }
`;

// TODO: de-structure props, set default values and improve component performance by setting 'else' case
function AddedCopy({ added, removed, incomplete, excluded = false }) {
  if (added) {
    return (
      <AddedConfirmationText>
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/green-check-mark-icon.svg`}
          height="15px"
          width="15px"
          alt=""
          aria-hidden="true"
        />
        <span>Added</span>
      </AddedConfirmationText>
    );
  }

  if (incomplete) {
    return (
      <AddedConfirmationText error>
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/warning-icon.svg`}
          height="15px"
          width="15px"
          alt=""
          aria-hidden="true"
        />
        <span>Incomplete</span>
      </AddedConfirmationText>
    );
  }

  if (removed) {
    return (
      <AddedConfirmationText error>
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/remove-icon-inactive.svg`}
          height="15px"
          width="15px"
          alt=""
          aria-hidden="true"
        />
        <span>Removed</span>
      </AddedConfirmationText>
    );
  }

  if (excluded) {
    return (
      <AddedConfirmationText error>
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/remove-icon-inactive.svg`}
          height="15px"
          width="15px"
          alt=""
          aria-hidden="true"
        />
        <span>Excluded</span>
      </AddedConfirmationText>
    );
  }

  return null;
}

function EditListItem({ item, listType }) {
  const {
    firstName,
    lastName,
    obfuscatedLastName,
    type = null,
    driverComplete = false,
    activeBit,
    vehicleComplete,
    year,
    modelYear,
    make,
    model,
    vin,
    age = null
  } = item;

  const usaaPersonInfoVerified = useSelector(
    state => state.app.usaaPersonInfoVerified
  );

  if (listType === 'drivers') {
    const isPrimaryDriver = type === 'primary';
    const isExcluded = activeBit && type === 'excluded';

    const nameVerified =
      isPrimaryDriver ||
      usaaPersonInfoVerified ||
      (driverComplete && activeBit);

    const displayLastName = nameVerified ? lastName : obfuscatedLastName;

    const fullName = `${firstName} ${displayLastName} ${
      isPrimaryDriver ? '(You)' : ''
    }`;

    return (
      <ItemWrapper $primaryDriver={isPrimaryDriver}>
        <AddedCopy
          added={
            (driverComplete || isPrimaryDriver) && activeBit && !isExcluded
          }
          incomplete={!driverComplete && !isExcluded}
          removed={!activeBit && driverComplete}
          excluded={activeBit && driverComplete && isExcluded}
        />
        <AddedSubjectText>{fullName}</AddedSubjectText>
        <AddedDetailText>
          <span>AGE: {age}</span>
        </AddedDetailText>
      </ItemWrapper>
    );
  }

  const obfuscatedVIN = sliceEndingOfVIN(vin);
  const maskedVIN = maskVIN(vin);

  return (
    <ItemWrapper>
      <AddedCopy
        added={vehicleComplete && activeBit}
        incomplete={!vehicleComplete && activeBit}
        removed={!activeBit}
      />
      <AddedSubjectText>
        {`${year || modelYear} ${make} ${model}`}
      </AddedSubjectText>
      {vin && (
        <AddedDetailText>
          <span className="sr-only">{`VIN ending in ${obfuscatedVIN}`}</span>
          <span aria-hidden="true">{`VIN: ${maskedVIN}`}</span>
        </AddedDetailText>
      )}
    </ItemWrapper>
  );
}

export default EditListItem;
