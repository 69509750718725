import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { colors } from 'styles/variables';

const CustomButton = styled.button`
  padding: ${props => (props.noPadding ? '' : '20px 0')};
  display: ${props => (props.inlineDisplay ? 'inline' : 'flex')};
  justify-content: center;
  align-items: center;
  border-radius: 27.5px;
  transition: background-color 0.2s ease;
  border: ${props =>
    props.noBorder ? 'none' : `2px solid ${colors.alphaBlue500}`};
  background-color: ${props =>
    props.primary ? colors.alphaBlue500 : colors.white};
  color: ${props => (props.primary ? colors.white : colors.alphaBlue500)};
  cursor: pointer;

  ${props =>
    props.extraLarge &&
    css`
      width: 280px;
      height: 50px;
      max-height: 50px;
      margin: ${props.noMargin
        ? '0 auto'
        : `${props.secondary ? '30px' : '60px'} auto 0`};
      padding: 0 ${props.padding ? props.padding : '25px'};
    `}
  ${props =>
    props.large &&
    css`
      width: 245px;
      height: 50px;
      max-height: 50px;
      margin: ${props.noMargin ? '0 auto' : '60px auto 0'};
      font-size: 18px;
    `}
    ${props =>
    props.medium &&
    css`
      width: 150px;
      height: 50px;
    `}
    ${props =>
    props.small &&
    css`
      height: 40px;
      max-height: 40px;
      margin: ${props.noMargin ? '0 6px' : '60px 6px'};
      width: 120px;
      min-width: 120px;
      height: 40px;
      max-height: 40px;
      padding: 10px;
      letter-spacing: 0.78px;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    `}
    ${props =>
    props.buttonWidth &&
    css`
      width: ${props.buttonWidth};
    `}
    ${props =>
    props.primary &&
    css`
      &:active {
        box-shadow: inset 0 0 40px ${colors.alphaBlue100};
      }
    `}
    ${props =>
    props.disabled &&
    css`
      padding: 0;
      background-color: ${props.secondary
        ? colors.white
        : colors.usaaLightGrey};
      border-color: ${props.secondary
        ? colors.alphaBlue500
        : colors.usaaLightGrey};
      cursor: not-allowed;
      &.buy_policy_button {
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        > img {
          width: 100%;
          margin: 0 auto;
        }
      }
    `}
    ${props =>
    props.sticky &&
    css`
      position: sticky;
      bottom: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 80px;
      border-radius: 0;
    `}

     ${props =>
    props.cardBtn &&
    css`
      justify-content: space-evenly;
    `}
    &.try-button {
    margin-top: 25px;
    border-color: ${colors.white};
    color: ${colors.coreBlue500};
  }

  ${props =>
    props.sign &&
    css`
      width: 245px;
      height: 50px;
      margin: 0 auto;
    `}
`;

const Button = forwardRef((props, ref) => {
  const {
    as = null,
    href = null,
    rel = null,
    target = null,
    children = null,
    disabled = false,
    isSubmitting = false,
    invalid = false,
    type = 'submit',
    className = 'form__submit cta--1',
    onClick = null,
    cardBtn = false,
    primary = false,
    secondary = false,
    extraLarge = false, // see padding comment below
    large = false,
    medium = false,
    small = false,
    sign = false, // signature button styles
    noMargin = false,
    noBorder = false,
    padding = null, // horizontal padding for extraLarge styling
    buttonWidth = null,
    sticky = false,
    id = null,
    ariaLabel,
    inlineDisplay,
    noPadding
  } = props;

  const isDisabled = disabled || isSubmitting || invalid;

  return (
    <CustomButton
      as={as}
      href={href}
      rel={rel}
      target={target}
      id={id}
      ref={ref}
      type={type}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={isDisabled}
      aria-disabled={isDisabled}
      aria-live={cardBtn ? 'polite' : 'off'}
      primary={primary}
      secondary={secondary}
      className={className}
      noMargin={noMargin}
      cardBtn={cardBtn}
      extraLarge={extraLarge}
      large={large}
      medium={medium}
      small={small}
      sign={sign}
      buttonWidth={buttonWidth}
      sticky={sticky}
      noBorder={noBorder}
      padding={padding}
      inlineDisplay={inlineDisplay}
      noPadding={noPadding}
    >
      {children}
    </CustomButton>
  );
});

export default Button;
