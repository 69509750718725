import styled, { css } from 'styled-components/macro';
import { Typography, Grid } from '@material-ui/core';
import LinkButton from 'components/Buttons/LinkButton';
import Button from 'components/Buttons/Button';
import {
  openSans,
  breakPoints,
  colors,
  brandonGrotesque
} from 'styles/variables';

export const FormGrid = styled(Grid)`
  padding: 30px;
`;
export const StyledMessage = styled.p`
  text-align: center;
  font-family: ${openSans};
  font-size: 18px;
`;

export const BlockText = styled.span`
  display: block;
`;

export const AssignmentLabel = styled(Typography)`
  font-family: ${openSans};
  font-size: 16px;
  line-height: ${props => (props.primary ? 'normal' : '3em')};
  text-align: ${props => (props.primary ? 'left' : 'right')};
`;

export const DriverName = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`;

export const StyledParagraph = styled.p`
  width: 100%;
  display: flex;
  justify-content: right;
  font-size: 14px;
  line-height: 1.43;
  text-align: left;
  font-family: ${openSans};

  @media screen and (max-width: ${breakPoints.small}) {
    justify-content: center;
  }
`;

export const StyledLink = styled.a`
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.coreBlue700};
  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `};

  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `};

  ${props =>
    props.letterSpacing &&
    css`
      letter-spacing: ${props.letterSpacing};
    `};

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `};
`;

export const TextLink = styled.button`
  cursor: pointer;
  display: inline;
  border: none;
  background: none;
  text-transform: capitalize;

  ${props =>
    props.primary &&
    css`
      margin: 10px auto;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 1px;
      text-align: center;
      color: ${colors.alphaBlue500};
    `}

  ${props =>
    props.licenses &&
    css`
      display: inline-block;
      letter-spacing: normal;
      font-size: 10px;
      font-family: ${openSans};
      color: ${colors.coreBlue700};
    `}

  ${props =>
    props.military &&
    css`
      font-size: 14px;
      color: ${colors.coreBlue700};
      text-decoration: underline;
      letter-spacing: normal;
      text-align: left;
      margin: 0;
      padding: 0;
    `}

     ${props =>
    props.details &&
    css`
      display: inline-block;
      letter-spacing: normal;
      text-decoration: underline;
      font-weight: 500;
      font-family: ${openSans};
      color: ${colors.coreBlue700};
    `}
`;

export const StyledList = styled.ul`
  margin-bottom: 30px;
  font-size: 18px;
  font-family: ${openSans};
  line-height: 28px;
  letter-spacing: 0.45px;
  list-style-type: disc;
  color: ${colors.coreBlue700};
`;

export const StyledText = styled.p`
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `}
  font-size: 18px;
  font-family: ${openSans};
  line-height: 28px;
  letter-spacing: 0.45px;
  color: ${colors.coreBlue700};
`;

export const Label = styled.span`
  margin-top: 30px;
  font-size: 18px;
  text-align: left;
  font-family: ${brandonGrotesque};
  color: ${colors.coreBlue700};
  text-decoration: underline;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  cursor: pointer;
  padding-left: 12px;
`;

export const SectionHeader = styled.p`
  text-transform: uppercase;
  font-family: ${openSans};
  font-weight: 600;
  font-size: 21px;
  color: ${colors.coreBlue700};

  @media screen and (min-width: ${breakPoints.medium}) {
    margin-top: 30px;
  }
`;

export const CoverageHeader = styled.p`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  font-family: ${brandonGrotesque};
  text-align: left;
  ${props =>
    props.upperCase &&
    css`
      padding-left: 30px;
      text-transform: uppercase;
    `};
`;

export const TableRowHeader = styled.th`
  text-align: left;
  font-family: ${brandonGrotesque};
  color: ${colors.coreBlue700};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.93px;
  line-height: 1.7;
`;

export const SectionTitle = styled(SectionHeader)`
  font-size: 21px;
  text-align: center;
  font-family: ${openSans};

  @media only screen and (min-width: ${breakPoints.large}) {
    margin-top: 20px;
  }
`;

export const CoverageTitle = styled(CoverageHeader)`
  margin-top: 10px;
  @media only screen and (min-width: ${breakPoints.large}) {
    margin-top: 30px;
  }
`;

export const StyledDriverExclusionLinkButton = styled(LinkButton)`
  @media screen and (min-width: ${breakPoints.medium}) {
    margin: 40px 0 0;
  }
`;

export const StyledDriverExclusionButton = styled(Button)`
  @media screen and (min-width: ${breakPoints.medium}) {
    margin-top: 40px;
  }
`;
