import React from 'react';
import { Field } from 'formik';
import { RadioGroup, Select } from '@noblr-lab/noblr-ui';
import * as yup from 'yup';
import { string, mixed, addMethod, date, boolean, lazy, object } from 'yup';
import valid from 'card-validator';
import { validationTools } from '@noblr-lab/frontend-tools';
import { calculateAge } from 'utilities/person';
import { isUniqueLicense } from 'utilities/vehicles';
import { isDate } from 'date-fns';
import AutoCompleteAddressInput from 'components/Form/AutoCompleteAddressInput';
import DatePicker from 'components/Form/DatePicker';
import PasswordInput from 'components/Form/PasswordInput';
import SearchableSelect from 'components/Form/SearchableSelect';
import AsyncSelect from 'components/Form/AsyncSelect';
import MaterialSlider from 'components/Form/MaterialSlider';
import TextInput from 'components/Form/TextInput';
import MaskedTextInput from 'components/Form/MaskedTextInput';
import MaskedDateInput from 'components/Form/MaskedDateInput';
import { ABBREV_STATE_CODES } from '../../constants/stateCodes';
import {
  GENDER_VALUES,
  EDUCATION_LEVEL_VALUES,
  HOME_OWNER_VALUES,
  MARITAL_STATUS_VALUES,
  FORM_ERRORS
} from '../../constants';

const equals = (ref, msg) =>
  this.test({
    name: 'equals',
    exclusive: false,
    message: msg || `${ref.path} must be the same as ${ref}`,
    params: {
      reference: ref.path
    },
    test: value => value === this.resolve(ref)
  });

addMethod(yup.string, 'equals', equals);

const firstNamePattern = /^([A-Za-z]{1,50})([ |,|-]{1})?([A-Za-z]{1,50})?$/;

export const firstNameSchema = string()
  .required('Required')
  .ensure()
  .min(1)
  .max(20)
  .matches(firstNamePattern, {
    message: FORM_ERRORS.invalidFirstName,
    excludeEmptyString: true // Account for "yup.ensure()"
  });

const lastNameRegExp = /(^[A-Za-z]([ \-']?[A-Za-z]+){0,4}$)/;

export const lastNameSchema = string()
  .ensure()
  .required('Required')
  .min(1)
  .max(50)
  .matches(lastNameRegExp, {
    message: FORM_ERRORS.invalidLastName,
    excludeEmptyString: true // Account for "yup.ensure()"
  })
  .test(
    'check for too many dashes and appostraphes',
    FORM_ERRORS.invalidLastName,
    val => {
      return (
        (val.match(/[-']/g) || []).length < 3 &&
        val.length > 1 &&
        (val.match(/[-' ]/g) || []).length < 4
      );
    }
  );

const fullNameRegExp = /^(\s)*[A-Za-z]+((\s)?(('|-|\.)?([A-Za-z])+))*(\s)*$/;

export const fullNameSchema = string()
  .required('Required!')
  .matches(fullNameRegExp, {
    message: FORM_ERRORS.invalidFirstName,
    excludeEmptyString: true
  });

export const emailSchema = string()
  .required('Required')
  .max(50)
  .email(FORM_ERRORS.invalidEmail);

const primaryEmailSchema = string().ensure();

const maritalStatusSchema = mixed()
  .required('Required')
  .oneOf(MARITAL_STATUS_VALUES);

const genderMappingSchema = mixed()
  .required('Required')
  .label('Gender')
  .oneOf(GENDER_VALUES);

const homeOwnerSchema = mixed().required('Required').oneOf(HOME_OWNER_VALUES);

const educationLevelSchema = mixed()
  .required('Required')
  .label('Education Level')
  .oneOf(EDUCATION_LEVEL_VALUES);

/*
  DOB RegExp Pattern

  Supported Date Format:
    MM/DD/YYYY
    Also accounts for leap years
*/
const dobRegExp =
  /^((0[13578]|1[02])[/.](0[1-9]|[12][0-9]|3[01])[/.](18|19|20)[0-9]{2})|((0[469]|11)[/.](0[1-9]|[12][0-9]|30)[/.](18|19|20)[0-9]{2})|((02)[/.](0[1-9]|1[0-9]|2[0-8])[/.](18|19|20)[0-9]{2})|((02)[/.]29[/.](((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))$/;

const maskedDobRegExp = /^\u2022{2}\/\u2022{2}\/[0-9]{4}$/;

const regexToMatch = new RegExp(
  `${dobRegExp.source}|${maskedDobRegExp.source}`
);

export const dateOfBirthSchema = string()
  .required('Required')
  .ensure() // coerce undefined and null into an empty string
  .matches(regexToMatch, {
    message: FORM_ERRORS.invalidBirthday,
    excludeEmptyString: true // Account for "yup.ensure()"
  })
  .test('check within age range', FORM_ERRORS.outOfRangeBirthday, val => {
    const section = window.location.pathname.split('/')[1];

    // if value is masked, return true
    if (maskedDobRegExp.test(val)) {
      return true;
    }

    // calculate age
    const currentAge = calculateAge(val);

    // if less than 15 or greater than 124 show error
    if (section === 'add-drivers' || section === 'driver-exclusion') {
      return currentAge > 14 && currentAge < 125;
    }

    // if less than or equal to 18 or greater than 124 show error
    return currentAge >= 18 && currentAge < 125;
  });

export const primaryDriverDOBSchema = string()
  .required('Required')
  .ensure() // coerce undefined and null into an empty string
  .test('check above age range', FORM_ERRORS.underAgeUser, val => {
    // calculate age
    if (maskedDobRegExp.test(val)) {
      return true;
    }

    const currentAge = calculateAge(val);

    return currentAge >= 18;
  })
  .test('check within age range', FORM_ERRORS.outOfRangeBirthday, val => {
    // if value is masked, return true
    if (maskedDobRegExp.test(val)) {
      return true;
    }

    // calculate age
    const currentAge = calculateAge(val);

    // if  greater than 124 show error
    return currentAge < 125;
  })
  .matches(regexToMatch, {
    message: FORM_ERRORS.invalidBirthday,
    excludeEmptyString: true // Account for "yup.ensure()"
  });

export const additionalDriverDOBSchema = string()
  .required('Required')
  .ensure() // coerce undefined and null into an empty string
  .test('check within age range', FORM_ERRORS.outOfRangeBirthday, val => {
    // if value is masked, return true
    if (maskedDobRegExp.test(val)) {
      return true;
    }

    // calculate age
    const currentAge = calculateAge(val);

    // if less than or equal to 18 or greater than 124 show error
    return currentAge > 14 && currentAge < 125;
  })
  .matches(regexToMatch, {
    message: FORM_ERRORS.invalidBirthday,
    excludeEmptyString: true // Account for "yup.ensure()"
  });

const primaryDOBSchema = string().ensure();

export const ageLicensedSchema = mixed()
  .required('Required')
  .test(
    'check when user got their license is over 14',
    FORM_ERRORS.ageLicensed,
    val => {
      // if we don't have a value or the value isn't an integer, return false
      if (!val || !Number.isInteger(val.value || val)) {
        return false;
      }

      // otherwise check that the value is more than 14
      const age = Number(val.value || val);

      return age > 14;
    }
  );

const autoCompleteAddressSchema = string()
  .ensure()
  .min(3, null)
  .max(50, 'Address must be less than 50 characters')
  .required('Required')
  // .required('Required')
  .matches(/^\d+\s[\da-zA-Z\u0080-\u024F]*/, {
    message: FORM_ERRORS.invalidStreetAddress,
    excludeEmptyString: true // Account for "yup.ensure()"
  });

export const streetAddressSchema = string()
  .ensure()
  .min(3, null)
  .max(50, 'Address must be less than 50 characters')
  .required('Required')
  .matches(/^\d+\s[\da-zA-Z\u0080-\u024F]*/, {
    message: FORM_ERRORS.invalidStreetAddress
    // excludeEmptyString: true // Account for "yup.ensure()"
  });

export const apartmentOrUnitSchema = string()
  .notRequired()
  .ensure()
  .matches(/^[#][a-zA-Z]|([ a-zA-Z0-9.])$/, {
    message: FORM_ERRORS.invalidStreet2,
    excludeEmptyString: true
  });

export const citySchema = string()
  .required('Required')
  .ensure()
  .matches(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/, {
    message: FORM_ERRORS.invalidCity,
    excludeEmptyString: true // Account for "yup.ensure()"
  });

//  Validates 5 digit US zip codes a(nd optional "-" plus 4 digit ending)
const zipCodeRegExp = /^(?!0{3})\d{5}(?:-?\d{4})?$/;

export const zipCodeSchema = string()
  .required('Required')
  .ensure()
  .matches(zipCodeRegExp, {
    message: FORM_ERRORS.invalidZipCode,
    excludeEmptyString: true // Account for "yup.ensure()"
  });

const stateSchema = string()
  .label('State')
  .required('Required')
  .oneOf(ABBREV_STATE_CODES, 'Please select a valid state');

const dlStateSchema = stateSchema.clone().label('License State');

// append the method from the validation tool for dl state format after unique check
const { type, params } = validationTools.schemas.STATE_DL_FORMAT;
const licenseSchema = string()
  .required(FORM_ERRORS.license)
  .test(
    'is unique from additional drivers',
    FORM_ERRORS.duplicate_license,
    value => isUniqueLicense(value)
  )
  [type](...params);

export const passwordSchema = string()
  .required('Required')
  .ensure()
  .trim()
  .min(6, FORM_ERRORS.passwordTooShort)
  .matches(/(?=.*\d)/, { message: FORM_ERRORS.password }) // should contain at least one digit
  .matches(/(?=.*[a-z])/, { message: FORM_ERRORS.password }) // should contain at least one lower case
  .matches(/(?=.*[A-Z])/, { message: FORM_ERRORS.password });

export const confirmPassword = string()
  .required('Required')
  .trim()
  .ensure()
  .min(6, FORM_ERRORS.passwordTooShort)
  .test(
    'passwords-match',
    'Passwords do not match!',
    // function must be used - not arrow function
    (value, context) => {
      if (!value) {
        return false;
      }

      return context.parent.password === value;
    }
  );

const creditCardSchema = string()
  .required('Required')
  .test(
    'creditCard',
    FORM_ERRORS.payment.cardNum.invalid,
    value => valid.number(value).isPotentiallyValid
  );

const cardExpirationSchema = string()
  .required('Required')
  .test(
    'cardExp',
    'Invalid date',
    value => valid.expirationDate(value, '20').isValid
    // Passing '20' updates valid expiration year
    // TODO: Update to '21' next year
  );

const cardCVVSchema = string()
  .required('Required')
  .min(3, FORM_ERRORS.payment.cardCvv.tooShort)
  // Invalidates cvv values '000' or '0000'
  .matches(/^(?!.*\b(0{3}|0{4})\b).*$/, {
    message: FORM_ERRORS.payment.cardCvv.invalid
  })
  .test(
    'cardCVV',
    FORM_ERRORS.payment.cardCvv.invalid,
    value => valid.cvv(value, 3).isValid || valid.cvv(value, 4).isValid
  );

const phoneNumberSchema = string()
  .required('Required')
  .matches(/^(?:\(\d{3}\)\s)\d{3}-\d{4}$/, {
    message: FORM_ERRORS.invalidPhone
  });

// VINs can be masked (returned from prefill) or entered
const maskedVinPattern = /^\u2022{13}[a-zA-Z0-9]{4}$/;
const vinPattern = /^[a-zA-Z0-9_]*$/;
const vinRegex = new RegExp(`${maskedVinPattern.source}|${vinPattern.source}`);
const vinSchema = string()
  .required('Required')
  .trim()
  .matches(vinRegex, { message: FORM_ERRORS.invalidVin })
  .min(17, FORM_ERRORS.invalidVin)
  .max(17, FORM_ERRORS.invalidVin);

const policyEffectiveDateSchema = date()
  .required('Required')
  .typeError(`${FORM_ERRORS.invalidPolicyStartDate}`)
  .test(`${FORM_ERRORS.invalidPolicyStartDate}`, value => isDate(value));

export const vehicleYMMSSchema = string().required('Required');

export const defaultRadioSchema = string().required('Required');

// Utilized in ReviewAndPayForm
export const singleCheckboxSchema = boolean()
  .required('Required!')
  .oneOf([true], 'Required!');

const selectDropdownSchema = string().required('Required');

export const validationSchemas = {
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  fullName: fullNameSchema,
  prebindApplication: fullNameSchema, // For sign-insurance-application document
  umuimOfferForm: fullNameSchema, // For sign-insurance-application document
  membershipApplication: fullNameSchema, // For sign-insurance-application document
  pipWaiver: fullNameSchema, // For sign-insurance-application document
  uimbiSelection: fullNameSchema, // For sign-insurance-application document
  umbiSelection: fullNameSchema, // For sign-insurance-application document
  umSelection: fullNameSchema, // For sign-insurance-application document
  tortOptions: fullNameSchema, // For sign-insurance-application document
  email: emailSchema,
  primaryEmail: primaryEmailSchema, // for trial quote retrieval
  ageLicensed: ageLicensedSchema,
  genderMapping: genderMappingSchema,
  maritalStatus: maritalStatusSchema,
  education: educationLevelSchema,
  homeOwner: homeOwnerSchema,
  dlState: dlStateSchema,
  dlNumber: licenseSchema,
  dob: dateOfBirthSchema,
  dateOfBirth: dateOfBirthSchema,
  primaryDriverDOB: primaryDriverDOBSchema,
  additionalDriverDOB: additionalDriverDOBSchema,
  primaryDOB: primaryDOBSchema, // for trial quote retrieval
  street: streetAddressSchema,
  autoCompleteAddress: autoCompleteAddressSchema,
  streetAddress: autoCompleteAddressSchema,
  street2: apartmentOrUnitSchema,
  city: citySchema,
  state: stateSchema,
  zipCode: zipCodeSchema,
  creditCard: creditCardSchema,
  cardExpiration: cardExpirationSchema,
  cardCVV: cardCVVSchema,
  cardZipCode: zipCodeSchema,
  phoneNumber: phoneNumberSchema,
  vin: vinSchema,
  password: passwordSchema,
  confirmPassword,
  policyEffectiveDate: policyEffectiveDateSchema,
  modelYear: vehicleYMMSSchema,
  make: vehicleYMMSSchema,
  model: vehicleYMMSSchema,
  style: vehicleYMMSSchema,
  seniorDriverImprovementDiscount: defaultRadioSchema,
  safeDriver: defaultRadioSchema,
  goodStudentDiscount: defaultRadioSchema,
  selfSpouseMember: defaultRadioSchema,
  parentMember: defaultRadioSchema,
  widowMember: defaultRadioSchema,
  activeMilitaryPersonnel: defaultRadioSchema,
  honorableDischarge: defaultRadioSchema,
  commuteSurcharge: defaultRadioSchema,
  allVehiclesOwnedByAmp: defaultRadioSchema,
  antitheftDiscountMapping: defaultRadioSchema,
  vinEtchingDiscountMapping: defaultRadioSchema,
  isRideshare: defaultRadioSchema,
  umbrellaPolicy: defaultRadioSchema,
  advancedTechnologyDiscount: defaultRadioSchema,
  vehicleOwnership: selectDropdownSchema,
  vehicleOwnershipMonths: selectDropdownSchema,
  defensiveCourseTaken: defaultRadioSchema,
  priorInsuranceLapseReason: selectDropdownSchema,
  activeDriverLicense: defaultRadioSchema,
  excludeDriver: defaultRadioSchema
};

export const renderField = ({
  type: fieldType,
  hidden = false,
  autoComplete,
  confirmedPII,
  isSubmitting,
  history,
  disabled = false,
  ...rest
}) => {
  const fieldConfig = {
    ...rest,
    hidden: hidden instanceof Function ? hidden() : hidden,
    confirmedPII,
    autoComplete,
    disabled: disabled || isSubmitting
  };

  switch (fieldType) {
    case 'autoCompleteAddress':
      return (
        <Field
          {...fieldConfig}
          validate={validationSchemas[fieldConfig.validation]}
          component={AutoCompleteAddressInput}
        />
      );

    case 'select':
      return (
        <Field
          {...fieldConfig}
          color="primary"
          fullWidth
          disabled={fieldConfig.disabled || false}
          selectLabelId={fieldConfig.selectLabelId}
          selectLabel={fieldConfig.selectLabel}
          component={Select}
          required
        />
      );

    case 'asyncSelect':
      return (
        <Field
          color="primary"
          fullWidth
          {...fieldConfig}
          component={AsyncSelect}
        />
      );

    case 'searchableSelect':
      return <Field {...fieldConfig} component={SearchableSelect} />;

    case 'radio':
      return (
        <Field
          {...fieldConfig}
          required
          defaultValue={fieldConfig.value}
          row
          fullWidth
          className="primary"
          color="primary"
          disabled={isSubmitting}
          component={RadioGroup}
          aria-labelledby={`${fieldConfig.name}-field-title`}
        />
      );

    case 'maskedText':
      return (
        <Field
          {...fieldConfig}
          maskedValue={
            fieldConfig.initialValue &&
            `*********${fieldConfig.initialValue.split('').slice(-4).join('')}`
          }
          component={MaskedTextInput}
        />
      );

    case 'maskedDate':
      return (
        <Field
          {...fieldConfig}
          maskedValue={
            fieldConfig.initialValue &&
            `**/**/${fieldConfig.initialValue.split('/').slice(-4)}`
          }
          history={history}
          component={MaskedDateInput}
        />
      );

    case 'materialSlider':
      return <Field {...fieldConfig} component={MaterialSlider} required />;

    case 'date':
      return <Field {...fieldConfig} component={DatePicker} />;

    case 'password':
      return (
        <Field
          {...fieldConfig}
          type="password"
          component={PasswordInput}
          validate={validationSchemas[fieldConfig.validation]}
        />
      );

    default:
      // email, text
      return <Field {...fieldConfig} component={TextInput} />;
  }
};

export const getValidationSchema = fields => {
  const schema = lazy(() =>
    object().shape(
      fields.reduce((accumulator, field) => {
        accumulator[field.name] =
          validationSchemas[field.validation || field.name];

        return accumulator;
      }, {})
    )
  );

  return schema;
};
