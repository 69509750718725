import React from 'react';
import styled, { keyframes, css } from 'styled-components/macro';
import { colors } from 'styles/variables';

const LoaderWrapper = styled.div`
  width: 5em;
  height: 5em;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  ${props =>
    props.absolute &&
    css`
      flex: 3;
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
      transform: translateY(-60%);
    `}
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1020deg);
  }
  100% {
    transform: rotate(720deg);
  }
`;

const StyledLoader = styled.div`
  margin: ${props => (props.noMargin ? '0 auto' : '30px 0')};
  width: 100%;
  height: 100%;
  border: 10px solid ${colors.usaaLightestGrey};
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  animation: ${spin} 4s ease-in-out infinite;
  border-bottom-color: ${colors.alphaBlue500};
`;

function Loader({ absolute = true, noMargin = true }) {
  return (
    <LoaderWrapper absolute={absolute} aria-busy="true" aria-live="polite">
      <StyledLoader noMargin={noMargin} />
    </LoaderWrapper>
  );
}

export default Loader;
