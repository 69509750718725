export default {
  name: 'add-vehicles',
  pages: [
    {
      type: 'Static',
      name: 'start',
      svg: 'cars-start.webp',
      svgAltText: '',
      prompt: {
        type: 'static',
        value: "Let's add your vehicles."
      },
      body: {
        type: 'static',
        value: [
          "We'll see if any vehicles are associated with your household. Then you can choose which vehicles you want on your quote."
        ]
      },
      successRoute: { type: 'static', value: '/add-vehicles/vehicles-list' }
    },
    {
      name: 'vehicles-list'
    },
    {
      name: 'vehicle-ownership'
    },
    {
      name: 'vehicle-ownership-length'
    },
    {
      name: 'emergency-braking'
    },
    {
      name: 'vin'
    },
    {
      name: 'ride-share'
    },
    {
      name: 'military-service'
    },
    {
      name: 'vehicle-ownership-length'
    },
    {
      name: 'add-new-vehicle'
    },
    {
      name: 'anti-theft'
    },
    {
      name: 'vin-etching'
    },
    {
      name: 'commute'
    },
    { name: 'car-assignment' },
    { name: 'car-assignment-followup' },
    { name: 'airbags' }
  ]
};
