import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from 'components/Buttons/Button';
import Sticky from 'components/Modifiers/Sticky';
import { Prompt, SubHeader, colors, breakPoints } from 'styles';
import {
  DEFAULT_MAX_WIDTH,
  USAA_SIGNATURE_START_DISCLAIMER
} from '../../constants';

const Body = styled.div`
  margin: 0 auto;
  display: flex;
  flex: 1 0 100%;
  flex-flow: row wrap;
  align-self: center;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  background-color: ${colors.white};
  max-width: ${DEFAULT_MAX_WIDTH};

  @media screen and (max-width: ${breakPoints.medium}) {
    max-width: 98%;
  }
`;

function SignatureStart(props) {
  const {
    primaryDriverState,
    prompt,
    requiresDynamicForms,
    customPreBindForms
  } = props;

  function getValues(primaryDriverState, requiresDynamicForms) {
    let subHeader = null;
    let successRoute = null;

    if (requiresDynamicForms) {
      const dynamicFormTitleArray = Object.values(customPreBindForms.byId).map(
        form => {
          return form.title;
        }
      );

      // formats document titles array into a string
      // using this list format => "_, _, _ and _"
      // will be inserted into ui message below
      const formattedFormList = dynamicFormTitleArray
        .toString()
        .replace(/,/g, ', ')
        .replace(/,(?=[^,]+$)/, ', and');

      /* UT signature start date does not require the signature/payment disclaimer */
      const requiresDisclaimer = primaryDriverState !== 'UT';

      if (requiresDisclaimer) {
        subHeader = `Read then sign the ${formattedFormList} documents. Coverage can only begin once we receive your signatures and payment.`;
      } else {
        subHeader = `Read then sign the ${formattedFormList}.`;
      }

      successRoute = `/purchase/sign-form`;
    } else {
      const {
        data: { subHeader: pageConfigSubHeader }
      } = props;

      subHeader = pageConfigSubHeader;
      successRoute = '/purchase/sign-insurance-application';
    }

    return { subHeader, successRoute };
  }

  const { subHeader, successRoute } = getValues(
    primaryDriverState,
    requiresDynamicForms
  );

  return (
    <Wrapper>
      <div className="content-wrapper">
        <Body>
          <Prompt>{prompt}</Prompt>
          <SubHeader>{subHeader}</SubHeader>
          <SubHeader usaaStyles>{USAA_SIGNATURE_START_DISCLAIMER}</SubHeader>
          <Sticky className="sticky" marginTop="30px">
            <Link to={{ pathname: successRoute }}>
              <Button large primary type="button" className="cta--1">
                Continue
              </Button>
            </Link>
          </Sticky>
        </Body>
        <Link className="outer" to={{ pathname: successRoute }}>
          <Button
            large
            primary
            type="button"
            id="continue-button"
            className="cta--1 outer"
          >
            Continue
          </Button>
        </Link>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = ({
  app: { requiresDriverExclusion, requiresDynamicForms },
  excludedDrivers: { totalDriversToExclude },
  drivers: {
    primaryDriver: { state: primaryDriverState }
  },
  policy: { requiresUmuimOfferForm, customPreBindForms }
}) => ({
  primaryDriverState,
  requiresDriverExclusion,
  totalDriversToExclude,
  requiresUmuimOfferForm,
  requiresDynamicForms,
  customPreBindForms
});

SignatureStart.propTypes = {
  primaryDriverState: PropTypes.string.isRequired,
  requiresDynamicForms: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    subHeader: PropTypes.string,
    successRoute: PropTypes.exact({
      value: PropTypes.string.isRequired,
      static: PropTypes.bool.isRequired
    })
  }),
  customPreBindForms: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.shape({
      formOrder: PropTypes.number,
      title: PropTypes.string,
      preBindFormName: PropTypes.string,
      preBindFormsId: PropTypes.string
    })
  }),
  prompt: PropTypes.string.isRequired
};

SignatureStart.defaultProps = {
  customPreBindForms: {
    allIds: [],
    byId: {}
  },
  data: {
    subHeader:
      'Read then sign the Insurance Application, the Member Subscription Agreement, and the Power of Attorney.',
    successRoute: {
      static: true,
      value: '/purchase/sign-insurance-application'
    }
  }
};

export default connect(mapStateToProps)(SignatureStart);
