import * as Sentry from '@sentry/react';
import * as FullStory from '@fullstory/browser';
import { call, put, putResolve, select } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import Noblr from '@noblr-lab/react-app-services';
import {
  createPayload,
  getCookie,
  getUrlParameter,
  getBrowserSource,
  configureCookieName,
  stripDomainFromEmail
} from 'utilities';
import { calculateAge } from 'utilities/person';
import {
  BASE_COOKIE_HEADER_CONFIG,
  EXPIRES_COOKIE_HEADER_CONFIG,
  SECURE_COOKIE_HEADER_CONFIG
} from '../../constants';

const cookies = new Cookies();

/**
 * check if we have a person id
 */
export function* checkPersonStatusWatcher() {
  // show loader until call is completed
  yield put({
    type: 'TOGGLE_LOADER',
    payload: { toShow: true }
  });

  // get cookies based on env
  const sessionCookie = getCookie(configureCookieName('session'));
  const quoteIdCookie = getCookie(configureCookieName('quoteId'));
  const personIdCookie = getCookie(configureCookieName('personId'));
  // check for zip code in the url
  const zipCodeInUrl = getUrlParameter(window.location.search, 'zip');

  // get primary driver off of store
  const {
    // app: { hasPartnerData },
    drivers: { primaryDriver }
  } = yield select();

  // if we have a quote id, person id, and a session id, we have everything we need and we can hide the loader
  if (personIdCookie && quoteIdCookie && sessionCookie) {
    if (primaryDriver.email && primaryDriver.email.length > 0) {
      const primaryEmailWithoutDomain = stripDomainFromEmail(
        primaryDriver.email
      );

      FullStory.identify(personIdCookie, {
        email: primaryEmailWithoutDomain,
        personId_str: personIdCookie,
        quoteId_str: quoteIdCookie
      });
    }
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });
  } else {
    // set source for register quote call
    const source = getBrowserSource();

    let personStatusInfo = { source };

    // if there is a zip code in the url, set it in the payload body
    if (zipCodeInUrl) {
      personStatusInfo = { ...personStatusInfo, zipCode: zipCodeInUrl };
    }

    if (personIdCookie) {
      personStatusInfo = { ...personStatusInfo, personId: personIdCookie };
    }

    if (primaryDriver && primaryDriver.firstName && primaryDriver.lastName) {
      personStatusInfo = {
        ...personStatusInfo,
        firstName: primaryDriver.firstName,
        lastName: primaryDriver.lastName
      };

      /* DOB must be sent to register quote if user arrives
     on name page after arriving from leadcloud / everquote landing page */
      if (primaryDriver.dob) {
        personStatusInfo = {
          ...personStatusInfo,
          dob: primaryDriver.dob
        };
      }
    }
    // call action to register the quote with updated payload in personStatusInfo
    yield put({
      type: 'REGISTER_QUOTE',
      payload: personStatusInfo
    });
  }
}

/**
 * Register a new quote with or without optional parameters
 * @param {Object} action
 */
export function* registerQuoteSubroutine(action) {
  yield put({
    type: 'TOGGLE_LOADER',
    payload: { toShow: true }
  });

  const { payload, successRoute = null } = action;

  const {
    app,
    app: { leadType }
  } = yield select();

  const quoteSource = getBrowserSource();

  // check for a referral code in the url
  const referralCode =
    getUrlParameter(window.location.href, 'referralCode') || null;

  // get any url parameters
  const urlParameters = window.location.search;

  let headers = {};
  const requestBody = { ...payload };

  // Source is required
  if (!requestBody.source) {
    requestBody.source = quoteSource;
  }

  if (urlParameters.length) {
    headers = {
      campaignUrl: urlParameters
    };

    // if we have a referral code pass it in the header
    if (referralCode) {
      headers.referralCode = referralCode;

      /*
      Send user variables to FullStory
      Email is not available in register quote
      so we don't send email address without domain to FS
      */
      FullStory.setUserVars({
        referralCode_str: referralCode,
        quoteSource_str: quoteSource
      });
      //  Manually set tags in current scope for Sentry
      Sentry.withScope(scope => {
        scope.setTag('is_referral', true);
        scope.setTag('referral_code', referralCode);
        scope.setTag('quote_source', quoteSource);
      });

      yield put({
        type: 'UPDATE_PRIMARY_DRIVER',
        payload: {
          createdBy: 'referral'
        }
      });
    }
  }

  // this quote came from a partner
  if (leadType) {
    // pass lead id in request headers
    headers = {
      ...headers,
      [leadType]: app.leadId
    };
  }

  try {
    // Send request to Noblr App Services endpoint
    const { jwt, quoteId, person, ...response } = yield call(
      Noblr.quote.registerQuote,
      headers,
      requestBody
    );

    yield put({
      type: 'REGISTER_QUOTE_SUCCESS'
    });

    const { personId, activeBit, createdBy, source } = person;

    //  Manually set tags in current scope for Sentry
    Sentry.setUser({ id: personId });
    Sentry.setTag('quote_id', quoteId);

    /*
      Send user identity and variables to FullStory
      Email is not available in register quote
      so we don't send email address without domain to FS
    */

    FullStory.identify(personId, {
      personId_str: personId,
      quoteId_str: quoteId
    });

    window.dataLayer = window.dataLayer || [];

    const { dataLayer } = window;

    // Pass Quote ID to GTM dataLayer
    dataLayer.push({
      quoteId
    });

    // yield putResolve({
    //   type: 'GET_QUOTE_AUTOMATION_STATUS',
    //   payload: quoteId
    // });

    let age = null;

    if (payload.dob) {
      // TODO: Refactor to handle dots in dob
      age = calculateAge(payload.dob);
    }
    // Set secure session cookie
    cookies.set(
      configureCookieName('session'),
      jwt,
      SECURE_COOKIE_HEADER_CONFIG
    );
    // Set personId cookie
    cookies.set(
      configureCookieName('personId'),
      personId,
      SECURE_COOKIE_HEADER_CONFIG
    );

    // Set quoteId cookie
    cookies.set(
      configureCookieName('quoteId'),
      quoteId,
      SECURE_COOKIE_HEADER_CONFIG
    );

    const noblrCSRCookie = getCookie('noblr-quote-by-csr');

    if (!noblrCSRCookie) {
      // set cookie so we know this quote is done by a potential member, not csr
      cookies.set('noblr-quote-by-csr', false, BASE_COOKIE_HEADER_CONFIG);
    }

    // Reset 'quote-initiated' and 'quote-completed' Cookies
    cookies.remove('quote-initiated', EXPIRES_COOKIE_HEADER_CONFIG);
    cookies.remove('quote-completed', EXPIRES_COOKIE_HEADER_CONFIG);

    // if we have url parameters, pass them in to the header for the api request
    if (urlParameters.length) {
      headers = {
        campaignUrl: urlParameters
      };

      // if we have a referral code pass it in the header
      if (referralCode) {
        headers.referralCode = referralCode;

        /*
      Send user variables to FullStory
      Email is not available in register quote
      so we don't send email address without domain to FS
      */
        FullStory.setUserVars({
          referralCode_str: referralCode,
          quoteSource_str: quoteSource
        });
        //  Manually set tags in current scope for Sentry
        Sentry.withScope(scope => {
          scope.setTag('is_referral', true);
          scope.setTag('referral_code', referralCode);
          scope.setTag('quote_source', quoteSource);
        });

        yield put({
          type: 'UPDATE_PRIMARY_DRIVER',
          payload: {
            createdBy: 'referral'
          }
        });
      }
    }

    // update primary driver with their person id and quote id
    // if we have a referral code or a lead, we need to update driver with register response
    if (referralCode || leadType) {
      const {
        driver: {
          firstName,
          lastName,
          dob,
          street,
          city,
          state,
          zipCode,
          email,
          gender,
          maritalStatus,
          education,
          homeOwner,
          driverId
        }
      } = response;

      const homeOwnerStatus = homeOwner === 'true' ? 'Own' : 'Rent';

      // update person with all available values from quote response
      yield putResolve({
        type: 'UPDATE_PRIMARY_DRIVER',
        payload: {
          ...response,
          ...person,
          quoteId,
          driverId,
          activeBit,
          createdBy,
          source,
          firstName,
          lastName,
          dob,
          street: street || '',
          streetAddress: street || '',
          city: city || '',
          // todo: replace since dob is masked we can't use it to calculate age
          age,
          state: state || '',
          zipCode: person.zipCode || zipCode || '',
          email: email || '',
          genderMapping: gender || '',
          maritalStatus: maritalStatus || '',
          education: education || '',
          homeOwner: homeOwnerStatus
        }
      });

      // also update editing driver with result from endpoint
      yield putResolve({
        type: 'UPDATE_EDITING_DRIVER',
        payload: {
          driverId,
          firstName,
          lastName,
          dob,
          street: street || '',
          streetAddress: street || '',
          city: city || '',
          state: state || '',
          zipCode: zipCode || '',
          email: email || '',
          genderMapping: gender || '',
          maritalStatus: maritalStatus || '',
          education: education || '',
          homeOwner: homeOwnerStatus
        }
      });
    } else {
      yield put({
        type: 'UPDATE_PRIMARY_DRIVER',
        payload: {
          ...response,
          ...person,
          personId,
          quoteId,
          zipCode: requestBody.zipCode || person.zipCode
        }
      });
    }

    if (successRoute) {
      // redirect the user to the next screen
      yield put({
        type: 'REDIRECT',
        payload: { url: successRoute }
      });
    }
  } catch (error) {
    yield put({
      type: 'REGISTER_QUOTE_FAILURE'
    });

    if (error && error.response && error.responses.status === 504) {
      yield put({
        type: 'SET_ERROR',
        error: {
          status: error.response.status,
          message: 'Error: Session has timed out'
        }
      });
    } else {
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, message: error.data.message }
      });
    }
  } finally {
    // make sure we hide loader after the try and the catch
    yield put({
      type: 'TOGGLE_LOADER',
      payload: { toShow: false }
    });
  }
}

/**
 * Set primary driver's address if we have all of the components for it
 * If we don't have all address components, redirect user to manual entry screen to complete
 * @param {Object} action
 */
export function* savePrimaryPersonAddressSubroutine({ payload }) {
  const {
    app: { manualAddress },
    drivers: {
      primaryDriver: { street, street2, city, state, zipCode }
    }
  } = yield select();

  const addressPayload = {
    street,
    street2,
    city,
    state,
    zipCode,
    ...payload
  };

  // if we're missing a city or state, and the primary driver isn't complete
  // or we've passed in the setManualAddress flag
  if (manualAddress && !payload.state && !payload.zipCode) {
    let parsedStreet = '';
    const { streetAddress } = payload;
    // if streetAddress has commas, additional address fields were entered
    const isLongAddress = streetAddress.includes(',');

    if (isLongAddress) {
      // split street address and store string at 0 index
      [parsedStreet] = streetAddress.split(',');
    } else {
      // if not, take entire string and assign as parsedStreet
      parsedStreet = streetAddress;
    }

    const trimmedStreet = parsedStreet.trim();

    yield put({
      type: 'SAVE_AUTOCOMPLETE_ADDRESS',
      payload: {
        ...addressPayload,
        streetAddress: trimmedStreet,
        street: parsedStreet,
        addressComplete: false
      }
    });
    // update driver info without hitting api so we have what they've filled out available on address-entry
    yield call(saveOrUpdatePrimaryDriverSubroutine, {
      payload: {
        ...payload,
        addressComplete: false
      },
      makeApiCall: false,
      successRoute: '/start-quote/address-entry'
    });
  } else {
    if (addressPayload.state) {
      FullStory.setUserVars({
        garagingState_str: addressPayload.state
      });
    }
    // Send request to Noblr App Services endpoint when we have everything we need
    try {
      yield call(saveOrUpdatePrimaryDriverSubroutine, {
        payload: {
          ...addressPayload,
          addressComplete: true
        },
        makeApiCall: true,
        successRoute: '/start-quote/usaa-membership'
      });
    } catch (error) {
      // if api call fails, set error
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, message: error.data }
      });
    }
  }
}

/**
 *
 * Map Other option to rent and save to backend
 */
export function* setHomeOwnerSubroutine({ payload }) {
  // if value is Other, send rent. Otherwise, just pass to update driver request
  yield put({
    type: 'UPDATE_PRIMARY_DRIVER_REQUEST',
    payload: {
      ...payload,
      homeOwner: payload.homeOwner === 'Other' ? 'Rent' : payload.homeOwner
    },
    makeApiCall: true
  });
}

/**
 * Strip any dashes and save driver's license number
 * @param {Object} action
 */
export function* saveDlInfo(action) {
  const { payload, successRoute, makeApiCall } = action;

  // strip any dashes
  const strippedDlNumber = payload.dlNumber.replace(/-/g, '');

  // update driver info with stripped dl number
  yield put({
    type: 'UPDATE_ADDITIONAL_DRIVER_INFO',
    payload: {
      ...payload,
      dlNumber: strippedDlNumber
    },
    successRoute,
    makeApiCall
  });
}

/**
 *
 * Save primary driver info
 */
export function* savePrimaryDriverSubroutine({ payload }) {
  const {
    drivers: {
      primaryDriver: { dob, firstName, lastName, zipCode, age }
    }
  } = yield select();

  let driverAge = age;

  /*
      "SAVE_PRIMARY_DRIVER" is dispatched on
      first visit to "/start-quote/date-of-birth page"
  */
  if (payload.dob) {
    driverAge = calculateAge(payload.dob);
  }

  const keys = {
    firstName,
    lastName,
    dob: payload.dob || dob,
    type: 'primary',
    zipCode
  };

  // todo: determine if we can remove this?
  const payloadToSave = createPayload(keys);

  try {
    // Send request to Noblr App Services endpoint
    const response = yield call(Noblr.driver.saveDriverInfo, payloadToSave);

    /*
      "saveDriverInfo" does not return DOB in response body
       so manually pass into the success action
    */
    yield put({
      type: 'SAVE_PRIMARY_DRIVER_SUCCESS',
      payload: { ...response, age: driverAge }
    });

    // if we passed in a dob, street, or dl number
    if (payload.dob || payload.street || payload.dlNumber) {
      // update confirmed data so we know which fields need to be masked

      let confirmedData = null;

      if (payload.dob) {
        confirmedData = 'dob';
      } else if (payload.street) {
        confirmedData = 'street';
      } else {
        confirmedData = 'dlNumber';
      }
      // update app store with updated confirmed data
      yield put({
        type: 'CONFIRMED_PII',
        payload: {
          confirmedPII: {
            [confirmedData]: true
          }
        }
      });
    }
  } catch (error) {
    yield put({
      type: 'SAVE_PRIMARY_DRIVER_FAILURE'
    });
    // otherwise, catch error and set it
    yield put({
      type: 'SET_ERROR',
      error: error.status ? { status: error.status, ...error.data } : error
    });
  }
}

/**
 *
 * Update primary driver
 */
export function* saveOrUpdatePrimaryDriverSubroutine({
  payload,
  makeApiCall,
  successRoute
}) {
  const {
    app: { manualAddress, askMilitaryServiceVehicles },
    drivers: {
      primaryDriver: {
        quoteId,
        driverId,
        personId,
        person /* , firstName, lastName, dob, zipCode */
      }
    }
  } = yield select();

  const { firstName, lastName, dob, email = null, addressComplete } = payload;

  // if we're updating the email address
  if (email) {
    // RegExp matches domain names "google.com" and "gmail.com"
    // Does not match if "+", "-" or "." is used in username
    const googleEmailPattern =
      /(^\W|^)[\w\d]*(@g{1}((oogle)|(mail)))(\.com){1}(\W|$)/;

    const hasGoogleEmail = new RegExp(googleEmailPattern, 'gi').test(email);
    const emailUsername = stripDomainFromEmail(email);
    const personIdString = personId || person.personId;

    FullStory.identify(personIdString, {
      email: emailUsername,
      quoteId_str: quoteId,
      personId_str: personIdString
    });
    Sentry.withScope(scope => {
      scope.setTag('google_email_detected', hasGoogleEmail);
    });

    // todo: make saveEmailAddress saga that does this work and then calls update driver info
    yield put({
      type: 'CHECK_FOR_GMAIL_ADDRESS',
      payload: {
        showGoogleSignup: hasGoogleEmail
      }
    });
  }

  // if we're updating the dob or street or dl number
  if (
    payload.dob ||
    // make sure we have FULL street address before doing this
    (payload.street && makeApiCall) ||
    payload.dlNumber
  ) {
    let confirmedData = null;

    if (payload.dob) {
      confirmedData = 'dob';
    } else if (payload.street) {
      confirmedData = 'street';
    } else {
      confirmedData = 'dlNumber';
    }

    // update app store with confirmed data so we know to mask it
    yield put({
      type: 'CONFIRMED_PII',
      payload: {
        confirmedPII: {
          [confirmedData]: true
        }
      }
    });
  }

  yield put({
    type: 'UPDATE_PRIMARY_DRIVER',
    payload
  });

  // if we don't need to make the api call, skip it and redirect to next screen
  if (!makeApiCall && successRoute) {
    yield put({
      type: 'REDIRECT',
      payload: {
        url: successRoute
      }
    });

    return;
  }

  // we do need to make the api call
  try {
    let response = null;

    if (!driverId) {
      // if we don't have a driver id, we need to save instead of update
      // Send request to Noblr App Services endpoint to save driver info
      response = yield call(Noblr.driver.saveDriverInfo, {
        firstName,
        lastName,
        dob: payload.dob || dob,
        driverType: 'primary',
        zipCode: payload.zipCode
      });
    } else {
      // Set tag in Sentry
      Sentry.withScope(scope => {
        scope.setTag('driver_id', driverId);
      });
      // when we already have an id we do want to make an update
      // Send request to Noblr App Services endpoint
      response = yield call(Noblr.driver.updateDriverInfo, {
        ...payload,
        driverId
      });
    }

    const {
      requiresAntitheftDiscount,
      requiresSeniorSafeDriverDiscount,
      requiresSeniorDriverImprovementDiscount,
      requiresActiveMilitaryPersonnel,
      requiresVehicleAdvancedTechnologyDiscount,
      requiresVehicleOwnershipMonths,
      requiresVinEtchingDiscount,
      requiresAllVehiclesOwnedByAMP,
      requiresUmuimOfferForm,
      requiresVehicleAssignment,
      requiresCommuteSurcharge,
      requiresV2CoverageEndpoints,
      requiresAirbagVerification,
      driverId: primaryDriverId,
      requiresMedPayOfferForm,
      requiresDynamicForms,
      requiresDriverExclusion,
      requiresGoodStudentDiscount,
      street,
      street2,
      city,
      state,
      zipCode,
      driverComplete,
      activeMilitaryPersonnel = false
    } = response;

    if (
      !driverComplete &&
      addressComplete &&
      manualAddress &&
      street &&
      city &&
      state &&
      zipCode
    ) {
      const longStreetAddress = `${street},${
        street2 ? ` ${street2},` : ''
      } ${city}, ${state} ${zipCode}`;

      yield put({
        type: 'SAVE_AUTOCOMPLETE_ADDRESS',
        payload: {
          addressComplete,
          streetAddress: longStreetAddress,
          street,
          street2,
          city,
          zipCode
        }
      });
    }

    const requiresVehiclesAMPQuestion =
      activeMilitaryPersonnel &&
      !!requiresAllVehiclesOwnedByAMP &&
      !!requiresActiveMilitaryPersonnel &&
      !askMilitaryServiceVehicles;

    // specific to LA quotes
    if (requiresVehiclesAMPQuestion) {
      yield put({
        type: 'ASK_MILITARY_SERVICE_VEHICLES'
      });
    }

    if (response.state) {
      // Set tag in Sentry
      Sentry.withScope(scope => {
        scope.setTag('driver_state', response.state);
      });
    }

    yield put({
      type: 'UPDATE_POLICY',
      payload: {
        requiresDynamicForms,
        requiresUmuimOfferForm,
        requiresDriverExclusion
      }
    });

    // set booleans to check for safe driving or military service on primary driver
    yield put({
      type: 'UPDATE_PRIMARY_DRIVER',
      payload: {
        driverId: primaryDriverId,
        askSafeDriving: !!requiresSeniorSafeDriverDiscount,
        askSeniorDriverImprovement: !!requiresSeniorDriverImprovementDiscount,
        askActiveMilitaryPersonnel: !!requiresActiveMilitaryPersonnel,
        requiresAntitheftDiscount,
        requiresActiveMilitaryPersonnel,
        requiresVehicleAdvancedTechnologyDiscount,
        requiresVehicleOwnershipMonths,
        requiresVinEtchingDiscount,
        requiresAllVehiclesOwnedByAMP,
        requiresUmuimOfferForm,
        requiresVehicleAssignment,
        requiresCommuteSurcharge,
        requiresV2CoverageEndpoints,
        requiresAirbagVerification,
        requiresMedPayOfferForm,
        requiresDriverExclusion,
        requiresDynamicForms,
        askGoodStudentDiscount: !!requiresGoodStudentDiscount,
        ...response
      }
    });
    // set booleans for anti theft discount, ownership length, and emergency breaking discounts
    yield put({
      type: 'UPDATE_APP',
      payload: {
        askAntiTheft: !!requiresAntitheftDiscount,
        askSeniorDriverImprovement: !!requiresSeniorDriverImprovementDiscount,
        askSafeDriving: !!requiresSeniorSafeDriverDiscount,
        askOwnershipLength: !!requiresVehicleOwnershipMonths,
        askEmergencyBraking: !!requiresVehicleAdvancedTechnologyDiscount,
        requiresAllVehiclesOwnedByAMP,
        askVinEtching: !!requiresVinEtchingDiscount,
        requiresUmuimOfferForm: !!requiresUmuimOfferForm,
        askCommuteSurcharge: !!requiresCommuteSurcharge,
        // set on app since we check it for each driver
        askActiveMilitaryPersonnel: !!requiresActiveMilitaryPersonnel,
        requiresVehicleAssignment: !!requiresVehicleAssignment,
        askAirbagVerification: !!requiresAirbagVerification,
        requiresMedPayOfferForm: !!requiresMedPayOfferForm,
        requiresDynamicForms: !!requiresDynamicForms,
        requiresDriverExclusion: !!requiresDriverExclusion,
        askGoodStudentDiscount: !!requiresGoodStudentDiscount
      }
    });
  } catch (error) {
    // 409 error status indicates active trial or active policy
    if (error.status === 409) {
      const { policyExists, trialExists } = error.data;
      const policyActive = Boolean(policyExists);
      const trialActive = Boolean(trialExists);

      yield put({
        type: 'UPDATE_APP',
        payload: {
          policyActive,
          trialActive
        }
      });
      yield put({
        type: 'REDIRECT',
        payload: {
          url: '/account/need-help'
        }
      });
    }
    // catch any error and set it on redux store
    yield put({
      type: 'SET_ERROR',
      error: {
        status: error.status,
        message: error.data.message,
        ...error.data
      }
    });
  }
}

export function* updateDriverAndVerifyQuoteRoutine({ payload }) {
  yield putResolve({ type: 'UPDATE_PRIMARY_DRIVER', payload });

  const quoteIdCookie = getCookie(configureCookieName('quoteId'));

  yield putResolve({
    type: 'GET_QUOTE_AUTOMATION_STATUS',
    payload: quoteIdCookie
  });
}
