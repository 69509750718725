import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

class DateOfBirthPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStartingQuote: true,
      prompt: '',
      submitAction: '',
      fields: [
        {
          type: 'maskedDate',
          name: 'dob',
          id: 'birth-date-input',
          label: 'date of birth',
          hidden: false,
          // no placeholder being passed
          // from TextInput with inputComponent: CleaveInput
          placeholder: 'MM/DD/YYYY',
          required: true,
          options: {
            date: true,
            blocks: [2, 2],
            datePattern: ['m', 'd', 'Y'],
            delimiter: '/'
          },
          autoComplete: 'bday'
        }
      ],
      staticContent: {},
      successRoute: '/start-quote/date-of-birth'
    };
  }

  componentDidMount() {
    const { section, editingDriver, primaryDriver, primaryDriverId } =
      this.props;

    const { fields } = this.state;
    const isStartingQuote = section === 'start-quote';

    const driver = isStartingQuote ? primaryDriver : editingDriver;

    const sectionValidation = isStartingQuote
      ? 'primaryDriverDOB'
      : 'additionalDriverDOB';

    if (!driver) {
      return;
    }

    const prompt = isStartingQuote
      ? "What's your date of birth?"
      : "When is this driver's date of birth?";

    let submitAction = 'SAVE_PRIMARY_DRIVER';

    if (isStartingQuote && primaryDriverId) {
      submitAction = 'UPDATE_PRIMARY_DRIVER_REQUEST';
    } else if (!isStartingQuote) {
      submitAction = 'UPDATE_ADDITIONAL_DRIVER_INFO';
    }

    const successRoute = isStartingQuote
      ? '/start-quote/address'
      : '/add-drivers/marital-status';

    const updatedFields = fields.map(field => ({
      ...field,
      initialValue: driver.dob,
      validation: sectionValidation,
      formattingOptions: {
        ...field.formattingOptions,
        initValue: driver.dob ? `XXXX${driver.dob.slice(-4)}` : ''
      }
    }));

    const staticContent = !isStartingQuote
      ? false
      : {
          form: [
            {
              type: 'LiabilityAgreement'
            }
          ]
        };

    this.setState({
      isStartingQuote,
      prompt,
      submitAction,
      fields: updatedFields,
      successRoute,
      staticContent
    });
  }

  render() {
    const { editingDriver, section } = this.props;

    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form },
      isStartingQuote
    } = this.state;

    return !isStartingQuote && !editingDriver ? (
      <Redirect to="/add-drivers/drivers-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          staticContent={form}
          submitAsync
          displayReCaptcha={
            section === 'start-quote' && process.env.NODE_ENV === 'production'
          }
          buttonMarginTop={section === 'start-quote' ? '80px' : '20px'}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({ drivers: { primaryDriver, editingDriver } }) => ({
  primaryDriverId: primaryDriver.driverId,
  primaryDriver,
  editingDriver
});

DateOfBirthPage.propTypes = {
  primaryDriver: PropTypes.shape({
    dob: PropTypes.string
  }),
  primaryDriverId: PropTypes.string,
  editingDriver: PropTypes.shape({
    driverId: PropTypes.string,
    dob: PropTypes.string
  }),
  section: PropTypes.string.isRequired
};

DateOfBirthPage.defaultProps = {
  primaryDriver: {
    dob: ''
  },
  primaryDriverId: null,
  editingDriver: {
    dob: '',
    driverId: null
  }
};

export default connect(mapStateToProps)(DateOfBirthPage);
