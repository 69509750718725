import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditListCreator from 'components/ListEditor/EditListCreator';
import EditListItem from 'components/ListEditor/EditListItem';
import Sticky from 'components/Modifiers/Sticky';
import InfoBox from 'components/Static/InfoBox';
import Button from 'components/Buttons/Button';
import {
  startAddNewDriver,
  requestPriorInsuranceReasons,
  clearEditingDriver
} from 'redux/actions';
import { groupActiveItems, groupCompletedItems } from 'utilities';
import {
  ConfirmationContainer,
  Prompt,
  AddItemButton,
  BorderedTextButtonWrapper,
  StyledContentWrapper
} from 'styles';

class DriversListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onContinueRoute: '/add-vehicles/start',
      addNewDriverRoute: '/add-drivers/name',
      confirmDriverRoute: '/add-drivers/confirm-driver'
    };
  }

  componentDidMount() {
    const {
      primaryDriver: { state: policyState },
      askPriorInsuranceLapseReason,
      priorInsuranceLapseOptions,
      requestPriorInsuranceReasons
    } = this.props;

    const { onContinueRoute } = this.state;

    const isGeorgia = policyState === 'GA';
    const isIndiana = policyState === 'IN';
    let updatedContinueRoute = onContinueRoute;

    if (isGeorgia || isIndiana) {
      if (askPriorInsuranceLapseReason) {
        updatedContinueRoute = '/add-drivers/prior-insurance';
      } else if (isGeorgia) {
        updatedContinueRoute = '/add-drivers/safe-driving';
      }

      if (askPriorInsuranceLapseReason && !priorInsuranceLapseOptions.length) {
        requestPriorInsuranceReasons();
      }
    }
    this.setState({
      onContinueRoute: updatedContinueRoute
    });
  }

  addNewDriver = () => {
    const { history, startAddNewDriver, editingDriver, clearEditingDriver } =
      this.props;

    const { addNewDriverRoute } = this.state;

    if (editingDriver && editingDriver.driverId) {
      clearEditingDriver();
    }

    // dispatch action to start add new driver
    startAddNewDriver();

    history.push({
      pathname: addNewDriverRoute
    });
  };

  handleContinue = () => {
    const { history } = this.props;
    const { onContinueRoute } = this.state;

    history.push({
      pathname: onContinueRoute
    });
  };

  renderDriverList = () => {
    const { confirmDriverRoute, addNewDriverRoute } = this.state;
    const { items, primaryDriver, editedDrivers, history } = this.props;

    const EditListLineItem = EditListCreator(EditListItem, 'drivers');
    /* TODO: create selectors to determine driver count and whether driver count
    is at the maximum */
    const atMax = items.filter(driver => driver.activeBit).length === 6;

    // group active drivers and inactive drivers first
    const driversByActiveBit = groupActiveItems(items);

    // within active drivers, group by incomplete
    const groupedActiveDrivers = groupCompletedItems(
      [...driversByActiveBit.active, ...driversByActiveBit.excluded],
      'driver'
    );

    const sortedDrivers = [
      ...groupedActiveDrivers.complete,
      ...groupedActiveDrivers.incomplete,
      ...driversByActiveBit.inactive
    ];

    return (
      <>
        {primaryDriver && (
          <EditListLineItem
            item={{
              ...primaryDriver,
              isPrimaryDriver: true
            }}
          />
        )}
        {sortedDrivers.map(driver => (
          <EditListLineItem
            key={driver.driverId}
            item={driver}
            editedDrivers={editedDrivers}
            confirmItemRoute={
              driver.prefill || (driver.driverComplete && driver.edited)
                ? confirmDriverRoute
                : addNewDriverRoute
            }
            history={history}
          />
        ))}
        {!atMax && (
          <BorderedTextButtonWrapper>
            <AddItemButton type="button" onClick={this.addNewDriver}>
              <img
                src={`${process.env.REACT_APP_NOBLR_CDN}/icons/circular-outline-plus-sign-icon.svg`}
                height="20px"
                width="20px"
                alt=""
              />
              Add New Driver
            </AddItemButton>
          </BorderedTextButtonWrapper>
        )}
        <Sticky marginTop="60px">
          <Button
            primary
            large
            onClick={this.handleContinue}
            disabled={groupedActiveDrivers.incomplete.length > 0}
            id="continue-button"
          >
            Continue
          </Button>
        </Sticky>
      </>
    );
  };

  render() {
    const driversList = this.renderDriverList();

    return (
      <StyledContentWrapper>
        <ConfirmationContainer>
          <Prompt>Add the drivers you want covered.</Prompt>
          {driversList}
        </ConfirmationContainer>
        <InfoBox
          extraData={{
            header: 'Who Should I Include?',
            text: 'Include anyone who drives any of your vehicles and anyone in your household who is old enough to drive. Anyone you do not include will not be covered on your policy.'
          }}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: {
    usaaPersonInfoRetrieved,
    askPriorInsuranceLapseReason,
    priorInsuranceLapseOptions
  },
  drivers: { items, primaryDriver, editingDriver, editedDrivers }
}) => ({
  items,
  primaryDriver,
  editingDriver,
  editedDrivers,
  usaaPersonInfoRetrieved,
  askPriorInsuranceLapseReason,
  priorInsuranceLapseOptions
});

const mapDispatchToProps = dispatch => ({
  startAddNewDriver: () => dispatch(startAddNewDriver()),
  requestPriorInsuranceReasons: () => dispatch(requestPriorInsuranceReasons()),
  clearEditingDriver: () => dispatch(clearEditingDriver())
});

DriversListPage.propTypes = {
  primaryDriver: PropTypes.shape({
    state: PropTypes.string
  }).isRequired,
  editingDriver: PropTypes.shape({
    driverId: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      activeBit: PropTypes.bool,
      askActiveMilitaryPersonnel: PropTypes.bool,
      askGoodStudentDiscount: PropTypes.bool,
      askSafeDriving: PropTypes.bool,
      dlNumber: PropTypes.string,
      dlState: PropTypes.string,
      dob: PropTypes.string,
      driverComplete: PropTypes.bool,
      driverId: PropTypes.string,
      education: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      maritalStatus: PropTypes.string,
      prefill: PropTypes.bool,
      type: PropTypes.string
    })
  ).isRequired,
  editedDrivers: PropTypes.arrayOf(PropTypes.string).isRequired,
  askPriorInsuranceLapseReason: PropTypes.bool.isRequired,
  priorInsuranceLapseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  startAddNewDriver: PropTypes.func.isRequired,
  requestPriorInsuranceReasons: PropTypes.func.isRequired,
  clearEditingDriver: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(DriversListPage);
