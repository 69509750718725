import React from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'components/Form/Form';
import { getUrlParameter } from 'utilities';

function CodeEntryPage({ history }) {
  const codeInUrl = getUrlParameter(history.location.search, 'code');

  return (
    <Form
      noValidate=""
      buttonWidth="280px"
      submitAsync
      fields={[
        {
          type: 'text',
          name: 'otp',
          id: 'otp-input',
          label: 'security code',
          required: true,
          autoComplete: 'one-time-code',
          initialValue: codeInUrl
        }
      ]}
      submitAction="VERIFY_OTP"
      prompt="Enter security code"
      successRoute="/account/create-password"
    />
  );
}

export default withRouter(CodeEntryPage);
