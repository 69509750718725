// colors
export const colors = {
  alphaBlue100: '#E6F1FA',
  alphaBlue500: '#006EC8', //  replaces brandOrange
  alphaBlue600: '#0058A0',
  white: '#FFFFFF',
  coreBlue400: '#41617C',
  coreBlue500: '#12395B', // replaces miamiTeal
  coreBlue700: '#0B2237', // palette.primary.main color - used for all text and p replaces usaaNavyBlue
  green500: '#0A7800',
  red500: '#B40000',
  coolGray400: '#DADBDC',
  coolGray700: '#8B8D8F',
  coolGray800: '#4E4E4F',
  overlayBackground: 'rgba(79, 79, 79, 0.46)',
  // usaaNavyBlue: '#0D3A5C', // replaces "#414C5C" aka "darkGunMetal"
  usaaLightNavyBlue: '#2C4D6D', // replaces "#8090a8" aka "accessibleLightGunMetal" everywhere except input placeholders (see below)
  usaaLightGrey: '#ACB8C5', // replaces "#B3BECF" aka "fullGunMetalJacket"
  usaaLighterGrey: '#D6DBE1', // not replacing existing colors
  usaaLightestGrey: '#F4F5F7', // replaces "#E4E8F3" aka "mediumGunMetal" and  #F5F7FB aka "lightGunMetal",
  accessibleLightGunMetal: '#8090A8' // color for input placeholder text
};

export const breakPoints = {
  extraSmall: '360px',
  small: '500px' /* Mobile */,
  medium: '769px' /* Mobile */,
  large: '979px' /* Tablet */,
  extraLarge: '980px' /* Desktop */,
  widescreen: '1200px'
};

export const brandonGrotesque = 'brandon-grotesque, sans-serif';
export const openSans = 'Open Sans, sans-serif';
