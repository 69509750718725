import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignPage from 'components/Pages/SignPage';
import { setPendingForms } from 'redux/actions';
import { StyledContentWrapper } from 'styles';

const buildPreBindFormAcknowledgement = title => {
  return `By continuing, I acknowledge that I have read and agree to the ${title} form and agree that entering my name above serves as and replaces the need for my physical signature and signifies my authorization going forward.`;
};

class DynamicPreBindForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'SUBMIT_FORM_ID_SIGNATURE',
      currentFormId: null,
      fields: [
        {
          type: 'sign',
          name: null,
          id: 'dynamic-pre-bind-form-input',
          label: 'First and Last Name',
          required: true,
          validation: 'fullName',
          autoComplete: 'name',
          fullWidth: true
        }
      ],
      staticContent: {
        form: [
          {
            type: 'static',
            location: 'form',
            value: null
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { currentFormId, customPreBindForms, setPendingForms } = this.props;

    const { preBindFormName, title } = customPreBindForms.byId[currentFormId];
    const preBindFormPrompt = buildPreBindFormAcknowledgement(title);

    if (customPreBindForms.allIds.length > 0) {
      const pendingFormIds = customPreBindForms.allIds.slice();
      const currentFormId = pendingFormIds.shift();
      let nextFormId = null;

      if (pendingFormIds.length > 0) {
        nextFormId = pendingFormIds.shift();
      }
      /* resets pending forms if page is refreshed before forms are complete */
      setPendingForms(
        pendingFormIds,
        currentFormId,
        nextFormId,
        customPreBindForms
      );
    }
    this.setState({
      currentFormId,
      fields: [
        {
          type: 'sign',
          name: `${preBindFormName}:${currentFormId}`,
          id: 'dynamic-pre-bind-form-input',
          label: 'First and Last Name',
          required: true,
          validation: 'fullName',
          autoComplete: 'name',
          fullWidth: true
        }
      ],
      staticContent: {
        form: [
          {
            type: 'static',
            location: 'form',
            value: preBindFormPrompt
          }
        ]
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { currentFormId, customPreBindForms } = this.props;
      const { preBindFormName, title } = customPreBindForms.byId[currentFormId];
      const preBindFormPrompt = buildPreBindFormAcknowledgement(title);

      this.setState({
        currentFormId,
        fields: [
          {
            type: 'sign',
            name: `${preBindFormName}:${currentFormId}`,
            id: 'dynamic-pre-bind-form-input',
            label: 'First and Last Name',
            required: true,
            validation: 'fullName',
            autoComplete: 'name',
            fullWidth: true
          }
        ],
        staticContent: {
          form: [
            {
              type: 'static',
              location: 'form',
              value: preBindFormPrompt
            }
          ]
        }
      });
    }
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      staticContent: { form },
      currentFormId
    } = this.state;

    const { currentFormId: docId, nextFormId, customPreBindForms } = this.props;

    const { preBindFormName, title } = customPreBindForms.byId[docId];

    let updatedSuccessRoute = null;

    if (!nextFormId) {
      updatedSuccessRoute = '/purchase/umbrella-policy';
    }

    return (
      <StyledContentWrapper>
        <SignPage
          key={currentFormId}
          pdfFileName={preBindFormName}
          pdfFileId={currentFormId}
          prompt={prompt}
          formStaticContent={form}
          fields={fields}
          submitAction={submitAction}
          successRoute={updatedSuccessRoute}
          documentName={title}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = () => {
  return ({ policy: { customPreBindForms, currentFormId, nextFormId } }) => {
    return {
      customPreBindForms,
      currentFormId,
      nextFormId
    };
  };
};

const mapDispatchToProps = dispatch => ({
  setPendingForms: (
    pendingFormIds,
    currentFormId,
    nextFormId,
    customPreBindForms
  ) =>
    dispatch(
      setPendingForms(
        pendingFormIds,
        currentFormId,
        nextFormId,
        customPreBindForms
      )
    )
});

DynamicPreBindForm.propTypes = {
  customPreBindForms: PropTypes.shape({
    allIds: PropTypes.arrayOf(PropTypes.string),
    byId: PropTypes.objectOf(
      PropTypes.shape({
        formOrder: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        preBindFormName: PropTypes.string.isRequired,
        preBindFormsId: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  currentFormId: PropTypes.string.isRequired,
  nextFormId: PropTypes.string,
  setPendingForms: PropTypes.func.isRequired
};

DynamicPreBindForm.defaultProps = {
  nextFormId: null
};
export default connect(mapStateToProps, mapDispatchToProps)(DynamicPreBindForm);
