export default {
  name: 'quote',
  pages: [
    {
      type: 'Quote',
      name: 'review-your-quote',
      maxWidth: 'auto',
      hidePageHeader: true,
      subHeader: 'A rate based on how you actually drive every month.',
      sections: [
        'noblr_rewards',
        'policy_summary',
        'about_your_rate',
        'driver_history'
      ],
      modalDetails: [
        {
          id: 'noblr-promise',
          title: 'About the Noblr Promise',
          body: [
            {
              text: [
                'The Noblr Promise ensures that your monthly cost will not exceed the specified maximum for your first 6-month term, no matter how well or how much you drive. The max amount is based on info about your cars, drivers, and driving history. It will not change as long as your cars, drivers, and coverage do not change during your first term.'
              ]
            }
          ]
        },
        {
          id: 'late-night-reward',
          title: 'About the Late Night Lyft Reward',
          body: [
            {
              text: 'Noblr Rewards are deducted from your variable rate, so you save even more on your monthly bill. You earn $5 for every Lyft you take in Colorado between 11PM and 4AM any night of the week. Each driver on the policy is eligible to earn a reward for up to 12 trips per policy term.'
            },
            {
              text: 'Your total reward amount includes all late night Lyft rides taken by all drivers on the policy with linked accounts.'
            },
            {
              text: "Noblr Rewards for late night Lyft rides can only be applied once you've linked your Lyft account in the Noblr app."
            },
            {
              text: "Linking your Lyft account takes you out of the Noblr app. Upon successfully linking your Lyft account you will be returned to the Noblr app. Noblr's Terms and Conditions do not apply while you are in the Lyft app."
            },
            {
              text: 'You can manage your linked accounts under Settings in the Account Menu in the Noblr app.'
            }
          ]
        }
      ]
    },
    {
      name: 'retrieve-your-quote',
      hideBackArrow: true,
      overrideSectionTitle: {
        type: 'static',
        value: 'Retrieve Your Quote'
      },
      prompt: null,
      subHeader: null,
      successRoute: '/quote/review-your-quote'
    },
    {
      type: 'Static',
      name: 'not-found',
      prompt: { type: 'static', value: 'Need a quote?' },
      hidePageHeader: true,
      subHeader:
        "We don't seem to have a saved quote for you. If you're looking for pricing, please start a quote.",
      successRoute: { type: 'static', value: '/start-quote/name' },
      buttonText: 'Start Quote'
    }
  ]
};
