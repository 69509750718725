import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Container, Box, Grid } from '@material-ui/core';
import { makeSelectCurrentExcludedDriver } from 'redux/selectors';
import Form from 'components/Form/Form';
import Button from 'components/Buttons/Button';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper, ModalHeader } from 'styles';
import Modal from 'components/Modal';

const ButtonGrid = styled(Grid)`
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const ModalPrimaryButton = styled(Button)`
  margin: ${props => (props.margin ? props.margin : '0.125rem auto')};
  padding: 8px;
  height: auto;
`;

export default function ConfirmDrivingCourses({ history, location }) {
  const { pathname } = location;

  const [prompt, setPrompt] = useState('');
  const [successRoute, setSuccessRoute] = useState('');
  const [fields, setFields] = useState([]);
  const [submitAction, setSubmitAction] = useState('');

  const { driverId } = useParams();

  const quoteState = useSelector(state => state.drivers.primaryDriver.state);

  // memoize makeSelectCurrentExcludedDriver
  const selectCurrentExcludedDriver = useMemo(
    makeSelectCurrentExcludedDriver,
    []
  );

  // use selector to pull excluded driver from state
  const excludedDriver = useSelector(state =>
    selectCurrentExcludedDriver(state)
  );

  const enableDefensiveDriving =
    pathname ===
    `/driver-exclusion/${driverId}/confirm-defensive-driving-course`;

  const enableSafeDriving =
    pathname === '/driver-exclusion/confirm-safe-driving';

  useEffect(() => {
    let pageSubmitAction = 'UPDATE_EXCLUDED_DRIVER_INFO';
    let pageSuccessRoute = `/driver-exclusion/${driverId}/confirmation`;
    let pagePrompt = prompt;
    let pageFields = [
      {
        type: 'radio',
        name: 'safeDriver',
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ],
        required: true,
        initialValue: '',
        radioGroupAriaLabel: 'Safe Driver Discount'
      }
    ];

    if (enableDefensiveDriving) {
      pagePrompt = `Has ${excludedDriver.firstName} participated in a safe driver program in the last 3 years?`;
    } else if (enableSafeDriving) {
      pagePrompt =
        'Has every driver on your quote completed a safe driving course in the last 3 years?';
      pageSubmitAction = 'UPDATE_DEFENSIVE_DRIVING_ANSWER';
      pageSuccessRoute = '/purchase/effective-date';
      pageFields = [
        {
          type: 'radio',
          name: 'defensiveCourseTaken',
          options: [
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ],
          required: true,
          radioGroupAriaLabel: 'Safe Driving Discount'
        }
      ];
    }

    setPrompt(pagePrompt);
    setSubmitAction(pageSubmitAction);
    setSuccessRoute(pageSuccessRoute);
    setFields(pageFields);
  }, [
    setPrompt,
    setFields,
    setSubmitAction,
    setSuccessRoute,
    prompt,
    excludedDriver,
    driverId,
    enableDefensiveDriving,
    enableSafeDriving,
    quoteState
  ]);

  const dispatch = useDispatch();
  const prevLocation = useRef(pathname);
  const unListen = useRef();

  const backArrowListener = useCallback(() => {
    if (prevLocation !== pathname) {
      history.go(1);
      dispatch({ type: 'OPEN_MODAL', payload: 'driverExclusionModal' });
    }
  }, [history, pathname, dispatch, prevLocation]);

  useEffect(() => {
    if (prevLocation !== pathname) {
      history.push(pathname, '', '');

      unListen.current = history.listen((newLocation, action) => {
        if (action === 'POP') {
          backArrowListener();
        }
      });
    }

    return unListen.current;
  }, [backArrowListener, pathname, history, prevLocation]);

  const handleClose = () => {
    dispatch({ type: 'CLOSE_MODAL' });
  };

  const handleNavigation = () => {
    handleClose();
    dispatch({
      type: 'REDIRECT',
      payload: { url: '/driver-exclusion/start' }
    });
  };

  return (
    <Container>
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          submitAsync
          renderFieldRow={false}
          maxWidth="500px"
        />
        <InfoBox
          withMarginBottom
          extraData={{
            header: 'Why Are We Asking This?'
          }}
        >
          <span>
            If this driver has voluntarily completed a driver improvement course
            in the last 3 years, your policy may be eligible for a discount. We
            may require you to provide a copy of your Certificate of Completion.
          </span>
        </InfoBox>
        <Modal
          inline
          modalLabel="DriverExclusionModal"
          modalName="driverExclusionModal"
        >
          <Box>
            <Grid container>
              <ModalHeader>
                {`Clicking 'BACK' will take you to the section start page and reset the list of drivers you've gone through.`}
              </ModalHeader>
              <ButtonGrid container item>
                <Grid item xs sm>
                  <ModalPrimaryButton large onClick={handleClose}>
                    CANCEL
                  </ModalPrimaryButton>
                </Grid>
                <Grid item xs sm>
                  <ModalPrimaryButton primary large onClick={handleNavigation}>
                    BACK
                  </ModalPrimaryButton>
                </Grid>
              </ButtonGrid>
            </Grid>
          </Box>
        </Modal>
      </StyledContentWrapper>
    </Container>
  );
}
