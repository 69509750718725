/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/react';
import { TextField } from '@material-ui/core';
import PlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-places-autocomplete';
import {
  redirect,
  saveAutocompleteAddress,
  setManualAddressFlag
} from 'redux/actions';
import { colors, openSans, breakPoints } from 'styles/variables';

const textStyles = `
  font-family: ${openSans};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 1.79;
  font-weight: 500;
  color: ${colors.coreBlue700};
  cursor: pointer;
`;

const SuggestionOption = styled.span`
  ${textStyles}
  padding: 5px;
  margin: 5px auto;
  min-height: 24px;
  height: 24px;
  text-decoration: underline;
`;

const AutocompleteDropDown = styled.ul`
  position: absolute;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 5;
  border: 2px solid ${colors.coreBlue700};

  @media only screen and (max-width: ${breakPoints.extraSmall}) {
    margin: 0 auto;
    width: inherit;
    border: 2px solid ${colors.coreBlue700};
    max-width: 99vw;
  }

  @media only screen and (min-width: 361px) {
    width: 350px;
    max-width: 350px;
  }
  .suggestion-item--active,
  .suggestion-item:hover {
    background-color: ${colors.usaaLightestGrey};
  }
  .list-item-container {
    padding: 0 5px;
  }

  text-align: left;
  background-color: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.coreBlue700};
  > li {
    height: 30px;
  }
  > span {
    padding: 0 5px;
  }
`;

const GoogleImage = styled.img`
  margin: 5px 5px 5px auto;
  max-width: 125px;
  align-self: right;
`;

const Text = styled.p`
  ${textStyles}
  ${props => props.loading === 'true' && 'margin: 5px 0 0 5px;'}
   ${props => props.loading === 'true' && `color: ${colors.coreBlue700};`}
`;

function SuggestionText({ suggestion: { type, description } }) {
  return type === 'manual-address' ? (
    <SuggestionOption>{"I don't see my address here >"}</SuggestionOption>
  ) : (
    <SuggestionOption>{description}</SuggestionOption>
  );
}

class AutoCompleteAddressInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isManualActive: false
    };

    this.handleError = this.handleError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleAddressSuggestionSelection =
      this.handleAddressSuggestionSelection.bind(this);
    this.setFormattedAddress = this.setFormattedAddress.bind(this);
  }

  handleChange(address) {
    const {
      manualAddress,
      setManualAddressFlag,
      form: { setFieldValue }
    } = this.props;

    // if manual address is not true
    if (!manualAddress) {
      // set manual address flag to true
      setManualAddressFlag(true);
    }

    // replace tabs, 2x sequential whitespace characters
    const trimmedAddress = address.replace(/  +/g, ' ');

    setFieldValue('streetAddress', trimmedAddress, true);
    this.setState({
      isManualActive: true
    });
  }

  handleBlur() {
    const {
      form: { setFieldTouched }
    } = this.props;

    setFieldTouched('streetAddress', true, false);
    this.setState({
      isManualActive: false
    });
  }

  // type, placeId and suggestion are passed in but placeId is all we need
  async handleAddressSuggestionSelection({ placeId }) {
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = '' } =
      place.address_components.find(component =>
        component.types.includes('postal_code')
      ) || {};

    this.setFormattedAddress(place, postalCode);
  }

  handleSelect(address = '', placeId = null, suggestion = null) {
    const {
      saveAutocompleteAddress,
      setManualAddressFlag,
      manualAddress,
      redirect
    } = this.props;

    // `placeId` and `suggestion` are null when user hits Enter key with no suggestion item selected. If user clicks "I don't see my address", placeId is equal to 'manual-address'
    if (
      placeId === 'manual-address' ||
      (address.length && !placeId && !suggestion)
    ) {
      this.setState({
        isManualActive: true
      });

      // If handleSelect does not receive an address, use field value as backup
      const {
        field: { value }
      } = this.props;

      const addressString = value || address;

      const [street, city = null, stateZipCode = null] =
        addressString.split(',');

      let state = null;
      let zipCode = null;

      if (stateZipCode) {
        [, state, zipCode] = stateZipCode.split(' ');
      }

      saveAutocompleteAddress({
        street,
        city,
        zipCode,
        state,
        streetAddress: addressString
      });

      // check if manual address is not true
      if (!manualAddress) {
        // and set to manual address to true
        setManualAddressFlag(true);
      }
      // always redirect to address entry after
      redirect('/start-quote/address-entry');
    } else {
      // if manual address is true, reset to false
      if (manualAddress) {
        setManualAddressFlag(false);
      }

      // otherwise, pass placeId suggestion to event handler
      this.handleAddressSuggestionSelection({
        type: 'autocomplete',
        placeId,
        ...suggestion
      });
      this.setState({
        isManualActive: false
      });
    }
  }

  handleError(status, clearSuggestions) {
    const { redirect, setManualAddressFlag } = this.props;

    // If Google Maps API fails to load, send status to Sentry
    if (status !== 'OK') {
      Sentry.captureMessage(`${status} status in Google Maps API`, 'error');
    }

    // if address is not found or request is invalid
    if (status === 'NOT_FOUND' || status === 'INVALID_REQUEST') {
      const {
        field: { value }
      } = this.props;

      // set manual address flag and save field value
      setManualAddressFlag(true);
      saveAutocompleteAddress({
        streetAddress: value
      });
      // redirect to manual entry page
      redirect('/start-quote/address-entry');
    } else {
      // clear suggestions, hide dropdown and set manual active to true
      clearSuggestions();
      this.setState({
        isManualActive: true
      });
    }
  }

  setFormattedAddress(place, postalCode) {
    const {
      saveAutocompleteAddress,
      primaryDriverComplete,
      form: { setValues },
      manualAddress
    } = this.props;

    const { address_components, formatted_address } = place;
    const formattedAddress = formatted_address
      .split(',')
      .slice(0, -1)
      .join(',');

    const streetNumberObj = address_components.find(address_component =>
      address_component.types.includes('street_number')
    );

    const streetNumber = streetNumberObj && streetNumberObj.long_name;
    const streetName = address_components.find(address_component =>
      address_component.types.includes('route')
    ).long_name;

    const cityObj = address_components.find(address_component =>
      address_component.types.includes('locality')
    );

    const city = cityObj && cityObj.long_name;

    const state = address_components.find(address_component =>
      address_component.types.includes('administrative_area_level_1')
    ).short_name;

    const zipCode = postalCode;

    const addressPayload = {
      street: streetNumber ? `${streetNumber} ${streetName}` : streetName,
      city,
      state,
      zipCode,
      streetAddress: formattedAddress
    };

    // set all values so entire address is sent in onSubmit event handler
    setValues(addressPayload);

    if ((!zipCode || !streetNumberObj) && !primaryDriverComplete) {
      if (!manualAddress) {
        setManualAddressFlag(true);
      }
    } else {
      // suggestion was selected so reset manual address flag
      setManualAddressFlag(false);
    }
    // save autocomplete address string
    saveAutocompleteAddress({
      ...addressPayload
    });
  }

  render() {
    const { isManualActive, scriptError } = this.state;

    const {
      placeholder,
      label,
      field,
      form: { isValid, isSubmitting, isValidating, dirty, values }
    } = this.props;

    return (
      <>
        <div id="map" />
        <PlacesAutocomplete
          debounce={600}
          value={values.streetAddress}
          onError={this.handleError}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          shouldFetchSuggestions={
            dirty && isValid && !isValidating && !isSubmitting
          }
          searchOptions={{
            types: ['address'],
            componentRestrictions: { country: 'us' }
          }}
          googleCallbackName="initPlaces"
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => {
            const suggestionsLength = suggestions.length;
            const noActiveSuggestions =
              suggestionsLength < 3 || suggestions.every(val => !val.active);

            const suggestionsWithNotFoundLink = [
              ...suggestions,
              {
                type: 'manual-address',
                id: 'manual-address',
                placeId: 'manual-address',
                index: suggestionsLength,
                active: isManualActive && noActiveSuggestions
              }
            ];

            return (
              <>
                <TextField
                  type="text"
                  variant="outlined"
                  name="Address autocomplete"
                  aria-label="Address autocomplete. Type and navigate options with arrow keys and press tab to select option, or if cannot find address, press enter for manual address entry."
                  {...getInputProps({
                    name: field.name,
                    placeholder,
                    label,
                    id: 'address-autocomplete-input',
                    'aria-owns': 'suggestions-listbox',
                    'aria-controls': 'suggestions-listbox'
                  })}
                />
                {isManualActive &&
                  !loading &&
                  dirty &&
                  (isValid || isValidating) &&
                  !isSubmitting &&
                  !scriptError && (
                    <AutocompleteDropDown
                      role="listbox"
                      name="suggestions"
                      id="suggestions-listbox"
                      aria-label="address suggestions"
                    >
                      {loading ? (
                        <li role="none" className="list-item-container">
                          <Text loading="true">Loading...</Text>
                        </li>
                      ) : (
                        <>
                          {suggestionsWithNotFoundLink.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';

                            const style = suggestion.active
                              ? {
                                  backgroundColor: `${colors.usaaLightestGrey}`,
                                  cursor: 'pointer'
                                }
                              : {
                                  backgroundColor: `${colors.white}`,
                                  cursor: 'pointer'
                                };

                            return (
                              <li
                                role="option"
                                // className="list-item-container"
                                aria-selected={suggestion.active}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style
                                })}
                                key={suggestion.placeId || suggestion.id}
                                id={
                                  suggestion.active
                                    ? 'selected_option'
                                    : suggestion.placeId
                                }
                              >
                                <SuggestionText suggestion={suggestion} />
                              </li>
                            );
                          })}
                          <li role="none" className="list-item-container">
                            <GoogleImage
                              crossorigin="anonymous"
                              alt=""
                              src={`${process.env.REACT_APP_NOBLR_CDN}/images/powered-by-google.webp`}
                            />
                          </li>
                        </>
                      )}
                    </AutocompleteDropDown>
                  )}
              </>
            );
          }}
        </PlacesAutocomplete>
      </>
    );
  }
}

const mapStateToProps = ({
  app: { manualAddress },
  drivers: {
    primaryDriver: { addressComplete, street, driverComplete }
  }
}) => ({
  manualAddress,
  streetAddress: street,
  addressComplete,
  driverComplete
});

const mapDispatchToProps = dispatch => ({
  redirect: url => dispatch(redirect(url)),
  saveAutocompleteAddress: address =>
    dispatch(saveAutocompleteAddress(address)),
  setManualAddressFlag: value => dispatch(setManualAddressFlag(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoCompleteAddressInput);
