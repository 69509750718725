import * as Sentry from '@sentry/react';
import Noblr from '@noblr-lab/react-app-services';
import { put, all, call, select, putResolve } from 'redux-saga/effects';
import { createPayload } from 'utilities';

function AssociatedDriver({
  activeBit,
  activeDriverLicense = true,
  age,
  ageLicensed,
  askActiveMilitaryPersonnel = false,
  askGoodStudentDiscount = false,
  askSafeDriving = false,
  dlNumber,
  dlState,
  doB = null, // prefill sends "doB" for additional drivers
  dob,
  driverId,
  driverComplete = false,
  educationLevelString,
  firstName,
  lastName,
  gender = null,
  genderMapping,
  givenName,
  homeOwner,
  maritalStatusString = null,
  prefill = true,
  safeDriver = false,
  sequence,
  surname,
  type = 'additional',
  edited = false,
  obfuscatedLastName
}) {
  this.activeBit = activeBit;
  this.activeDriverLicense = activeDriverLicense;
  this.age = age;
  this.ageLicensed = ageLicensed;
  this.askActiveMilitaryPersonnel = askActiveMilitaryPersonnel;
  this.askGoodStudentDiscount = askGoodStudentDiscount;
  this.askSafeDriving = askSafeDriving;
  this.dlNumber = dlNumber;
  this.dlState = dlState;
  this.dob = doB || dob;
  this.driverId = driverId;
  this.driverComplete = driverComplete;
  this.education = educationLevelString;
  this.firstName = firstName || givenName;
  this.gender = gender || genderMapping;
  this.genderMapping = genderMapping;
  this.homeOwner = homeOwner;
  this.lastName = lastName || surname || '';
  this.maritalStatus = maritalStatusString;
  this.prefill = prefill;
  this.safeDriver = safeDriver;
  this.sequence = sequence;
  this.type = type;
  this.edited = edited;
  this.driverComplete = driverComplete;
  this.obfuscatedLastName = obfuscatedLastName;
}

/**
 * Update array of additional drivers
 * @param {Object} payload - array of drivers
 */
export function* getAssociatedDriversSubroutine({ payload }) {
  const additionalDrivers = payload
    // filter out primary driver
    .filter(driver => driver.type !== 'primary')
    .map(driver => new AssociatedDriver(driver));

  yield put({
    type: 'GET_ASSOCIATED_DRIVERS_SUCCESS',
    payload: additionalDrivers
  });
}

/**
 * Calls Noblr API to delete driver by driverId
 * Sets active bit to false for driver if call was successful
 * @param {Object} payload
 * @param {string} driverId - unique id for driver
 */
export function* removeDriverWatcher({ payload }) {
  const { driverId, activeMilitaryPersonnel } = payload;

  const {
    app: { askMilitaryServiceVehicles },
    drivers: { items, primaryDriver }
  } = yield select();

  const drivers = items.concat(primaryDriver);

  try {
    /* checks if no other drivers are active military personnel then resets activeMilitaryServiceVehicles flag */
    if (askMilitaryServiceVehicles && activeMilitaryPersonnel) {
      const hasActiveMilitaryDrivers = drivers.some(
        driver =>
          driver.activeMilitaryPersonnel &&
          driver.activeBit &&
          driverId !== driver.driverId
      );

      if (!hasActiveMilitaryDrivers) {
        yield put({
          type: 'UPDATE_APP',
          payload: {
            askMilitaryServiceVehicles: false
          }
        });
      }
    }
    // calls endpoint to set active bit to false for driver id
    yield call(Noblr.driver.deleteDriverAfterPrefill, {
      driverId
    });

    yield put({
      type: 'REMOVE_DRIVER_SUCCESS',
      payload: driverId
    });
    yield put({
      type: 'CLEAR_EDITING_DRIVER'
    });
  } catch (error) {
    yield put({
      type: 'REMOVE_DRIVER_FAILURE',
      error
    });
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 * Fetch all drivers on quote from Noblr API
 */
export function* getAllDriversWatcher() {
  try {
    const {
      drivers: { primaryDriver }
    } = yield select();

    // calls endpoint to get all drivers
    const allDrivers = yield call(Noblr.driver.getDrivers);

    yield put({
      type: 'GET_ALL_DRIVERS_SUCCESS',
      payload: {
        drivers: allDrivers,
        personId: primaryDriver.personId,
        quoteId: primaryDriver.quoteId,
        primaryDriverEmail: primaryDriver.email
      }
    });
  } catch (error) {
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, ...error.data }
    });
  }
}

/**
 *
 * Splits drivers by type
 * Builds driver objects for primary and additional drivers
 * Stores driver objects in driver reducer
 * @param {Object} payload
 * @param {Array} payload.drivers - array of driver objects
 * @param {string} payload.personId - primary driver's person id
 * @param {string} payload.quoteId - quote id
 * @param {string} payload.primaryDriverEmail - primary driver's email address
 */
export function* getAllDriversSuccess({
  payload: { drivers, personId, quoteId, primaryDriverEmail }
}) {
  let retrievedPrimaryDriver = {};
  let additionalDrivers = [];

  // loop through all drivers
  for (let i = 0; i < drivers.length; i++) {
    const driver = drivers[i];

    // restructure primary driver object
    if (driver.type === 'primary') {
      if (primaryDriverEmail) {
        retrievedPrimaryDriver = {
          ...driver,
          personId,
          quoteId,
          email: primaryDriverEmail
        };
      } else {
        retrievedPrimaryDriver = {
          ...driver,
          personId,
          quoteId
        };
      }
    } else {
      // push all additional drivers into array
      additionalDrivers = [
        ...additionalDrivers,
        {
          ...driver,
          givenName: driver.firstName,
          obfuscatedLastName: driver.lastName,
          surname: driver.lastName,
          // set primary to false for additional drivers

          // set prefill to true since drivers were returned from verisk
          prefill: true,
          maritalStatusString: driver.maritalStatus,
          educationLevelString: driver.education
        }
      ];
    }
  }

  // update the primary driver
  yield putResolve({
    type: 'UPDATE_PRIMARY_DRIVER',
    payload: { ...retrievedPrimaryDriver }
  });

  // set all additional drivers info
  yield putResolve({
    type: 'GET_ASSOCIATED_DRIVERS',
    payload: additionalDrivers
  });
}

/**
 * Save driver info to backend
 */
export function* saveAdditionalDriverSubroutine({ payload }) {
  const { firstName, lastName } = payload;
  const requestBody = {
    firstName,
    lastName,
    type: 'additional'
  };

  // Send request to Noblr App Services endpoint
  try {
    /*
      ** NOTE: Destructure specific values from response **
      DO NOT destructure: "firstName", "lastName", "email" or "personId" (see below)

      "lastName" - response sends obfuscated value
      "email" and "personId" - correspond to primary driver, not additional driver
    */
    const { activeBit, driverId, driverComplete, type, quoteId } = yield call(
      Noblr.driver.saveDriverInfo,
      requestBody
    );

    yield put({
      type: 'SAVE_ADDITIONAL_DRIVER_INFO_SUCCESS',
      payload: {
        firstName,
        driverId,
        activeBit,
        driverComplete,
        type,
        quoteId,
        lastName,
        edited: true,
        prefill: false
      }
    });
  } catch (error) {
    yield put({
      type: 'SAVE_ADDITIONAL_DRIVER_INFO_FAILURE',
      error
    });
    // catch error and set it in our store
    yield put({
      type: 'SET_ERROR',
      error: error.status ? { status: error.status, ...error.data } : error
    });
  }
}

export function* updateAdditionalDriverInfoSubroutine(action) {
  const {
    app: { askMilitaryServiceVehicles, askActiveMilitaryPersonnel },
    drivers: { editingDriver, savingDriver }
  } = yield select();

  const { makeApiCall = false, payload, successRoute } = action;
  // get user id from editing driver or person
  const userDriverId = editingDriver && editingDriver.driverId;

  Sentry.withScope(scope => {
    scope.setTag('driver_id', userDriverId);
  });

  // include driver id in payload update the driver correctly
  const payloadToSave = {
    ...payload,
    driverId: userDriverId,
    type: 'additional'
  };

  /*
    ! TODO: Confirm whether "edited" should be set here or come up with a better solution to track "edited" drivers that have actually received updated information
    UPRDATE_EDITING_DRIVER - inconsistent and should be changed to align with initial action - UPDATE_ADDITIONAL_DRIVER_INFO -> UPDATE_ADDITIONAL_DRIVER_INFO_SUCCES /UPDATE_ADDITIONAL_DRIVER_INFO_FAILURE
   */

  // check if we need to hit the endpoint
  if (makeApiCall) {
    try {
      // Send request to Noblr App Services endpoint to update driver info
      const response = yield call(Noblr.driver.updateDriverInfo, payloadToSave);
      /*
        NOTE: When confirming driver information, /update response body does not send every value that's passed in the request body.
        For ex. "maritalStatus", "gender"/"genderMapping", "dlState" are not sent in response even when they're in the request body
      */
      const {
        requiresVehicleOwnershipMonths,
        requiresVehicleAdvancedTechnologyDiscount,
        requiresSeniorSafeDriverDiscount,
        requiresAntitheftDiscount,
        requiresVinEtchingDiscount,
        requiresActiveMilitaryPersonnel,
        requiresAllVehiclesOwnedByAMP,
        requiresSeniorDriverImprovementDiscount,
        requiresVehicleAssignment,
        requiresAirbagVerification,
        requiresDriverExclusion,
        requiresUmuimOfferForm,
        requiresMedPayOfferForm,
        requiresRefusalToWriteNoticeForm,
        requiresDynamicForms,
        requiresGoodStudentDiscount,
        driverComplete,
        lastName,
        activeBit,
        dlNumber,
        activeMilitaryPersonnel = false, // Specific to LA
        ...rest
      } = response;

      const updatedDriver = {
        ...payload,
        ...rest,
        dlNumber,
        obfuscatedLastName: lastName,
        activeMilitaryPersonnel
      };

      /* if an active additional driver is active military personnel and
      askMilitaryServiceVehicles is still false, set it to "true" for LA quotes */
      if (
        activeBit &&
        !askMilitaryServiceVehicles &&
        requiresAllVehiclesOwnedByAMP &&
        !!activeMilitaryPersonnel
      ) {
        yield put({
          type: 'ASK_MILITARY_SERVICE_VEHICLES'
        });
      }

      yield putResolve({
        type: 'UPDATE_EDITING_DRIVER',
        payload: {
          ...updatedDriver,
          driverComplete
        }
      });

      // TODO: Find a better way to track whether a driver has been completed. Response will contain "driverComplete": true" after the first time the BE receives the required additional driver information, anytime a subsequent request is sent with the same or modified driver information
      if (successRoute === '/add-drivers/drivers-list' && driverComplete) {
        // set the driver as completed
        yield putResolve({
          type: 'COMPLETE_ADDING_ADDITIONAL_DRIVER',
          payload: {
            ...updatedDriver,
            incomplete: false,
            added: true,
            driverComplete: true
          }
        });
      }

      // if we aren't saving safe driver and the user is eligible for senior safe driving discount
      if (
        !editingDriver.askSafeDriving &&
        !payload.safeDriver &&
        requiresSeniorSafeDriverDiscount
      ) {
        yield put({
          type: 'UPDATE_EDITING_DRIVER',
          payload: {
            askSafeDriving: true
          }
        });

        // if driver being edited is prefill driver, and the backend response has requiresSeniorSafeDriverDiscount as true, send to safe driver screen
        if (editingDriver.prefill && requiresSeniorSafeDriverDiscount) {
          yield put({
            type: 'REDIRECT',
            payload: {
              url: '/add-drivers/defensive-driving-course'
            }
          });
        }

        return;
      }

      if (
        !payload.goodStudentDiscount &&
        response.requiresGoodStudentDiscount
      ) {
        yield put({
          type: 'UPDATE_EDITING_DRIVER',
          payload: {
            askGoodStudentDiscount: true
          }
        });

        // if driver being edited is prefill driver, and the backend response has requiresGoodDriverStudentDiscount as true, send to college graduate discount screen
        if (editingDriver.prefill && requiresGoodStudentDiscount) {
          yield put({
            type: 'REDIRECT',
            payload: {
              url: '/add-drivers/good-student-discount'
            }
          });
        }

        return;
      }

      // if we aren't saving activeMilitaryPersonnel, set boolean to ask military service question
      if (
        !payload.activeMilitaryPersonnel &&
        payload.dlNumber &&
        askActiveMilitaryPersonnel
      ) {
        // redirect prefill users with military service question to screen to answer it

        yield put({
          type: 'REDIRECT',
          payload: {
            url: '/add-drivers/active-military-service'
          }
        });

        return;
      }
    } catch (error) {
      // catch an errors and set them on redux store
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    }
  } else {
    // we don't need to make the api call
    // redirect to on success route
    yield put({
      type: 'REDIRECT',
      payload: {
        url: successRoute
      }
    });

    if (!savingDriver) {
      yield put({
        type: 'CLEAR_EDITING_DRIVER'
      });
    }
  }
}

/**
 *
 * Save primary driver info
 */
export function* savePrimaryDriver({ payload }) {
  const {
    drivers: {
      primaryDriver: { dob, firstName, lastName, zipCode }
    }
  } = yield select();

  const keys = {
    firstName,
    lastName,
    dob: payload.dob || dob,
    type: 'primary',
    zipCode
  };

  // todo: determine if we can remove this?
  const payloadToSave = createPayload(keys);

  try {
    // Send request to Noblr App Services endpoint
    const response = yield call(Noblr.driver.saveDriverInfo, payloadToSave);

    // if we passed in a dob, street, or dl number
    if (payload.dob || payload.street || payload.dlNumber) {
      // update confirmed data so we know which fields need to be masked

      let confirmedData = null;

      if (payload.dob) {
        confirmedData = 'dob';
      } else if (payload.street) {
        confirmedData = 'street';
      } else {
        confirmedData = 'dlNumber';
      }
      // update app store with updated confirmed data
      yield put({
        type: 'CONFIRMED_PII',
        payload: {
          confirmedPII: {
            [confirmedData]: true
          }
        }
      });
    }

    // update primary driver in driver store
    yield put({
      type: 'UPDATE_PRIMARY_DRIVER',
      payload: {
        ...response
      }
    });
  } catch (error) {
    // otherwise, catch error and set it
    yield put({
      type: 'SET_ERROR',
      error: error.status ? { status: error.status, ...error.data } : error
    });
  }
}

export function* activeDriverLicenseStatusSubroutine({
  payload: { activeDriverLicense }
}) {
  if (activeDriverLicense === 'Yes') {
    yield put({
      type: 'REDIRECT',
      payload: { url: '/add-drivers/driver-license' }
    });
  } else {
    yield put({
      type: 'REDIRECT',
      payload: { url: '/add-drivers/confirm-excluded' }
    });
  }
}

export function* confirmExcludedDriverWatcher({ payload: { excludeDriver } }) {
  const {
    drivers: {
      editingDriver: { driverId }
    }
  } = yield select();

  // User answered "No" on '/add-drivers/confirm-excluded
  const isDriverAdded = excludeDriver === 'No';

  if (isDriverAdded) {
    yield put({
      type: 'REDIRECT',
      payload: { url: '/add-drivers/verify-active-driver-license' }
    });
  } else {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: true
      }
    });

    // driver has been excluded
    try {
      const response = yield call(Noblr.driver.updateDriverInfo, {
        driverId,
        activeDriverLicense: false,
        type: 'excluded'
      });

      yield all([
        putResolve({
          type: 'UPDATE_EDITING_DRIVER',
          payload: { ...response }
        }),
        putResolve({
          type: 'COMPLETE_ADDING_ADDITIONAL_DRIVER'
        }),
        put({
          type: 'REDIRECT',
          payload: { url: '/add-drivers/drivers-list' }
        })
      ]);
    } catch (error) {
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    } finally {
      yield put({
        type: 'TOGGLE_LOADER',
        payload: {
          toShow: false
        }
      });
    }
  }
}
