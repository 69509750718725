import React, { Component } from 'react';
import SignPage from 'components/Pages/SignPage';
import { StyledContentWrapper } from 'styles';

class TortOptionsFormPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'SUBMIT_PREBIND_SIGNATURE',
      successRoute: '/purchase/sign-insurance-application',
      fields: [
        {
          type: 'sign',
          name: 'tortOptions',
          id: 'tort-options-input',
          label: 'First and Last Name',
          required: true,
          validation: 'fullName',
          autoComplete: 'name',
          fullWidth: true
        }
      ],
      staticContent: {
        form: [
          {
            type: 'static',
            location: 'form',
            value:
              'By continuing, I acknowledge that I have read and agree to the Notice to Named Insured and agree that entering my name above serves as and replaces the need for my physical signature and signifies my authorization going forward.'
          }
        ]
      }
    };
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    return (
      <StyledContentWrapper>
        <SignPage
          pdfFileName="tortOptions"
          prompt={prompt}
          formStaticContent={form}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          documentName="Tort Options - Notice to Name Insured"
        />
      </StyledContentWrapper>
    );
  }
}

export default TortOptionsFormPage;
