import React, { Component } from 'react';
import { withStyles, styled } from '@material-ui/core/styles';
import { Slider, Tooltip, Typography, Box } from '@material-ui/core';
import {
  generateMileageHeading,
  generatePersonaContent
} from 'utilities/coverage';
import { colors, brandonGrotesque } from 'styles/variables';
import { SLIDER_CONFIG } from '../../constants';

const NoblrSlider = withStyles({
  root: {
    marginTop: 50,
    marginBottom: 50,
    color: colors.coreBlue700,
    borderRadius: 4
  },
  track: {
    height: 6,
    borderRadius: 6
  },
  rail: {
    height: 6,
    opacity: 1,
    backgroundColor: colors.coreBlue700,
    borderRadius: 4
  },
  mark: {
    height: 6,
    width: 2,
    borderRadius: 0,
    backgroundColor: colors.white
  },
  markActive: {
    opacity: 1,
    backgroundColor: colors.white
  },
  active: {},
  thumb: {
    height: 30,
    width: 30,
    marginTop: -12,
    marginLeft: -16,
    backgroundColor: 'transparent',
    border: '0px solid transparent',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  }
})(Slider);

const TitleContainer = styled('div')({
  borderRadius: 2,
  border: `2px solid ${colors.alphaBlue500}`,
  marginTop: -5,
  marginLeft: -13,
  marginRight: -8
});

const SubTitleContainer = styled('div')({
  background: colors.usaaLightestGrey,
  height: 40,
  paddingTop: 10,
  width: 'inherit',
  textAlign: 'center',
  verticalAlign: 'middle'
});

const subTitleStyles = theme => ({
  subtitle2: {
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16
    }
  }
});

const SubTitleTypography = withStyles(subTitleStyles)(Typography);

const bodyTypographyStyles = theme => ({
  body2: {
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
});

const BodyTypography = withStyles(bodyTypographyStyles)(Typography);

const BodyContainer = styled('div')({
  padding: 10
});

const mileageToolTipStyles = theme => ({
  arrow: {
    color: colors.alphaBlue500
  },
  popper: {
    padding: 0,
    marginTop: '-10px',
    height: 30,
    width: 120,
    [theme.breakpoints.down('xs')]: {
      marginTop: -20
    }
  },
  tooltip: {
    left: -2,
    height: 30,
    width: 120,
    padding: 5,
    backgroundColor: colors.white,
    borderRadius: 2,
    border: `2px solid ${colors.alphaBlue500}`,
    color: colors.coreBlue700,
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 14
  }
});

const MileageToolTip = withStyles(mileageToolTipStyles)(Tooltip);

const personaToolTipStyles = theme => ({
  arrow: {
    marginTop: -10,
    color: colors.alphaBlue500
  },
  popper: {
    padding: 0,
    marginTop: '-10px',
    height: 180,
    width: 200,
    [theme.breakpoints.down('xs')]: {
      marginTop: -20,
      height: 160,
      width: 190
    }
  },
  tooltip: {
    left: -2,
    paddingTop: 5,
    width: 200,
    backgroundColor: colors.white,
    color: colors.coreBlue700,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 150,
      width: 180,
      left: 0
    }
  }
});

const PersonasToolTip = withStyles(personaToolTipStyles)(Tooltip);

const MileageHeading = styled('div')({
  marginTop: 10,
  fontFamily: `${brandonGrotesque}`,
  fontSize: 18,
  letterSpacing: 1,
  color: colors.coreBlue700
});

function MileageLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <MileageToolTip
      open={open}
      arrow
      enterTouchDelay={0}
      placement="bottom"
      title={`${value} miles/day`}
    >
      {children}
    </MileageToolTip>
  );
}

function PersonaLabelComponent(props) {
  const { children, open, value } = props;
  const { title, body } = generatePersonaContent(value);

  return (
    <PersonasToolTip
      open={open}
      arrow
      enterTouchDelay={0}
      placement="bottom"
      title={
        <TitleContainer id="material-slider-marks-text">
          <SubTitleContainer>
            <SubTitleTypography component="div" variant="subtitle2">
              <Box m={0}>{title}</Box>
            </SubTitleTypography>
          </SubTitleContainer>
          <BodyContainer>
            <BodyTypography component="div" variant="body2">
              <Box m={0}>{body}</Box>
            </BodyTypography>
          </BodyContainer>
        </TitleContainer>
      }
    >
      {children}
    </PersonasToolTip>
  );
}

const NoblrThumbComponent = React.forwardRef((props, ref) => (
  <div id="material-slider-marks-text" {...props} ref={ref}>
    <img
      src={`${process.env.REACT_APP_NOBLR_CDN}/icons/slider-icon.svg`}
      alt=""
      className="sliderSelector"
    />
  </div>
));

NoblrThumbComponent.displayName = 'NoblrThumbComponent';

class MaterialSlider extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.renderMileageHeading = this.renderMileageHeading.bind(this);
    this.handleCommittedChange = this.handleCommittedChange.bind(this);
    this.valueText = this.valueText.bind(this);
  }

  handleChange(event, value) {
    const {
      form: { setFieldTouched, setFieldValue },
      field: { name }
    } = this.props;

    const currentValue = name === 'mileage' ? Math.floor(value) : value;

    setFieldTouched(name, true, false);
    setFieldValue(name, currentValue, false);
  }

  handleCommittedChange(event, value) {
    const {
      form: { setFieldValue, setFieldTouched },
      field: { name }
    } = this.props;

    const currentValue = name === 'mileage' ? Math.floor(value) : value;

    setFieldValue(name, currentValue, false);
    setFieldTouched(name, true, false);
  }

  // TODO: Figure out why this is necessary
  // eslint-disable-next-line class-methods-use-this
  valueText(index) {
    return `${index}`;
  }

  renderMileageHeading() {
    const {
      field: { value }
    } = this.props;

    const mileageHeadingValue = generateMileageHeading(value);

    return (
      <MileageHeading>
        <SubTitleTypography
          id="material-slider-marks-text"
          component="div"
          variant="subtitle2"
        >
          <Box m={1}>{mileageHeadingValue}</Box>
        </SubTitleTypography>
      </MileageHeading>
    );
  }

  render() {
    const {
      field: { name, value }
    } = this.props;

    let mileageHeading = null;
    const mileageName = name === 'mileage';

    const { title } = generatePersonaContent(value);
    const sliderAriaValueText = `${
      mileageName ? `${value} miles per day` : `${title}`
    }`;

    const sliderAriaLabelText = `Slider for choosing
      ${mileageName ? 'miles driven per day' : 'your driving behavior'}`;

    if (mileageName) {
      mileageHeading = this.renderMileageHeading();
    }

    return (
      <>
        {mileageName && mileageHeading}
        <NoblrSlider
          ValueLabelComponent={
            mileageName ? MileageLabelComponent : PersonaLabelComponent
          }
          valueLabelDisplay="on"
          ThumbComponent={NoblrThumbComponent}
          value={value}
          min={SLIDER_CONFIG[name].min}
          max={SLIDER_CONFIG[name].max}
          marks={SLIDER_CONFIG[name].marks}
          onChange={this.handleChange}
          onChangeCommitted={this.handleCommittedChange}
          step={SLIDER_CONFIG[name].step}
          aria-label={sliderAriaLabelText}
          aria-valuetext={sliderAriaValueText}
          aria-describedby="material-slider-marks-text"
        />
      </>
    );
  }
}

export default MaterialSlider;
