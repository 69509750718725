import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

class MaskedTextInput extends Component {
  constructor(props) {
    super(props);

    this.state = { shrinkLabel: false };

    this.toggleLabel = this.toggleLabel.bind(this);
    this.generateDisplayValue = this.generateDisplayValue.bind(this);
  }

  toggleLabel() {
    this.setState(prevState => ({
      shrinkLabel: !prevState.shrinkLabel
    }));
  }

  generateDisplayValue() {
    const { form, field } = this.props;

    if (form.isSubmitting) {
      return '***********';
    }

    return field.value;
  }

  render() {
    const { shrinkLabel } = this.state;
    const {
      field,
      form,
      form: { setFieldValue, setFieldTouched, initialValues },
      label,
      placeholder,
      required
    } = this.props;

    const updatedValue = this.generateDisplayValue();

    return (
      <TextField
        {...field}
        id={field.id}
        value={updatedValue}
        label={label}
        InputLabelProps={{
          htmlFor: field.name,
          shrink: (field.value && !!field.value.length) || shrinkLabel,
          notched: 'true'
        }}
        onFocus={() => {
          this.toggleLabel();

          if (field.value === initialValues[field.name]) {
            setFieldTouched(field.name, true, false);

            setFieldValue(field.name, '', true);
          }
        }}
        onBlur={() => {
          if (!field.value) {
            form.setFieldValue(
              field.name,
              form.initialValues[field.name],
              false
            );

            form.setFieldTouched(field.name, false, false);

            this.toggleLabel();
          }
        }}
        placeholder={placeholder}
        className="fs-exclude"
        variant="outlined"
        type="text"
        inputProps={{
          'aria-label': field.name,
          'aria-labelledby': field.name,
          'aria-required': required,
          required
        }}
      />
    );
  }
}

export default MaskedTextInput;
