import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

class PaymentInfoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Please enter your payment info here.',
      submitAction: 'SAVE_PAYMENT_INFO',
      fields: [
        {
          type: 'text',
          inputType: 'text',
          id: 'full-name-id-input',
          name: 'fullName',
          placeholder: 'First and Last Name',
          validation: 'fullName',
          label: 'Cardholder name',
          autoComplete: 'cc-name',
          required: true
        },
        {
          type: 'text',
          id: 'credit-card-input',
          name: 'creditCard',
          autoComplete: 'cc-number',
          required: true,
          label: 'Credit card number',
          maxLength: 19
        },
        {
          type: 'text',
          inputType: 'text',
          id: 'card-expiration-input',
          name: 'cardExpiration',
          autoComplete: 'cc-exp',
          required: true,
          formattingOptions: {
            date: true,
            datePattern: ['m', 'y']
          },
          label: 'Expiration date (MM/YY)'
        },
        {
          type: 'text',
          inputType: 'text',
          id: 'card-cvv-input',
          name: 'cardCVV',
          autoComplete: 'cc-csc',
          placeholder: '***',
          required: true,
          validation: 'cardCVV',
          label: 'security code'
        },
        {
          type: 'text',
          inputType: 'text',
          id: 'card-zip-code-input',
          name: 'cardZipCode',
          autoComplete: 'shipping postal-code',
          validation: 'cardZipCode',
          required: true,
          label: 'Billing Zip'
        },
        {
          type: 'text',
          inputType: 'text',
          id: 'phone-number-input',
          name: 'phoneNumber',
          autoComplete: 'tel',
          validation: 'phoneNumber',
          minLength: 15,
          maxLength: 15,
          required: true,
          label: 'Phone Number',
          formattingOptions: {
            delimiters: ['(', ') ', '-', '-'],
            blocks: [0, 3, 3, 4]
          }
        },
        {
          type: 'text',
          inputType: 'email',
          id: 'payment-email-input',
          name: 'email',
          autoComplete: 'email',
          validation: 'email',
          label: 'Confirm Email Address',
          required: true,
          initialValue: '' // set in componentDidMount below
        }
      ],
      successRoute: '/purchase/review-and-pay',
      staticContent: {
        form: [
          {
            type: 'PaymentDisclaimer',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { trialActive, primaryDriver } = this.props;
    const { fields } = this.state;
    const primaryEmailExists =
      primaryDriver.email && primaryDriver.email.length > 5;

    const { email } = primaryDriver;

    const updatedFields = fields.map(field =>
      field.name === 'email'
        ? {
            ...field,
            initialValue: primaryEmailExists && email,
            // disable email input for active trial users
            disabled: trialActive && primaryEmailExists
          }
        : field
    );

    this.setState({ fields: updatedFields });
  }

  render() {
    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    return (
      <StyledContentWrapper>
        <Form
          submitAsync
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          staticContent={form}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { trialActive, form },
  drivers: { primaryDriver }
}) => ({
  trialActive,
  form,
  primaryDriver
});

PaymentInfoPage.propTypes = {
  trialActive: PropTypes.bool,
  primaryDriver: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired
};
PaymentInfoPage.defaultProps = {
  trialActive: false
};

export default connect(mapStateToProps)(PaymentInfoPage);
