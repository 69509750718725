/* eslint-disable styled-components-a11y/click-events-have-key-events */
/* eslint-disable styled-components-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  brandonGrotesque,
  openSans,
  colors,
  breakPoints
} from 'styles/variables';

const AccordionContainer = styled.div`
  margin-top: 25px;

  @media screen and (max-width: ${breakPoints.medium}) {
    padding: 0 40px;
    ${props =>
      props.purchaseSummarySection &&
      css`
        padding: 0;
      `}
  }

  ${props =>
    props.noMargin &&
    css`
      margin-top: 0;
    `}
`;

const StyledAccordion = styled.div`
  padding: 32px 0;
  ${props =>
    props.noMargin &&
    css`
      padding: 0;
    `}
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.usaaLightestGrey};
  }
  .accordion-section {
    height: 0;
    overflow: hidden;
    &.open {
      height: auto;
      padding-left: 26px;
      .accordion-carat {
        transform: rotate(90deg);
      }
    }
  }
`;

const StyledTitle = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;

  .title-text {
    font-family: ${brandonGrotesque};
    text-align: left;
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: 0.8px;
    text-transform: capitalize;
    margin: 0;
    padding-left: 10px;
  }
  .accordion-carat {
    p {
      color: ${colors.white};
      margin: 0;
      text-align: center;
      line-height: 1;
      font-weight: bold;
    }
    &.open {
      transform: rotate(90deg);
    }
  }
`;

const StyledContent = styled.div`
  & > p {
    text-align: left;
    font-family: ${openSans};
    font-size: 14px;
    margin: 1em auto;
  }
`;

class Accordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openSections: {}
    };

    this.toggleAccordionSection = this.toggleAccordionSection.bind(this);
    this.renderSectionTitle = this.renderSectionTitle.bind(this);
  }

  componentDidMount() {
    const { defaultOpen, sections } = this.props;

    if (!defaultOpen) {
      return;
    }

    const openSections = {};

    for (let i = 0; i < sections.length; i++) {
      const sectionTitle = sections[i].title;

      if (!Object.prototype.hasOwnProperty.call(openSections, sectionTitle)) {
        openSections[sectionTitle] = true;
      }
    }

    this.setState({
      openSections
    });
  }

  toggleAccordionSection(title) {
    this.setState(prevState => ({
      openSections: {
        [title]: !prevState.openSections[title]
      }
    }));
  }

  renderSectionTitle(title) {
    const { openSections } = this.state;

    return (
      <StyledTitle onClick={() => this.toggleAccordionSection(title)}>
        <img
          className={`accordion-carat ${openSections[title] ? 'open' : ''}`}
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/alpha-blue-accordion.svg`}
          width="16px"
          height="16px"
          alt="Toggle Expandible"
        />
        <div className="title-text">{title}</div>
      </StyledTitle>
    );
  }

  render() {
    const {
      sections,
      purchaseSummarySection = false,
      noMargin = false
    } = this.props;

    const { openSections } = this.state;

    return (
      <AccordionContainer
        purchaseSummarySection={purchaseSummarySection}
        noMargin={noMargin}
      >
        {sections.map(section => (
          <StyledAccordion key={section.title} noMargin={noMargin}>
            {this.renderSectionTitle(section.title)}
            <StyledContent
              className={`accordion-section ${
                openSections[section.title] ? 'open' : ''
              }`}
            >
              {section.flag === 'dangerouslySetInnerHTML' && (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: section.content }} />
              )}
              {!section.flag && section.content}
            </StyledContent>
          </StyledAccordion>
        ))}
      </AccordionContainer>
    );
  }
}

export default Accordion;
