import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { colors, breakPoints } from 'styles/variables';

const StickyWrapper = styled.div`
  &&& {
    width: 100%;
  }
  z-index: 5;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  padding: 0;
  position: ${props => (props.buttonPosition ? props.buttonPosition : 'fixed')};
  background-color: ${colors.white};
  left: 0;
  right: 0;
  bottom: ${props => (props.bottomOffset ? props.bottomOffset : 0)};

  button {
    max-width: 100%;
    margin: 0 auto;
  }
  /* 500px to ensure button does not overlap SF chat widget */
  @media only screen and (min-width: ${breakPoints.small}) {
    padding: 10px 0;
    background-color: transparent;
    position: inherit;
  }
`;

const StickyItem = styled.div`
  padding: 20px 0 15px;
  height: ${props => (props.primaryLinkButton ? '95px' : 'auto')};
  border-top: 1px solid ${colors.usaaLightGrey};
  display: inline-block;

  /* 500px to ensure button does not overlap SF chat widget */
  @media only screen and (min-width: ${breakPoints.small}) {
    padding-top: 10px;
    border-top: none;
    height: auto;
  }
`;

function Sticky({
  children = null,
  buttonPosition = null,
  marginTop = '0',
  marginBottom = '0',
  bottomOffset = '0',
  primaryLinkButton = false
}) {
  return (
    children && (
      <StickyWrapper
        buttonPosition={buttonPosition}
        bottomOffset={bottomOffset}
        marginTop={marginTop}
        marginBottom={marginBottom}
      >
        <StickyItem primaryLinkButton={primaryLinkButton}>
          {children}
        </StickyItem>
      </StickyWrapper>
    )
  );
}

export default memo(Sticky);
