import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';

class PriorInsuranceLapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt: '',
      submitAction: 'UPDATE_PRIOR_INSURANCE_LAPSE_REASON',
      fields: [
        {
          type: 'select',
          name: 'priorInsuranceLapseReason',
          id: 'prior-insurance-lapse-select-input',
          selectLabel: 'Prior Insurance',
          selectLabelId: 'prior-insurance-select-label',
          options: [
            { value: 'MILITARY_DEPLOYMENT', label: 'Military Deployment' },
            { value: 'OTHER', label: 'Another Reason' }
          ],
          required: true,
          initialValue: null
        }
      ],
      successRoute: '/add-drivers/safe-driving',
      staticContent: {
        form: [
          {
            type: 'static',
            value:
              'A lapse in coverage can affect your rate. If you believe you were not required to have car insurance or believe our information is incorrect, please contact us at 1-877-236-6257.',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { priorInsuranceLapseReason, priorInsuranceLapseOptions, veriskHit } =
      this.props;

    const { fields } = this.state;

    const promptToDisplay = veriskHit
      ? 'Your driving records show a lapse in coverage in the last 6 months. Please select the reason.'
      : 'We did not find your prior insurance records. Please select the reason for any lapse in coverage in the last 6 months.';

    if (priorInsuranceLapseOptions.length > 0) {
      const updatedFields = fields.map(field => {
        const currField = { ...field };

        currField.options = [...priorInsuranceLapseOptions];

        if (priorInsuranceLapseReason) {
          currField.initialValue = priorInsuranceLapseReason;
        }

        return currField;
      });

      this.setState({
        fields: updatedFields,
        prompt: promptToDisplay
      });
    }
  }

  render() {
    const {
      submitAction,
      fields,
      prompt,
      successRoute,
      staticContent: { form }
    } = this.state;

    const { primaryDriverState } = this.props;
    const isIndiana = primaryDriverState === 'IN';

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          staticContent={form}
          submitAsync
        />
        {isIndiana && (
          <InfoBox
            extraData={{
              header: 'What Should I Select?',
              text: 'You should select “Armed Forces” if you are currently serving or have served within the last 6 months in one of the following Armed Forces:  Army, Navy, Air Force, Marine Corps, or Coast Guard. Otherwise, select “Others”.'
            }}
          />
        )}
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { priorInsuranceLapseReason, priorInsuranceLapseOptions, veriskHit },
  drivers: {
    primaryDriver: { state: primaryDriverState }
  }
}) => ({
  priorInsuranceLapseReason,
  priorInsuranceLapseOptions,
  veriskHit,
  primaryDriverState
});

PriorInsuranceLapse.propTypes = {
  priorInsuranceLapseReason: PropTypes.string,
  priorInsuranceLapseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  veriskHit: PropTypes.bool.isRequired,
  primaryDriverState: PropTypes.string.isRequired
};

PriorInsuranceLapse.defaultProps = {
  priorInsuranceLapseReason: ''
};

export default connect(mapStateToProps)(PriorInsuranceLapse);
