import React from 'react';
import styled from 'styled-components/macro';
import { Prompt, SubHeader } from 'styles';
import { breakPoints } from 'styles/variables';
import NoblrPhoneLink from 'components/NoblrPhoneLink';

const ContentContainer = styled.div`
  max-width: 480px;
  margin: 0 auto;
  @media screen and (max-width: ${breakPoints.medium}) {
    max-width: 90%;
  }
  img,
  svg {
    margin: 30px auto;
    max-width: 100%;
    @media screen and (min-width: ${breakPoints.medium}) {
      max-width: 330px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 50px auto 0;
  width: 100%;

  @media screen and (min-width: ${breakPoints.medium}) {
    margin: 100px auto 0;
  }
  img {
    max-width: 50%;
  }
  ${SubHeader} {
    margin-bottom: 40px;
    a {
      text-decoration: underline;
    }
  }
`;

function NeedHelpPage() {
  return (
    <Wrapper>
      <Prompt>Hi There Need Help?</Prompt>
      <ContentContainer>
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/images/oops-error.webp`}
          alt=""
        />
        <SubHeader>
          {`We see that you have a Noblr car insurance policy already. Please log into your app for information about your account. If you need assistance or would like to get pricing information, please give us a call at `}
          <NoblrPhoneLink />
        </SubHeader>
      </ContentContainer>
    </Wrapper>
  );
}

export default NeedHelpPage;
