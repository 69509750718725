import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import createCompressor from 'redux-persist-transform-compress';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { RESET_QUOTE } from 'redux/actions';
import appReducer from './app';
import authReducer from './auth';
import coverageReducer from './coverage';
import driversReducer from './drivers';
import formReducer from './form';
import policyReducer from './policy';
import rateReducer from './rate';
import reCaptchaReducer from './reCaptcha';
import vehiclesReducer from './vehicles';
import excludedDriversReducer from './excludedDrivers';

const debug = process.env.REACT_APP_ENABLE_REDUX_DEBUGGING;

const compressor = createCompressor();

const CURRENT_PERSIST_VERSION = 389;

const appMigration = {
  364: state => ({
    ...state,
    loading: false,
    requiresUSAAEligibility: false,
    eligibilityQuestions: null,
    isUSAAMember: false,
    prefillComplete: false
  }),
  365: state => ({
    ...state,
    eligibilityQuestionsReceived: false
  }),
  366: state => ({
    ...state,
    requiresDLVerification: true,
    verificationError: null,
    dlVerificationStatus: 'unverified'
  }),
  367: state => ({
    ...state,
    policyActive: false
  }),
  368: state => ({
    ...state,
    form: {
      error: null
    }
  }),
  369: state => ({
    ...state,
    defensiveCourseTaken: false,
    askPriorInsuranceLapseReason: false,
    numberOfDaysWithoutInsurance: null,
    priorInsuranceLapseReason: null
  }),
  370: state => ({
    ...state,
    priorInsuranceLapseOptions: []
  }),
  371: state => ({
    ...state
  }),
  372: state => ({
    ...state,
    veriskHit: false
  }),
  373: state => ({
    ...state,
    requiresAirbagVerification: false
  }),
  374: state => ({
    ...state,
    requiresUmuimOfferForm: false,
    requiresMedPayOfferForm: false
  }),
  375: state => ({
    ...state
  }),
  376: state => ({
    ...state,
    usaaPersonInfoRetrieved: null,
    usaaPersonInfoVerified: null
  }),
  377: state => ({
    ...state
  }),
  378: state => ({
    ...state,
    requiresDynamicForms: false,
    requiresDriverExclusion: false
  }),
  379: state => ({
    ...state
  }),
  380: state => ({
    ...state,
    requiresDynamicForms: false,
    requiresDriverExclusion: false,
    customPreBindForms: { allIds: [], byId: {} },
    pendingFormIds: null,
    currentFormId: null,
    nextFormId: null,
    requestingPreBindForm: false
  }),
  381: state => ({
    ...state
  }),
  382: state => ({
    ...state,
    doNotQuote: null
  }),
  383: state => ({
    ...state
  }),
  384: state => ({
    ...state
  }),
  385: state => ({
    ...state
  }),
  386: state => ({
    isModalOpen: false,
    openModalName: null,
    ...state
  }),
  387: state => ({
    ...state,
    allVehiclesOwnedByAmp: null
  }),
  388: state => ({
    ...state,
    askDefensiveDrivingInDriverExclusion: false
  }),
  389: state => ({
    ...state
  })
};

const driversMigration = {
  364: state => ({
    ...state,
    primaryDriver: {
      ...state.primaryDriver,
      softQuote: false,
      askSeniorDriverImprovement: false,
      requiresUSAAEligibility: false
    }
  }),
  365: state => ({
    ...state
  }),
  366: state => ({
    ...state
  }),
  367: state => ({
    ...state
  }),
  368: state => ({
    ...state,
    primaryDriver: {
      ...state.primaryDriver,
      activeBit: true,
      source: null,
      createdBy: null,
      softQuote: false,
      primaryDriverComplete: false,
      givenName: null,
      middleName: null,
      surName: null,
      age: null,
      dob: null,
      dateOfBirth: null,
      dlNumber: null,
      dlState: null,
      street: null,
      street2: null,
      streetAddress: null,
      city: null,
      state: null,
      genderMapping: null,
      genderString: null,
      maritalStatus: '',
      maritalStatusString: null,
      education: '',
      educationLevelString: null,
      safeDriver: null,
      requiresUSAAEligibility: true
    }
  }),
  369: state => ({
    ...state
  }),
  370: state => ({
    ...state
  }),
  371: state => ({
    ...state
  }),
  372: state => ({
    ...state
  }),
  373: state => ({
    ...state
  }),
  374: state => ({
    ...state,
    primaryDriver: {
      ...state.primaryDriver,
      requiresActiveMilitaryPersonnel: false,
      requiresAirbagVerification: false,
      requiresAllVehiclesOwnedByAMP: false,
      requiresAntitheftDiscount: false,
      requiresCommuteSurcharge: false,
      requiresMedPayOfferForm: false,
      requiresRefusalToWriteNoticeForm: false,
      requiresUmuimOfferForm: false,
      requiresV2CoverageEndpoints: true,
      requiresVehicleAdvancedTechnologyDiscount: false,
      requiresVehicleAssignment: false,
      requiresVehicleOwnershipMonths: false,
      requiresVinEtchingDiscount: false
    }
  }),
  375: state => ({
    ...state
  }),
  376: state => ({
    ...state
  }),
  377: state => ({
    ...state
  }),
  378: state => ({
    ...state
  }),
  379: state => ({
    ...state
  }),
  380: state => ({
    ...state,
    primaryDriver: {
      ...state.primaryDriver,
      type: 'primary',
      driverComplete: false,
      requiresDynamicForms: false,
      requiresDriverExclusion: false
    }
  }),
  381: state => ({
    ...state
  }),
  382: state => ({
    ...state
  }),
  383: state => ({
    ...state
  }),
  384: state => ({
    ...state,
    editingDriver: {
      ...state.editingDriver,
      obfuscatedLastName: ''
    },
    items: state.items.map(driver => {
      return {
        ...driver,
        obfuscatedLastName: driver.lastName || ''
      };
    })
  }),
  385: state => ({
    ...state
  }),
  386: state => ({
    ...state
  }),
  387: state => ({
    ...state
  }),
  388: state => ({
    ...state
  }),
  389: state => ({
    ...state
  })
};

const coverageMigration = {
  364: state => ({
    ...state,
    currentPackage: null,
    coveragePackages: { allIds: [], byId: {} }
  }),
  365: state => ({
    ...state
  }),
  366: state => ({
    ...state
  }),
  367: state => ({
    ...state
  }),
  368: state => ({
    ...state
  }),
  369: state => ({
    ...state
  }),
  370: state => ({
    ...state
  }),
  371: state => ({
    ...state
  }),
  372: state => ({
    ...state
  }),
  373: state => ({
    ...state
  }),
  374: state => ({
    ...state
  }),
  375: state => ({
    ...state
  }),
  376: state => ({
    ...state
  }),
  377: state => ({
    ...state
  }),
  378: state => ({
    ...state
  }),
  379: state => ({
    ...state
  }),
  380: state => ({
    ...state
  }),
  381: state => ({
    ...state
  }),
  382: state => ({
    ...state
  }),
  383: state => ({
    ...state
  }),
  384: state => ({
    ...state
  }),
  385: state => ({
    ...state
  }),
  386: state => ({
    ...state
  }),
  387: state => ({
    ...state
  }),
  388: state => ({
    ...state
  }),
  389: state => ({
    ...state
  })
};

const rateMigration = {
  364: state => ({ ...state }),
  365: state => ({
    ...state
  }),
  366: state => ({
    ...state
  }),
  367: state => ({
    ...state
  }),
  368: state => ({
    ...state
  }),
  369: state => ({
    ...state
  }),
  370: state => ({
    ...state
  }),
  371: state => ({
    ...state
  }),
  372: state => ({
    ...state
  }),
  373: state => ({
    ...state
  }),
  374: state => ({
    ...state
  }),
  375: state => ({
    ...state
  }),
  376: state => ({
    ...state
  }),
  377: state => ({
    ...state,
    mvrOrdered: false,
    mvrOrderData: null
  }),
  378: state => ({
    ...state
  }),
  379: state => ({
    ...state
  }),
  380: state => ({
    ...state,
    savedCoverages: {
      policyCoverages: {},
      vehicleCoverages: {}
    }
  }),
  381: state => ({
    ...state
  }),
  382: state => ({
    ...state
  }),
  383: state => ({
    ...state
  }),
  384: state => ({
    ...state
  }),
  385: state => ({
    ...state
  }),
  386: state => ({
    ...state
  }),
  387: state => ({
    ...state
  }),
  388: state => ({
    ...state
  }),
  389: state => ({
    ...state
  })
};

const policyMigration = {
  364: state => ({
    ...state,
    firstPossibleEffectiveDate: ''
  }),
  365: state => ({
    ...state
  }),
  366: state => ({
    ...state
  }),
  367: state => ({
    ...state
  }),
  368: state => ({
    ...state
  }),
  369: state => ({
    ...state
  }),
  370: state => ({
    ...state
  }),
  371: state => ({
    ...state,
    requiresUmuimOfferForm: false
  }),
  372: state => ({
    ...state
  }),
  373: state => ({
    ...state
  }),
  374: state => ({
    ...state
  }),
  375: state => ({
    ...state,
    ratePlanStateCode: null
  }),
  376: state => ({
    ...state
  }),
  377: state => ({
    ...state
  }),
  378: state => ({
    ...state,
    lastPossibleEffectiveDate: ''
  }),
  379: state => ({
    ...state
  }),
  380: state => ({
    ...state,
    requiresDriverExclusion: false,
    requiresDynamicForms: false,
    customPreBindForms: { allIds: [], byId: {} },
    pendingFormIds: null,
    currentFormId: null,
    nextFormId: null,
    requestingPreBindForm: false
  }),
  381: state => ({
    ...state
  }),
  382: state => ({
    ...state
  }),
  383: state => ({
    ...state
  }),
  384: state => ({
    ...state
  }),
  385: state => ({
    ...state
  }),
  386: state => ({
    ...state
  }),
  387: state => ({
    ...state
  }),
  388: state => ({
    ...state
  }),
  389: state => ({
    ...state
  })
};

const vehiclesMigration = {
  364: state => ({
    ...state
  }),
  365: state => ({
    ...state
  }),
  366: state => ({
    ...state
  }),
  367: state => ({
    ...state
  }),
  368: state => ({
    ...state
  }),
  369: state => ({
    ...state
  }),
  370: state => ({
    ...state
  }),
  371: state => ({
    ...state
  }),
  372: state => ({
    ...state
  }),
  373: state => ({
    ...state
  }),
  374: state => ({
    ...state
  }),
  375: state => ({
    ...state
  }),
  376: state => ({
    ...state
  }),
  377: state => ({
    ...state
  }),
  378: state => ({
    ...state
  }),
  379: state => ({
    ...state
  }),
  380: state => ({
    ...state
  }),
  381: state => ({
    ...state
  }),
  382: state => ({
    ...state
  }),
  383: state => ({
    ...state
  }),
  384: state => ({
    ...state
  }),
  385: state => ({
    ...state
  }),
  386: state => ({
    ...state
  }),
  387: state => ({
    ...state
  }),
  388: state => ({
    ...state
  }),
  389: state => ({
    ...state
  })
};

const excludedDriversMigration = {
  377: state => ({
    ...state,
    driver: {},
    byId: {}
  }),
  378: state => ({
    ...state
  }),
  379: state => ({
    ...state
  }),
  380: state => ({
    ...state,
    completedDrivers: [],
    requiresUpdatedRates: false
  }),
  381: state => ({
    ...state,
    requiresFormMetadata: false
  }),
  382: state => ({
    ...state
  }),
  383: state => ({
    ...state,
    driverNamesObfuscated: true
  }),
  384: state => ({
    ...state,
    driver: {
      ...state.driver,
      obfuscatedLastName: state.driver.lastName || ''
    }
  }),
  385: state => ({
    ...state
  }),
  386: state => ({
    ...state
  }),
  387: state => ({
    ...state,
    driver: {
      ...state.driver,
      activeMilitaryPersonnel: null
    }
  }),
  388: state => ({
    ...state
  }),
  389: state => ({
    ...state,
    driver: {
      ...state.driver,
      requiresActiveMilitaryPersonnel: false,
      requiresSeniorSafeDriverDiscount: false
    }
  })
};

const driversPersistConfig = {
  debug,
  key: 'drivers',
  migrate: createMigrate(driversMigration, {
    debug
  }),
  stateReconciler: autoMergeLevel2,
  storage,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION,
  whitelist: ['primaryDriver', 'items', 'editingDriver', 'lastEditedDriver']
};

const vehiclesPersistConfig = {
  debug,
  key: 'vehicles',
  migrate: createMigrate(vehiclesMigration, { debug }),
  stateReconciler: autoMergeLevel2,
  storage,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION,
  whitelist: ['items', 'editedVehicles', 'lastEditedVehicle']
};

const appPersistConfig = {
  blacklist: [
    'error',
    'modal',
    'loading',
    'isQuoteRetrieval',
    'form',
    'section',
    'endpointUrl',
    'crmGateError',
    'isEmailModalOpen',
    'redirect',
    'asyncRequest',
    'prevSection',
    // 'isPreBind', // sales tool handoff
    // 'preBindComplete', // sales tool handoff
    'isEmailModalOpen',
    'currentStep',
    'sendingCRMGate',
    'errorMessage',
    'requiresDLVerification',
    'requiresDynamicForms',
    'requiresDriverExclusion',
    'askVinEtching',
    'manualAddress',
    'isQuoteRetrieval',
    'requiresDynamicForms',
    'dlVerificationStatus',
    'requiresDLVerification',
    'usaaPersonInfoRetrieved',
    'usaaPersonInfoVerified',
    'isModalOpen',
    'openModalName'
  ],
  debug,
  key: 'app',
  migrate: createMigrate(appMigration, { debug }),
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION
};

const coveragePersistConfig = {
  debug,
  key: 'coverage',
  migrate: createMigrate(coverageMigration, { debug }),
  version: CURRENT_PERSIST_VERSION,
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [compressor],
  whiteList: [
    'restrictUM',
    'coverageRuleOptions',
    'rulesByCoverage',
    'config',
    'savedMileage',
    'personaSaved',
    'ratePlanStateCode'
  ]
};

const ratePersistConfig = {
  debug,
  key: 'rate',
  blackList: [
    'ratePlanStateCode',
    'loading',
    'error',
    'pickerEffectiveStartDate',
    'firstPossibleEffectiveDate',
    'lastPossibleEffectiveDate',
    'mvrOrdered',
    'mvrOrderData'
  ],
  migrate: createMigrate(rateMigration, {
    debug
  }),
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION
};

const policyPersistConfig = {
  blacklist: [
    'paymentPlanSaved',
    'prebindApplication',
    'membershipApplication',
    'umuimOfferForm',
    'UMBI_SELECTION',
    'UIMBI_SELECTION',
    'TORT_OPTIONS',
    'pickerEffectiveStartDate',
    'firstPossibleEffectiveDate',
    'lastPossibleEffectiveDate',
    'isSaving',
    'policyEffectiveDate',
    'requiresDynamicForms',
    'requestingPreBindForm',
    'requiresDriverExclusion',
    'requiresUmuimOfferForm'
  ],
  debug,
  key: 'policy',
  migrate: createMigrate(policyMigration, { debug }),
  stateReconciler: autoMergeLevel2,
  storage,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION
};

const excludedDriversPersistConfig = {
  debug,
  key: 'excludedDrivers',
  migrate: createMigrate(excludedDriversMigration, {
    debug
  }),
  stateReconciler: autoMergeLevel2,
  storage,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION,
  blacklist: [
    'allDriversExcluded',
    'excludedDriverError',
    'excludedDriverErrorMessage',
    'requiresUpdatedRates',
    'requiresFormMetadata',
    'loading',
    'driverNamesObfuscated'
  ]
};

const formPersistConfig = {
  debug,
  key: 'form',
  stateReconciler: autoMergeLevel2,
  storage,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION
};

const combinedReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  drivers: persistReducer(driversPersistConfig, driversReducer),
  vehicles: persistReducer(vehiclesPersistConfig, vehiclesReducer),
  coverage: persistReducer(coveragePersistConfig, coverageReducer),
  rate: persistReducer(ratePersistConfig, rateReducer),
  policy: persistReducer(policyPersistConfig, policyReducer),
  form: persistReducer(formPersistConfig, formReducer),
  excludedDrivers: persistReducer(
    excludedDriversPersistConfig,
    excludedDriversReducer
  ),
  reCaptcha: reCaptchaReducer,
  auth: authReducer
});

const primaryReducer = (state, action) => {
  let newState = { ...state };

  if (action.type === RESET_QUOTE) {
    storage.removeItem('persist:app');
    storage.removeItem('persist:drivers');
    storage.removeItem('persist:vehicles');
    storage.removeItem('persist:coverage');
    storage.removeItem('persist:rate');
    storage.removeItem('persist:policy');
    storage.removeItem('persist:form');
    storage.removeItem('persist:excludedDrivers');
    newState = undefined;
  }

  return combinedReducer(newState, action);
};

const rootPersistConfig = {
  blacklist: ['auth', 'reCaptcha'],
  key: 'root',
  stateReconciler: autoMergeLevel2,
  storage,
  transforms: [compressor],
  version: CURRENT_PERSIST_VERSION
};

const rootReducer = persistReducer(rootPersistConfig, primaryReducer);

export default rootReducer;
