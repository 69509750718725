import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Document, Page, pdfjs } from 'react-pdf';
import Form from 'components/Form/Form';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import {
  getDynamicPreBindFormById,
  requestPreBindPDF,
  updatePrebindRateFields,
  openModal
} from 'redux/actions';
import { makeSelectConfirmedExcludedDrivers } from 'redux/selectors';
import {
  ModalHeader,
  brandonGrotesque,
  breakPoints,
  CenteredLoaderContainer,
  colors,
  openSans
} from 'styles';
import Button from '../Buttons/Button';
import { DEFAULT_MAX_WIDTH } from '../../constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PageContainer = styled.div`
  margin: 20px auto;
  padding: 0;
  width: auto;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: ${colors.white};

  @media only screen and (min-width: ${breakPoints.large}) {
    margin: 20px auto 0;
  }

  @media only screen and (max-width: ${breakPoints.medium}) {
    height: 100%;
    margin-top: 60px;
    padding: 0 5px;
  }
`;

const DocumentWrapper = styled.div`
  width: inherit;
  padding: 0;
  @media only screen and (max-width: ${breakPoints.medium}) {
    padding: 10px 0;
  }

  /* Portrait */
  @media only screen and (min-device-width: ${breakPoints.large}) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    max-width: ${DEFAULT_MAX_WIDTH};
    width: auto;
  }
`;

const ExcludedDriverList = styled.ul`
  align-self: flex-start;
  text-align: left;
  list-style-type: inherit;
  li {
    margin: 10px 0 5px 30px;
    font-size: 18px;

    &:last-child {
      margin-bottom: 20px;
    }
  }
`;

const StyledDocumentName = styled.h2`
  font-family: ${openSans};
  font-size: 20px;
  color: ${colors.coreBlue700};

  @media only screen and (min-width: ${breakPoints.large}) {
    font-size: 24px;
  }
`;

const SignPrompt = styled.h2`
  margin: 20px 0 10px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '350px')};
  font-size: 18px;
  font-family: ${openSans};
  color: ${colors.coreBlue700};
  align-self: flex-start;
  text-align: left;
  white-space: pre-line;

  @media only screen and (min-width: ${breakPoints.large}) {
    margin: 10px 0;
    padding: 20px;
    font-size: 20px;
  }
`;

const SignHeader = styled(ModalHeader)`
  margin: 20px 0 10px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : '350px')};
  font-family: ${brandonGrotesque};
  font-size: 24px;
  font-weight: 300;
  align-self: flex-start;
  text-align: left;
  color: ${colors.coreBlue700};
  text-transform: uppercase;
`;

const SignButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  min-width: 100%;
  z-index: 100;
  bottom: 0;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  background-color: ${colors.white};

  .modal-toggle-link {
    width: 100%;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 100;
    padding: 15px 0;
  }

  @media only screen and (min-width: ${breakPoints.small}) {
    height: 100px;
    .modal-toggle-link {
      width: auto;
      padding: 0;
    }
  }
`;

class SignPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      numPages: null,
      displayButton: false,
      modalMaxWidth: '720px',
      fileURL: null
    };

    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.changePage = this.changePage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  componentDidMount() {
    const {
      pdfFileName,
      pdfFileId,
      requestPreBindPDF,
      requestingPreBindForm,
      getDynamicPreBindFormById,
      submittingSignature,
      updateRateFields,
      requiresDynamicForms
    } = this.props;

    if (updateRateFields && !requestingPreBindForm && !submittingSignature) {
      updatePrebindRateFields();
    }

    if (requiresDynamicForms) {
      if (pdfFileId) {
        getDynamicPreBindFormById(pdfFileId, pdfFileName);
      }
    } else {
      requestPreBindPDF(pdfFileName);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props || prevState !== this.state) {
      const { policy, pdfFileName } = this.props;

      if (
        !prevState.fileURL &&
        policy[pdfFileName] &&
        policy[pdfFileName].pdfURL
      ) {
        // TODO: Refactor so componentDidUpdate does not setState
        this.setState({
          fileURL: policy[pdfFileName].pdfURL
        });
      }
    }
  }

  handleOpenModal() {
    const { documentName, openModal } = this.props;

    return openModal(documentName);
  }

  onDocumentLoadSuccess({ numPages }) {
    const { closeAnyModal } = this.props;

    this.setState({
      numPages,
      displayButton: true
    });

    closeAnyModal();
  }

  changePage(offset) {
    this.setState(prevState => ({
      pageNumber: prevState.pageNumber + offset
    }));
  }

  previousPage() {
    return this.changePage(-1);
  }

  nextPage() {
    return this.changePage(1);
  }

  render() {
    const { numPages, displayButton, modalMaxWidth, fileURL } = this.state;

    const {
      requestingPreBindForm,
      successRoute,
      data,
      formStaticContent,
      fields,
      submitAction,
      documentName,
      excludedDrivers,
      isModalOpen
    } = this.props;

    const excludedDriverDoc = documentName === 'Driver Exclusion';

    return (
      <PageContainer>
        <StyledDocumentName>{documentName}</StyledDocumentName>
        {requestingPreBindForm && (
          <CenteredLoaderContainer>
            <Loader absolute />
          </CenteredLoaderContainer>
        )}
        {fileURL && (
          <DocumentWrapper>
            <Document
              file={fileURL}
              loading={
                <CenteredLoaderContainer>
                  <Loader absolute />
                </CenteredLoaderContainer>
              }
              error="Error getting application. Please try again."
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  wrap={false}
                  renderAnnotationLayer
                />
              ))}
            </Document>
          </DocumentWrapper>
        )}
        {!requestingPreBindForm && displayButton && (
          <SignButtonWrapper>
            <Button
              sign
              primary
              type="button"
              id="sign-document"
              disabled={isModalOpen}
              onClick={this.handleOpenModal}
            >
              Sign Document
            </Button>
          </SignButtonWrapper>
        )}
        <Modal
          showCloseButton={false}
          inline
          modalLabel="SignDocumentModal"
          linkStyle="sticky"
          modalName={documentName}
        >
          <SignHeader>{documentName}</SignHeader>
          {excludedDriverDoc ? (
            <SignPrompt>
              Type your first and last name to authorize and sign a separate
              Driver Exclusion form for each person listed below, which will
              exclude them from your policy. There will not be coverage under
              this policy when they drive any car.
              <ExcludedDriverList>
                {excludedDrivers.map(({ firstName, lastName, dlNumber }) => {
                  return (
                    <li key={dlNumber}>
                      {firstName} {lastName}
                    </li>
                  );
                })}
              </ExcludedDriverList>
            </SignPrompt>
          ) : (
            <SignPrompt maxWidth={modalMaxWidth}>
              Type your first and last name to authorize and sign.
            </SignPrompt>
          )}
          <Form
            submitAsync
            desktopMaxWidth={modalMaxWidth}
            mobileMaxWidth="100%"
            promptTextAlign="left"
            fields={fields || data.form.fields}
            submitAction={submitAction || data.submitAction}
            successRoute={successRoute}
            staticContent={formStaticContent}
            buttonText="SIGN DOCUMENT"
            buttonMarginTop="40px"
            staticTextAlign="left"
            stickyButton={false}
          />
        </Modal>
      </PageContainer>
    );
  }
}

const mapStateToProps = () => {
  const selectExcludedDrivers = makeSelectConfirmedExcludedDrivers();

  const realMapState = state => {
    const {
      policy,
      policy: { requestingPreBindForm, submittingSignature, currentFormId },
      app: { requiresDynamicForms, isModalOpen }
    } = state;

    return {
      policy,
      requestingPreBindForm,
      submittingSignature,
      currentFormId,
      requiresDynamicForms,
      excludedDrivers: selectExcludedDrivers(state),
      isModalOpen
    };
  };

  return realMapState;
};

const mapDispatchToProps = dispatch => ({
  updatePrebindRateFields: () => dispatch(updatePrebindRateFields()),
  requestPreBindPDF: pdfName => dispatch(requestPreBindPDF(pdfName)),
  getDynamicPreBindFormById: (pdfId, pdfName) =>
    dispatch(getDynamicPreBindFormById(pdfId, pdfName)),
  openModal: modalName => dispatch(openModal(modalName)),
  closeAnyModal: () => dispatch({ type: 'CLOSE_MODAL' })
});

export default connect(mapStateToProps, mapDispatchToProps)(SignPage);
