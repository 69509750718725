import React from 'react';
import styled from 'styled-components/macro';
import { colors, openSans, breakPoints } from 'styles/variables';

const StyledBox = styled.aside`
  margin: 40px auto;
  padding: 25px;
  max-width: 255px;
  border-radius: 2px;
  transform: rotate(-2deg);
  background: ${colors.usaaLightestGrey};
  text-align: left;
  cursor: default;

  @media only screen and (min-width: ${breakPoints.widescreen}) and (max-height: 768px) {
    margin: 0 auto;
    position: absolute;
    top: min(350px, calc(100% - 100px));
    right: min(75px, 3%);
    transform: translate(-2%, -50%) rotate(-2deg);
  }

  @media only screen and (min-width: ${breakPoints.widescreen}) and (min-height: 769px) {
    margin: auto 0;
    position: absolute;
    top: min(350px, calc(100% - 250px));
    right: min(50px, 2%);
    transform: translate(-2%, -50%) rotate(-2deg);
  }

  .info-box-content {
    height: 100%;
    transform: rotate(2deg);
    font-family: ${openSans};
    color: ${colors.coreBlue700};

    span,
    span > a,
    p {
      font-family: ${openSans};
      font-size: 14px;
      font-weight: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: ${colors.coreBlue700};
    }
  }
`;

const InfoBoxTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 15px;
  font-family: ${openSans};
  font-size: 14px;
  letter-spacing: 1.2px;
  font-weight: 600;
  color: ${colors.coreBlue700};
`;

const StyledText = styled.p`
  margin-bottom: ${props => (props.withMarginBottom ? '20px' : '0')};
`;

const Wrapper = styled.div`
  span,
  span > a,
  p {
    margin-bottom: ${props => (props.withMarginBottom ? '20px' : '0')};
  }
  /* for driver improvement discount */
  span > a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

function InfoBox({ extraData, children, withMarginBottom = true }) {
  const header = extraData.header || 'Noblr Tip';
  const text = extraData.text ? extraData.text : children || '';

  return (
    <StyledBox role="note">
      <div className="info-box-content">
        <InfoBoxTitle>{header}</InfoBoxTitle>
        {children ? (
          <Wrapper>{children}</Wrapper>
        ) : (
          <StyledText withMarginBottom={withMarginBottom}>{text}</StyledText>
        )}
      </div>
    </StyledBox>
  );
}

export default InfoBox;
