import Noblr from '@noblr-lab/react-app-services';
import { put, putResolve, call, select } from 'redux-saga/effects';

export function* excludeDriverWatcher({ payload }) {
  yield put({
    type: 'EXCLUDE_DRIVER_SUCCESS',
    payload
  });
}

export function* updateExcludedDriverInfoSubroutine({ payload, successRoute }) {
  const {
    activeBit = null,
    maritalStatus = null,
    maritalStatusString = null
  } = payload;

  yield put({
    type: 'TOGGLE_LOADER',
    payload: {
      toShow: true
    }
  });

  const {
    app: {
      askMilitaryServiceVehicles,
      defensiveCourseTaken,
      askDefensiveDrivingInDriverExclusion
    },
    drivers: { items },
    excludedDrivers: {
      requiresUpdatedRates,
      driver,
      driver: { driverId }
    }
  } = yield select();

  const [quoteFlowDriver] = items.filter(
    driverItem => driverItem.driverId === driverId
  );

  const isRemoved = activeBit === false;

  const updatedMaritalStatus =
    maritalStatus ||
    maritalStatusString ||
    driver?.maritalStatus ||
    quoteFlowDriver?.maritalStatus ||
    quoteFlowDriver?.maritalStatusString;

  const combinedDriverInfo = {
    ...driver,
    ...payload,
    maritalStatus: updatedMaritalStatus
  };

  if (isRemoved) {
    yield put({ type: 'SET_LOADING_STATUS', payload: true });
    try {
      yield call(Noblr.driver.updateDriverInfo, combinedDriverInfo);
      yield call(Noblr.driver.deleteDriverAfterPrefill, {
        driverId
      });

      yield putResolve({
        type: 'UPDATE_EXCLUDED_DRIVER_INFO_SUCCESS',
        payload: { ...payload, activeBit: false, type: 'additional' }
      });
      // Driver has been removed so activeBit is false
      yield putResolve({
        type: 'EXCLUDE_DRIVER',
        payload: { driverId, activeBit: false }
      });
      yield put({
        type: 'REDIRECT',
        payload: { url: successRoute }
      });
    } catch (error) {
      yield put({
        type: 'UPDATE_EXCLUDED_DRIVER_INFO_FAILURE',
        error
      });
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    } finally {
      yield put({ type: 'SET_LOADING_STATUS', payload: false });
      yield put({
        type: 'TOGGLE_LOADER',
        payload: {
          toShow: false
        }
      });
    }
  } else {
    const confirmed = successRoute && successRoute.includes('confirmation');

    combinedDriverInfo.activeBit = true;

    /*
      ! NOTE: "changeRequireRates" and "changeRequireForms" can both be false but both can never be true. "changeRequiresRates" supersedes "changeRequireForms"

      * DRIVER EXCLUSION CHANGE REQUIRES RATES LOGIC
        If driver was "REMOVED" in quote flow
        and "ADDED" in driver exclusion flow
        * Quote Flow                         Purchase Flow
        * activeBit === false       activeBit === true && type === 'additional'
 */
    let changeRequiresRates = false;
    /*
      * DRIVER EXCLUSION CHANGE REQUIRES FORMS META DATA
        Variable determines if  updated dynamic forms are requested
        If driver was "REMOVED" in quote flow
        and "EXCLUDED" in driver exclusion flow
        * Quote Flow                         Purchase Flow
        * activeBit === false         activeBit === true && type === 'excluded'
 */
    let changeRequiresForms = false;

    if (confirmed && !payload.dlNumber) {
      if (payload.dob) {
        combinedDriverInfo.type = 'excluded';
      }
    } else {
      combinedDriverInfo.type = 'additional';
    }

    try {
      const {
        activeBit: responseActiveBit,
        driverId,
        age,
        yearsLicensed,
        type,
        dlNumber = null,
        dlState = null,
        education = null,
        genderMapping,
        maritalStatus = null,
        ageLicensed,
        driverComplete,
        memberOfHousehold,
        drivesVehicles,
        activeMilitaryPersonnel = null,
        requiresActiveMilitaryPersonnel,
        requiresSeniorSafeDriverDiscount,
        safeDriver = null
      } = yield call(Noblr.driver.updateDriverInfo, combinedDriverInfo);

      if (
        requiresActiveMilitaryPersonnel &&
        activeMilitaryPersonnel &&
        !askMilitaryServiceVehicles &&
        type === 'additional'
      ) {
        yield put({
          type: 'ASK_MILITARY_SERVICE_VEHICLES'
        });
      }

      if (
        !quoteFlowDriver.activeBit &&
        (responseActiveBit || driver.activeBit) &&
        type === 'additional'
      ) {
        changeRequiresRates = true;

        if (
          defensiveCourseTaken === 'Yes' &&
          !askDefensiveDrivingInDriverExclusion
        ) {
          yield put({ type: 'ASK_DEFENSIVE_DRIVING_IN_DRIVER_EXCLUSION' });
        }
      }

      if (
        responseActiveBit &&
        type === 'excluded' &&
        !quoteFlowDriver.activeBit &&
        quoteFlowDriver.type === 'additional' &&
        !changeRequiresRates
      ) {
        changeRequiresForms = true;
      }

      /*
       * NOTE: "firstName" is already stored in Redux and
       * "lastName" is obfuscated so do not pass either in "driverToSave"
       * otherwise "lastName" will be overwritten and appear obfuscated in UI
       */
      const driverToSave = {
        ...combinedDriverInfo,
        activeBit: responseActiveBit,
        age,
        ageLicensed,
        driverId,
        type,
        dlNumber,
        dlState,
        education,
        genderMapping,
        maritalStatus,
        yearsLicensed,
        driverComplete,
        memberOfHousehold,
        drivesVehicles,
        activeMilitaryPersonnel,
        requiresActiveMilitaryPersonnel,
        requiresSeniorSafeDriverDiscount
      };

      yield put({
        type: 'UPDATE_EXCLUDED_DRIVER_INFO_SUCCESS',
        payload: driverToSave
      });

      /*
        If driver was "removed" or "excluded" in quote flow and
        driver was added back during driver exclusion flow,
        rates must be requested
      */
      if (changeRequiresRates) {
        yield put({
          type: 'CHANGE_REQUIRES_RATES'
        });
      } else if (changeRequiresForms && !requiresUpdatedRates) {
        yield put({
          type: 'CHANGE_REQUIRES_FORMS_METADATA'
        });
      }

      let url = successRoute;

      if (
        type === 'additional' &&
        !driverComplete &&
        requiresSeniorSafeDriverDiscount &&
        safeDriver === null
      ) {
        url = `/driver-exclusion/${driverId}/confirm-defensive-driving-course`;
      } else if (confirmed && driverComplete) {
        yield put({
          type: 'EXCLUDE_DRIVER',
          payload: driverToSave
        });
      }

      yield put({
        type: 'REDIRECT',
        payload: { url }
      });
    } catch (error) {
      yield put({
        type: 'UPDATE_EXCLUDED_DRIVER_INFO_FAILURE',
        error
      });
      yield put({
        type: 'SET_ERROR',
        error: { status: error.status, ...error.data }
      });
    } finally {
      yield put({
        type: 'TOGGLE_LOADER',
        payload: {
          toShow: false
        }
      });
    }
  }
}

export function* requestRemovedAndExcludedDriverNamesSubroutine() {
  yield put({
    type: 'TOGGLE_LOADER',
    payload: {
      toShow: true
    }
  });

  const {
    excludedDrivers: { driver, byId }
  } = yield select();

  try {
    const drivers = yield call(Noblr.driver.getDrivers);

    let currentExcludedDriver = { ...driver };

    const driversById = drivers.reduce(
      (acc, { driverId, firstName, lastName }) => {
        if (acc[driverId]) {
          acc[driverId] = { ...acc[driverId], firstName, lastName };

          if (currentExcludedDriver.driverId === driverId) {
            currentExcludedDriver = {
              ...currentExcludedDriver,
              firstName,
              lastName
            };
          }
        }

        return acc;
      },
      { ...byId }
    );

    yield put({
      type: 'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_SUCCESS',
      payload: { byId: driversById, driver: currentExcludedDriver }
    });
  } catch (error) {
    yield put({
      type: 'GET_REMOVED_AND_EXCLUDED_DRIVER_NAMES_FAILURE'
    });
    yield put({
      type: 'SET_ERROR',
      error: { status: error.status, message: error.data.message }
    });
  } finally {
    yield put({
      type: 'TOGGLE_LOADER',
      payload: {
        toShow: false
      }
    });
  }
}
