import React from 'react';
import { Grid, Container } from '@material-ui/core';
import LinkButton from 'components/Buttons/LinkButton';
import Sticky from 'components/Modifiers/Sticky';
import { Prompt, SubHeader } from 'styles';

export default function UmbrellaPolicyAcknowledgement() {
  const acknowledgementPromptHeader = 'Please Note:';
  const acknowledgementPrompt =
    'The pay as you drive auto policy may not be acceptable underlying auto policy for the USAA Umbrella Insurance policy. To learn more, call us at 800-531-USAA (8722).';

  const successRoute = `/purchase/payment-info`;

  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid item>
          <Prompt>{acknowledgementPromptHeader}</Prompt>
          <SubHeader>{acknowledgementPrompt}</SubHeader>
        </Grid>
        <Sticky marginBottom="5px" primaryLinkButton>
          <LinkButton
            id="acknowledgement-button"
            primary
            routeTo={successRoute}
          >
            I ACKNOWLEDGE
          </LinkButton>
        </Sticky>
      </Grid>
    </Container>
  );
}
