import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { replaceSpacesWithDashes } from 'utilities';
import Button from 'components/Buttons/Button';

const LoadingButton = styled.img`
  display: block;
  margin: 0 auto;
  height: 100%;
`;

class AsyncButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { formTouched: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { formTouched } = this.state;
      const { asyncRequest, history, successRoute, isSubmitting } = this.props;

      if (isSubmitting && !formTouched) {
        // TODO: Refactor so setting state does not occur in componentDidUpdate
        this.setState({ formTouched: true });
      }

      if (history && formTouched && asyncRequest === 'success') {
        if (!successRoute) {
          return;
        }
        history.push(successRoute);
      }
    }
  }

  render() {
    const {
      asyncRequest,
      disabled,
      invalid,
      buttonText,
      buttonWidth,
      noMargin,
      loading,
      isSubmitting
    } = this.props;

    const buttonId = buttonText
      ? `${replaceSpacesWithDashes(buttonText)}-button`
      : 'continue-button';

    const submittingRequest =
      loading || isSubmitting || asyncRequest === 'pending';

    return submittingRequest ? (
      <Button
        // uses default type ("submit") and className ("form__submit cta--1")
        primary
        large
        noMargin={noMargin}
        buttonWidth={buttonWidth}
        disabled={disabled || invalid || submittingRequest}
        ariaLabel="Loading"
        id="async-button"
      >
        <LoadingButton
          src={`${process.env.REACT_APP_NOBLR_CDN}/animations/spinner-white-grey.gif`}
          alt=""
          aria-hidden="true"
        />
      </Button>
    ) : (
      <Button
        // uses default type ("submit") and className ("form__submit cta--1")
        primary
        large
        noMargin={noMargin}
        disabled={disabled || invalid}
        buttonWidth={buttonWidth}
        id={buttonId}
      >
        {buttonText || 'Continue'}
      </Button>
    );
  }
}

const mapStateToProps = ({ app: { asyncRequest, loading } }) => ({
  asyncRequest,
  loading
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AsyncButton)
);
