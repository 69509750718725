import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { openSans, colors } from 'styles/variables';

const StyledRecoverPassLink = styled(Link)`
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
  font-weight: normal;
  font-family: ${openSans};
  text-align: left;
  text-decoration: none;
  color: ${colors.alphaBlue500};
`;

function RecoverPasswordLink({ trialActive, isQuoteRetrieval, email }) {
  return (
    <StyledRecoverPassLink to="/account/confirm-email">
      {trialActive || (isQuoteRetrieval && email)
        ? 'Reset password'
        : 'Create or reset password'}
    </StyledRecoverPassLink>
  );
}

const mapStateToProps = ({
  app: { trialActive, isQuoteRetrieval },
  drivers: {
    primaryDriver: { email }
  }
}) => ({
  trialActive,
  isQuoteRetrieval,
  email
});

export default connect(mapStateToProps)(RecoverPasswordLink);
