import React from 'react';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

export default function UmbrellaPolicy() {
  const fields = [
    {
      type: 'radio',
      name: 'umbrellaPolicy',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ],
      required: true,
      radioGroupAriaLabel: 'USAA Umbrella Policy'
    }
  ];

  const userPrompt = 'Do you have or want a USAA Umbrella Policy?';
  const submitAction = 'USAA_UMBRELLA_POLICY';

  return (
    <StyledContentWrapper>
      <Form prompt={userPrompt} fields={fields} submitAction={submitAction} />
    </StyledContentWrapper>
  );
}
