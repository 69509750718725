import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { colors } from 'styles';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.label
});

const CustomTextField = forwardRef((props, ref) => {
  return (
    <TextField
      fullWidth
      margin="normal"
      variant="outlined"
      ref={ref}
      size="medium"
      {...props}
    />
  );
});

function SearchableSelect({
  form,
  field,
  options,
  required,
  label,
  id,
  autoComplete
}) {
  return (
    <Autocomplete
      filterOptions={filterOptions}
      autoComplete
      openOnFocus={false}
      disableListWrap
      autoSelect
      blurOnSelect // controls if input is blurred when an option is selected
      autoHighlight // first option match is automatically highlighted
      selectOnFocus // text is selected on focus. Helps the user clear the selected value
      clearOnBlur // helps user enter new value
      clearOnEscape // esc clears input
      handleHomeEndKeys // move focus inside popup with home/end keys
      disablePortal // for iOS accessibility
      fullWidth // input takes up full width of container
      forcePopupIcon="auto"
      id={id}
      aria-controls={id}
      disabled={form.isSubmitting}
      required={required}
      inputValue={field.value}
      options={options} // Array of Objects. Each Object has a label and value
      name={field.name}
      size="medium"
      onInputChange={(event, value, reason) => {
        // onInputChange - event handler for text input below
        // if user clicks "X" inside select to clear input
        if (reason === 'clear') {
          // clear field value and validate field
          form.setFieldValue(field.name, '');
        } else if (reason === 'input') {
          form.setFieldValue(field.name, value.toUpperCase());
        }
      }}
      onChange={(event, value, reason) => {
        // change handler for Select
        // if user clicks "X" inside select to clear input
        if (reason === 'clear') {
          // clear field value and validate field
          form.setFieldValue(field.name, '');
        } else if (reason === 'select-option') {
          form.setFieldValue(field.name, value.value.toUpperCase());
        }
      }}
      onClose={() =>
        !form.touched[field.name] && form.setFieldTouched(field.name, true)
      }
      getOptionLabel={option => option.label}
      getOptionSelected={(option, value) => {
        return option.value === value.value; // must compare option.value to value.value
      }}
      popupIcon={
        <img
          src={`${process.env.REACT_APP_NOBLR_CDN}/icons/down-chevron-icon.svg`}
          alt=""
          style={{
            objectFit: 'contain',
            padding: '5px',
            fill: colors.coreBlue700
          }}
        />
      }
      renderInput={params => {
        return (
          <CustomTextField
            {...params}
            ref={params.InputProps.ref}
            label={label}
            size="medium"
            inputProps={{
              ...params.inputProps,
              autoComplete
            }}
          />
        );
      }}
    />
  );
}

export default SearchableSelect;
