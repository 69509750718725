import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';

export default function EmergencyBraking() {
  const askVinEtching = useSelector(state => state.app?.askVinEtching);

  const askCommuteSurcharge = useSelector(
    state => state.app?.askCommuteSurcharge
  );

  const make = useSelector(state => state.vehicles?.editingVehicle?.make);

  const model = useSelector(state => state.vehicles?.editingVehicle?.model);

  const vehicleId = useSelector(
    state => state.vehicles?.editingVehicle?.vehicleId
  );

  const fields = [
    {
      type: 'radio',
      name: 'advancedTechnologyDiscount',
      radioGroupAriaLabel: 'Advanced Technology Discount',
      options: [
        { value: 'AUTOMATIC_EMERGENCY_BRAKING', label: 'Yes' },
        { value: 'NONE', label: 'No' }
      ],
      required: true
    }
  ];

  const prompt = `Does your ${make} ${model} have automatic emergency braking?`;

  const submitAction = 'UPDATE_VEHICLE';

  const configureSuccessRoute = () => {
    if (askVinEtching) {
      return '/add-vehicles/vin-etching';
    }

    if (askCommuteSurcharge) {
      return '/add-vehicles/commute';
    }

    return '/add-vehicles/vehicles-list';
  };

  const [successRoute] = useState(configureSuccessRoute);

  return !vehicleId ? (
    <Redirect to="/add-vehicles/vehicles-list" replace />
  ) : (
    <StyledContentWrapper>
      <Form
        prompt={prompt}
        fields={fields}
        submitAction={submitAction}
        successRoute={successRoute}
        submitAsync
      />
      <InfoBox
        extraData={{
          header: 'What Is Automatic Emergency Braking?',
          text: 'Automatic emergency braking works to detect forward vehicle collisions before they happen to help minimize or avoid a crash.'
        }}
      />
    </StyledContentWrapper>
  );
}
