import React, { Component } from 'react';
import Form from 'components/Form/Form';
import { StyledContentWrapper } from 'styles';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: 'Create your password',
      submitAction: 'RESET_PASSWORD',
      successRoute: '/account/sign-in',
      fields: [
        {
          type: 'password',
          name: 'password',
          id: 'reset-password-input',
          placeholder: '******',
          required: true,
          label: 'password'
        },
        {
          type: 'password',
          name: 'confirmPassword',
          id: 'reset-confirm-password-input',
          placeholder: '******',
          required: true,
          label: 'Confirm password'
        }
      ],
      staticContent: {
        form: [
          {
            type: 'PasswordRequirements',
            location: 'form'
          }
        ]
      }
    };
  }

  render() {
    const {
      fields,
      submitAction,
      prompt,
      successRoute,
      staticContent: { form }
    } = this.state;

    return (
      <StyledContentWrapper>
        <Form
          noValidate=""
          submitAsync
          fields={fields}
          buttonWidth="280px"
          submitAction={submitAction}
          prompt={prompt}
          staticContent={form}
          successRoute={successRoute}
        />
      </StyledContentWrapper>
    );
  }
}

export default ResetPasswordPage;
