/* eslint-disable default-param-last */
import {
  VERIFY_RECAPTCHA_TOKEN,
  VERIFY_RECAPTCHA_TOKEN_SUCCESS,
  VERIFY_RECAPTCHA_TOKEN_FAILURE,
  GET_QUOTE_AUTOMATION_STATUS,
  GET_QUOTE_AUTOMATION_STATUS_SUCCESS,
  GET_QUOTE_AUTOMATION_STATUS_FAILURE,
  RESET_RECAPTCHA_STATUS
} from 'redux/actions';

const INITIAL_STATE = {
  success: false,
  expired: true,
  token: null,
  reCaptchaVerified: false,
  errorCodes: null,
  host: null,
  disableReCaptchaForTests: false
};

const reCaptchaReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case VERIFY_RECAPTCHA_TOKEN:
      return {
        ...state,
        token: payload,
        reCaptchaVerified: 'pending',
        errorCodes: null
      };

    case VERIFY_RECAPTCHA_TOKEN_SUCCESS:
      return {
        ...state,
        ...payload,
        token: null,
        reCaptchaVerified: true,
        expired: false
      };

    case VERIFY_RECAPTCHA_TOKEN_FAILURE:
      return {
        ...state,
        ...payload,
        token: null,
        reCaptchaVerified: false,
        expired: true
      };

    case GET_QUOTE_AUTOMATION_STATUS:
      return {
        ...state,
        disableReCaptchaForTests: false,
        requestingQuoteStatus: true
      };

    case GET_QUOTE_AUTOMATION_STATUS_SUCCESS:
      return {
        ...state,
        disableReCaptchaForTests: payload,
        expired: !payload,
        requestingQuoteStatus: false
      };

    case GET_QUOTE_AUTOMATION_STATUS_FAILURE:
      return {
        ...state,
        error: payload.error,
        requestingQuoteStatus: false
      };

    case RESET_RECAPTCHA_STATUS:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};

export default reCaptchaReducer;
