import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SignPage from 'components/Pages/SignPage';
import { StyledContentWrapper } from 'styles';

class SignUmuimOfferForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitAction: 'SIGN_UMUIM_FORM',
      fields: [
        {
          type: 'sign',
          name: 'umuimOfferForm',
          id: 'umuim-offer-form-input',
          label: 'First and Last Name',
          required: true,
          validation: 'fullName',
          autoComplete: 'name',
          fullWidth: true
        }
      ]
    };
  }

  render() {
    const { submitAction, fields } = this.state;
    const {
      data: { staticContent },
      prompt,
      successRoute
    } = this.props;

    return (
      <StyledContentWrapper>
        <SignPage
          prompt={prompt}
          pdfFileName="umuimOfferForm"
          formStaticContent={staticContent}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          documentName={prompt}
        />
      </StyledContentWrapper>
    );
  }
}

SignUmuimOfferForm.propTypes = {
  prompt: PropTypes.string.isRequired,
  successRoute: PropTypes.string.isRequired,
  data: PropTypes.shape({
    staticContent: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
  }).isRequired
};

export default SignUmuimOfferForm;
