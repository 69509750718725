import ReferralDisclaimer from './ReferralDisclaimer';
import Copyright from './Copyright';
import InfoBox from './InfoBox';
import LiabilityAgreement from './LiabilityAgreement';
import Verisk from './Verisk';
import PaymentDetails from './PaymentDetails';
import PaymentDisclaimer from './PaymentDisclaimer';
import EmailDisclaimer from './EmailDisclaimer';
import RecoverPasswordLink from './RecoverPasswordLink';
import PasswordRequirements from './PasswordRequirements';
import StartOver from './StartOver';
import StandaloneLink from './StandaloneLink';
import UpdatedPrice from './UpdatedPrice';
import EnterVIN from './EnterVIN';
import MilitaryDiscount from './MilitaryDiscount';
import TransUnion from './TransUnion';
import ConsumerFinance from './ConsumerFinance';
import RightToDisputeByState from './RightToDisputeByState';

const StaticPages = {
  LiabilityAgreement,
  Copyright,
  InfoBox,
  Verisk,
  PaymentDetails,
  PaymentDisclaimer,
  EmailDisclaimer,
  RecoverPasswordLink,
  PasswordRequirements,
  StartOver,
  StandaloneLink,
  UpdatedPrice,
  EnterVIN,
  ReferralDisclaimer,
  MilitaryDiscount,
  TransUnion,
  ConsumerFinance,
  RightToDisputeByState
};

export default StaticPages;
