import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import styled from 'styled-components/macro';
import IdleTimer from 'react-idle-timer';
import { clearRedirect, resetQuote } from 'redux/actions';
import { configureCookieName } from 'utilities';
import { ModalHeader } from 'styles';
import Button from '../../components/Buttons/Button';
import Modal from '../../components/Modal';
import {
  EXPIRES_COOKIE_HEADER_CONFIG,
  PAUSED_IDLE_TIMER_PAGES,
  DEFAULT_IDLE_TIMEOUT,
  CONFIRM_IDLE_TIMEOUT
} from '../../constants';

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  p {
    margin: 10px auto 0;

    span.bold {
      font-weight: 900;
    }
  }
`;

class AppController extends Component {
  constructor(props) {
    super(props);
    // ref
    this.idleTimer = null;
    this.intervalId = null;

    this.state = {
      timeout: DEFAULT_IDLE_TIMEOUT, // 30 minutes
      remaining: DEFAULT_IDLE_TIMEOUT, // 30 minutes
      isIdle: false
      // elapsed: 0 // TODO: Utilize elapsed or remove
    };

    // Bind event handlers and methods
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.resumeTimer = this.resumeTimer.bind(this);
  }

  componentDidMount() {
    const {
      history,
      history: {
        location: { pathname }
      },
      redirect,
      clearRedirect,
      cookies
    } = this.props;

    const section = pathname.split('/')[1];

    const sessionCookie = cookies.get(configureCookieName('session'));
    const personIdCookie = cookies.get(configureCookieName('personId'));
    const quoteIdCookie = cookies.get(configureCookieName('quoteId'));

    if (redirect) {
      history.push(redirect);

      clearRedirect();
    }

    if (
      PAUSED_IDLE_TIMER_PAGES[pathname] ||
      pathname.includes('/driver-exclusion/')
    ) {
      this.pauseTimer();
    }

    this.setState({
      remaining: this.idleTimer.getRemainingTime()
      // elapsed: this.idleTimer.getElapsedTime()
    });

    this.intervalId = setInterval(() => {
      this.setState({
        remaining: this.idleTimer.getRemainingTime()
        // elapsed: this.idleTimer.getElapsedTime()
      });
    }, 1000);

    if (
      section === 'purchase' &&
      !sessionCookie &&
      !personIdCookie &&
      !quoteIdCookie
    ) {
      resetQuote();
      history.replace('/quote/retrieve-your-quote', {});
    }
  }

  // eslint-disable-next-line consistent-return
  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props || prevState !== this.state) {
      const {
        history,
        redirect,
        clearRedirect,
        history: {
          location: { pathname }
        },
        asyncRequest,
        cookies,
        resetQuote,
        error
      } = this.props;

      const { remaining, isIdle } = this.state;

      const section = pathname.split('/')[1];

      const sessionCookie = cookies.get(configureCookieName('session'));
      const personIdCookie = cookies.get(configureCookieName('personId'));
      const quoteIdCookie = cookies.get(configureCookieName('quoteId'));

      if (
        section === 'purchase' &&
        !sessionCookie &&
        !personIdCookie &&
        !quoteIdCookie
      ) {
        resetQuote();

        return history.replace('/quote/retrieve-your-quote', {});
      }

      if (!asyncRequest && !remaining && isIdle) {
        cookies.remove(
          configureCookieName('session'),
          EXPIRES_COOKIE_HEADER_CONFIG
        );
        cookies.remove(
          configureCookieName('quoteId'),
          EXPIRES_COOKIE_HEADER_CONFIG
        );

        this.resetTimer();

        history.replace('/quote/retrieve-your-quote', {});

        return resetQuote();
      }

      if (
        PAUSED_IDLE_TIMER_PAGES[pathname] || // Pause time on specific paths
        asyncRequest || // Pause timer while making API requests
        (error && error.critical) // account for ErrorPage
      ) {
        this.pauseTimer();
      } else {
        this.resumeTimer();
      }

      // handle redirects
      if (redirect) {
        // if we need to pass state with redirect use redirect config
        if (redirect.config) {
          history.push(redirect.url, redirect.config);
        } else {
          history.push(redirect.url);
        }

        return clearRedirect();
      }
    }
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  handleOnActive() {
    this.setState({ isIdle: false });
    this.resumeTimer();
  }

  handleOnIdle() {
    this.setState({ isIdle: true });
  }

  resetTimer() {
    this.idleTimer.reset();
    this.setState({
      isIdle: false,
      remaining: DEFAULT_IDLE_TIMEOUT,
      timeout: DEFAULT_IDLE_TIMEOUT
    });
  }

  pauseTimer() {
    this.idleTimer.pause();
  }

  resumeTimer() {
    this.idleTimer.resume();
  }

  render() {
    const { timeout, remaining } = this.state;
    const showIdleModal = remaining <= CONFIRM_IDLE_TIMEOUT;

    return (
      <>
        <IdleTimer
          eventsThrottle={300} // reduce CPU usage
          timeout={timeout}
          ref={ref => {
            this.idleTimer = ref;
          }}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
        />
        {showIdleModal && (
          <Modal open onClose={this.resetTimer}>
            <TextContainer>
              <ModalHeader>Your session is about to expire</ModalHeader>
              <p>
                {`We'll end your session in `}
                <span className="bold">60 seconds</span>
                {` to make sure your
                information stays secure. Select "Continue" to continue your
                session.`}
              </p>
            </TextContainer>
            <ButtonWrapper>
              <Button
                primary
                large
                type="button"
                noMargin
                onClick={this.resetTimer}
              >
                Continue
              </Button>
            </ButtonWrapper>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ app: { redirect, asyncRequest, error } }) => ({
  redirect,
  asyncRequest,
  error
});

const mapDispatchToProps = dispatch => ({
  clearRedirect: () => dispatch(clearRedirect()),
  resetQuote: () => dispatch(resetQuote())
});

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(AppController)
);
