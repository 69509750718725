import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/variables';

function EffectiveDateErrorMessage() {
  return (
    <Container>
      <img
        src={`${process.env.REACT_APP_NOBLR_CDN}/icons/error_icon.svg`}
        alt="Error"
      />
      <span>
        It looks like your previously selected Start Date has expired.
      </span>
    </Container>
  );
}

const Container = styled.div`
  max-width: 300px;
  border-bottom: 1px solid ${colors.usaaLightGrey};
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 30px;
  position: relative;
  span {
    color: ${colors.coreBlue700};
  }
`;

export default EffectiveDateErrorMessage;
