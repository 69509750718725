import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { redirect } from 'redux/actions';
import { STATE_OPTIONS } from 'constants/stateCodes';
import { StyledContentWrapper } from 'styles';

class DriverLicensePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prompt: "What's your drivers license number and state?",
      submitAction: 'UPDATE_PRIMARY_DRIVER_REQUEST',
      fields: [
        {
          type: 'text',
          name: 'dlNumber',
          id: 'driver-license-number-input',
          label: 'drivers license',
          placeholder: 'License Number',
          required: true
        },
        {
          type: 'select',
          name: 'dlState',
          id: 'dl-state-input',
          selectLabel: 'License State',
          selectLabelId: 'state-license-label',
          required: true,
          initialValue: '',
          options: STATE_OPTIONS
        }
      ],
      successRoute: '/add-drivers/drivers-list',
      staticContent: {
        form: [
          {
            type: 'static',
            value: `By clicking "Continue", you are confirming that the information above is accurate and this driver's license is in good standing.`,
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const {
      section,
      editingDriver,
      vinVerificationNeeded,
      dlVerificationStatus,
      requiresDLVerification,
      redirect,
      requiresDriverExclusion,
      allDriversExcluded,
      askGoodStudentDiscount,
      askSafeDriving,
      askActiveMilitaryPersonnel,
      askSeniorDriverImprovement
    } = this.props;

    const { fields, prompt, successRoute } = this.state;
    const isPurchaseFlow = section === 'purchase';
    const isStartingQuote = section === 'start-quote';
    const isAdditionalDriverFlow = section === 'add-drivers';
    const isPrimaryDriver = isStartingQuote || isPurchaseFlow;
    const driver = { ...editingDriver };

    let enableSafeDriving = askSafeDriving;
    let enableGoodStudentDiscount = askGoodStudentDiscount;
    let enableSeniorDriverImprovement = askSeniorDriverImprovement;

    // if driver is null
    if (!driver) {
      // prevent state from being set locally
      return;
    }

    if (!isPrimaryDriver) {
      enableSafeDriving = driver.askSafeDriving;
      enableGoodStudentDiscount = driver.askGoodStudentDiscount;
      enableSeniorDriverImprovement = driver.askSeniorDriverImprovement;
    }

    // if PM is in start-quote or add-drivers and dl verification hasn't been requested
    if (
      (isStartingQuote || isAdditionalDriverFlow) &&
      !isPurchaseFlow &&
      dlVerificationStatus === 'unverified'
    ) {
      let updatedSuccessRoute = successRoute;

      if (enableSafeDriving) {
        updatedSuccessRoute =
          section === 'add-drivers'
            ? `/add-drivers/defensive-driving-course`
            : `/${section}/defensive-driving-course`;
      }

      if (enableGoodStudentDiscount) {
        updatedSuccessRoute = `/${section}/good-student-discount`;
      }

      if (askActiveMilitaryPersonnel) {
        updatedSuccessRoute =
          section === 'add-drivers'
            ? `/add-drivers/active-military-service`
            : `/${section}/active-military-service`;
      }

      if (enableSeniorDriverImprovement) {
        updatedSuccessRoute = '/start-quote/driver-improvement-discount';
      }

      const initializedFields = fields.map(field => ({
        ...field,
        initialValue: driver[field.name]
      }));

      const updatedPrompt = !isStartingQuote
        ? "What's their drivers license number and state?"
        : prompt;

      const updatedSubmitAction = !isStartingQuote
        ? 'SAVE_DL_INFO'
        : 'UPDATE_PRIMARY_DRIVER_REQUEST';

      this.setState({
        prompt: updatedPrompt,
        submitAction: updatedSubmitAction,
        fields: initializedFields,
        successRoute: updatedSuccessRoute
      });

      return;
    }

    // if PM is in purchase flow
    if (isPurchaseFlow && dlVerificationStatus === 'unverified') {
      const updatedPrompt = 'Please verify your drivers license';
      let updatedSuccessRoute = '/purchase/effective-date';

      if (vinVerificationNeeded) {
        updatedSuccessRoute = '/purchase/vin-confirm';
      } else if (requiresDriverExclusion && !allDriversExcluded) {
        updatedSuccessRoute = '/driver-exclusion/start';
      } else {
        updatedSuccessRoute = '/purchase/effective-date';
      }

      const updatedSubmitAction = 'VERIFY_DRIVERS_LICENSES';

      this.setState({
        prompt: updatedPrompt,
        successRoute: updatedSuccessRoute,
        submitAction: updatedSubmitAction,
        staticContent: { form: null }
      });

      return;
    }

    // if dl verification failed and PM is in start-quote section
    if (dlVerificationStatus === 'failed' && isStartingQuote) {
      const verifyDLSuccessRoute = `/add-drivers/drivers-list`;
      const verifyDLSubmitAction = 'UPDATE_PRIMARY_DRIVER_REQUEST';
      const verifyDLPrompt = 'Please enter your drivers license information';

      this.setState({
        prompt: verifyDLPrompt,
        successRoute: verifyDLSuccessRoute,
        submitAction: verifyDLSubmitAction
      });

      return;
    }

    if (
      section === 'purchase' &&
      (!requiresDLVerification || dlVerificationStatus === 'success')
    ) {
      if (vinVerificationNeeded) {
        redirect('/purchase/vin-confirm');
      } else if (requiresDriverExclusion && !allDriversExcluded) {
        redirect('/driver-exclusion/start');
      } else {
        redirect('/purchase/effective-date');
      }
    }
  }

  render() {
    const { section, editingDriver, gotRate, dlVerificationStatus } =
      this.props;

    const {
      prompt,
      submitAction,
      fields,
      staticContent: { form },
      successRoute
    } = this.state;

    const isPurchaseFlow = section === 'purchase' && gotRate;

    const showPrimaryDriver =
      section === 'start-quote' || section === 'purchase';

    const additionalDriverFlow = section === 'add-drivers';

    const userPronoun = showPrimaryDriver ? 'your' : 'their';

    if (isPurchaseFlow && dlVerificationStatus === 'failed') {
      return <Redirect to="/purchase/dl-verification-issue" replace />;
    }

    // if section is add drivers and there is no editing driver, redirect to drivers list
    return dlVerificationStatus === 'unverified' &&
      additionalDriverFlow &&
      !editingDriver.firstName ? (
      <Redirect to="/add-drivers/drivers-list" replace />
    ) : (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          staticContent={!isPurchaseFlow && form}
          submitAsync
        />
        <InfoBox
          extraData={{
            header: 'Why Are We Asking This?'
          }}
        >
          <>
            <p>
              {`We use ${userPronoun} driver's license to verify ${userPronoun} information, speeding up your quote process.`}
            </p>
            {!isPurchaseFlow && (
              <>
                <br />
                <p>
                  {`You are confirming that ${userPronoun} license is current and not expired, suspended, revoked, or otherwise inactive.`}
                </p>{' '}
              </>
            )}
          </>
        </InfoBox>
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: {
    askActiveMilitaryPersonnel,
    askSafeDriving,
    askSeniorDriverImprovement,
    section,
    dlVerificationStatus,
    requiresDLVerification,
    requiresDriverExclusion,
    askGoodStudentDiscount
  },
  drivers: { editingDriver },
  excludedDrivers: { allDriversExcluded },
  rate: { gotRate, vinVerificationNeeded }
}) => ({
  editingDriver,
  askSafeDriving,
  askSeniorDriverImprovement,
  askActiveMilitaryPersonnel,
  gotRate,
  section,
  vinVerificationNeeded,
  requiresDLVerification,
  dlVerificationStatus,
  requiresDriverExclusion,
  allDriversExcluded,
  askGoodStudentDiscount
});

const mapDispatchToProps = dispatch => ({
  redirect: url => dispatch(redirect(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverLicensePage);
