import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import Loader from 'components/Loader';
import { StyledContentWrapper } from 'styles';

class EmailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: "What's your email address?",
      submitAction: 'UPDATE_PRIMARY_DRIVER_REQUEST',
      fields: [
        {
          type: 'email',
          name: 'email',
          id: 'email-input',
          label: 'Email Address',
          placeholder: 'Email Address',
          validation: 'email',
          required: true,
          autoComplete: 'email'
        }
      ],
      successRoute: '/start-quote/home-ownership',
      staticContent: {
        form: [
          {
            type: 'EmailDisclaimer',
            location: 'form'
          }
        ]
      }
    };
  }

  componentDidMount() {
    const { fields, successRoute } = this.state;
    const { dlNumber, email } = this.props;

    let updatedSuccessRoute = successRoute;
    let updatedFields = fields;

    if (dlNumber) {
      updatedSuccessRoute = '/start-quote/confirm-primary-driver-info';
    }

    if (email) {
      updatedFields = [
        {
          ...fields[0],
          initialValue: email
        }
      ];
    }

    this.setState({ fields: updatedFields, successRoute: updatedSuccessRoute });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { fields, successRoute } = this.state;
      const { dlNumber, email } = this.props;

      let updatedSuccessRoute = successRoute;
      let updatedFields = fields;

      if (dlNumber) {
        updatedSuccessRoute = '/start-quote/confirm-primary-driver-info';
      }

      if (email) {
        updatedFields = [
          {
            ...fields[0],
            initialValue: email
          }
        ];
      }

      this.setState({
        fields: updatedFields,
        successRoute: updatedSuccessRoute
      });
    }
  }

  render() {
    const { softQuote, prefillComplete, loading } = this.props;

    const {
      prompt,
      submitAction,
      fields,
      successRoute,
      staticContent: { form }
    } = this.state;

    if (!prefillComplete || loading) {
      return (
        <StyledContentWrapper>
          <Loader absolute />
        </StyledContentWrapper>
      );
    }

    if ((softQuote && prefillComplete) || (prefillComplete && loading)) {
      return <Redirect to="/call-to-complete" replace />;
    }

    return (
      <StyledContentWrapper>
        <Form
          prompt={prompt}
          fields={fields}
          submitAction={submitAction}
          successRoute={successRoute}
          staticContent={form}
          submitAsync
        />
        <InfoBox
          extraData={{
            header: 'Why Are We Asking This?',
            text: `This allows us to send you your quote when it's ready and, if you choose to become a Noblr member, will help us set up your account.`
          }}
        />
      </StyledContentWrapper>
    );
  }
}

const mapStateToProps = ({
  app: { prefillComplete, loading },
  drivers: {
    primaryDriver: { dlNumber, email, softQuote }
  }
}) => ({
  dlNumber,
  email,
  softQuote,
  prefillComplete,
  loading
});

EmailPage.propTypes = {
  prefillComplete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  softQuote: PropTypes.bool.isRequired,
  dlNumber: PropTypes.string,
  email: PropTypes.string
};

EmailPage.defaultProps = {
  email: '',
  dlNumber: ''
};

export default connect(mapStateToProps)(EmailPage);
