import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Box } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Form from 'components/Form/Form';
import InfoToolTip from 'components/Static/InfoToolTip';
import ContentBox from 'components/ContentBox';
import { retrieveUSAAPersonInfo, clearAsyncErrors } from 'redux/actions';
import { brandonGrotesque, colors, breakPoints } from 'styles/variables';
import { getUrlParameter, parseSearchQueryParams } from 'utilities';

const WelcomeText = styled.p`
  margin: 20px auto -10px;
  font-size: 25px;
  line-height: 2.48;
  letter-spacing: 0.79px;
  font-weight: 300;
  font-family: ${brandonGrotesque};
  color: ${colors.coreBlue500};
  text-align: center;

  @media only screen and (min-width: ${breakPoints.medium}) {
    margin: 20px auto;
    font-size: 40px;
    line-height: 1.44;
  }
`;

const Header = styled.h1`
  font-weight: 500;
  letter-spacing: 0.71px;
  color: ${colors.coreBlue500};
  text-align: center;
  font-size: 40px;

  @media only screen and (min-width: ${breakPoints.medium}) {
    font-size: 55px;
    line-height: 0.98;
    letter-spacing: 0.98px;
  }
`;

const CompanySubHeader = styled.h2`
  margin: -5px auto 20px;
  font-size: 25px;
  line-height: 2.48;
  letter-spacing: 0.45px;
  color: ${colors.coreBlue500};
  text-align: center;

  @media only screen and (min-width: ${breakPoints.medium}) {
    font-size: 30px;
    line-height: 2.07;
    letter-spacing: 0.54px;
  }
`;

const PrimaryDriverName = styled.h3`
  margin: 20px auto 50px;
  height: 55px;
  font-size: 42px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.coreBlue500};
`;

const DriverBox = styled(Box)`
  margin: 20px auto;
`;

const QuoteGrid = styled(Grid)`
  position: relative;
  & #usaa-header-info-box {
    margin-top: 13px;
    right: 9%;

    @media (max-width: 767px) {
      margin-top: 5%;
      right: 1%;
    }
  }
`;

class QuoteIntegration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [
        {
          type: 'text',
          name: 'firstName',
          id: 'first-name-input',
          label: 'First Name',
          value: '',
          initialValue: '',
          required: false,
          hidden: true
        },
        {
          type: 'text',
          name: 'lastName',
          id: 'last-name-input',
          value: '',
          initialValue: '',
          label: 'Last Name',
          required: true,
          hidden: true
        },
        {
          type: 'text',
          name: 'dob',
          label: 'date of birth',
          id: 'date-of-birth-input',
          placeholder: 'MM/DD/YYYY',
          required: true,
          disabled: false,
          formattingOptions: {
            custom: true,
            maskedDate: true,
            date: true,
            datePattern: ['m', 'd', 'Y']
          }
        }
      ],
      submitAction: 'VERIFY_USAA_PERSON_AND_RETRIEVE_QUOTE',
      successRoute: '/start-quote/confirm-primary-driver-info',
      staticContent: {
        form: [
          {
            location: 'form',
            type: 'LiabilityAgreement'
          }
        ]
      },
      infoToolTipHeader: 'Additional Information',
      infoToolTipBody: [
        'USAA is now offering pay as your drive, a usage-based insurance option with auto rates based on how much and how safely you drive.',
        'Pay as you drive policies are issued by Noblr, a USAA company. All sales, servicing and claims are managed through Noblr. To speak with a Noblr team member, call 877-BE-NOBLR (877-236-6257). If you already have a policy, you can reach the Noblr team through the USAA pay as you drive app.',
        'USAA membership is managed through USAA. To speak with a USAA representative, call 800-531-USAA (8722). '
      ],
      infoToolTipId: 'usaa-header-info-box',
      mobileAppInfoBoxBody: [
        'Our pay as you drive mobile app measures how many miles you drive and scores your driving to help you make safer choices and save money. Low mileage and better driving scores add up to huge potential savings.',
        "If you don't drive a lot, usage-based insurance might be a good option for you.",
        'Also save money when you:',
        '•Drive less.',
        '•Brake and accelerate mindfully.',
        "•Don't text and drive.",
        '•Avoid driving late at night.'
      ],

      usaaInfoBoxBody: [
        "Pay as your drive policies are issued by Noblr, a USAA company, so you can be confident you'll get the same USAA quality and excellent service.",
        'All sales, servicing and claims are managed through Noblr. To speak with a Noblr team member, call 877-BE-NOBLR (877-236-6257). If you already have a policy, you can reach the Noblr team through the USAA pay as your drive app.'
      ]
    };
  }

  componentDidMount() {
    const {
      location: { search },
      primaryDriver,
      retrieveUSAAPersonInfo,
      usaaPersonInfoRetrieved,
      loading,
      clearAsyncErrors
    } = this.props;

    // parse out incoming query params
    const queryParams = parseSearchQueryParams(search);

    clearAsyncErrors();

    if (!usaaPersonInfoRetrieved && !loading) {
      retrieveUSAAPersonInfo(queryParams);
    } else {
      this.setState(prevState => ({
        fields: prevState.fields.map(field => {
          const updatedField = { ...field };

          if (field.name !== 'dob' && primaryDriver[field.name]) {
            updatedField.initialValue = primaryDriver[field.name];
          }

          return updatedField;
        })
      }));
    }
  }

  render() {
    const {
      location: { search },
      primaryDriver,
      usaaPersonInfoRetrieved
    } = this.props;

    const {
      staticContent: { form },
      fields,
      submitAction,
      successRoute,
      infoToolTipId,
      infoToolTipHeader,
      infoToolTipBody,
      mobileAppInfoBoxBody,
      usaaInfoBoxBody
    } = this.state;

    const fullName = `${primaryDriver.firstName} ${primaryDriver.lastName}`;
    // If quoteId is in the query param, store in variable
    const quoteId = getUrlParameter(search, 'quoteId');

    if (!quoteId) {
      return <Redirect to="/quote/not-found" replace />;
    }

    return (
      <Container>
        <Grid container direction="column" wrap="nowrap" alignItems="center">
          <Grid item xs sm>
            <WelcomeText>Let&apos;s get you to your quote!</WelcomeText>
            <Header>Pay As You Drive</Header>
            <QuoteGrid container>
              <CompanySubHeader>by Noblr, a USAA Company</CompanySubHeader>
              <InfoToolTip
                id={infoToolTipId}
                header={infoToolTipHeader}
                body={infoToolTipBody}
              />
            </QuoteGrid>
          </Grid>
        </Grid>
        <ContentBox
          img={`${process.env.REACT_APP_NOBLR_CDN}/images/plain-phone.svg`}
          description="…uses a mobile app to personalize your rate with the miles you drive and how well you drive"
          infoBoxId="mobile-app-info-box"
          infoBoxBody={mobileAppInfoBoxBody}
        />
        <ContentBox
          img={`${process.env.REACT_APP_NOBLR_CDN}/images/shield-with-usaa-logo.svg`}
          description="...is the same USAA quality and excellent service you expect"
          infoBoxId="usaa-info-box"
          infoBoxBody={usaaInfoBoxBody}
        />
        <Grid item xs sm>
          {usaaPersonInfoRetrieved && (
            <DriverBox>
              <PrimaryDriverName>{fullName}</PrimaryDriverName>
            </DriverBox>
          )}
        </Grid>
        <Grid container item xs sm>
          <Form
            prompt={null}
            submitAsync
            fields={fields}
            buttonPosition="static"
            staticContent={form}
            submitAction={submitAction}
            successRoute={successRoute}
          />
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = ({
  app: { usaaPersonInfoRetrieved, loading },
  drivers: { primaryDriver }
}) => ({ usaaPersonInfoRetrieved, loading, primaryDriver });

const mapDispatchToProps = dispatch => ({
  retrieveUSAAPersonInfo: quoteId => dispatch(retrieveUSAAPersonInfo(quoteId)),
  clearAsyncErrors: () => dispatch(clearAsyncErrors())
});

QuoteIntegration.propTypes = {
  usaaPersonInfoRetrieved: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  primaryDriver: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }).isRequired,
  retrieveUSAAPersonInfo: PropTypes.func.isRequired,
  clearAsyncErrors: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteIntegration);
