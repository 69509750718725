import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import PageBody from 'components/PageBody';
import { buildPrompt, retryImport } from 'utilities';
import QUOTE_FLOW_SECTIONS from 'assets/data';

const ErrorPage = lazy(() =>
  retryImport(() => import('components/Pages/ErrorPage'))
);

const PageHeader = lazy(() =>
  retryImport(() => import('components/PageHeader'))
);

const Loader = lazy(() => retryImport(() => import('components/Loader')));

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: { hideBackArrow: false },
      sectionTitle: 'start quote'
    };
  }

  componentDidMount() {
    const { dispatch, asyncRequest, path } = this.props;
    const splitPath = path.split('/');
    // todo: pass this down so we can use it on name page?
    const sectionKey = splitPath[1];
    const step = splitPath[2];

    const sectionData = QUOTE_FLOW_SECTIONS.find(
      section => section.name === sectionKey
    );

    const sectionTitle = sectionKey.split('-').join(' ');

    if (sectionData) {
      const pageData =
        sectionData.pages.find(page => page.name === step) || null;

      if (!pageData) {
        return;
      }

      const postContentData =
        (pageData &&
          pageData.staticContent &&
          pageData.staticContent.filter(
            data => data.location === 'postContent'
          )) ||
        null;

      const formStaticContent =
        pageData &&
        pageData.staticContent &&
        pageData.staticContent.filter(data => data.location === 'form');

      const preStaticContent =
        (pageData.staticContent &&
          pageData.staticContent.filter(data => data.location === 'pre')) ||
        null;

      const overrideSectionTitle =
        pageData.overrideSectionTitle &&
        buildPrompt(pageData.overrideSectionTitle);

      const prompt = (pageData.prompt && buildPrompt(pageData.prompt)) || null;

      const { subHeader = null } = pageData;
      // const { subHeaderDisclaimer } = pageData;
      // const { subHeaderDisclaimerLink } = pageData;
      const { continueRouteAction = null } = pageData;

      const successRoute =
        (pageData.successRoute && buildPrompt(pageData.successRoute)) || null;

      const pageBody = (pageData.body && buildPrompt(pageData.body)) || null;

      this.setState({
        pageData,
        sectionTitle,
        preStaticContent,
        postContentData,
        formStaticContent,
        overrideSectionTitle,
        prompt,
        subHeader,
        // subHeaderDisclaimer,
        // subHeaderDisclaimerLink,
        continueRouteAction,
        successRoute,
        pageBody
      });

      const { componentDidMountActions = [] } = pageData;

      if (componentDidMountActions.length > 0) {
        componentDidMountActions.map(action =>
          dispatch({
            type: action
          })
        );
      }

      if (asyncRequest) {
        dispatch({
          type: 'CLEAR_ASYNC_STATUS'
        });
      }
    }
  }

  render() {
    const {
      history,
      appLoading,
      error,
      loadingRate,
      driversVerified,
      gotRate,
      registeredQuote,
      location,
      isQuoteRetrieval
    } = this.props;

    const {
      pageData,
      postContentData,
      formStaticContent,
      preStaticContent,
      overrideSectionTitle,
      sectionTitle,
      prompt,
      subHeader,
      continueRouteAction,
      successRoute,
      pageBody
    } = this.state;

    const hideArrowOnDLPage =
      location.pathname === '/start-quote/driver-license' &&
      gotRate &&
      !driversVerified &&
      registeredQuote;

    if ((appLoading || loadingRate) && !isQuoteRetrieval) {
      return <Loader absolute />;
    }

    if (error && error.critical && !error.DNQ) {
      return <ErrorPage error={error} history={history} />;
    }

    return (
      <>
        {pageData && !pageData.hidePageHeader && (
          <PageHeader
            hideBackArrow={
              pageData.hideBackArrow ||
              (error && error.critical) ||
              hideArrowOnDLPage
            }
          >
            {overrideSectionTitle || sectionTitle}
          </PageHeader>
        )}
        <PageBody
          history={history}
          location={location}
          data={pageData}
          prompt={prompt}
          subHeader={subHeader}
          postContentData={postContentData}
          formStaticContent={formStaticContent}
          preStaticContent={preStaticContent}
          continueRouteAction={continueRouteAction}
          successRoute={successRoute}
          body={pageBody}
          headerTitle={overrideSectionTitle || sectionTitle}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  app: {
    error,
    loading: appLoading,
    asyncRequest,
    registeredQuote,
    driversVerified,
    isQuoteRetrieval
  },
  rate: { loading: loadingRate, gotRate }
}) => ({
  error,
  appLoading,
  asyncRequest,
  loadingRate,
  registeredQuote,
  driversVerified,
  gotRate,
  isQuoteRetrieval
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
