import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Form from 'components/Form/Form';
import InfoBox from 'components/Static/InfoBox';
import { StyledContentWrapper } from 'styles';

export default function CommuteSurcharge() {
  const vehicleId = useSelector(
    state => state.vehicles?.editingVehicle?.vehicleId
  );

  const fields = [
    {
      type: 'radio',
      name: 'commuteSurcharge',
      radioGroupAriaLabel: 'Commute Surcharge',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ],
      required: true
    }
  ];

  const prompt =
    'Is this car used to commute to New York or New Jersey four or more times a month by any of your drivers?';

  const submitAction = 'UPDATE_VEHICLE';

  const successRoute = '/add-vehicles/vehicles-list';

  return !vehicleId ? (
    <Redirect to="/add-vehicles/vehicles-list" replace />
  ) : (
    <StyledContentWrapper>
      <Form
        prompt={prompt}
        fields={fields}
        submitAction={submitAction}
        successRoute={successRoute}
        submitAsync
      />
      <InfoBox
        extraData={{
          header: 'Why Are We Asking This?',
          text: 'In order to provide you with an accurate rate and coverage, we need to know if you and/or anyone else regularly commutes with this car to New York or New Jersey.'
        }}
      />
    </StyledContentWrapper>
  );
}
