import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import RecoverPasswordLink from 'components/Static/RecoverPasswordLink';

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      highlight: false
    };

    this.toggleHighlight = this.toggleHighlight.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleFocus() {
    this.setState({
      highlight: true
    });
  }

  handleBlur() {
    const { form, field } = this.props;

    form.setFieldTouched(field.name, true, true);

    this.setState({
      highlight: false
    });
  }

  toggleHighlight() {
    this.setState(prevState => ({
      highlight: !prevState.highlight
    }));
  }

  render() {
    const { highlight } = this.state;
    const {
      field,
      label,
      placeholder,
      showRecoverPasswordLink,
      hidden = false,
      required
    } = this.props;

    return (
      <>
        <TextField
          {...field}
          id={field.id}
          label={label}
          type="password"
          autoComplete="off"
          className="fs-exclude"
          placeholder={placeholder}
          variant="outlined"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          hidden={hidden}
          InputLabelProps={{
            notched: 'true',
            shrink: (field.value && field.value.length > 0) || highlight
          }}
          inputProps={{
            'aria-label': field.name,
            'aria-labelledby': field.name,
            'aria-required': required,
            onInit: this.onCleaveInit,
            onKeyDown: this.handleOnKeyDown,
            ...field
          }}
        />
        {showRecoverPasswordLink && <RecoverPasswordLink />}
      </>
    );
  }
}

export default PasswordInput;
